import React, { FunctionComponent, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useIntl } from 'react-intl';
import { ERROR_CONFIG, QueryError, ErrorMessage } from 'components/ErrorManagement';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { DEFAULT_START_PATH } from 'constants/routeBuilders';
import { RichMessage } from 'components/RichMessage';
import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';

interface Props {
    error?: QueryError;
}

interface ErrorPageFormatter {
    formatTitle: (arg0: QueryError) => string | undefined;
    formatIconClass: (arg0: QueryError) => IconDefinition | undefined;
    formatContext: (arg0: QueryError) => Record<string, string> | undefined;
}

export const useErrorPageFormatter = (): ErrorPageFormatter => {
    const intl = useIntl();

    const formatTitle = ({ code, context, namespace }: QueryError): string | undefined => {
        // Get error message key
        const errorContext = context || {};
        const errorCode = code || 'default';
        const errorNamespace = namespace || 'generic';
        const fullNamespace = `errors.${errorNamespace}`;

        // Message Ids
        const msgId = `${fullNamespace}.${errorCode}.title`;
        const defaultMsgId = `${fullNamespace}.default.title`;

        // Display specific or generic message based on key existence
        if (intl.messages[msgId]) {
            return intl.formatMessage({ id: msgId }, errorContext);
        } else if (intl.messages[defaultMsgId]) {
            return intl.formatMessage({ id: defaultMsgId });
        } else {
            return intl.formatMessage({ id: 'error-page.title' });
        }
    };

    const formatIconClass = ({ code, namespace }: QueryError): IconDefinition | undefined => {
        // Get error message key
        const errorNamespace = namespace || 'generic';
        const errorCode = code || 'default';
        const errorConfig = (ERROR_CONFIG[errorNamespace] || {})[errorCode];

        return errorConfig?.icon || ERROR_CONFIG['generic']['default'].icon;
    };

    const formatContext = ({ code, context, namespace }: QueryError): Record<string, string> | undefined => {
        // Get error message key
        const errorNamespace = namespace || 'generic';
        const errorCode = code || 'default';
        const errorConfig = (ERROR_CONFIG[errorNamespace] || {})[errorCode];

        return { ...(errorConfig?.defaultContext || {}), ...(context || {}) };
    };

    return { formatTitle, formatIconClass, formatContext };
};

export const ErrorPage: FunctionComponent<Props> = ({ error = { code: '' } }: Props) => {
    // Get the formatted title and error icon class
    const formatter = useErrorPageFormatter();
    const icon = formatter.formatIconClass(error);
    const errorTitle = formatter.formatTitle(error);

    // Format error context (adds defaults if present in ERROR_CONFIG)
    error.context = formatter.formatContext(error);

    return (
        <Fragment>
            <div className="container">
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <Card className="text-center pt-2 pb-5 w-75 w-lg-50 text-dark">
                        <Card.Header className="bg-white pt-5 pb-4">
                            {/* Error icon */}
                            <Card.Title className="mt-2 mb-4">
                                {icon && <FontAwesomeIcon icon={icon} size="4x" className="text-primary mb-2" />}
                                <h2>{errorTitle}</h2>
                            </Card.Title>
                        </Card.Header>

                        <Card.Body className="pt-5">
                            {/* Error content */}
                            <div className="mb-4">
                                <ErrorMessage error={error} />
                            </div>

                            {/* Link back home */}
                            <div className="d-flex justify-content-center">
                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                <Button as={Link as any} variant="dark" to={error.origin || DEFAULT_START_PATH}>
                                    {error.origin ? (
                                        <RichMessage id="error-page.back-link" />
                                    ) : (
                                        <RichMessage id="error-page.back-home-link" />
                                    )}
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="fixed-bottom text-center text-muted">
                <div className="mb-5">
                    <KeypupLogo height={32} className="mb-2" />
                    <p>
                        <em>
                            <RichMessage id="generic.footer" />
                        </em>
                    </p>
                </div>
            </div>
        </Fragment>
    );
};
