import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useIntercom } from 'react-use-intercom';
import { LS_INITIAL_UTM_KEY, LS_ONBOARDING_VARIANT_KEY } from 'constants/defaultValues';
import { useLocalStorage, useLocation } from 'react-use';
import { ONBOARDING1_PATH, ONBOARDING_VARIANTS } from 'constants/routeBuilders';

// This hooks is in charge of booting Intercom with the right configuration
//
// Note: we could probably move Intercom to Google Tag manager eventually. We simply
// need to make sure that all relevant data are pushed to GTM Data Layer
// See: useGtmTracker.
export function useIntercomTracker(): void {
    // Services
    const location = useLocation();
    const { boot: bootIntercom, update: updateIntercom } = useIntercom();

    // Context
    const apiUser = useSelector((e: ReduxState) => e.authUser.apiUser);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // State
    const [isBooted, setIsBooted] = useState<boolean>(false);

    // Local storage
    const [initialUtm] = useLocalStorage<Record<string, string>>(LS_INITIAL_UTM_KEY);
    const [onboardingVariant] = useLocalStorage<string>(LS_ONBOARDING_VARIANT_KEY, ONBOARDING_VARIANTS[0].name);

    // Boot intercom
    useEffect(() => {
        if (!apiUser) return;

        // Display Intercom on top of Elev.io on the onboarding
        const isForceShown = location.pathname?.startsWith(ONBOARDING1_PATH);

        // The user payload to send to intercom
        const payload = {
            // Always hide Intercom launcher since we trigger it from Elevio
            hideDefaultLauncher: !isForceShown,
            userId: apiUser.user.id,
            userHash: apiUser.intercomHash,
            email: apiUser.user.email,
            name: apiUser.user.firstName,
            company: company ? { companyId: company.id, name: company.name } : undefined,
            customAttributes: {
                ...initialUtm,
                onboarding_variant: onboardingVariant
            }
        };

        // Update or boot intercom
        if (isBooted) {
            updateIntercom(payload);
        } else {
            bootIntercom(payload);
            setIsBooted(true);
        }
    }, [company, initialUtm, onboardingVariant, isBooted, updateIntercom, bootIntercom, apiUser, location.pathname]);
}
