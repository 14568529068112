import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import React, { FunctionComponent, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigableDropdown } from 'components/NavigableDropdown/useNavigableDropdown';
import NavigableDropdownMenu from './NavigableDropdownMenu';
import NavigableDropdownItem from './NavigableDropdownItem';

export interface SelectDropdownOption {
    eventKey: string;
    icon: IconDefinition;
    labelId: string;
}

interface FormattedDropdownOption {
    eventKey: string;
    icon: IconDefinition;
    label: string;
}

interface Props {
    activeKey: string;
    className?: string;
    isSearchable?: boolean;
    onSelect: (e: string | null) => void;
    options: SelectDropdownOption[];
}

// A select input styled as a dropdown menu
const NavigableDropdown: FunctionComponent<Props> = ({ activeKey, className, onSelect, options, isSearchable }) => {
    // Service
    const intl = useRichIntl();

    // Get selected options
    const selectedOption = useMemo(() => options.find(e => e.eventKey == activeKey), [activeKey, options]);

    // Generate available options
    const availableOptions: FormattedDropdownOption[] = useMemo(() => {
        return options
            .filter(e => e.eventKey != activeKey)
            .map(e => {
                return { eventKey: e.eventKey, icon: e.icon, label: intl.formatMessage({ id: e.labelId }) };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [activeKey, intl, options]);

    // Use Dropdown
    const {
        getDropdownProps,
        getDropdownMenuProps,
        getDropdownItemProps,
        options: finalOptions
    } = useNavigableDropdown<FormattedDropdownOption>({
        isSearchable,
        onSelect,
        options: availableOptions,
        mapper: {
            value: 'eventKey',
            label: 'label'
        }
    });

    // Render the dropdown
    return (
        <Dropdown {...getDropdownProps} onSelect={onSelect} className={className}>
            {/* Button */}
            <Dropdown.Toggle variant="white" className="shadow-sm border-grey-11 w-100">
                {selectedOption ? (
                    <>
                        <div className="bg-secondary bg-opacity-10 d-flex justify-content-center align-items-center me-2 p-2 text-primary">
                            <FontAwesomeIcon icon={selectedOption.icon} size="lg" />
                        </div>
                        <span className="fw-bold me-auto">
                            <RichMessage id={selectedOption.labelId} />
                        </span>
                    </>
                ) : (
                    '-'
                )}
            </Dropdown.Toggle>

            {/* Menu */}
            <NavigableDropdownMenu {...getDropdownMenuProps} className="w-100">
                {finalOptions.map(e => {
                    return (
                        <NavigableDropdownItem
                            key={e.eventKey}
                            eventKey={e.eventKey}
                            value={e.eventKey}
                            as="div"
                            role="button"
                            className="d-flex align-items-center"
                            {...getDropdownItemProps}
                        >
                            <div className="bg-secondary bg-opacity-10 d-flex justify-content-center align-items-center me-2 p-2 text-primary">
                                <FontAwesomeIcon icon={e.icon} size="lg" />
                            </div>
                            <span>{e.label}</span>
                        </NavigableDropdownItem>
                    );
                })}
            </NavigableDropdownMenu>
        </Dropdown>
    );
};

export default NavigableDropdown;
