import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import {
    DashboardWithLayoutAndWidgets,
    GetDashboardWithWidgetsResp,
    GET_DASHBOARD_WITH_WIDGETS
} from '../queries/Dashboard';
import { useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';

interface QueryOpts {
    id?: string;
    skip?: boolean;
}

interface DashboardQueryResults extends QueryResult<GetDashboardWithWidgetsResp, OperationVariables> {
    normalized?: DashboardWithLayoutAndWidgets;
}

export function useDashboardWithWidgets({ id, skip }: QueryOpts): DashboardQueryResults {
    // Retrieve the dashboard by ID
    const rs = useQuery<GetDashboardWithWidgetsResp>(GET_DASHBOARD_WITH_WIDGETS, {
        skip: skip || !id,
        variables: { id }
    });

    // Extract the dashboard
    const normalized = useMemo(() => {
        const obj = rs.data?.dashboard;
        return obj ? (omitDeep(obj, '__typename') as DashboardWithLayoutAndWidgets) : undefined;
    }, [rs.data?.dashboard]);

    return { ...rs, loading: rs.loading, normalized: normalized };
}
