import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import {
    faDatabase,
    faTable,
    faBullseye,
    faChartLine,
    faFilter,
    faPaintRoller,
    faBook,
    faCrosshairs
} from '@fortawesome/free-solid-svg-icons';
import {
    WidgetConfigType,
    WidgetDesignMode,
    WidgetFactType,
    WIDGET_DISPLAY_CONFIGS
} from 'components/Dashboarding/Models/Widget';
import { WIDGET_BUILDER_PARAM_STEP } from 'constants/routeBuilders';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type MenuId =
    | 'configure-columns'
    | 'configure-filters'
    | 'configure-kpi-metric-table'
    | 'configure-kpi-trend-table'
    | 'configure-source'
    | 'configure-table'
    | 'configure-visualization'
    | 'customize-chart'
    | 'customize-list'
    | 'configure-drilldown'
    | 'document-insight';

export interface MenuConfig {
    id: MenuId;
    icon: IconDefinition;
    designModes: WidgetDesignMode[];
    configTypes?: WidgetConfigType[];
    excludedConfigTypes?: WidgetConfigType[];
    defaultSelectedFor?: WidgetConfigType[];
    migrateFrom?: MenuId[];
    stage?: 'beta';
}

// The list of configuration menus. Ordered by appearance rank.
const MENU_LIST: MenuConfig[] = [
    {
        id: 'configure-source',
        icon: faDatabase,
        designModes: ['chart', 'kpi']
    },
    {
        id: 'configure-visualization',
        icon: faChartBar,
        designModes: ['chart', 'kpi', 'cards']
    },
    {
        id: 'configure-table',
        icon: faTable,
        designModes: ['chart'],
        defaultSelectedFor: ['LIST']
    },
    {
        id: 'configure-kpi-metric-table',
        icon: faBullseye,
        designModes: ['kpi']
    },
    {
        id: 'configure-kpi-trend-table',
        icon: faChartLine,
        designModes: ['kpi'],
        configTypes: ['SPARKLINEKPI', 'SPARKCOLUMNKPI', 'TRENDKPI']
    },
    {
        id: 'configure-filters',
        icon: faFilter,
        designModes: ['cards'],
        configTypes: ['LARGECARD'],
        migrateFrom: ['configure-columns']
    },
    {
        id: 'configure-columns',
        icon: faFilter,
        designModes: ['cards'],
        configTypes: ['MINICARD'],
        migrateFrom: ['configure-filters']
    },
    {
        id: 'configure-drilldown',
        icon: faCrosshairs,
        designModes: ['chart', 'kpi'],
        excludedConfigTypes: ['NUMBERKPI', 'TRENDKPI'],
        stage: 'beta'
    },
    {
        id: 'customize-chart',
        icon: faPaintRoller,
        designModes: ['chart', 'kpi'],
        excludedConfigTypes: ['LIST']
    },
    {
        id: 'customize-list',
        icon: faPaintRoller,
        designModes: ['chart'],
        configTypes: ['LIST']
    },
    {
        id: 'document-insight',
        icon: faBook,
        designModes: ['chart', 'kpi', 'cards']
    }
];

export function useConfigurationPanelMenu(
    widget: WidgetFactType | undefined
): {
    selectedMenu: string | null;
    selectedSubMenu: number | undefined;
    applicableMenus: MenuConfig[];
    onMenuSelect: (menu: string | null) => void;
    onSubMenuSelect: (index: number) => void;
} {
    // State
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedMenu, setSelectedMenu] = useState<string | null>(searchParams.get(WIDGET_BUILDER_PARAM_STEP));
    const [selectedSubMenu, setSelectedSubMenu] = useState<number | undefined>();

    // Infer context
    const designMode = useMemo(() => (widget ? WIDGET_DISPLAY_CONFIGS[widget.configType].designMode : undefined), [
        widget
    ]);
    const applicableMenus = useMemo(() => {
        return MENU_LIST.filter(e => {
            return (
                designMode &&
                e.designModes.includes(designMode) &&
                (!e.configTypes || (widget && e.configTypes.includes(widget.configType))) &&
                (!e.excludedConfigTypes || (widget && !e.excludedConfigTypes.includes(widget.configType)))
            );
        });
    }, [designMode, widget]);

    // Update the `step` search param in the URL
    const updateStepSearchParam = useCallback(
        (step: string | null) => {
            // Create new search params from the current ones
            const newParams = new URLSearchParams(searchParams);

            // Overwrite the `step` search param
            if (step == null) newParams.delete(WIDGET_BUILDER_PARAM_STEP);
            else newParams.set(WIDGET_BUILDER_PARAM_STEP, step);

            // Set search params state
            setSearchParams(newParams);
        },
        [searchParams, setSearchParams]
    );

    // Invoked when a menu is selected
    const onMenuSelect = useCallback((menu: string | null) => {
        if (!menu) return;

        // State update
        setSelectedMenu(menu);
    }, []);

    // Invoked when a sub menu is selected
    const onSubMenuSelect = useCallback((index: number) => {
        // State update
        setSelectedSubMenu(index);
    }, []);

    // Auto-select the first menu if no menu is selected or the current menu is not applicable
    // anymore.
    useEffect(() => {
        if (!applicableMenus || applicableMenus.length == 0) return;

        // Select the menu for the current widget which is related to the previously selected menu.
        // E.g. configure-filters in charts will migrate to configure-columns in reports
        const migrateFrom = applicableMenus.find(e => e.migrateFrom?.includes(selectedMenu as MenuId));
        if (migrateFrom) return onMenuSelect(migrateFrom.id);

        if (!applicableMenus.map(e => e.id).includes(selectedMenu as MenuId)) {
            const prefMenu = widget
                ? applicableMenus.find(e => e.defaultSelectedFor?.includes(widget.configType))?.id
                : null;
            onMenuSelect(prefMenu || applicableMenus[0].id);
        }
    }, [applicableMenus, onMenuSelect, selectedMenu, widget]);

    // On `selectedMenu` change, update the search param with the new value
    useEffect(() => updateStepSearchParam(selectedMenu), [selectedMenu, updateStepSearchParam]);

    return { selectedMenu, selectedSubMenu, applicableMenus, onMenuSelect, onSubMenuSelect };
}
