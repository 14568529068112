import React, { FunctionComponent, useCallback, useMemo, useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { faCog, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdateWidgetResp, UPDATE_WIDGET } from 'api/hq/queries/Widget';
import { WidgetMarkdown } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { useClickAway } from 'react-use';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import MarkdownEditor from 'components/Input/MarkdownEditor';

interface Props {
    className?: string;
    isEditable?: boolean;
    isDashboardEditing?: boolean;
    isRemovable?: boolean;
    loading?: boolean;
    onRemoveWidget?: () => void;
    widget: WidgetMarkdown;
}

const MarkdownWidget: FunctionComponent<Props> = ({
    className,
    isEditable,
    isRemovable,
    isDashboardEditing,
    loading,
    onRemoveWidget,
    widget
}: Props) => {
    // Refs
    const widgetRef = useRef(null);

    // State
    const defaultContent = useMemo(() => widget?.config?.series[0]?.content, [widget?.config?.series]);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [content, setContent] = useState<string>(defaultContent);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    // GraphQL mutation
    const [updateWidget] = useMutation<UpdateWidgetResp>(UPDATE_WIDGET);

    // Save the content of the markdown widget
    const saveWidget = useCallback(
        async content => {
            if (!content) return;
            setSaveLoading(true);

            // Update content
            const input = { id: widget.id, config: { series: [{ content }] } };
            const response = (await updateWidget({ variables: { input } })).data?.updateWidget;

            // Return to preview mode
            if (response?.success) setIsEditMode(false);

            setSaveLoading(false);
        },
        [updateWidget, widget.id]
    );

    // Save the content of the widget when users click outside
    useClickAway(widgetRef, () => isEditMode && saveWidget(content));

    // Save the content state
    const handleContentChange = useCallback(value => setContent(value), []);

    // Render
    return (
        <Card ref={widgetRef} className={classNames('h-100', className, { 'cursor-move': isDashboardEditing })}>
            {/* Overlay on top of the widget when we're editing the dashboard */}
            {isDashboardEditing && (
                <div
                    className="cursor-move position-absolute bg-semi-transparent m-3"
                    style={{ zIndex: 2, inset: 0 }}
                ></div>
            )}

            {/* Save Loader Overlay */}
            {saveLoading && (
                <div
                    className="position-absolute bg-white bg-opacity-75 start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center"
                    style={{ zIndex: 3 }}
                >
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {/* Widget content */}
            {loading ? (
                // Loading state
                <Card.Body className="h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </Card.Body>
            ) : (
                <Card.Body className={classNames('h-100', { 'pb-1': isEditMode })}>
                    {/* Action menu */}
                    {!isEditMode && (
                        <div
                            className="position-absolute end-0 top-0 p-2 m-2 bg-transparent bg-opacity-75"
                            style={{ zIndex: 2 }}
                        >
                            <Dropdown>
                                <Dropdown.Toggle as="div" bsPrefix="none" role="button" className="text-light">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                    <Dropdown.Item onClick={() => setIsEditMode(true)} disabled={!isEditable}>
                                        <FontAwesomeIcon icon={faCog} className="me-2" />
                                        <RichMessage id="dashboarding.dashboard-editor.edit-content" />
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        as="div"
                                        onClick={onRemoveWidget}
                                        className={classNames({ 'text-danger': isRemovable })}
                                        role="button"
                                        disabled={!isRemovable}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                        <RichMessage id="dashboarding.dashboard-editor.delete-insight" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}

                    {/* Edit form */}
                    <MarkdownEditor
                        preview={isEditMode ? 'edit' : 'preview'}
                        value={content}
                        onChange={handleContentChange}
                        onSave={saveWidget}
                        onCancel={() => {
                            setContent(defaultContent);
                            setIsEditMode(false);
                        }}
                    />
                </Card.Body>
            )}
        </Card>
    );
};

export default MarkdownWidget;
