import { OffsetFilter, TimePeriodFilter } from 'components/Dashboarding/DataSource/QueryOperators';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { NameLabelPair } from 'react-querybuilder';
import { TimePeriodValue } from '../useRulesetValidator';
import SelectValueField from './SelectValueField';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { RulesetValueFieldProps } from './RulesetValueField';
import PickedDataWrapper from './PickedDataWrapper';

interface Props extends RulesetValueFieldProps {
    className: string | undefined;
    value: TimePeriodValue;
    onChange: (value: TimePeriodValue) => void;
    values: NameLabelPair[][];
    withOffset?: boolean;
}

const TimePeriodValueField: FunctionComponent<Props> = ({
    className,
    value,
    onChange,
    values,
    withOffset,
    drilldownEnabled
}) => {
    // State
    const [amount, setAmount] = useState<string>('');

    // Get debounced version of onChange so as to not trigger successive updates
    // while the user is typing.
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Handle amount value change
    const onAmountChange = useCallback(
        (val: string, opts?: { debounce: boolean }): void => {
            const newValueContent: TimePeriodValue = {
                ...value,
                amount: val ? +val : undefined,
                pickedCoordinateIndex: undefined
            };
            setAmount(val);
            opts?.debounce ? debouncedOnChange(newValueContent) : onChange(newValueContent);
        },
        [debouncedOnChange, onChange, value]
    );

    // Handle timePeriod value change
    const onTimePeriodChange = useCallback(
        (val: string | undefined): void => {
            const newValue: TimePeriodValue = {
                ...value,
                timePeriod: val as TimePeriodFilter
            };
            onChange(newValue);
        },
        [onChange, value]
    );

    // Handle offset value change
    const onOffsetChange = useCallback(
        (val: string | undefined): void => {
            const newValue: TimePeriodValue = {
                ...value,
                offset: val as OffsetFilter
            };
            onChange(newValue);
        },
        [onChange, value]
    );

    // Handle picked coordinate index selection
    const handlePickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange({
                ...value,
                amount: undefined,
                pickedCoordinateIndex: e == null ? undefined : e
            });
        },
        [onChange, value]
    );

    useEffect(() => {
        // We specifically check for undefined and not just truthiness,
        // because the amount is a number, and 0 is falsy while being an actual value
        setAmount(value?.amount != undefined ? `${value.amount}` : '');
    }, [value]);

    return (
        <>
            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={value?.pickedCoordinateIndex}
                onChange={handlePickedCoordinateIndexChange}
            >
                {/* Input `amount` */}
                <input
                    type="number"
                    value={amount}
                    placeholder="XX"
                    className={className}
                    onChange={e => onAmountChange(e.target.value, { debounce: true })}
                />
            </PickedDataWrapper>

            {/* TimePeriod */}
            <SelectValueField
                className={className}
                onChange={onTimePeriodChange}
                value={value?.timePeriod}
                values={values[0]}
            />

            {/* Offset (AGO / FROM_NOW) */}
            {withOffset && (
                <SelectValueField
                    className={className}
                    onChange={onOffsetChange}
                    value={value?.offset}
                    values={values[1]}
                />
            )}
        </>
    );
};

export default TimePeriodValueField;
