import { DEFAULT_DIMENSIONS, useExplorerReport } from 'api/viz/hooks/useExplorerReport';
import { WidgetDimensionField, WidgetLargeCard } from 'components/Dashboarding/Models/Widget';
import InboxCard from 'components/InboxCard';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';

interface Props {
    widget: WidgetLargeCard;
    onReloading: (reloading: boolean) => void;
    forcedMessageId?: string;
}

const LargeCardWidget: FunctionComponent<Props> = ({ widget, onReloading, forcedMessageId }: Props) => {
    // State
    const [dimensions, setDimensions] = useState<WidgetDimensionField[] | undefined>(undefined);

    // Get redux context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);
    const cardsContainerRef = useRef<HTMLDivElement>(null);

    // The sorting dimension
    const sortingDimension: WidgetDimensionField | undefined = useMemo(() => dimensions?.find(e => e.sort), [
        dimensions
    ]);

    // Scroll to the top when the sorting is changed
    useEffect(() => cardsContainerRef?.current?.scrollTo(0, 0), [sortingDimension]);

    // Fetch report
    const { initialLoading, loading, refreshing, normalized: reportItems } = useExplorerReport({
        fact: widget.config.fact,
        dimensions,
        filters: widget.config.series[0].filters,
        userId: user?.id,
        companyId: company?.id
    });

    // Update parent state when widget reloads
    useEffect(() => onReloading(loading || refreshing), [loading, onReloading, refreshing]);

    // Set the dimensions coming from the parent
    useEffect(() => setDimensions(widget.config.series[0].dimensions || DEFAULT_DIMENSIONS), [widget.config.series]);

    return (
        <div className="large-card text-dark d-flex justify-content-center h-100">
            {initialLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                <div ref={cardsContainerRef} className="overflow-y-auto h-100 w-100 p-3">
                    {forcedMessageId ? (
                        <div className="d-flex align-items-center justify-content-center h-100 text-grey-7 h1">
                            <FormattedMessage id={forcedMessageId} />
                        </div>
                    ) : reportItems.length ? (
                        reportItems.map(item => (
                            <InboxCard
                                key={item._system_id}
                                item={item}
                                sortingDimension={sortingDimension}
                                className="mb-4"
                            />
                        ))
                    ) : (
                        <div className="d-flex align-items-center justify-content-center h-100 text-grey-7 h1">
                            <FormattedMessage id="dashboarding.widget.no-data.title" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default LargeCardWidget;
