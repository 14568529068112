import { SeriesCardColumn, WidgetDimensionField, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { RulesetBuilder, useRulesetValidator } from 'components/RulesetBuilder';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PaletteColorPicker from 'components/Design/ColorPickers/PaletteColorPicker';
import { isEqual } from 'lodash';
import { FiltersRatio } from 'components/Dashboarding/FiltersRatio';
import WidgetSortSelector from '../WidgetSortSelector';

interface Props {
    colIndex: number;
    column: SeriesCardColumn;
    isOpen: boolean;
    onSave: (column: SeriesCardColumn) => void;
    toggle: () => void;
    widget: WidgetMiniCard;
}

// The filter box provides a light summary of the currently active
// fields and allows users to customize filters via a modal.
const ColumnEditModal: FunctionComponent<Props> = ({ colIndex, column, isOpen, onSave, toggle, widget }: Props) => {
    // State
    const [colName, setColName] = useState<string>(column.formatting?.label || '');
    const [colColor, setColColor] = useState<string | undefined>(column.formatting?.color);
    const [filters, setFilters] = useState<RuleGroupType | undefined>(column.filters);
    const [dimensions, setDimensions] = useState<WidgetDimensionField[] | undefined>(undefined);

    // Widget validation
    const isFiltersValid = useRulesetValidator({
        fact: widget.config.fact,
        ruleset: filters
    });
    const isChanged = useMemo(() => {
        return (
            colName != column.formatting?.label ||
            colColor != column.formatting?.color ||
            // TODO: fix the save button not disabled when changing back to the persisted value
            dimensions != column.dimensions ||
            !isEqual(filters, column.filters)
        );
    }, [
        colColor,
        colName,
        column.dimensions,
        column.filters,
        column.formatting?.color,
        column.formatting?.label,
        filters,
        dimensions
    ]);

    // Reset filters when modal gets closed
    const cancelEdit = useCallback(() => {
        toggle();
        setColName(column.formatting?.label || '');
        setFilters(column.filters);
    }, [toggle, column.formatting?.label, column.filters]);

    // Save filters on widget
    const saveEdit = useCallback(() => {
        onSave({
            ...column,
            dimensions,
            filters: filters,
            formatting: { ...column.formatting, color: colColor, label: colName }
        });
    }, [onSave, column, dimensions, filters, colColor, colName]);

    // Change temporary filters if modal is open
    // This prevents unwanted changes from RulesetBuilder on closing the modal (e.g. when resetting
    // the reset on cancel)
    const onFilterChange = useCallback((e: RuleGroupType) => isOpen && setFilters(e), [isOpen]);

    // Set the default dimensions
    useEffect(() => setDimensions(column?.dimensions), [column?.dimensions]);

    // Render
    return (
        <Modal show={isOpen} onHide={cancelEdit} size="xl" centered animation={false}>
            {/* Modal header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    <RichMessage
                        id="dashboarding.widget-configuration.axis.MINICARD.column.*"
                        values={{ index: colIndex + 1 }}
                    />
                    : {colName}
                </Modal.Title>
            </Modal.Header>

            {/* Modal body */}
            <Modal.Body className="vh-50 overflow-auto">
                {/* Column name */}
                <Form.Group controlId="columnName" className="mb-4">
                    <Form.Label>
                        <RichMessage id="dashboarding.widget-editor.configure-columns.form.name" />
                    </Form.Label>

                    <div className="d-flex">
                        <PaletteColorPicker color={colColor} onChange={setColColor} className="me-2" />
                        <Form.Control value={colName} onChange={e => setColName(e.target.value)} className="me-2" />

                        <WidgetSortSelector
                            fact={widget.config.fact}
                            onChange={setDimensions}
                            dimensions={dimensions}
                        />
                    </div>
                </Form.Group>

                {/* Column filters */}
                <div>
                    <Form.Label>
                        <RichMessage id="dashboarding.widget-editor.configure-columns.form.filters" />
                    </Form.Label>

                    {/* Error */}
                    {!isFiltersValid && (
                        <small className="mt-0 mb-2 ps-2 text-danger">
                            <RichMessage id="dashboarding.widget-editor.configure-columns.form.filters-error" />
                        </small>
                    )}

                    {/* Filters ratio */}
                    <h6 className="fw-semibold mb-4">
                        <FiltersRatio fact={widget.config.fact} filters={filters} />
                    </h6>

                    {/* Interactive builder */}
                    <RulesetBuilder
                        fact={widget.config.fact}
                        scope="query"
                        ruleset={filters}
                        onChange={onFilterChange}
                    />
                </div>
            </Modal.Body>

            {/* Apply or discard changes */}
            <Modal.Footer className="justify-content-center">
                <div className="d-flex justify-content-center align-items-center w-75">
                    <Button variant="outline-dark" className="w-50 justify-content-center me-2" onClick={cancelEdit}>
                        <RichMessage id="dashboarding.widget-editor.configure-columns.form.cancel" />
                    </Button>
                    <Button
                        variant="dark"
                        className="w-50 justify-content-center"
                        onClick={saveEdit}
                        disabled={!isFiltersValid || !isChanged}
                    >
                        <RichMessage id="dashboarding.widget-editor.configure-columns.form.save" />
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ColumnEditModal;
