import { useMutation } from '@apollo/react-hooks';
import { useCompany } from 'api/hq/hooks/useCompany';
import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import { UpdateCompanyResp, UPDATE_COMPANY } from 'api/hq/queries/Company';
import { UpdateProjectResp, UPDATE_PROJECT } from 'api/hq/queries/Project';
import { MutationResp, SprintSettings } from 'api/hq/queries/Shared';
import FullPageLoader from 'components/Design/FullPageLoader';
import { ErrorFragment } from 'components/ErrorManagement';
import { HelpIcon, useRichIntl } from 'components/RichMessage';
import {
    PROJECT_ACTIVE_STATUS,
    PROJECT_DISCONNECTED_STATUS,
    PROJECT_IMPORTING_STATUS,
    PROJECT_PENDING_STATUS
} from 'constants/defaultValues';
import React, { FunctionComponent, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import TeamManagementLayout from 'routes/settings/TeamManagementLayout';
import SprintSettingsForm from './SprintSettingsForm';
import SprintSettingsItem from './SprintSettingsItem';

const TeamSprintSettings: FunctionComponent = () => {
    // Services
    const intl = useRichIntl();

    // Get company
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Fetch/subscribe to company and active projects
    const { normalized: companyData, refetch: companyRefetch, loading: companyLoading, error } = useCompany({
        companyId: company?.id
    });
    const { normalized: projects, refetch: projectsRefetch, loading: projectsLoading } = useCompanyProjects({
        companyId: company?.id,
        statuses: [PROJECT_ACTIVE_STATUS, PROJECT_DISCONNECTED_STATUS, PROJECT_IMPORTING_STATUS, PROJECT_PENDING_STATUS]
    });

    // Declare update mutation
    const [updateCompany] = useMutation<UpdateCompanyResp>(UPDATE_COMPANY);
    const [updateProject] = useMutation<UpdateProjectResp>(UPDATE_PROJECT);

    // Infer state
    const canEdit = company?.role === 'ADMIN';

    // Return a save function for a specific project
    const handleProjectSave = useCallback(
        (id: string): ((e: SprintSettings | null) => Promise<MutationResp | undefined>) => {
            return async (sprintSettings: SprintSettings | null): Promise<MutationResp | undefined> => {
                try {
                    // Update team
                    const resp = await updateProject({
                        variables: {
                            id: id,
                            sprintSettings: sprintSettings
                        }
                    });
                    const updateResp = resp.data?.updateProject;

                    if (updateResp?.success) await projectsRefetch();
                    return updateResp;
                } catch (e) {
                    return { success: false, errors: [{ code: 'default', path: '', message: e as string }] };
                }
            };
        },
        [projectsRefetch, updateProject]
    );

    // Save function for company
    const handleTeamSave = useCallback(
        async (sprintSettings: SprintSettings | null): Promise<MutationResp | undefined> => {
            try {
                // Update team
                const resp = await updateCompany({
                    variables: {
                        id: companyData?.id,
                        sprintSettings: sprintSettings
                    }
                });
                const updateResp = resp.data?.updateCompany;

                if (updateResp?.success) {
                    await companyRefetch();
                    await projectsRefetch();
                }
                return updateResp;
            } catch (e) {
                return { success: false, errors: [{ code: 'default', path: '', message: e as string }] };
            }
        },
        [companyData?.id, companyRefetch, projectsRefetch, updateCompany]
    );

    // Loading screen
    if (companyLoading || projectsLoading || !companyData || !company) {
        return (
            <TeamManagementLayout id="agile-settings">
                <FullPageLoader vh={75} />
            </TeamManagementLayout>
        );
    }

    // Handle fetch errors
    if (error) {
        return <ErrorFragment error={{ code: 'fetch-team', namespace: 'team-management' }} />;
    }

    return (
        <TeamManagementLayout id="agile-settings">
            {/* Team settings (default settings) */}
            <div className="mt-4 mb-5">
                {/* Header */}
                <h5 className="text-truncate mb-4">
                    <span className="fw-bold me-2 text-dark">
                        <FormattedMessage id="settings.team-management.team-sprint-settings.title" />
                    </span>
                    <HelpIcon
                        size="xs"
                        className="text-muted"
                        id="settings.team-management.team-sprint-settings.help-info"
                    />
                </h5>
            </div>

            {/* Project-specific settings */}
            <Accordion>
                <Accordion.Item eventKey="-">
                    <Accordion.Header className="bg-transparent rounded">
                        <div className="w-100 py-4 px-5">
                            <SprintSettingsItem
                                name={intl.formatRichMessage({
                                    id: 'settings.team-management.team-sprint-settings.default-settings'
                                })}
                                company={companyData}
                                sprintSettings={companyData?.sprintSettings}
                            />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <SprintSettingsForm
                            sprintSettings={companyData?.sprintSettings}
                            onSubmit={handleTeamSave}
                            canEdit={canEdit}
                        />
                    </Accordion.Body>
                </Accordion.Item>

                {projects.map((project, index) => (
                    <Accordion.Item key={project.id} eventKey={index.toString()}>
                        <Accordion.Header className="bg-transparent rounded">
                            <div className="w-100 py-4 px-5">
                                <SprintSettingsItem
                                    name={project.name}
                                    company={companyData}
                                    sprintSettings={project.sprintSettings}
                                    showBadge={true}
                                    displayOpts={{
                                        app: project.app.provider,
                                        appName: project.app.name,
                                        ownerType: project.account.accountType,
                                        ownerName: project.account.name
                                    }}
                                />
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SprintSettingsForm
                                sprintSettings={project.sprintSettings}
                                onSubmit={handleProjectSave(project.id)}
                                canEdit={canEdit}
                                hasReset
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </TeamManagementLayout>
    );
};

export default TeamSprintSettings;
