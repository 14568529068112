import React, { FunctionComponent, ReactNodeArray } from 'react';
import { QueryError } from '.';
import { useRichIntl } from 'components/RichMessage';

interface QueryErrorFormatter {
    format: (arg0: QueryError) => string | ReactNodeArray | undefined;
}

export function useQueryErrorFormatter(): QueryErrorFormatter {
    const intl = useRichIntl();

    // Get the human friendly error message for the specified error code or
    // fallback on a specific message id.
    const format = (error: QueryError): string | ReactNodeArray | undefined => {
        // Get error message key
        const fullNamespace = `errors.${error.namespace}`;

        // Message Ids
        const msgId = `${fullNamespace}.${error.code}`;
        const defaultMsgId = `${fullNamespace}.default`;

        // Display specific or generic message based on key existence
        if (intl.messages[msgId]) {
            return intl.formatRichMessage({ id: msgId }, error.context);
        } else if (error.sanitizedMessage) {
            return error.sanitizedMessage;
        } else if (intl.messages[defaultMsgId]) {
            return intl.formatRichMessage({ id: `${fullNamespace}.default` });
        } else {
            return intl.formatRichMessage({ id: `errors.generic.default` });
        }
    };

    return { format };
}

interface ErrorMessageProps {
    className?: string;
    error?: Partial<QueryError>;
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ className, error }: ErrorMessageProps) => {
    const formatter = useQueryErrorFormatter();

    if (!error) return null;

    // Set the error values
    const { context = {}, namespace = 'generic', code = 'default', ...restError } = error;
    const formattedError = {
        namespace,
        context,
        code,
        ...restError
    };

    // Render the formatted error message
    return <span className={className}>{formatter.format(formattedError)}</span>;
};
