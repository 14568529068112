import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, useCallback, useRef, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu';
import { useIntl } from 'react-intl';

interface Props extends DropdownMenuProps {
    handleSearch: (str: string) => void;
    isSearchable: boolean;
    toggle: boolean;
}
const NavigableDropdownMenu: ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> = forwardRef(
    ({ children, isSearchable, handleSearch, toggle, className, ...props }: Props, ref) => {
        // State
        const [isFocused, setIsFocused] = useState<boolean>(false);
        const [value, setValue] = useState<string>('');

        // Services
        const intl = useIntl();
        const inputSearchRef = useRef<HTMLInputElement>(null);

        // handle search bar when the value changed
        const handleChange = useCallback(
            (value: string) => {
                setValue(value);
                handleSearch(value);
            },
            [handleSearch]
        );

        // The input focused once the dropdown opened
        React.useEffect(() => {
            if (inputSearchRef.current && toggle) {
                inputSearchRef.current.focus();
                handleChange('');
            }
        }, [handleChange, toggle]);

        return (
            <Dropdown.Menu className={classNames('navigable-dropdown-menu', className)} {...props}>
                {/* Search Input */}
                {isSearchable ? (
                    <div className="bg-white p-2 px-4" style={{ position: 'sticky', top: 0 }}>
                        <InputGroup className={classNames('transparent-addons', { focus: isFocused })}>
                            {/* Search icon */}
                            <InputGroup.Text className={classNames('ps-3 py-1', { 'text-primary': isFocused })}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>

                            {/* Input */}
                            <Form.Control
                                autoComplete="off"
                                placeholder={intl.messages['menu.search'] as string}
                                autoFocus
                                ref={inputSearchRef}
                                value={value}
                                onChange={e => handleChange(e.target.value)}
                                className="border-start-0 py-1"
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                            />
                        </InputGroup>
                    </div>
                ) : (
                    <div />
                )}

                {/* Children */}
                <div ref={ref}>{children}</div>
            </Dropdown.Menu>
        );
    }
);

export default NavigableDropdownMenu;
