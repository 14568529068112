import React, { FunctionComponent } from 'react';
import { RichMessage } from 'components/RichMessage';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearchParams } from 'react-router-dom';
import {
    EXTERNAL_LINK_REQUEST_A_DEMO,
    ONBOARDING1_PATH,
    ONBOARDING1_STEP1,
    ONBOARDING_SKIPPED_STEP_KEY
} from 'constants/routeBuilders';

const BACK_STEP_PATH = '/flows/onboarding/configure-dashboard/template-selection';

const Wall: FunctionComponent = () => {
    // Extract params
    const [searchParams] = useSearchParams();
    const skippedStep = searchParams.get(ONBOARDING_SKIPPED_STEP_KEY);

    return (
        <div className="vh-100 vw-100 d-flex flex-column overflow-y-auto">
            {/* Header */}
            <div className="splitscreen-flow-header flex-grow-0 p-4">
                <KeypupLogoFull />
            </div>

            {/* Content */}
            <div className="flex-grow-1">
                <div
                    className="d-flex flex-column h-100 align-items-center justify-content-center m-auto p-4"
                    style={{ maxWidth: '992px' }}
                >
                    {/* Title */}
                    <div className="d-flex align-self-baseline mt-4 mb-5">
                        <Button
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            as={Link as any}
                            to={`${BACK_STEP_PATH}?${ONBOARDING_SKIPPED_STEP_KEY}=${skippedStep}`}
                            variant="link"
                            className="p-0"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                        </Button>
                        <h1 className="fw-bolder">
                            <RichMessage id="flows.onboarding.wall.title" />
                        </h1>
                    </div>

                    {/* Iframe */}
                    <div
                        className="position-relative w-100 mb-5"
                        style={{ aspectRatio: '1.83', boxShadow: '0px 0px 206px -75px rgba(11, 12, 74, 0.53)' }}
                    >
                        <iframe
                            src="https://demo.arcade.software/mwQF0fjRoe3sEy6huhL1?embed"
                            frameBorder="0"
                            loading="lazy"
                            allowFullScreen
                            className="position-absolute"
                            style={{ top: 0, left: 0, width: '100%', height: '100%' }}
                        ></iframe>
                    </div>

                    {/* Action buttons */}
                    <Row className="w-100 mb-4" xs={1} sm={2}>
                        <Col className="px-0">
                            {/* Connect projects */}
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                variant="dark"
                                to={`${ONBOARDING1_PATH}/${ONBOARDING1_STEP1}`}
                                className="justify-content-center mb-2 me-sm-2"
                            >
                                <RichMessage id="flows.onboarding.wall.actions.connect-projects" />
                            </Button>
                        </Col>
                        <Col className="px-0">
                            {/* Book a demo */}
                            <Button
                                variant="light"
                                href={EXTERNAL_LINK_REQUEST_A_DEMO}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="justify-content-center mb-2 ms-sm-2"
                            >
                                <RichMessage id="flows.onboarding.wall.actions.book-demo" />
                            </Button>
                        </Col>
                    </Row>

                    {/* Info */}
                    <Alert className="px-5 mb-5 border-0 text-primary rounded-4" style={{ backgroundColor: '#eff5fe' }}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="position-absolute my-4 mx-3"
                            size="lg"
                            style={{ top: 0, left: 0 }}
                        />
                        <h5 className="my-2 fw-500">
                            <RichMessage id="flows.onboarding.project-import.info.title" />
                        </h5>
                        <div className="text-dark" style={{ opacity: 0.8, textAlign: 'justify' }}>
                            <RichMessage id="flows.onboarding.project-import.info.content" />
                        </div>
                    </Alert>
                </div>
            </div>
        </div>
    );
};

export default Wall;
