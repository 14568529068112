import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { APP_IMAGES } from 'constants/defaultValues';
import { ErrorMessage } from 'components/ErrorManagement';
import { AppResp } from 'api/hq/queries/App';
import { GetIdentitiesResp, GET_USER_IDENTITIES_BY_PROVIDER } from 'api/hq/queries/Identity';
import classnames from 'classnames';
import { GetIntegrationsResp, GET_INTEGRATIONS_BY_APP } from 'api/hq/queries/Integration';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import IdentityConnectionStatus from './IdentityConnectionStatus';
import IdentityActionButton from './IdentityActionButton';
import IdentityAddInstance from './IdentityAddInstanceBtn';
import Spinner from 'react-bootstrap/Spinner';
import AppIcon from 'components/Design/AppIcon';

interface Props {
    app: AppResp;
    skipAddInstance?: boolean;
}

const IdentityItem: FunctionComponent<Props> = ({ app, skipAddInstance }: Props) => {
    // Global state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve user identity for this provider
    const { data: identityData, loading: identityLoading, refetch: identityRefetch, error: identityError } = useQuery<
        GetIdentitiesResp
    >(GET_USER_IDENTITIES_BY_PROVIDER, {
        variables: { userId: user?.id, provider: app.provider }
    });

    // Get the integration - if any - currently setup on the company
    const { data: integrationData, loading: integrationLoading, error: integrationError } = useQuery<
        GetIntegrationsResp
    >(GET_INTEGRATIONS_BY_APP, {
        variables: { companyId: company?.id, appId: app.id }
    });

    // Show row as loading
    if (identityLoading || integrationLoading) {
        return (
            <tr>
                <td colSpan={3} className="text-center">
                    <Spinner animation="border" variant="dark" />
                </td>
            </tr>
        );
    }

    // Handle error
    if (identityError || integrationError) {
        const code = identityError ? 'fetch-identity' : 'fetch-integration';
        return (
            <tr>
                <td colSpan={3}>
                    <ErrorMessage error={{ code: code, namespace: 'fetch-user-account' }} />
                </td>
            </tr>
        );
    }

    // Extract component data
    const appImg = APP_IMAGES[app.provider];
    const identity = identityData?.identities.nodes[0];
    const integration = integrationData?.integrations.nodes[0];
    const identityNotRequired = !identity && !integration;

    // Render row
    return (
        <tr>
            {/* App name */}
            <td className="w-20">
                <div className={classnames('d-flex', 'align-items-center', { 'text-grey-4': identityNotRequired })}>
                    <AppIcon src={appImg} alt={app.name} className="me-3" />
                    <span>{app.name}</span>
                </div>
            </td>

            {/* Connection status */}
            <td className="w-60">
                <IdentityConnectionStatus identity={identity} notRequired={identityNotRequired} />
            </td>

            {/* Action: Connect/Disconnect */}
            <td className="w-20">
                <div className="d-flex">
                    {/* Main action */}
                    <IdentityActionButton
                        integration={integration}
                        identity={identity}
                        app={app}
                        onDisconnect={identityRefetch}
                        className="w-75 justify-content-center"
                    />

                    {/* Add instance */}
                    {skipAddInstance || (
                        <IdentityAddInstance integration={integration} identity={identity} app={app} className="ms-1" />
                    )}
                </div>
            </td>
        </tr>
    );
};

export default IdentityItem;
