import { WidgetDimensionField } from 'components/Dashboarding/Models/Widget';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormatDimensions } from 'api/viz/hooks/useFormatDimension';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { WidgetFieldSortDirection } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

interface Props {
    children?: ReactNode | ReactNodeArray;
    onChange: (dimensions: WidgetDimensionField[]) => void;
    dimensions?: WidgetDimensionField[];
    fact: QueryOpFact;
}

// Sort direction constants
const SORT_DIRECTIONS: WidgetFieldSortDirection[] = ['ASC', 'DESC'];

// A dropdown for MiniCard and LargeCard insights to sort by a certain field
const WidgetSortSelector: FunctionComponent<Props> = ({ onChange, fact, dimensions, children }) => {
    // Servic
    const intl = useIntl();

    // Sort fields
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: 'sort' });

    // Dimensions formatting
    const [formattedDimensions, { handleSortBy }] = useFormatDimensions({ dimensions, onChange });

    // Format the label of the sorting dimension.
    const formatLabel = useCallback(
        (sortField?: WidgetDimensionField) => {
            if (!sortField) return intl.formatMessage({ id: 'generic.sort' });

            return intl.formatMessage(
                {
                    id: `dashboarding.widget-editor.sort-selector.sort.${sortField?.sort?.toLowerCase()}`
                },
                { field: queryOpFields.find(e => e.id == sortField?.ref)?.label }
            );
        },
        [intl, queryOpFields]
    );

    // The field the user sorted the items with
    const sortedField = useMemo(() => formattedDimensions.find(e => e.sort), [formattedDimensions]);

    // Generate the label for the sort field
    const sortedFieldLabel = useMemo(() => formatLabel(sortedField), [formatLabel, sortedField]);

    return (
        <Dropdown>
            {children || (
                <Dropdown.Toggle
                    variant="outline-light"
                    role="button"
                    className="d-flex justify-content-between text-dark"
                >
                    <FontAwesomeIcon icon={faSort} className="me-2" />
                    <span className="me-2">{sortedFieldLabel}</span>
                </Dropdown.Toggle>
            )}
            <Dropdown.Menu
                align="end"
                flip
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
            >
                {queryOpFields.map(e =>
                    SORT_DIRECTIONS.map((sort, index) => (
                        <div key={index}>
                            <Dropdown.Item
                                onClick={() => handleSortBy({ field: e.id, sort })}
                                className={classNames({
                                    'fw-bold': sortedField?.ref == e.id && sortedField?.sort == sort
                                })}
                            >
                                <RichMessage
                                    id={`dashboarding.widget-editor.sort-selector.sort.${sort.toLowerCase()}`}
                                    values={{ field: e.label }}
                                />
                            </Dropdown.Item>
                        </div>
                    ))
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default WidgetSortSelector;
