import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import { To } from 'react-router-dom';
import { pick } from 'lodash';
import { WIDGET_CREATE_ATTRS, WIDGET_UPDATE_ATTRS } from 'api/hq/queries/Widget';

// General
export const HELP_CENTER_URL = 'https://help.keypup.io';
export const LOGIN_REDIRECTION_PATH = '/auth/auth0';
export const LOGOUT_REDIRECTION_PATH = '/auth/logout';
export const COMPANY_DISABLED_PATH = '/flows/company-disabled';

// Board Management
export const BOARD_BASE_PATH = '/app/reports/board';
export const BOARD_VIEW_PATH = '/app/reports/board/view';
export const BOARD_EDIT_PATH = '/app/reports/board/edit';

// General settings
export const COMPANY_SETTINGS_PATH = '/app/settings/company';

// Account settings
export const USER_ACCOUNT_PATH = '/app/settings/user-account';
export const SUBSCRIPTION_PATH = '/app/settings/subscription';
export const INVOICES_PATH = '/app/settings/invoices';

// Team management
export const TEAM_MANAGEMENT_PATH = '/app/settings/team';
export const TEAM_SPRINT_SETTINGS_PATH = '/app/settings/sprint';

// App Management
export const APP_MANAGEMENT_PATH = '/app/settings/app-management';
export const APP_MANAGEMENT_APPS_PATH = '/app/settings/app-management/apps';
export const APP_MANAGEMENT_PROJECTS_PATH = '/app/settings/app-management/projects';
const APP_CONNECTION_BASE_PATH = '/auth/auth0';
const IDENTITY_CONNECTION_BASE_PATH = '/auth/auth0';

// --------------------------------------------
// Onboarding
// --------------------------------------------
// Onboarding
export const ONBOARDING1_PATH = '/flows/onboarding';
export const ONBOARDING1_STEP0_A = 'company-creation';
export const ONBOARDING1_STEP0_B = 'whats-next';
export const ONBOARDING1_STEP1 = 'app-selection';
export const ONBOARDING1_STEP2 = 'project-import';
export const ONBOARDING1_STEP3 = 'configure-dashboard';
export const ONBOARDING1_STEP3_A = 'template-selection';
export const ONBOARDING1_STEP3_B = 'template-customization';
export const ONBOARDING1_WALL = 'wall';
export const ONBOARDING1_STEPS = [ONBOARDING1_STEP1, ONBOARDING1_STEP2, ONBOARDING1_STEP3];
export const EXTERNAL_LINK_REQUEST_A_DEMO = 'https://www.keypup.io/request-a-demo';
export const ONBOARDING_SKIPPED_STEP_KEY = 'skipped-step';

// Onboarding variants
export const ONBOARDING_VARIANTS: { name: string; path: string }[] = [
    {
        name: 'full-tour',
        path: ONBOARDING1_PATH
    }
];

export const onboardingTemplateSelectionNextPath = (skippedStep: string | null, templateId: string): To => {
    // Add query param dshb-tmpl-id
    const queryAttrs = new URLSearchParams();
    queryAttrs.set(DASHBOARD_TMPL_ID_KEY, templateId);

    // Return built path
    // If a step has been skipped, redirect to the wall
    if (skippedStep) {
        // Keep passing skippedStep in the search params.
        // Also pass the templateId because later, the arcade demo will be depending on this param
        queryAttrs.set(ONBOARDING_SKIPPED_STEP_KEY, skippedStep);

        return {
            pathname: `${ONBOARDING1_PATH}/${ONBOARDING1_WALL}`,
            search: queryAttrs.toString()
        };
    }

    // Else, redirect to the template customization
    return {
        pathname: `${ONBOARDING1_PATH}/${ONBOARDING1_STEP3}/${ONBOARDING1_STEP3_B}`,
        search: queryAttrs.toString()
    };
};

// --------------------------------------------
// Dashboards
// --------------------------------------------
export const WIDGET_BUILDER_PARAM_WIDGET = 'widget';
export const WIDGET_BUILDER_PARAM_STEP = 'step';
export const DASHBOARD_ROOT_PATH = '/app/reports/dashboards';
export const DASHBOARD_EDIT_MODE_PARAM = 'edit';
export const DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM = 'add-widget-modal-open';
export const NEW_DASHBOARD_MODAL_OPEN_PARAM = 'new-dashboard-modal-open';
export const REDIRECT_TO_PARAM = 'redirect-to';
export const WIDGET_EDIT_ROOT_PATH = '/app/reports/widgets/edit';
export const WIDGET_ASSISTANT_PATH = '/widgets/assistant';
export const WIDGET_ASSISTANT_STEP1 = 'configure';
export const WIDGET_ASSISTANT_STEP2 = 'document';
export const WIDGET_ASSISTANT_STEP3 = 'review';
export const WIDGET_ASSISTANT_STEPS = [WIDGET_ASSISTANT_STEP1, WIDGET_ASSISTANT_STEP2, WIDGET_ASSISTANT_STEP3] as const;
type WidgetAssistantStepType = typeof WIDGET_ASSISTANT_STEPS[number];

export const dashboardViewPath = ({
    dashboardId,
    edit,
    redirectTo,
    addWidgetModalOpen
}: {
    dashboardId: string;
    edit?: boolean;
    redirectTo?: string;
    addWidgetModalOpen?: boolean;
}): To => {
    const queryAttrs = new URLSearchParams();

    // Add query param `edit` if specified
    if (edit) {
        queryAttrs.set(DASHBOARD_EDIT_MODE_PARAM, edit.toString());
    }

    // Add query param `redirect-to` if specified
    if (redirectTo) {
        queryAttrs.set(REDIRECT_TO_PARAM, redirectTo);
    }

    // Add query param `add-widget-modal-open` if specified
    if (addWidgetModalOpen) {
        queryAttrs.set(DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM, 'true');
    }

    // Return built path
    return {
        pathname: `${DASHBOARD_ROOT_PATH}/${dashboardId}`,
        search: queryAttrs.toString()
    };
};

export const dashboardAssistantPath = ({ templateId }: { templateId: string }): To => {
    // Add query param template id
    const queryAttrs = new URLSearchParams();
    queryAttrs.set(DASHBOARD_TMPL_ID_KEY, templateId);

    // Return built path
    return {
        pathname: `${DASHBOARD_ROOT_PATH}/assistant`,
        search: queryAttrs.toString()
    };
};

export const dashboardWidgetNewPath = ({
    dashboardId,
    template
}: {
    dashboardId: string;
    template?: WidgetFactType;
}): To => {
    const queryAttrs = new URLSearchParams();

    // Add series type if specified
    if (template) queryAttrs.set(WIDGET_BUILDER_PARAM_WIDGET, JSON.stringify(pick(template, WIDGET_CREATE_ATTRS)));

    // Return built path
    return { pathname: `${DASHBOARD_ROOT_PATH}/${dashboardId}/widgets/edit`, search: queryAttrs.toString() };
};

export const widgetEditPath = ({
    dashboardId,
    widget,
    step,
    redirectTo
}: {
    dashboardId?: string;
    widget?: WidgetFactType;
    step?: string;
    redirectTo?: string;
}): To => {
    const queryAttrs = new URLSearchParams();

    // Add widget config if specified
    if (widget) {
        const widgetAttrs = widget.id ? WIDGET_UPDATE_ATTRS : WIDGET_CREATE_ATTRS;

        queryAttrs.set(WIDGET_BUILDER_PARAM_WIDGET, JSON.stringify(pick(widget, widgetAttrs)));
    }

    // Add step config if specified
    if (step) {
        queryAttrs.set(WIDGET_BUILDER_PARAM_STEP, step);
    }

    // Add query param redirectTo if specified
    if (redirectTo) queryAttrs.set(REDIRECT_TO_PARAM, redirectTo);

    // Get base path
    const basePath = dashboardId ? `${DASHBOARD_ROOT_PATH}/${dashboardId}/widgets/edit` : '/app/reports/widgets/edit';

    // Return built path
    return {
        pathname: basePath,
        search: queryAttrs.toString()
    };
};

export const widgetAssistantStepPath = (
    step: WidgetAssistantStepType,
    templateId: string | null,
    dashboardId?: string
): To => {
    const queryAttrs = new URLSearchParams();

    // If specified, add template id search param
    if (templateId) queryAttrs.set(WIDGET_TMPL_ID_KEY, templateId);

    // Build base path
    const basePath = dashboardId
        ? `${DASHBOARD_ROOT_PATH}/${dashboardId}/widgets/assistant/${step}`
        : `/app/reports/widgets/assistant/${step}`;

    // Return built path
    return {
        pathname: basePath,
        search: queryAttrs.toString()
    };
};

// --------------------------------------------
// Collections
// --------------------------------------------
export const COLLECTIONS_ROOT_PATH = '/app/collections';

// --------------------------------------------
// Dashboard templates
// --------------------------------------------
export const DASHBOARD_TEMPLATE_ROOT_PATH = '/flows/add-dashboard-template';

// Search param key used when a full template description is provided
// in JSON format
export const DASHBOARD_TMPL_KEY = 'dhb-tmpl';

// Search param key used when a reference to a default template is
// provided (see DashboardTemplates.ts and WidgetTemplates.ts)
export const DASHBOARD_TMPL_ID_KEY = 'dhb-tmpl-id';
export const WIDGET_TMPL_ID_KEY = 'wdg-tmpl-id';

// Relative template path to the provided dashboard template
export const addDashboardTemplatePath = ({ template }: { template: DashboardTemplate }): string => {
    const queryAttrs = new URLSearchParams();
    queryAttrs.set(DASHBOARD_TMPL_KEY, JSON.stringify(template));

    return `${DASHBOARD_TEMPLATE_ROOT_PATH}?${queryAttrs.toString()}`;
};

// --------------------------------------------
// App & Identities
// --------------------------------------------
// App Connection path function
interface AppConnectionPathProps {
    provider: string;
    companyId: string;
    returnTo?: string;
    reconnect?: boolean;
}
export const appConnectionPath = ({ provider, companyId, returnTo, reconnect }: AppConnectionPathProps): string => {
    const params = [
        `connection=${provider}`,
        `connectTo=${companyId}`,
        returnTo ? `origin=${returnTo}` : null,
        reconnect ? `reconnect=${reconnect}` : null
    ].filter(e => e);

    return `${APP_CONNECTION_BASE_PATH}?${params.join('&')}`;
};

// Identity Connection path function
interface IdentityConnectionPathProps {
    provider: string;
    returnTo?: string;
    connectionScope?: string;
    reconnect?: boolean;
}
export const identityConnectionPath = ({
    provider,
    returnTo,
    connectionScope,
    reconnect
}: IdentityConnectionPathProps): string => {
    const params = [
        'linkUser=true',
        `connection=${provider}`,
        returnTo ? `origin=${returnTo}` : null,
        reconnect ? `reconnect=${reconnect}` : null,
        connectionScope ? `connection_scope="${connectionScope}"` : null
    ].filter(e => e);

    return `${IDENTITY_CONNECTION_BASE_PATH}?${params.join('&')}`;
};

// Open a link to a new tab programatically
export const openInANewTab = (url: string): void => {
    const openInNewTabTag = document.createElement('a');
    openInNewTabTag.setAttribute('href', url);
    openInNewTabTag.setAttribute('target', '_blank');
    openInNewTabTag.setAttribute('rel', 'noopener noreferrer');
    openInNewTabTag.click();
    document.body.removeChild(openInNewTabTag);
};

// --------------------------------------------
// Default
// --------------------------------------------
// Default path on app load
export const DEFAULT_START_PATH = DASHBOARD_ROOT_PATH;
