import { GetInboxItemsResp, GET_INBOX_ITEMS, InboxItem } from '../queries/InboxItem';
import { UseReportResult, useReport } from './useReport';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';

interface GetInboxItemQueryResults extends UseReportResult<GetInboxItemsResp> {
    normalized?: InboxItem;
}

interface UseGetInboxItemOpts {
    systemId?: string;
    skip?: boolean | null;
    noSubscribe?: boolean;
    cacheDuration?: number;
}

// Retrieve an inbox item by ID
export function useGetInboxItem({
    systemId,
    skip,
    noSubscribe,
    cacheDuration
}: UseGetInboxItemOpts): GetInboxItemQueryResults {
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Fetch items
    const rs = useReport<GetInboxItemsResp>(GET_INBOX_ITEMS, {
        skip: skip || !systemId || !company?.id,
        noSubscribe: noSubscribe,
        variables: {
            companyId: company?.id,
            filters: [
                {
                    function: 'EQUAL',
                    args: [{ field: '_system_id' }, { string: systemId }]
                }
            ],
            cacheDuration: cacheDuration
        }
    });

    // Get normalized version
    const normalized = (rs.data?.report.results.queriedItems || [])[0];

    return {
        ...rs,
        normalized: normalized
    };
}
