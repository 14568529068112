import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDashboardWithWidgets } from 'api/hq/hooks/useDashboardWithWidgets';
import { merge, omit } from 'lodash';
import { DashboardTemplate, DashboardTemplateWidget } from 'components/Dashboarding/Models/DashboardTemplates';
import { WidgetType } from 'components/Dashboarding/Models/Widget';
import { useLocation } from 'react-use';
import { addDashboardTemplatePath } from 'constants/routeBuilders';
import CopyToClipboard from 'components/Design/CopyToClipboard';
import FullPageLoader from 'components/Design/FullPageLoader';

// Wrapper component to view and edit dashboards
const DashboardAsJsonTemplate: FunctionComponent = () => {
    // Services
    const params = useParams() as { dashboardId: string };
    const location = useLocation();

    // Route params
    const dashboardId = params.dashboardId;

    // Load dashboard
    const { normalized: dashboard, loading: dashboardLoading } = useDashboardWithWidgets({ id: dashboardId });

    // Format dashboard as template to be used in DashboardTemplates.ts
    const dashboardTemplate: DashboardTemplate | undefined = useMemo(() => {
        if (!dashboard) return undefined;

        const layoutWithWidgets: DashboardTemplateWidget[] = dashboard.layout.map(e => {
            const widget = dashboard.widgets.nodes.find(w => w.id == e.i) as WidgetType;
            return { ...e, i: '-', widget: { ...widget, id: '-' } };
        });

        return merge({}, { ...omit(dashboard, 'widgets'), id: '-', layout: layoutWithWidgets });
    }, [dashboard]);

    // Pre-formatted JSON
    const copyJsonContent = useMemo(() => {
        if (!dashboardTemplate) return;
        return JSON.stringify(dashboardTemplate, null, 2);
    }, [dashboardTemplate]);

    // URL to this template
    const copyLinkContent = useMemo(() => {
        if (!dashboardTemplate) return;
        return `${location.origin}${addDashboardTemplatePath({ template: dashboardTemplate })}`;
    }, [dashboardTemplate, location.origin]);

    // Display loader
    if (dashboardLoading) return <FullPageLoader />;

    return (
        <>
            <div className="ps-5 pt-5">
                {/* Copy buttons */}
                <div className="mb-4 d-flex">
                    {copyJsonContent && (
                        <CopyToClipboard
                            className="me-2"
                            content={copyJsonContent}
                            labelId="dashboarding.resource-as-json-template.copy-json"
                        />
                    )}

                    {copyLinkContent && (
                        <CopyToClipboard
                            content={copyLinkContent}
                            labelId="dashboarding.resource-as-json-template.copy-link"
                        />
                    )}
                </div>

                {/* Preformatted JSON */}
                <pre className="bg-white">{JSON.stringify(dashboardTemplate || {}, null, 2)}</pre>
            </div>
        </>
    );
};

export default DashboardAsJsonTemplate;
