import React, { FunctionComponent } from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';

const AddGroupAction: FunctionComponent<ActionWithRulesProps> = ({ className, title, level, handleOnClick }) => {
    // level 0 => main rule group
    // level 1 => first nested rule group
    // level 2 => second nested rule groups
    // level 3 => third nested rule groups
    // => To limit the nested rule groups to 3, if the group's depth level is >= 3,
    // we do not display another `Add rule group` button
    if (level >= 3) return null;

    return (
        <button role="button" type="button" className={className} title={title} onClick={e => handleOnClick(e)}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            <RichMessage id="components.ruleset-builder.add-group-button.label" />
        </button>
    );
};

export default AddGroupAction;
