import gql from 'graphql-tag';
import { MutationResp } from './Shared';

export interface IdentityResp {
    id: string;
    provider: string;
    username: string;
}

export interface GetIdentitiesResp {
    identities: {
        nodes: IdentityResp[];
    };
}

export const GET_USER_IDENTITIES = gql`
    query($userId: ID!) {
        identities(userId: $userId) {
            nodes {
                id
                provider
                username
            }
        }
    }
`;

export const GET_USER_IDENTITIES_BY_PROVIDER = gql`
    query($userId: ID!, $provider: String!) {
        identities(userId: $userId, provider: $provider) {
            nodes {
                id
                provider
                username
            }
        }
    }
`;

export interface DeleteIdentityResp {
    deleteIdentity: MutationResp;
}

// Delete an identity (= disconnect a user identity)
export const DELETE_IDENTITY = gql`
    mutation($id: ID!) {
        deleteIdentity(input: { id: $id }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
