import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import {
    GetCompanyWidgetsResp,
    GET_COMPANY_WIDGETS,
    SUBSCRIBE_TO_WIDGET_CREATED,
    SUBSCRIBE_TO_WIDGET_DELETED,
    SUBSCRIBE_TO_WIDGET_UPDATED
} from '../queries/Widget';
import {
    ResourceRoleDeletedSubscription,
    ResourceRoleName,
    ResourceRoleUpsertedSubscription,
    SUBSCRIBE_TO_RESOURCE_ROLE_CREATED,
    SUBSCRIBE_TO_RESOURCE_ROLE_DELETED,
    SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED
} from '../queries/ResourceRole';

// The list of widget types considered as "decoration only". These types
// of widgets are excluded from the collections as they're not really
// the type of widgets to be shared or reused across dashboards.
export const DECORATOR_WIDGET_TYPES = ['HSEPARATOR', 'MARKDOWN', 'VSEPARATOR'];

interface QueryOpts {
    skip?: boolean;
    roles?: ResourceRoleName[];
    excludeDecorators?: boolean;
}

interface WidgetsQueryResults extends QueryResult<GetCompanyWidgetsResp, OperationVariables> {
    normalized: (WidgetFactType & { dashboards: { id: string; name: string }[] })[];
}

// TODO: we'll probably need to implement pagination soon enough.
// GraphQL collections are limited to 100 results on the HQ API.
export function useWidgets(opts?: QueryOpts): WidgetsQueryResults {
    // Get context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);
    const excludedConfigTypes = opts?.excludeDecorators && DECORATOR_WIDGET_TYPES;

    // Retrieve the widgets belonging to the current user/company
    const rs = useQuery<GetCompanyWidgetsResp>(GET_COMPANY_WIDGETS, {
        skip: opts?.skip || !company?.id,
        variables: { companyId: company?.id, currentUserRoles: opts?.roles, excludedConfigTypes: excludedConfigTypes }
    });

    // Subscribe to widget updates
    useSafeSubscription(SUBSCRIBE_TO_WIDGET_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to widget creation
    useSafeSubscription(SUBSCRIBE_TO_WIDGET_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to widget deletion
    useSafeSubscription(SUBSCRIBE_TO_WIDGET_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to role updates
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role creation
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role deletion
    useSafeSubscription<ResourceRoleDeletedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            // The subscription payload only contains the object ID. We cannot
            // check the Actor.
            rs.refetch();
        }
    });

    // Extract the list of widgets
    const normalized = useMemo(() => {
        const objList = rs.data?.widgets.nodes || [];
        return sortBy(
            objList
                .map(
                    e =>
                        omitDeep(e, '__typename') as WidgetFactType & {
                            dashboards: { nodes: { id: string; name: string }[] };
                        }
                )
                .map(e => ({ ...e, dashboards: e.dashboards?.nodes || [] })),
            'name'
        );
    }, [rs.data?.widgets.nodes]);

    return { ...rs, loading: rs.loading, normalized: normalized };
}
