import classNames from 'classnames';
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

interface Props extends DropdownItemProps {
    focusedValue: string | null;
    onHover: (value: string) => void;
    onFocusing: (value: string) => void;
    value: string;
}

const NavigableDropdownItem: ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> = forwardRef(
    ({ children, className, value, onHover, onFocusing, focusedValue, ...rest }: Props, ref) => (
        <Dropdown.Item
            ref={ref}
            className={classNames('navigable-dropdown-item', className, { focused: focusedValue == value })}
            {...rest}
            onMouseEnter={() => onHover(value)}
            onFocus={() => onFocusing(value)}
        >
            {children}
        </Dropdown.Item>
    )
);

export default NavigableDropdownItem;
