export const NOTIFICATION_TYPES = [
    'connect-apps',
    'connect-projects',
    'projects-importing',
    'projects-imported'
] as const;
export type NotificationType = typeof NOTIFICATION_TYPES[number];

export interface Notification {
    type: NotificationType;
    context?: {
        projectsImportCount?: number;
    };
}
