import { useCompanyMemberships } from 'api/hq/hooks/useCompanyMemberships';
import { ErrorFragment } from 'components/ErrorManagement';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { ReduxState } from 'redux/reducers';
import { useCompanyInvites } from 'api/hq/hooks/useCompanyInvites';
import TeamManagementLayout from 'routes/settings/TeamManagementLayout';
import { sortBy } from 'util/ArrayOperators';
import TeamInviteItem from './TeamInviteItem';
import TeamUserItem from './TeamUserItem';
import TeamSettings from './TeamSettings';
import TeamInviteModal from './TeamInviteModal';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import FullPageLoader from 'components/Design/FullPageLoader';

interface Props {
    currentTab?: string;
}

// TODO: pagination on member list
const TeamMemberList: FunctionComponent<Props> = ({ currentTab }: Props) => {
    // Get context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve memberships
    const {
        loading: membersLoading,
        refetch: refetchMembers,
        error: membersError,
        normalized: companyMembers
    } = useCompanyMemberships({ companyId: company?.id });

    // Retrieve pending invites
    const {
        loading: invitesLoading,
        refetch: refetchInvites,
        error: invitesError,
        normalized: companyInvites
    } = useCompanyInvites({ companyId: company?.id });

    // States
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    // Extract data from responses
    const memberships = useMemo(() => companyMembers.sort(sortBy('firstName')), [companyMembers]);
    const invites = useMemo(() => companyInvites.sort(sortBy('email')), [companyInvites]);

    // onSuccess callback for invite modal
    const onInviteSuccess = useCallback((): void => {
        refetchInvites();
    }, [refetchInvites]);

    // Callback invoked when invites are edited/deleted
    const onInviteChange = useCallback((): void => {
        refetchInvites();
    }, [refetchInvites]);

    // Callback invoked when memberships are edited/deleted
    const onMembershipChange = useCallback((): void => {
        refetchMembers();
    }, [refetchMembers]);

    // Display loading screen if the initial query is loading
    // or if the fetchMore query is still going through pagination
    if (membersLoading || invitesLoading || !company) {
        return (
            <TeamManagementLayout id="team-members">
                <FullPageLoader vh={75} />
            </TeamManagementLayout>
        );
    }

    // Handle fetch errors
    if (invitesError || membersError) {
        const code = membersError ? 'fetch-members' : 'fetch-invites';
        return <ErrorFragment error={{ code: code, namespace: 'team-management' }} />;
    }

    return (
        <TeamManagementLayout
            id="team-members"
            actions={
                company?.role === 'ADMIN' && (
                    <Button variant="dark" onClick={() => setModalOpen(true)} className="text-nowrap py-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <FormattedMessage id="settings.team-management.actions.invite-memeber" />
                    </Button>
                )
            }
        >
            {/* Invite user modal */}
            <TeamInviteModal
                companyId={company?.id}
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                onSuccess={onInviteSuccess}
            />

            {/* Team's name */}
            <div className="mb-4">
                <h5 className="mb-4 text-dark">
                    <RichMessage id="settings.team-management.title.team-name" />
                </h5>
                <TeamSettings company={company} />
            </div>

            <div>
                {/* Members list */}
                <h5 className="mb-4 text-dark">
                    <RichMessage id="settings.team-management.title.members-management" />
                </h5>

                <Table>
                    <thead>
                        <tr>
                            <th>
                                <RichMessage id="settings.team-management.members-list.thead.members-name" />
                            </th>

                            <th>
                                <RichMessage id="settings.team-management.members-list.thead.email" />
                            </th>

                            <th>
                                <RichMessage id="settings.team-management.members-list.thead.role" />
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Invites */}
                        {invites.map(invite => (
                            <tr key={invite.id} className="align-middle">
                                <TeamInviteItem
                                    invite={invite}
                                    enableActions={company.role === 'ADMIN'}
                                    onChange={onInviteChange}
                                />
                            </tr>
                        ))}

                        {/* Members */}
                        {memberships.map(membership => (
                            <tr key={membership.id} className="align-middle">
                                <TeamUserItem
                                    membership={membership}
                                    enableActions={company.role === 'ADMIN' && membership.user.id != user?.id}
                                    onChange={onMembershipChange}
                                    currentTab={currentTab}
                                />
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </TeamManagementLayout>
    );
};

export default TeamMemberList;
