import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import Card from 'react-bootstrap/Card';

interface Props {
    className?: string;
}

const InboxCardLoader: FunctionComponent<Props> = ({ className }: Props) => {
    return (
        <Card className={className}>
            <Card.Body className="ps-2 pt-2">
                <ContentLoader
                    speed={2}
                    width={600}
                    height={150}
                    viewBox="0 0 600 150"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#d4d4d4"
                >
                    <circle cx="18" cy="18" r="18" />

                    <rect x="75" y="7" rx="3" ry="3" width="400" height="19" />
                    <rect x="75" y="37" rx="3" ry="3" width="300" height="5" />

                    <rect x="75" y="70" rx="3" ry="3" width="75" height="8" />
                    <rect x="75" y="88" rx="3" ry="3" width="75" height="8" />

                    <rect x="225" y="70" rx="3" ry="3" width="75" height="8" />
                    <rect x="225" y="88" rx="3" ry="3" width="75" height="8" />

                    <rect x="375" y="70" rx="3" ry="3" width="75" height="8" />
                    <rect x="375" y="88" rx="3" ry="3" width="75" height="8" />
                </ContentLoader>
            </Card.Body>
        </Card>
    );
};

export default InboxCardLoader;
