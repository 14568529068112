import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { Placement } from 'popper.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { truncate } from 'lodash';

const TOOLTIP_CONTENT_MAX_LENGTH = 500;
const TOOLTIP_MAX_WIDTH = 500;

interface Props {
    children: ReactElement;
    disabled?: boolean;
    value: string;
    placement?: Placement;
}

// Wrap a component with Tooltip to add contextual information on hover
export const InstantTooltip: FunctionComponent<Props> = ({
    children,
    disabled,
    value,
    placement = 'bottom'
}: Props) => {
    // Content formatting
    const truncatedValue = useMemo(() => truncate(value, { length: TOOLTIP_CONTENT_MAX_LENGTH }), [value]);

    // Return content without tooltip
    if (disabled) return children;

    // Return content with tooltip
    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement={placement}
            transition={false}
            overlay={
                <Tooltip className="rounded tooltip-lg instant-tooltip" style={{ maxWidth: TOOLTIP_MAX_WIDTH }}>
                    {truncatedValue}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};
