import gql from 'graphql-tag';

export const GET_COMPANY_USERS = gql`
    query getCompanyUsers($companyId: ID!) {
        company(id: $companyId) {
            id
            users {
                nodes {
                    id
                    firstName
                    email
                    identities {
                        nodes {
                            id
                            provider
                            datalinkId
                        }
                    }
                }
            }
        }
    }
`;

export interface CompanyUser {
    id: string;
    firstName: string;
    email: string;
    refs: string[];
}

export interface CompanyUserIdentityResp {
    id: string;
    provider: string;
    datalinkId: string;
}

export interface CompanyUserResp {
    id: string;
    firstName: string;
    email: string;
    identities: {
        nodes: CompanyUserIdentityResp[];
    };
}

export interface GetCompanyUsersResp {
    company: {
        id: string;
        users: {
            nodes: CompanyUserResp[];
        };
    };
}
