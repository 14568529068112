import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { DateRangeValue } from '../useRulesetValidator';
import { RichMessage } from 'components/RichMessage';
import { QUERY_OP_TS_DIMENSION_FORMATTERS } from 'components/Dashboarding/DataSource/QueryOperators';
import SelectValueField from './SelectValueField';
import { useIntl } from 'react-intl';
import { RulesetValueFieldProps } from './RulesetValueField';
import PickedDataWrapper from './PickedDataWrapper';

interface Props extends RulesetValueFieldProps {
    className: string | undefined;
    value: DateRangeValue;
    onChange: (value: DateRangeValue) => void;
}

const DateRangePickerValueField: FunctionComponent<Props> = ({
    className,
    value,
    onChange,
    drilldownEnabled
}: Props) => {
    // State
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();

    // Services
    const intl = useIntl();

    // Handle fromDate value change
    const handleFromDateChange = useCallback(
        (date: Date | null): void => {
            const momentDate = moment(date);
            const fromDateInputValue = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
            onChange({
                ...value,
                fromDate: fromDateInputValue,
                fromPickedCoordinateIndex: undefined
            } as DateRangeValue);
        },
        [onChange, value]
    );

    // Handle toDate value change
    const handleToDateChange = useCallback(
        (date: Date | null): void => {
            const momentDate = moment(date);
            const toDateInputValue = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
            onChange({
                ...value,
                toDate: toDateInputValue,
                toPickedCoordinateIndex: undefined
            } as DateRangeValue);
        },
        [onChange, value]
    );

    // Handle granularity value change
    const handeGranularityChange = useCallback(
        (granularity: string | undefined) => {
            onChange({
                ...value,
                granularity
            } as DateRangeValue);
        },
        [onChange, value]
    );

    // Handle `from` picked coordinate index selection
    const handleFromPickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange({
                ...value,
                fromDate: '',
                fromPickedCoordinateIndex: e == null ? undefined : e
            });
        },
        [onChange, value]
    );

    // Handle `to` picked coordinate index selection
    const handleToPickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange({
                ...value,
                toDate: '',
                toPickedCoordinateIndex: e == null ? undefined : e
            });
        },
        [onChange, value]
    );

    // Perform date validity checks on fromDate input value changes
    useEffect(() => {
        if (value.fromDate && moment(value.fromDate).isValid()) {
            setFromDate(moment(value.fromDate).toDate());
        } else {
            setFromDate(undefined);
        }
    }, [value.fromDate]);

    // Perform date validity checks on toDate input value changes
    useEffect(() => {
        if (value.toDate && moment(value.toDate).isValid()) {
            setToDate(moment(value.toDate).toDate());
        } else {
            setToDate(undefined);
        }
    }, [value.toDate]);

    return (
        <>
            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={value?.fromPickedCoordinateIndex}
                onChange={handleFromPickedCoordinateIndexChange}
            >
                {/* DatePicker for `fromDate` */}
                <DatePicker
                    className={className}
                    maxDate={
                        toDate
                            ? moment(toDate)
                                  .add(-1, 'day')
                                  .toDate()
                            : undefined
                    }
                    selected={fromDate ? moment(fromDate).toDate() : undefined}
                    onChange={handleFromDateChange}
                />
            </PickedDataWrapper>

            {/* AND */}
            <div className={className} style={{ minWidth: 'auto' }}>
                <RichMessage id="components.ruleset-builder.daterangepicker.AND" />
            </div>

            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={value?.toPickedCoordinateIndex}
                onChange={handleToPickedCoordinateIndexChange}
            >
                {/* DatePicker for `toDate` */}
                <DatePicker
                    className={className}
                    minDate={
                        fromDate
                            ? moment(fromDate)
                                  .add(1, 'day')
                                  .toDate()
                            : undefined
                    }
                    selected={toDate ? moment(toDate).toDate() : undefined}
                    onChange={handleToDateChange}
                />
            </PickedDataWrapper>

            {/* Granularity */}
            <SelectValueField
                className={className}
                onChange={handeGranularityChange}
                value={value?.granularity}
                values={QUERY_OP_TS_DIMENSION_FORMATTERS.map(e => ({
                    label: intl.formatMessage({ id: `dashboarding.widget-field-selector.dimension-function.${e}` }),
                    name: e
                }))}
            />
        </>
    );
};
export default DateRangePickerValueField;
