import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import ColumnEditBox from './ColumnEditBox';
import { cloneDeep, debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { SeriesCardColumn, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import classNames from 'classnames';

// We allow max 10 columns for now, more column would lead to a horrible board UI
const MAX_COLUMNS = 10;

// Template used for new columns
const COLUMN_TEMPLATE: SeriesCardColumn = {
    filters: {
        id: '0',
        combinator: 'AND',
        not: false,
        rules: [
            {
                id: '1',
                field: 'state',
                operator: 'EQUAL',
                value: 'string:OPEN'
            }
        ]
    }
};

interface Props {
    widget: WidgetMiniCard;
    onChange: (widget: WidgetMiniCard) => void;
}

// This component handle the display of all column edit components for the
// minicard widget
const ColumnAccordion: FunctionComponent<Props> = ({ widget, onChange }: Props) => {
    // State
    const [localWidget, setLocalWidget] = useState<WidgetMiniCard>(widget);

    // Infer state
    const localColumns = localWidget.config.series;
    const canAddColumn = localColumns.length < MAX_COLUMNS;

    // Debounced onChange
    // This is used in combination with a local state to apply updates on screen faster
    const debouncedOnChange = useMemo(() => debounce(onChange), [onChange]);

    // Hook invoked when a column is edited
    const onColumnChange = useCallback(
        (column: SeriesCardColumn, colIndex: number): void => {
            // Generate the updated list of columns
            const updatedColumns = [...localColumns] as [SeriesCardColumn, ...SeriesCardColumn[]];
            updatedColumns[colIndex] = column;

            // Update the parent widget
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, series: updatedColumns } };
            setLocalWidget(updatedWidget);
            debouncedOnChange(updatedWidget);
        },
        [debouncedOnChange, localColumns, localWidget]
    );

    // Hook invoked when a column is reordered
    const onColumnReorder = useCallback(
        (colIndex: number, direction: 'left' | 'right'): void => {
            // Evaluate the destination index
            const dstIndex = colIndex + (direction === 'left' ? -1 : 1);

            // Switch items
            const updatedColumns = [...localColumns] as [SeriesCardColumn, ...SeriesCardColumn[]];
            const srcItem = updatedColumns[colIndex];
            const dstItem = updatedColumns[dstIndex];
            updatedColumns[colIndex] = dstItem;
            updatedColumns[dstIndex] = srcItem;

            // Update the parent widget
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, series: updatedColumns } };
            setLocalWidget(updatedWidget);
            debouncedOnChange(updatedWidget);
        },
        [debouncedOnChange, localColumns, localWidget]
    );

    // Hook invoked when a column is added
    const onColumnAdd = useCallback((): void => {
        // Prepare new column
        const newCol = cloneDeep(COLUMN_TEMPLATE);

        // Add column
        const updatedColumns = [...localColumns, newCol] as [SeriesCardColumn, ...SeriesCardColumn[]];

        // Update the parent widget
        const updatedWidget = { ...localWidget, config: { ...localWidget.config, series: updatedColumns } };
        setLocalWidget(updatedWidget);
        debouncedOnChange(updatedWidget);
    }, [debouncedOnChange, localColumns, localWidget]);

    // Hook invoked when a column is removed
    const onColumnDelete = useCallback(
        (colIndex: number): void => {
            // Remove column
            const updatedColumns = [...localColumns] as [SeriesCardColumn, ...SeriesCardColumn[]];
            updatedColumns.splice(colIndex, 1);

            // Notify parent
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, series: updatedColumns } };
            setLocalWidget(updatedWidget);
            debouncedOnChange(updatedWidget);
        },
        [debouncedOnChange, localColumns, localWidget]
    );

    // Resync local state from parent
    useEffect(() => setLocalWidget(widget), [widget]);

    // Display board edit screen
    return (
        <div className="d-flex overflow-x-auto pb-4">
            {/* Show each column */}
            {localColumns.map((column, index) => (
                <ColumnEditBox
                    key={index}
                    widget={localWidget}
                    column={column}
                    colIndex={index}
                    colCount={localColumns.length}
                    onChange={onColumnChange}
                    onReorder={onColumnReorder}
                    onDelete={onColumnDelete}
                />
            ))}

            {/* Table addon used to add column */}
            <div className="column-addon" onClick={onColumnAdd} role="button">
                <div
                    className={classNames('vertical-line h-40', {
                        'border-light': !canAddColumn,
                        'border-primary': canAddColumn
                    })}
                />
                <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classNames({
                        'text-light': !canAddColumn,
                        'text-primary': canAddColumn
                    })}
                />
                <div
                    className={classNames('vertical-line h-40', {
                        'border-light': !canAddColumn,
                        'border-primary': canAddColumn
                    })}
                />
            </div>
        </div>
    );
};

export default ColumnAccordion;
