import { ApolloError } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { AppResp, GetAppsResp, GET_APPS } from '../queries/App';
import { APP_ALPHA_STAGE, APP_BETA_STAGE, APP_PROD_STAGE } from 'constants/defaultValues';
import { useCacheDefinition, useCachedValue } from 'util/useCache';

const ALL_STAGES = [APP_ALPHA_STAGE, APP_BETA_STAGE, APP_PROD_STAGE];
const DEFAULT_STAGES = [APP_BETA_STAGE, APP_PROD_STAGE];

interface GetAppsOps {
    skip?: boolean;
    alpha?: boolean;
}

interface QueryRs {
    apps: AppResp[];
    loading: boolean;
    error?: ApolloError;
}

// const useAppState = createGlobalState<{ [key: string]: QueryRs | undefined }>({});

export function useApps({ skip, alpha }: GetAppsOps = {}): QueryRs {
    const stages = alpha ? ALL_STAGES : DEFAULT_STAGES;

    // Get cached value
    const [cachedValue, cacheKey] = useCachedValue<QueryRs>('useApps', [stages]);

    // Fetch data
    const { data, loading, error } = useQuery<GetAppsResp>(GET_APPS, {
        skip: skip || !!cachedValue,
        variables: { stages: stages }
    });

    // Define content of cached value
    useCacheDefinition<QueryRs>(() => {
        const apps = data?.apps.nodes || [];

        if (!loading && !error && apps.length > 0) {
            return { apps, loading };
        }
    }, cacheKey);

    // Return cached result or live result
    return cachedValue || { apps: [], loading, error };
}
