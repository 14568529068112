import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import PickedDataWrapper from './PickedDataWrapper';
import { RulesetValueFieldProps } from './RulesetValueField';

interface Props extends RulesetValueFieldProps {
    className: string | undefined;
    title: string | undefined;
    inputType: string;
    value: string;
    pickedCoordinateIndex?: number;
    onChange: (value: string | number | undefined, pickedCoordinateIndex?: number) => void;
}

const InputValueField: FunctionComponent<Props> = ({
    className,
    title,
    inputType,
    value,
    onChange,
    pickedCoordinateIndex,
    drilldownEnabled
}: Props) => {
    // State
    const [localValue, setLocalValue] = useState<string>(value ?? '');
    const [lockedUpdates, setLockedUpdates] = useState<boolean>(false);

    // Get debounced version of onChange so as to not trigger successive updates
    // while the user is typing.
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Get debounced version of setLockedUpdates
    const debouncedSetLockedUpdates = useMemo(() => debounce(setLockedUpdates, DEFAULT_DEBOUNCE_TIME), [
        setLockedUpdates
    ]);

    // Handle value change
    const onValueChange = useCallback(
        (val: string): void => {
            // When the user is typing, lock the updates
            if (!lockedUpdates) setLockedUpdates(true);

            // Update state
            setLocalValue(val);

            // Format the value
            let newValue: string | number | undefined = val;
            if (inputType === 'number') {
                newValue = val != '' ? +val : undefined;
            }

            // Propagate value to parent with debouncing
            debouncedOnChange(newValue, undefined);

            // Unlock the updates with the same debouncing
            debouncedSetLockedUpdates(false);
        },
        [debouncedOnChange, lockedUpdates, debouncedSetLockedUpdates, inputType]
    );

    // Handle picked coordinate index selection
    const handlePickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange(undefined, e == null ? undefined : e);
        },
        [onChange]
    );

    useEffect(() => {
        // If updates are locked, abort
        if (lockedUpdates || localValue == value) return;

        // Update local state with value
        setLocalValue(value ?? '');
    }, [lockedUpdates, localValue, value]);

    return (
        <>
            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={pickedCoordinateIndex}
                onChange={handlePickedCoordinateIndexChange}
            >
                {/* Input */}
                <input
                    type={inputType}
                    value={localValue}
                    title={title}
                    className={className}
                    onChange={e => onValueChange(e.target.value)}
                />
            </PickedDataWrapper>
        </>
    );
};

export default InputValueField;
