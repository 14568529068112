import React, { FunctionComponent, Fragment, useState, useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_START_PATH } from 'constants/routeBuilders';
import { GetCurrentApiUserResp, GET_CURRENT_API_USER } from 'api/hq/queries/CurrentApiUser';
import { useDispatch } from 'react-redux';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import {
    GetCompanyInviteByRefResp,
    GET_COMPANY_INVITE_BY_REF,
    AcceptCompanyInviteResp,
    ACCEPT_COMPANY_INVITE
} from 'api/hq/queries/CompanyInvite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faSadCry } from '@fortawesome/free-regular-svg-icons';
import { LOGIN_USER_SUCCESS } from 'constants/actionTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';
import { useDashboardCreator } from 'components/Dashboarding/Hooks/useDashboardCreator';
import { DASHBOARD_ONBOARDING_TEMPLATES } from 'components/Dashboarding/Models/DashboardTemplates';
import FullPageLoader from 'components/Design/FullPageLoader';

const CompanyInvite: FunctionComponent = () => {
    // Services
    const params = useParams() as { id: string; token: string };
    const navigate = useNavigate();

    // URL params
    const id = params.id;
    const token = params.token;

    // Declare state
    const [acceptInProgress, setAcceptInProgress] = useState<boolean>(false);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);

    // Global state
    const dispatch = useDispatch();

    // Retrieve current user
    const { refetch } = useQuery<GetCurrentApiUserResp>(GET_CURRENT_API_USER);

    // Retrieve company invite
    const { data, loading } = useQuery<GetCompanyInviteByRefResp>(GET_COMPANY_INVITE_BY_REF, {
        skip: !id || !token,
        variables: { id, token }
    });

    // Accept invite mutation
    const [acceptInviteQuery] = useMutation<AcceptCompanyInviteResp>(ACCEPT_COMPANY_INVITE);

    // Get dashboard creator
    const { createDashboardFromTemplate } = useDashboardCreator();

    // Extract the invite data
    const invite = data?.companyInvite;
    const targetCompanyId = invite?.company.id;

    // Accept invite action
    const acceptInvite = useCallback(
        async (id: string, token: string) => {
            if (!targetCompanyId) return;
            setAcceptInProgress(true);

            try {
                const resp = await acceptInviteQuery({ variables: { id: id, token: token } });
                const acceptResp = resp.data?.acceptCompanyInvite;

                if (acceptResp?.success) {
                    // Refetch user
                    const userResp = await refetch();
                    const apiUser = userResp.data.currentApiUser;

                    // Setup user dashboards for this company
                    await Promise.all(
                        DASHBOARD_ONBOARDING_TEMPLATES.map(e =>
                            createDashboardFromTemplate(e, {
                                companyId: targetCompanyId,
                                pinned: true,
                                actionSource: 'platform-template'
                            })
                        )
                    );

                    // Login user to the newly joined company
                    dispatch({
                        type: LOGIN_USER_SUCCESS,
                        payload: { ...apiUser, currentCompanyId: targetCompanyId }
                    });

                    // Redirect to start path
                    navigate(DEFAULT_START_PATH, { replace: true });
                } else {
                    // Trigger error page
                    setMutationError({ code: 'expired' });
                }
            } catch {
                setMutationError({ code: 'default' });
            } finally {
                setAcceptInProgress(false);
            }
        },
        [acceptInviteQuery, createDashboardFromTemplate, dispatch, navigate, refetch, targetCompanyId]
    );

    // Handle invalid link
    useEffect(() => {
        !loading && !invite && !mutationError && setMutationError({ code: 'invite-not-found' });
    }, [invite, loading, mutationError]);

    // Display loading screen if the initial query is loading
    if (loading || acceptInProgress) return <FullPageLoader />;

    return (
        <Fragment>
            <div className="container">
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <Card className="text-center pt-2 pb-5 w-75 w-lg-50 text-dark">
                        {invite && !mutationError ? (
                            <>
                                <Card.Header className="bg-white pt-5 pb-4">
                                    <Card.Title className="mt-2 mb-4">
                                        <FontAwesomeIcon icon={faHandshake} size="4x" className="text-primary mb-2" />
                                        <h4>
                                            <FormattedMessage
                                                id="flows.company-invite.title"
                                                values={{ name: invite.requestor.firstName }}
                                            />
                                        </h4>
                                    </Card.Title>
                                </Card.Header>

                                <Card.Body className="pt-5 d-flex justify-content-center">
                                    <Button
                                        variant="dark"
                                        size="lg"
                                        className="mt-5 mb-5"
                                        onClick={() => acceptInvite(id, token)}
                                    >
                                        <FormattedMessage id="flows.company-invite.accept" />
                                    </Button>
                                </Card.Body>
                            </>
                        ) : (
                            <>
                                <Card.Header className="bg-white pt-5 pb-4">
                                    <Card.Title className="mt-2 mb-4">
                                        <FontAwesomeIcon icon={faSadCry} size="4x" className="text-primary mb-2" />
                                        <h4>
                                            <FormattedMessage id="flows.company-invite.error.title" />
                                        </h4>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="pt-5">
                                    <ErrorMessage error={{ ...mutationError, namespace: 'accept-company-invite' }} />
                                </Card.Body>
                            </>
                        )}
                    </Card>
                </div>
            </div>

            <div className="fixed-bottom text-center text-muted">
                <div className="mb-5">
                    <KeypupLogo height={32} className="mb-2" />
                    <p>
                        <em>
                            <FormattedMessage id="flows.company-invite.footer" />
                        </em>
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default CompanyInvite;
