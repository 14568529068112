import gql from 'graphql-tag';
import { ItemUser } from './User';

export interface ItemComment {
    _system_id: string;
    author: ItemUser;
    body: string;
    created_at: string;
    inbox_item_system_id: string;
    updated_at: string;
    url: string;
}

export interface GetItemCommentsResp {
    comments: ItemComment[];
}

// The last 500 comments are fetched
export const GET_ITEM_COMMENTS = gql`
    query($parentSysIds: [String!]) {
        report(
            queries: [
                {
                    target: "comments"
                    fact: COMMENTS
                    limit: 500
                    dimensions: [
                        { field: "_system_id" }
                        { field: "author" }
                        { field: "body" }
                        { field: "created_at", sort: DESC }
                        { field: "inbox_item_system_id" }
                        { field: "updated_at" }
                        { field: "url" }
                    ]
                    filters: [{ function: IN, args: [{ field: "inbox_item_system_id" }, { stringL: $parentSysIds }] }]
                }
            ]
        ) {
            id
            results
            errors {
                message
            }
        }
    }
`;
