import { ResourceType } from 'api/hq/queries/ResourceRole';
import { cloneDeep, isEmpty, pick, sortBy, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateID, RuleGroupArray, RuleGroupType, RuleType } from 'react-querybuilder';
import { getDeepValuesByPropertyName, deepMatchValue, omitByDeep } from 'util/ObjectOperators';
import {
    ConfigQn,
    CONFIG_QNS,
    isDashboardDescriptionQnType,
    isDashboardNamingQnType,
    isInputQnType,
    isMultiSelectInputQnType,
    isProjectsSelectQnType,
    isSingleSelectQnType,
    isWidgetNamingQnType
} from '../Models/ConfigQns';
import {
    DashboardTemplate,
    DashboardTemplateWidget,
    DASHBOARD_ONBOARDING_TEMPLATES
} from '../Models/DashboardTemplates';
import { ChartSeriesType, KpiSeriesType, WidgetBase, WidgetFactType } from '../Models/Widget';
import { WidgetFactTemplate, WIDGET_TEMPLATES } from '../Models/WidgetTemplates';

const FROM_ANSWER_REGEXP = /.+FromAnswer$/;
const INTERPOLATION_REGEXP = /\$\{([\w\-.]+)\}/;

/**
 * Remove all the empty rules from a ruleset
 * @param ruleset the ruleset to cleanup
 * @returns the cleaned ruleset
 */
export function cleanupEmptyRules(
    ruleset: RuleGroupArray | RuleGroupType | RuleType
): RuleGroupArray | RuleGroupType | RuleType | null {
    if (!ruleset || isEmpty(ruleset)) return null;

    const group = ruleset as RuleGroupType;

    if (group.combinator) {
        // If all rules are empty, remove the whole rule group
        if (group.rules.every(e => isEmpty(e))) return null;

        // Remove the empty rules
        return {
            ...group,
            rules: group.rules.filter(e => !isEmpty(e))
        };
    }

    return ruleset;
}

/**
 * Omit all the empty rules from a template
 * @param template the template to cleanup
 * @returns the cleaned template
 */
function omitEmptyRules<T extends DashboardTemplate | WidgetFactType>(template: T): T {
    // For dashboards, cleanup all widgets
    const layout = (template as DashboardTemplate)?.layout;
    if (layout) {
        return {
            ...template,
            layout: layout.map(e => ({
                ...e,
                widget: omitEmptyRules(e.widget as WidgetFactType)
            }))
        };
    }

    // For widgets, cleanup all filter rules
    const config = (template as WidgetFactType)?.config;
    if (config) {
        return {
            ...template,
            config: {
                ...config,
                filters: cleanupEmptyRules(config.filters as RuleGroupType),
                series: config.series.map(e => ({
                    ...e,
                    filters: cleanupEmptyRules(e.filters as RuleGroupType),
                    drilldownConfigs: (e as ChartSeriesType | KpiSeriesType).drilldownConfigs?.map(d => ({
                        ...d,
                        series: {
                            ...d.series,
                            filters: cleanupEmptyRules(d.series.filters as RuleGroupType)
                        }
                    }))
                }))
            }
        };
    }

    return template;
}

/**
 * Clean up the template
 * @param template the template to cleanup
 * @returns the cleaned template
 */
export function cleanupTemplateMarkup<T extends DashboardTemplate | WidgetFactType>(template: T): T {
    return omitEmptyRules(omitByDeep(template, FROM_ANSWER_REGEXP) as T);
}

/**
 * Initializes a question's result
 * @param question the question to init
 * @param template the template if some default values should be extracted from it
 * @returns the initialized question
 */
const initQuestion = (
    question: ConfigQn,
    template: DashboardTemplate | WidgetFactTemplate,
    templateType: ResourceType
): ConfigQn | undefined => {
    if (isDashboardNamingQnType(question)) {
        // Specific rule for the `dashboard-naming` question:
        // the result must be initialized from the template
        const result = {
            name: template?.name ?? '',
            icon: (template as DashboardTemplate)?.icon
        };

        return {
            ...question,
            result
        };
    } else if (isDashboardDescriptionQnType(question)) {
        // Specific rule for the `dashboard-desecription` question:
        // the result must be initialized from the template
        const result = template?.description;
        return {
            ...question,
            result
        };
    } else if (isWidgetNamingQnType(question)) {
        // Specific rule for the `widget-naming` question:
        // the result must be initialized from the template
        const result = template?.name;
        return {
            ...question,
            result
        };
    } else if (isSingleSelectQnType(question)) {
        return {
            ...question,
            result: question.options.find(e => e.id == question.default),
            impactedWidgets:
                templateType == 'DASHBOARD'
                    ? determineImpactedWidgets(question, template as DashboardTemplate)
                    : undefined
        };
    } else if (isMultiSelectInputQnType(question)) {
        return {
            ...question,
            result: {
                selectedOptions: question.options.filter(e => question.default.selectedOptions.includes(e.id)),
                input: question.default.input
            },
            impactedWidgets:
                templateType == 'DASHBOARD'
                    ? determineImpactedWidgets(question, template as DashboardTemplate)
                    : undefined
        };
    } else if (isInputQnType(question)) {
        return {
            ...question,
            result: question.default,
            impactedWidgets:
                templateType == 'DASHBOARD'
                    ? determineImpactedWidgets(question, template as DashboardTemplate)
                    : undefined
        };
    } else if (isProjectsSelectQnType(question)) {
        return {
            ...question,
            result: {
                selectedOption: question.options.find(e => e.id == question.default),
                projects: []
            },
            impactedWidgets:
                templateType == 'DASHBOARD'
                    ? determineImpactedWidgets(question, template as DashboardTemplate)
                    : undefined
        };
    }
    return;
};

/**
 * Determine widgets impacted by this question
 * @param question the impacting question
 * @param template the impacted template
 * @returns the impacted widgets
 */
const determineImpactedWidgets = (question: ConfigQn, template: DashboardTemplate): Partial<WidgetBase>[] => {
    return template.layout.reduce((array: Partial<WidgetBase>[], e: DashboardTemplateWidget) => {
        if (deepMatchValue(e.widget, new RegExp(`(\\{|^)${question.id}(\\}|\\.|$)`))) {
            array.push(pick({ ...e.widget }, ['id', 'name', 'description']));
        }
        return array;
    }, []);
};

/**
 * Extract the list of question IDs related to the provided string.
 * @param value The string from which to extract question IDs
 * @returns {string[]} the list of related question IDs
 */
const extractQuestionIds = (value: string): string[] => {
    return extractQuestionRefs(value).map(e => e.split('.')[0]);
};

/**
 * Extract the list of question references (with dot notation) related to the provided string.
 * @param value The string from which to extract question references
 * @returns {string[]} the list of related question references
 */
const extractQuestionRefs = (value: string): string[] => {
    return [...(value.match(INTERPOLATION_REGEXP) || []), value]
        .map(e => e.split('.'))
        .filter(path => path[0] && CONFIG_QNS.find(e => e.id == path[0]))
        .map(e => e.join('.'));
};

/**
 * Initializes all the questions that need to be asked to the user for a given template
 *
 * @param templateId the id of the template
 * @returns {Map<string, ConfigQn>} a `Map` of question ids to question configurations.
 */
const initQuestions = (
    templateId: string | null,
    templateType: ResourceType,
    templates: (DashboardTemplate | WidgetFactTemplate)[],
    maxPriority: number
): Map<string, ConfigQn> | undefined => {
    // Retrieve template from id
    const template = templates.find(e => e.id == templateId);

    // Abort if no template found
    if (!template) return;

    // Extract all question ids
    const questionRefs = getDeepValuesByPropertyName(template, FROM_ANSWER_REGEXP) as string[];
    const questionIds = uniq(questionRefs.flatMap(ref => extractQuestionIds(ref)));

    // Build the map
    const questionsMap = new Map<string, ConfigQn>();
    questionIds.forEach(id => {
        // Find question by id
        const question = CONFIG_QNS.find(e => e.id == id);

        // Abort if no question found
        if (!question) return;

        // Abort if question priority is too low
        if (question.priority > maxPriority) return;

        // Abort if question scopes don't include template type
        if (question.scopes && !question.scopes.includes(templateType)) return;

        // Initialize the question
        const initializedQuestion = initQuestion(question, template, templateType);

        if (!initializedQuestion) return;

        // Add the validated question to the map
        questionsMap.set(id, validateQuestion(initializedQuestion));
    });

    // Sort the question map by question priority
    const sortedQuestionsMap = new Map(sortBy(Array.from(questionsMap.entries()), ([, v]) => v.priority));

    // Return questions for the ids
    return sortedQuestionsMap;
};

/**
 * Format the result of a question depending on its type
 * @param question the question
 * @returns the formatted result
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatResultByQuestionType = (question: ConfigQn): any => {
    if (
        isInputQnType(question) ||
        isDashboardNamingQnType(question) ||
        isDashboardDescriptionQnType(question) ||
        isWidgetNamingQnType(question)
    ) {
        return question.result;
    } else if (isSingleSelectQnType(question)) {
        return question.result?.value;
    } else if (isMultiSelectInputQnType(question)) {
        if (!question.result) return;

        const selectedValues = question.result.selectedOptions?.map(r => r.value);
        const commaSeparatedValues = question.result.input?.split(',');
        return selectedValues
            .concat(commaSeparatedValues)
            .filter(e => !!e)
            .join(',');
    } else if (isProjectsSelectQnType(question)) {
        if (question.result?.selectedProjects) {
            const projects = question.result.selectedProjects?.map(r => r.name) ?? [];
            return {
                id: `g-${generateID()}`,
                combinator: 'OR',
                rules: projects.map(value => ({
                    id: `r-${generateID()}`,
                    field: 'project.name',
                    value: {
                        type: 'string',
                        value
                    },
                    operator: 'EQUAL'
                }))
            };
        }
        return {};
    }
    return;
};

/**
 * Validates a question's result. The validation depends on the question type
 * @param question the question on which we validate the result
 * @returns the validated question
 */
const validateQuestion = (question: ConfigQn): ConfigQn => {
    if (isInputQnType(question) || isDashboardDescriptionQnType(question) || isWidgetNamingQnType(question)) {
        const isValid = !!question.result;
        return {
            ...question,
            isValid
        };
    } else if (isDashboardNamingQnType(question)) {
        const isValid = question.result.name != '' && question.result.icon != undefined;
        return {
            ...question,
            isValid
        };
    } else if (isSingleSelectQnType(question)) {
        const isValid = question.result != undefined;
        return {
            ...question,
            isValid
        };
    } else if (isMultiSelectInputQnType(question)) {
        const isValid = question.result && (question.result.selectedOptions.length > 0 || question.result.input != '');
        return {
            ...question,
            isValid
        };
    } else if (isProjectsSelectQnType(question)) {
        const isValid =
            question.result &&
            question.result.selectedOption &&
            (question.result.selectedOption.id == 'all' ||
                (question.result.selectedOption.id == 'some' && question.result.selectedProjects.length > 0));
        return {
            ...question,
            isValid
        };
    }
    return {
        ...question,
        isValid: false
    };
};

/**
 * Interpolate a string using a list of variables.
 * E.g. fillStringTemplate("Hello ${name}", { name: "World" }) => "Hello World"
 *
 * @param templateString The string to interpolate
 * @param templateVars The list of variables to use as context for the interpolation
 * @returns The interpolated string
 */
const fillStringTemplate = (templateString: string, templateVars: object): string => {
    return Object.entries(templateVars).reduce((str, [key, value]) => {
        return str.replaceAll(`\${${key}}`, value);
    }, templateString);
};

/**
 * Compile a fromAnswer property value as an interpolated string.
 *
 * @param obj The parent object where to inject answers to template questions
 * @param prop The fromAnswer prop being evaluated for replacement
 * @param questions The list of questions and their answers to use for injecting parameters
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fillTemplatePropWithInterpolation = (obj: any, prop: string, questions: Map<string, ConfigQn>): void => {
    const questionRefs = extractQuestionRefs(obj[prop]);

    // Build the interpolation variables
    const interpolationVars = questionRefs.reduce((vars, questionRef) => {
        // Retrieve the question and the sub attribute
        const [propQuestionId, propQuestionAttr] = questionRef.split('.');

        // Find a question for this id
        const question = questions.get(propQuestionId);

        // If there isn't or the answer is not valid yet, continue
        if (!question || !question.isValid) return vars;

        // Format result by question type
        const questionAnswer = formatResultByQuestionType(question);

        // If no answer, continue
        if (!questionAnswer) return vars;

        const result = propQuestionAttr ? questionAnswer[propQuestionAttr] : questionAnswer;

        return { ...vars, [questionRef]: result };
    }, {});

    // Get the propname's prefix
    const propName = prop.replace('FromAnswer', '');

    // Assign the result to the prop
    obj[propName] = fillStringTemplate(obj[prop], interpolationVars);
};

/**
 * Compile a fromAnswer property value as a direct reference to an answer.
 *
 * @param obj The parent object where to inject answers to template questions
 * @param prop The fromAnswer prop being evaluated for replacement
 * @param questions The list of questions and their answers to use for injecting parameters
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fillTemplatePropWithDirectRef = (obj: any, prop: string, questions: Map<string, ConfigQn>): void => {
    // If the prop's name is ending with `FromAnswer`
    const [propQuestionId, propQuestionAttr] = obj[prop].split('.');

    // Find a question for this id
    const question = questions.get(propQuestionId);

    // If there isn't or the answer is not valid yet, continue
    if (!question || !question.isValid) return;

    // Format result by question type
    const questionAnswer = formatResultByQuestionType(question);

    // If no answer, continue
    if (!questionAnswer) return;

    const result = propQuestionAttr ? questionAnswer[propQuestionAttr] : questionAnswer;

    if (prop == 'ruleFromAnswer') {
        // Clear the whole rule current properties
        Object.keys(obj).forEach(p => delete obj[p]);

        // Assign all the new properties of the result
        Object.keys(result).forEach(p => (obj[p] = result[p]));
    } else if (prop == 'partialRuleFromAnswer') {
        // Override result's props of the rule
        Object.keys(result).forEach(p => (obj[p] = result[p]));
    } else {
        // Get the propname's prefix
        const propName = prop.replace('FromAnswer', '');

        // Assign the result to the prop
        obj[propName] = result;
    }
};

/**
 * Replace properties with their counterpart 'fromAnswer' based on answers provided to
 * template questions.
 * @param obj The object where to inject answers to template questions
 * @param questions The list of questions and their answers to use for injecting parameters
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fillTemplate = (obj: any, questions: Map<string, ConfigQn>): void => {
    // Loop through all object properties
    for (const prop in obj) {
        // If the prop's value is an object
        if (typeof obj[prop] == 'object') {
            // Recursively execute the method on the object's sub properties
            fillTemplate(obj[prop], questions);
        } else if (prop.match(FROM_ANSWER_REGEXP)) {
            if (obj[prop].match(INTERPOLATION_REGEXP)) {
                fillTemplatePropWithInterpolation(obj, prop, questions);
            } else {
                fillTemplatePropWithDirectRef(obj, prop, questions);
            }
        }
    }
};

interface Results {
    loading: boolean;
    resultTemplate: DashboardTemplate | WidgetFactTemplate | undefined;
    questions: Map<string, ConfigQn> | undefined;
    updateQuestion: (e: ConfigQn) => void;
    resetQuestions: () => void;
    clearQuestions: () => void;
}

export const useTemplateAssistant = (
    localStorageKeyPrefix: string,
    templateId: string | null,
    templateType: ResourceType,
    maxPriority: number
): Results => {
    // State
    const [loading, setLoading] = useState<boolean>(true);
    const [questions, setQuestions] = useState<Map<string, ConfigQn>>();

    // Local storage key
    const localStorageKey = useMemo(() => `${localStorageKeyPrefix}-${templateId}`, [
        localStorageKeyPrefix,
        templateId
    ]);

    // Templates to search in
    const templates: (DashboardTemplate | WidgetFactTemplate)[] = useMemo(
        () =>
            templateType == 'DASHBOARD'
                ? DASHBOARD_ONBOARDING_TEMPLATES
                : templateType == 'WIDGET'
                ? WIDGET_TEMPLATES
                : [],
        [templateType]
    );

    // Build the result template from the templateId and the questions state
    const resultTemplate = useMemo(() => {
        // Retrieve template from id
        const template = templates.find(e => e.id == templateId);

        // Clone the template
        const clonedTemplate = cloneDeep(template);

        // Fill in the user's answer
        if (questions) fillTemplate(clonedTemplate, questions);

        return clonedTemplate;
    }, [questions, templateId, templates]);

    // Hook invoked when user inputs change the current question state
    const updateQuestion = useCallback(
        (updatedQuestion: ConfigQn) => {
            // Update questions state with the validated answer
            setQuestions(prev => new Map(prev).set(updatedQuestion.id, validateQuestion(updatedQuestion)));
        },
        [setQuestions]
    );

    // Hook invoked to clear questions data from local storage
    const clearQuestions = useCallback(() => {
        localStorage.removeItem(localStorageKey);
    }, [localStorageKey]);

    // Hook invoked to reset the questions
    const resetQuestions = useCallback(() => {
        clearQuestions();
        setQuestions(initQuestions(templateId, templateType, templates, maxPriority));
    }, [templateId, templateType, templates, maxPriority, clearQuestions]);

    // Initialize questions depending on `templateId`
    useEffect(() => {
        // Set loading state
        setLoading(true);

        // Look for questions draft in the local storage
        const localStorageQuestions = localStorage.getItem(localStorageKey);

        // If found, update questions state
        if (localStorageQuestions) {
            setQuestions(new Map(JSON.parse(localStorageQuestions)));
        } else {
            // Else init the questions
            setQuestions(initQuestions(templateId, templateType, templates, maxPriority));
        }

        // Reset loading state
        setLoading(false);
    }, [templates, localStorageKey, templateId, templateType, maxPriority]);

    // On questions update, sync them in the local storage
    useEffect(() => {
        if (questions) localStorage.setItem(localStorageKey, JSON.stringify(Array.from(questions.entries())));
    }, [localStorageKey, localStorageKeyPrefix, questions]);

    return {
        loading,
        questions,
        resultTemplate,
        updateQuestion,
        resetQuestions,
        clearQuestions
    };
};
