import showdown from 'showdown';

// Parse JSON from string and return undefined in case of parsing error
export const safeParseJson = (json: string | undefined | null): object | undefined => {
    try {
        return json ? JSON.parse(json) : undefined;
    } catch {
        return undefined;
    }
};

// Remove HTML markup from a string.
// See: https://stackoverflow.com/a/5002161
// Note: Any solution relying on creating a div and then getting its inner text
// to strip the HTML tags is to be avoided as it would introduce a security vulnerability
// (arbitrary JS execution)
export const stripHtml = (html: string): string => {
    return html.replace(/<(?:[^><"']*?(?:(["']).*?\1)?[^><'"]*?)+(?:>|$)/g, '');
};

// Remove mardown syntax
export const stripMarkdown = (markdown: string): string => {
    const converter = new showdown.Converter();
    return stripHtml(converter.makeHtml(markdown));
};

// A simplistic email validator
export const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

// Split a string in exactly two parts using the specified delimiter
export const split2 = (str: string | undefined, delim: string): string[] => {
    if (!str) return [];

    const parts = str.split(delim);
    return [parts[0], parts.splice(1, parts.length).join(delim)];
};

// Dasherize a string
export const dasherize = (str: string): string => {
    return str
        .replace(/[A-Z]/g, m => '-' + m.toLowerCase())
        .replace('_', '-')
        .replace(/-+/g, '-')
        .replace(/^-+/g, '');
};

// Escape characters inside a string.
// Used to insert literal words inside a parent regex expression
export const escapeRegexp = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

// Escape graphql special characters for inclusion in GraphQL
// literal queries
export const escapeGraphql = (str: string): string => {
    return str.replace(/\\/, '\\\\');
};
