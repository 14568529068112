import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useBillingInvoices } from 'api/hq/hooks/useBillingInvoices';
import { ReduxState } from 'redux/reducers';
import { RichMessage } from 'components/RichMessage';
import { Table } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { BillingInvoiceResp } from 'api/hq/queries/BillingInvoice';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import AccountSettingsLayout from 'routes/settings/AccountSettingsLayout';
import { Color } from 'react-bootstrap/types';
import FullPageLoader from 'components/Design/FullPageLoader';

const badgeColor = (invoice: BillingInvoiceResp): { bg: string; text: Color } => {
    switch (invoice.status) {
        case 'PAID':
            return { bg: 'success', text: 'success' };
        case 'OPEN':
            return { bg: 'danger', text: 'danger' };
        default:
            return { bg: 'light', text: 'dark' };
    }
};

// Price formatter label for billing invoices
const amountLabel = (invoice: BillingInvoiceResp): string => {
    const wholeAmt = invoice.amountCents / 100;

    return `${invoice.currency.toUpperCase()} ${wholeAmt}`;
};

const BillingInvoiceList: FunctionComponent = () => {
    // Get context
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Get company invoices
    const { loading: billingInvoicesLoading, normalized: billingInvoices } = useBillingInvoices({
        companyId: company?.id
    });

    // Abort if still loading
    if (billingInvoicesLoading) {
        return (
            <AccountSettingsLayout id="invoices">
                <FullPageLoader vh={75} />;
            </AccountSettingsLayout>
        );
    }

    // Abort if no billing invoices
    if (billingInvoices.length === 0) {
        return (
            <AccountSettingsLayout id="invoices">
                <div className="text-center text-muted">
                    <RichMessage id="settings.billing.billing-invoice-list.empty" />
                </div>
            </AccountSettingsLayout>
        );
    }

    return (
        <>
            <AccountSettingsLayout id="invoices">
                <Table className="align-middle">
                    <thead>
                        <tr>
                            <th>
                                <RichMessage id="settings.billing.billing-invoice-list.thead.invoice-number" />
                            </th>

                            <th>
                                <RichMessage id="settings.billing.billing-invoice-list.thead.date" />
                            </th>

                            <th>
                                <RichMessage id="settings.billing.billing-invoice-list.thead.amount" />
                            </th>

                            <th>
                                <RichMessage id="settings.billing.billing-invoice-list.thead.status" />
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {billingInvoices?.map(e => {
                            const colorConfig = badgeColor(e);

                            return (
                                <tr key={e.id}>
                                    <td className="fw-bold">{e.reference}</td>
                                    <td>
                                        <Moment format="ll">{e.publishedAt}</Moment>
                                    </td>
                                    <td>{amountLabel(e)}</td>
                                    <td>
                                        <Badge
                                            bg={colorConfig.bg}
                                            text={colorConfig.text}
                                            className="bg-opacity-10"
                                            pill
                                        >
                                            <RichMessage
                                                id={`settings.billing.billing-invoice-list.status.${e.status}`}
                                            />
                                        </Badge>
                                    </td>
                                    <td>
                                        <a href={e.url} target="_blank" rel="noopener noreferrer" className="text-dark">
                                            <FontAwesomeIcon size="lg" icon={faPrint} />
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </AccountSettingsLayout>
        </>
    );
};

export default BillingInvoiceList;
