import React, { FunctionComponent, useMemo } from 'react';
import { InboxItem, InboxItemRelated } from 'api/viz/queries/InboxItem';
import InboxFeedList from './InboxFeedList';
import { useItemComments } from 'api/viz/hooks/useItemComments';
import { useFeedGenerator } from 'api/viz/hooks/useFeedGenerator';
import InboxFeedItemLoader from './InboxFeedItemLoader';
import { useItemReviews } from 'api/viz/hooks/useItemReviews';

interface Props {
    item: InboxItem | InboxItemRelated;
    inView?: boolean;
}

// Generate a feed for a single inbox item, without taking into account
// the related items (resolving PRs or resolved issues)
const InboxRegularFeed: FunctionComponent<Props> = ({ item, inView }: Props) => {
    // Services
    const generateFeed = useFeedGenerator();

    // Load comments
    const { loading: commentsLoading, normalized: comments } = useItemComments({
        skip: !inView,
        parentSysIds: [item._system_id]
    });

    // Load reviews
    const { loading: reviewsLoading, normalized: reviews } = useItemReviews({
        skip: !inView,
        parentSysIds: item.type === 'issue' ? [] : [item._system_id]
    });

    // Generate feed for the inbox item
    const feedItems = useMemo(() => generateFeed(item, comments, reviews), [comments, generateFeed, item, reviews]);

    // Abort if comments are still loading
    if (commentsLoading || reviewsLoading || !inView) {
        return <InboxFeedItemLoader />;
    }

    return <InboxFeedList parent={item} items={feedItems} />;
};

export default InboxRegularFeed;
