import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import {
    faTable,
    faChartLine,
    faChartBar,
    faChartPie,
    faColumns,
    faGripLines,
    faChartArea,
    faBullseye,
    faPercentage,
    faGripLinesVertical
} from '@fortawesome/free-solid-svg-icons';
import { CompanyUser } from 'api/hq/queries/CompanyUser';
import { WIDGET_COLOR_PALETTE } from 'constants/colors';
import { DIMENSION_IDENTIFIER, METRIC_IDENTIFIER } from 'constants/defaultValues';
import { clone, pick, sample, times, uniq } from 'lodash';
import { Layout } from 'react-grid-layout';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { kpChartBar, kpChartHeatmap } from 'util/customIcons';
import { QueryOpFact } from '../DataSource';
import {
    QueryOpAggregator,
    QueryOpDimensionFormatter,
    QueryOpDisplayFormatter,
    QueryOpField
} from '../DataSource/QueryOperators';
import { customFieldSplitHeadTail } from '../Hooks/useQueryOpFieldLookup';

// Widget categories
export const WIDGET_DESIGN_MODES = ['chart', 'kpi', 'cards'] as const;
export type WidgetDesignMode = typeof WIDGET_DESIGN_MODES[number];

// List of available types and categories
// Each category relates to a different design/edit flow
export const WIDGET_TABLE_TYPES = ['LIST'] as const;
export type WidgetTableConfigType = typeof WIDGET_TABLE_TYPES[number];
export const WIDGET_CHART_TYPES = [
    'AREACHART',
    'BARCHART',
    'COLUMNCHART',
    'HEATMAPCHART',
    'LINECHART',
    'PIECHART'
] as const;
export type WidgetChartConfigType = typeof WIDGET_CHART_TYPES[number];
export const WIDGET_KPI_TYPES = ['NUMBERKPI', 'SPARKCOLUMNKPI', 'SPARKLINEKPI', 'TRENDKPI'] as const;
export type WidgetKpiConfigType = typeof WIDGET_KPI_TYPES[number];
export const WIDGET_MINI_CARD_TYPES = ['MINICARD'] as const;
export type WidgetMiniCardConfigType = typeof WIDGET_MINI_CARD_TYPES[number];
export const WIDGET_LARGE_CARD_TYPES = ['LARGECARD'] as const;
export type WidgetLargeCardConfigType = typeof WIDGET_LARGE_CARD_TYPES[number];
export const WIDGET_CARDS_TYPES = [...WIDGET_MINI_CARD_TYPES, ...WIDGET_LARGE_CARD_TYPES] as const;
export type WidgetCardsConfigType = typeof WIDGET_CARDS_TYPES[number];
export const WIDGET_SEPARATOR_TYPES = ['VSEPARATOR', 'HSEPARATOR'] as const;
export type WidgetSeparatorConfigType = typeof WIDGET_SEPARATOR_TYPES[number];
export const WIDGET_MARKDOWN_TYPES = ['MARKDOWN'] as const;
export type WidgetMarkdownConfigType = typeof WIDGET_MARKDOWN_TYPES[number];
export const WIDGET_TYPES = [
    ...WIDGET_CHART_TYPES,
    ...WIDGET_KPI_TYPES,
    ...WIDGET_CARDS_TYPES,
    ...WIDGET_TABLE_TYPES,
    ...WIDGET_SEPARATOR_TYPES,
    ...WIDGET_MARKDOWN_TYPES
] as const;
export type WidgetConfigType = typeof WIDGET_TYPES[number];

export const WIDGET_STATS_TYPES = [
    ...WIDGET_CHART_TYPES,
    ...WIDGET_KPI_TYPES,
    ...WIDGET_CARDS_TYPES,
    ...WIDGET_TABLE_TYPES
] as const;

// The list of types for which a blank template must exist
// See: WidgetTemplates.ts
export type WidgetBlankTemplateConfigType = typeof WIDGET_STATS_TYPES[number];

// Widget field group types
export type WidgetFieldCategory = 'metric' | 'dimension';

// Sort directions
export type WidgetFieldSortDirection = 'ASC' | 'DESC';

//  Line style
export const WIDGET_LINE_STYLE_TYPES = ['solid', 'dashed'] as const;
export type WidgetLineStyleType = typeof WIDGET_LINE_STYLE_TYPES[number];

// Color range
export type WidgetColorRange = [string, number | undefined] | [string];

//============================================
// Formatting
//============================================
export interface WidgetFormattingOptions {
    baseColors?: string[];
    columnWidths?: { [key: string]: number } | undefined;
    content?: string;
    enableStripedRows?: boolean;
    showChartLegend?: boolean;
    showDataLabel?: boolean;
    showTrendProgression?: boolean;
    xAxis?: {
        label?: string;
        show?: boolean;
    };
    yAxis?: {
        label?: string;
        show?: boolean;
    };
}

export type MetricColorMode = 'palette' | 'individual' | 'gradient' | 'threshold';

export interface MetricFormattingOptions {
    color?: string;
    colorMode?: MetricColorMode;
    colorRanges?: WidgetColorRange[];
    formatter?: QueryOpDisplayFormatter;
    label?: string;
    lineStyle?: WidgetLineStyleType;
    rank?: number;
}

export interface DimensionFormattingOptions {
    color?: string;
    formatter?: QueryOpDisplayFormatter;
    label?: string;
    rank?: number;
}

export interface ColumnFormattingOptions {
    label?: string;
    color?: string;
}

//============================================
// Series fields
//============================================
export interface WidgetFieldBase {
    ref: string;
    sort?: WidgetFieldSortDirection;
}

export interface WidgetMetricField extends WidgetFieldBase {
    function: QueryOpAggregator;
    formatting?: MetricFormattingOptions;
}

export interface WidgetDimensionField extends WidgetFieldBase {
    function?: QueryOpDimensionFormatter;
    formatting?: DimensionFormattingOptions;
}

export type FieldFormattingOptions = MetricFormattingOptions | DimensionFormattingOptions;
export type WidgetFieldType = WidgetMetricField | WidgetDimensionField;

// A wrapping interface embedding the type of the field. This makes union arrays easier to manipulate.
export interface WidgetTypedFieldWrapperBase {
    absoluteIndex: number;
    accessor: string;
    localIndex: number;
}
export interface WidgetTypedMetricFieldWrapper extends WidgetTypedFieldWrapperBase {
    type: 'metric';
    field: WidgetMetricField;
}
export interface WidgetTypedDimensionFieldWrapper extends WidgetTypedFieldWrapperBase {
    type: 'dimension';
    field: WidgetDimensionField;
}
export type WidgetTypedFieldWrapper = WidgetTypedMetricFieldWrapper | WidgetTypedDimensionFieldWrapper;

//============================================
// Series types
//============================================
export interface DrilldownSeries {
    metrics: WidgetMetricField[];
    dimensions: WidgetDimensionField[];
    filters?: RuleGroupType;
    limit?: number;
}

export interface DrilldownConfig {
    enabled: boolean;
    series: DrilldownSeries;
    formatting: WidgetFormattingOptions;
}

export interface Series01Dimension1Metric {
    metrics: [WidgetMetricField];
    dimensions: [WidgetDimensionField] | [];
    filters?: RuleGroupType;
    limit?: number;
    drilldownConfigs?: [DrilldownConfig];
}

export interface Series1Dimension1Metric {
    metrics: [WidgetMetricField];
    dimensions: [WidgetDimensionField];
    filters?: RuleGroupType;
    limit?: number;
    drilldownConfigs?: [DrilldownConfig];
}

export interface Series1DimensionNMetrics {
    metrics: [WidgetMetricField, ...WidgetMetricField[]];
    dimensions: [WidgetDimensionField];
    filters?: RuleGroupType;
    limit?: number;
    drilldownConfigs?: [DrilldownConfig, ...DrilldownConfig[]];
}

export interface Series2Dimension1Metric {
    metrics: [WidgetMetricField];
    dimensions: [WidgetDimensionField, WidgetDimensionField];
    filters?: RuleGroupType;
    limit?: number;
    drilldownConfigs?: [DrilldownConfig];
}

export interface SeriesNDimensionsNMetrics {
    metrics: WidgetMetricField[];
    dimensions: WidgetDimensionField[];
    filters?: RuleGroupType;
    limit?: number;
    drilldownConfigs?: DrilldownConfig[];
}

// Widget column
export interface SeriesCardColumn {
    dimensions?: WidgetDimensionField[];
    filters?: RuleGroupType;
    formatting?: ColumnFormattingOptions;
    limit?: number;
}

// Content-based "series"
export interface SeriesContent {
    content: string;
}

export type SeriesNCardColumns = [SeriesCardColumn, ...SeriesCardColumn[]];
export type Series1CardColumn = [SeriesCardColumn];

export type CardsSeriesType = Series1CardColumn | SeriesNCardColumns;
export type ChartSeriesType = Series1Dimension1Metric | Series1DimensionNMetrics | SeriesNDimensionsNMetrics;
export type KpiSeriesType = Series01Dimension1Metric;
export type AllSeriesType = ChartSeriesType | KpiSeriesType | SeriesCardColumn;

//============================================
// Config types
//============================================
export interface WidgetConfigFactBase {
    fact: QueryOpFact;
    filters?: RuleGroupType;
    formatting?: WidgetFormattingOptions;
}
export interface WidgetConfig1Dimension1Metric extends WidgetConfigFactBase {
    series: [Series1Dimension1Metric];
}

export interface WidgetConfig1DimensionNMetrics extends WidgetConfigFactBase {
    series: [Series1DimensionNMetrics];
}

export interface WidgetConfig2Dimension1Metric extends WidgetConfigFactBase {
    series: [Series2Dimension1Metric];
}

export interface WidgetConfigNDimensionsNMetrics extends WidgetConfigFactBase {
    series: [SeriesNDimensionsNMetrics];
}

export interface WidgetConfigCard1Column extends WidgetConfigFactBase {
    series: [SeriesCardColumn];
}

export interface WidgetConfigCardNColumns extends WidgetConfigFactBase {
    series: [SeriesCardColumn, ...SeriesCardColumn[]];
}

export interface WidgetConfigKpi extends WidgetConfigFactBase {
    series: [Series01Dimension1Metric];
}

export interface WidgetConfigKpiWithTrend extends WidgetConfigFactBase {
    series: [Series01Dimension1Metric, Series01Dimension1Metric];
}

export interface WidgetConfigMarkdown {
    series: [SeriesContent];
}

//============================================
// Widget types
//============================================
export interface WidgetBase {
    id: string;
    name: string;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    configType: WidgetConfigType;
    documentation?: string;
    documentationEnabled?: boolean;
    templateSource?: string;
    actionSource?: string;
    currentUserRole?: {
        name: string;
    };
    user?: CompanyUser;
}

// Design mode: Chart
export interface WidgetColumnChart extends WidgetBase {
    configType: 'COLUMNCHART';
    config: WidgetConfig1DimensionNMetrics;
}
export interface WidgetList extends WidgetBase {
    configType: 'LIST';
    config: WidgetConfigNDimensionsNMetrics;
}

export interface WidgetBarChart extends WidgetBase {
    configType: 'BARCHART';
    config: WidgetConfig1DimensionNMetrics;
}

export interface WidgetHeatmapChart extends WidgetBase {
    configType: 'HEATMAPCHART';
    config: WidgetConfig2Dimension1Metric;
}
export interface WidgetLineChart extends WidgetBase {
    configType: 'LINECHART';
    config: WidgetConfig1DimensionNMetrics;
}
export interface WidgetPieChart extends WidgetBase {
    configType: 'PIECHART';
    config: WidgetConfig1Dimension1Metric;
}
export interface WidgetAreaChart extends WidgetBase {
    configType: 'AREACHART';
    config: WidgetConfig1DimensionNMetrics;
}

// Design mode: KPI
export interface WidgetNumberKpi extends WidgetBase {
    configType: 'NUMBERKPI';
    config: WidgetConfigKpi;
}
export interface WidgetSparkcolumnKpi extends WidgetBase {
    configType: 'SPARKCOLUMNKPI';
    config: WidgetConfigKpiWithTrend;
}
export interface WidgetSparklineKpi extends WidgetBase {
    configType: 'SPARKLINEKPI';
    config: WidgetConfigKpiWithTrend;
}
export interface WidgetTrendKpi extends WidgetBase {
    configType: 'TRENDKPI';
    config: WidgetConfigKpiWithTrend;
}

// Design mode: Large card
export interface WidgetLargeCard extends WidgetBase {
    configType: 'LARGECARD';
    config: WidgetConfigCard1Column;
}

export interface WidgetMarkdown extends WidgetBase {
    configType: 'MARKDOWN';
    config: WidgetConfigMarkdown;
}
// Design mode: Mini card
export interface WidgetMiniCard extends WidgetBase {
    configType: 'MINICARD';
    config: WidgetConfigCardNColumns;
}

// Design mode: Vertical separator
export interface WidgetVSeparator extends WidgetBase {
    configType: 'VSEPARATOR';
}

// Design mode: Horizontal separator
export interface WidgetHSeparator extends WidgetBase {
    configType: 'HSEPARATOR';
}

// Widget categories and union types
// Each category corresponds to a different widget designer flow
export type WidgetMiniCardType = WidgetMiniCard;
export type WidgetLargeCardType = WidgetLargeCard;
export type WidgetCardsType = WidgetLargeCard | WidgetMiniCard;
export type WidgetChartType =
    | WidgetBarChart
    | WidgetColumnChart
    | WidgetHeatmapChart
    | WidgetLineChart
    | WidgetList
    | WidgetPieChart
    | WidgetAreaChart;
export type WidgetKpiType = WidgetNumberKpi | WidgetSparkcolumnKpi | WidgetSparklineKpi | WidgetTrendKpi;
export type WidgetSeparatorType = WidgetVSeparator | WidgetHSeparator;
export type WidgetDecoratorType = WidgetSeparatorType | WidgetMarkdown;
export type WidgetFactType = WidgetChartType | WidgetKpiType | WidgetCardsType;
export type WidgetType = WidgetDecoratorType | WidgetFactType;

// Callback functions for automatic series changes
interface SeriesChangedCallbacks {
    toastWidgetEditorSuccess: (msgId: string, namespace?: string) => void;
}

//============================================
// Widget Configurations
//============================================

// Widget dimension inside the layout
export type WidgetSizing = Pick<Layout, 'w' | 'h' | 'minW' | 'maxW' | 'minH' | 'maxH'>;
export interface WidgetDisplayConfig {
    absoluteRanking?: boolean;
    allowedMetricColorModes?: MetricColorMode[];
    customizeAxis?: boolean;
    customizeMetricColorMode?: MetricColorMode;
    customizeMetricLineStyle?: boolean;
    customizeTrendProgression?: boolean;
    colorMetric?: boolean;
    colorDimension?: boolean;
    colorTrend?: boolean;
    defaultSizing: WidgetSizing;
    designMode?: WidgetDesignMode;
    icon: IconDefinition;
    rangeDimensions: [number, number];
    rangeMetrics: [number, number];
    trendTrailingPoints?: number;
}

const DEFAULT_WIDGET_SIZING = { w: 8, h: 4 };

export const WIDGET_DISPLAY_CONFIGS: { [key in WidgetConfigType]: WidgetDisplayConfig } = {
    AREACHART: {
        allowedMetricColorModes: ['individual'],
        colorMetric: true,
        customizeAxis: true,
        customizeMetricColorMode: 'individual',
        customizeMetricLineStyle: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        icon: faChartArea,
        rangeDimensions: [1, 1],
        rangeMetrics: [1, Infinity]
    },
    BARCHART: {
        allowedMetricColorModes: ['individual'],
        colorMetric: true,
        customizeAxis: true,
        customizeMetricColorMode: 'individual',
        customizeMetricLineStyle: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        icon: kpChartBar,
        rangeDimensions: [1, 1],
        rangeMetrics: [1, Infinity]
    },
    COLUMNCHART: {
        allowedMetricColorModes: ['individual'],
        colorMetric: true,
        customizeAxis: true,
        customizeMetricColorMode: 'individual',
        customizeMetricLineStyle: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        icon: faChartBar,
        rangeDimensions: [1, 1],
        rangeMetrics: [1, Infinity]
    },
    HEATMAPCHART: {
        allowedMetricColorModes: ['gradient', 'threshold'],
        colorMetric: false,
        customizeAxis: true,
        customizeMetricColorMode: 'gradient',
        customizeMetricLineStyle: false,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        icon: kpChartHeatmap,
        rangeDimensions: [2, 2],
        rangeMetrics: [1, 1]
    },
    HSEPARATOR: {
        defaultSizing: { h: 1, w: 8 },
        icon: faGripLines,
        rangeDimensions: [0, 0],
        rangeMetrics: [0, 0]
    },
    LARGECARD: {
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'cards',
        icon: faClone,
        rangeDimensions: [0, 0],
        rangeMetrics: [0, 0]
    },
    LINECHART: {
        allowedMetricColorModes: ['individual'],
        colorMetric: true,
        customizeAxis: true,
        customizeMetricColorMode: 'individual',
        customizeMetricLineStyle: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        icon: faChartLine,
        rangeDimensions: [1, 1],
        rangeMetrics: [1, Infinity]
    },
    LIST: {
        allowedMetricColorModes: ['individual'],
        absoluteRanking: true,
        colorMetric: true,
        colorDimension: true,
        customizeMetricColorMode: 'individual',
        icon: faTable,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        rangeDimensions: [0, Infinity],
        rangeMetrics: [0, Infinity]
    },
    MARKDOWN: {
        defaultSizing: DEFAULT_WIDGET_SIZING,
        icon: faColumns,
        rangeDimensions: [0, 0],
        rangeMetrics: [0, 0]
    },
    MINICARD: {
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'cards',
        icon: faColumns,
        rangeDimensions: [0, 0],
        rangeMetrics: [0, 0]
    },
    NUMBERKPI: {
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'kpi',
        icon: faBullseye,
        rangeDimensions: [0, 1],
        rangeMetrics: [1, 1]
    },
    PIECHART: {
        allowedMetricColorModes: ['palette'],
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'chart',
        customizeMetricColorMode: 'palette',
        icon: faChartPie,
        rangeDimensions: [1, 1],
        rangeMetrics: [1, 1]
    },
    SPARKLINEKPI: {
        colorTrend: true,
        customizeTrendProgression: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'kpi',
        icon: faChartLine,
        rangeDimensions: [0, 1],
        rangeMetrics: [1, 1],
        trendTrailingPoints: 6
    },
    SPARKCOLUMNKPI: {
        colorTrend: true,
        customizeTrendProgression: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'kpi',
        icon: faChartBar,
        rangeDimensions: [0, 1],
        rangeMetrics: [1, 1],
        trendTrailingPoints: 6
    },
    TRENDKPI: {
        colorTrend: true,
        defaultSizing: DEFAULT_WIDGET_SIZING,
        designMode: 'kpi',
        icon: faPercentage,
        rangeDimensions: [0, 1],
        rangeMetrics: [1, 1],
        trendTrailingPoints: 2
    },
    VSEPARATOR: {
        defaultSizing: { h: 8, w: 1 },
        icon: faGripLinesVertical,
        rangeDimensions: [0, 0],
        rangeMetrics: [0, 0]
    }
};

//============================================
// Widget type predicates
//============================================
// Is the series type a mini card type
export const isMiniCardSeriesType = (seriesType: WidgetConfigType): seriesType is WidgetMiniCardConfigType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return WIDGET_MINI_CARD_TYPES.includes(seriesType as any);
};

// Is the series type a large card type
export const isLargeCardSeriesType = (seriesType: WidgetConfigType): seriesType is WidgetLargeCardConfigType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return WIDGET_LARGE_CARD_TYPES.includes(seriesType as any);
};

// Is the series type a cards type
export const isCardsSeriesType = (seriesType: WidgetConfigType): seriesType is WidgetCardsConfigType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return WIDGET_CARDS_TYPES.includes(seriesType as any);
};

// Is the series type a chart type
export const isChartSeriesType = (seriesType: WidgetConfigType): seriesType is WidgetChartConfigType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [...WIDGET_CHART_TYPES, ...WIDGET_TABLE_TYPES].includes(seriesType as any);
};

// Is the series type a kpi type
export const isKpiSeriesType = (seriesType: WidgetConfigType): seriesType is WidgetKpiConfigType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return WIDGET_KPI_TYPES.includes(seriesType as any);
};

// Is the widget a mini card widget
export const isMiniCardWidget = (widget: WidgetFactType): widget is WidgetMiniCardType => {
    return isMiniCardSeriesType(widget.configType);
};

// Is the widget a large card widget
export const isLargeCardWidget = (widget: WidgetFactType): widget is WidgetLargeCardType => {
    return isLargeCardSeriesType(widget.configType);
};

// Is the widget a cards widget
export const isCardsWidget = (widget: WidgetFactType): widget is WidgetCardsType => {
    return isCardsSeriesType(widget.configType);
};

// Is the widget a chart widget
export const isChartWidget = (widget: WidgetFactType): widget is WidgetChartType => {
    return isChartSeriesType(widget.configType);
};

// Is the widget a kpi widget
export const isKpiWidget = (widget: WidgetFactType): widget is WidgetKpiType => {
    return isKpiSeriesType(widget.configType);
};

//============================================
// Widget helpers
//============================================
// Add a number of metrics to the widget using the last defined metric
export function addWidgetMetric<TSeries extends ChartSeriesType>(series: TSeries, count?: number): TSeries {
    // Add metrics by cloning the last one
    const srcField = series.metrics[series.metrics.length - 1] || {
        function: 'COUNT',
        ref: '_system_id'
    };
    const clonedFields = times(
        count || 1,
        () =>
            JSON.parse(
                JSON.stringify({ ...srcField, sort: undefined, formatting: { color: sample(WIDGET_COLOR_PALETTE) } })
            ) as WidgetMetricField
    );

    // Build field list
    const updatedFields = [...series.metrics, ...clonedFields];

    // Return updated widget
    return { ...series, metrics: updatedFields };
}

// Remove the specified number of metrics and return the updated widget
export function removeWidgetMetric<TSeries extends ChartSeriesType>(series: TSeries, count?: number): TSeries {
    // Remove metrics at the end
    const updatedFields = series.metrics.slice(0, -(count || 1));

    // Return updated widget
    return { ...series, metrics: updatedFields };
}

// Add a number of dimensions to the widget using the last defined dimension
export function addWidgetDimension<TSeries extends ChartSeriesType>(series: TSeries, count?: number): TSeries {
    // Add dimensions by cloning the last one
    const srcField = series.dimensions[series.dimensions.length - 1] || {
        function: 'YEAR_MONTH',
        ref: 'created_at'
    };
    const clonedFields = times(
        count || 1,
        () =>
            JSON.parse(
                JSON.stringify({ ...srcField, sort: undefined, formatting: { color: sample(WIDGET_COLOR_PALETTE) } })
            ) as WidgetMetricField
    );

    // Build field list
    const updatedFields = [...series.dimensions, ...clonedFields];

    // Return updated widget
    return { ...series, dimensions: updatedFields };
}

// Remove the specified number of dimension and return the updated widget
export function removeWidgetDimension<TSeries extends ChartSeriesType>(series: TSeries, count?: number): TSeries {
    // Remove dimensions at the end
    const updatedFields = series.dimensions.slice(0, -(count || 1));

    // Return updated widget
    return { ...series, dimensions: updatedFields };
}

// Ensure the widget has enough dimensions and metrics
export function complyChartSeries<TSeries extends ChartSeriesType>(
    configType: WidgetConfigType,
    series: TSeries,
    callbacks?: SeriesChangedCallbacks
): TSeries {
    // Get widget configuration
    const displayConfig = WIDGET_DISPLAY_CONFIGS[configType];
    let updatedSeries = series;

    // Remove excess metrics
    if (series.metrics.length > displayConfig.rangeMetrics[1]) {
        updatedSeries = removeWidgetMetric(updatedSeries, series.metrics.length - displayConfig.rangeMetrics[1]);
        callbacks?.toastWidgetEditorSuccess('metric-hidden', 'match-insight-requirement');
    }

    // Add missing metrics
    if (series.metrics.length < displayConfig.rangeMetrics[0]) {
        updatedSeries = addWidgetMetric(updatedSeries, displayConfig.rangeMetrics[0] - series.metrics.length);
        callbacks?.toastWidgetEditorSuccess('metric-added', 'match-insight-requirement');
    }

    // Remove excess dimensions
    if (series.dimensions.length > displayConfig.rangeDimensions[1]) {
        updatedSeries = removeWidgetDimension(
            updatedSeries,
            series.dimensions.length - displayConfig.rangeDimensions[1]
        );
        callbacks?.toastWidgetEditorSuccess('dimension-hidden', 'match-insight-requirement');
    }

    // Add missing dimensions
    if (series.dimensions.length < displayConfig.rangeDimensions[0]) {
        updatedSeries = addWidgetDimension(updatedSeries, displayConfig.rangeDimensions[0] - series.dimensions.length);
        callbacks?.toastWidgetEditorSuccess('dimension-added', 'match-insight-requirement');
    }

    // Comply metrics formatting
    updatedSeries = complyMetricsFormatting(updatedSeries, displayConfig) as TSeries;

    // Return updated widget
    return updatedSeries;
}

// When switching widget type, ensure that the previous widget's `colorMode` is compatible with the new widget type.
// If not, force it to the default `colorMode` of the new widget type.
function complyMetricsFormatting(series: ChartSeriesType, displayConfig: WidgetDisplayConfig): ChartSeriesType {
    return {
        ...series,
        metrics: series.metrics.map(e => {
            // If current `colorMode` is allowed for the new widget display config, do nothing
            if (e.formatting?.colorMode && displayConfig.allowedMetricColorModes?.includes(e.formatting?.colorMode))
                return e;

            // Else, set the default `colorMode` for this widget display config
            return {
                ...e,
                formatting: {
                    ...e.formatting,
                    colorMode: displayConfig.customizeMetricColorMode
                }
            };
        })
    };
}

export function complyCardsWidget<TWidget extends WidgetCardsType>(
    configType: WidgetConfigType,
    widget: TWidget,
    callbacks?: SeriesChangedCallbacks
): TWidget {
    // If we switch from MiniCard to LargeCard
    if (isMiniCardWidget(widget) && isLargeCardSeriesType(configType)) {
        let updatedSeries;

        // If there were more than one serie
        if (widget.config.series.length > 1) {
            // Keep only the first serie
            updatedSeries = widget.config.series.slice(0, 1);
            callbacks?.toastWidgetEditorSuccess('mini-to-large-card.columns-hidden');
        } else {
            updatedSeries = widget.config.series;
        }

        // If main filters were configured
        if (widget.config.filters) {
            // Merge them with the first serie's filters and erase the formatting property that only concerns MiniCard widget
            updatedSeries = [
                {
                    ...updatedSeries[0],
                    filters: mergeFilters(updatedSeries[0].filters, widget.config.filters),
                    formatting: undefined
                }
            ];
            callbacks?.toastWidgetEditorSuccess('mini-to-large-card.filters-merged-with-global');
        } else {
            // Keep the filters as are, and just erase the formatting property that only concerns MiniCard widget
            updatedSeries = [
                {
                    ...updatedSeries[0],
                    formatting: undefined
                }
            ];
            callbacks?.toastWidgetEditorSuccess('mini-to-large-card.filters-moved');
        }

        return { ...widget, config: { ...widget.config, filters: undefined, series: updatedSeries } };
    } else if (isLargeCardWidget(widget) && isMiniCardSeriesType(configType)) {
        callbacks?.toastWidgetEditorSuccess('large-to-mini-card');
    }
    return widget;
}

export function mergeFilters(
    filters1: RuleGroupType | undefined,
    filters2: RuleGroupType | undefined
): RuleGroupType | undefined {
    if (filters1 && filters2) {
        // If the two filters use the same combinator
        if (filters1.combinator === filters2.combinator) {
            // We can merge rules directly into the first filters rules
            return {
                ...filters1,
                rules: [...filters1.rules, ...filters2.rules]
            };
        } else {
            // We create a parent "AND" combinator
            return {
                id: 'g-0',
                combinator: 'AND',
                rules: [filters1, filters2]
            };
        }
    } else if (filters1) {
        return filters1;
    } else if (filters2) {
        return filters2;
    }
}

export function switchableSeriesConfigTypes(configType?: WidgetConfigType): WidgetConfigType[] {
    if (!configType) return [];

    // Return the configuration of all widgets compatible with the currently used design mode
    const designMode = WIDGET_DISPLAY_CONFIGS[configType].designMode;
    const applicableTypes = Object.entries(WIDGET_DISPLAY_CONFIGS).filter(
        ([, config]) => config.designMode == designMode
    ) as [WidgetConfigType, WidgetDisplayConfig][];

    // Return the list of config types
    return applicableTypes.map(([id]) => id);
}

/**
 * Replicate series configuration
 *
 * @param series the series to replicate the configuration to
 * @param source the source series where to pick the configuration from
 * @param callbacks the callbacks to execute
 * @returns the updated series with the replicated configuration
 */
export function replicateSeriesConfiguration(
    series: KpiSeriesType,
    source: KpiSeriesType,
    callbacks: SeriesChangedCallbacks
): KpiSeriesType {
    const updatedSeries = {
        // Replicate the filters of the source
        ...pick(source, ['filters']),
        // Replicate the metrics of the source, keeping the original formatting
        metrics: source.metrics.map((e, i) => ({ ...clone(e), formatting: series.metrics[i]?.formatting })),
        // Replicate the dimensions of the source, keeping the original formatting
        dimensions: source.dimensions.map((e, i) => ({ ...clone(e), formatting: series.dimensions[i]?.formatting })),
        // Keep the original limit
        limit: series.limit
    } as KpiSeriesType;

    // Toast if some excess metrics have been removed
    if (series.metrics.length > updatedSeries.metrics.length) {
        callbacks?.toastWidgetEditorSuccess('metric-hidden', 'replicate-configuration');
    }

    // Toast if some missing metrics have been added
    if (series.metrics.length < updatedSeries.metrics.length) {
        callbacks?.toastWidgetEditorSuccess('metric-added', 'replicate-configuration');
    }

    // Toast if some excess dimensions have been removed
    if (series.dimensions.length > updatedSeries.dimensions.length) {
        callbacks?.toastWidgetEditorSuccess('dimension-hidden', 'replicate-configuration');
    }

    // Toast if some missing dimensions have been added
    if (series.dimensions.length < updatedSeries.dimensions.length) {
        callbacks?.toastWidgetEditorSuccess('dimension-added', 'replicate-configuration');
    }

    // Return the updated series
    return updatedSeries;
}

// Change the type of a widget and migrate its series
export function switchWidgetConfigType(
    widget: WidgetFactType,
    newType: WidgetConfigType,
    callbacks?: SeriesChangedCallbacks
): WidgetFactType {
    if (isChartWidget(widget) && isChartSeriesType(newType)) {
        // Change of chart visualization
        const formattedSeries = complyChartSeries(newType, widget.config.series[0], callbacks);
        const formattedWidget = {
            ...widget,
            configType: newType,
            config: { ...widget.config, series: [formattedSeries] }
        } as WidgetChartType;

        return formattedWidget;
    } else if (isKpiWidget(widget) && isKpiSeriesType(newType)) {
        // Enforce limit on the metric series
        const newTypeConfig = WIDGET_DISPLAY_CONFIGS[newType];
        const metricSeries = { ...widget.config.series[0], limit: 1 };

        // Evaluate if the trend series should be included
        const trendLimit = newTypeConfig.trendTrailingPoints || 1;
        const trendSeries = {
            ...complyChartSeries(newType, widget.config.series[1] || metricSeries, callbacks),
            limit: trendLimit
        };
        const series = newTypeConfig.trendTrailingPoints == undefined ? [metricSeries] : [metricSeries, trendSeries];

        // Format the widget
        const formattedWidget = {
            ...widget,
            configType: newType,
            config: { ...widget.config, series }
        } as WidgetKpiType;
        return formattedWidget;
    } else if (isCardsWidget(widget) && isCardsSeriesType(newType)) {
        // Change of cards visualization
        const formattedWidget = {
            ...complyCardsWidget(newType, widget, callbacks),
            configType: newType
        } as WidgetCardsType;
        return formattedWidget;
    } else {
        // Change of chart family
        // This is placeholder logic. A change of family is not supported yet
        const formattedWidget = {
            ...widget,
            configType: newType
        } as WidgetChartType;
        return formattedWidget;
    }
}

// Return the dimensions and metrics as a sorted union array of field
// wrappers.
export function sortedSeriesColumns<TSeries extends ChartSeriesType>(
    configType: WidgetConfigType,
    series: TSeries
): WidgetTypedFieldWrapper[] {
    // Get widget configuration
    const config = WIDGET_DISPLAY_CONFIGS[configType];

    // Wrap fields to include type and local index
    const typedMetrics: WidgetTypedFieldWrapper[] = series.metrics.map((e, i) => {
        return {
            absoluteIndex: 0,
            accessor: `${METRIC_IDENTIFIER}${i}`,
            field: e,
            localIndex: i,
            type: 'metric'
        };
    });
    const typedDimensions: WidgetTypedFieldWrapper[] = series.dimensions.map((e, i) => {
        return {
            absoluteIndex: 0,
            accessor: `${DIMENSION_IDENTIFIER}${i}`,
            field: e,
            localIndex: i,
            type: 'dimension'
        };
    });

    // Rank the fields based on their ranking strategy.
    const rankedList = [...typedDimensions, ...typedMetrics].sort((a, b) => {
        if (config.absoluteRanking) {
            // In absolute ranking mode the fields with no explicit ranks are set with an "Infinity" rank
            // so as to be pushed at the end (= new fields get pushed at the end)
            const aIndex = a.field.formatting?.rank == undefined ? Infinity : a.field.formatting?.rank;
            const bIndex = b.field.formatting?.rank == undefined ? Infinity : b.field.formatting?.rank;
            return aIndex - bIndex;
        } else {
            return 0;
        }
    });

    // Return the wrapped fields and attach their absolute index
    return rankedList.map((e, i) => {
        return { ...e, absoluteIndex: i };
    });
}

// Retrieve all fields filtered on
export const filterFieldList = (
    fieldList: QueryOpField[],
    group?: RuleGroupType | RuleType,
    opts?: { uniq: boolean }
): string[] => {
    if (!group) return [];

    if ('combinator' in group) {
        if (opts?.uniq) {
            return uniq(group.rules.flatMap(e => filterFieldList(fieldList, e, opts))).sort();
        } else {
            return group.rules.flatMap(e => filterFieldList(fieldList, e, opts)).sort();
        }
    } else {
        // If the field is a native field, return it immediately
        const nativeField = fieldList.find(e => e.name == group.field);
        if (nativeField) return [nativeField.label];

        // This may be a custom field. Attempt to look it up by parent name
        const [customFieldName] = customFieldSplitHeadTail(group.field);
        const customField = fieldList.find(e => e.name == customFieldName);
        if (customField) return [customField.label];

        // Otherwise skip this field
        return [];
    }
};
