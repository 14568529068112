import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { SUBSCRIBE_TO_COMPANY_UPDATED } from '../queries/Company';
import { GetCompanyInvitesResp, GET_COMPANY_INVITES, InviteResp } from '../queries/CompanyInvite';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
}

interface CompanyInvitesQueryResults extends QueryResult<GetCompanyInvitesResp, OperationVariables> {
    normalized: InviteResp[];
}

// TODO: implement pagination
export function useCompanyInvites({ companyId, skip }: QueryOpts): CompanyInvitesQueryResults {
    // Retrieve company members
    const rs = useQuery<GetCompanyInvitesResp>(GET_COMPANY_INVITES, {
        skip: skip || !companyId,
        variables: { companyId: companyId }
    });

    // Refetch data when company is updated
    useSafeSubscription(SUBSCRIBE_TO_COMPANY_UPDATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Extract core data
    const normalized = rs.data?.company.requestedInvites.nodes || [];

    return { ...rs, normalized: normalized };
}
