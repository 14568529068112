import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    label: string;
    selected?: boolean;
    onToggle: () => void;
    img?: ReactNodeArray | ReactNode;
}
const DropdownItemCheckbox: FunctionComponent<Props> = ({ label, selected, onToggle, img }: Props) => {
    return (
        <div
            className="w-100 px-3 py-1 d-flex align-items-center text-dark cursor-pointer"
            style={{ height: 35 }}
            onClick={onToggle}
        >
            <Form.Check className="fs-xs me-1" type="checkbox" checked={selected} readOnly />
            {img ? img : null}
            <span className="ms-1">{label}</span>
        </div>
    );
};

export default DropdownItemCheckbox;
