import { QueryOpField } from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { cleanupEmptyRules } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import {
    WidgetChartType,
    WidgetKpiType,
    isKpiWidget,
    ChartSeriesType,
    KpiSeriesType,
    SeriesNDimensionsNMetrics,
    WidgetList,
    DrilldownConfig
} from 'components/Dashboarding/Models/Widget';
import { useRichIntl } from 'components/RichMessage';
import { DateValue, RuleValue } from 'components/RulesetBuilder';
import { KPI_TREND_SERIES_INDEX, CHART_SERIES_INDEX } from 'constants/defaultValues';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { generateID, RuleType, RuleGroupType } from 'react-querybuilder';

interface Props {
    widget: WidgetChartType | WidgetKpiType | undefined;
    onComplete?: (widget: WidgetChartType | WidgetKpiType) => void;
}

interface Results {
    series: ChartSeriesType | KpiSeriesType | undefined;
    isDrilldownEnabledForMetric: (metricIndex: number) => boolean;
    enableDrilldownForMetric: (metricIndex: number) => void;
    disableDrilldownForMetric: (metricIndex: number) => void;
    onDrilldownSeriesChangeForMetric: (series: SeriesNDimensionsNMetrics, metricIndex: number) => void;
    mergeDrilldownConfigAndWidget: (
        drilldownWidget: WidgetList,
        metricIndex: number
    ) => WidgetChartType | WidgetKpiType | undefined;
    getDrilldownWidgetForMetric: (
        metricIndex: number | undefined,
        sourceWidgetName: string | undefined
    ) => WidgetList | undefined;
    applyRecommendedDrilldownConfiguration: (
        source: ChartSeriesType | KpiSeriesType
    ) => ChartSeriesType | KpiSeriesType;
}

export const useDrilldown = ({ widget, onComplete }: Props): Results => {
    // Intl
    const intl = useRichIntl();

    // Get all relevant fields
    const { queryOpFields } = useQueryOpFields({ fact: widget?.config?.fact || 'INBOX_ITEMS' });

    // Series index to target, depending on the widget type
    const seriesIndex = useMemo(() => {
        if (!widget) return;

        return isKpiWidget(widget) ? KPI_TREND_SERIES_INDEX : CHART_SERIES_INDEX;
    }, [widget]);

    // Series to target
    const series = useMemo(() => (seriesIndex != undefined ? widget?.config?.series?.[seriesIndex] : undefined), [
        seriesIndex,
        widget
    ]);

    // Is the drilldown enabled for a given metric
    const isDrilldownEnabledForMetric = useCallback(
        (metricIndex: number) => !!series?.drilldownConfigs?.[metricIndex]?.enabled,
        [series]
    );

    // Apply a recommended configuration to a series based on the following strategy:
    // - copy the dimensions of the source series
    // - copy the filters of the source series
    // - add one recommended filter per dimension, given it is likely to be used as a drilldown filter
    const applyRecommendedDrilldownConfiguration = useCallback(
        (source: ChartSeriesType | KpiSeriesType): ChartSeriesType | KpiSeriesType => {
            const recommendedConfig = {
                ...pick(source, ['filters']),
                dimensions: [...source.dimensions],
                metrics: []
            } as ChartSeriesType | KpiSeriesType;

            const recommendedFilters: RuleType[] = source.dimensions.map((e, index) => {
                // Is it a time dimension
                const field: QueryOpField | undefined = queryOpFields.find(f => f.id == e.ref);

                // Abort if field is not found
                if (!field) return {} as RuleType;

                if (field.type == 'ts') {
                    // If it's a time dimension, generate a IS_DATE filter
                    return {
                        id: generateID(),
                        field: field.name,
                        operator: 'IS_DATE',
                        value: {
                            type: field.type,
                            value: {
                                granularity: e.function,
                                pickedCoordinateIndex: index
                            } as DateValue
                        } as RuleValue
                    } as RuleType;
                } else {
                    // Else, generate a EQUAL filter
                    return {
                        id: generateID(),
                        field: field.name,
                        operator: 'EQUAL',
                        value: {
                            type: field.type,
                            pickedCoordinateIndex: index
                        } as RuleValue
                    } as RuleType;
                }
            });

            // Original source filters
            const sourceFiltersRules = source.filters?.rules ? (source.filters?.rules as Array<RuleGroupType>) : [];

            // Merge filters and return the recommended configuration
            return {
                ...recommendedConfig,
                filters: {
                    ...source.filters,
                    combinator: 'AND',
                    rules: [...sourceFiltersRules, ...(cleanupEmptyRules(recommendedFilters) as Array<RuleGroupType>)]
                }
            };
        },
        [queryOpFields]
    );

    // Enable drilldown for the provided metric index, by setting the `enabled` property to `true`.
    // If no drilldown series exist yet for the provided metric index, we create one
    // and apply the recommended config to it.
    const enableDrilldownForMetric = useCallback(
        (metricIndex: number) => {
            if (!widget || seriesIndex == undefined) return;

            // Update the series
            const updatedSeries = [...widget.config.series];
            const seriesToUpdate = updatedSeries[seriesIndex];

            let updatedDrilldownConfigs;
            if (seriesToUpdate.drilldownConfigs) {
                updatedDrilldownConfigs = [...seriesToUpdate.drilldownConfigs];
            } else {
                updatedDrilldownConfigs = Array(seriesToUpdate.metrics.length);
            }

            // Create the drilldown config from the widget series
            const newDrilldownSeries = applyRecommendedDrilldownConfiguration(seriesToUpdate);

            updatedDrilldownConfigs[metricIndex] = {
                enabled: true,
                series: updatedDrilldownConfigs[metricIndex]?.series || newDrilldownSeries,
                formatting: updatedDrilldownConfigs[metricIndex]?.formatting || { enableStripedRows: true }
            };

            updatedSeries[seriesIndex] = {
                ...seriesToUpdate,
                drilldownConfigs: updatedDrilldownConfigs
            };

            // Update widget
            const updatedWidget = {
                ...widget,
                config: { ...widget.config, series: updatedSeries }
            } as WidgetChartType | WidgetKpiType;

            // Apply changes
            onComplete && onComplete(updatedWidget);
        },
        [onComplete, seriesIndex, widget, applyRecommendedDrilldownConfiguration]
    );

    // Disable drilldown for the provided metric index, by setting the `enabled` property to `false`.
    const disableDrilldownForMetric = useCallback(
        (metricIndex: number) => {
            if (!widget || seriesIndex == undefined) return;

            // Update the series
            const updatedSeries = [...widget.config.series];
            const seriesToUpdate = updatedSeries[seriesIndex];

            if (!seriesToUpdate.drilldownConfigs) return;

            const updatedDrilldownConfigs = [...seriesToUpdate.drilldownConfigs];
            updatedDrilldownConfigs[metricIndex] = {
                ...updatedDrilldownConfigs[metricIndex],
                enabled: false
            };

            updatedSeries[seriesIndex] = {
                ...seriesToUpdate,
                drilldownConfigs: updatedDrilldownConfigs
            };

            // Update widget
            const updatedWidget = {
                ...widget,
                config: { ...widget.config, series: updatedSeries }
            } as WidgetChartType | WidgetKpiType;

            // Apply changes
            onComplete && onComplete(updatedWidget);
        },
        [onComplete, seriesIndex, widget]
    );

    // Hook invoked when the drilldown series get updated
    const onDrilldownSeriesChangeForMetric = useCallback(
        (series: SeriesNDimensionsNMetrics, metricIndex: number) => {
            if (!widget || seriesIndex == undefined) return;

            // Update the series
            const updatedSeries = [...widget.config.series];
            const seriesToUpdate = updatedSeries[seriesIndex];

            if (!seriesToUpdate.drilldownConfigs) return;

            const updatedDrilldownConfigs = Object.assign([], [...seriesToUpdate.drilldownConfigs], {
                [metricIndex]: {
                    ...seriesToUpdate.drilldownConfigs[metricIndex],
                    series: {
                        dimensions: series.dimensions,
                        metrics: series.metrics,
                        filters: series.filters,
                        limit: series.limit
                    }
                }
            });

            updatedSeries[seriesIndex] = {
                ...seriesToUpdate,
                drilldownConfigs: updatedDrilldownConfigs
            };

            // Update widget
            const updatedWidget = {
                ...widget,
                config: { ...widget.config, series: updatedSeries }
            } as WidgetChartType | WidgetKpiType;

            // Apply changes
            onComplete && onComplete(updatedWidget);
        },
        [onComplete, widget, seriesIndex]
    );

    // Merge the provided drilldown config into the widget
    const mergeDrilldownConfigAndWidget = useCallback(
        (drilldownWidget: WidgetList, metricIndex: number) => {
            if (!widget || seriesIndex == undefined) return;

            // Update the series
            const updatedSeries = [...widget.config.series];
            const seriesToUpdate = updatedSeries[seriesIndex];

            if (!seriesToUpdate.drilldownConfigs) return;

            const updatedDrilldownConfig = {
                enabled: true,
                series: { ...drilldownWidget.config.series[0] },
                formatting: {
                    ...drilldownWidget.config.formatting
                }
            } as DrilldownConfig;

            const updatedDrilldownConfigs = Object.assign([], [...seriesToUpdate.drilldownConfigs], {
                [metricIndex]: {
                    ...updatedDrilldownConfig
                }
            });

            updatedSeries[seriesIndex] = {
                ...seriesToUpdate,
                drilldownConfigs: updatedDrilldownConfigs
            };

            // Update widget
            const updatedWidget = {
                ...widget,
                config: { ...widget.config, series: updatedSeries }
            } as WidgetChartType | WidgetKpiType;

            return updatedWidget;
        },
        [seriesIndex, widget]
    );

    // Generate a list widget configuration from the drilldown configuration of the
    // provided metric index. This configuration is then used in the widget preview
    // (drilldown tab) and drilldown modal as a standard widget card.
    const getDrilldownWidgetForMetric = useCallback(
        (metricIndex: number | undefined, sourceWidgetName: string | undefined): WidgetList | undefined => {
            if (
                !widget ||
                !series?.drilldownConfigs?.[metricIndex ?? 0]?.enabled ||
                !series?.drilldownConfigs?.[metricIndex ?? 0]?.series
            )
                return;

            return {
                id: '-',
                name: intl.formatMessage({ id: 'dashboarding.drilldown-widget.title' }, { sourceWidgetName }),
                configType: 'LIST',
                config: {
                    fact: widget.config.fact,
                    series: [series.drilldownConfigs[metricIndex ?? 0].series],
                    formatting: series.drilldownConfigs[metricIndex ?? 0].formatting
                }
            };
        },
        [widget, series?.drilldownConfigs, intl]
    );

    return {
        series,
        isDrilldownEnabledForMetric,
        enableDrilldownForMetric,
        disableDrilldownForMetric,
        onDrilldownSeriesChangeForMetric,
        mergeDrilldownConfigAndWidget,
        getDrilldownWidgetForMetric,
        applyRecommendedDrilldownConfiguration
    };
};
