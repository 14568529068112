/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */

// Originally taken from https://graphql-ruby.org/javascript_client/apollo_subscriptions#apollo-2--pusher
// An Apollo Link for using graphql-pro's Pusher subscriptions
//
// @example Adding subscriptions to a HttpLink
//   // Load Pusher and create a client
//   import Pusher from "pusher-js"
//   var pusherClient = new Pusher("your-app-key", { cluster: "us2" })
//
//   // Build a combined link, initialize the client:
//   const pusherLink = new PusherLink({pusher: pusherClient})
//   const link = ApolloLink.from([authLink, pusherLink, httpLink])
//   const client = new ApolloClient(link: link, ...)
//
// @example Building a subscription, then subscribing to it
//  subscription = client.subscribe({
//    variables: { room: roomName},
//    query: gql`
//      subscription MessageAdded($room: String!) {
//        messageWasAdded(room: $room) {
//          room {
//            messages {
//              id
//              body
//              author {
//                screenname
//              }
//            }
//          }
//        }
//      }
//       `
//   })
//
//   subscription.subscribe({ next: ({data, errors}) => {
//     // Do something with `data` and/or `errors`
//   }})
//
import { Pusher } from 'pusher-js';
import { ApolloLink, Observable, Operation, NextLink } from 'apollo-link';
import { Observer } from 'zen-observable-ts';

// Keep a count of how many components in the React app have subscribed to a specific Pusher channel.
// We unsubscribe from a Pusher channel only if there are no more component subscriptions listening
// to this channel.
const COMPONENT_REGISTRY: { [key: string]: number } = {};

// Flag that a component just checked in on a specific channel
const channelCheckIn = (channel: string): void => {
    COMPONENT_REGISTRY[channel] = (COMPONENT_REGISTRY[channel] || 0) + 1;
};

// Flag that a component just checked out on a specific channel
const channelCheckOut = (channel: string): void => {
    COMPONENT_REGISTRY[channel] = Math.max(...[(COMPONENT_REGISTRY[channel] || 1) - 1, 0]);
    if (COMPONENT_REGISTRY[channel] === 0) delete COMPONENT_REGISTRY[channel];
};

// Return true if a pusher channel has no more component subscriptions
const isChannelEmpty = (channel: string): boolean => {
    return !COMPONENT_REGISTRY[channel] || COMPONENT_REGISTRY[channel] <= 0;
};

// This is the initial object send back to subscriptions upon success
// We use a proxy that responds to every attribute so that Apollo does
// not winge that some fields are missing from the subscription query response
const ackResponse = (): object => {
    return new Proxy(
        {},
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            get: function(target: any, name: any): any {
                // eslint-disable-next-line no-prototype-builtins
                return target.hasOwnProperty(name) ? target[name] : null;
            }
        }
    );
};

class PusherLink extends ApolloLink {
    private pusher: Pusher;

    public constructor(options: { pusher: Pusher }) {
        super();

        // Retain a handle to the Pusher client
        this.pusher = options.pusher;
    }

    public request(operation: Operation, forward: NextLink): Observable<{}> {
        const subscribeObservable = new Observable<{}>(() => {});

        // Capture the super method
        const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable);

        // Override subscribe to return an `unsubscribe` object, see
        // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
        subscribeObservable.subscribe = (observerOrNext, onError, onComplete) => {
            // Call super
            prevSubscribe(observerOrNext, onError, onComplete);

            // Get request observer
            const observer = getObserver(observerOrNext, onError, onComplete);
            let subscriptionChannel: string | null = null;

            // Check the result of the operation
            const resultObservable = forward(operation);

            // When the operation is done, try to get the subscription ID from the server
            resultObservable.subscribe({
                next: data => {
                    // If the operation has the subscription header, it's a subscription
                    const response = operation.getContext().response;

                    // Check to see if the response has the header
                    subscriptionChannel = response.headers.get('X-Subscription-Channel');

                    if (subscriptionChannel) {
                        // Set up the pusher subscription for updates from the server
                        const pusherChannel = this.pusher.subscribe(subscriptionChannel);

                        // Notify the registry that a new component has subscribed
                        channelCheckIn(subscriptionChannel);

                        // Subscribe for more update
                        pusherChannel.bind('update', function(payload) {
                            // Send the response to listeners
                            observer && observer.next && observer.next(payload);
                        });

                        // Send initial empty payload to let the useSubscription hook know
                        // that the subscription is successful
                        observer && observer.next && observer.next({ data: ackResponse() });
                    } else {
                        // This isn't a subscription,
                        // So pass the data along and close the observer.
                        observer && observer.next && observer.next(data);
                        observer && observer.complete && observer.complete();
                    }
                },
                error: error => {
                    observer && observer.error && observer.error(error);
                }
            });

            return {
                unsubscribe: () => {
                    if (!subscriptionChannel) return;

                    // Notify the registry that a component has unsubscribed
                    channelCheckOut(subscriptionChannel);

                    // Remove the Pusher subscription if no components are subscribed
                    isChannelEmpty(subscriptionChannel) && this.pusher.unsubscribe(subscriptionChannel);
                },
                closed: false
            };
        };

        return subscribeObservable;
    }
}

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L329-L343
function getObserver(
    observerOrNext: ((value: {}) => void) | Observer<{}>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: ((error: any) => void) | undefined,
    onComplete: (() => void) | undefined
): Observer<{}> {
    if (typeof observerOrNext === 'function') {
        // Duck-type an observer
        return {
            next: v => observerOrNext(v),
            error: e => onError && onError(e),
            complete: () => onComplete && onComplete()
        };
    } else {
        // Make an object that calls to the given object, with safety checks
        return {
            next: v => observerOrNext.next && observerOrNext.next(v),
            error: e => observerOrNext.error && observerOrNext.error(e),
            complete: () => observerOrNext.complete && observerOrNext.complete()
        };
    }
}

export default PusherLink;
