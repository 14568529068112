import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RichMessage } from 'components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import {
    BillingSubscriptionWithTier,
    BILLING_SUBSCRIPTION_REF_PAID,
    SwitchBillingSubscriptionPlanResp,
    SWITCH_BILLING_SUBSCRIPTION_PLAN
} from 'api/hq/queries/BillingSubscription';
import { ErrorMessage, QueryError } from 'components/ErrorManagement';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import CreditCardForm from 'components/CreditCard/CreditCardForm';
import { useSafeState } from 'util/useSafeState';
import CreditCardItem from 'components/CreditCard/CreditCardItem';
import { Button, Col, Row } from 'react-bootstrap';
import { AsyncButton } from 'components/AsyncButton';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    billingSubscription: BillingSubscriptionWithTier;
    onContinue?: () => void | Promise<void>;
}

const PlanRequiredModal: FunctionComponent<Props> = ({ isOpen, toggle, billingSubscription, onContinue }: Props) => {
    // Context
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // State
    const [isProcessing, setIsProcessing] = useSafeState<boolean>(false);
    const [isCardEdit, setIsCardEdit] = useState<boolean | null>(null);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);

    // Mutations
    const [switchBillingSubscriptionPlan] = useMutation<SwitchBillingSubscriptionPlanResp>(
        SWITCH_BILLING_SUBSCRIPTION_PLAN
    );

    // Display context
    const isUsingExistingCc = billingSubscription?.billingCard && !isCardEdit;

    // On proceed we upgrade the subscription then invoke the original
    // onContinue hook (e.g. enablement of projects) then close the modal.
    const handleProceed = async (): Promise<void> => {
        setIsProcessing(true);

        try {
            // Update billing subscription to team plan
            const resp = await switchBillingSubscriptionPlan({
                variables: { companyId: company?.id, subscriptionRef: BILLING_SUBSCRIPTION_REF_PAID[0] }
            });
            const respContent = resp.data?.switchBillingSubscriptionPlan;

            // Abort if subscription could not be updated
            if (!respContent?.success) {
                const error = respContent?.errors[0] || { code: 'default' };
                setMutationError(error);
                setIsProcessing(false);
                return;
            }

            // Success
            setMutationError(undefined);
            onContinue && (await onContinue());
            toggle();
            setIsProcessing(false);
        } catch (e) {
            setMutationError({ code: 'default' });
            setIsProcessing(false);
        }
    };

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            {/* Modal Title */}
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowAltCircleUp} className="text-primary me-3" size="2x" />
                    <RichMessage id="components.billing-guard.plan-required-modal.title" />
                </Modal.Title>
            </Modal.Header>

            {/* Modal Body */}
            <Modal.Body className="px-5 pb-3">
                {/* Explanation on why they need to upgrade */}
                <div className="mb-4">
                    <RichMessage id={`components.billing-guard.plan-required-modal.body`} />
                </div>

                {/* Update error */}
                {mutationError && (
                    <div className="mb-2">
                        <ErrorMessage
                            error={{ ...mutationError, namespace: 'upgrade-subscription' }}
                            className="text-danger"
                        />
                    </div>
                )}

                {/*  Credit card form */}
                {isUsingExistingCc ? (
                    <CreditCardItem
                        billingSubscription={billingSubscription}
                        onEdit={() => setIsCardEdit(true)}
                        displayMode="compact"
                    />
                ) : (
                    <CreditCardForm
                        displayMode="compact"
                        billingSubscription={billingSubscription}
                        onSave={handleProceed}
                        onCancelNew={toggle}
                        onCancelEdit={() => setIsCardEdit(false)}
                        btnConfig={{
                            saveKey: 'components.billing-guard.plan-required-modal.btn-save-cc-edit',
                            savingKey: 'components.billing-guard.plan-required-modal.btn-proceeding',
                            cancelNewKey: 'components.billing-guard.plan-required-modal.btn-cancel',
                            cancelEditKey: 'components.billing-guard.plan-required-modal.btn-cancel-cc-edit'
                        }}
                        disabled={isProcessing}
                    />
                )}
            </Modal.Body>

            {/* Actions - Only displayed if an existing credit card is present */}
            {isUsingExistingCc && (
                <Modal.Footer>
                    <Row className="w-100">
                        <Col md="6">
                            {/* Cancel button */}
                            <Button
                                variant="outline-dark"
                                className="w-100 justify-content-center"
                                onClick={toggle}
                                disabled={isProcessing}
                                track-id="plan-required-modal/cancel"
                            >
                                <RichMessage id="components.billing-guard.plan-required-modal.btn-cancel" />
                            </Button>
                        </Col>
                        <Col md="6">
                            <AsyncButton
                                variant="primary"
                                className="w-100 justify-content-center"
                                onClick={handleProceed}
                                track-id="plan-required-modal/proceed"
                                loading={isProcessing}
                                disabled={isProcessing}
                                messageProps={{ id: 'components.billing-guard.plan-required-modal.btn-proceed' }}
                                loadingMessageProps={{
                                    id: 'components.billing-guard.plan-required-modal.btn-proceeding'
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default PlanRequiredModal;
