import React, { FunctionComponent } from 'react';
import { Progress } from 'reactstrap';
import { useSafeState } from 'util/useSafeState';

interface Props {
    show?: boolean;
    minDuration?: number;
    wrap?: true;
}

export const LineLoadIndicator: FunctionComponent<Props> = ({ show, minDuration = 1000, wrap }: Props) => {
    // State
    const [showIndicator, setShowIndicator] = useSafeState<boolean | undefined>(false);

    // Ensure load indicator has a minimum duration
    if (showIndicator && !show) {
        if (minDuration && minDuration > 0) {
            setTimeout(() => setShowIndicator(show), minDuration);
        } else {
            setShowIndicator(show);
        }
    } else if (!showIndicator && show) {
        setShowIndicator(show);
    }

    return (
        <div style={{ height: wrap ? '3px' : '' }} className="w-100">
            {showIndicator ? <Progress animated value="100" color="info" className="progress-no-height w-100" /> : null}
        </div>
    );
};
