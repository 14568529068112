import React, { FunctionComponent, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faChevronRight,
    faCommentDots,
    faCreditCard,
    faExchangeAlt,
    faSignOutAlt,
    faThLarge,
    faUser,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { RichMessage } from 'components/RichMessage';
import { Nav, Spinner } from 'react-bootstrap';
import { NavLink, Path, To, useLocation } from 'react-router-dom';
import {
    APP_MANAGEMENT_PATH,
    SUBSCRIPTION_PATH,
    LOGOUT_REDIRECTION_PATH,
    TEAM_MANAGEMENT_PATH,
    USER_ACCOUNT_PATH,
    dashboardViewPath,
    COLLECTIONS_ROOT_PATH
} from 'constants/routeBuilders';
import { ReduxState } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'util/ArrayOperators';
import { LOGIN_USER_SUCCESS, LOGOUT_USER } from 'constants/actionTypes';
import { useDashboards } from 'api/hq/hooks/useDashboards';
import classnames from 'classnames';
import IconRenderer from 'components/Design/ColorPickers/IconRenderer';
import { kpCollections } from 'util/customIcons';
import { ELEVIO_FEEDBACK_ID, ElevioClient } from 'util/useElevio';

interface Props {
    className?: string;
}

// The TopNav menu is displayed in mobile version.
// For the tablet/desktop menu, see Sidebar.
const TopNav: FunctionComponent<Props> = ({ className }: Props) => {
    // State
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isSwitchCompanyOn, setIsSwitchCompanyOn] = useState<boolean>(false);

    // Global state
    const authUser = useSelector((e: ReduxState) => e.authUser);
    const dispatch = useDispatch();

    // Services
    const location = useLocation();

    // Load the dashboards
    const { normalized: dashboards, loading: dashboardsLoading } = useDashboards({ pinned: true });

    // Abort if no user
    if (!authUser.apiUser || !authUser.company) return null;

    // Extract data
    const apiUser = authUser.apiUser;
    const user = apiUser.user;
    const selectedCompany = authUser.company;
    const companies = user.memberships.nodes.map(e => e.company).sort(sortBy('name'));
    const currentCompany = companies.find(e => e.id === selectedCompany.id) || { id: null, name: null };
    const isMultiCompany = companies.length > 1;

    // Toggle menu appearance
    const toggleMenu = (): void => {
        setIsSwitchCompanyOn(false);
        setIsOpen(!isOpen);
    };

    // Return true if the top menu passed as parameter is currently active
    const isMenuActive = (path: To): boolean => {
        return (
            location.pathname.startsWith(path as string) ||
            location.pathname.startsWith((path as Partial<Path>).pathname || '')
        );
    };

    // Logout action
    const handleLogout = (): void => {
        dispatch({ type: LOGOUT_USER });
        window.location.href = LOGOUT_REDIRECTION_PATH;
    };

    // Return true if the provided company is the current one
    const isCurrentCompany = (e: { id: string }): boolean => {
        return e.id === currentCompany.id;
    };

    // Switch user to selected company
    const switchCompany = (companyId: string): void => {
        dispatch({ type: LOGIN_USER_SUCCESS, payload: { ...apiUser, currentCompanyId: companyId } });
        toggleMenu();
    };

    return (
        <nav className={classNames('navbar fixed-top', className)}>
            {/* Burger menu */}
            <div onClick={toggleMenu} className="mx-3">
                <FontAwesomeIcon icon={faBars} size="2x" />
            </div>

            {/* Foldable menu - Regular Mode*/}
            {isOpen && !isSwitchCompanyOn && (
                <Nav className="list-unstyled h-100 w-100 flex-column d-flex">
                    {/* List of dashboards */}
                    <hr className="my-2" />
                    {dashboardsLoading ? (
                        <Spinner animation="border" variant="white" className="mx-auto mt-2" />
                    ) : dashboards.length > 0 ? (
                        <>
                            {dashboards.map(e => (
                                <NavLink key={e.id} to={dashboardViewPath({ dashboardId: e.id })} onClick={toggleMenu}>
                                    <Nav.Item
                                        key={e.id}
                                        className={classnames('selectable-item d-flex w-100', {
                                            active: isMenuActive(dashboardViewPath({ dashboardId: e.id }))
                                        })}
                                        title={e.name}
                                    >
                                        <div className="menu-icon me-2">
                                            <IconRenderer icon={e.icon?.ref} />
                                        </div>
                                        {e.name}
                                    </Nav.Item>
                                </NavLink>
                            ))}
                            {/* Collections Section */}
                            <hr className="my-2" />
                        </>
                    ) : null}

                    {/* Collections */}
                    <Nav.Item className={classNames({ active: isMenuActive(COLLECTIONS_ROOT_PATH) })}>
                        <NavLink to={COLLECTIONS_ROOT_PATH} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={kpCollections} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.collections" />
                        </NavLink>
                    </Nav.Item>

                    {/* Feedback */}
                    <Nav.Item
                        className={classNames({ active: isMenuActive(COLLECTIONS_ROOT_PATH) })}
                        onClick={() => {
                            ElevioClient.openModule(ELEVIO_FEEDBACK_ID);
                            toggleMenu();
                        }}
                    >
                        <FontAwesomeIcon icon={faCommentDots} className="me-2" />
                        <RichMessage id="menu.feedback" />
                    </Nav.Item>

                    {/* Account section */}
                    <hr className="my-2" />

                    {/* User Account */}
                    <Nav.Item className={classNames({ active: isMenuActive(USER_ACCOUNT_PATH) })}>
                        <NavLink to={USER_ACCOUNT_PATH} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faUser} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.account" />
                        </NavLink>
                    </Nav.Item>

                    {/* App Management */}
                    <Nav.Item className={classNames({ active: isMenuActive(APP_MANAGEMENT_PATH) })}>
                        <NavLink to={APP_MANAGEMENT_PATH} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faThLarge} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.apps-and-projects" />
                        </NavLink>
                    </Nav.Item>

                    {/* Billing */}
                    <Nav.Item className={classNames({ active: isMenuActive(SUBSCRIPTION_PATH) })}>
                        <NavLink to={SUBSCRIPTION_PATH} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faCreditCard} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.billing" />
                        </NavLink>
                    </Nav.Item>

                    {/* Team Management */}
                    <Nav.Item className={classNames({ active: isMenuActive(TEAM_MANAGEMENT_PATH) })}>
                        <NavLink to={TEAM_MANAGEMENT_PATH} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faUsers} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.team-management" />
                        </NavLink>
                    </Nav.Item>

                    {/* Switch Company */}
                    {isMultiCompany && (
                        <Nav.Item onClick={() => setIsSwitchCompanyOn(true)}>
                            <FontAwesomeIcon icon={faExchangeAlt} className="me-2" size="sm" />
                            <RichMessage id="top-nav.user-menu.switch-company" />
                            <span className="text-muted ms-1">({currentCompany.name})</span>
                        </Nav.Item>
                    )}

                    {/* Sign out Section */}
                    <hr className="my-2" />

                    <Nav.Item onClick={handleLogout} track-id="click-logout">
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" size="sm" />
                        <RichMessage id="top-nav.user-menu.sign-out" />
                    </Nav.Item>
                </Nav>
            )}

            {/* Foldable menu - Switch Company Mode*/}
            {isOpen && isSwitchCompanyOn && (
                <Nav className="list-unstyled h-100">
                    {companies.map(e => {
                        return (
                            <Nav.Item
                                onClick={() => switchCompany(e.id)}
                                key={e.id}
                                className={isCurrentCompany(e) ? 'font-weight-bold' : undefined}
                            >
                                {isCurrentCompany(e) && <FontAwesomeIcon icon={faChevronRight} className="me-1" />}
                                {e.name}
                            </Nav.Item>
                        );
                    })}

                    {/* Cancel Section */}
                    <hr className="mb-3" />

                    <Nav.Item onClick={() => setIsSwitchCompanyOn(false)}>
                        <RichMessage id="top-nav.user-menu.cancel" />
                    </Nav.Item>
                </Nav>
            )}
        </nav>
    );
};

export default TopNav;
