import React, { FunctionComponent, Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_START_PATH } from 'constants/routeBuilders';
import { APP_IMAGES } from 'constants/defaultValues';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import {
    LINK_INTEGRATION_USER,
    LinkIntegrationUserResp,
    GET_INTEGRATION,
    GetIntegrationResp
} from 'api/hq/queries/Integration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSadCry } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';
import FullPageLoader from 'components/Design/FullPageLoader';

const LinkUser: FunctionComponent = () => {
    // Services
    const params = useParams() as { id: string; token: string };
    const navigate = useNavigate();

    // Capture URL parameters
    const integrationId = params.id;
    const token = params.token;

    // Declare state
    const [linkSuccess, setLinkSuccess] = useState<boolean>(false);
    const [linkInProgress, setLinkInProgress] = useState<boolean>(false);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);

    // Retrieve integration
    const { data, loading } = useQuery<GetIntegrationResp>(GET_INTEGRATION, {
        skip: !integrationId,
        variables: { id: integrationId }
    });

    // Declare link user mutation
    const [linkUserQuery] = useMutation<LinkIntegrationUserResp>(LINK_INTEGRATION_USER);

    // Extract data
    const app = data && data.integration ? data.integration.app : null;
    const company = data && data.integration ? data.integration.company : null;
    const appImg = app ? APP_IMAGES[app.provider] : undefined;

    // Hande invalid link
    if (!data?.integration && !mutationError) {
        setMutationError({ code: 'integration-not-found' });
    }

    // Confirm user linkage
    const confirmLinkage = async (integrationId: string, token: string): Promise<void> => {
        setLinkInProgress(true);

        try {
            const resp = await linkUserQuery({ variables: { integrationId, token } });
            const linkResp = resp.data?.linkIntegrationUser;

            if (linkResp?.success) {
                // Flag action as successful
                setLinkSuccess(true);

                // Redirect to start path after displayin the confirmation message
                setTimeout(() => navigate(DEFAULT_START_PATH, { replace: true }), 3000);
            } else {
                // Trigger error page
                setMutationError({ code: 'expired' });
            }
        } catch {
            setMutationError({ code: 'default' });
        } finally {
            setLinkInProgress(false);
        }
    };

    // Display loading screen if the initial query is loading
    if (loading || linkInProgress) return <FullPageLoader />;

    return (
        <Fragment>
            <div className="container">
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <Card className="text-center pt-2 pb-5 w-75 w-lg-50 text-dark">
                        {app && company && !mutationError ? (
                            <>
                                <Card.Header className="bg-white pt-5 pb-4">
                                    {/* Flow title */}
                                    <Card.Title className="mt-2 mb-4">
                                        <div className="py-3">
                                            <img alt={app.name} src={appImg} height="60" />
                                        </div>
                                        <h4>
                                            <FormattedMessage
                                                id="flows.link-user.title"
                                                values={{ appName: app.name, companyName: company.name }}
                                            />
                                        </h4>
                                    </Card.Title>
                                </Card.Header>

                                <Card.Body className="pt-5 d-flex justify-content-center">
                                    {/* Confirm button or success message */}
                                    {linkSuccess ? (
                                        <h5 className="text-success mt-5 mb-5">
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                            <FormattedMessage id="flows.link-user.confirmed" />
                                        </h5>
                                    ) : (
                                        <Button
                                            variant="dark"
                                            size="lg"
                                            className="mt-4 mb-4"
                                            onClick={() => confirmLinkage(integrationId, token)}
                                        >
                                            <FormattedMessage id="flows.link-user.confirm" />
                                        </Button>
                                    )}
                                </Card.Body>
                            </>
                        ) : (
                            <>
                                <Card.Header className="bg-white pt-5 pb-4">
                                    <Card.Title className="mt-2 mb-4">
                                        <FontAwesomeIcon icon={faSadCry} size="4x" className="text-primary mb-2" />

                                        <h2>
                                            <FormattedMessage id="flows.link-user.error.title" />
                                        </h2>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="pt-5">
                                    <ErrorMessage error={{ ...mutationError, namespace: 'link-connector-user' }} />
                                </Card.Body>
                            </>
                        )}
                    </Card>
                </div>
            </div>

            <div className="fixed-bottom text-center text-muted">
                <div className="mb-5">
                    <KeypupLogo height={32} className="mb-2" />
                    <p>
                        <em>
                            <FormattedMessage id="generic.footer" />
                        </em>
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default LinkUser;
