import React, { ReactNodeArray, Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { appConnectionPath, APP_MANAGEMENT_APPS_PATH, APP_MANAGEMENT_PROJECTS_PATH } from 'constants/routeBuilders';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { ExternalLink } from 'components/ExternalLink';

interface AppReconnectionLinkProps {
    provider: string;
    children: ReactNodeArray;
}

const AppReconnectionLink: FunctionComponent<AppReconnectionLinkProps> = ({
    provider,
    children
}: AppReconnectionLinkProps) => {
    // Get company
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Abort if no company
    if (!company) return null;

    // Reconnection link
    const href = appConnectionPath({
        provider: provider,
        companyId: company?.id,
        returnTo: `${APP_MANAGEMENT_PROJECTS_PATH}?app=${provider}`,
        reconnect: true
    });

    return (
        <a href={href} className="hover-underline text-primary">
            {children}
        </a>
    );
};

// Helper method used to generate the link tag function
const appReconnectionLinkTag = (provider: string): (() => JSX.Element) => {
    return (...chunks: ReactNodeArray): JSX.Element => {
        return <AppReconnectionLink provider={provider}>{chunks}</AppReconnectionLink>;
    };
};

// Helper method used to generate the link tag function
const externalLinkTag = (href: string): (() => JSX.Element) => {
    return (...chunks: ReactNodeArray): JSX.Element => {
        return <ExternalLink href={href}>{chunks}</ExternalLink>;
    };
};

export const RICH_TAGS = {
    break: <br />,
    space: <span className="ms-1"></span>,
    faArrowRight: <FontAwesomeIcon icon={faLongArrowAltRight} className="mx-1" />,
    break2: (
        <Fragment>
            <br />
            <br />
        </Fragment>
    ),
    b: (...chunks: ReactNodeArray) => chunks.map((e, i) => <b key={i}>{e}</b>),
    em: (...chunks: ReactNodeArray) => chunks.map((e, i) => <em key={i}>{e}</em>),
    pre: (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <pre className="d-inline text-light-purple fw-bold" key={i}>
                {e}
            </pre>
        )),
    'date-ll': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <Moment key={i} format="ll">
                {e?.toString()}
            </Moment>
        )),
    'from-now': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <Moment key={i} fromNow>
                {e?.toString()}
            </Moment>
        )),
    'fw-light': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="fw-light" key={i}>
                {e}
            </span>
        )),
    'text-muted': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-muted" key={i}>
                {e}
            </span>
        )),
    'text-light': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-light" key={i}>
                {e}
            </span>
        )),
    'text-light-turquoise': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-light-turquoise" key={i}>
                {e}
            </span>
        )),
    'text-grey-4': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-grey-4" key={i}>
                {e}
            </span>
        )),
    'text-danger': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-danger" key={i}>
                {e}
            </span>
        )),
    'text-warning': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-warning" key={i}>
                {e}
            </span>
        )),
    'text-success': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-success" key={i}>
                {e}
            </span>
        )),
    'text-secondary': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-secondary" key={i}>
                {e}
            </span>
        )),
    'text-primary': (...chunks: ReactNodeArray) =>
        chunks.map((e, i) => (
            <span className="text-primary" key={i}>
                {e}
            </span>
        )),
    'link-to-app-connect': (...chunks: ReactNodeArray) => (
        <Link to={APP_MANAGEMENT_APPS_PATH} className="hover-underline text-primary">
            {chunks}
        </Link>
    ),
    'link-to-helpcenter-managing-identities': externalLinkTag(
        'https://docs.keypup.io/en/articles/219-managing-app-identities'
    ),
    'link-to-helpcenter-priority-inbox': externalLinkTag(
        'https://help.keypup.io/en/articles/3925166-what-is-the-priority-inbox'
    ),
    'link-to-helpcenter-prioritization-rules': externalLinkTag(
        'https://help.keypup.io/en/articles/3925166-what-is-the-priority-inbox'
    ),
    'link-to-helpcenter-connect-apps': externalLinkTag(
        'https://docs.keypup.io/en/articles/33-connect-apps-to-your-workspace'
    ),
    'link-to-helpcenter-connect-projects': externalLinkTag(
        'https://docs.keypup.io/en/articles/26-connect-your-projects'
    ),
    'link-to-helpcenter-team-management': externalLinkTag(
        'https://docs.keypup.io/en/articles/11-introduction-to-teams-on-keypup'
    ),
    'link-to-helpcenter-integration-github': externalLinkTag(
        'https://docs.keypup.io/en/articles/21-github-integration'
    ),
    'link-to-helpcenter-integration-gitlab': externalLinkTag(
        'https://docs.keypup.io/en/articles/17-gitlab-integration'
    ),
    'link-to-helpcenter-integration-slack': externalLinkTag(
        'https://help.keypup.io/en/articles/3959376-slack-integration'
    ),
    'link-to-helpcenter-integration-jira': externalLinkTag('https://docs.keypup.io/en/articles/23-jira-integration'),
    'link-to-helpcenter-integration-clickup': externalLinkTag(
        'https://docs.keypup.io/en/articles/24-clickup-integration'
    ),
    'link-to-helpcenter-integration-trello': externalLinkTag(
        'https://docs.keypup.io/en/articles/28-trello-integration'
    ),
    'link-to-helpcenter-integration-bitbucket': externalLinkTag(
        'https://docs.keypup.io/en/articles/18-bitbucket-integration'
    ),
    'link-to-helpcenter-activity-funnel': externalLinkTag('https://help.keypup.io/en/articles/5759639-activity-funnel'),
    'link-to-add-instance-jira': appReconnectionLinkTag('jira')
};
