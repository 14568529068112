import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { GetIdentitiesResp, GET_USER_IDENTITIES } from '../queries/Identity';

interface QueryOpts {
    userId?: string;
    skip?: boolean;
}

export function useUserIdentities({ userId, skip }: QueryOpts): QueryResult<GetIdentitiesResp, OperationVariables> {
    return useQuery<GetIdentitiesResp>(GET_USER_IDENTITIES, { variables: { userId: userId }, skip: !userId || skip });
}
