import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface Props {
    isOpen: boolean;
    icon?: IconDefinition;
    iconColor?: string;
    title: ReactNode;
    content: ReactNode;
    customConfirmButton?: ReactNode;
    onCancel: () => void;
    onConfirm?: () => void;
}

export const ConfirmationModal: FunctionComponent<Props> = ({
    isOpen,
    icon,
    iconColor,
    title,
    content,
    customConfirmButton,
    onCancel,
    onConfirm
}: Props) => {
    return (
        <Modal show={isOpen} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* Icon */}
                    {icon && <FontAwesomeIcon icon={icon} color={iconColor} className="me-3" />}

                    {/* Title */}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 pb-3">{content}</Modal.Body>
            <Modal.Footer>
                <Row className="w-100">
                    <Col md="6">
                        {/* Cancel button */}
                        <Button variant="outline-dark" className="w-100 justify-content-center" onClick={onCancel}>
                            <FormattedMessage id="component.confirmation-modal.cancel-button" />
                        </Button>
                    </Col>
                    <Col md="6">
                        {/* Confirm button, either the default synchronous one, or a custom one for asynchronous */}
                        {customConfirmButton || (
                            <Button variant="danger" className="w-100 justify-content-center" onClick={onConfirm}>
                                <FormattedMessage id="component.confirmation-modal.confirm-button" />
                            </Button>
                        )}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
