import React, { ReactNode, ReactNodeArray, FunctionComponent, useCallback } from 'react';
import NavigationalTabs, { NavigationalTab } from 'components/Design/NavigationalTabs';
import { useNavigate } from 'react-router-dom';
import TopNavBar from 'components/Design/TopNavBar';
import { RichMessage } from 'components/RichMessage';
import Container from 'react-bootstrap/Container';
import { USER_ACCOUNT_PATH, SUBSCRIPTION_PATH, INVOICES_PATH } from 'constants/routeBuilders';
import { useTitleLabel } from 'util/useTitleLabel';

const TABS = [
    {
        id: 'settings.user-account.tab.general',
        name: 'general',
        to: USER_ACCOUNT_PATH
    },
    {
        id: 'settings.user-account.tab.subscription',
        name: 'subscription',
        to: SUBSCRIPTION_PATH
    },
    {
        id: 'settings.user-account.tab.invoices',
        name: 'invoices',
        to: INVOICES_PATH
    }
];

interface Props {
    id: string; // active tab id. e.g. 'general'
    actions?: ReactNode | ReactNodeArray;
    children: ReactNode | ReactNodeArray;
}

// Account Settings Layout
//
// Usage:
//
// <Layout id="general" actions={<Button>action</Button>}>
//    {your_content}
// </Layout>
//
const AccountSettingsLayout: FunctionComponent<Props> = ({ children, id, actions }) => {
    // Page title
    useTitleLabel('settings.account-settings.title');

    // Services
    const navigate = useNavigate();

    // navigate to another tab url
    const switchTab = useCallback(
        (tab: NavigationalTab): void => {
            navigate(tab.to);
        },
        [navigate]
    );

    return (
        <>
            {/* Top bar */}
            <TopNavBar>
                <h4 className="w-100">
                    <RichMessage id="settings.account-settings.title" />
                </h4>
                {actions}
            </TopNavBar>
            <Container>
                {/* Navigation tabs */}
                <NavigationalTabs onSelect={switchTab} className="mb-4" tabs={TABS} activeTab={id} />
                {children}
            </Container>
        </>
    );
};

export default AccountSettingsLayout;
