import { faSadCry } from '@fortawesome/free-regular-svg-icons';
import { faLock, faMapSigns, faNotEqual, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { ErrorConfigOptions } from 'components/ErrorManagement';

// Object containing configurations for specific error codes
// e.g. ERROR_CONFIG[namespace][code][attribute]
export const ERROR_CONFIG: { [key: string]: { [key: string]: ErrorConfigOptions } } = {
    generic: {
        default: { icon: faSadCry }
    },
    public: {
        'page-not-found': { icon: faMapSigns },
        'identity-taken': {
            icon: faLock,
            defaultContext: { provider: 'provider' }
        },
        'integration-reconnect-mismatch': {
            icon: faNotEqual,
            defaultContext: { provider: 'this app' }
        },
        access_denied: {
            icon: faSignInAlt,
            defaultContext: { provider: 'this provider' }
        },
        'access_denied:invalid_endpoint': {
            icon: faSignInAlt,
            defaultContext: { provider: 'this provider' }
        },
        'access_denied:instance_unreachable': {
            icon: faSignInAlt,
            defaultContext: { provider: 'hosted' }
        },
        'access_denied:user_profile_fetch_failed': {
            icon: faSignInAlt,
            defaultContext: { provider: 'hosted' }
        }
    }
};
