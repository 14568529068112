import React, { FunctionComponent, useCallback } from 'react';
import { MetricColorMode, WidgetHeatmapChart } from 'components/Dashboarding/Models/Widget';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import Form from 'react-bootstrap/Form';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';
import { WIDGET_GRADIENT_COLORS, WIDGET_THRESHOLD_COLORS } from 'constants/colors';
import ThresholdColorConfig from 'components/Design/ColorPickers/ThresholdColorConfig';
import GradientColorConfig from 'components/Design/ColorPickers/GradientColorConfig';
import { FormattedMessage } from 'react-intl';
import { useCustomizeChart } from './useCustomizeChart';
import { useColorRanges } from './useColorRanges';

interface Props {
    widget: WidgetHeatmapChart;
    onChange: (widget: WidgetHeatmapChart) => void;
}

const CustomizeHeatmap: FunctionComponent<Props> = ({ widget, onChange }: Props) => {
    // Services
    const intl = useRichIntl();

    const {
        localWidget,
        mainSeries,
        displayConfig,
        onWidgetFormattingChange,
        onMetricFormattingChange
    } = useCustomizeChart<WidgetHeatmapChart>(widget, onChange);

    const { onAddRange, onRangeUpdate, complyColorRanges } = useColorRanges(widget, onMetricFormattingChange);

    const handleColorModeChange = useCallback(
        (colorMode: MetricColorMode) => {
            onMetricFormattingChange(0, {
                ...mainSeries.metrics[0].formatting,
                colorMode,
                colorRanges: complyColorRanges(mainSeries.metrics[0].formatting?.colorRanges, colorMode)
            });
        },
        [complyColorRanges, mainSeries.metrics, onMetricFormattingChange]
    );

    // Render
    return (
        <div>
            {/* Metrics section */}
            <div>
                {/* Title */}
                <div>
                    <h4 className="text-dark mb-4">
                        <span className="elevio-customize-chart-title">
                            <RichMessage
                                id="dashboarding.widget-editor.customize-chart.metrics-customization"
                                values={{ count: mainSeries.metrics.length }}
                            />
                        </span>
                    </h4>
                </div>

                {/* Metrics section inner */}
                <Row className="mb-4">
                    <Col xs="6" className="pe-4">
                        {mainSeries.metrics.map((metric, index) => (
                            <div key={index} className="mb-2">
                                {/* Metric label */}
                                <Form.Label className="d-block">
                                    <RichMessage id="dashboarding.widget-editor.customize.metric-label" />
                                </Form.Label>
                                <FormControlWithCommit
                                    value={metric.formatting?.label || ''}
                                    className="mb-3"
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.metric-legend-placeholder'
                                    })}
                                    onCommit={val => onMetricFormattingChange(index, { label: val })}
                                />

                                {/* Metric color */}
                                <Form.Label className="d-block">
                                    <RichMessage id="dashboarding.widget-editor.customize.metric-color" />
                                    {metric.formatting?.colorMode == 'gradient' && (
                                        <RichMessage id="dashboarding.widget-editor.customize.color-suffix.gradient-based" />
                                    )}
                                    {metric.formatting?.colorMode == 'threshold' && (
                                        <RichMessage id="dashboarding.widget-editor.customize.color-suffix.threshold-based" />
                                    )}
                                </Form.Label>

                                {/* Metric color: gradient-based */}
                                {metric.formatting?.colorMode == 'gradient' && (
                                    <GradientColorConfig
                                        colorRanges={metric.formatting?.colorRanges || [...WIDGET_GRADIENT_COLORS]}
                                        onRangeUpdate={(rangeIndex, range) =>
                                            onRangeUpdate('gradient', 0, rangeIndex, range)
                                        }
                                        onAddRange={() => onAddRange('gradient', 0, true)}
                                    >
                                        <div
                                            className="btn link-primary h6 align-self-center"
                                            onClick={() => handleColorModeChange('threshold')}
                                        >
                                            <FormattedMessage id="dashboarding.widget-editor.customize-chart.configure-threshold" />
                                        </div>
                                    </GradientColorConfig>
                                )}

                                {/* Metric color: threshold-based */}
                                {metric.formatting?.colorMode == 'threshold' && (
                                    <ThresholdColorConfig
                                        colorRanges={metric.formatting?.colorRanges || [...WIDGET_THRESHOLD_COLORS]}
                                        onRangeUpdate={(rangeIndex, range) =>
                                            onRangeUpdate('threshold', 0, rangeIndex, range)
                                        }
                                        onAddRange={() => onAddRange('threshold', 0)}
                                    >
                                        <div
                                            className="btn link-primary h6 align-self-center"
                                            onClick={() => handleColorModeChange('gradient')}
                                        >
                                            <FormattedMessage id="dashboarding.widget-editor.customize-chart.configure-gradient" />
                                        </div>
                                    </ThresholdColorConfig>
                                )}
                            </div>
                        ))}
                    </Col>
                    <Col xs="6" className="ps-4">
                        {/* Legend switch */}
                        <div className="d-flex justify-content-between mb-3">
                            <div className="me-2">
                                <Form.Label htmlFor="legendSwitchInput" className="d-block mb-0">
                                    <RichMessage
                                        id="dashboarding.widget-editor.customize-chart.show-metric-legend.title"
                                        values={{ count: mainSeries.metrics.length }}
                                    />
                                </Form.Label>
                                <Form.Text id="legendSwitchHelp" muted>
                                    <RichMessage id="dashboarding.widget-editor.customize-chart.show-metric-legend.description" />
                                </Form.Text>
                            </div>
                            <Form.Check
                                type="switch"
                                id="legendSwitchInput"
                                aria-describedby="legendSwitchHelp"
                                role="button"
                                checked={!!localWidget.config.formatting?.showChartLegend}
                                onChange={e => onWidgetFormattingChange({ showChartLegend: e.target.checked })}
                            />
                        </div>

                        {/* Data label switch */}
                        <div className="d-flex justify-content-between mb-3">
                            <div className="me-2">
                                <Form.Label htmlFor="dataLabelSwitchInput" className="d-block mb-0">
                                    <RichMessage id="dashboarding.widget-editor.customize-chart.show-data-label.title" />
                                </Form.Label>
                                <Form.Text id="dataLabelSwitchHelp" muted>
                                    <RichMessage id="dashboarding.widget-editor.customize-chart.show-data-label.description" />
                                </Form.Text>
                            </div>
                            <Form.Check
                                type="switch"
                                id="dataLabelSwitchInput"
                                aria-describedby="dataLabelSwitchHelp"
                                role="button"
                                checked={!!localWidget.config.formatting?.showDataLabel}
                                onChange={e => onWidgetFormattingChange({ showDataLabel: e.target.checked })}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Axis & Legend Options */}
            {displayConfig.customizeAxis && (
                <div>
                    {/* Title */}
                    <div>
                        <h4 className="text-dark mb-4">
                            <RichMessage id="dashboarding.widget-editor.customize-chart.axis-legend-options" />
                        </h4>
                    </div>

                    {/* Axis section inner */}
                    <Row className="mb-4">
                        {/* X Axis */}
                        <Col xs="6" className="pe-4">
                            {/* Label */}
                            <Form.Label htmlFor="axisXLabelInput" className="d-block">
                                <RichMessage id="dashboarding.widget-editor.customize-chart.axis-x.label" />
                            </Form.Label>

                            {/* Control */}
                            <div className="d-flex align-items-center mb-2">
                                <FormControlWithCommit
                                    value={localWidget.config.formatting?.xAxis?.label || ''}
                                    id="axisXLabelInput"
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.axis-x.placeholder'
                                    })}
                                    onCommit={val => onWidgetFormattingChange({ xAxis: { label: val } })}
                                    className="flex-grow-1 me-4"
                                />
                                <Form.Check
                                    type="switch"
                                    role="button"
                                    checked={!!localWidget.config.formatting?.xAxis?.show}
                                    onChange={e => onWidgetFormattingChange({ xAxis: { show: e.target.checked } })}
                                />
                            </div>
                        </Col>

                        {/* Y Axis */}
                        <Col xs="6" className="pe-4">
                            {/* Label */}
                            <Form.Label htmlFor="axisYLabelInput" className="d-block">
                                <RichMessage id="dashboarding.widget-editor.customize-chart.axis-y.label" />
                            </Form.Label>

                            {/* Control */}
                            <div className="d-flex align-items-center mb-2">
                                <FormControlWithCommit
                                    value={localWidget.config.formatting?.yAxis?.label || ''}
                                    id="axisYLabelInput"
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.axis-y.placeholder'
                                    })}
                                    onCommit={val => onWidgetFormattingChange({ yAxis: { label: val } })}
                                    className="flex-grow-1 me-4"
                                />
                                <Form.Check
                                    type="switch"
                                    role="button"
                                    checked={!!localWidget.config.formatting?.yAxis?.show}
                                    onChange={e => onWidgetFormattingChange({ yAxis: { show: e.target.checked } })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default CustomizeHeatmap;
