import React, { ReactElement, useCallback } from 'react';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import WidgetFactSelector from './WidgetFactSelector';
import FactFieldList from './FactFieldList';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

interface Props<TWidget extends WidgetFactType> {
    widget: TWidget;
    onChange: (widget: TWidget) => void;
    showAvailableFields: boolean;
    onToggleShowAvailableFields: () => void;
}

function ConfigureSource<TWidget extends WidgetFactType>({
    widget,
    onChange,
    showAvailableFields,
    onToggleShowAvailableFields
}: Props<TWidget>): ReactElement<Props<TWidget>> {
    // Extract parameters
    const mainSeries = widget.config;
    const fact = mainSeries.fact;

    // Hook invoked when a fact gets selected
    const onFactChange = useCallback(
        (fact: QueryOpFact) => {
            onChange({ ...widget, config: { ...widget.config, fact } });
        },
        [onChange, widget]
    );

    return (
        <div>
            <Row className="mb-4">
                <Col>
                    <h4 className="text-dark mb-1">
                        <RichMessage id="dashboarding.widget-editor.configure-source.heading.title" />
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage id="dashboarding.widget-editor.configure-source.heading.description" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="4">
                    <WidgetFactSelector
                        className={classNames('justify-content-between w-100 p-3 border-dark text-dark bg-white')}
                        onChange={onFactChange}
                        value={fact}
                    />
                    <p className="mt-4 mb-4 text-grey-1" style={{ textAlign: 'justify' }}>
                        <RichMessage id={`dashboarding.widget-fact-selector.fact-description.${fact}`} />
                    </p>
                    <Button
                        variant="link"
                        className="p-0 text-decoration-underline"
                        onClick={onToggleShowAvailableFields}
                    >
                        {showAvailableFields ? (
                            <RichMessage id="dashboarding.widget-fact-selector.available-fields.hide-label" />
                        ) : (
                            <RichMessage id="dashboarding.widget-fact-selector.available-fields.show-label" />
                        )}
                    </Button>
                </Col>
                {showAvailableFields && (
                    <Col>
                        <FactFieldList fact={fact} />
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default ConfigureSource;
