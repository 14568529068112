import { PickEvent } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import React, { FunctionComponent } from 'react';
import LargeCardWidget from './LargeCardWidget';
import ListWidget from './ListWidget';
import MetricChartWidget from './MetricChartWidget';
import MetricKpiWidget from './MetricKpiWidget';
import MiniCardWidget from './MiniCardWidget';

interface Props {
    widget: WidgetFactType;
    drilldownMode?: boolean;
    onChange: (e: WidgetFactType) => void;
    onReloading: (reloading: boolean) => void;
    onPick?: (event: PickEvent) => void;
    forcedMessageId?: string;
    tooltipZIndex?: number;
}

const InnerWidget: FunctionComponent<Props> = ({
    widget,
    drilldownMode,
    onChange,
    onReloading,
    onPick,
    forcedMessageId,
    tooltipZIndex
}: Props) => {
    // Extract params
    const widgetType = widget.configType;

    if (widgetType == 'LARGECARD') {
        return <LargeCardWidget widget={widget} onReloading={onReloading} forcedMessageId={forcedMessageId} />;
    } else if (widgetType == 'LIST') {
        return (
            <ListWidget
                widget={widget}
                drilldownMode={drilldownMode}
                onChange={onChange}
                onReloading={onReloading}
                forcedMessageId={forcedMessageId}
                onPick={onPick}
            />
        );
    } else if (
        widgetType == 'AREACHART' ||
        widgetType == 'BARCHART' ||
        widgetType == 'COLUMNCHART' ||
        widgetType == 'HEATMAPCHART' ||
        widgetType == 'LINECHART' ||
        widgetType == 'PIECHART'
    ) {
        return (
            <MetricChartWidget
                widget={widget}
                onReloading={onReloading}
                forcedMessageId={forcedMessageId}
                onPick={onPick}
                tooltipZIndex={tooltipZIndex}
            />
        );
    } else if (
        widgetType == 'NUMBERKPI' ||
        widgetType == 'SPARKCOLUMNKPI' ||
        widgetType == 'SPARKLINEKPI' ||
        widgetType == 'TRENDKPI'
    ) {
        return (
            <MetricKpiWidget
                widget={widget}
                onReloading={onReloading}
                forcedMessageId={forcedMessageId}
                onPick={onPick}
                tooltipZIndex={tooltipZIndex}
            />
        );
    } else if (widgetType == 'MINICARD') {
        return (
            <MiniCardWidget
                widget={widget}
                onReloading={onReloading}
                onChange={onChange}
                forcedMessageId={forcedMessageId}
            />
        );
    } else {
        return <div>Type {widgetType} not recognized</div>;
    }
};

export default InnerWidget;
