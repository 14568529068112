import React, { FunctionComponent, useCallback } from 'react';
import { QueryOpFact, QUERY_OP_FACTS } from 'components/Dashboarding/DataSource';
import { RichMessage } from 'components/RichMessage';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
    value: string;
    onChange: (e: QueryOpFact) => void;
    className?: string;
}

const WidgetFactSelector: FunctionComponent<Props> = ({ value, onChange, className }: Props) => {
    const handleOnSelect = useCallback(
        (fact: string | null): void => {
            const queryOpFact = fact as QueryOpFact;
            if (queryOpFact) onChange(queryOpFact);
        },
        [onChange]
    );
    return (
        <Dropdown className="flex-grow-1" onSelect={handleOnSelect}>
            <Dropdown.Toggle
                style={{ whiteSpace: 'pre-wrap' }}
                className={classNames('fw-bold', className)}
                title={value}
            >
                <RichMessage id={`dashboarding.widget-fact-selector.fact.${value}`} />
            </Dropdown.Toggle>
            {/* List of values */}
            <Dropdown.Menu
                align="end"
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
            >
                {QUERY_OP_FACTS.map(e => {
                    return (
                        <Dropdown.Item key={e} eventKey={e} className={classNames({ 'fw-bold': e == value })}>
                            <RichMessage id={`dashboarding.widget-fact-selector.fact.${e}`} />
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default WidgetFactSelector;
