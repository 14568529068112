import React, { FunctionComponent, useCallback } from 'react';
import { WidgetColorRange } from 'components/Dashboarding/Models/Widget';
import PaletteColorPicker from 'components/Design/ColorPickers/PaletteColorPicker';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEquals, faTrash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';

interface Props {
    className?: string;
    onChange: (r?: WidgetColorRange) => void;
    range: WidgetColorRange;
}

const NumberColorRange: FunctionComponent<Props> = ({ className, onChange, range }: Props) => {
    // Services
    const intl = useRichIntl();

    // Is this range the starting one?
    // The starting range applies to all numbers until a specific threshold is set
    const isStartingRange = range[1] == undefined;

    // Hook invoked when the value is changed
    const onThresholdChange = useCallback(
        (value?: string) => {
            const threshold = value == undefined ? undefined : parseFloat(value);
            onChange([range[0], threshold]);
        },
        [onChange, range]
    );

    // Render
    return (
        <div className={classNames('d-flex align-items-center', className)}>
            {/* Color */}
            <PaletteColorPicker color={range[0]} onChange={e => onChange([e, range[1]])} className="me-2" />

            {/* Applies to */}
            {isStartingRange ? (
                <div className="py-3 px-2 text-dark fw-bold">
                    <RichMessage id="dashboarding.widget-editor.number-color-range.base-color" />
                </div>
            ) : (
                <>
                    {/* Greater than */}
                    <div className="d-flex py-3 px-2 text-dark">
                        <FontAwesomeIcon icon={faChevronRight} />
                        <FontAwesomeIcon icon={faEquals} />
                    </div>

                    {/* Threshold */}
                    <FormControlWithCommit
                        value={range[1]?.toString()}
                        type="number"
                        placeholder={intl.formatMessage({
                            id: 'dashboarding.widget-editor.number-color-range.threshold-placeholder'
                        })}
                        onCommit={val => onThresholdChange(val)}
                        className="text-right ms-2"
                        style={{ maxWidth: 150 }}
                    />
                </>
            )}

            {/* Delete Button */}
            {!isStartingRange && (
                <div role="button" className="text-light ms-4" onClick={() => onChange(undefined)}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};

export default NumberColorRange;
