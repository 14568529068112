import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    dashboardViewPath,
    DASHBOARD_ROOT_PATH,
    REDIRECT_TO_PARAM,
    WIDGET_BUILDER_PARAM_WIDGET
} from 'constants/routeBuilders';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    WidgetConfigType,
    WidgetFactType,
    WIDGET_DISPLAY_CONFIGS,
    switchWidgetConfigType,
    switchableSeriesConfigTypes,
    WidgetChartType,
    WidgetKpiType
} from 'components/Dashboarding/Models/Widget';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import ConfigurationPanel from './ConfigurationPanel';
import { useStateWithHistory } from 'react-use';
import { WIDGET_BLANK_TEMPLATES } from 'components/Dashboarding/Models/WidgetTemplates';
import { useMutation } from '@apollo/react-hooks';
import {
    CreateWidgetResp,
    CREATE_WIDGET,
    UpdateWidgetResp,
    UPDATE_WIDGET,
    WIDGET_CREATE_ATTRS,
    WIDGET_UPDATE_ATTRS
} from 'api/hq/queries/Widget';
import { MutationResp } from 'api/hq/queries/Shared';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useSafeState } from 'util/useSafeState';
import { debounce, pick } from 'lodash';
import { useDashboard } from 'api/hq/hooks/useDashboard';
import { UpdateDashboardResp, UPDATE_DASHBOARD } from 'api/hq/queries/Dashboard';
import { useWidget } from 'api/hq/hooks/useWidget';
import { faPlusSquare, faSave } from '@fortawesome/free-regular-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import WidgetCard from '../DashboardWidget/WidgetCard';
import NavigableDropdown from 'components/NavigableDropdown';
import { SelectDropdownOption } from 'components/NavigableDropdown/NavigableDropdown';
import { ButtonGroup, Container, Dropdown } from 'react-bootstrap';
import { useUndoRedo } from 'util/useUndoRedo';
import { useTitleLabel } from 'util/useTitleLabel';
import FullPageLoader from 'components/Design/FullPageLoader';
import { findFirstAvailableGridPlacement } from '../DashboardGrid';
import DynamicWidthTransparentInput from 'components/Design/DynamicWidthTransparentInput';
import { kpRedo, kpUndo } from 'util/customIcons';
import { usePlatform } from 'util/usePlatform';
import { useConfigurationPanelMenu } from 'util/useConfigurationPanelMenu';
import { toastError, toastQueryError } from 'components/Toast';
import { PickEvent } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import { useDrilldown } from './ConfigurationPanel/useDrilldown';
import { DrilldownContext, DrilldownContextProvider } from 'contexts/DrilldownContext';
import { useDrilldownContext } from './ConfigurationPanel/useDrilldownContext';

// Default widget template
const DEFAULT_WIDGET_TEMPLATE = WIDGET_BLANK_TEMPLATES['LINECHART'];

// Chart language namespace
const WIDGET_TYPE_NS = 'dashboarding.widget-editor.widget-type';

// Number of undo changes recorded
export const WIDGET_EDITOR_MAX_UNDO_CHANGES = 30;
export const widgetEditorUndoPredicate = (event: KeyboardEvent): boolean =>
    (event.ctrlKey || event.metaKey) && !event.shiftKey && event.key == 'z';
export const widgetEditorRedoPredicate = (event: KeyboardEvent): boolean =>
    (event.ctrlKey || event.metaKey) && ((event.key == 'z' && event.shiftKey) || event.key == 'Z');

// List of possible save action types
type SaveActionType = 'save' | 'save-as-new';

// Function for showing toasts on successful editor actions
export const toastWidgetEditorSuccess = (msgId: string, namespace = 'default'): void => {
    toastError({
        type: 'update-success',
        children: <RichMessage id={`dashboarding.widget-editor.toasts.${msgId}.${namespace}`} />
    });
};

// This component manages the edition flow of widgets
const AdvancedBuilder: FunctionComponent = () => {
    // Page title
    useTitleLabel('dashboarding.widget-editor.title');

    // Services
    const navigate = useNavigate();
    const intl = useRichIntl();

    // Route parameters
    const params = useParams() as { dashboardId?: string };
    const [searchParams, setSearchParams] = useSearchParams();
    const dashboardId = params.dashboardId;

    // State
    const [actionInProgress, setActionInProgress] = useSafeState<SaveActionType | undefined>(undefined);
    const [
        previewWidget,
        setPreviewWidget,
        { back: undoWidgetState, forward: redoWidgetState, history: widgetHistory, position: widgetHistoryPosition }
    ] = useStateWithHistory<WidgetFactType | undefined, WidgetFactType>(undefined, WIDGET_EDITOR_MAX_UNDO_CHANGES);
    const { selectedMenu, selectedSubMenu, applicableMenus, onMenuSelect, onSubMenuSelect } = useConfigurationPanelMenu(
        previewWidget
    );

    // Drilldown context
    const { setPickedMetricIndex, setPickedCoordinates } = useContext(DrilldownContext);

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // OS
    const { isMac } = usePlatform();

    // Mutations
    const [createWidget] = useMutation<CreateWidgetResp>(CREATE_WIDGET);
    const [updateWidget] = useMutation<UpdateWidgetResp>(UPDATE_WIDGET);
    const [updateDashboard] = useMutation<UpdateDashboardResp>(UPDATE_DASHBOARD);

    // Load parent dashboard
    const { normalized: parentDashboard, loading: parentDashboardLoading } = useDashboard({ id: dashboardId });

    // Load widget by ID (if any available in the params). This is used to check
    // if the widget can be saved or if it must be saved as new.
    const { normalized: persistedWidget, loading: persistedWidgetLoading } = useWidget({ id: previewWidget?.id });

    // Get currently selected dashboard (if any)
    const redirectTo = useMemo(() => searchParams?.get(REDIRECT_TO_PARAM), [searchParams]);
    const postUpdateRedirectLink = useMemo(() => {
        return redirectTo ? redirectTo : dashboardId ? dashboardViewPath({ dashboardId }) : DASHBOARD_ROOT_PATH;
    }, [dashboardId, redirectTo]);

    // Get the configuration of all series types compatible with this design mode
    const switchableSeriesTypeConfigs = useMemo((): SelectDropdownOption[] => {
        // Get compatible visualizations and format them as dropdown options
        const seriesTypes = switchableSeriesConfigTypes(previewWidget?.configType);
        return seriesTypes.map(id => {
            return { eventKey: id, labelId: `${WIDGET_TYPE_NS}.${id}`, icon: WIDGET_DISPLAY_CONFIGS[id].icon };
        });
    }, [previewWidget?.configType]);

    // Update the `widget` search param in the URL
    // The method is debounced so as to not wait for updates. This makes
    // the UI more responsive to changes.
    const updateWidgetSearchParam = useCallback(
        (e: WidgetFactType) => {
            // Create new search params from the current ones
            const newParams = new URLSearchParams(searchParams);

            // Overwrite the `widget` search param
            newParams.set(WIDGET_BUILDER_PARAM_WIDGET, JSON.stringify(e));

            // Set search params state
            setSearchParams(newParams);
        },
        [setSearchParams, searchParams]
    );
    const debouncedUpdateWidgetSearchParam = useMemo(() => debounce(updateWidgetSearchParam), [
        updateWidgetSearchParam
    ]);

    // Hook invoked when the widget configuration is updated
    const onWidgetUpdate = useCallback(
        (e: WidgetFactType): void => {
            setPreviewWidget(e);
            debouncedUpdateWidgetSearchParam(e);
        },
        [setPreviewWidget, debouncedUpdateWidgetSearchParam]
    );

    // Drilldown hook
    useDrilldownContext({ widget: previewWidget as WidgetChartType | WidgetKpiType });
    const { isDrilldownEnabledForMetric, enableDrilldownForMetric, getDrilldownWidgetForMetric } = useDrilldown({
        widget: previewWidget as WidgetChartType | WidgetKpiType,
        onComplete: onWidgetUpdate
    });

    // Retrieve generated widget for the drilldown report
    const drilldownPreviewWidget = useMemo(() => getDrilldownWidgetForMetric(selectedSubMenu, previewWidget?.name), [
        getDrilldownWidgetForMetric,
        previewWidget?.name,
        selectedSubMenu
    ]);

    // Switch series type and make sure the widget has the right number of metrics/dimensions as a result.
    const switchWidgetType = useCallback(
        (newType: WidgetConfigType) => {
            if (!previewWidget) return;

            const formattedWidget = switchWidgetConfigType(previewWidget, newType, {
                toastWidgetEditorSuccess
            });
            onWidgetUpdate(formattedWidget);
        },
        [onWidgetUpdate, previewWidget]
    );

    // Add widget to dashboard function
    const addWidgetToDashboardLayout = useCallback(
        async (widget: WidgetFactType) => {
            // Abort if no parentDashboard
            if (!parentDashboard) return;

            // Abort if widget is already part of the dashboard
            const layout = parentDashboard.layout;
            if (layout.map(e => e.i).indexOf(widget.id) > -1) return;

            // Build new version
            const { defaultSizing } = WIDGET_DISPLAY_CONFIGS[widget.configType];
            const widgetPlacement = findFirstAvailableGridPlacement(layout, { ...defaultSizing, i: widget.id });
            const payload = {
                id: parentDashboard.id,
                layout: [...layout, widgetPlacement]
            };

            // Update dashboard
            return (await updateDashboard({ variables: { input: payload } })).data?.updateDashboard;
        },
        [parentDashboard, updateDashboard]
    );

    // Cancel function
    const cancelEdit = useCallback(() => navigate(postUpdateRedirectLink), [navigate, postUpdateRedirectLink]);

    // Widget save function
    const saveDashboardWidget = useCallback(
        async (saveType: SaveActionType) => {
            if (!previewWidget || !company) return;

            // Set loading state
            setActionInProgress(saveType);

            try {
                let resp: (MutationResp & { widget?: { id: string } }) | undefined;

                if (previewWidget.id && saveType == 'save') {
                    // Update existing widget
                    const payload = pick(previewWidget, WIDGET_UPDATE_ATTRS);
                    resp = (await updateWidget({ variables: { input: payload } })).data?.updateWidget;
                } else {
                    // Create new widget
                    const payload = pick({ ...previewWidget, companyId: company.id }, WIDGET_CREATE_ATTRS);
                    resp = (await createWidget({ variables: { input: payload } })).data?.createWidget;
                }

                if (resp?.success) {
                    // Add id to new widget
                    const updatedWidget = resp.widget?.id ? { ...previewWidget, id: resp.widget?.id } : previewWidget;

                    // Add widget to dashboard
                    if (parentDashboard) {
                        const widgetAddResp = await addWidgetToDashboardLayout(updatedWidget);

                        // Abort save if the widget could not be added to the dashboard
                        if (widgetAddResp && !widgetAddResp.success) {
                            toastQueryError({ ...widgetAddResp.errors[0], namespace: 'add-widget-to-dashboard' });
                            return;
                        }
                    }

                    // The reason for encapsulating the navigate function into another function and call it,
                    // is that the updateWidgetSearchParam blocks the navigate function from executing.
                    // The reason is yet to be known.
                    navigate(postUpdateRedirectLink);
                } else {
                    toastQueryError({ ...resp?.errors[0], namespace: 'save-widget' });
                }
            } catch (e) {
                toastQueryError({ namespace: 'save-widget' });
            } finally {
                // Stop loading state
                setActionInProgress(undefined);
            }
        },
        [
            addWidgetToDashboardLayout,
            company,
            createWidget,
            navigate,
            parentDashboard,
            postUpdateRedirectLink,
            previewWidget,
            setActionInProgress,
            updateWidget
        ]
    );

    // Undo widget state, switch URL back and return previous version
    const undoChange = useCallback(() => {
        if (widgetHistoryPosition == 1) return;

        const previousVersion = widgetHistory[widgetHistoryPosition - 1];
        if (!previousVersion) return;

        // Update state
        undoWidgetState();
        debouncedUpdateWidgetSearchParam(previousVersion);
    }, [undoWidgetState, debouncedUpdateWidgetSearchParam, widgetHistory, widgetHistoryPosition]);

    // Redo widget state, switch URL and return next version
    const redoChange = useCallback((): WidgetFactType | undefined => {
        if (widgetHistoryPosition == widgetHistory.length - 1) return;

        const nextVersion = widgetHistory[widgetHistoryPosition + 1];
        if (!nextVersion) return;

        // Update state
        redoWidgetState();
        debouncedUpdateWidgetSearchParam(nextVersion);
    }, [redoWidgetState, debouncedUpdateWidgetSearchParam, widgetHistory, widgetHistoryPosition]);

    // Capture undo/redo keyboard actions
    useUndoRedo(
        useCallback(
            (event: KeyboardEvent) => {
                // For regular inputs, let the browser handle the undo/redo at the local level.
                // The undo/redo change will be propagated upward naturally.
                const nodeType = (event.target && 'nodeName' in event.target ? event.target['nodeName'] : '') as string;
                if (['INPUT', 'TEXTAREA'].includes(nodeType)) return;

                // Proceed with global undo/redo otherwise
                event.preventDefault();
                undoChange();
            },
            [undoChange]
        ),
        useCallback(
            (event: KeyboardEvent) => {
                // For regular inputs, let the browser handle the undo/redo at the local level.
                // The undo/redo change will be propagated upward naturally.
                const nodeType = (event.target && 'nodeName' in event.target ? event.target['nodeName'] : '') as string;
                if (['INPUT', 'TEXTAREA'].includes(nodeType)) return;

                // Proceed with global undo/redo otherwise
                event.preventDefault();
                redoChange();
            },
            [redoChange]
        )
    );

    // Handle pick event
    const handlePickEvent = useCallback(
        (e: PickEvent) => {
            // Update `pickedMetricIndex` in drilldown context
            setPickedMetricIndex(e.srcIndex);

            // Update `pickedCoordinates` in drilldown context
            setPickedCoordinates(e.coordinates);

            // If drilldown is not already enabled for the picked metric, enable it
            if (!isDrilldownEnabledForMetric(e.srcIndex)) enableDrilldownForMetric(e.srcIndex);

            // Redirect to configure-drilldown tab and the corresponding metric tab index
            onMenuSelect('configure-drilldown');
            onSubMenuSelect(e.srcIndex);
        },
        [
            enableDrilldownForMetric,
            isDrilldownEnabledForMetric,
            onMenuSelect,
            onSubMenuSelect,
            setPickedCoordinates,
            setPickedMetricIndex
        ]
    );

    // Load existing widget or default widget template
    useEffect(() => {
        if (previewWidget) return;

        // Get default template
        const defaultTemplate = {
            ...DEFAULT_WIDGET_TEMPLATE,
            name: intl.formatMessage({ id: 'dashboarding.widget-editor.defaut-config.name' })
        };

        // Get widget from URL
        const widgetFromParamStr = searchParams.get(WIDGET_BUILDER_PARAM_WIDGET);
        const widgetFromUrl = widgetFromParamStr ? (JSON.parse(widgetFromParamStr) as WidgetFactType) : undefined;

        // Load widget or template
        setPreviewWidget(widgetFromUrl || defaultTemplate);
    }, [intl, previewWidget, searchParams, setPreviewWidget]);

    // If previewWidget is not loaded then return a loading page
    if (!previewWidget || parentDashboardLoading || persistedWidgetLoading) return <FullPageLoader />;

    // Render editor
    return (
        <div>
            <div className="vh-50 bg-grey-11 d-flex flex-column">
                {/* Action bar */}
                <div className="shadow-sm bg-white px-4 py-3 d-flex">
                    {/* Widget name */}
                    <div className="d-flex align-items-center flex-grow-1 flex-basis-0">
                        <DynamicWidthTransparentInput
                            value={previewWidget.name}
                            onCommit={val => onWidgetUpdate({ ...previewWidget, name: val } as WidgetFactType)}
                            placeholderId="dashboarding.widget-editor.defaut-config.name-placeholder"
                            className="elevio-widget-edit-title transparent-framed-input framed-input h4 px-2"
                        />
                    </div>

                    {/* Undo / Redo */}
                    <ButtonGroup
                        className="align-self-center justify-content-center mx-2"
                        style={{ height: 'fit-content' }}
                    >
                        {/* Undo */}
                        <Button
                            onClick={undoChange}
                            variant="outline-dark"
                            className="btn-sm"
                            disabled={widgetHistoryPosition == 1 || !!actionInProgress}
                            title={intl.formatMessage({
                                id: `dashboarding.widget-editor.action.undo.${isMac ? 'mac' : '*'}`
                            })}
                        >
                            <FontAwesomeIcon icon={kpUndo} />
                        </Button>

                        {/* Redo */}
                        <Button
                            onClick={redoChange}
                            variant="outline-dark"
                            className="btn-sm"
                            disabled={widgetHistoryPosition == widgetHistory.length - 1 || !!actionInProgress}
                            title={intl.formatMessage({
                                id: `dashboarding.widget-editor.action.redo.${isMac ? 'mac' : '*'}`
                            })}
                        >
                            <FontAwesomeIcon icon={kpRedo} />
                        </Button>
                    </ButtonGroup>

                    {/* Actions */}
                    <div className="d-flex align-items-center justify-content-end flex-grow-1 flex-basis-0">
                        {/* Cancel / Save / Save as new */}
                        <div className="d-flex">
                            {/* Cancel */}
                            <Button onClick={cancelEdit} variant="link" className="me-2" disabled={!!actionInProgress}>
                                <RichMessage id="dashboarding.widget-editor.action.cancel" />
                            </Button>

                            {persistedWidget && (
                                <Dropdown as={ButtonGroup}>
                                    <Button
                                        variant="dark"
                                        onClick={() => saveDashboardWidget('save')}
                                        className="d-flex"
                                    >
                                        {actionInProgress == 'save' ? (
                                            <Spinner animation="border" size="sm" variant="white" className="me-2" />
                                        ) : (
                                            <FontAwesomeIcon icon={faSave} className="me-2" />
                                        )}
                                        <RichMessage id="dashboarding.widget-editor.action.save" />
                                    </Button>
                                    <Dropdown.Toggle split variant="twilight" />
                                    <Dropdown.Menu
                                        className="add-insight-menu"
                                        variant="dark"
                                        align={'end'}
                                        // Fixed strategy is required to avoid issues with container overflow
                                        popperConfig={{ strategy: 'fixed' }}
                                        // Fixed strategy is bugged. Need renderOnMount to work properly
                                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                                        renderOnMount
                                    >
                                        <Dropdown.Item as={Button} onClick={() => saveDashboardWidget('save-as-new')}>
                                            {actionInProgress == 'save-as-new' ? (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    variant="depends_on"
                                                    className="me-2"
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={faPlusSquare} className="me-2" />
                                            )}
                                            <RichMessage id="dashboarding.widget-editor.action.save-as-new" />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            {/* Save as new */}
                            {!persistedWidget && (
                                <Button
                                    onClick={() => saveDashboardWidget('save-as-new')}
                                    variant="dark"
                                    disabled={!!actionInProgress}
                                >
                                    {actionInProgress == 'save-as-new' ? (
                                        <Spinner animation="border" size="sm" variant="depends_on" className="me-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faPlusSquare} className="me-2" />
                                    )}
                                    <RichMessage id="dashboarding.widget-editor.action.save-as-new" />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {/* Preview content */}
                <Container fluid className="flex-grow-1">
                    <Row className="h-100">
                        <>
                            {/* Select Widget Type */}
                            <Col xs="3" className="pt-4">
                                {selectedMenu != 'configure-visualization' &&
                                    selectedMenu != 'configure-drilldown' &&
                                    switchableSeriesTypeConfigs.length >= 1 && (
                                        <NavigableDropdown
                                            activeKey={previewWidget.configType}
                                            isSearchable={false}
                                            className="w-75"
                                            onSelect={e => switchWidgetType(e as WidgetConfigType)}
                                            options={switchableSeriesTypeConfigs}
                                        />
                                    )}
                            </Col>

                            {/* Widget Preview */}
                            {selectedMenu == 'configure-drilldown' && drilldownPreviewWidget ? (
                                <Col xs={{ span: 6, offset: 1.5 }} className="d-flex align-items-center">
                                    <div className="vh-30 w-100">
                                        <WidgetCard
                                            widget={previewWidget}
                                            onChange={onWidgetUpdate}
                                            drilldownMode
                                            hideMoreButton
                                        />
                                    </div>
                                </Col>
                            ) : previewWidget.configType == 'MINICARD' ? (
                                <Col xs={{ span: 8, offset: 2 }} className="d-flex align-items-center">
                                    <div className="vh-30 w-100">
                                        <WidgetCard
                                            onChange={onWidgetUpdate}
                                            isEditing
                                            widget={previewWidget}
                                            className="shadow-sm"
                                        />
                                    </div>
                                </Col>
                            ) : ['LARGECARD', 'LIST', 'HEATMAP'].includes(previewWidget.configType) ? (
                                <Col xs={{ span: 6, offset: 1.5 }} className="d-flex align-items-center">
                                    <div className="vh-30 w-100">
                                        <WidgetCard
                                            onChange={onWidgetUpdate}
                                            isEditing
                                            widget={previewWidget}
                                            className="shadow-sm"
                                            onPick={handlePickEvent}
                                        />
                                    </div>
                                </Col>
                            ) : (
                                <Col xs={{ span: 4, offset: 1 }} className="d-flex align-items-center">
                                    <div className="vh-30 w-100">
                                        <WidgetCard
                                            onChange={onWidgetUpdate}
                                            isEditing
                                            widget={previewWidget}
                                            className="shadow-sm"
                                            onPick={handlePickEvent}
                                        />
                                    </div>
                                </Col>
                            )}
                        </>
                    </Row>
                </Container>
            </div>

            {/* Configuration Panel */}
            <div className="bg-white shadow-top-sm vh-50">
                <ConfigurationPanel
                    applicableMenus={applicableMenus}
                    selectedMenu={selectedMenu}
                    selectedSubMenu={selectedSubMenu}
                    widget={previewWidget}
                    onChange={onWidgetUpdate}
                    onMenuSelect={onMenuSelect}
                    onSubMenuSelect={onSubMenuSelect}
                />
            </div>
        </div>
    );
};

const DashboardWidgetEdit: FunctionComponent = () => {
    return (
        <DrilldownContextProvider>
            <AdvancedBuilder />
        </DrilldownContextProvider>
    );
};

export default DashboardWidgetEdit;
