import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useWidget } from 'api/hq/hooks/useWidget';
import WidgetCard from './WidgetCard';
import WidgetHSeparator from './WidgetHSeparator';
import WidgetVSeparator from './WidgetVSeparator';
import WidgetMarkdown from './WidgetMarkdown';
import { useUserRoleForResource } from 'api/hq/hooks/useUserRoleForResource';
import WidgetNotFound from './WidgetNotFound';
import { PickEvent } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import DrilldownModal from './DrilldownModal';
import { WidgetChartType, WidgetKpiType } from 'components/Dashboarding/Models/Widget';
import { useDrilldown } from '../DashboardWidgetEdit/ConfigurationPanel/useDrilldown';
import { DrilldownContext } from 'contexts/DrilldownContext';
import { useDrilldownContext } from '../DashboardWidgetEdit/ConfigurationPanel/useDrilldownContext';

interface Props {
    dashboardId?: string;
    isEditing?: boolean;
    canRemove?: boolean;
    onRemoveWidget: (id?: string) => void;
    widgetId: string;
}

// Widget wrapper on the dashboard grid.
// Load the widget remotely and pass it to widget
const DashboardWidget: FunctionComponent<Props> = ({
    dashboardId,
    isEditing,
    canRemove,
    onRemoveWidget,
    widgetId
}: Props) => {
    // State
    const [isDrilldownModalOpen, setIsDrilldownModalOpen] = useState<boolean>(false);

    // Load widget
    const { normalized: widget, loading: widgetLoading } = useWidget({ id: widgetId });

    // Load the user roles
    const { canView, canEdit, loading: userRoleLoading } = useUserRoleForResource({
        resourceId: widgetId,
        resourceType: 'WIDGET'
    });

    // Drilldown context
    const { setPickedMetricIndex, setPickedCoordinates } = useContext(DrilldownContext);

    // Drilldown hook
    useDrilldownContext({ widget: widget as WidgetChartType | WidgetKpiType });
    const { isDrilldownEnabledForMetric } = useDrilldown({ widget: widget as WidgetChartType | WidgetKpiType });

    // Group the loading variables in a single one
    const loading = userRoleLoading || widgetLoading;

    // Widget removal hook
    const handleOnRemoveWidget = useCallback(() => onRemoveWidget(widgetId), [onRemoveWidget, widgetId]);

    // Handle pick event
    const handlePickEvent = useCallback(
        (e: PickEvent) => {
            // Update `pickedMetricIndex` in drilldown context
            setPickedMetricIndex(e.srcIndex);

            // Update `pickedCoordinates` in drilldown context
            setPickedCoordinates(e.coordinates);

            // If drilldown is enabled for the picked metric, open the drilldown modal
            if (isDrilldownEnabledForMetric(e.srcIndex)) setIsDrilldownModalOpen(true);
        },
        [isDrilldownEnabledForMetric, setPickedCoordinates, setPickedMetricIndex]
    );

    // Abort if user is not allowed to view the widget
    if (!userRoleLoading && !widgetLoading && (!canView || !widget)) {
        return <WidgetNotFound isEditing={isEditing} onRemoveWidget={handleOnRemoveWidget} />;
    }

    // Display the widget based on its type
    if (widget?.configType == 'HSEPARATOR') {
        return (
            <WidgetHSeparator
                isEditable={canEdit}
                isEditing={isEditing}
                loading={loading}
                onRemoveWidget={handleOnRemoveWidget}
                widget={widget}
            />
        );
    } else if (widget?.configType == 'MARKDOWN') {
        return (
            <WidgetMarkdown
                isEditable={canEdit}
                isRemovable={canRemove}
                isDashboardEditing={isEditing}
                loading={loading}
                onRemoveWidget={handleOnRemoveWidget}
                widget={widget}
            />
        );
    } else if (widget?.configType == 'VSEPARATOR') {
        return (
            <WidgetVSeparator
                isEditable={canEdit}
                isEditing={isEditing}
                loading={loading}
                onRemoveWidget={handleOnRemoveWidget}
                widget={widget}
            />
        );
    } else if (widget) {
        return (
            <>
                <WidgetCard
                    dashboardId={dashboardId}
                    canEdit={canEdit}
                    canView={canView}
                    canRemove={canRemove}
                    isDashboardEditing={isEditing}
                    enableSave
                    loading={loading}
                    onRemoveWidget={handleOnRemoveWidget}
                    widget={widget}
                    onPick={handlePickEvent}
                />
                <DrilldownModal
                    isOpen={isDrilldownModalOpen}
                    onHide={() => setIsDrilldownModalOpen(false)}
                    widget={widget as WidgetChartType | WidgetKpiType}
                    dashboardId={dashboardId}
                    canEdit={canEdit}
                />
            </>
        );
    } else return null;
};

export default DashboardWidget;
