import gql from 'graphql-tag';
import { NameLabelPair } from 'react-querybuilder';

export const GET_CUSTOM_FIELDS = gql`
    query {
        report(
            queries: [
                {
                    fact: CUSTOM_FIELDS
                    dimensions: [
                        { field: "id" }
                        { field: "name" }
                        { field: "schema" }
                        { field: "meta.provider", label: "provider" }
                    ]
                    target: "fields"
                }
            ]
        ) {
            id
            results
            errors {
                message
            }
        }
    }
`;

export type CustomFieldType = 'STRING' | 'INTEGER' | 'FLOAT' | 'BOOLEAN' | 'DATETIME' | 'ARRAY' | 'OBJECT';

export interface ObjectFieldSchema {
    // eslint-disable-next-line no-use-before-define
    [key: string]: PrimitiveFieldSchema;
}

export interface PrimitiveFieldSchema {
    type: CustomFieldType;
    array?: boolean;
    schema: ObjectFieldSchema;
    values?: NameLabelPair[];
}

export interface CustomFieldResp {
    id: string;
    name: string;
    schema: PrimitiveFieldSchema;
    provider: string;
    providerLabel?: string;
}

export interface GetCustomFieldsResp {
    report: {
        id: string;
        results: {
            fields: CustomFieldResp[];
        };
        errors?: { message: string }[];
    };
}
