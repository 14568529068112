import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import {
    SUBSCRIBE_TO_INTEGRATION_UPDATED,
    GetCompanyIntegrationsResp,
    GET_COMPANY_INTEGRATIONS,
    SUBSCRIBE_TO_INTEGRATION_DELETED,
    IntegrationResp
} from '../queries/Integration';
import { useSafeSubscription } from 'api/core/useSafeSubscription';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
    categories?: string[];
}

export function useCompanyIntegrations({
    companyId,
    categories,
    skip
}: QueryOpts): QueryResult<GetCompanyIntegrationsResp, OperationVariables> {
    const updSub = useSafeSubscription(SUBSCRIBE_TO_INTEGRATION_UPDATED, {
        skip: skip || !companyId,
        variables: { companyId: companyId }
    });

    const delSub = useSafeSubscription(SUBSCRIBE_TO_INTEGRATION_DELETED, {
        skip: skip || !companyId,
        variables: { companyId: companyId },
        onSubscriptionData: ({ client, subscriptionData }) => {
            // After disconnecting an app, if we just try to refetch here, in order to have the synchronised list of integrations,
            // apollo fails to update the cache. Therefore, we remove the deleted integration from the cache manually.
            const deletedIntegration = subscriptionData?.data?.integrationDeleted;
            if (!deletedIntegration) return;

            // Read the integrations cache
            const previousResult: GetCompanyIntegrationsResp | null = client.readQuery({
                query: GET_COMPANY_INTEGRATIONS,
                variables: { companyId: companyId }
            });
            const existingIntegrations = previousResult?.integrations.nodes || [];
            const pageInfo = previousResult?.integrations.pageInfo || { endCursor: null };

            // Remove the deleted integration from the list of integrations
            const newIntegrations = existingIntegrations?.filter((i: IntegrationResp) => i.id != deletedIntegration.id);

            // Write the updated list in the cache
            client.writeQuery({
                query: GET_COMPANY_INTEGRATIONS,
                variables: { companyId: companyId },
                data: {
                    integrations: {
                        __typename: 'IntegrationConnection',
                        pageInfo: pageInfo,
                        nodes: newIntegrations
                    }
                }
            });
        }
    });

    // Integrations data fetching and subscriptions
    // Note 1: we do not fetch paginated pages on integrations because it is not expected
    // that companies will have more than 100 integrations.
    // Note 2: we fetch integrations after subscribing to updates and deletions to ensure we do not
    // miss any update event
    const rs = useQuery<GetCompanyIntegrationsResp>(GET_COMPANY_INTEGRATIONS, {
        skip: skip || !companyId || updSub.loading || delSub.loading,
        variables: { companyId: companyId, categories: categories }
    });

    // Note: if the endCursor is not null then it means we're still fetching pages of data
    return { ...rs, loading: updSub.loading || delSub.loading || rs.loading };
}
