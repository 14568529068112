import { QueryResult } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { OperationVariables } from 'apollo-client';
import omitDeep from 'omit-deep-lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import {
    GetResourceRolesResp,
    ResourceType,
    ResourceRole,
    RESOURCE_ROLES,
    GET_DASHBOARD_RESOLVED_ROLES,
    GET_WIDGET_RESOLVED_ROLES,
    ResourceRoleDeletedSubscription,
    ResourceRoleUpsertedSubscription,
    SUBSCRIBE_TO_RESOURCE_ROLE_CREATED,
    SUBSCRIBE_TO_RESOURCE_ROLE_DELETED,
    SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED
} from '../queries/ResourceRole';

interface QueryOpts {
    skip?: boolean;
    resourceId: string;
    resourceType: ResourceType;
}

interface ResourceRolesQueryResults extends QueryResult<GetResourceRolesResp, OperationVariables> {
    normalized: ResourceRole[];
    isShared: boolean;
}

export function useResourceRoles(opts: QueryOpts): ResourceRolesQueryResults {
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Determine the query based on the provided resourceType
    let query;
    switch (opts.resourceType) {
        case 'DASHBOARD':
            query = GET_DASHBOARD_RESOLVED_ROLES;
            break;
        case 'WIDGET':
            query = GET_WIDGET_RESOLVED_ROLES;
            break;
        default:
            throw new Error("'useResourceRoles' hook arguments: unsupported resourceType.");
    }

    // Retrieve the resource's resolved roles
    const rs = useQuery<GetResourceRolesResp>(query, {
        skip: opts?.skip,
        variables: {
            resourceId: opts.resourceId
        }
    });

    // Subscribe to role updates
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if:
            // - the role is directly related to the resource
            // - the resource is a widget and the role is related to ANY dashboard
            const role = sub.subscriptionData.data?.role;
            if (opts.resourceType == 'DASHBOARD' && role?.resourceType != opts.resourceType) return;
            if (role?.resourceType == opts.resourceType && role?.resourceId != opts.resourceId) return;

            rs.refetch();
        }
    });

    // Subscribe to role creation
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if:
            // - the role is related the evaluated dashboard
            // - the resource is a widget and any dashboard is updated
            const role = sub.subscriptionData.data?.role;
            if (role?.resourceType != opts.resourceType || role?.resourceId != opts.resourceId) return;

            rs.refetch();
        }
    });

    // Subscribe to role deletion
    useSafeSubscription<ResourceRoleDeletedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            // The subscription payload only contains the object ID. We cannot
            // check the Resource.
            rs.refetch();
        }
    });

    // Extract the list of roles
    const normalized = useMemo(() => {
        const objList = rs.data?.resource?.roles || [];
        return objList
            .map(e => omitDeep(e, '__typename') as ResourceRole)
            .sort((a, b) => RESOURCE_ROLES.indexOf(a.name) - RESOURCE_ROLES.indexOf(b.name));
    }, [rs.data?.resource?.roles]);

    // Evaluate if the resource is shared with more than one person
    const isShared = useMemo(() => normalized && normalized.length > 1, [normalized]);

    return { ...rs, loading: rs.loading, normalized: normalized, isShared };
}
