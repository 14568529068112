import React, { ReactNode, FunctionComponent } from 'react';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

export interface SortConfig {
    columnId: string;
    asc: boolean;
}

interface Props {
    id: string;
    className?: string;
    children?: ReactNode;
    sort: SortConfig | undefined;
    onClick: () => void;
}

export const SortableHeader: FunctionComponent<Props> = ({ id, className, children, sort, onClick }) => {
    return (
        <th className={classnames('cursor-pointer', className)} onClick={onClick}>
            {children}
            {!sort || sort.columnId != id ? (
                <FontAwesomeIcon icon={faSort} className="ms-2" />
            ) : sort.asc ? (
                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
            ) : (
                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
            )}
        </th>
    );
};
