import React, { FunctionComponent, useState, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import { IntegrationResp } from 'api/hq/queries/Integration';
import { TrackProjectResp, TRACK_PROJECT } from 'api/hq/queries/Project';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    onTrack?: (projectRef: string) => void;
    variant?: string;
    className?: string;
    integrations: IntegrationResp[];
}

const TrackProjectBtn: FunctionComponent<Props> = ({
    variant = 'primary',
    className,
    onTrack,
    integrations
}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleTrackSuccess = useCallback((projectRef: string): void => onTrack && onTrack(projectRef), [onTrack]);

    // Abort if no integrations are present
    if (integrations.length === 0) return null;

    return (
        <>
            <TrackProjectModal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                onSuccess={handleTrackSuccess}
                integrations={integrations}
            />
            <Button variant={variant} className={className} onClick={() => setIsModalOpen(true)}>
                <FormattedMessage id="settings.project-list.track-project.track-project" />
            </Button>
        </>
    );
};

interface TrackProjectModalProps {
    isOpen: boolean;
    toggle: () => void;
    onSuccess?: (projectRef: string) => void;
    integrations: IntegrationResp[];
}

const TrackProjectModal: FunctionComponent<TrackProjectModalProps> = ({
    isOpen,
    toggle,
    onSuccess,
    integrations
}: TrackProjectModalProps) => {
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);
    const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);
    const [projectIntegration, setProjectIntegration] = useState<IntegrationResp>(integrations[0]);
    const [projectReference, setProjectReference] = useState<string>('');
    const [mutationDone, setMutationDone] = useState<boolean>(false);

    // Mutation Declaration
    const [trackProject] = useMutation<TrackProjectResp>(TRACK_PROJECT);

    // Handle following a new project
    const followProject = useCallback(async () => {
        setMutationInProgress(true);
        const splitProjectRef = projectReference?.split('/');
        const accountName = splitProjectRef[0];
        const projectName = splitProjectRef[1];

        try {
            const resp = await trackProject({
                variables: { integrationId: projectIntegration.id, accountName: accountName, projectName: projectName }
            });
            const trackResp = resp.data?.trackProject;

            if (trackResp?.success) {
                setMutationDone(true);
                setMutationError(undefined);
                onSuccess && onSuccess(projectReference);
            } else {
                setMutationError(trackResp?.errors[0]);
            }
        } catch {
            setMutationError({ code: 'default' });
        } finally {
            setMutationInProgress(false);
        }
    }, [onSuccess, projectIntegration.id, projectReference, trackProject]);

    // Validate project reference input
    const isProjectReferenceValid = useMemo(() => !!projectReference.match(/^\w([\w-]*\w)?\/\w([\w-]*\w)$/), [
        projectReference
    ]);

    // Reset state when closing the modal
    const toggleModal = useCallback((): void => {
        toggle();
        setMutationError(undefined);
        setMutationInProgress(false);
        setProjectReference('');
        setMutationDone(false);
    }, [toggle]);

    return (
        <Modal show={isOpen} onHide={toggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="settings.project-list.track-project.modal.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mutationDone ? (
                    <p>
                        <FormattedMessage
                            id="settings.project-list.track-project.modal.body-success"
                            values={{ projectRef: <b>{`${projectIntegration.app.provider}:${projectReference}`}</b> }}
                        />
                    </p>
                ) : (
                    <>
                        <p>
                            <FormattedMessage id="settings.project-list.track-project.modal.body1" />
                        </p>
                        <p>
                            <FormattedMessage id="settings.project-list.track-project.modal.body2" />
                        </p>

                        <Form className="mb-2 w-100 m-auto">
                            <Form.Group>
                                <InputGroup>
                                    <DropdownButton variant="outline-dark" title={projectIntegration.app.name}>
                                        {integrations.map(integration => (
                                            <Dropdown.Item
                                                key={integration.id}
                                                onClick={() => setProjectIntegration(integration)}
                                            >
                                                {integration.app.name}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                    <Form.Control
                                        className="border-dark"
                                        placeholder="E.g. keypup-io/cloudtasker"
                                        isValid={isProjectReferenceValid}
                                        onChange={e => setProjectReference(e.target.value)}
                                    />
                                </InputGroup>
                                <Form.Text>
                                    <FormattedMessage id="settings.project-list.track-project.modal.form.project-reference-explanation" />
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </>
                )}

                {mutationError && (
                    <ErrorMessage error={{ ...mutationError, namespace: 'track-project' }} className="text-danger" />
                )}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                {mutationDone ? (
                    <Button
                        track-id="click-app-disconnect"
                        variant="outline-dark"
                        onClick={toggleModal}
                        className="w-50 justify-content-center"
                    >
                        <FormattedMessage id="settings.project-list.track-project.modal.close" />
                    </Button>
                ) : (
                    <AsyncButton
                        track-id="click-follow-new-project"
                        variant="dark"
                        onClick={followProject}
                        disabled={!isProjectReferenceValid || mutationInProgress}
                        className="w-50 justify-content-center"
                        loading={mutationInProgress}
                        messageProps={{ id: 'settings.project-list.track-project.modal.submit' }}
                        loadingMessageProps={{ id: 'settings.project-list.track-project.modal.submitting' }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default TrackProjectBtn;
