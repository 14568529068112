import React, { FunctionComponent, ReactElement } from 'react';
import { RichMessage } from './RichMessage';
import { Placement } from 'popper.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Props {
    children: ReactElement;
    disabled?: boolean;
    id: string;
    placement?: Placement;
    values?: object;
}

// Wrap a component with RichTooltip to add contextual information on hover
export const RichTooltip: FunctionComponent<Props> = ({
    children,
    disabled,
    id,
    placement = 'right',
    values
}: Props) => {
    // Return content without tooltip
    if (disabled) return children;

    // Return content with tooltip
    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement={placement}
            transition={false}
            popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] }}
            overlay={
                <Tooltip className="rounded tooltip-md">
                    <RichMessage id={id} values={values} />
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};
