import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetColorRange } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';
import { Button } from 'react-bootstrap';
import NumberColorRange from 'routes/reports/GenericDashboard/DashboardWidgetEdit/ConfigurationPanel/NumberColorRange';

interface Props {
    colorRanges: WidgetColorRange[];
    onRangeUpdate: (rangeIndex?: number, range?: WidgetColorRange) => void;
    onAddRange: () => void;
    children?: ReactNode | ReactNodeArray;
}

const ThresholdColorConfig: FunctionComponent<Props> = ({ colorRanges, onRangeUpdate, onAddRange, children }) => {
    return (
        <>
            {/* Threshold-based coloring */}
            <div>
                {colorRanges?.map((range, index) => {
                    return (
                        <NumberColorRange
                            key={index}
                            className="mt-2"
                            range={range}
                            onChange={e => onRangeUpdate(index, e)}
                        />
                    );
                })}
                <div className="d-flex">
                    <Button variant="outline-light" onClick={onAddRange} className="mt-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <RichMessage id="dashboarding.widget-editor.threshold-based-color-config.add-threshold" />
                    </Button>
                    {children}
                </div>
            </div>
        </>
    );
};

export default ThresholdColorConfig;
