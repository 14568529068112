import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';
import { useMeasure } from 'react-use';

interface Props {
    header?: ReactNode;
    leftSectionStyle?: object;
    leftSectionClassName?: string;
    children: ReactNodeArray;
}

const LayoutSplitScreen: FunctionComponent<Props> = ({
    header,
    leftSectionStyle,
    leftSectionClassName,
    children
}: Props) => {
    // Height of the header
    const [ref, { height: headerHeight }] = useMeasure<HTMLDivElement>();

    return (
        <div className="vh-100 vw-100 d-flex flex-column overflow-hidden">
            {/* Header (optional) */}
            {header && (
                <div ref={ref} className="flex-grow-0">
                    {header}
                </div>
            )}

            {/* Split screen */}
            <div className="flex-grow-1 d-flex">
                {/* Left section */}
                <div className="w-100 w-xl-50 overflow-y-auto" style={{ maxHeight: `calc(100vh - ${headerHeight}px)` }}>
                    <div
                        className={classNames('w-100 h-100 p-4 d-flex flex-column mx-auto', leftSectionClassName)}
                        style={leftSectionStyle}
                    >
                        {children[0]}
                    </div>
                </div>

                {/* Right section */}
                <div className="w-50 d-none d-xl-block bg-dark-cross-pattern-1">
                    <div className="w-100 h-100 text-center d-flex align-items-center">{children[1]}</div>
                </div>
            </div>
        </div>
    );
};

export default LayoutSplitScreen;
