import { useGenericReport } from 'api/viz/hooks/useGenericReport';
import { Coordinates } from 'contexts/DrilldownContext';
import { RuleGroupType } from 'react-querybuilder';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { QueryOpFact } from '../DataSource';

interface Props {
    fact: QueryOpFact;
    filters?: RuleGroupType;
    pickedCoordinates?: Coordinates;
}

interface Results {
    filteredRecordsCount: number;
    totalRecordsCount: number;
    filteredRecordsLoading: boolean;
    totalRecordsLoading: boolean;
}

export function useFilteredRecords({ fact, filters, pickedCoordinates }: Props): Results {
    // Redux state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    const metricLabel = 'm0';
    const countMetric = { label: metricLabel, function: 'COUNT', args: [] };

    const { normalized: filteredRecords, loading: filteredRecordsLoading } = useGenericReport({
        userId: user?.id,
        companyId: company?.id,
        fact,
        filters,
        dimensions: [],
        metrics: [countMetric],
        pickedCoordinates
    });
    const { normalized: totalRecords, loading: totalRecordsLoading } = useGenericReport({
        userId: user?.id,
        companyId: company?.id,
        fact,
        dimensions: [],
        metrics: [countMetric]
    });

    const filteredRecordsCount = filteredRecords && filteredRecords.length ? +filteredRecords[0][metricLabel] : 0;
    const totalRecordsCount = totalRecords && totalRecords.length ? +totalRecords[0][metricLabel] : 0;

    return {
        filteredRecordsCount,
        filteredRecordsLoading,
        totalRecordsCount,
        totalRecordsLoading
    };
}
