import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { faCog, faEllipsisV, faSort, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    WidgetChartType,
    WidgetConfigType,
    WidgetDimensionField,
    WidgetFactType,
    WidgetKpiType,
    WidgetList
} from 'components/Dashboarding/Models/Widget';
import { LineLoadIndicator } from 'components/LineLoadIndicator';
import { widgetEditPath } from 'constants/routeBuilders';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import InnerWidget from './InnerWidget';
import { ErrorBoundary } from 'react-error-boundary';
import WidgetErrorFallback from './WidgetErrorFallback';
import Dropdown from 'react-bootstrap/Dropdown';
import { RichMessage } from 'components/RichMessage';
import classNames from 'classnames';
import { Button, Spinner } from 'react-bootstrap';
import DynamicWidthTransparentInput from 'components/Design/DynamicWidthTransparentInput';
import WidgetDocumentationTooltip from 'components/Dashboarding/WidgetDocumentationTooltip';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useIntl } from 'react-intl';
import { Bullet } from 'components/Bullet';
import { isEqual, pick } from 'lodash';
import { toastQueryError } from 'components/Toast';
import { useMutation } from '@apollo/react-hooks';
import { UpdateWidgetResp, UPDATE_WIDGET, WIDGET_UPDATE_ATTRS } from 'api/hq/queries/Widget';
import { PickEvent } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import { DrilldownContext } from 'contexts/DrilldownContext';
import { useDrilldown } from '../DashboardWidgetEdit/ConfigurationPanel/useDrilldown';
import WidgetSortSelector from '../DashboardWidgetEdit/ConfigurationPanel/WidgetSortSelector';
import { immutableSet } from 'util/ObjectOperators';

// List of widget types where scrolling is allowed
const SCROLLABLE_CONFIG_TYPE: WidgetConfigType[] = ['LARGECARD', 'MINICARD', 'LIST'];

interface Props {
    className?: string;
    dashboardId?: string;
    canEdit?: boolean;
    canView?: boolean;
    canRemove?: boolean;
    isDashboardEditing?: boolean;
    isRemovable?: boolean;
    isEditing?: boolean;
    enableSave?: boolean;
    hideMoreButton?: boolean;
    loading?: boolean;
    onChange?: (e: WidgetFactType) => void;
    onPick?: (event: PickEvent) => void;
    onRemoveWidget?: () => void;
    widget: WidgetFactType;
    drilldownMode?: boolean;
    forcedMessageId?: string;
    tooltipZIndex?: number;
}

// The actual widget card. Takes a full widget as parameter.
const WidgetCard: FunctionComponent<Props> = ({
    className,
    dashboardId,
    canEdit,
    canView,
    canRemove,
    isDashboardEditing,
    isEditing,
    enableSave,
    hideMoreButton,
    loading,
    onChange,
    onPick,
    onRemoveWidget,
    widget,
    drilldownMode,
    forcedMessageId,
    tooltipZIndex
}: Props) => {
    // Service
    const intl = useIntl();

    // Retrieve `pickedMetricIndex` from context
    const { pickedMetricIndex } = useContext(DrilldownContext);

    // Retrieve generated widget for the drilldown report
    const { getDrilldownWidgetForMetric, mergeDrilldownConfigAndWidget } = useDrilldown({
        widget: widget as WidgetChartType | WidgetKpiType,
        onComplete: onChange
    });
    const drilldownPreviewWidget = useMemo(() => getDrilldownWidgetForMetric(pickedMetricIndex, widget.name), [
        getDrilldownWidgetForMetric,
        pickedMetricIndex,
        widget.name
    ]);

    // State
    const [previewWidget, setPreviewWidget] = useState<WidgetFactType>(
        drilldownMode && drilldownPreviewWidget ? drilldownPreviewWidget : widget
    );
    const [dataReloading, setDataReloading] = useState<boolean>(false);
    const [editedWidget, setEditedWidget] = useState<WidgetFactType>();
    const [showSaveCancelButtons, setShowSaveCancelButtons] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

    // Retrieve `coordinates` from drilldown context
    const { coordinates } = useContext(DrilldownContext);

    // Mutations
    const [updateWidget] = useMutation<UpdateWidgetResp>(UPDATE_WIDGET);

    // Evaluate if the widget is scrollable. Used to control the overflow
    // behaviour
    const isScrollable = useMemo(() => {
        return widget?.configType && SCROLLABLE_CONFIG_TYPE.includes(widget?.configType);
    }, [widget?.configType]);

    // Build edit link
    const widgetEditLink = useMemo(() => widgetEditPath({ dashboardId, widget }), [dashboardId, widget]);

    // Build edit drilldown link
    const widgetEditDrilldownLink = useMemo(
        () => widgetEditPath({ dashboardId, widget, step: 'configure-drilldown' }),
        [dashboardId, widget]
    );

    // Build the duplicate link
    const widgetDuplicateLink = useMemo(() => {
        if (!widget) return '';

        // If the user (can edit) the dashboard, the duplication will result in adding the widget to the  current dashboard
        // if the user (can not edit) the dashboard, the duplication will result in adding the widget to the user collection NOT the current dashboard
        return widgetEditPath({
            dashboardId: canEdit ? dashboardId : undefined,
            widget: {
                ...widget,
                id: '',
                name: intl.formatMessage({ id: 'generic.with-copy-suffix' }, { name: widget.name }),
                templateSource: widget.id,
                actionSource: 'duplicate'
            }
        });
    }, [canEdit, dashboardId, intl, widget]);

    // Hook invoked when the inner widget emits
    const handleOnChange = useCallback(
        (e: WidgetFactType) => {
            setEditedWidget(undefined);
            // Direct save from the card
            if (enableSave) {
                // Abort if user can't edit the widget
                if (!canEdit) return;

                // Abort if the emitted widget is equal to the current one
                if (isEqual(e, previewWidget)) {
                    setShowSaveCancelButtons(false);
                    return;
                }

                // Update temporary state
                setEditedWidget(e);

                // Show save/cancel buttons
                setShowSaveCancelButtons(true);
            } else if (onChange && drilldownMode) {
                const updatedWidget = mergeDrilldownConfigAndWidget(e as WidgetList, pickedMetricIndex);

                if (updatedWidget) onChange(updatedWidget);
            } else if (onChange) {
                // Propagate to parent component
                onChange(e);
            }
        },
        [enableSave, onChange, drilldownMode, canEdit, previewWidget, mergeDrilldownConfigAndWidget, pickedMetricIndex]
    );

    // Extracted dimensions
    const dimensions = useMemo(() => (editedWidget || previewWidget).config.series[0].dimensions, [
        editedWidget,
        previewWidget
    ]);

    // This will handle sort changing for LargeCard insight which has only one series
    const handleSortChanged = useCallback(
        (dimensions: WidgetDimensionField[]) => {
            const newEditedWidget = immutableSet(widget, 'config.series[0].dimensions', dimensions);

            setEditedWidget(newEditedWidget);
            if (enableSave) setShowSaveCancelButtons(true);

            // This will persist the update
            if (onChange) onChange(newEditedWidget);
        },
        [enableSave, onChange, widget]
    );

    // Hook invoked when the `Save` button is clicked
    const handleSaveWidget = useCallback(async () => {
        // Set loading state
        setSaveInProgress(true);

        try {
            let widgetToSave;
            if (drilldownMode) {
                const updatedWidget = mergeDrilldownConfigAndWidget(editedWidget as WidgetList, pickedMetricIndex);
                widgetToSave = updatedWidget;
            } else {
                widgetToSave = editedWidget;
            }

            // Perform the save
            const payload = pick(widgetToSave, WIDGET_UPDATE_ATTRS);
            const resp = (await updateWidget({ variables: { input: payload } })).data?.updateWidget;

            // Handle failure
            if (resp?.success) {
                // Reset loading state
                setSaveInProgress(false);

                // Hide the `Save` / `Cancel` buttons
                setShowSaveCancelButtons(false);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'save-widget' });
            }
        } catch (e) {
            toastQueryError({ namespace: 'save-widget' });
        } finally {
            // Reset loading state
            setSaveInProgress(false);
        }
    }, [drilldownMode, editedWidget, mergeDrilldownConfigAndWidget, pickedMetricIndex, updateWidget]);

    // Hook invoked on `Cancel` button click
    const handleCancelFormattingChanges = useCallback(() => {
        // Reset widget
        setPreviewWidget(drilldownMode && drilldownPreviewWidget ? { ...drilldownPreviewWidget } : { ...widget });

        // Hide the `Save` / `Cancel` buttons
        setShowSaveCancelButtons(false);
    }, [drilldownMode, drilldownPreviewWidget, widget]);

    // Save the temporary changes or reset to the persisted changes
    const SaveChangesButtons: FunctionComponent = useMemo(
        () => () =>
            !isEditing && saveInProgress ? (
                <Spinner animation="border" size="sm" />
            ) : showSaveCancelButtons ? (
                <>
                    <Button
                        className="p-0 border-0 text-primary"
                        variant="link"
                        onClick={handleCancelFormattingChanges}
                    >
                        <RichMessage id="dashboarding.widget-editor.action.cancel" />
                    </Button>
                    <span className="text-primary">
                        <Bullet />
                    </span>
                    <Button className="p-0 border-0 text-primary" variant="link" onClick={handleSaveWidget}>
                        <RichMessage id="dashboarding.widget-editor.action.save" />
                    </Button>
                </>
            ) : null,
        [handleCancelFormattingChanges, handleSaveWidget, isEditing, saveInProgress, showSaveCancelButtons]
    );

    // Widget actions for each insight type
    const WidgetActions: FunctionComponent = useMemo(
        () => () => {
            switch (widget.configType) {
                case 'LARGECARD':
                    return (
                        <>
                            <span className="me-2 d-flex">
                                <SaveChangesButtons />
                            </span>
                            <WidgetSortSelector
                                fact={widget.config.fact}
                                onChange={handleSortChanged}
                                dimensions={dimensions}
                            >
                                <Dropdown.Toggle
                                    as="div"
                                    bsPrefix="none"
                                    className="d-flex justify-content-between align-items-center h-100"
                                    variant="link"
                                >
                                    <FontAwesomeIcon icon={faSort} className="cursor-pointer" />
                                </Dropdown.Toggle>
                            </WidgetSortSelector>
                        </>
                    );
                default:
                    return <SaveChangesButtons />;
            }
        },
        [SaveChangesButtons, dimensions, handleSortChanged, widget.config.fact, widget.configType]
    );

    useEffect(() => setPreviewWidget(drilldownMode && drilldownPreviewWidget ? drilldownPreviewWidget : widget), [
        drilldownMode,
        drilldownPreviewWidget,
        widget
    ]);

    // Render
    return (
        <Card className={classNames('h-100 position-relative', className, { 'cursor-move': isDashboardEditing })}>
            {isDashboardEditing && (
                <>
                    {/* Overlay on top of the widget when we're editing the dashboard */}
                    <div
                        className="cursor-move position-absolute bg-semi-transparent m-3"
                        style={{ zIndex: 1, inset: 0 }}
                    ></div>

                    {/* Remove button */}
                    <div className="position-absolute text-danger m-3" style={{ zIndex: 1, top: 0, right: 0 }}>
                        <FontAwesomeIcon role="button" icon={faTrash} onClick={onRemoveWidget} />
                    </div>
                </>
            )}

            {/* Widget refreshing indicator */}
            <LineLoadIndicator show={dataReloading} />

            {/* Widget content */}
            {loading ? (
                // Loading state
                <Card.Body className="h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </Card.Body>
            ) : previewWidget ? (
                // Widget is loaded
                <Card.Body className="h-100 d-flex flex-column">
                    {/* Widget name & Action buttons*/}
                    <div className="d-flex mb-3">
                        {/* Name */}
                        {isEditing && onChange ? (
                            <div className="d-flex align-items-center flex-grow-1">
                                <DynamicWidthTransparentInput
                                    value={previewWidget?.name}
                                    onCommit={val => onChange({ ...previewWidget, name: val })}
                                    placeholderId="dashboarding.widget-editor.defaut-config.name-placeholder"
                                    className="transparent-framed-input framed-input h4 px-2 m-0"
                                />
                                {previewWidget?.documentationEnabled && (
                                    <WidgetDocumentationTooltip widget={previewWidget} />
                                )}
                            </div>
                        ) : (
                            <div className="mw-100 m-0 flex-grow-1">
                                {/* Name */}
                                <h5 className="text-dark d-flex align-items-center" title={previewWidget.description}>
                                    <div className="text-truncate">{previewWidget.name}</div>
                                    {previewWidget?.documentationEnabled && (
                                        <WidgetDocumentationTooltip widget={previewWidget} />
                                    )}
                                </h5>

                                {/* Picked datapoint (for drilldown report only) */}
                                {drilldownMode && (
                                    <div className="d-flex text-grey-4">
                                        <RichMessage id="dashboarding.drilldown-widget.subtitle" />
                                        {coordinates
                                            ?.map<React.ReactNode>((e, index) => (
                                                <span
                                                    key={`${e}${index}`}
                                                    className={classNames({ 'ms-2': index == 0 })}
                                                >
                                                    {e}
                                                </span>
                                            ))
                                            .reduce((previous, current, currentIndex) => [
                                                previous,
                                                <Bullet key={currentIndex} />,
                                                current
                                            ])}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Widget actions */}
                        <div className="mh-100 me-3 d-flex align-items-center">
                            <WidgetActions />
                        </div>

                        {/* Edit mode control buttons */}
                        {!hideMoreButton && (
                            <div className="d-none d-md-flex align-items-center">
                                <Dropdown>
                                    <Dropdown.Toggle as="div" bsPrefix="none" role="button" className="text-light">
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu align="end">
                                        {drilldownMode ? (
                                            <>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to={widgetEditDrilldownLink}
                                                    disabled={!canEdit}
                                                >
                                                    <FontAwesomeIcon icon={faCog} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.edit-drilldown" />
                                                </Dropdown.Item>
                                            </>
                                        ) : (
                                            <>
                                                <Dropdown.Item as={Link} to={widgetEditLink} disabled={!canEdit}>
                                                    <FontAwesomeIcon icon={faCog} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.edit-insight" />
                                                </Dropdown.Item>
                                                <Dropdown.Divider className="d-none d-md-block" />
                                                <Dropdown.Item as={Link} to={widgetDuplicateLink} disabled={!canView}>
                                                    <FontAwesomeIcon icon={faCopy} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.duplicate-insight" />
                                                </Dropdown.Item>
                                                <Dropdown.Divider className="d-none d-md-block" />
                                                <Dropdown.Item
                                                    as="div"
                                                    onClick={onRemoveWidget}
                                                    className={classNames({ 'text-danger': canRemove })}
                                                    role="button"
                                                    disabled={!canRemove}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.delete-insight" />
                                                </Dropdown.Item>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    </div>

                    {/* Widget data content */}
                    <div className={classNames('flex-grow-1', { 'overflow-auto': isScrollable })}>
                        <ErrorBoundary FallbackComponent={WidgetErrorFallback} onReset={onRemoveWidget}>
                            <InnerWidget
                                widget={previewWidget}
                                drilldownMode={drilldownMode}
                                onChange={handleOnChange}
                                onReloading={setDataReloading}
                                forcedMessageId={forcedMessageId}
                                onPick={onPick}
                                tooltipZIndex={tooltipZIndex}
                            />
                        </ErrorBoundary>
                    </div>
                </Card.Body>
            ) : null}
        </Card>
    );
};

export default WidgetCard;
