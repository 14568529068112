import enLang from './entries/en-US';
import esLang from './entries/es-ES';

interface LocaleConfig {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const AppLocale: LocaleConfig = {
    en: enLang,
    es: esLang
};

export default AppLocale;
