import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';
import { WidgetConfigType, WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { GetWidgetsConfigTypeResp, GET_WIDGETS_CONFIG_TYPE } from '../queries/Widget';

interface QueryOpts {
    skip?: boolean;
    ids?: string[];
}

interface WidgetsQueryResults extends QueryResult<GetWidgetsConfigTypeResp, OperationVariables> {
    normalized: { [id: string]: WidgetConfigType };
}

export function useWidgetsConfigType(opts?: QueryOpts): WidgetsQueryResults {
    // Retrieve the widgets by ids
    const rs = useQuery<GetWidgetsConfigTypeResp>(GET_WIDGETS_CONFIG_TYPE, {
        skip: opts?.skip || !opts?.ids,
        variables: { ids: opts?.ids }
    });

    // Extract the list of widgets
    const normalized = useMemo(() => {
        const objList = rs.data?.widgets.nodes || [];
        return Object.fromEntries(
            objList?.map(e => omitDeep(e, '__typename') as WidgetFactType)?.map(e => [e.id, e.configType])
        );
    }, [rs.data?.widgets.nodes]);

    return { ...rs, loading: rs.loading, normalized: normalized };
}
