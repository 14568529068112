import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent } from 'react';
import { Modal } from 'react-bootstrap';
import WidgetPreview from 'routes/reports/GenericDashboard/DashboardWidgetAssistant/WidgetPreview';
import WidgetActions from '../../../components/LibraryCard/WidgetLibraryCard/WidgetActions';

interface Props {
    isOpen: boolean;
    widget: WidgetFactType;
    isTemplate?: boolean;
    onHide: () => void;
}

const WidgetPreviewModal: FunctionComponent<Props> = ({ isOpen, widget, isTemplate, onHide }: Props) => {
    return (
        <Modal show={isOpen} onHide={onHide} centered fullscreen="md-down" size="lg">
            <Modal.Header closeButton className="d-flex">
                {/* Modal title */}
                <Modal.Title className="flex-grow-1">
                    <RichMessage id="collections.widget-preview-modal.title" />
                </Modal.Title>

                {/* Action buttons */}
                <div className="d-flex">
                    {!isTemplate && <WidgetActions variant="buttons" widget={widget} onDelete={onHide} />}
                </div>
            </Modal.Header>
            <Modal.Body className="bg-grey-11">
                {/* Widget preview */}
                <WidgetPreview widget={{ ...widget, documentationEnabled: true }} />
            </Modal.Body>
        </Modal>
    );
};

export default WidgetPreviewModal;
