import React, { FunctionComponent, useCallback } from 'react';
import { WidgetConfigCardNColumns, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import ColumnAccordion from './ColumnAccordion';
import { RichMessage } from 'components/RichMessage';
import FilterBoxButton from '../FilterBoxButton';

interface Props {
    widget: WidgetMiniCard;
    onChange: (widget: WidgetMiniCard) => void;
}

// Allows the user to configure the dimensions and metrics to be used by
// the widget and visualize the data in a table.
const ConfigureColumns: FunctionComponent<Props> = ({ widget, onChange }: Props) => {
    const onGlobalFiltersChange = useCallback(
        (updatedConfig: WidgetConfigCardNColumns) => {
            onChange({ ...widget, config: updatedConfig });
        },
        [onChange, widget]
    );

    return (
        <div>
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h4 className="text-dark mb-1">
                        <RichMessage id="dashboarding.widget-editor.configure-columns.heading.title" />
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage id="dashboarding.widget-editor.configure-columns.heading.description" />
                    </div>
                </div>
                <FilterBoxButton
                    className="me-4"
                    fact={widget.config.fact}
                    onChange={onGlobalFiltersChange}
                    series={widget.config}
                    variant="outline-dark"
                    titleId="dashboarding.widget-editor.configure-columns.global-filters"
                />
            </div>

            {/* Column-specific configuration */}
            <ColumnAccordion widget={widget} onChange={onChange} />
        </div>
    );
};

export default ConfigureColumns;
