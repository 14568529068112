import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { RichMessage } from './RichMessage';
import { Placement } from 'popper.js';
import { useRichIntl } from './useRichIntl';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { kpQuestionMark } from 'util/customIcons';
import classNames from 'classnames';
import { useBreakpoint } from 'util/useBreakpoint';
import { useClickAway } from 'react-use';

interface Props {
    id?: string;
    placement?: Placement;
    icon?: IconDefinition;
    className?: string;
    style?: object;
    children?: React.ReactElement;
    onToggle?: () => void;
    size?: SizeProp;
    show?: boolean;
    values?: object;
}

export const HelpIcon: FunctionComponent<Props> = ({
    id,
    placement,
    className = 'ms-1',
    style,
    icon = kpQuestionMark,
    children,
    onToggle,
    size,
    show,
    values
}: Props) => {
    // Services
    const intl = useRichIntl();
    const breakpoint = useBreakpoint();

    // State
    const [innerShow, setInnerShow] = useState(false);

    // Toggle the show state. wheather the state is controlled from the outside
    // or controlled internally from inside
    const handleToggle = useCallback(() => {
        if (onToggle) onToggle();
        setInnerShow(!innerShow);
    }, [onToggle, innerShow]);

    // Toggle the tooltip when clicking outside OR clicking on an another tooltip
    const tooltipRef = useRef(null);
    useClickAway(tooltipRef, handleToggle);

    // Set the right placement according to the screen size. Unless it is overriden by the placement prop
    const smallScreen = useMemo(() => ['xs', 'sm'].includes(breakpoint), [breakpoint]);
    const defaultPlacement = useMemo(() => (smallScreen ? 'bottom' : 'right'), [smallScreen]);
    const calculatedPlacement = useMemo(() => placement || defaultPlacement, [defaultPlacement, placement]);

    // Stop the propagation of the event so that, if the help icon is located inside another clickable element,
    // the click event on the help icon only triggers the tooltip and not the parent element click event handler.
    const onClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
    };

    // Abort if no content
    if (!(children || (id && intl.messages[id]))) return null;

    return (
        <OverlayTrigger
            trigger={['click']}
            flip
            onToggle={handleToggle}
            show={show ?? innerShow}
            placement={calculatedPlacement}
            overlay={
                <Tooltip className="rounded tooltip-sm mx-1" onClick={onClick}>
                    {children ? (
                        <div ref={tooltipRef}>{children}</div>
                    ) : (
                        <div ref={tooltipRef} className="tooltip-content">
                            <RichMessage id={id || ''} values={values} />
                        </div>
                    )}
                </Tooltip>
            }
        >
            <div className={classNames('d-inline-block align-middle', className)} style={style} onClick={onClick}>
                <span
                    className="mx-2 bg-grey-2 d-flex justify-content-center align-items-center rounded-circle border-1 border-white"
                    style={{ width: 14, height: 14, cursor: 'help' }}
                >
                    <FontAwesomeIcon icon={icon} size={size} color="white" style={{ width: 8, height: 8 }} />
                </span>
            </div>
        </OverlayTrigger>
    );
};
