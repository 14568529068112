import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from './useNotifications';
import { NotificationType, NOTIFICATION_TYPES } from 'api/hq/queries/Notification';
import toast from 'react-hot-toast';
import ConnectAppsToast from './NotificationToasts/ConnectAppsToast';
import ConnectProjectsToast from './NotificationToasts/ConnectProjectsToast';
import ProjectsImportedToast from './NotificationToasts/ProjectsImportedToast';
import ProjectsImportingToast from './NotificationToasts/ProjectsImportingToast';
import { useLocation } from 'react-router-dom';

// List of path regexes for which notification toasts should be visible
const ENABLED_PATHS = [new RegExp('/app/reports/[\\w-]+')];

/**
 * Method to dismiss all notification toasts
 */
const dismissAllNotificationToasts = (): void => {
    NOTIFICATION_TYPES.forEach((type: NotificationType) => toast.remove(type));
};

export function useToastNotifications(): void {
    const [currentNotification, setCurrentNotification] = useState<string>();
    const location = useLocation();
    const isHidden = useMemo(() => !ENABLED_PATHS.some(e => e.test(location.pathname)), [location]);

    // Get system notifications
    const notification = useNotifications();

    /**
     * Method to evaluate if a notification toast of a given notification type should be created
     * @param type the notification type
     */
    const shouldCreateToast = useCallback(
        (type: NotificationType): boolean => {
            // Is it allowed to be visible on the current location?
            if (isHidden) return false;

            // Is a toast of this type not already displayed?
            return currentNotification != type;
        },
        [currentNotification, isHidden]
    );

    // Whenever the location pathname changes, reset the current notification
    useEffect(() => {
        setCurrentNotification(undefined);
    }, [location.pathname]);

    useEffect(() => {
        // If location changes and the visible state changes to hidden, dismiss all notification toasts
        if (isHidden) dismissAllNotificationToasts();
    }, [isHidden]);

    useEffect(() => {
        // If the notification is of type 'connect-apps'
        if (notification?.type != 'connect-apps') return;
        if (!shouldCreateToast('connect-apps')) return;

        dismissAllNotificationToasts();
        toast.custom(t => <ConnectAppsToast t={t} />, { id: 'connect-apps', duration: Infinity });
        setCurrentNotification('connect-apps');
    }, [shouldCreateToast, currentNotification, notification?.type]);

    useEffect(() => {
        // If the notification is of type 'connect-projects'
        if (notification?.type != 'connect-projects') return;
        if (!shouldCreateToast('connect-projects')) return;

        dismissAllNotificationToasts();
        toast.custom(t => <ConnectProjectsToast t={t} />, { id: 'connect-projects', duration: Infinity });
        setCurrentNotification('connect-projects');
    }, [shouldCreateToast, currentNotification, notification?.type]);

    useEffect(() => {
        // If the notification is of type 'projects-importing'
        if (notification?.type != 'projects-importing') return;
        if (!shouldCreateToast('projects-importing')) return;
        const projectsCount = notification?.context?.projectsImportCount || 0;
        toast.custom(t => <ProjectsImportingToast t={t} projectsCount={projectsCount} />, {
            id: 'projects-importing',
            duration: Infinity
        });
        setCurrentNotification('projects-importing');
    }, [shouldCreateToast, currentNotification, notification]);

    useEffect(() => {
        // If the notification is of type 'projects-imported'
        if (notification?.type != 'projects-imported') return;
        if (!shouldCreateToast('projects-imported')) return;
        dismissAllNotificationToasts();
        const projectsCount = notification?.context?.projectsImportCount || 0;
        toast.custom(t => <ProjectsImportedToast t={t} projectsCount={projectsCount} />);

        // Set the current notification state back to undefined, so that further import
        // toasts can be displayed again if needed
        setCurrentNotification(undefined);
    }, [shouldCreateToast, currentNotification, notification]);
}
