import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ItemUserIcon from './ItemUserIcon';
import { ItemUser } from 'api/viz/queries/User';

// how many users icons to preview
const USERS_PREVIEW_COUNTS = 3;

interface Props {
    users: ItemUser[];
    size?: number;
    className?: string;
}

const ItemUserIconList: FunctionComponent<Props> = ({ users, size = 20, className }: Props) => {
    return (
        <div className={classNames('d-flex align-items-center justify-content-end ms-1 me-1', className)}>
            {/* users avatars */}
            {users.length > 0 &&
                users.slice(0, USERS_PREVIEW_COUNTS).map((user, index) => (
                    <div key={index} className="cards user-item position-relative">
                        <ItemUserIcon user={user} size={size} />
                    </div>
                ))}

            {/* users counter if they are more than 3 */}
            {users.length > USERS_PREVIEW_COUNTS && (
                <div
                    className="user bg-grey-7 d-flex justify-content-center align-items-center rounded-circle fs-sm fw-semibold"
                    style={{ width: size, height: size, zIndex: 1 }}
                >
                    +{users.length - USERS_PREVIEW_COUNTS}
                </div>
            )}
        </div>
    );
};

export default ItemUserIconList;
