// Apollo Configuration
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Pusher, { AuthInfo } from 'pusher-js';
import PusherLink from './PusherLink';
import AuthErrorLink from './AuthErrorLink';
import { RetryLink } from 'apollo-link-retry';
import { OperationDefinitionNode, OperationTypeNode } from 'graphql';
import { CURRENT_API_USER_TOKEN_KEY } from 'constants/localStorageKeys';

// Configuration
const GRAPHQL_URI = process.env.REACT_APP_VIZ_GRAPHQL_URI;
const PUSHER_APP_KEY = process.env.REACT_APP_VIZ_PUSHER_APP_KEY || 'none';
const PUSHER_CLUSTER = process.env.REACT_APP_VIZ_PUSHER_CLUSTER || 'us2';
const PUSHER_AUTH_URI = process.env.REACT_APP_VIZ_PUSHER_AUTH_URI || 'none';

// Custom Pusher authorizer using Bearer authentication to Viz API
const loadPusherToken = async (channelName: string, socketId: string): Promise<AuthInfo> => {
    const formData = new FormData();
    formData.append('channel_name', channelName);
    formData.append('socket_id', socketId);

    const response = await fetch(PUSHER_AUTH_URI, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(CURRENT_API_USER_TOKEN_KEY)}`
        }
    });

    return response.json();
};

// Load Pusher and create a client
// A custom authorizer is provided so as to defer the auth until
// currentApiUserToken becomes available in localStorage
const pusherClient = new Pusher(PUSHER_APP_KEY, {
    cluster: PUSHER_CLUSTER,
    authEndpoint: PUSHER_AUTH_URI,
    authorizer: function(channel) {
        return {
            authorize: async function(socketId, callback) {
                const resp = await loadPusherToken(channel.name, socketId);
                callback(false, resp);
            }
        };
    },
    forceTLS: true
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(CURRENT_API_USER_TOKEN_KEY);

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

// Automatically retry on network/server error
const retryLink = new RetryLink({
    attempts: {
        max: 10,
        retryIf: (error, operation) => {
            // Do not retry mutations or unauthorized queries
            return (
                error.statusCode !== 401 &&
                error.statusCode !== 403 &&
                !operation.query.definitions
                    .map(e => (e as OperationDefinitionNode).operation)
                    .includes(OperationTypeNode.MUTATION)
            );
        }
    }
});

// Make the HTTP link which actually sends the queries
const httpLink = new HttpLink({
    uri: GRAPHQL_URI
});

// Make the Pusher link which will pick up on subscriptions
const pusherLink = new PusherLink({ pusher: pusherClient });

// Combine the two links to work together
// IMPORTANT NOTE: ensure that authLink is concatenated to httpLink ONLY. If authLink
// is concatenated to pusherLink (e.g. by calling authLink.concat(link) in vizApolloClient)
// then Pusher updates will stop refreshing the cache.
const link = ApolloLink.from([retryLink, AuthErrorLink, pusherLink, authLink.concat(httpLink)]);

// Initialize the client
export const vizApolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        }
    }
    // connectToDevTools: true
});
