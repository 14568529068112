import React, { FunctionComponent } from 'react';
import { ItemLabel } from 'api/viz/queries/InboxItem';

import { textColorFromBg, shadeHexColor } from 'util/ColorOperators';
import Badge from 'react-bootstrap/Badge';

interface Props {
    label: ItemLabel;
}

const LabelBadge: FunctionComponent<Props> = ({ label }: Props) => {
    const backgroundColor = label.color || 'd6d6d6';
    const bgColor = backgroundColor.startsWith('#') ? backgroundColor : `#${backgroundColor}`;

    return (
        <Badge
            className="me-1 mt-1"
            pill
            bg="none"
            style={{
                color: textColorFromBg(bgColor, shadeHexColor(bgColor, 0.9), shadeHexColor(bgColor, -0.8)),
                backgroundColor: bgColor
            }}
        >
            {label.name}
        </Badge>
    );
};

export default LabelBadge;
