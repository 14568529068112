import React, { ReactElement } from 'react';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage } from 'components/RichMessage';
import Form from 'react-bootstrap/Form';
import MarkdownEditor from 'components/Input/MarkdownEditor';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';

interface Props<TWidget extends WidgetFactType> {
    hideTitle?: boolean;
    widget: TWidget;
    onChange: (widget: TWidget) => void;
}

function DocumentInsight<TWidget extends WidgetFactType>({
    hideTitle,
    widget,
    onChange
}: Props<TWidget>): ReactElement<Props<TWidget>> {
    // Render
    return (
        <div>
            {/* Metrics section */}
            <div>
                {/* Title */}
                {!hideTitle && (
                    <div>
                        <h4 className="text-dark mb-4">
                            <span className="elevio-customize-chart-title">
                                <RichMessage id="dashboarding.widget-editor.document-insight.title" />
                            </span>
                        </h4>
                    </div>
                )}

                {/* Documentation switch */}
                <Row className="mb-4">
                    <Col xs="12">
                        {/* Legend switch */}
                        <div className="d-flex justify-content-between mb-2">
                            {/* Header */}
                            <div className="me-2">
                                <Form.Label htmlFor="legendSwitchInput" className="d-block mb-0">
                                    <RichMessage id="dashboarding.widget-editor.document-insight.display-documentation.label" />
                                </Form.Label>
                                <Form.Text muted>
                                    <RichMessage id="dashboarding.widget-editor.document-insight.display-documentation.description" />
                                </Form.Text>
                            </div>

                            {/* Input */}
                            <Form.Check
                                type="switch"
                                id="documentationSwitchInput"
                                aria-describedby="documentationSwitchHelp"
                                role="button"
                                checked={!!widget.documentationEnabled}
                                onChange={e => onChange({ ...widget, documentationEnabled: e.target.checked })}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Description */}
                <Row className="mb-4">
                    <Col xs="12" className="pe-4">
                        <div className="mb-2">
                            {/* Header */}
                            <div className="mb-2">
                                <Form.Label htmlFor="descriptionInput" className="d-block mb-0">
                                    <RichMessage id="dashboarding.widget-editor.document-insight.description.label" />
                                </Form.Label>
                                <Form.Text muted>
                                    <RichMessage id="dashboarding.widget-editor.document-insight.description.description" />
                                </Form.Text>
                            </div>

                            {/* Description input */}
                            <FormControlWithCommit
                                id="descriptionInput"
                                value={widget.description}
                                onCommit={val => onChange({ ...widget, description: val })}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Documentation */}
                <Row className="mb-4">
                    <Col xs="12" className="pe-4">
                        <div className="mb-2">
                            {/* Header */}
                            <div className="mb-2">
                                <Form.Label htmlFor="documentationInput" className="d-block mb-0">
                                    <RichMessage id="dashboarding.widget-editor.document-insight.documentation.label" />
                                </Form.Label>
                                <Form.Text muted>
                                    <RichMessage id="dashboarding.widget-editor.document-insight.documentation.description" />
                                </Form.Text>
                            </div>

                            {/* Documentation input */}
                            <div className="px-3 py-2 form-control-border rounded" style={{ height: 250 }}>
                                <MarkdownEditor
                                    preview="edit"
                                    previewClass="tooltip-content"
                                    value={widget.documentation}
                                    onCommit={val => onChange({ ...widget, documentation: val })}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default DocumentInsight;
