import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Button } from 'react-bootstrap';
import { BLANK_DASHBOARD_TEMPLATE, DashboardTemplate, DASHBOARD_TEMPLATES } from './Models/DashboardTemplates';
import { useDashboardCreator } from './Hooks/useDashboardCreator';
import { dashboardAssistantPath } from 'constants/routeBuilders';
import {
    DashboardTemplateLibraryCard,
    DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH
} from 'components/LibraryCard/DashboardTemplateLibraryCard';
import { Link } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { LIBRARY_CARD_MARGIN } from 'components/LibraryCard';
import { computeCardsContainerMaxWidth } from 'components/LibraryCard/LibraryCardUtils';
import { AsyncButton } from 'components/AsyncButton';
import { toastQueryError } from 'components/Toast';

interface Props {
    isOpen: boolean;
    onCreate: (dashboardId: string) => void;
    onHide: () => void;
}

// Create a dashboard and redirects to it
const NewDashboardModal: FunctionComponent<Props> = ({ isOpen, onCreate, onHide }: Props) => {
    // State
    const [isBlankTemplateCreating, setIsBlankTemplateCreating] = useState<boolean>(false);

    // Refs
    const [parentContainerRef, { width: parentContainerWidth }] = useMeasure<HTMLDivElement>();

    // Mutations
    const { createDashboardFromTemplate } = useDashboardCreator();

    // Blank dashboard template
    const blankDashboardTemplate = useMemo(() => ({ ...BLANK_DASHBOARD_TEMPLATE }), []);

    // Keypup dashboard templates
    const dashboardTemplates = useMemo(() => DASHBOARD_TEMPLATES.filter(e => e.id.startsWith('dashboard')), []);

    // Invoked when create dashboard from template button is clicked
    const handleCreate = useCallback(async () => {
        try {
            // Set loading state
            setIsBlankTemplateCreating(true);

            // Create the dashboard
            const resp = (
                await createDashboardFromTemplate(blankDashboardTemplate as DashboardTemplate, {
                    pinned: true,
                    actionSource: 'manual'
                })
            )?.createDashboard;

            // Invoke post creation hook and redirect to newly created dashboard
            if (resp?.success && resp.dashboard?.id) {
                // Redirect to the newly created dashboard
                onCreate(resp.dashboard.id);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'add-dashboard-template' });
            }
        } catch {
            toastQueryError({ namespace: 'add-dashboard-template' });
        } finally {
            setIsBlankTemplateCreating(false);
        }
    }, [blankDashboardTemplate, createDashboardFromTemplate, onCreate]);

    // Render
    return (
        <Modal show={isOpen} onHide={onHide} size="lg" fullscreen="xl-down" dialogClassName="modal-dialog-scrollable">
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    <RichMessage id="dashboarding.new-dashboard-modal.title" />
                </Modal.Title>
            </Modal.Header>

            {/* Choose initial dashboard configuration */}
            <Modal.Body>
                <div ref={parentContainerRef}>
                    <Row
                        className="mx-auto"
                        xs="auto"
                        style={{
                            maxWidth: computeCardsContainerMaxWidth(
                                parentContainerWidth,
                                DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH + 2 * LIBRARY_CARD_MARGIN
                            )
                        }}
                    >
                        {/* Blank template */}
                        <Col className="p-0">
                            <DashboardTemplateLibraryCard
                                dashboard={blankDashboardTemplate}
                                headerConfig={{
                                    variant: 'rectangle-icon',
                                    icon: blankDashboardTemplate.icon,
                                    backgroundColor: blankDashboardTemplate.icon?.color
                                }}
                            >
                                <AsyncButton
                                    variant="dark"
                                    onClick={handleCreate}
                                    disabled={isBlankTemplateCreating}
                                    className="rounded-0 rounded-bottom justify-content-center w-100"
                                    loading={isBlankTemplateCreating}
                                    messageProps={{ id: 'dashboarding.new-dashboard-modal.create-blank-dashboard' }}
                                    loadingMessageProps={{
                                        id: 'dashboarding.new-dashboard-modal.creating-blank-dashboard'
                                    }}
                                />
                            </DashboardTemplateLibraryCard>
                        </Col>

                        {/* Dashboard templates */}
                        {dashboardTemplates.map(template => (
                            <Col key={template.id} className="p-0">
                                <DashboardTemplateLibraryCard dashboard={template}>
                                    <Button
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        as={Link as any}
                                        variant="dark"
                                        to={dashboardAssistantPath({ templateId: template.id })}
                                        className="rounded-0 rounded-bottom justify-content-center w-100"
                                    >
                                        <RichMessage id="components.library-card.create-dashboard-from-template" />
                                    </Button>
                                </DashboardTemplateLibraryCard>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NewDashboardModal;
