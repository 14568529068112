import { useQuery, useMutation } from '@apollo/react-hooks';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppResp } from 'api/hq/queries/App';
import { IdentityResp, GetIdentitiesResp, GET_USER_IDENTITIES, DELETE_IDENTITY } from 'api/hq/queries/Identity';
import { RichMessage } from 'components/RichMessage';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { ReduxState } from 'redux/reducers';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    onSuccess?: () => void;
    app: AppResp;
    identity: IdentityResp;
}

const DisconnectIdentityModal: FunctionComponent<Props> = ({ isOpen, toggle, onSuccess, identity, app }: Props) => {
    // State
    const [mutationError, setMutationError] = useState<string | null | undefined>(undefined);
    const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

    // Global state
    const apiUser = useSelector((e: ReduxState) => e.authUser.apiUser);

    // Fetch user identities to detect if the user is trying to delete its last identity
    const { data: userIdentities } = useQuery<GetIdentitiesResp>(GET_USER_IDENTITIES, {
        skip: !isOpen,
        variables: { userId: apiUser?.user.id },
        fetchPolicy: 'no-cache'
    });

    // Declare integration deletion mutation
    const [deleteIdentity] = useMutation(DELETE_IDENTITY);

    // Disconnect application
    const disconnectIdentity = async (): Promise<void> => {
        setMutationInProgress(true);

        const resp = await deleteIdentity({ variables: { id: identity.id } });
        const deleteResp = resp.data && resp.data.deleteIdentity;

        if (deleteResp && deleteResp.success) {
            onSuccess && onSuccess();
            toggle();
        } else {
            if (deleteResp) setMutationError(deleteResp.errors[0].code);
        }
        setMutationInProgress(false);
    };

    // Extract rendering data
    const isCurrentIdentity = identity.id === apiUser?.identity.id;
    const isLastIdentity = userIdentities && userIdentities.identities.nodes.length <= 1;

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <RichMessage id="settings.app-management.identity-item.disconnect-modal.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLastIdentity && (
                    <p>
                        <RichMessage
                            id="settings.app-management.identity-item.disconnect-modal.body"
                            values={{ appName: app.name }}
                        />
                    </p>
                )}

                {isLastIdentity && (
                    <Fragment>
                        <div className="text-center mb-3 text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="h1 mb-2" />
                        </div>
                        <p>
                            <RichMessage
                                id="settings.app-management.identity-item.disconnect-modal.warning-unenroll"
                                values={{ appName: app.name }}
                            />
                        </p>
                    </Fragment>
                )}

                {!isLastIdentity && isCurrentIdentity && (
                    <p>
                        <RichMessage
                            id="settings.app-management.identity-item.disconnect-modal.warning-disconnection"
                            values={{ appName: app.name }}
                        />
                    </p>
                )}

                {mutationError ? (
                    <div className="text-danger">
                        <RichMessage
                            id={`settings.app-management.identity-item.disconnect-modal.errors.${mutationError}`}
                        />
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <AsyncButton
                    track-id="click-app-disconnect"
                    variant="outline-danger"
                    onClick={disconnectIdentity}
                    disabled={mutationInProgress}
                    className="w-50 justify-content-center"
                    loading={mutationInProgress}
                    messageProps={{
                        id: isLastIdentity
                            ? 'settings.app-management.identity-item.disconnect-modal.unenroll'
                            : 'settings.app-management.identity-item.disconnect-modal.disconnect'
                    }}
                    loadingMessageProps={{ id: 'settings.app-management.identity-item.disconnect-modal.disconnecting' }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default DisconnectIdentityModal;
