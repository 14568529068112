import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { max, pick, range, sortBy, times } from 'lodash';
import { Layout, Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { useMeasure } from 'react-use';
import DashboardWidget from './DashboardWidget';
import { WidgetConfigType } from 'components/Dashboarding/Models/Widget';
import { useWidgetsConfigType } from 'api/hq/hooks/useWidgetsConfigType';
import { DrilldownContextProvider } from 'contexts/DrilldownContext';

// NOTE: that the breakpoints are based on the grid width, not the screen width
const GRID_BREAKPOINTS = { desktop: 700, mobile: 0 };
const GRID_COLS: { [key: string]: number } = { desktop: 16, mobile: 4 };
const GRID_MARGIN = 10;
const GRID_CELL_STROKE_COLOR = '#ddd';

// Return true if the two layout objects do not intersect
const areLayoutDistincts = (
    l1: Pick<Layout, 'x' | 'y' | 'h' | 'w'>,
    l2: Pick<Layout, 'x' | 'y' | 'h' | 'w'>
): boolean => {
    return l2.x >= l1.x + l1.w || l1.x >= l2.x + l2.w || l2.y >= l1.y + l1.h || l1.y >= l2.y + l2.h;
};

// Find the first available placement on the grid for the desired layout object,
// starting from the top left corner.
export function findFirstAvailableGridPlacement<T extends Pick<Layout, 'h' | 'w'>>(
    layout: Layout[],
    desired: T
): T & Pick<Layout, 'x' | 'y'> {
    const desktopCols = GRID_COLS['desktop'];
    const yRangeEnd = (max(layout.map(e => e.y + e.h)) || 0) + 1;
    const width = desired.w;

    // Try to find a valid placement inside the used grid
    for (const y of range(yRangeEnd)) {
        for (const x of range(desktopCols - width + 1)) {
            const testedPlacement = { ...desired, x, y };
            const isValid = layout.reduce((prev, curr) => {
                return prev && areLayoutDistincts(curr, testedPlacement);
            }, true);

            if (isValid) return testedPlacement;
        }
    }

    // If none was found, return a placement at the bottom of the grid
    return { ...desired, x: 0, y: yRangeEnd };
}

// Sort breakpoints by size
const sortBreakpoints = (breakpoints: Record<string, number>): string[] => {
    const keys: string[] = Object.keys(breakpoints);
    return keys.sort(function(a, b) {
        return breakpoints[a] - breakpoints[b];
    });
};

// Get the name of the breakpoint matching the provided width
const getBreakpointFromWidth = (breakpoints: Record<string, number>, width: number): string => {
    const sorted = sortBreakpoints(breakpoints);
    let matching = sorted[0];
    for (let i = 1, len = sorted.length; i < len; i++) {
        const breakpointName = sorted[i];
        if (width > breakpoints[breakpointName]) matching = breakpointName;
    }
    return matching;
};

interface GenerateGridBackgroundProps {
    gridCell: { height: number; width: number };
    cols: number;
    gridWidth: number;
}

// Generate the SVG image used as background when editing the grid
const generateGridBgImg = ({ gridCell, cols, gridWidth }: GenerateGridBackgroundProps): string => {
    const XMLNS = 'http://www.w3.org/2000/svg';
    const rowHeight = gridCell.height + GRID_MARGIN;

    // Adjust coordinates to account for borders
    const y = GRID_MARGIN + 1;
    const baseX = GRID_MARGIN + 1;
    const w = gridCell.width - 2;
    const h = gridCell.height - 2;

    // Generate columns with one rectangle in each column. The background image will be repeated
    // vertically.
    const rectangles = times(cols, i => {
        const x = baseX + i * (gridCell.width + GRID_MARGIN);
        return `<rect stroke='${GRID_CELL_STROKE_COLOR}' stroke-width='1' fill='none' x='${x}' y='${y}' width='${w}' height='${h}' rx='3' />`;
    });

    const svg = [`<svg xmlns='${XMLNS}' width='${gridWidth}' height='${rowHeight}'>`, ...rectangles, `</svg>`].join('');

    return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
};

interface Props {
    dashboardId: string;
    isEditing?: boolean;
    canEdit: boolean;
    layout: (Layout & { configType?: WidgetConfigType })[];
    onChange?: (e: Layout[]) => void;
}

const DashboardGrid: FunctionComponent<Props> = ({ isEditing, canEdit, dashboardId, layout, onChange }: Props) => {
    // Reference to the grid div wrapper to detect changes of size
    const [gridWrapperRef, { width: gridWidth }] = useMeasure<HTMLDivElement>();

    // Grid Configuration
    const breakpoint = useMemo(() => getBreakpointFromWidth(GRID_BREAKPOINTS, gridWidth), [gridWidth]);
    const cols = useMemo(() => GRID_COLS[breakpoint], [breakpoint]);

    // Load the config types of all contained widgets
    const { normalized: widgetsConfigType } = useWidgetsConfigType({ ids: layout?.map(e => e.i) });

    // Evaluate the width and height of grid cells
    const gridCell = useMemo(() => {
        const marginsCount = cols + 1;
        const marginSpace = marginsCount * GRID_MARGIN;
        const gridCellSpace = gridWidth - marginSpace;
        const cellSize = gridCellSpace / cols;
        return {
            width: cellSize,
            height: cellSize
        };
    }, [cols, gridWidth]);

    // Create a displayed layout where we filter out some widgets depending on the current breakpoint
    const displayedLayout = useMemo(() => {
        // On 'mobile' display, do not display horizontal and vertical separator widgets
        return layout.filter(
            e =>
                breakpoint != 'mobile' ||
                !widgetsConfigType[e.i] ||
                !['HSEPARATOR', 'VSEPARATOR'].includes(widgetsConfigType[e.i])
        );
    }, [layout, breakpoint, widgetsConfigType]);

    // In mobile layout all widgets will have the full width
    const mobileLayout = useMemo(() => {
        return layout.map(e => ({ ...e, w: GRID_COLS['mobile'] }));
    }, [layout]);

    // Detect grid height
    const height = useMemo(() => {
        const nativeLowestPoint = max(displayedLayout.map(l => l.y + l.h)) || 0;
        const editExtraSpace = isEditing ? Math.ceil(window.innerHeight / gridCell.height) : 0;
        return (gridCell.height + GRID_MARGIN) * (nativeLowestPoint + editExtraSpace);
    }, [gridCell.height, displayedLayout, isEditing]);

    // Generate grid background SVG
    const backgroundImg = useMemo(() => generateGridBgImg({ gridCell, cols, gridWidth }), [gridCell, gridWidth, cols]);

    // Generate grid style
    const style = useMemo(
        () => ({
            width: gridWidth,
            height,
            background: isEditing ? backgroundImg : ''
        }),
        [gridWidth, height, backgroundImg, isEditing]
    );

    // Callback used when widgets are reorganised on the grid
    const onLayoutChange = useCallback(
        (currentLayout: Layout[], allLayouts: Record<string, Layout[]>) => {
            if (!onChange || !isEditing) return;

            // Restrict attributes to those expected by the GraphQL API
            const updatedLayout = allLayouts.desktop.map(e => pick(e, 'i', 'x', 'y', 'w', 'h'));
            onChange(updatedLayout);
        },
        [isEditing, onChange]
    );

    // Callback used when widgets are removed from the grid
    // Note: unlike onLayoutChange, we allow widgets to be removed when the dashboard
    // is not in edit mode. Doing so will trigger the edit mode and immediately
    // remove the widget.
    const onRemoveWidget = useCallback(
        (widgetId?: string) => {
            if (!widgetId) return;

            onChange && onChange(layout.filter(e => e.i !== widgetId));
        },
        [onChange, layout]
    );

    // Memoize children: https://github.com/react-grid-layout/react-grid-layout#performance
    // Note: isEditing must be part of the dependencies for react-grid-layout to properly re-render
    // the grid on edit. See https://github.com/react-grid-layout/react-grid-layout/issues/1608
    const children = useMemo(
        () =>
            sortBy(displayedLayout, ['y', 'x']).map(e => (
                <div key={e.i}>
                    <DrilldownContextProvider>
                        <DashboardWidget
                            dashboardId={dashboardId}
                            widgetId={e.i}
                            isEditing={isEditing}
                            canRemove={canEdit}
                            onRemoveWidget={onRemoveWidget}
                        />
                    </DrilldownContextProvider>
                </div>
            )),
        [displayedLayout, dashboardId, isEditing, canEdit, onRemoveWidget]
    );

    return (
        <div ref={gridWrapperRef} className="py-4">
            <ResponsiveGridLayout
                layouts={{ desktop: displayedLayout, mobile: mobileLayout }}
                cols={GRID_COLS}
                breakpoints={GRID_BREAKPOINTS}
                rowHeight={gridCell.height}
                margin={[GRID_MARGIN, GRID_MARGIN]}
                isDraggable={isEditing}
                isResizable={isEditing}
                width={gridWidth}
                style={style}
                className="dashboard-layout"
                onLayoutChange={onLayoutChange}
            >
                {children}
            </ResponsiveGridLayout>
        </div>
    );
};

export default DashboardGrid;
