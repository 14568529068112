import { defaultLocale, localeOptions } from 'constants/defaultValues';
import { CHANGE_LOCALE, SettingsActionTypes } from 'constants/actionTypes';
import { CURRENT_LANGUAGE_KEY } from 'constants/localStorageKeys';

export interface SettingsState {
    locale: string;
}

export const settingsInitialState: SettingsState = {
    locale:
        (window.localStorage.getItem(CURRENT_LANGUAGE_KEY) &&
            localeOptions.filter((x): boolean => x.id === window.localStorage.getItem(CURRENT_LANGUAGE_KEY)).length >
                0 &&
            window.localStorage.getItem(CURRENT_LANGUAGE_KEY)) ||
        defaultLocale
};

export default (state = settingsInitialState, action: SettingsActionTypes): SettingsState => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return { ...state, locale: action.payload };

        default:
            return { ...state };
    }
};
