import { chunk } from 'lodash';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { ReactComponent as UsersSvg } from 'assets/img/subscriptions/users.svg';
import { ReactComponent as ContributorsSvg } from 'assets/img/subscriptions/contributors.svg';
import { ReactComponent as DashboardBuilderSvg } from 'assets/img/subscriptions/dashboard_builder.svg';
import { ReactComponent as DashboardTemplateSvg } from 'assets/img/subscriptions/dashboard_template.svg';
import { ReactComponent as GitIntegrationSvg } from 'assets/img/subscriptions/git_integration.svg';
import { ReactComponent as HistoricalDataSvg } from 'assets/img/subscriptions/historical_data.svg';
import { ReactComponent as MetricsBuilderSvg } from 'assets/img/subscriptions/metrics_builder.svg';
import { ReactComponent as MetricsWidgetTemplateSvg } from 'assets/img/subscriptions/metrics_widget_template.svg';
import { ReactComponent as ProjectIntegrationSvg } from 'assets/img/subscriptions/project_integration.svg';
import { RichMessage } from 'components/RichMessage';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { BillingSubscriptionWithTier } from 'api/hq/queries/BillingSubscription';

type BenefitStatusName = 'included' | 'unlimited' | 'upgrade';

interface KeyBenefit {
    id: string;
    icon: ReactNode | string;
    status: BenefitStatusName;
}

const generateBenefits = (billingSubscription: BillingSubscriptionWithTier): KeyBenefit[] => {
    const tier = billingSubscription.tier;
    const fillColor = tier.config.color;

    return [
        {
            id: 'users',
            icon: <UsersSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'unlimited'
        },
        {
            id: 'metrics_builder',
            icon: <MetricsBuilderSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        },
        {
            id: 'dashboard_templates',
            icon: <DashboardTemplateSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        },
        {
            id: 'contributors',
            icon: <ContributorsSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'unlimited'
        },
        {
            id: 'dashboard_builder',
            icon: <DashboardBuilderSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        },
        {
            id: 'git_integration',
            icon: <GitIntegrationSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        },
        {
            id: 'historical_data',
            icon: <HistoricalDataSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'unlimited'
        },
        {
            id: 'metric_widget',
            icon: <MetricsWidgetTemplateSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        },
        {
            id: 'project_integration',
            icon: <ProjectIntegrationSvg fill={fillColor} />,
            status: tier.name == 'NO_PLAN' ? 'upgrade' : 'included'
        }
    ];
};

interface Props {
    billingSubscription: BillingSubscriptionWithTier;
}

const KeyBenefits: FunctionComponent<Props> = ({ billingSubscription }) => {
    // Generate list of benefits for the current subscription
    const benefits = useMemo(() => generateBenefits(billingSubscription), [billingSubscription]);

    // Render
    return (
        <Table borderless className="text-dark border-0 p-4 m-0">
            <tbody>
                {chunk(benefits, 3).map((row, index) => (
                    <tr className="align-middle" key={index}>
                        {row.map((benefit: KeyBenefit) => {
                            const upgradeRequired = benefit.status == 'upgrade';

                            return (
                                <td key={benefit.id}>
                                    <div className="d-flex">
                                        <span className="h-100 me-2">{benefit.icon}</span>
                                        <div>
                                            <span className="fw-bold">
                                                <RichMessage
                                                    id={`settings.billing.subscription-presenter.benefits.${benefit.id}`}
                                                />
                                            </span>
                                            <br />
                                            <span
                                                className={classNames({
                                                    'fw-light': !upgradeRequired,
                                                    'text-warning': upgradeRequired
                                                })}
                                            >
                                                {upgradeRequired && (
                                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-1" />
                                                )}
                                                <RichMessage
                                                    id={`settings.billing.subscription-presenter.benefit-status.${benefit.status}`}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default KeyBenefits;
