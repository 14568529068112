import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';

const InboxFeedItemLoader: FunctionComponent = props => {
    return (
        <ContentLoader
            speed={2}
            width={600}
            height={90}
            viewBox="0 0 600 90"
            backgroundColor="#f5f5f5"
            foregroundColor="#d4d4d4"
            {...props}
        >
            <rect x="63" y="29" rx="3" ry="3" width="120" height="6" />
            <rect x="63" y="53" rx="3" ry="3" width="600" height="6" />
            <circle cx="40" cy="32" r="12" />
        </ContentLoader>
    );
};

export default InboxFeedItemLoader;
