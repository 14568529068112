import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import classNames from 'classnames';
import { ONBOARDING1_PATH, ONBOARDING1_STEPS } from 'constants/routeBuilders';
import { useNotifications } from 'components/Notifications/useNotifications';
import { useProjectImportProgress } from 'components/Notifications/useProjectImportProgress';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { DEFAULT_TOAST_DURATION } from 'constants/defaultValues';
import { useBreakpoint } from 'util/useBreakpoint';
import { RichMessage } from 'components/RichMessage';
import { NEUTRAL_GREY_7, SECONDARY_ANIS } from 'constants/colors';
import { Stepper } from 'components/Stepper';

const Header: FunctionComponent = () => {
    // State
    const [projectsCount, setProjectsCount] = useState<number | undefined>(undefined);
    const [importComplete, setImportComplete] = useState<boolean>(false);
    const { importProgress } = useProjectImportProgress(projectsCount);

    // Services
    const notification = useNotifications();
    const breakpoint = useBreakpoint();
    const importDisplayBreakpoint = useMemo(() => ['md', 'lg', 'xl', 'xxl'].includes(breakpoint), [breakpoint]);

    // The project import status template
    const projectImportStatus = useMemo(() => {
        if (!importProgress) return;
        return (
            <div
                className={classNames('d-flex align-items-center', {
                    'justify-content-center': !importDisplayBreakpoint,
                    'justify-content-end': importDisplayBreakpoint
                })}
            >
                {/* Circular progress bar */}
                <div className="me-3" style={{ width: 32, height: 32 }}>
                    <CircularProgressbar
                        value={importComplete ? 100 : importProgress}
                        strokeWidth={8}
                        styles={buildStyles({
                            pathColor: SECONDARY_ANIS,
                            trailColor: NEUTRAL_GREY_7
                        })}
                    />
                </div>

                {/* Importing projects... */}
                <div className="me-3 text-dark text-nowrap fw-bold">
                    <RichMessage id="flows.onboarding.header.project-import-status.importing" />
                </div>

                {/* Progress percentage */}
                <div className="text-grey-14 fw-500">{importComplete ? 100 : Math.round(importProgress)}%</div>
            </div>
        );
    }, [importComplete, importProgress, importDisplayBreakpoint]);

    // Handle notifications
    useEffect(() => {
        // Abort if no notification
        if (!notification) return;

        if (notification.type == 'projects-importing' && notification.context) {
            setProjectsCount(notification.context.projectsImportCount);
        } else if (notification.type == 'projects-imported') {
            setImportComplete(true);

            // When projects are done importing, show 100% status for a short duration
            setTimeout(() => {
                setImportComplete(false);
                setProjectsCount(undefined);
            }, DEFAULT_TOAST_DURATION);
        }
    }, [notification]);

    return (
        <>
            <div className="splitscreen-flow-header d-flex p-4">
                {/* Logo */}
                <div className="flex-grow-1 flex-basis-0">
                    <KeypupLogoFull />
                </div>

                {/* Stepper */}
                <Stepper basePath={ONBOARDING1_PATH} steps={ONBOARDING1_STEPS} messageId="flows.onboarding.header" />

                {/* Project import progress status */}
                <div className="flex-grow-1 flex-basis-0">{importDisplayBreakpoint && projectImportStatus}</div>
            </div>

            {/* On smaller screens, display the project import status below the header */}
            {!importDisplayBreakpoint && projectImportStatus && <div className="w-100 my-4">{projectImportStatus}</div>}
        </>
    );
};

export default Header;
