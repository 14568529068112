import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import {
    addWidgetDimension,
    addWidgetMetric,
    ChartSeriesType,
    KpiSeriesType,
    sortedSeriesColumns,
    WidgetConfigType,
    WidgetFieldCategory,
    WidgetFieldSortDirection,
    WidgetTypedDimensionFieldWrapper,
    WidgetTypedMetricFieldWrapper,
    WIDGET_DISPLAY_CONFIGS
} from 'components/Dashboarding/Models/Widget';
import { useFetchSeries } from 'components/Dashboarding/Hooks/useFetchSeries';
import FieldFormatter from '../../../DashboardWidget/FieldFormatter';
import { Column, useBlockLayout, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';
import { max, min } from 'lodash';
import PreviewTableTh from './PreviewTableTh';
import scrollbarWidth from 'util/scrollbarWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';
import classNames from 'classnames';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useMeasure } from 'react-use';
import Dropdown from 'react-bootstrap/Dropdown';
import { useIntl } from 'react-intl';
import { DrilldownContext } from 'contexts/DrilldownContext';
import { DIMENSION_IDENTIFIER, METRIC_IDENTIFIER } from 'constants/defaultValues';

// Table configuration
const TABLE_ROW_HEIGHT = 35; //px
const TABLE_COL_WIDTH = 400; //px
const TABLE_ADDON_WIDTH = 30; //px
const TABLE_TH_HEIGHT = 110; //px
const TABLE_CELL_PADDING_X = 5; //px

type TableColumn = Column<Record<string, string>> & { className?: string };

interface Props<TSeries> {
    fact: QueryOpFact;
    configType: WidgetConfigType;
    highlightFirst?: number;
    series: TSeries;
    onChange: (series: TSeries) => void;
    drilldownEnabled?: boolean;
}

// Allows the user to configure the dimensions and metrics to be used by
// the widget and visualize the data in a table.
function PreviewTable<TSeries extends ChartSeriesType | KpiSeriesType>({
    fact,
    configType,
    highlightFirst,
    series,
    onChange,
    drilldownEnabled
}: Props<TSeries>): ReactElement<Props<TSeries>> {
    // Services
    const intl = useIntl();

    // Extract parameters
    const metrics = series.metrics;
    const dimensions = series.dimensions;
    const seriesConfig = WIDGET_DISPLAY_CONFIGS[configType];

    // Available column actions
    const canAddMetric = metrics.length < seriesConfig.rangeMetrics[1];
    const canAddDimension = dimensions.length < seriesConfig.rangeDimensions[1];
    const canAddColumn = canAddMetric || canAddDimension;

    // Table formatting configuration
    const absoluteRanking = seriesConfig.absoluteRanking;
    const showSectionSeparator = !absoluteRanking && metrics.length > 0 && dimensions.length > 0;

    // Reference to the div wrapper to detect the available width the table can use
    const [tableWrapperRef, { height: tableWrapperHeight, width: tableWrapperWidth }] = useMeasure<HTMLDivElement>();

    // Get all widget fields as a union array
    const rankedFields = useMemo(() => sortedSeriesColumns(configType, series), [configType, series]);

    // Hook invoked when a dimension is added
    // To make it easier, we simply duplicate the last dimension without formatting/sorting.
    // This is easier than attempting to "smart select" a dimension.
    const onDimensionAdd = useCallback(() => {
        onChange(addWidgetDimension(series));
    }, [onChange, series]);

    // Hook invoked when one of a dimension gets updated
    const onDimensionChange = useCallback(
        (atIndex: number, typedField?: WidgetTypedDimensionFieldWrapper) => {
            const updatedFields = [...dimensions];

            if (typedField && atIndex >= 0) {
                updatedFields[atIndex] = typedField.field;
            } else if (typedField) {
                updatedFields.push(typedField.field);
            } else {
                updatedFields.splice(atIndex, 1);
            }

            // Update series
            onChange({ ...series, dimensions: updatedFields });
        },
        [dimensions, onChange, series]
    );

    // Hook invoked when a metric is added
    // To make it easier, we simply duplicate the last metric without formatting/sorting.
    // This is easier than attempting to "smart select" a metric.
    const onMetricAdd = useCallback(() => {
        onChange(addWidgetMetric(series));
    }, [onChange, series]);

    // Hook invoked when one of a metric gets updated
    const onMetricChange = useCallback(
        (atIndex: number, typedField?: WidgetTypedMetricFieldWrapper) => {
            const updatedFields = [...metrics];

            if (typedField && atIndex >= 0) {
                updatedFields[atIndex] = typedField.field;
            } else if (typedField) {
                updatedFields.push(typedField.field);
            } else {
                updatedFields.splice(atIndex, 1);
            }

            // Update widget
            onChange({ ...series, metrics: updatedFields });
        },
        [metrics, onChange, series]
    );

    // Reorder a field in absolute and local fashion
    const onFieldReorder = useCallback(
        (absoluteFromIndex: number, direction: 'left' | 'right') => {
            // Get absolute destination index.
            // Restrict the destination index to be within range of the array.
            const absoluteToIndex = min([
                max([absoluteFromIndex + (direction == 'right' ? 1 : -1), 0]),
                rankedFields.length - 1
            ]) as number;

            // Abort if there is no change
            if (absoluteToIndex == absoluteFromIndex) return;

            // Move elements in the absolute ranking array
            const updatedRankedCols = [...rankedFields];
            const fromItem = rankedFields[absoluteFromIndex];
            const toItem = rankedFields[absoluteToIndex];
            updatedRankedCols[absoluteFromIndex] = { ...toItem, absoluteIndex: absoluteFromIndex };
            updatedRankedCols[absoluteToIndex] = { ...fromItem, absoluteIndex: absoluteToIndex };

            // Rebuild metric and dimension arrays using new ranks
            const updatedMetrics = updatedRankedCols
                .filter(e => e.type == 'metric')
                .map(e => {
                    return { ...e.field, formatting: { ...e.field.formatting, rank: e.absoluteIndex } };
                });
            const updatedDimensions = updatedRankedCols
                .filter(e => e.type == 'dimension')
                .map(e => {
                    return { ...e.field, formatting: { ...e.field.formatting, rank: e.absoluteIndex } };
                });

            // Update series
            onChange({ ...series, metrics: updatedMetrics, dimensions: updatedDimensions });
        },
        [onChange, rankedFields, series]
    );

    // Change the field on which sorting is applied
    const onSort = useCallback(
        (fieldType: WidgetFieldCategory, atIndex: number, direction: WidgetFieldSortDirection): void => {
            const updatedMetrics = metrics.map((e, i) => {
                return { ...e, sort: fieldType == 'metric' && i == atIndex ? direction : undefined };
            });
            const updatedDimensions = dimensions.map((e, i) => {
                return { ...e, sort: fieldType == 'dimension' && i == atIndex ? direction : undefined };
            });

            // Update widget
            onChange({ ...series, metrics: updatedMetrics, dimensions: updatedDimensions });
        },
        [dimensions, metrics, onChange, series]
    );

    // Retrieve `coordinates` from drilldown context
    const { coordinates } = useContext(DrilldownContext);

    // Fetch data
    // The limit attribute gets ignored so as to give users a better overview
    // of the underlying data
    const { records } = useFetchSeries({
        fact,
        series: { ...series, limit: undefined },
        pickedCoordinates: drilldownEnabled ? coordinates : undefined
    });

    // Generate table column definitions
    const tableColumns = useMemo(() => {
        return rankedFields.map(fieldWrapper => {
            return {
                accessor: fieldWrapper.accessor,
                Header: (
                    <PreviewTableTh
                        fact={fact}
                        configType={configType}
                        series={series}
                        typedField={fieldWrapper}
                        onChange={e =>
                            fieldWrapper.type == 'metric'
                                ? onMetricChange(fieldWrapper.localIndex, e as WidgetTypedMetricFieldWrapper)
                                : onDimensionChange(fieldWrapper.localIndex, e as WidgetTypedDimensionFieldWrapper)
                        }
                        onReorder={e => onFieldReorder(fieldWrapper.absoluteIndex, e)}
                        onSort={e => onSort(fieldWrapper.type, fieldWrapper.localIndex, e)}
                    />
                ),
                Cell: ({ value }: { value: string }) => (
                    <FieldFormatter value={value} fact={fact} field={fieldWrapper.field} disableTooltip />
                ),
                width: TABLE_COL_WIDTH,
                className:
                    fieldWrapper.type == 'dimension' &&
                    showSectionSeparator &&
                    fieldWrapper.localIndex == dimensions.length - 1
                        ? 'section-end'
                        : undefined
            };
        });
    }, [
        configType,
        dimensions.length,
        fact,
        onDimensionChange,
        onFieldReorder,
        onMetricChange,
        onSort,
        rankedFields,
        series,
        showSectionSeparator
    ]);

    // No record placeholder persisted to differentiate it in the cell styling
    const noRecordPlaceholder = useMemo(
        () => intl.formatMessage({ id: 'dashboarding.widget-editor.configure-table.no-records-placeholder' }),
        [intl]
    );

    // Generate a virtual row with empty values for every possible dimensions and metrics
    // as a default row if there is no records
    const emptyRecords = useMemo(() => {
        const record = {} as Record<string, string>;
        dimensions.forEach((_, i) => (record[`${DIMENSION_IDENTIFIER}${i}`] = noRecordPlaceholder));
        metrics.forEach((_, i) => (record[`${METRIC_IDENTIFIER}${i}`] = noRecordPlaceholder));
        return [record];
    }, [dimensions, metrics, noRecordPlaceholder]);

    // Get table helpers
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth } = useTable(
        {
            columns: tableColumns,
            data: records.length ? records : emptyRecords
        },
        useBlockLayout
    );

    // Get the width of the scrollbar for the current OS/browser so as to adjust the
    // width of the scrollable container and avoid having the squeezing the content of the
    // table to the left and dealigning the table.
    const scrollBarSize = useMemo(() => scrollbarWidth(), []);
    // Calculate the height of the table
    const tableContentHeight = useMemo(() => rows.length * TABLE_ROW_HEIGHT, [rows.length]);
    const tableMaxHeight = useMemo(() => {
        return min([
            tableWrapperHeight - scrollBarSize - TABLE_TH_HEIGHT,
            rows.length * (TABLE_ROW_HEIGHT + 1)
        ]) as number;
    }, [rows.length, scrollBarSize, tableWrapperHeight]);
    const isVerticalScrollbarEnabled = tableContentHeight > tableMaxHeight;

    // Calculate the width of the table
    const tableMaxWidth = useMemo(() => {
        return min([tableWrapperWidth, tableColumns.length * TABLE_COL_WIDTH]) as number;
    }, [tableColumns.length, tableWrapperWidth]);

    // Adjust the row width based to accomodate the scrollbar
    const rowWidth = totalColumnsWidth - (isVerticalScrollbarEnabled ? TABLE_CELL_PADDING_X : 0);

    // Pure component used to limit the number of re-renders
    const renderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            return (
                <div {...row.getRowProps({ style })} className="tr">
                    {row.cells.map((cell, cellIndex) => {
                        const column = (cell.column as unknown) as TableColumn;

                        // Reduce cell width to accomodate scrollbar size
                        const isLast = cellIndex == row.cells.length - 1;
                        const origCellWidth = (cell.column.width || 0) as number;
                        const hasScrollbar = isVerticalScrollbarEnabled && isLast;
                        const cellWidth = hasScrollbar
                            ? origCellWidth - scrollBarSize - TABLE_CELL_PADDING_X
                            : origCellWidth;

                        // Should the cell be highlighted?
                        const isHighlighted = highlightFirst && index < highlightFirst;

                        // Format cell props
                        const { key, style, ...cellProps } = cell.getCellProps({
                            className: classNames('td', column.className, { 'with-scrollbar': hasScrollbar })
                        });

                        return (
                            <div key={key} {...cellProps} style={{ ...style, width: `${cellWidth}px` }}>
                                <div
                                    className={classNames('td-inner', {
                                        'bg-primary': isHighlighted,
                                        'bg-opacity-25': isHighlighted,
                                        'text-grey-4 bg-white': records.length === 0
                                    })}
                                >
                                    <span>{cell.render('Cell')}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        },
        [highlightFirst, isVerticalScrollbarEnabled, prepareRow, records.length, rows, scrollBarSize]
    );

    // Render the UI for your table
    return (
        <div className="d-flex vh-40" ref={tableWrapperRef}>
            <div
                {...getTableProps({ style: { width: tableMaxWidth } })}
                className="widget-edit-panel-virtualized-table"
            >
                <div>
                    {headerGroups.map(headerGroup => {
                        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <div key={key} {...restHeaderGroupProps} className="tr">
                                {headerGroup.headers.map(column => {
                                    const colProps = (column as unknown) as TableColumn;
                                    const { key, ...restHeaderProps } = column.getHeaderProps({
                                        className: classNames('th', colProps.className)
                                    });

                                    return (
                                        <div key={key} {...restHeaderProps}>
                                            {column.render('Header')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>

                <div {...getTableBodyProps()} className="tbody">
                    <FixedSizeList
                        height={tableMaxHeight}
                        itemCount={rows.length}
                        itemSize={TABLE_ROW_HEIGHT}
                        width={rowWidth}
                    >
                        {renderRow}
                    </FixedSizeList>
                </div>
            </div>

            {/* Table addon used to add column */}
            <div
                className="widget-edit-panel-virtualized-table column-addon"
                style={{ width: TABLE_ADDON_WIDTH, height: tableMaxHeight + TABLE_TH_HEIGHT, paddingTop: 90 }}
            >
                <div
                    className={classNames('vertical-line', {
                        'border-light': !canAddColumn,
                        'border-primary': canAddColumn
                    })}
                />
                <Dropdown>
                    <Dropdown.Toggle as="div" role="button" bsPrefix="none">
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className={classNames({
                                'text-light': !canAddColumn,
                                'text-primary': canAddColumn
                            })}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        <Dropdown.Item disabled={!canAddMetric} onClick={onMetricAdd}>
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.add-metric" />
                        </Dropdown.Item>
                        <Dropdown.Item disabled={!canAddDimension} onClick={onDimensionAdd}>
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.add-dimension" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div
                    className={classNames('vertical-line', {
                        'border-light': !canAddColumn,
                        'border-primary': canAddColumn
                    })}
                />
            </div>
        </div>
    );
}

export default PreviewTable;
