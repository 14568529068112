import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CustomFieldType, ObjectFieldSchema, PrimitiveFieldSchema } from 'api/viz/queries/CustomField';
import { RuleValueType } from 'components/RulesetBuilder';

export interface NameLabelPair {
    name: string;
    label: string;
}

export type QueryOpEnum = NameLabelPair;
export type QueryOpCombinator = NameLabelPair;

export type QueryOpFieldType = 'string' | 'int' | 'float' | 'ts' | 'bool' | 'label';
export type QueryOpUsageScope = 'metric' | 'dimension' | 'filter' | 'sort';
export type QueryOpFilterScope = 'query' | 'custom-rule' | 'board';
export type QueryOpFilterDisplayType =
    | 'no-arg'
    | 'text'
    | 'text-list'
    | 'number'
    | 'checkbox'
    | 'radio'
    | 'datepicker'
    | 'select'
    | 'select-company-user'
    | 'daterangepicker'
    | 'timeperiod'
    | 'timeperiod-with-offset';

export interface QueryOpFunction extends NameLabelPair {
    type?: RuleValueType;
    displayType?: QueryOpFilterDisplayType;
    explainer?: string;
    explainerFn?: string;
    values?: QueryOpEnum[] | QueryOpEnum[][];
    section?: string;
}

export interface QueryOpFunctionConfig {
    id: string;
    argHints: string[];
    hideInFormula?: boolean;
}

// Generic formula used for aggregators and dimensions
export const QUERY_OP_FORMULA_FUNCTION = 'FORMULA';

// =============================================
// Formula Operator functions
// ---
// These operators are available in both 'metric'
// and 'dimension' mode.
// =============================================
export const QUERY_OP_FORMULA_OPERATORS = [
    'ARRAY_FIND',
    'BEGINNING_OF_HOUR',
    'BEGINNING_OF_DAY',
    'BEGINNING_OF_MONTH',
    'BEGINNING_OF_QUARTER',
    'BEGINNING_OF_WEEK',
    'BEGINNING_OF_YEAR',
    'BETWEEN',
    'CONCAT',
    'CONTAINS',
    'DATE',
    'DAY',
    'DAY_OF_WEEK',
    'DAY_OF_YEAR',
    'END_OF_HOUR',
    'END_OF_DAY',
    'END_OF_MONTH',
    'END_OF_QUARTER',
    'END_OF_WEEK',
    'END_OF_YEAR',
    'EQUAL',
    'GREATER_THAN',
    'GREATER_THAN_EQUAL',
    'GREATEST',
    'HOUR',
    'IF',
    'IF_NULL',
    'IF_ZERO',
    'IN',
    'IS_NULL',
    'IS_NOT_NULL',
    'LEAST',
    'LESS_THAN',
    'LESS_THAN_EQUAL',
    'LENGTH',
    'MATCH',
    'MOD',
    'MONTH',
    'NOT',
    'NOT_CONTAINS',
    'NOT_EQUAL',
    'NOT_MATCH',
    'NOW',
    'OR',
    'POWER',
    'ROUND',
    'ROUNDDOWN',
    'ROUNDUP',
    'WEEK',
    'YEAR',
    'YEAR_MONTH',
    'YEAR_MONTH_DAY',
    'YEAR_WEEK'
] as const;
export type QueryOpFormulaOperator = typeof QUERY_OP_FORMULA_OPERATORS[number];

// Function definititions
export interface QueryOpFormulaOperatorConfig extends QueryOpFunctionConfig {
    id: QueryOpFormulaOperator;
}
export const QUERY_OP_FORMULA_OPERATOR_CONFIGS: QueryOpFormulaOperatorConfig[] = [
    {
        id: 'ARRAY_FIND',
        argHints: ['list', 'value1', '...', 'valueN']
    },
    {
        id: 'BEGINNING_OF_HOUR',
        argHints: ['datetime']
    },
    {
        id: 'BEGINNING_OF_DAY',
        argHints: ['datetime']
    },
    {
        id: 'BEGINNING_OF_MONTH',
        argHints: ['datetime']
    },
    {
        id: 'BEGINNING_OF_QUARTER',
        argHints: ['datetime']
    },
    {
        id: 'BEGINNING_OF_WEEK',
        argHints: ['datetime']
    },
    {
        id: 'BEGINNING_OF_YEAR',
        argHints: ['datetime']
    },
    {
        id: 'BETWEEN',
        argHints: ['number|datetime', 'start', 'end']
    },
    {
        id: 'CONCAT',
        argHints: ['value1', '...', 'valueN']
    },
    {
        id: 'CONTAINS',
        argHints: ['list_field', 'term|term_list']
    },
    {
        id: 'DATE',
        argHints: ['year', 'month', 'day']
    },
    {
        id: 'DAY',
        argHints: ['[datetime]']
    },
    {
        id: 'DAY_OF_WEEK',
        argHints: ['datetime']
    },
    {
        id: 'DAY_OF_YEAR',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_HOUR',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_DAY',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_MONTH',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_QUARTER',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_WEEK',
        argHints: ['datetime']
    },
    {
        id: 'END_OF_YEAR',
        argHints: ['datetime']
    },
    {
        id: 'GREATEST',
        argHints: ['value1', '...', 'valueN']
    },
    {
        id: 'HOUR',
        argHints: ['[datetime]']
    },
    {
        id: 'IF',
        argHints: ['condition', 'value_if_true', '[value_if_false]']
    },
    {
        id: 'IF_NULL',
        argHints: ['value', 'fallback_if_null']
    },
    {
        id: 'IF_ZERO',
        argHints: ['value', 'fallback_if_zero']
    },
    {
        id: 'IN',
        argHints: ['value', 'list']
    },
    {
        id: 'IS_NULL',
        argHints: ['value']
    },
    {
        id: 'IS_NOT_NULL',
        argHints: ['value']
    },
    {
        id: 'LEAST',
        argHints: ['value1', '...', 'valueN']
    },
    {
        id: 'LENGTH',
        argHints: ['list']
    },
    {
        id: 'MATCH',
        argHints: ['string', 'regex']
    },
    {
        id: 'MOD',
        argHints: ['dividend', 'divisor']
    },
    {
        id: 'MONTH',
        argHints: ['[datetime]']
    },
    {
        id: 'NOT',
        argHints: ['condition']
    },
    {
        id: 'NOT_CONTAINS',
        argHints: ['list_field', 'term|term_list']
    },
    {
        id: 'NOT_MATCH',
        argHints: ['string', 'regex']
    },
    {
        id: 'NOW',
        argHints: []
    },
    {
        id: 'POWER',
        argHints: ['base', 'exponent']
    },
    {
        id: 'ROUND',
        argHints: ['number', '[precision=0]']
    },
    {
        id: 'ROUNDDOWN',
        argHints: ['number', '[precision=0]']
    },
    {
        id: 'ROUNDUP',
        argHints: ['number', '[precision=0]']
    },
    {
        id: 'WEEK',
        argHints: ['[datetime]']
    },
    {
        id: 'YEAR',
        argHints: ['[datetime]']
    },
    {
        id: 'YEAR_MONTH',
        argHints: ['datetime']
    },
    {
        id: 'YEAR_MONTH_DAY',
        argHints: ['datetime']
    },
    {
        id: 'YEAR_WEEK',
        argHints: ['datetime']
    }
];

// =============================================
// Metric Aggregator functions
// =============================================
export const QUERY_OP_AGGREGATORS = [
    'AVG',
    'COUNT',
    'COUNT_DISTINCT',
    'COUNT_IF',
    'MAX',
    'MEDIAN',
    'MIN',
    'STDDEV',
    'SUM',
    'SUM_IF',
    'VARIANCE',
    QUERY_OP_FORMULA_FUNCTION
] as const;
export type QueryOpAggregator = typeof QUERY_OP_AGGREGATORS[number];

// Function definititions
export interface QueryOpAggregatorConfig extends QueryOpFunctionConfig {
    id: QueryOpAggregator;
}
export const QUERY_OP_AGGREGATOR_CONFIGS: QueryOpAggregatorConfig[] = [
    {
        id: 'AVG',
        argHints: ['number']
    },
    {
        id: 'COUNT',
        argHints: []
    },
    {
        id: 'COUNT_DISTINCT',
        argHints: ['field|expression']
    },
    {
        id: 'COUNT_IF',
        argHints: ['condition']
    },
    {
        id: 'FORMULA',
        argHints: [],
        hideInFormula: true
    },
    {
        id: 'MAX',
        argHints: ['number']
    },
    {
        id: 'MEDIAN',
        argHints: ['number']
    },
    {
        id: 'MIN',
        argHints: ['number']
    },
    {
        id: 'STDDEV',
        argHints: ['number']
    },
    {
        id: 'SUM',
        argHints: ['number']
    },
    {
        id: 'SUM_IF',
        argHints: ['condition', 'number']
    },
    {
        id: 'VARIANCE',
        argHints: ['number']
    }
];

// =============================================
// Dimension Formatters
// =============================================
// This is the default formatter used when no specific formatter is used
// Note that some dimensions (e.g. date dimension) do not allow the native formatter
// to be used.
export const QUERY_OP_NATIVE_FORMATTER = 'NATIVE';
export const QUERY_OP_DIMENSION_FORMATTERS = [
    QUERY_OP_FORMULA_FUNCTION,
    QUERY_OP_NATIVE_FORMATTER,
    'YEAR',
    'YEAR_MONTH',
    'YEAR_MONTH_DAY',
    'YEAR_WEEK'
] as const;
export type QueryOpDimensionFormatter = typeof QUERY_OP_DIMENSION_FORMATTERS[number];

// Dimension formatter groups
export const QUERY_OP_TS_DIMENSION_FORMATTERS: QueryOpDimensionFormatter[] = [
    'YEAR',
    'YEAR_MONTH',
    'YEAR_MONTH_DAY',
    'YEAR_WEEK'
];

// =============================================
// Display Formatters
// =============================================

// List all display formatter functions
export const QUERY_OP_DISPLAY_FORMATTERS = [
    QUERY_OP_NATIVE_FORMATTER,
    'TS_L',
    'TS_DD/MM/YYYY',
    'TS_LL',
    'TS_ll',
    'TS_LLL',
    'TS_lll',
    'TS_LLLL',
    'TS_llll',
    'TS_MM/YYYY',
    'TS_MMM_YYYY',
    'TS_WW',
    'TS_WW_YYYY',
    'TS_YYYY-WW',
    'TS_YYYY-MM',
    'TS_YYYY',
    'LABEL_BADGES',
    'FULL_LINK',
    'SHORT_LINK'
] as const;
export type QueryOpDisplayFormatter = typeof QUERY_OP_DISPLAY_FORMATTERS[number];

// Display formatter groups
export const QUERY_OP_TS_DISPLAY_FORMATTERS: QueryOpDisplayFormatter[] = [
    'NATIVE',
    'TS_L',
    'TS_DD/MM/YYYY',
    'TS_LL',
    'TS_ll',
    'TS_LLL',
    'TS_lll',
    'TS_LLLL',
    'TS_llll',
    'TS_MM/YYYY',
    'TS_MMM_YYYY',
    'TS_WW',
    'TS_WW_YYYY',
    'TS_YYYY-WW',
    'TS_YYYY-MM',
    'TS_YYYY'
];

export const QUERY_OP_URL_DISPLAY_FORMATTERS: QueryOpDisplayFormatter[] = ['NATIVE', 'FULL_LINK', 'SHORT_LINK'];

// =============================================
// Filters
// =============================================
// List all filter functions
export const QUERY_OP_FILTERS = [
    'AFTER_DATE',
    'AFTER_N_TIMEPERIOD_WITH_OFFSET',
    'ASSIGNEES_INCLUDE_ME',
    'ASSIGNEES_INCLUDE_USER',
    'AUTHOR_IS_ME',
    'AUTHOR_IS_USER',
    'BEFORE_DATE',
    'BEFORE_N_TIMEPERIOD_WITH_OFFSET',
    'CURRENT_TIMEPERIOD',
    'EQUAL',
    'GREATER_THAN',
    'GREATER_THAN_EQUAL',
    'LENGTH_GREATER_THAN',
    'LENGTH_GREATER_THAN_EQUAL',
    'LENGTH_EQUAL',
    'LENGTH_LESS_THAN',
    'LENGTH_LESS_THAN_EQUAL',
    'LENGTH_NOT_EQUAL',
    'INCLUDE',
    'INCLUDE_ANY_OF',
    'IS_DATE',
    'IS_EMPTY',
    'IS_NULL',
    'IS_NOT_DATE',
    'IS_NOT_EMPTY',
    'IS_NOT_NULL',
    'LAST_N_TIMEPERIOD',
    'LESS_THAN',
    'LESS_THAN_EQUAL',
    'MATCH',
    'MERGED_BY_IS_ME',
    'MERGED_BY_IS_USER',
    'NEXT_N_TIMEPERIOD',
    'NOT_EQUAL',
    'NOT_INCLUDE',
    'NOT_INCLUDE_ANY_OF',
    'NOT_MATCH',
    'REVIEWERS_INCLUDE_ME',
    'REVIEWERS_INCLUDE_USER',
    'WITHIN_DATES'
] as const;
export type QueryOpFilter = typeof QUERY_OP_FILTERS[number];

export interface QueryOpFilterFunction extends QueryOpFunction {
    name: QueryOpFilter;
}

// Default enum selection to boolean if no value are specified on the field
export const QUERY_OP_DEFAULT_ENUM_VALUES: QueryOpEnum[] = [
    { name: 'true', label: 'true' },
    { name: 'false', label: 'false' }
];

// List allowed filter combinators
export const QUERY_OP_FILTER_COMBINATORS: QueryOpCombinator[] = [
    { name: 'AND', label: 'AND' },
    { name: 'OR', label: 'OR' }
];

// List allowed time period values
export const QUERY_OP_FILTER_TIMEPERIOD: QueryOpEnum[] = [
    { name: 'HOUR', label: 'Hour' },
    { name: 'DAY', label: 'Day' },
    { name: 'WEEK', label: 'Week' },
    { name: 'MONTH', label: 'Month' },
    { name: 'QUARTER', label: 'Quarter' },
    { name: 'YEAR', label: 'Year' }
];
const QUERY_OP_FILTER_TIMEPERIOD_NAMES = ['HOUR', 'DAY', 'WEEK', 'QUARTER', 'MONTH', 'YEAR'] as const;
export type TimePeriodFilter = typeof QUERY_OP_FILTER_TIMEPERIOD_NAMES[number];

// List allowed time offset values
export const QUERY_OP_FILTER_TIMEOFFSET: QueryOpEnum[] = [
    { name: 'AGO', label: 'Ago' },
    { name: 'FROM_NOW', label: 'From now' }
];
const QUERY_OP_FILTER_OFFSET_NAMES = ['AGO', 'FROM_NOW'] as const;
export type OffsetFilter = typeof QUERY_OP_FILTER_OFFSET_NAMES[number];

// If you update the operators, ensure you also update the `components.ruleset-builder.help.operator.*`
// translation keys in en_US.
export const QUERY_OP_FILTER_FUNCTIONS: QueryOpFilterFunction[] = [
    {
        name: 'ASSIGNEES_INCLUDE_ME',
        label: 'include me',
        displayType: 'no-arg'
    },
    {
        name: 'ASSIGNEES_INCLUDE_USER',
        label: 'include user',
        displayType: 'select-company-user'
    },
    {
        name: 'AUTHOR_IS_ME',
        label: 'is me',
        displayType: 'no-arg'
    },
    {
        name: 'AUTHOR_IS_USER',
        label: 'is user',
        displayType: 'select-company-user'
    },
    {
        name: 'EQUAL',
        label: '='
    },
    {
        name: 'GREATER_THAN',
        label: '>',
        type: 'int'
    },
    {
        name: 'GREATER_THAN_EQUAL',
        label: '>=',
        type: 'int'
    },
    {
        name: 'IS_NULL',
        label: 'is null',
        displayType: 'no-arg'
    },
    {
        name: 'IS_NOT_NULL',
        label: 'is not null',
        displayType: 'no-arg'
    },
    {
        name: 'LENGTH_GREATER_THAN',
        label: 'length >',
        type: 'int',
        displayType: 'number',
        explainer: '>',
        explainerFn: 'length'
    },
    {
        name: 'LENGTH_GREATER_THAN_EQUAL',
        label: 'length >=',
        type: 'int',
        displayType: 'number',
        explainer: '>=',
        explainerFn: 'length'
    },
    {
        name: 'LENGTH_EQUAL',
        label: 'length =',
        type: 'int',
        displayType: 'number',
        explainer: '=',
        explainerFn: 'length'
    },
    {
        name: 'LENGTH_LESS_THAN',
        label: 'length <',
        type: 'int',
        displayType: 'number',
        explainer: '<',
        explainerFn: 'length'
    },
    {
        name: 'LENGTH_LESS_THAN_EQUAL',
        label: 'length <=',
        type: 'int',
        displayType: 'number',
        explainer: '<=',
        explainerFn: 'length'
    },
    {
        name: 'LENGTH_NOT_EQUAL',
        label: 'length !=',
        type: 'int',
        displayType: 'number',
        explainer: '!=',
        explainerFn: 'length'
    },
    {
        name: 'INCLUDE',
        label: 'contains'
    },
    {
        name: 'INCLUDE_ANY_OF',
        label: 'contains any of',
        type: 'stringL',
        displayType: 'text-list'
    },
    {
        name: 'LESS_THAN',
        label: '<',
        type: 'int'
    },
    {
        name: 'LESS_THAN_EQUAL',
        label: '<=',
        type: 'int'
    },
    {
        name: 'MATCH',
        label: '~ (regex)',
        explainer: '~'
    },
    {
        name: 'MERGED_BY_IS_ME',
        label: 'is me',
        displayType: 'no-arg'
    },
    {
        name: 'MERGED_BY_IS_USER',
        label: 'is user',
        displayType: 'select-company-user'
    },
    {
        name: 'NOT_EQUAL',
        label: '!='
    },
    {
        name: 'NOT_INCLUDE',
        label: 'does not contain'
    },
    {
        name: 'NOT_INCLUDE_ANY_OF',
        label: 'does not contain any of',
        type: 'stringL',
        displayType: 'text-list'
    },
    {
        name: 'NOT_MATCH',
        label: '!~ (regex)',
        explainer: '!~'
    },
    {
        name: 'REVIEWERS_INCLUDE_ME',
        label: 'include me',
        displayType: 'no-arg'
    },
    {
        name: 'REVIEWERS_INCLUDE_USER',
        label: 'include user',
        displayType: 'select-company-user'
    },
    {
        name: 'IS_DATE',
        label: 'Is (date)',
        explainer: '=',
        displayType: 'datepicker',
        section: 'dates'
    },
    {
        name: 'IS_NOT_DATE',
        label: 'Is not (date)',
        explainer: '!=',
        displayType: 'datepicker',
        section: 'dates'
    },
    {
        name: 'AFTER_DATE',
        label: 'After (date)',
        explainer: '>',
        displayType: 'datepicker',
        section: 'dates'
    },
    {
        name: 'BEFORE_DATE',
        label: 'Before (date)',
        explainer: '<',
        displayType: 'datepicker',
        section: 'dates'
    },
    {
        name: 'WITHIN_DATES',
        label: 'Within (dates)',
        type: 'rts',
        explainer: 'Between',
        displayType: 'daterangepicker',
        section: 'dates'
    },
    {
        name: 'CURRENT_TIMEPERIOD',
        label: 'Current',
        type: 'string',
        explainer: 'In current',
        displayType: 'select',
        values: QUERY_OP_FILTER_TIMEPERIOD,
        section: 'timeperiods'
    },
    {
        name: 'LAST_N_TIMEPERIOD',
        label: 'Last',
        type: 'tp',
        explainer: 'In last',
        displayType: 'timeperiod',
        values: [QUERY_OP_FILTER_TIMEPERIOD],
        section: 'timeperiods'
    },
    {
        name: 'NEXT_N_TIMEPERIOD',
        label: 'Next',
        type: 'tp',
        explainer: 'In next',
        displayType: 'timeperiod',
        values: [QUERY_OP_FILTER_TIMEPERIOD],
        section: 'timeperiods'
    },
    {
        name: 'BEFORE_N_TIMEPERIOD_WITH_OFFSET',
        label: 'Before (from/ago)',
        type: 'otp',
        explainer: 'Before',
        displayType: 'timeperiod-with-offset',
        values: [QUERY_OP_FILTER_TIMEPERIOD, QUERY_OP_FILTER_TIMEOFFSET],
        section: 'timeperiods'
    },
    {
        name: 'AFTER_N_TIMEPERIOD_WITH_OFFSET',
        label: 'After (from/ago)',
        type: 'otp',
        explainer: 'After',
        displayType: 'timeperiod-with-offset',
        values: [QUERY_OP_FILTER_TIMEPERIOD, QUERY_OP_FILTER_TIMEOFFSET],
        section: 'timeperiods'
    },
    {
        name: 'IS_EMPTY',
        label: 'Is empty',
        displayType: 'no-arg',
        section: 'no-arg'
    },
    {
        name: 'IS_NOT_EMPTY',
        label: 'Is not empty',
        displayType: 'no-arg',
        section: 'no-arg'
    }
];

// =============================================
// Pre-built operator lists per type
// =============================================
export const QUERY_OP_ID_AGGREGATORS: QueryOpAggregator[] = ['COUNT'];
export const QUERY_OP_DEFAULT_AGGREGATORS: QueryOpAggregator[] = ['COUNT_DISTINCT'];
export const QUERY_OP_NUMBER_AGGREGATORS: QueryOpAggregator[] = [
    'AVG',
    'COUNT_DISTINCT',
    'MAX',
    'MEDIAN',
    'MIN',
    'STDDEV',
    'SUM',
    'VARIANCE'
];

export const QUERY_OP_LIST_FILTERS: QueryOpFilter[] = [
    'INCLUDE',
    'INCLUDE_ANY_OF',
    'NOT_INCLUDE',
    'NOT_INCLUDE_ANY_OF',
    'LENGTH_GREATER_THAN',
    'LENGTH_GREATER_THAN_EQUAL',
    'LENGTH_EQUAL',
    'LENGTH_LESS_THAN',
    'LENGTH_LESS_THAN_EQUAL',
    'LENGTH_NOT_EQUAL'
];
export const QUERY_OP_STRING_FILTERS: QueryOpFilter[] = [
    'EQUAL',
    'IS_NULL',
    'IS_NOT_NULL',
    'MATCH',
    'NOT_EQUAL',
    'NOT_MATCH'
];
export const QUERY_OP_ENUM_FILTERS: QueryOpFilter[] = ['EQUAL', 'NOT_EQUAL'];
export const QUERY_OP_NUMBER_FILTERS: QueryOpFilter[] = [
    'EQUAL',
    'NOT_EQUAL',
    'GREATER_THAN',
    'GREATER_THAN_EQUAL',
    'LESS_THAN',
    'LESS_THAN_EQUAL'
];
export const QUERY_OP_TS_FILTERS: QueryOpFilter[] = [
    'IS_DATE',
    'IS_NOT_DATE',
    'BEFORE_DATE',
    'AFTER_DATE',
    'WITHIN_DATES',
    'CURRENT_TIMEPERIOD',
    'LAST_N_TIMEPERIOD',
    'NEXT_N_TIMEPERIOD',
    'BEFORE_N_TIMEPERIOD_WITH_OFFSET',
    'AFTER_N_TIMEPERIOD_WITH_OFFSET',
    'IS_EMPTY',
    'IS_NOT_EMPTY'
];

// =============================================
// Field definition
// =============================================
export interface QueryOpField extends NameLabelPair {
    id: string;
    // Override the field icon with a custom icon. All icons decided by default using (type) attribute
    icon?: IconDefinition;
    // The Viz type of the field (true type). For arrays, this is the type of the array elements.
    type: QueryOpFieldType;
    // Whether the type is an array
    typeIsArray?: boolean;
    // In which contexts can this field be used
    usageScopes: QueryOpUsageScope[];
    // A description of the content of this field. Used to discovered dynamic
    // inbox item fields.
    schema?: PrimitiveFieldSchema | ObjectFieldSchema;
    // The list of metric aggregator functions that can be used with this field
    aggregators?: QueryOpAggregator[];
    // The default display formatting function
    defaultDisplayFormatter?: QueryOpDisplayFormatter;
    // The list of display formatting functions
    displayFormatters?: QueryOpDisplayFormatter[];
    // The list of functions that can be used in combination with GROUP BY fields
    dimensionFormatters?: QueryOpDimensionFormatter[];
    // The vizualization to use on filters
    // See: RulesetValueEditor component
    filterDisplayType?: QueryOpFilterDisplayType;
    // The list of filters that can be used with this field
    filters?: QueryOpFilter[];
    // The available values for an ENUM type field
    filterValues?: QueryOpEnum[];
    // Should the field be put in a dedicated section inside the dropdown?
    // Used to group dynamic inbox item fields per app (see useCustomFields)
    filterSection?: string;
    // Used to restrict the field to certain views (query, board, prioritization rules)
    filterScopes?: QueryOpFilterScope[];
}

// ================================================
// Configuration of app-specific custom fields
// ================================================
export interface QueryOpDynamicFieldMappingConfig {
    // The Viz type of the field (true type)
    type: QueryOpFieldType;
    // The list of filters that can be used with this field
    filters?: QueryOpFilter[];
    // The vizualization to use on filters
    // See: RulesetValueEditor component
    filterDisplayType?: QueryOpFilterDisplayType;
}

export const QUERY_OP_FIELD_TRAVERSING_SEPARATOR = '.';
export const QUERY_OP_FIELD_ID_TRAVERSING_SEPARATOR = '_';
export const QUERY_OP_FIELD_WILDCARD_SELECTOR = '*';
export const QUERY_OP_CUSTOM_FIELD_PREFIX = 'custom_fields';
export const QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING: {
    [key in CustomFieldType]: QueryOpDynamicFieldMappingConfig;
} = {
    STRING: { type: 'string', filters: QUERY_OP_STRING_FILTERS },
    INTEGER: { type: 'int', filterDisplayType: 'number', filters: QUERY_OP_NUMBER_FILTERS },
    FLOAT: { type: 'float', filterDisplayType: 'number', filters: QUERY_OP_NUMBER_FILTERS },
    BOOLEAN: { type: 'bool', filterDisplayType: 'select', filters: QUERY_OP_ENUM_FILTERS },
    DATETIME: { type: 'ts', filters: QUERY_OP_TS_FILTERS },
    ARRAY: { type: 'string', filters: QUERY_OP_LIST_FILTERS },
    OBJECT: { type: 'string', filters: [] } // not expected
};

// ================================================
// Formula configurations
// ================================================
// List of function configurations that are usable in formula when
// the formula is written in 'metric' mode.
export const LG_FORMULA_METRIC_FN_CONFIGS = [
    ...QUERY_OP_AGGREGATOR_CONFIGS.filter(e => !e.hideInFormula),
    ...QUERY_OP_FORMULA_OPERATOR_CONFIGS
];

// List of function configurations that are usable in formula when
// the formula is written in 'dimension' mode.
export const LG_FORMULA_DIMENSION_FN_CONFIGS = QUERY_OP_FORMULA_OPERATOR_CONFIGS;
