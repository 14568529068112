import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ErrorMessage } from 'components/ErrorManagement';
import React, { FunctionComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { faBan, faTrash } from '@fortawesome/free-solid-svg-icons';

interface Props {
    isEditing?: boolean;
    onRemoveWidget: () => void;
}

// Widget wrapper on the dashboard grid.
// Load the widget remotely and pass it to widget
const WidgetNotFound: FunctionComponent<Props> = ({ isEditing, onRemoveWidget }: Props) => {
    return (
        <Card className={classNames('h-100', { 'cursor-move': isEditing })}>
            <Card.Body className="h-100 d-flex flex-column">
                {/* Action bar with bin button */}
                <div className="d-flex justify-content-end align-items-center">
                    <Button variant="link" className="p-0">
                        <FontAwesomeIcon onClick={onRemoveWidget} icon={faTrash} className="text-danger" />
                    </Button>
                </div>

                {/* Error message */}
                <div className="flex-grow-1 d-flex justify-content-center align-items-center flex-column text-center">
                    <FontAwesomeIcon icon={faBan} className="text-grey-7 mb-3" size="3x" />
                    <div>
                        <ErrorMessage error={{ namespace: 'dashboard-widget', code: 'no-access' }} />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default WidgetNotFound;
