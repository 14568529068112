import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DiamondsStart } from 'assets/img/diamonds-start.svg';
import { ReactComponent as DiamondsEnd } from 'assets/img/diamonds-end.svg';
import Header from './Header';
import classNames from 'classnames';
import { RichMessage } from 'components/RichMessage';
import { useMeasure } from 'react-use';
import { useBreakpoint } from 'util/useBreakpoint';
import DiamondBullet from 'components/Design/DiamondBullet';

// Where to go after this step
const NEXT_STEP_PATH = '/flows/onboarding/app-selection';

const STEPS = ['connect-apps', 'import-projects', 'configure-dashboard'];

const Step0bWhatsNext: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();

    const [step2Ref, { height: step2Height }] = useMeasure<HTMLDivElement>();

    const breakpoint = useBreakpoint();
    const largeScreen = useMemo(() => ['xl', 'xxl'].includes(breakpoint), [breakpoint]);
    const diamondSize = useMemo(() => (largeScreen ? 96 : 48), [largeScreen]);

    const connectApps = useCallback(() => {
        navigate(NEXT_STEP_PATH, { replace: true });
    }, [navigate]);

    return (
        <div className="vh-100 vw-100 d-flex flex-column overflow-y-auto">
            {/* Header */}
            <div className="splitscreen-flow-header flex-grow-0">
                <Header />
            </div>

            {/* Main */}
            <div className="flex-grow-1 bg-dark-cross-pattern-2 d-flex flex-column justify-content-around mx-auto align-items-center text-white w-100 py-5">
                {/* Title */}
                <Row className="mb-5">
                    <h1>
                        <DiamondsStart className="me-3" />
                        <RichMessage id="flows.onboarding.whats-next.title" />
                        <DiamondsEnd className="ms-3" />
                    </h1>
                </Row>

                {/* Steps */}
                <div
                    className="steps-container"
                    style={{
                        marginBottom: largeScreen ? step2Height : undefined
                    }}
                >
                    <Row className="mx-auto" style={{ maxWidth: '1200px' }} xs={1} xl={3}>
                        {STEPS.map((step, index) => {
                            const even = index % 2 == 0;
                            const odd = !even;
                            return (
                                <Col
                                    key={step}
                                    ref={index == 1 ? step2Ref : undefined}
                                    className="step d-flex justify-content-end align-items-center"
                                    style={{ transform: odd && largeScreen ? 'translateY(100%)' : undefined }}
                                >
                                    {/* Description */}
                                    <div className="my-3">
                                        <h2 className="mb-2">
                                            <RichMessage id={`flows.onboarding.whats-next.${step}.title`} />
                                        </h2>
                                        <div>
                                            <RichMessage id={`flows.onboarding.whats-next.${step}.description`} />
                                        </div>
                                    </div>

                                    {/* Arrow (only on large screen) */}
                                    {largeScreen && (
                                        <FontAwesomeIcon
                                            className={classNames({ 'mb-5': even, 'mt-5': odd })}
                                            icon={even ? faLongArrowAltDown : faLongArrowAltUp}
                                            size="3x"
                                        />
                                    )}

                                    {/* Diamond bullet */}
                                    <DiamondBullet
                                        label={`${index + 1}`}
                                        size={diamondSize}
                                        className={classNames({ 'me-5': !largeScreen })}
                                        style={{
                                            marginBottom: largeScreen
                                                ? even
                                                    ? diamondSize * -0.5
                                                    : undefined
                                                : undefined,
                                            marginTop: largeScreen ? (odd ? diamondSize * -0.5 : undefined) : undefined,
                                            marginLeft: !largeScreen ? diamondSize * -0.5 : undefined
                                        }}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </div>

                {/* Next button */}
                <Row className="w-100 mt-5">
                    <Button
                        variant="primary"
                        className="w-50 justify-content-center mx-auto"
                        style={{ maxWidth: '592px', minWidth: '300px' }}
                        onClick={connectApps}
                    >
                        <RichMessage id="flows.onboarding.whats-next.next" />
                    </Button>
                </Row>
            </div>
        </div>
    );
};

export default Step0bWhatsNext;
