import classNames from 'classnames';
import React, { forwardRef, ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react';

interface Props {
    children: ReactNode;
    className: string;
    style: object;
}
const DropdownMultiselectMenu: ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> = forwardRef(
    ({ children, className, style }: Props, ref) => {
        return (
            <div ref={ref} style={style} className={classNames('dropdown-multiselect-menu', className)}>
                {children}
            </div>
        );
    }
);

export default DropdownMultiselectMenu;
