import React, { FunctionComponent, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WIDGET_BUILDER_PARAM_WIDGET } from 'constants/routeBuilders';
import CopyToClipboard from 'components/Design/CopyToClipboard';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';

const WidgetAsJsonTemplate: FunctionComponent = () => {
    // Services
    const [searchParams] = useSearchParams();

    // Get widget from URL
    const widgetFromParamStr = searchParams.get(WIDGET_BUILDER_PARAM_WIDGET);
    const widgetFromUrl = widgetFromParamStr ? (JSON.parse(widgetFromParamStr) as WidgetFactType) : undefined;

    // Pre-formatted JSON
    const copyJsonContent = useMemo(() => {
        if (!widgetFromUrl) return;
        return JSON.stringify(widgetFromUrl, null, 2);
    }, [widgetFromUrl]);

    return (
        <>
            <div className="ps-5 pt-5">
                {/* Copy buttons */}
                <div className="mb-4 d-flex">
                    {copyJsonContent && (
                        <CopyToClipboard
                            className="me-2"
                            content={copyJsonContent}
                            labelId="dashboarding.resource-as-json-template.copy-json"
                        />
                    )}
                </div>

                {/* Preformatted JSON */}
                <pre className="bg-white">{JSON.stringify(widgetFromUrl || {}, null, 2)}</pre>
            </div>
        </>
    );
};

export default WidgetAsJsonTemplate;
