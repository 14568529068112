import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { CompanyUser, GetCompanyUsersResp, GET_COMPANY_USERS } from '../queries/CompanyUser';
import { useMemo } from 'react';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
}

interface CompanyUsersQueryResults extends QueryResult<GetCompanyUsersResp, OperationVariables> {
    normalized: CompanyUser[];
}

// TODO: implement pagination
export function useCompanyUsers({ companyId, skip }: QueryOpts): CompanyUsersQueryResults {
    // Integrations data fetching and subscriptions
    // We do not fetch paginated pages on users because we don't expect more than a 100 users
    // on a company anytime soon.
    const rs = useQuery<GetCompanyUsersResp>(GET_COMPANY_USERS, {
        skip: skip || !companyId,
        variables: { companyId: companyId }
    });

    const normalized = useMemo(
        () =>
            (rs.data?.company.users.nodes || []).map(e => {
                return {
                    id: e.id,
                    firstName: e.firstName,
                    email: e.email,
                    refs: e.identities.nodes.map(i => [i.provider, i.datalinkId].join(':'))
                };
            }),
        [rs.data?.company.users.nodes]
    );

    return { ...rs, normalized: normalized };
}
