import React, { FunctionComponent } from 'react';
import { Toast as ReactHotToast } from 'react-hot-toast';
import { ProgressBar } from 'react-bootstrap';
import { RichMessage } from 'components/RichMessage';
import { Toast } from 'components/Toast';
import { useProjectImportProgress } from '../useProjectImportProgress';

interface Props {
    t: ReactHotToast;
    projectsCount: number;
}

const ProjectsImportingToast: FunctionComponent<Props> = ({ t, projectsCount }: Props) => {
    const { importProgress, remainingImportingProjects } = useProjectImportProgress(projectsCount);

    return (
        <Toast t={t} type="warning">
            <div>
                <RichMessage
                    id="priority-inbox.notification.projects-importing.message"
                    values={{ projectsCount: remainingImportingProjects }}
                />
            </div>
            <ProgressBar striped animated now={importProgress} style={{ height: '13px', marginTop: '5px' }} />
        </Toast>
    );
};

export default ProjectsImportingToast;
