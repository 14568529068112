import { Membership } from 'api/hq/queries/CompanyMembership';
import { ResourceRoleName } from 'api/hq/queries/ResourceRole';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Form, Dropdown, Button } from 'react-bootstrap';
import Gravatar from 'react-gravatar';
import { FormattedMessage, useIntl } from 'react-intl';
import { ALLOWED_ROLES } from './ShareDashboardModal/ShareDashboardModal';

interface Props {
    memberships: Membership[];
    className?: string;
    onRoleAdded: (membership: Membership, newRole: ResourceRoleName) => void;
}

const AddResourceRole: FunctionComponent<Props> = ({ memberships, className, onRoleAdded }: Props) => {
    // Services
    const intl = useIntl();

    // State
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedMember, setSelectedMember] = useState<Membership | undefined>(undefined);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<ResourceRoleName>('VIEWER');

    // Filtered members by search term
    const filteredMembers = useMemo(
        () =>
            memberships.filter(
                m => m.user.email.toLowerCase().includes(searchTerm) || m.user.firstName.includes(searchTerm)
            ),
        [memberships, searchTerm]
    );

    // Hook invoked when the value changes
    const onValueChange = (val: string): void => {
        setSearchTerm(val);
    };

    // Hook invoked when we add a role
    const handleOnAddRole = useCallback(() => {
        if (!selectedMember) return;

        // Update the parent about the added role
        onRoleAdded(selectedMember, selectedRole);

        // Reset the field
        setSelectedMember(undefined);
        setSearchTerm('');
    }, [onRoleAdded, selectedMember, selectedRole]);

    // Hook invoked when the user selects a member
    const handleSelectMember = useCallback((member: Membership) => {
        setSelectedMember(member);
        setSearchTerm(member.user.email);
        setIsFocused(false);
    }, []);

    return (
        <div className={classNames('d-flex w-100', className)}>
            <div className="flex-grow-1 me-3 position-relative">
                {/* Input */}
                <Form.Control
                    autoComplete="off"
                    placeholder={intl.messages['dashboarding.share-dashboard-modal.input.placeholder'] as string}
                    value={searchTerm}
                    onChange={e => onValueChange(e.target.value)}
                    onKeyDown={() => setSelectedMember(undefined)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />

                {/* Autocomplete results */}
                <div
                    className={classNames('position-absolute w-100 border-1 rounded-2 mt-1 bg-white', {
                        'd-none': !isFocused
                    })}
                    style={{
                        borderStyle: 'solid',
                        borderColor: '#9cc0fb'
                    }}
                >
                    {filteredMembers && filteredMembers.length > 0 ? (
                        filteredMembers.map(member => {
                            return (
                                <div
                                    key={member.user.id}
                                    className="dropdown-item cursor-pointer d-flex align-items-center p-3"
                                    // Use `onMouseDown` event instead of `onClick` event, otherwise the `onBlur` event of the input
                                    // might be processed before, resulting in no member selected at all.
                                    onMouseDown={() => handleSelectMember(member)}
                                >
                                    {/* Avatar */}
                                    <Gravatar
                                        email={member.user.email}
                                        default="wavatar"
                                        className="rounded-circle me-3"
                                    />
                                    {/* Name / email */}
                                    <div>
                                        <strong>{member.user.firstName}</strong>
                                        <div>{member.user.email}</div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-grey-1 p-3">
                            <FormattedMessage id="dashboarding.share-dashboard-modal.input.no-results" />
                        </div>
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center">
                {/* Role dropdown */}
                <Dropdown onSelect={(eventKey: string | null) => setSelectedRole(eventKey as ResourceRoleName)}>
                    <Dropdown.Toggle
                        variant="outline-dark"
                        role="button"
                        className="d-flex justify-content-between align-items-center h-100 me-3"
                        style={{ width: '110px' }}
                    >
                        <FormattedMessage id={`dashboarding.share-dashboard-modal.roles.${selectedRole}`} />
                        &nbsp;
                    </Dropdown.Toggle>

                    {/* List of allowed roles */}
                    <Dropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        {ALLOWED_ROLES.map(option => {
                            return (
                                <Dropdown.Item key={option} eventKey={option}>
                                    <FormattedMessage id={`dashboarding.share-dashboard-modal.roles.${option}`} />
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                {/* Add button */}
                <Button
                    disabled={!selectedMember}
                    variant="primary"
                    className="w-100 justify-content-center"
                    onClick={handleOnAddRole}
                >
                    <FormattedMessage id="dashboarding.share-dashboard-modal.add" />
                </Button>
            </div>
        </div>
    );
};

export default AddResourceRole;
