import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import {
    DashboardWithHeaders,
    GetCompanyDashboardsResp,
    GET_COMPANY_DASHBOARDS,
    SUBSCRIBE_TO_DASHBOARD_CREATED,
    SUBSCRIBE_TO_DASHBOARD_DELETED,
    SUBSCRIBE_TO_DASHBOARD_UPDATED,
    CompanyDashboardResp
} from '../queries/Dashboard';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';
import {
    ResourceRoleDeletedSubscription,
    ResourceRoleName,
    ResourceRoleUpsertedSubscription,
    SUBSCRIBE_TO_RESOURCE_ROLE_CREATED,
    SUBSCRIBE_TO_RESOURCE_ROLE_DELETED,
    SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED
} from '../queries/ResourceRole';

interface QueryOpts {
    skip?: boolean;
    pinned?: boolean;
    roles?: ResourceRoleName[];
}

interface DashboardsQueryResults extends QueryResult<GetCompanyDashboardsResp, OperationVariables> {
    normalized: DashboardWithHeaders[];
}

export function useDashboards(opts?: QueryOpts): DashboardsQueryResults {
    // Get context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve the dashboards belonging to the current user/company
    const rs = useQuery<GetCompanyDashboardsResp>(GET_COMPANY_DASHBOARDS, {
        skip: opts?.skip || !company?.id,
        variables: {
            companyId: company?.id,
            currentUserPinned: opts?.pinned,
            currentUserRoles: opts?.roles
        }
    });

    // Subscribe to dashboard updates
    useSafeSubscription(SUBSCRIBE_TO_DASHBOARD_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to dashboard creation
    useSafeSubscription(SUBSCRIBE_TO_DASHBOARD_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to dashboard deletion
    useSafeSubscription(SUBSCRIBE_TO_DASHBOARD_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Subscribe to role updates
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role creation
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role deletion
    useSafeSubscription<ResourceRoleDeletedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            // The subscription payload only contains the object ID. We cannot
            // check the Actor.
            rs.refetch();
        }
    });

    // Extract the list of dashboards
    const normalized = useMemo(() => {
        const objList = rs.data?.dashboards?.nodes || [];
        return sortBy(
            objList.map(
                (dr: CompanyDashboardResp): DashboardWithHeaders => {
                    const dashboardWithHeaders = omitDeep(dr, '__typename') as DashboardWithHeaders;
                    dashboardWithHeaders.pinned = dr.pins?.nodes?.[0]?.pinned === true;
                    return dashboardWithHeaders;
                }
            ),
            'name'
        );
    }, [rs.data?.dashboards?.nodes]);

    return { ...rs, loading: rs.loading, normalized: normalized };
}
