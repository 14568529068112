import { faList } from '@fortawesome/free-solid-svg-icons';
import {
    QueryOpField,
    QueryOpFilter,
    QUERY_OP_STRING_FILTERS,
    QUERY_OP_TS_FILTERS,
    QUERY_OP_TS_DIMENSION_FORMATTERS,
    QUERY_OP_TS_DISPLAY_FORMATTERS,
    QUERY_OP_ID_AGGREGATORS,
    QUERY_OP_DEFAULT_AGGREGATORS,
    QUERY_OP_ENUM_FILTERS,
    QUERY_OP_LIST_FILTERS,
    QUERY_OP_URL_DISPLAY_FORMATTERS
} from './QueryOperators';

export const REVIEWS_QUERY_OP_FIELDS: QueryOpField[] = [
    {
        id: 'author_username',
        name: 'author.username',
        label: 'Author',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['AUTHOR_IS_ME', 'AUTHOR_IS_USER'].concat(QUERY_OP_STRING_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'body',
        name: 'body',
        label: 'Body',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'created_at',
        name: 'created_at',
        label: 'Created at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: '_system_id',
        name: '_system_id',
        label: 'ID (system)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'id',
        name: 'id',
        label: 'ID (source)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'project_name',
        name: 'project.name',
        label: 'Project',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'state',
        name: 'state',
        label: 'State',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'APPROVED', label: 'Approved' },
            { name: 'CHANGES_REQUESTED', label: 'Changes requested' },
            { name: 'COMMENTED', label: 'Informational' },
            { name: 'DISMISSED', label: 'Dismissed' },
            { name: 'PENDING', label: 'Pending' }
        ]
    },
    {
        id: 'updated_at',
        name: 'updated_at',
        label: 'Updated at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'url',
        name: 'url',
        label: 'URL',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        defaultDisplayFormatter: 'FULL_LINK',
        displayFormatters: QUERY_OP_URL_DISPLAY_FORMATTERS,
        filters: QUERY_OP_STRING_FILTERS
    },

    // ------------------------------------
    // Pull Request Association
    // ------------------------------------
    {
        id: 'pull_request_author_username',
        name: 'pull_request_author.username',
        label: 'Pull Request | Author',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['AUTHOR_IS_ME', 'AUTHOR_IS_USER'].concat(QUERY_OP_STRING_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'pull_request_base_ref_branch',
        name: 'pull_request_base_ref.name',
        label: 'Pull Request | Base ref | Branch',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_base_ref_repository',
        name: 'pull_request_base_ref.repo_fullname',
        label: 'Pull Request | Base ref | Repository',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_created_at',
        name: 'pull_request_created_at',
        label: 'Pull Request | Created at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'pull_request_closed_at',
        name: 'pull_request_closed_at',
        label: 'Pull Request | Closed at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'pull_request_due_on',
        name: 'pull_request_due_on',
        label: 'Pull Request | Due on',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'pull_request_head_ref_branch',
        name: 'pull_request_head_ref.name',
        label: 'Pull Request | Head ref | Branch',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_head_ref_repository',
        name: 'pull_request_head_ref.repo_fullname',
        label: 'Pull Request | Head ref | Repository',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_system_id',
        name: 'pull_request_system_id',
        label: 'Pull Request | ID (system)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_id',
        name: 'pull_request_id',
        label: 'Pull Request | ID (source)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_label_names',
        name: 'pull_request_labels.*.name',
        label: 'Pull Request | Labels',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_LIST_FILTERS
    },
    {
        id: 'pull_request_merged_at',
        name: 'pull_request_merged_at',
        label: 'Pull Request | Merged at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'pull_request_review_state',
        name: 'pull_request_review_state',
        label: 'Pull Request | Review state',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'APPROVED', label: 'Approved' },
            { name: 'CHANGES_REQUESTED', label: 'Changes requested' },
            { name: 'NONE', label: 'None' },
            { name: 'PENDING', label: 'Pending' }
        ]
    },
    {
        id: 'pull_request_state',
        name: 'pull_request_state',
        label: 'Pull Request | State',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'CLOSED', label: 'Closed (not merged)' },
            { name: 'MERGED', label: 'Merged' },
            { name: 'OPEN', label: 'Open' }
        ]
    },
    {
        id: 'pull_request_title',
        name: 'pull_request_title',
        label: 'Pull Request | Title',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'pull_request_updated_at',
        name: 'pull_request_updated_at',
        label: 'Pull Request | Updated at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'pull_request_url',
        name: 'pull_request_url',
        label: 'Pull Request | URL',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        defaultDisplayFormatter: 'FULL_LINK',
        displayFormatters: QUERY_OP_URL_DISPLAY_FORMATTERS,
        filters: QUERY_OP_STRING_FILTERS
    }
];
