import { useMemo } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { ComplexValue, DateRangeValue, DateValue, RuleValue, TimePeriodValue } from 'components/RulesetBuilder';
import { Coordinates } from 'contexts/DrilldownContext';

export interface Context {
    userId?: string | null;
}

// Fragment association in filters
const COMBINATORS = [
    {
        name: 'AND',
        fragment: () => 'AND'
    },
    {
        name: 'OR',
        fragment: () => 'OR'
    }
];

// Simplified filter operators
const OPERATORS_WITH_VALUE = [
    {
        name: 'AFTER_DATE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN',
            args: dateTimeGranularityArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'AFTER_N_TIMEPERIOD_WITH_OFFSET',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN_EQUAL',
            args: timePeriodWithOffsetArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'ASSIGNEES_INCLUDE_USER',
        fragment: (field: string, ruleValue: RuleValue) => ({
            function: 'CONTAINS',
            args: [{ field: 'assignees.*.ref' }, { userRefs: ruleValue.value }]
        })
    },
    {
        name: 'AUTHOR_IS_USER',
        fragment: (field: string, ruleValue: RuleValue) => ({
            function: 'IN',
            args: [{ field: 'author.ref' }, { userRefs: ruleValue.value }]
        })
    },
    {
        name: 'BEFORE_DATE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN',
            args: dateTimeGranularityArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'BEFORE_N_TIMEPERIOD_WITH_OFFSET',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN_EQUAL',
            args: timePeriodWithOffsetArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'CURRENT_TIMEPERIOD',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'AND',
            args: currentTimePeriodArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'EQUAL',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'GREATER_THAN',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'GREATER_THAN_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN_EQUAL',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'INCLUDE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'CONTAINS',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'INCLUDE_ANY_OF',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'CONTAINS',
            args: anyOfArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'IS_DATE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'EQUAL',
            args: dateTimeGranularityArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'IS_NOT_DATE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_EQUAL',
            args: dateTimeGranularityArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LAST_N_TIMEPERIOD',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'AND',
            args: lastTimePeriodArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_GREATER_THAN',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_GREATER_THAN_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'GREATER_THAN_EQUAL',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'EQUAL',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_LESS_THAN',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_LESS_THAN_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN_EQUAL',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LENGTH_NOT_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_EQUAL',
            args: lengthArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LESS_THAN',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'LESS_THAN_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'LESS_THAN_EQUAL',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'MATCH',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'MATCH',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'MERGED_BY_IS_USER',
        fragment: (field: string, ruleValue: RuleValue) => ({
            function: 'IN',
            args: [{ field: 'merged_by.ref' }, { userRefs: ruleValue.value }]
        })
    },
    {
        name: 'NEXT_N_TIMEPERIOD',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'AND',
            args: nextTimePeriodArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'NOT_EQUAL',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_EQUAL',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'NOT_INCLUDE',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_CONTAINS',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'NOT_INCLUDE_ANY_OF',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_CONTAINS',
            args: anyOfArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'NOT_MATCH',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'NOT_MATCH',
            args: regularArgs(field, ruleValue, pickedCoordinates)
        })
    },
    {
        name: 'RECOMMENDED_ACTORS_INCLUDE_USER',
        fragment: (field: string, ruleValue: RuleValue) => ({
            function: 'CONTAINS',
            args: [{ field: 'recommended_actors.*.ref' }, { userRefs: ruleValue.value }]
        })
    },
    {
        name: 'REVIEWERS_INCLUDE_USER',
        fragment: (field: string, ruleValue: RuleValue) => ({
            function: 'CONTAINS',
            args: [{ field: 'requested_reviewers.*.ref' }, { userRefs: ruleValue.value }]
        })
    },
    {
        name: 'WITHIN_DATES',
        fragment: (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates) => ({
            function: 'AND',
            args: withinDatesArgs(field, ruleValue, pickedCoordinates)
        })
    }
];

const OPERATORS_WITHOUT_VALUE = [
    {
        name: 'ASSIGNEES_INCLUDE_ME',
        fragment: (field: string, ctx: Context) => ({
            function: 'CONTAINS',
            args: [{ field: 'assignees.*.ref' }, { userRefs: ctx.userId }]
        })
    },
    {
        name: 'AUTHOR_IS_ME',
        fragment: (field: string, ctx: Context) => ({
            function: 'IN',
            args: [{ field: 'author.ref' }, { userRefs: ctx.userId }]
        })
    },
    {
        name: 'IS_EMPTY',
        fragment: (field: string) => ({
            function: 'IS_NULL',
            args: [{ field: field }]
        })
    },
    {
        name: 'IS_NULL',
        fragment: (field: string) => ({
            function: 'IS_NULL',
            args: [{ field: field }]
        })
    },
    {
        name: 'IS_NOT_EMPTY',
        fragment: (field: string) => ({
            function: 'IS_NOT_NULL',
            args: [{ field: field }]
        })
    },
    {
        name: 'IS_NOT_NULL',
        fragment: (field: string) => ({
            function: 'IS_NOT_NULL',
            args: [{ field: field }]
        })
    },
    {
        name: 'MERGED_BY_IS_ME',
        fragment: (field: string, ctx: Context) => ({
            function: 'IN',
            args: [{ field: 'merged_by.ref' }, { userRefs: ctx.userId }]
        })
    },
    {
        name: 'RECOMMENDED_ACTORS_INCLUDE_ME',
        fragment: (field: string, ctx: Context) => ({
            function: 'CONTAINS',
            args: [{ field: 'recommended_actors.*.ref' }, { userRefs: ctx.userId }]
        })
    },
    {
        name: 'REVIEWERS_INCLUDE_ME',
        fragment: (field: string, ctx: Context) => ({
            function: 'CONTAINS',
            args: [{ field: 'requested_reviewers.*.ref' }, { userRefs: ctx.userId }]
        })
    }
];

// Return the picked coordinate if available, otherwise return the value
const pickedCoordinateOrValue = ({
    value,
    pickedCoordinates,
    pickedCoordinateIndex
}: {
    value: string | boolean | number | undefined | ComplexValue;
    pickedCoordinates?: Coordinates;
    pickedCoordinateIndex?: number;
}): string | number | boolean | ComplexValue | undefined => {
    if (pickedCoordinateIndex == undefined || !pickedCoordinates) return value;

    return pickedCoordinates[pickedCoordinateIndex];
};

// Build the arguments for rule values of simple type
const regularArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as string | boolean | number;
    const pickedCoordinateIndex = ruleValue.pickedCoordinateIndex;

    return [
        { field: field },
        { [ruleValue.type]: pickedCoordinateOrValue({ value, pickedCoordinates, pickedCoordinateIndex }) }
    ];
};

// Build the arguments for rule values of `any of` operators
const anyOfArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as string;
    const pickedCoordinateIndex = ruleValue.pickedCoordinateIndex;
    return [
        { field: field },
        {
            [ruleValue.type]: (pickedCoordinateOrValue({
                value,
                pickedCoordinates,
                pickedCoordinateIndex
            }) as string)
                ?.split(',')
                .map(e => e.trim())
        }
    ];
};

// Build the arguments for rule values of `length` operators
const lengthArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as string | boolean | number;
    const pickedCoordinateIndex = ruleValue.pickedCoordinateIndex;

    return [
        { function: 'LENGTH', args: [{ field: field }] },
        {
            [ruleValue.type]: pickedCoordinateOrValue({
                value,
                pickedCoordinates,
                pickedCoordinateIndex
            })
        }
    ];
};

// Build the arguments for rule values of complex type `DateValue`
const dateTimeGranularityArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as DateValue;
    const pickedCoordinateIndex = value.pickedCoordinateIndex;
    const granularity = value.granularity || 'YEAR_MONTH_DAY';

    return [
        { function: granularity, args: [{ field: field }] },
        {
            function: granularity,
            args: [
                {
                    [ruleValue.type]: pickedCoordinateOrValue({
                        value: value.value,
                        pickedCoordinates,
                        pickedCoordinateIndex
                    })
                }
            ]
        }
    ];
};

// Build the arguments for rule values of complex type `TimePeriodValue`
const timePeriodWithOffsetArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as TimePeriodValue;
    const pickedCoordinateIndex = value.pickedCoordinateIndex;
    const timePeriod = value.timePeriod;
    const units = value.amount;
    const offset = value.offset;

    const fn = offset === 'AGO' ? 'SUBTRACT' : offset === 'FROM_NOW' ? 'ADD' : undefined;
    if (!fn) return [];

    return [
        { field: field },
        {
            function: fn,
            args: [
                { function: 'NOW' },
                {
                    function: 'MULTIPLY',
                    args: [
                        { int: pickedCoordinateOrValue({ value: units, pickedCoordinates, pickedCoordinateIndex }) },
                        { function: timePeriod }
                    ]
                }
            ]
        }
    ];
};

// Build the arguments for rule values of complex type `DateValue`
const currentTimePeriodArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as string;
    const pickedCoordinateIndex = ruleValue.pickedCoordinateIndex;

    return [
        {
            function: 'GREATER_THAN',
            args: [
                { field: field },
                {
                    function: `BEGINNING_OF_${(pickedCoordinateOrValue({
                        value,
                        pickedCoordinates,
                        pickedCoordinateIndex
                    }) as string).toUpperCase()}`
                }
            ]
        },
        {
            function: 'LESS_THAN',
            args: [
                { field: field },
                {
                    function: `END_OF_${(pickedCoordinateOrValue({
                        value,
                        pickedCoordinates,
                        pickedCoordinateIndex
                    }) as string).toUpperCase()}`
                }
            ]
        }
    ];
};

// Build the arguments for rule values of `LAST_N_TIMEPERIOD` operator
const lastTimePeriodArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as TimePeriodValue;
    const pickedCoordinateIndex = value.pickedCoordinateIndex;
    const timePeriod = value.timePeriod;
    const amount = value.amount;
    return [
        {
            function: 'GREATER_THAN',
            args: [
                { field: field },
                {
                    function: `BEGINNING_OF_${timePeriod}`,
                    args: [
                        {
                            function: 'SUBTRACT',
                            args: [
                                { function: 'NOW' },
                                {
                                    function: 'MULTIPLY',
                                    args: [
                                        {
                                            int: pickedCoordinateOrValue({
                                                value: amount,
                                                pickedCoordinates,
                                                pickedCoordinateIndex
                                            })
                                        },
                                        { function: timePeriod }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            function: 'LESS_THAN',
            args: [
                { field: field },
                {
                    function: `END_OF_${timePeriod}`,
                    args: [
                        {
                            function: 'SUBTRACT',
                            args: [{ function: 'NOW' }, { function: timePeriod }]
                        }
                    ]
                }
            ]
        }
    ];
};

// Build the arguments for rule values of `NEXT_N_TIMEPERIOD` operator
const nextTimePeriodArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as TimePeriodValue;
    const pickedCoordinateIndex = value.pickedCoordinateIndex;
    const timePeriod = value.timePeriod;
    const amount = value.amount;
    return [
        {
            function: 'GREATER_THAN',
            args: [
                { field: field },
                {
                    function: `BEGINNING_OF_${timePeriod}`,
                    args: [
                        {
                            function: 'ADD',
                            args: [{ function: 'NOW' }, { function: timePeriod }]
                        }
                    ]
                }
            ]
        },
        {
            function: 'LESS_THAN',
            args: [
                { field: field },
                {
                    function: `END_OF_${timePeriod}`,
                    args: [
                        {
                            function: 'ADD',
                            args: [
                                { function: 'NOW' },
                                {
                                    function: 'MULTIPLY',
                                    args: [
                                        {
                                            int: pickedCoordinateOrValue({
                                                value: amount,
                                                pickedCoordinates,
                                                pickedCoordinateIndex
                                            })
                                        },
                                        { function: timePeriod }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];
};

// Build the arguments for rule values of `WITHIN_DATES` operator
const withinDatesArgs = (field: string, ruleValue: RuleValue, pickedCoordinates?: Coordinates): object[] => {
    const value = ruleValue.value as DateRangeValue;
    const fromPickedCoordinateIndex = value.fromPickedCoordinateIndex;
    const toPickedCoordinateIndex = value.toPickedCoordinateIndex;
    const granularity = value.granularity || 'YEAR_MONTH_DAY';

    return [
        {
            function: 'GREATER_THAN_EQUAL',
            args: [
                { function: granularity, args: [{ field: field }] },
                {
                    function: granularity,
                    args: [
                        {
                            ts: pickedCoordinateOrValue({
                                value: value.fromDate,
                                pickedCoordinates,
                                pickedCoordinateIndex: fromPickedCoordinateIndex
                            })
                        }
                    ]
                }
            ]
        },
        {
            function: 'LESS_THAN_EQUAL',
            args: [
                { function: granularity, args: [{ field: field }] },
                {
                    function: granularity,
                    args: [
                        {
                            ts: pickedCoordinateOrValue({
                                value: value.toDate,
                                pickedCoordinates,
                                pickedCoordinateIndex: toPickedCoordinateIndex
                            })
                        }
                    ]
                }
            ]
        }
    ];
};

const toApiFilters = ({
    context,
    ruleset,
    pickedCoordinates
}: {
    context: Context;
    ruleset?: RuleGroupType | RuleType | null;
    pickedCoordinates?: Coordinates;
}): object | undefined => {
    const group = ruleset as RuleGroupType;
    const rule = ruleset as RuleType<string, string, RuleValue | undefined>;

    if (!ruleset) return undefined;

    // Join sub-rules using AND/OR
    if (group.combinator) {
        const combinator = COMBINATORS.find(c => c.name == group.combinator);
        const formattedArgs = group.rules
            .map((r: RuleGroupType | RuleType) => toApiFilters({ context, ruleset: r, pickedCoordinates }))
            .filter(r => r !== undefined);

        if (!combinator || !formattedArgs || formattedArgs.length == 0) return undefined;

        return {
            function: combinator.fragment(),
            args: formattedArgs
        };
    }

    // Apply filtering operator
    if (rule.operator) {
        const operatorWithValue = OPERATORS_WITH_VALUE.find(o => o.name == rule.operator);
        const operatorWithoutValue = OPERATORS_WITHOUT_VALUE.find(o => o.name == rule.operator);
        if (!operatorWithValue && !operatorWithoutValue) return undefined;

        // Extract value details
        if (operatorWithValue) {
            if (!rule.value) return undefined;
            return operatorWithValue.fragment(rule.field, rule.value as RuleValue, pickedCoordinates);
        }
        if (operatorWithoutValue) return operatorWithoutValue.fragment(rule.field, context);
    }

    return undefined;
};

export function useFormatRuleset({
    context,
    filters,
    pickedCoordinates
}: {
    context: Context;
    filters?: RuleGroupType | null;
    pickedCoordinates?: Coordinates;
}): object {
    return useMemo(() => toApiFilters({ context, ruleset: filters, pickedCoordinates }) || {}, [
        context,
        filters,
        pickedCoordinates
    ]);
}
