import { useQuery } from '@apollo/react-hooks';
import { vizApolloClient } from 'clients/vizApolloClient';
import { OperationVariables } from 'apollo-client';
import gql from 'graphql-tag';
import { QueryResult } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import { useSafeState } from 'util/useSafeState';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { useEffect } from 'react';

export const SUBSCRIBE_REPORT_UPDATED_QUERY = gql`
    subscription subscribeToReportUpdated($id: ID!) {
        reportUpdated(id: $id) {
            id
        }
    }
`;

export interface ReportResults<TResults> {
    id: string;
    results: TResults;
    pageInfo?: {
        [key: string]: {
            total_count: number;
        };
    };
    errors?: { message: string }[];
}
export interface GetReportResultResp<TResults> {
    report: ReportResults<TResults>;
}

interface QueryOpts<TResults> {
    skip?: boolean | null;
    variables?: OperationVariables;
    noSubscribe?: boolean;
    onCompleted?: (data: GetReportResultResp<TResults>) => void;
}

export interface UseReportResult<TResults> extends QueryResult<GetReportResultResp<TResults>, OperationVariables> {
    refreshing: boolean;
    initialLoading: boolean;
}

// A React hook that fetches a report from Viz and subscribe to the report ongoing updates
export function useReport<TResults>(
    query: DocumentNode,
    { skip, variables, noSubscribe, onCompleted }: QueryOpts<TResults> = {}
): UseReportResult<TResults> {
    const [refreshing, setRefreshing] = useSafeState<boolean>(false);
    const [isInitialLoading, setIsInitialLoading] = useSafeState<boolean | undefined>(undefined);

    // Report query
    const apolloQuery = useQuery<GetReportResultResp<TResults>, OperationVariables>(query, {
        client: vizApolloClient,
        skip: !!skip,
        variables: variables,
        onCompleted
    });

    // Subscribe to report updated events
    useSafeSubscription(SUBSCRIBE_REPORT_UPDATED_QUERY, {
        client: vizApolloClient,
        variables: { id: apolloQuery.data?.report.id },
        skip: noSubscribe || apolloQuery.loading || !apolloQuery.data?.report.id,
        onSubscriptionData: async ({ client }) => {
            // Force refetch
            // Note: subscriptions are limited to 10Kb of data because Pusher imposes this limitation
            // Reports are likely to exceed this limitation, hence the refetch instead of relying on Pusher
            // to deliver the updated results
            setRefreshing(true);
            await client.query({ query, variables });
            setRefreshing(false);
        }
    });

    // The initialLoading variable only gets set to true during the first load
    // and not on subsequent refreshes.
    useEffect(() => {
        if (apolloQuery.loading && isInitialLoading === undefined) {
            setIsInitialLoading(true);
        } else if (!apolloQuery.loading && isInitialLoading) {
            setIsInitialLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apolloQuery.loading, isInitialLoading]);

    // Get initial loading status
    const initialLoading = isInitialLoading === undefined ? apolloQuery.loading : isInitialLoading;

    return { ...apolloQuery, refreshing, initialLoading: initialLoading };
}
