import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CombinatorSelectorProps } from 'react-querybuilder';

const CombinatorSelector: FunctionComponent<CombinatorSelectorProps> = ({
    className,
    options,
    title,
    value,
    handleOnChange
}) => {
    return (
        <Dropdown onSelect={(eventKey: string | null) => handleOnChange(eventKey)}>
            <Dropdown.Toggle
                as="div"
                role="button"
                className={classNames(className, 'd-flex justify-content-between align-items-center h-100')}
                title={title}
            >
                {value}
            </Dropdown.Toggle>

            {/* List of combinators */}
            <Dropdown.Menu
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
            >
                {options.map(option => {
                    return (
                        <Dropdown.Item key={option.label} eventKey={option.label}>
                            {option.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CombinatorSelector;
