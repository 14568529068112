import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { InboxItem, InboxItemRelated, InboxItemWithRelated } from 'api/viz/queries/InboxItem';
import InboxFeedList from './InboxFeedList';
import { useItemComments } from 'api/viz/hooks/useItemComments';
import { useFeedGenerator } from 'api/viz/hooks/useFeedGenerator';
import { ItemComment } from 'api/viz/queries/Comment';
import InboxFeedItemLoader from './InboxFeedItemLoader';
import { useItemReviews } from 'api/viz/hooks/useItemReviews';
import { ItemReview } from 'api/viz/queries/Review';

interface Props {
    item: InboxItemWithRelated;
    inView?: boolean;
}

// Generate a unified feed for an inbox item, which includes the item itself
// and related items (resolving PRs or resolved issues)
const InboxUnifiedFeed: FunctionComponent<Props> = ({ item, inView }: Props) => {
    // Services
    const generateFeed = useFeedGenerator();

    // Extract related items
    const relatedItems: InboxItemRelated[] = useMemo(
        () => (item.type === 'issue' ? item.resolving_pull_requests : item.resolved_issues),
        [item.resolved_issues, item.resolving_pull_requests, item.type]
    );

    // Load comments
    const { loading: commentsLoading, normalized: comments } = useItemComments({
        skip: !inView,
        parentSysIds: [item._system_id, ...relatedItems.map(e => e._system_id)]
    });

    // Load reviews
    const { loading: reviewsLoading, normalized: reviews } = useItemReviews({
        skip: !inView,
        parentSysIds: item.type === 'issue' ? relatedItems.map(e => e._system_id) : [item._system_id]
    });

    // Select the comments that belong the provided parent. This is then used to
    // to generate an inbox item feed for each inbox item
    const extractComments = useCallback(
        (parent: InboxItem | InboxItemRelated): ItemComment[] => {
            return comments.filter(c => c.inbox_item_system_id === parent._system_id);
        },
        [comments]
    );

    // Select the review that belong the provided parent. This is then used to
    // to generate an inbox item feed for each inbox item
    const extractReviews = useCallback(
        (parent: InboxItem | InboxItemRelated): ItemReview[] => {
            if (parent.type === 'issue') return [];

            return reviews.filter(c => c.pull_request_system_id === parent._system_id);
        },
        [reviews]
    );

    // Generate feed for the inbox item
    // We generate the feed for each inbox item with its associated comments and review
    // then combine all the feeds.
    const includeParentName = relatedItems.length > 0;
    const allItems = useMemo(() => [item, relatedItems].flat(1), [item, relatedItems]);
    const feedItems = useMemo(
        () =>
            allItems.flatMap(e => {
                return generateFeed(e, extractComments(e), extractReviews(e));
            }),
        [allItems, extractComments, extractReviews, generateFeed]
    );

    // Abort if comments are still loading
    if (commentsLoading || reviewsLoading || !inView) {
        return <InboxFeedItemLoader />;
    }

    return <InboxFeedList parent={item} items={feedItems} includeName={includeParentName} />;
};

export default InboxUnifiedFeed;
