import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ErrorFragment } from 'components/ErrorManagement';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useCompanyUsers } from 'api/hq/hooks/useCompanyUsers';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { CompanyUser } from 'api/hq/queries/CompanyUser';

interface Props {
    className?: string;
    title?: string;
    onChange: (value: string) => void;
    value?: string;
}

const SelectCompanyUser: FunctionComponent<Props> = ({ className, title, onChange, value }: Props) => {
    // Get company
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve company members (used to populate values in QueryEditor)
    const { loading: membersLoading, error: membersError, normalized: companyMembers } = useCompanyUsers({
        companyId: company?.id
    });

    const [selectedUser, setSelectedUser] = useState<CompanyUser | undefined>(companyMembers.find(m => m.id == value));

    // Handle field selection
    const handleSelection = useCallback(
        (name: string | null): void => {
            if (name === null) return;
            onChange(name);
        },
        [onChange]
    );

    // Set default value for drop down
    useEffect(() => {
        if (!membersLoading && companyMembers.length > 0) {
            setSelectedUser(companyMembers.find(e => e.id == value));
        }
    }, [companyMembers, membersLoading, onChange, selectedUser, value]);

    // Display loading screen if the initial query is loading
    // or if the fetchMore query is still going through pagination
    if (membersLoading || !company?.id) {
        return <Spinner animation="border" size="sm" variant="dark" />;
    }

    // Handle fetch errors
    if (membersError) {
        return <ErrorFragment error={{ code: 'fetch-members', namespace: 'ruleset-builder' }} />;
    }

    return (
        <Dropdown onSelect={handleSelection}>
            <Dropdown.Toggle
                as="div"
                role="button"
                className={classNames(className, 'd-flex justify-content-between align-items-center h-100')}
                title={title}
            >
                {selectedUser ? (
                    <>
                        {selectedUser.firstName} ({selectedUser.email})
                    </>
                ) : (
                    '-'
                )}
            </Dropdown.Toggle>

            {/* List of users */}
            <Dropdown.Menu
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
            >
                {companyMembers.map(member => {
                    return (
                        <Dropdown.Item key={member.id} eventKey={member.id}>
                            {member.firstName} ({member.email})
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SelectCompanyUser;
