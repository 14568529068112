import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPickerConfig } from 'components/Design/ColorPickers/IconPicker';
import IconRenderer from 'components/Design/ColorPickers/IconRenderer';
import { PRIMARY_LIGHT_BLUE } from 'constants/colors';
import React, { FunctionComponent, useMemo } from 'react';

// Default background color
const DEFAULT_BG_COLOR = PRIMARY_LIGHT_BLUE;

// Rectangle variant fixed height
const RECTANGLE_HEIGHT = 145;

// The padding in the image container
const PADDING = 24;

// The size of the icon
const ICON_SIZE = 36;

// Background gradient opacity
const GRADIENT_OPACITY = 0.2;

// Possible variants of the header
const VARIANTS = ['square-icon', 'rectangle-icon', 'rectangle-img'] as const;
export type HeaderVariant = typeof VARIANTS[number];

export interface HeaderConfig {
    variant: HeaderVariant;
    imgDataUrl?: string;
    icon?: IconPickerConfig;
    backgroundColor?: string;
    faIcon?: IconDefinition;
    faIconSize?: SizeProp;
}

interface Props {
    config: HeaderConfig;
    title?: string;
}

const LibraryCardHeader: FunctionComponent<Props> = ({ config, title }: Props) => {
    // Extract config from props
    const { variant, imgDataUrl, icon, backgroundColor, faIcon, faIconSize } = config;

    // Background linear gradients
    const background = useMemo(() => {
        const color = backgroundColor || DEFAULT_BG_COLOR;
        return `linear-gradient(${color}, ${color}),
    linear-gradient(
      to bottom left,
      transparent 50%,
      rgba(255, 255, 255, ${GRADIENT_OPACITY}) 50%,
      rgba(255, 255, 255, ${GRADIENT_OPACITY}) 100%
    ),
    linear-gradient(
      to bottom right,
      transparent 50%,
      rgba(255, 255, 255, ${GRADIENT_OPACITY}) 50%,
      rgba(255, 255, 255, ${GRADIENT_OPACITY}) 100%
    )`;
    }, [backgroundColor]);

    // Render
    switch (variant) {
        case 'square-icon':
            // Render a square with the provided icon inside
            return (
                <div className="ps-3 pt-4" style={{ width: 'fit-content' }} title={title}>
                    <div className="bg-primary p-3 rounded">
                        {faIcon ? (
                            <FontAwesomeIcon size={faIconSize ?? 'lg'} icon={faIcon} className="text-white" />
                        ) : icon ? (
                            <IconRenderer className="my-0 mx-auto" color="white" size={ICON_SIZE} icon={icon?.ref} />
                        ) : null}
                    </div>
                </div>
            );
        case 'rectangle-icon':
            // Render a rectangle with the provided icon inside
            return (
                <div
                    className="d-flex position-relative align-items-center w-100"
                    style={{
                        height: RECTANGLE_HEIGHT,
                        backgroundBlendMode: 'overlay',
                        background
                    }}
                    title={title}
                >
                    {faIcon ? (
                        <FontAwesomeIcon size={faIconSize ?? 'lg'} icon={faIcon} className="text-white mx-auto" />
                    ) : icon ? (
                        <IconRenderer className="my-0 mx-auto" color="white" size={ICON_SIZE} icon={icon?.ref} />
                    ) : null}
                </div>
            );
        case 'rectangle-img':
            // Render a rectangle with the provided image
            return (
                <div
                    className="d-flex position-relative align-items-center w-100"
                    style={{
                        height: RECTANGLE_HEIGHT,
                        backgroundBlendMode: 'overlay',
                        background
                    }}
                    title={title}
                >
                    {imgDataUrl && (
                        <div
                            className="position-absolute w-100 h-100 overflow-hidden"
                            style={{ padding: `${PADDING}px`, paddingBottom: 0 }}
                        >
                            <img className="w-100 bg-white" src={imgDataUrl} />
                        </div>
                    )}
                </div>
            );
    }
};

export default LibraryCardHeader;
