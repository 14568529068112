import React, { FunctionComponent, ReactNodeArray } from 'react';
import { ValueEditorProps } from 'react-querybuilder';
import { RulesetValueField } from './RulesetValueField';
import { QUERY_OP_FILTER_FUNCTIONS } from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFieldLookup } from 'components/Dashboarding/Hooks/useQueryOpFieldLookup';
import { useRichIntl, HelpIcon } from 'components/RichMessage';
import { GithubMarkdown } from 'components/TextProcessor';
import { RulesetBuilderContext } from '../RulesetBuilder';

const FieldHelpIcon: FunctionComponent<ValueEditorProps> = ({ operator, field, context }: ValueEditorProps) => {
    // Extract contextual information
    const { fact } = context as RulesetBuilderContext;

    // Services
    const intl = useRichIntl();
    const fieldConfig = useQueryOpFieldLookup({ fact, fieldUri: field, matchParent: true });

    // Extract operator info
    const operatorConfig = QUERY_OP_FILTER_FUNCTIONS.find(e => e.name === operator);
    const operatorLabel = operatorConfig?.label;
    const operatorHelpKey = `components.ruleset-builder.help.operator.${operator}`;
    const operatorHelpText = intl.messages[operatorHelpKey] ? intl.formatMessage({ id: operatorHelpKey }) : null;

    // Evaluate field label
    const fieldLabel = fieldConfig?.label || fieldConfig?.id;

    // Evaluate translation key based on whether this is a native or custom field
    let fieldHelpText: string | ReactNodeArray | null;
    if (fieldConfig?.schema) {
        // For custom fields, try to evaluate a relevant help message based on the custom
        // field type. The subKey will look like `object`, `object_array`, `string_array` etc.
        // The most important fields to document are `object`` and `object_array` because they're
        // the most complex from a UI perspective.
        const subKey = [(fieldConfig.schema.type as string).toLowerCase(), fieldConfig.schema.array ? 'array' : null]
            .filter(e => e)
            .join('_');
        const fieldHelpKey = `components.ruleset-builder.help.custom_field.${subKey}`;
        fieldHelpText = intl.messages[fieldHelpKey]
            ? intl.formatMessage({ id: fieldHelpKey }, { section: fieldConfig.filterSection })
            : null;
    } else {
        const filterDocKey = `dashboarding.formula-editor.help.fields.${fact}.${fieldConfig?.id}.filter-doc`;
        const generalDocKey = `dashboarding.formula-editor.help.fields.${fact}.${fieldConfig?.id}.documentation`;
        fieldHelpText = intl.messages[filterDocKey]
            ? intl.formatMessage({ id: filterDocKey })
            : intl.messages[generalDocKey]
            ? intl.formatMessage({ id: generalDocKey })
            : null;
    }

    // Abort if not ready
    if (!operatorHelpText && !fieldHelpText) return null;

    // Render help tooltip
    return (
        <HelpIcon placement="bottom" className="me-1">
            <ul>
                {fieldLabel && fieldHelpText && (
                    <li>
                        <b>{fieldLabel}</b> <GithubMarkdown markup={fieldHelpText} />
                    </li>
                )}
                {operatorLabel && operatorHelpText && (
                    <li>
                        <b>{operatorLabel?.length <= 2 ? `"${operatorLabel}"` : operatorLabel}</b>{' '}
                        <GithubMarkdown markup={operatorHelpText} />
                    </li>
                )}
            </ul>
        </HelpIcon>
    );
};

const RulesetValueEditor: FunctionComponent<ValueEditorProps> = (props: ValueEditorProps) => {
    return (
        <>
            <RulesetValueField {...props} />
            <FieldHelpIcon {...props} />
        </>
    );
};

export default RulesetValueEditor;
