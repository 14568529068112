import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    widget: WidgetFactType & {
        dashboards: { id: string; name: string }[];
    };
}

const RelatedDashboardsList: FunctionComponent<Props> = ({ widget }) => {
    // Format the dashboards list
    const dashboards = widget.dashboards?.map(d => d.name);

    return (
        <>
            <FormattedMessage id="components.library-card.related-dashboards" />
            {dashboards && dashboards.length > 0 ? (
                <span className="d-block text-truncate" title={dashboards.join(', ')}>
                    {dashboards.join(', ')}
                </span>
            ) : (
                <FormattedMessage id="components.library-card.no-related-dashboards" />
            )}
        </>
    );
};

export default RelatedDashboardsList;
