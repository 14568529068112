import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import { PROJECT_IMPORTING_STATUS, PROJECT_PENDING_STATUS } from 'constants/defaultValues';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { ReduxState } from 'redux/reducers';

interface Result {
    importProgress: number;
    remainingImportingProjects: number;
}

export function useProjectImportProgress(projectsCount: number | undefined): Result {
    // Global state
    const authUser = useSelector((e: ReduxState) => e.authUser);
    const projectsResp = useCompanyProjects({ companyId: authUser?.company?.id });
    const projects = projectsResp.data?.company.projects.nodes || [];
    const importingProjects = projects.filter(
        e => e.status == PROJECT_IMPORTING_STATUS || e.status == PROJECT_PENDING_STATUS
    );
    const previousImportingProjects = usePrevious(importingProjects);

    if (projectsCount == undefined)
        return {
            importProgress: 0,
            remainingImportingProjects: 0
        };

    // In order to display a consistent notification we take into account
    // the initial number of projects importing, through the `projectsCount` prop,
    // to compute the overall progress. Without that the progress would vary
    // unexpectedly as projects transition from IMPORTING to ACTIVE.
    const remainingImportingProjects = importingProjects.length;
    const importingProgress = importingProjects.reduce((p, e) => p + e.progress, 0);
    const completedCount = projectsCount - remainingImportingProjects;

    let totalProgress = 0;

    // To prevent the progress bar from starting at 100% before receiving actual progresses,
    // we check if previous state update already had importing projects in progress
    if (previousImportingProjects && previousImportingProjects.length != 0) {
        totalProgress = (importingProgress + completedCount * 100) / projectsCount;
    }

    // Set a minimum progress to 5%. This makes it easier for users to understand
    // that the line is a progress bar.
    const rawProgres = totalProgress || 0;
    const importProgress = rawProgres > 5 ? rawProgres : 5;

    return {
        importProgress,
        remainingImportingProjects
    };
}
