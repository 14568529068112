import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { ASSISTANT_WIDGET_TMPL_KEY } from 'constants/localStorageKeys';
import {
    widgetAssistantStepPath,
    WIDGET_ASSISTANT_STEP1,
    WIDGET_ASSISTANT_STEP3,
    WIDGET_TMPL_ID_KEY
} from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { useTitleLabel } from 'util/useTitleLabel';
import DocumentInsight from '../DashboardWidgetEdit/ConfigurationPanel/DocumentInsight';
import Header from './Header';
import WidgetPreview from './WidgetPreview';

export const Step2Document: FunctionComponent = () => {
    // Page title
    useTitleLabel('dashboarding.widget-assistant.title');

    // State
    const [widget, setWidget] = useLocalStorage<WidgetFactType>(ASSISTANT_WIDGET_TMPL_KEY);

    // Extract params
    const params = useParams() as { dashboardId: string };
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(WIDGET_TMPL_ID_KEY);
    const dashboardId = params.dashboardId;

    // Hook invoked when the documentation is edited
    const onChange = useCallback(
        (e: WidgetFactType): void => {
            setWidget(e);
        },
        [setWidget]
    );

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            <>
                <div className="flex-grow-1">
                    {/* Title */}
                    <div className="d-flex align-items-baseline mt-4 mb-5">
                        <Button
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            as={Link as any}
                            to={widgetAssistantStepPath(WIDGET_ASSISTANT_STEP1, templateId, dashboardId)}
                            variant="link"
                            className="p-0"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                        </Button>
                        <h1 className="fw-bolder">
                            <RichMessage id="dashboarding.widget-assistant.document.title" />
                        </h1>
                    </div>

                    {/* Document insight */}
                    <div className="mb-5">
                        {widget && <DocumentInsight hideTitle widget={widget} onChange={onChange} />}
                    </div>
                </div>

                {/* Next button */}
                <div className="flex-grow-0">
                    <Button
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        as={Link as any}
                        to={widgetAssistantStepPath(WIDGET_ASSISTANT_STEP3, templateId, dashboardId)}
                        variant="dark"
                        className="justify-content-center w-100 mb-4"
                    >
                        <RichMessage id="dashboarding.widget-assistant.document.next" />
                    </Button>
                </div>
            </>

            {/* Right section */}
            {widget && <WidgetPreview widget={widget} />}
        </LayoutSplitScreen>
    );
};
