import { onError } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import { LOGIN_REDIRECTION_PATH } from 'constants/routeBuilders';
import { Observable } from 'apollo-link';
import { ExecutionResult } from 'graphql';
import { ObjMap } from 'graphql/jsutils/ObjMap';

// Redirect user for authentication on 401 response
const AuthErrorLink = onError(({ networkError }) => {
    if (networkError) {
        const error = networkError as ServerError;

        // Handle 401 network error
        if (error.statusCode === 401) {
            window.location.href = LOGIN_REDIRECTION_PATH;

            // Return unresolved obversable to make downstream queries
            // wait and to avoid component crashes due to empty response.
            // The browser will eventually redirect the user (href assignment above).
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new Observable<ExecutionResult<{ [key: string]: any }, ObjMap<unknown>>>(() => {
                /* default */
            });
        }
    }
});

export default AuthErrorLink;
