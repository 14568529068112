/* Gogo Language Texts

Table of Contents

01.General
02.Inicio de sesión de usuario, cierre de sesión, registro 
03.Menú
04.Error 
*/

const mainMessages = {
    /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
    'user.login-title': 'Iniciar sesión',
    'user.register': 'Registro',
    'user.forgot-password': 'Se te olvidó tu contraseña',
    'user.email': 'Email',
    'user.password': 'Contraseña',
    'user.forgot-password-question': '¿Contraseña olvidada?',
    'user.fullname': 'Nombre completo',
    'user.login-button': 'INICIAR SESIÓN',
    'user.register-button': 'REGISTRO',
    'user.reset-password-button': 'REINICIAR',

    /* 03.Menú */
    'menu.app': 'Inicio',
    'menu.reports': 'Reports',
    'menu.dashboard': 'Dashboard',
    'menu.settings': 'Settings',
    'menu.project-list': 'Projects',
    'menu.ui': 'IU',
    'menu.charts': 'Gráficos',
    'menu.chat': 'Chatea',
    'menu.survey': 'Encuesta',
    'menu.todo': 'Notas',
    'menu.search': 'Búsqueda',

    /* 04.Error  */
    'layouts.error-title': 'Vaya, parece que ha ocurrido un error!',
    'layouts.error-code': 'Código de error',
    'layouts.go-back-home': 'REGRESAR A INICIO'
};

export default mainMessages;
