import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

// Layout with NO sidebar (desktop) or top nav (mobile)
const LayoutFullScreen: FunctionComponent = () => {
    return (
        <main className="menu-hidden">
            {/* Flow down routing components */}
            <Outlet />
        </main>
    );
};

export default LayoutFullScreen;
