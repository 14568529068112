import { faMagic, faTools } from '@fortawesome/free-solid-svg-icons';
import { useDashboard } from 'api/hq/hooks/useDashboard';
import classNames from 'classnames';
import useAddNewWidgetToDashboard from 'components/Dashboarding/Hooks/useAddNewWidgetToDashboard';
import { cleanupTemplateMarkup } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import { WidgetFactTemplate } from 'components/Dashboarding/Models/WidgetTemplates';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import toast from 'react-hot-toast';
import { Toast, toastQueryError } from 'components/Toast';
import { PRIMARY_DARK_BLUE, PRIMARY_NORMAL_BLUE } from 'constants/colors';
import { dashboardWidgetNewPath, widgetAssistantStepPath, WIDGET_ASSISTANT_STEP1 } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BuilderLibraryCard from './BuilderLibraryCard';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    className?: string;
    widget: WidgetFactTemplate | undefined;
    dashboardId: string;
    onWidgetAdded: () => Promise<void>;
}

export const BuilderSelection: FunctionComponent<Props> = ({ className, widget, dashboardId, onWidgetAdded }) => {
    // State
    const [loading, setLoading] = useState<boolean>(false);

    // Services
    const navigate = useNavigate();
    const intl = useRichIntl();
    const { normalized: dashboard } = useDashboard({ id: dashboardId });
    const addNewWidgetToDashboard = useAddNewWidgetToDashboard({ dashboard });

    // Hook invoked on assistant click
    const handleAssistantClick = useCallback(() => {
        if (!widget) return;

        // Redirect to the assistant
        navigate(widgetAssistantStepPath(WIDGET_ASSISTANT_STEP1, widget.id, dashboardId));
    }, [dashboardId, navigate, widget]);

    // Hook invoked on advanced builder click
    const handleAdvancedBuilderClick = useCallback(() => {
        if (!widget) return;

        // Template cleanup
        const formattedWidget = cleanupTemplateMarkup(widget);

        // Redirect to the advanced builder
        navigate(
            dashboardWidgetNewPath({
                dashboardId,
                template: {
                    ...formattedWidget,
                    name:
                        widget.name +
                        intl.formatMessage({ id: `dashboarding.add-insight-modal.widget-library.title.suffix` }),
                    templateSource: formattedWidget.id,
                    actionSource: 'platform-template'
                }
            })
        );
    }, [dashboardId, intl, navigate, widget]);

    // Hook invoked on add widget to dashboard click
    const handleAddToDashboardClick = useCallback(async () => {
        if (!widget) return;

        // Set loading state
        setLoading(true);

        // Template cleanup
        const formattedWidget = cleanupTemplateMarkup(widget);

        // Add widget to the dashboard
        const resp = await addNewWidgetToDashboard({
            widget: {
                ...formattedWidget,
                name:
                    widget.name +
                    intl.formatMessage({ id: `dashboarding.add-insight-modal.widget-library.title.suffix` }),
                templateSource: formattedWidget.id,
                actionSource: 'platform-template'
            }
        });

        // Abort if the widget could not be created and added to the dashboard
        if (resp && !resp.success) {
            toastQueryError({ namespace: 'add-widget-to-dashboard' });
            return;
        }

        // Await parent handles this event
        await onWidgetAdded();

        // Reset loading state
        setLoading(false);

        // Success toast
        toast.custom(t => (
            <Toast t={t} type="success">
                <RichMessage id="dashboarding.add-insight-modal.add-to-dashboard-success" />
            </Toast>
        ));
    }, [addNewWidgetToDashboard, intl, onWidgetAdded, widget]);

    return (
        <div className={classNames('p-5', className)}>
            <div className="m-auto" style={{ maxWidth: 700 }}>
                <Row>
                    <Col>
                        <BuilderLibraryCard
                            headerConfig={{
                                variant: 'rectangle-icon',
                                faIcon: faMagic,
                                faIconSize: '3x',
                                backgroundColor: PRIMARY_NORMAL_BLUE
                            }}
                            titleId="dashboarding.add-insight-modal.builder-selection.assistant.title"
                            descriptionId="dashboarding.add-insight-modal.builder-selection.assistant.description"
                            mainActionMessageId="dashboarding.add-insight-modal.builder-selection.assistant.button"
                            onSelect={handleAssistantClick}
                        />
                    </Col>

                    {/* Advanced builder card */}
                    <Col>
                        <BuilderLibraryCard
                            headerConfig={{
                                variant: 'rectangle-icon',
                                faIcon: faTools,
                                faIconSize: '3x',
                                backgroundColor: PRIMARY_DARK_BLUE
                            }}
                            titleId="dashboarding.add-insight-modal.builder-selection.advanced.title"
                            descriptionId="dashboarding.add-insight-modal.builder-selection.advanced.description"
                            mainActionMessageId="dashboarding.add-insight-modal.builder-selection.advanced.button"
                            onSelect={handleAdvancedBuilderClick}
                        />
                    </Col>
                </Row>
            </div>

            {/* Create and add widget to dashboard link */}
            <div className="text-center mt-5">
                <AsyncButton
                    variant="link"
                    className="m-auto fw-bold"
                    onClick={handleAddToDashboardClick}
                    disabled={loading}
                    loading={loading}
                    messageProps={{ id: 'dashboarding.add-insight-modal.builder-selection.add-to-dashboard' }}
                    loadingMessageProps={{ id: 'dashboarding.add-insight-modal.builder-selection.adding-to-dashboard' }}
                />
            </div>
        </div>
    );
};
