import React, { FunctionComponent, ChangeEvent, useCallback, useMemo } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Color } from 'react-bootstrap/types';

import {
    APP_IMAGES,
    PROJECT_ACTIVE_STATUS,
    PROJECT_DISABLED_STATUS,
    PROJECT_IMPORTING_STATUS,
    PROJECT_PENDING_STATUS
} from 'constants/defaultValues';
import { RichMessage } from 'components/RichMessage';
import { ProjectResp } from 'api/hq/queries/Project';
import { faBuilding, faUser, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFan, faSync, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AppIcon from 'components/Design/AppIcon';
import { Spinner } from 'react-bootstrap';

const BADGE_STATUS_COLORS: { [key: string]: { bg: string; text: Color } } = {
    ACTIVE: { bg: 'primary', text: 'primary' },
    DISCONNECTED: { bg: 'danger', text: 'danger' },
    DISABLED: { bg: 'light', text: 'light' },
    PENDING: { bg: 'warning', text: 'warning' }
};

const WARNING_CONFIG: { [key: string]: { icon: IconDefinition; css: string } } = {
    API_THROTTLING: { icon: faFan, css: 'text-warning' },
    API_UNAVAILABLE: { icon: faWaveSquare, css: 'text-danger' },
    WEBHOOK_UNAVAILABLE: { icon: faSync, css: 'text-dark' }
};

interface Props {
    project: ProjectResp;
    onSelectChange: (project: ProjectResp, selected: boolean) => void;
    selected?: boolean;
    compact?: boolean;
}

const ProjectListItem: FunctionComponent<Props> = ({ project, onSelectChange, selected, compact = false }: Props) => {
    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // User role
    const isAdmin = company?.role === 'ADMIN';

    // Exact formatting variables
    const statusColor = BADGE_STATUS_COLORS[project.status] || 'primary';
    const ownerIcon = project.account.accountType === 'ORGANIZATION' ? faBuilding : faUser;
    const isImporting = project.status == PROJECT_IMPORTING_STATUS;
    const isPending = project.status == PROJECT_PENDING_STATUS;
    const isActive = project.status == PROJECT_ACTIVE_STATUS;
    const isDisabled = project.status == PROJECT_DISABLED_STATUS;
    const appImg = APP_IMAGES[project.app.provider];
    const warningList = useMemo(() => [...project.warnings].sort(), [project.warnings]);

    // Hook invoked when a project is selected
    const handleSelectedChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            onSelectChange(project, e.target.checked);
        },
        [onSelectChange, project]
    );

    return (
        <tr>
            {/* Select/deselect project */}
            <td className="text-center checkbox">
                {isAdmin &&
                    (compact ? (
                        isDisabled ? (
                            <Form.Check
                                className="fs-xs"
                                type="checkbox"
                                checked={selected}
                                onChange={handleSelectedChange}
                            />
                        ) : isImporting || isPending ? (
                            <Spinner animation="border" size="sm" />
                        ) : isActive ? (
                            <Form.Check className="fs-xs" type="checkbox" checked readOnly isValid disabled />
                        ) : null
                    ) : (
                        <Form.Check
                            className="fs-xs"
                            type="checkbox"
                            checked={selected}
                            onChange={handleSelectedChange}
                        />
                    ))}
            </td>

            {/* Project name & app icon */}
            <td>
                <div className="d-flex align-items-center">
                    <AppIcon src={appImg} alt={project.app.name} className="me-2 border-0" />
                    <span className="text-dark fw-semibold">{project.name}</span>
                </div>
            </td>

            {/* Project owner */}
            <td>
                <div className="d-flex align-items-center text-grey-4 text-nowrap">
                    <FontAwesomeIcon icon={ownerIcon} className="me-2" />
                    <span>{project.account.name}</span>
                </div>
            </td>

            {/* Status & warnings */}
            {!compact && (
                <td>
                    <div className="d-flex justify-content-center align-items-center">
                        {isImporting ? (
                            <div className="text-center">
                                <div className="text-dark fw-bold mb-2">
                                    <RichMessage
                                        id="settings.project-list-item.status.IMPORTING"
                                        values={{ progress: project.progress }}
                                    />
                                </div>
                                <ProgressBar animated now={project.progress} style={{ width: '10rem' }} />
                            </div>
                        ) : (
                            <Badge
                                bg={statusColor.bg}
                                text={statusColor.text}
                                pill
                                className="bg-opacity-10 d-flex align-items-center px-4"
                            >
                                <FontAwesomeIcon icon={faCircle} className="me-2 fs-xs" />
                                <RichMessage id={`settings.project-list-item.status.${project.status}`} />
                            </Badge>
                        )}
                        {!isDisabled &&
                            warningList.map(e => <WarningIcon key={e} warning={e} provider={project.app.name} />)}
                    </div>
                </td>
            )}
        </tr>
    );
};

interface WarningIconProps {
    warning: string;
    provider: string;
}

const WarningIcon: FunctionComponent<WarningIconProps> = ({ warning, provider }: WarningIconProps) => {
    // Icon configuration
    const warnConfig = WARNING_CONFIG[warning];

    // Abort if no warning configuration
    if (!warnConfig) return null;

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={200}
            overlay={
                <Tooltip className="rounded tooltip-md">
                    <RichMessage id={`settings.project-list-item.warning-tooltip.${warning}`} values={{ provider }} />
                </Tooltip>
            }
        >
            <span className="mx-1">
                <FontAwesomeIcon icon={warnConfig.icon} className={warnConfig.css} />
            </span>
        </OverlayTrigger>
    );
};

export default ProjectListItem;
