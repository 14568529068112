import React, { FunctionComponent } from 'react';
import { IntegrationResp } from 'api/hq/queries/Integration';
import { RichMessage } from 'components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { INTEGRATION_DISABLED_STATUS, INTEGRATION_DISCONNECTED_STATUS } from 'constants/defaultValues';

interface Props {
    status?: string;
    integration?: IntegrationResp;
}

const AppConnectionStatus: FunctionComponent<Props> = ({ integration }: Props) => {
    const appStatus = (integration && integration.status) || INTEGRATION_DISABLED_STATUS;

    switch (appStatus) {
        case INTEGRATION_DISABLED_STATUS:
            return (
                <>
                    <FontAwesomeIcon icon={faUnlink} className="text-dark" />
                    <span className="ms-2">
                        <RichMessage id="components.app-connect-item.disabled" />
                    </span>
                </>
            );
        case INTEGRATION_DISCONNECTED_STATUS:
            return (
                <>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
                    <span className="ms-2 text-danger">
                        <RichMessage
                            id="components.app-connect-item.revoked-by"
                            values={{ username: integration?.authorizerUsername }}
                        />
                    </span>
                </>
            );
        default:
            return (
                <>
                    <FontAwesomeIcon icon={faLink} className="text-success" />
                    <span className="ms-2">
                        <RichMessage
                            id="components.app-connect-item.connected-via"
                            values={{ username: integration?.authorizerUsername }}
                        />
                    </span>
                </>
            );
    }
};

export default AppConnectionStatus;
