import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigQnInput } from 'components/Dashboarding/Models/ConfigQns';
import { Form } from 'react-bootstrap';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { Label } from '../Label';

interface Props {
    config: ConfigQnInput;
    onChange: (e: ConfigQnInput) => void;
}

export const Input: FunctionComponent<Props> = ({ config, onChange }) => {
    const [value, setValue] = useState<string>('');

    // Debounced version of onChange
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Hook invoked when the input changes
    const handleOnChange = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            setValue(event.target.value);
            debouncedOnChange({ ...config, result: event.target.value });
        },
        [debouncedOnChange, config]
    );

    // Resync input value from parent update
    useEffect(() => setValue(config.result), [config.result]);

    return (
        <Form.Group>
            <Label config={config} />
            <Form.Control type="input" placeholder={config.placeholder} value={value} onChange={handleOnChange} />
        </Form.Group>
    );
};
