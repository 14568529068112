import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Step0aCompanyCreation from './Step0aCompanyCreation';
import Step1AppSelection from './Step1AppSelection';
import Step2ProjectImport from './Step2ProjectImport';
import FinalizeAccount from './FinalizeAccount';
import Step0bWhatsNext from './Step0bWhatsNext';
import {
    ONBOARDING1_STEP0_A,
    ONBOARDING1_STEP0_B,
    ONBOARDING1_STEP1,
    ONBOARDING1_STEP2,
    ONBOARDING1_STEP3,
    ONBOARDING1_STEP3_A,
    ONBOARDING1_STEP3_B
} from 'constants/routeBuilders';
import Step3aTemplateSelection from './Step3aTemplateSelection';
import Step3bTemplateCustomization from './Step3bTemplateCustomization';
import Wall from './Wall';

// Path: /flows/onboarding
const OnboardingFlow: FunctionComponent = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to={ONBOARDING1_STEP0_A} />} />
            <Route path={ONBOARDING1_STEP0_A} element={<Step0aCompanyCreation />} />
            <Route path={ONBOARDING1_STEP0_B} element={<Step0bWhatsNext />} />
            <Route path={ONBOARDING1_STEP1} element={<Step1AppSelection />} />
            <Route path={ONBOARDING1_STEP2} element={<Step2ProjectImport />} />
            <Route path={ONBOARDING1_STEP3}>
                <Route index element={<Navigate to={ONBOARDING1_STEP3_A} />} />
                <Route path={ONBOARDING1_STEP3_A} element={<Step3aTemplateSelection />} />
                <Route path={ONBOARDING1_STEP3_B} element={<Step3bTemplateCustomization />} />
            </Route>
            <Route path="wall" element={<Wall />} />
            <Route path="finalize-account" element={<FinalizeAccount />} />
        </Routes>
    );
};

export default OnboardingFlow;
