import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import { FormulaEditor, FormulaState } from 'components/Dashboarding/FormulaEditor';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { QueryOpUsageScope } from 'components/Dashboarding/DataSource/QueryOperators';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import classNames from 'classnames';
import { FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons';
import { kpFormula } from 'util/customIcons';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { useFormulaValidator } from 'components/Dashboarding/FormulaEditor/useFormulaValidator';
import { parseFormulaAsObject } from 'components/Dashboarding/FormulaEditor/useParseFormulaAsQuery';

export interface FormulaComposerState extends FormulaState {
    errorMessage?: string;
}

interface Props {
    label: ReactNode;
    className?: string;
    fact: QueryOpFact;
    onChange: (e: FormulaComposerState) => void;
    onClear: () => void;
    usageScope: QueryOpUsageScope;
    value?: string;
}

const DraggableModalDialog: FunctionComponent = props => {
    return (
        <Draggable handle=".modal-header">
            <ModalDialog {...props} />
        </Draggable>
    );
};

// Wrapper around the formula editor to handle styling and general behaviour
const WidgetFormulaComposer: FunctionComponent<Props> = ({
    label,
    fact,
    usageScope,
    value,
    onChange,
    onClear,
    className
}: Props) => {
    // Get services
    const intl = useRichIntl();

    // State
    const [formulaState, setFormulaState] = useState<FormulaComposerState | undefined>(undefined);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isFormulaModalOpen, setIsFormulaModalOpen] = useState<boolean>(false);

    // Get the formula validator
    const parsedFormulaValidator = useFormulaValidator({ fact, usageScope });

    // Formula update. Add full error message to the formula state.
    const onFormulaChange = useCallback(
        (e?: string): void => {
            const formula = e?.replace(/[\n\r]/g, '');

            // Handle case where the formula is empty
            if (formula == undefined || formula == '') {
                const formulaState: FormulaComposerState = {
                    formula,
                    isValid: false,
                    errorMessage: intl.formatMessage({ id: 'dashboarding.formula-editor.error.formula-empty' }),
                    error: { id: 'formula-empty' }
                };

                // Propagate
                setFormulaState(formulaState);
                onChange(formulaState);
            } else {
                // Parse value and indicate if the formula is valid
                try {
                    const parsedFormula = parseFormulaAsObject(formula);
                    const validationState = parsedFormulaValidator(parsedFormula);
                    const formulaState: FormulaComposerState = { ...validationState, formula, parsedFormula };

                    // Propagate
                    setFormulaState(formulaState);
                    onChange(formulaState);
                } catch (e) {
                    const formulaState: FormulaComposerState = {
                        formula,
                        isValid: false,
                        errorMessage: intl.formatMessage({ id: 'dashboarding.formula-editor.error.syntax-invalid' }),
                        error: { id: 'syntax-invalid' }
                    };

                    // Propagate
                    setFormulaState(formulaState);
                    onChange(formulaState);
                }
            }
        },
        [intl, onChange, parsedFormulaValidator]
    );

    // Help or error messages
    const helpOrErrorText = useMemo(
        () => (
            <FormText>
                {!formulaState?.formula && (
                    <span className="text-muted">
                        <RichMessage id="dashboarding.formula-editor.help-hint.empty" />
                    </span>
                )}

                {formulaState?.formula && formulaState?.errorMessage && (
                    <>
                        <span className="text-danger me-1">{formulaState?.errorMessage}</span>
                        <span className="text-muted">
                            <RichMessage id="dashboarding.formula-editor.help-hint.error" />
                        </span>
                    </>
                )}
            </FormText>
        ),
        [formulaState?.errorMessage, formulaState?.formula]
    );

    // Render
    return (
        <div
            className={classNames(className, 'w-100', 'formula-composer', { 'formula-focused': isFocused })}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            {/* Formula editor */}
            <div className="d-flex">
                <div
                    className={classNames('formula-editor flex-grow-1', { 'bg-grey-10': isFormulaModalOpen })}
                    style={{ height: isFormulaModalOpen ? '28px' : undefined }}
                >
                    <FontAwesomeIcon icon={kpFormula} style={{ zIndex: 1 }} />
                    {!isFormulaModalOpen && (
                        <FormulaEditor
                            value={value}
                            onCommit={onFormulaChange}
                            usageScope={usageScope}
                            fact={fact}
                            lineDecorationsWidth={24}
                            fixedOverflowWidgets={true}
                        />
                    )}
                    <FontAwesomeIcon icon={faTimes} className="text-muted" onClick={onClear} role="button" />
                </div>
                <FontAwesomeIcon
                    className={classNames('align-self-center cursor-pointer mx-2 text-primary', {
                        'text-grey-4': isFormulaModalOpen
                    })}
                    icon={faExpand}
                    onClick={() => setIsFormulaModalOpen(true)}
                />
            </div>

            {/* Help/error text */}
            {!isFormulaModalOpen && helpOrErrorText}

            {/* Formula editor modal */}
            {isFormulaModalOpen && (
                <Modal
                    dialogAs={DraggableModalDialog}
                    show={isFormulaModalOpen}
                    onHide={() => setIsFormulaModalOpen(false)}
                    size="lg"
                    animation={false}
                    centered
                    backdropClassName="formula-editor-backdrop"
                    dialogClassName="formula-editor-modal"
                >
                    <Modal.Header className="cursor-move">
                        <Modal.Title className="d-flex align-items-center text-uppercase">
                            <FontAwesomeIcon icon={kpFormula} className="text-dark me-3" size="lg" />
                            {label}
                        </Modal.Title>
                        <FontAwesomeIcon
                            icon={faCompress}
                            className="text-primary cursor-pointer"
                            size="lg"
                            onClick={() => setIsFormulaModalOpen(false)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="formula-editor py-3">
                            <FormulaEditor
                                value={value}
                                onCommit={onFormulaChange}
                                onEnterKeyPressed={() => setIsFormulaModalOpen(false)}
                                usageScope={usageScope}
                                fact={fact}
                                height={120}
                                wordWrap="on"
                                // Disable fixed overflow widgets because it doesn't behave well with Draggable
                                fixedOverflowWidgets={false}
                            />
                        </div>

                        {/* Help/error text */}
                        {helpOrErrorText}
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default WidgetFormulaComposer;
