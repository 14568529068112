import React, { ReactElement, useCallback } from 'react';
import { WidgetLargeCardType } from 'components/Dashboarding/Models/Widget';
import { RulesetBuilder, RulesetExplainer } from 'components/RulesetBuilder';
import { RuleGroupType } from 'react-querybuilder';
import { RichMessage } from 'components/RichMessage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiltersRatio } from 'components/Dashboarding/FiltersRatio';
import WidgetSortSelector from './WidgetSortSelector';
import { immutableSet } from 'util/ObjectOperators';

interface Props<TWidget extends WidgetLargeCardType> {
    widget: TWidget;
    onChange: (widget: TWidget) => void;
}

function ConfigureFilters<TWidget extends WidgetLargeCardType>({
    widget,
    onChange
}: Props<TWidget>): ReactElement<Props<TWidget>> {
    // Infer parameters
    const fact = widget.config.fact;
    const filters = widget.config.series[0].filters;

    // Hook invoked when the filters get updated
    const onFiltersChange = useCallback(
        (e: RuleGroupType) => onChange(immutableSet(widget, 'config.series[0].filters', e)),
        [onChange, widget]
    );

    // Hook invoked when the sorting criteria changed
    const onSortingChange = useCallback(
        dimensions => onChange(immutableSet(widget, 'config.series[0].dimensions', dimensions)),
        [onChange, widget]
    );

    // Render
    return (
        <div>
            <Row className="mb-4">
                <Col xs="8">
                    <h4 className="text-dark mb-1">
                        <span className="elevio-configure-filters-title">
                            <RichMessage id="dashboarding.widget-editor.configure-filters.heading.title" />
                        </span>
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage id="dashboarding.widget-editor.configure-filters.heading.description" />
                    </div>
                </Col>
                <Col xs="4">
                    <WidgetSortSelector
                        fact={widget.config.fact}
                        onChange={onSortingChange}
                        dimensions={widget.config.series[0].dimensions}
                    />
                </Col>
            </Row>

            {/* Configuration of filters */}
            <Row>
                <Col>
                    {/* Filters ratio */}
                    <h6 className="fw-semibold mb-4 text-dark">
                        <FiltersRatio fact={fact} filters={filters} />
                    </h6>
                    {/* Ruleset explainer */}
                    <RulesetExplainer fact={fact} ruleset={filters} />

                    {/* Query Builder */}
                    <RulesetBuilder fact={fact} scope="query" ruleset={filters} onChange={onFiltersChange} />
                </Col>
            </Row>
        </div>
    );
}

export default ConfigureFilters;
