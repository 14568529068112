import React, { FormEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/react-hooks';
import {
    AttachBillingCardResp,
    ATTACH_BILLING_CARD,
    BillingSubscriptionResp,
    BILLING_CARD_SETUP_STATUS_SUCCEEDED,
    BILLING_CARD_SETUP_STATUS_REQUIRES_ACTION
} from 'api/hq/queries/BillingSubscription';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { useSafeState } from 'util/useSafeState';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import classNames from 'classnames';

// Call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const STRIPE_PUBKEY = process.env.REACT_APP_STRIPE_PUBKEY;
const stripePromise = STRIPE_PUBKEY ? loadStripe(STRIPE_PUBKEY) : null;

const CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
        base: {
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased'
        }
    }
};

interface BtnConfig {
    saveKey?: string;
    savingKey?: string;
    saveVariant?: string;
    cancelNewKey?: string;
    cancelNewVariant?: string;
    cancelEditKey?: string;
    cancelEditVariant?: string;
}

interface Props {
    billingSubscription?: BillingSubscriptionResp;
    className?: string;
    onSave: (() => Promise<void>) | (() => void);
    onCancelNew?: () => void;
    onCancelEdit?: () => void;
    displayMode?: 'cozy' | 'compact';
    btnConfig?: BtnConfig;
    disabled?: boolean;
}

// Wrap the credit card form in a Stripe elements wrapper
// This is required to have multiple card forms on the same
// screen (e.g. one in a modal and one on the screen)
const CreditCardForm: FunctionComponent<Props> = (props: Props) => {
    return (
        <Elements stripe={stripePromise}>
            <CreditCardInnerForm {...props} />
        </Elements>
    );
};

// The actual credit card form
const CreditCardInnerForm: FunctionComponent<Props> = ({
    billingSubscription,
    className,
    onSave,
    onCancelNew,
    onCancelEdit,
    displayMode = 'cozy',
    btnConfig,
    disabled
}: Props) => {
    // Get context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Services
    const stripe = useStripe();
    const elements = useElements();
    const intl = useRichIntl();

    // State
    const [cardName, setCardName] = useState<string>(billingSubscription?.billingCard?.name || user?.firstName || '');
    const [cardEmail, setCardEmail] = useState<string>(billingSubscription?.billingCard?.email || user?.email || '');
    const [saveInProgress, setSaveInProgress] = useSafeState<boolean>(false);
    const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useSafeState<string | undefined>(undefined);

    // Mutations
    const [attachBillingCard] = useMutation<AttachBillingCardResp>(ATTACH_BILLING_CARD);

    // Existing card validation
    const existingCard = billingSubscription?.billingCard;
    const existingCardValid = existingCard?.setupStatus === BILLING_CARD_SETUP_STATUS_SUCCEEDED;
    const genericError = intl.formatMessage({ id: 'components.credit-card.form.error.INVALID' });

    // Form validation
    const isValid = !!cardName && !!cardEmail && isCardComplete;

    // Display variant
    const isCompact = displayMode == 'compact';

    // One cancel mode is supported at a time
    // - Cancel edit, if a credit card exists and is currently being edited
    // - Cancel new form, if a credit card does not exist. This is typically used in modals, with
    //  the cancel action usually closing the modal
    const cancelBtnConfig = useMemo(() => {
        return onCancelEdit && billingSubscription?.billingCard && existingCardValid
            ? { handler: onCancelEdit, key: btnConfig?.cancelEditKey, variant: btnConfig?.cancelEditVariant }
            : onCancelNew
            ? { handler: onCancelNew, key: btnConfig?.cancelNewKey, variant: btnConfig?.cancelNewVariant }
            : undefined;
    }, [
        billingSubscription?.billingCard,
        btnConfig?.cancelEditKey,
        btnConfig?.cancelEditVariant,
        btnConfig?.cancelNewKey,
        btnConfig?.cancelNewVariant,
        existingCardValid,
        onCancelEdit,
        onCancelNew
    ]);

    // Handle card submission
    // Save the card in Stripe and attach the card on the company BillingSubscription
    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>): Promise<void> => {
            // Block native form submission.
            event.preventDefault();

            // Dependencies are not loaded yet
            if (!stripe || !elements || !company) return;

            // Flag action in progress
            setSaveInProgress(true);

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) return;

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: cardName,
                    email: cardEmail
                }
            });

            // Handle error
            if (!paymentMethod?.id) {
                setErrorMessage(error?.message || genericError);
                setSaveInProgress(false);
                return;
            }

            // Update billing subscription with payment method
            const resp = await attachBillingCard({
                variables: { companyId: company.id, paymentMethodId: paymentMethod?.id }
            });
            const respContent = resp.data?.attachBillingCard;

            // Abort if credit card details could not be saved
            if (!respContent?.success) {
                const error = respContent?.errors[0]?.message;
                setErrorMessage(error || genericError);
                setSaveInProgress(false);
                return;
            }

            // Check if 3-D Secure is required
            const cardStatus = respContent?.billingSubscription.billingCard?.setupStatus;
            const cardSecret = respContent?.billingSubscription.billingCard?.setupSecret;

            // Validate 3-D secure card
            if (cardSecret && cardStatus === BILLING_CARD_SETUP_STATUS_REQUIRES_ACTION) {
                const result = await stripe.confirmCardSetup(cardSecret, { payment_method: paymentMethod?.id });

                if (result.setupIntent?.status !== 'succeeded') {
                    setErrorMessage(result.error?.message);
                    setSaveInProgress(false);
                    return;
                }
            }

            // Success
            await onSave();
            setErrorMessage(undefined);
            setSaveInProgress(false);
        },
        [
            attachBillingCard,
            cardEmail,
            cardName,
            company,
            elements,
            genericError,
            onSave,
            setErrorMessage,
            setSaveInProgress,
            stripe
        ]
    );

    // Add default error if existing card is invalid
    useEffect(() => {
        if (!errorMessage && existingCard && !existingCardValid) {
            setErrorMessage(intl.formatMessage({ id: 'components.credit-card.form.error.REQUIRES_ACTION' }));
        }
    }, [errorMessage, existingCard, existingCardValid, intl, setErrorMessage]);

    return (
        <Form onSubmit={handleSubmit} className={className}>
            {/* Error message */}
            {!saveInProgress && errorMessage && (
                <>
                    {isCompact ? (
                        <div className="text-danger mb-4" style={{ maxWidth: '500px' }}>
                            {errorMessage}
                        </div>
                    ) : (
                        <Row>
                            <Col xs="12">
                                <div className="text-danger mb-4">{errorMessage}</div>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {/* Card owner details */}
            <Row className="mb-4">
                {/* Name on card */}
                <Col md={isCompact ? 12 : 6}>
                    <Form.Group>
                        <Form.Label>
                            <RichMessage id="components.credit-card.form.name" />
                        </Form.Label>
                        <Form.Control
                            name="name"
                            value={cardName}
                            onChange={e => setCardName(e.target.value)}
                            disabled={saveInProgress || disabled}
                        />
                    </Form.Group>
                </Col>

                {/* Billing email */}
                <Col md={isCompact ? 12 : 6} className={classNames('mt-4', { 'mt-md-0': !isCompact })}>
                    <Form.Group>
                        <Form.Label>
                            <RichMessage id="components.credit-card.form.email" />
                        </Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            value={cardEmail}
                            onChange={e => setCardEmail(e.target.value)}
                            disabled={saveInProgress || disabled}
                        />
                    </Form.Group>
                </Col>
            </Row>

            {/* Card details */}
            <Row className="mb-4">
                <Col>
                    {isCompact ? (
                        <Form.Group>
                            <Form.Label>
                                <RichMessage id="components.credit-card.form.card-details" />
                            </Form.Label>
                            <div className="stripe-form-control">
                                <CardElement options={CARD_OPTIONS} onChange={e => setIsCardComplete(e.complete)} />
                            </div>
                        </Form.Group>
                    ) : (
                        <div className="stripe-form-control">
                            <CardElement options={CARD_OPTIONS} onChange={e => setIsCardComplete(e.complete)} />
                        </div>
                    )}
                </Col>
            </Row>

            {/* Submit */}
            <Row className={classNames('justify-content-end', { 'pt-4': isCompact })}>
                {/* Cancel */}
                {cancelBtnConfig && (
                    <Col md={isCompact ? 6 : 2}>
                        <Button
                            variant={cancelBtnConfig.variant || 'outline-dark'}
                            onClick={cancelBtnConfig.handler}
                            className={classNames('w-100 justify-content-center', { 'me-2': !isCompact })}
                            disabled={saveInProgress || disabled}
                        >
                            <RichMessage id={cancelBtnConfig.key || 'components.credit-card.form.cancel'} />
                        </Button>
                    </Col>
                )}

                {/* Save button */}
                <Col md={isCompact ? 6 : 2}>
                    <AsyncButton
                        type="submit"
                        variant={btnConfig?.saveVariant || 'primary'}
                        className="w-100 justify-content-center"
                        disabled={!stripe || saveInProgress || !isValid || disabled}
                        loading={saveInProgress}
                        messageProps={{ id: btnConfig?.saveKey || 'components.credit-card.form.save' }}
                        loadingMessageProps={{ id: btnConfig?.savingKey || 'components.credit-card.form.saving' }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default CreditCardForm;
