import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { WidgetItem } from '../Collections';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetFactType, WIDGET_DISPLAY_CONFIGS } from 'components/Dashboarding/Models/Widget';
import { useResourceRoles } from 'api/hq/hooks/useResourceRoles';
import { kpShared } from 'util/customIcons';
import WidgetActions from '../../../components/LibraryCard/WidgetLibraryCard/WidgetActions';
import WidgetPreviewModal from '../WidgetPreviewModal/WidgetPreviewModal';
import { useMeasure } from 'react-use';
import { useRichIntl } from 'components/RichMessage';

// Default icon size
const DEFAULT_SIZE = 40;

interface Props {
    widget: WidgetItem;
    selected?: boolean;
    onSelectChange: (dashboard: WidgetItem, selected: boolean) => void;
}

const WidgetRowItem: FunctionComponent<Props> = ({ widget, selected, onSelectChange }) => {
    // State
    const [widgetPreviewModalOpen, setWidgetPreviewModalOpen] = useState<boolean>(false);

    // Refs
    const [iconRef, { width: iconWidth }] = useMeasure<HTMLDivElement>();
    const [sharedIconRef, { width: sharedIconWidth }] = useMeasure<HTMLDivElement>();

    // Services
    const intl = useRichIntl();

    // Load the roles
    const { isShared, loading: loadingRoles } = useResourceRoles({
        resourceId: widget.id,
        resourceType: 'WIDGET'
    });

    // Hook invoked when a project is selected
    const handleSelectedChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            onSelectChange(widget, e.target.checked);
        },
        [onSelectChange, widget]
    );

    return (
        <>
            <tr>
                {/* Select/deselect dashboard */}
                <td className="text-center checkbox">
                    <Form.Check className="fs-xs" type="checkbox" checked={selected} onChange={handleSelectedChange} />
                </td>

                {/* Icon + name + shared icon */}
                <td>
                    <div className="d-flex align-items-center">
                        {/* Icon */}
                        <div
                            ref={iconRef}
                            className="flex-grow-0 rounded d-flex justify-content-center align-items-center"
                            style={{ minHeight: DEFAULT_SIZE, minWidth: DEFAULT_SIZE }}
                            title={intl.formatMessage({
                                id: `dashboarding.widget-editor.widget-type.${widget.configType}`
                            })}
                        >
                            <FontAwesomeIcon icon={WIDGET_DISPLAY_CONFIGS[widget.configType].icon} size="lg" />
                        </div>

                        <div
                            className="flex-grow-1 d-flex align-items-center pe-4"
                            style={{ maxWidth: `calc(100% - ${iconWidth}px)` }}
                        >
                            {/* Name */}
                            <Button
                                variant="link"
                                onClick={() => setWidgetPreviewModalOpen(true)}
                                title={widget.name}
                                className="text-dark fw-semibold p-0 m-2"
                                style={{ maxWidth: `calc(100% - ${sharedIconWidth}px)` }}
                            >
                                <span className="text-truncate">{widget.name}</span>
                            </Button>

                            {/* Shared */}
                            <div ref={sharedIconRef} className="me-2">
                                {loadingRoles ? (
                                    <Spinner animation="border" size="sm" />
                                ) : isShared ? (
                                    <FontAwesomeIcon icon={kpShared} size="sm" />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </td>

                {/* Owner */}
                <td className="text-center d-lg-table-cell d-none">
                    <span>{widget.user?.firstName}</span>
                </td>

                {/* Last modified */}
                <td className="text-center d-lg-table-cell d-none">
                    <Moment format="ll">{widget.updatedAt}</Moment>
                </td>

                {/* More button */}
                <td>
                    <WidgetActions variant="dropdown" widget={widget as WidgetFactType} />
                </td>
            </tr>
            <WidgetPreviewModal
                isOpen={widgetPreviewModalOpen}
                widget={widget as WidgetFactType}
                onHide={() => setWidgetPreviewModalOpen(false)}
            />
        </>
    );
};

export default WidgetRowItem;
