import { useDashboards } from 'api/hq/hooks/useDashboards';
import FullPageLoader from 'components/Design/FullPageLoader';
import { dashboardViewPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Used as dashboard root path. Redirect to first available
// dashboard or display a blank page otherwise.
const NoDashboardView: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();

    // Load the dashboards
    const { normalized: dashboards, loading: dashboardsLoading } = useDashboards({ pinned: true });
    const firstDashboard = dashboards[0];

    // Redirect to first available dashboard (if any)
    useEffect(() => {
        if (firstDashboard) {
            navigate(dashboardViewPath({ dashboardId: firstDashboard.id }));
        }
    }, [firstDashboard, navigate]);

    if (dashboardsLoading) {
        // Wait until dashboards are loaded
        return <FullPageLoader />;
    } else {
        // Blank page
        return <div></div>;
    }
};

export default NoDashboardView;
