import MarkdownEditor from 'components/Input/MarkdownEditor';
import { RichMessage } from 'components/RichMessage';
import { MODAL_SIZE_DEFAULT, MODAL_SIZE_LG } from 'constants/defaultValues';
import React, { FunctionComponent, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

const STRING_LENGTH_THRESHOLD = 128;
const LINES_COUNT_THRESHOLD = 10;
const MODAL_MIN_HEIGHT = 80;

interface Props {
    isOpen: boolean;
    onHide: () => void;
    value: { field: string; columnName: string; value: string } | undefined;
}
const CellDetailsModal: FunctionComponent<Props> = ({ isOpen, onHide, value }) => {
    // Determine modal size from content
    const modalSize = useMemo(() => {
        // Lines
        const lines = value?.value?.split('\n') || [];

        // Retrieve the longest line
        const longestString = [...lines].sort((a, b) => b.length - a.length)[0]?.length;

        // Compute width from longest line
        const modalWidth = longestString > STRING_LENGTH_THRESHOLD ? MODAL_SIZE_LG : MODAL_SIZE_DEFAULT;

        // Compute height from lines count
        const modalHeight = lines.length > LINES_COUNT_THRESHOLD ? MODAL_SIZE_LG : MODAL_SIZE_DEFAULT;

        return Math.max(modalWidth, modalHeight);
    }, [value?.value]);

    // For `body` and `message` fields, we expect having to display big contents, in a modal of XL size
    const isXLModal = useMemo(() => value?.field && ['body', 'message'].includes(value.field), [value?.field]);

    return (
        <Modal
            show={isOpen}
            onHide={onHide}
            dialogClassName={isXLModal ? 'cell-tooltip-dialog' : undefined}
            size={!isXLModal ? 'lg' : undefined}
            fullscreen="lg-down"
        >
            <div style={{ width: isXLModal ? modalSize : undefined }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <RichMessage id="dashboarding.list-widget.details-modal.title" /> {value?.columnName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 pb-3" style={{ height: isXLModal ? modalSize : MODAL_MIN_HEIGHT }}>
                    <MarkdownEditor preview="preview" value={value?.value} />
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default CellDetailsModal;
