import React, { FunctionComponent, ReactNodeArray, useState } from 'react';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';

export const LIBRARY_CARD_DEFAULT_WIDTH = 305;
export const LIBRARY_CARD_DEFAULT_HEIGHT = 325;
export const LIBRARY_CARD_MARGIN = 8;

interface Props {
    width?: number;
    height?: number;
    children: ReactNodeArray;
}

const LibraryCard: FunctionComponent<Props> = ({ width, height, children }: Props) => {
    // State
    const [isHover, setIsHover] = useState<boolean>(false);

    // Render
    return (
        <Card
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={classNames('border rounded', { 'border-dark': isHover })}
            style={{
                maxWidth: width || LIBRARY_CARD_DEFAULT_WIDTH,
                minWidth: width || LIBRARY_CARD_DEFAULT_WIDTH,
                maxHeight: height || LIBRARY_CARD_DEFAULT_HEIGHT,
                minHeight: height || LIBRARY_CARD_DEFAULT_HEIGHT,
                margin: LIBRARY_CARD_MARGIN
            }}
        >
            {/* Header */}
            {children[0]}

            {/* Body */}
            <Card.Body className="py-4">{children[1]}</Card.Body>

            {/* Main action button */}
            <div
                className={classNames('position-absolute', { 'd-none': !isHover })}
                style={{ zIndex: 1, bottom: 0, left: 0, right: 0 }}
            >
                {children[2]}
            </div>

            {/* Actions */}
            <div
                className={classNames('position-absolute', { 'd-none': !isHover })}
                style={{ zIndex: 1, top: '0.5rem', right: '0.4rem' }}
            >
                {children[3]}
            </div>
        </Card>
    );
};

export default LibraryCard;
