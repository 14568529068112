import { useIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { RICH_TAGS } from './RichMessageConfig';
import { ReactElement, useCallback, useMemo } from 'react';

interface RichIntlShape extends IntlShape {
    safeFormatMessage(
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType | ReactElement>
    ): string | React.ReactNodeArray;
    formatRichMessage(
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType | ReactElement>
    ): string | React.ReactNodeArray;
    safeFormatRichMessage(
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType | ReactElement>
    ): string | React.ReactNodeArray;
}

// Wrapper hook that adds a formatRichMessage. See RichMessage for an explanation.
export const useRichIntl = (): RichIntlShape => {
    const intl = useIntl();

    // Safe Format message. Return an empty string when the key is not defined.
    // function safeFormatMessage(descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>): string;
    const safeFormatMessage = useCallback(
        (
            descriptor: MessageDescriptor,
            values?: Record<string, PrimitiveType | ReactElement>
        ): string | React.ReactNodeArray => {
            if (descriptor.id && intl.messages[descriptor.id]) {
                return intl.formatMessage(descriptor, values);
            } else {
                return '';
            }
        },
        [intl]
    );

    // Format message with custom HTML tags
    const formatRichMessage = useCallback(
        (
            descriptor: MessageDescriptor,
            values?: Record<string, PrimitiveType | ReactElement>
        ): string | React.ReactNodeArray => {
            return intl.formatMessage(descriptor, { ...RICH_TAGS, ...values });
        },
        [intl]
    );

    // Safe version
    const safeFormatRichMessage = useCallback(
        (
            descriptor: MessageDescriptor,
            values?: Record<string, PrimitiveType | ReactElement>
        ): string | React.ReactNodeArray => {
            if (descriptor.id && intl.messages[descriptor.id]) {
                return formatRichMessage(descriptor, values);
            } else {
                return '';
            }
        },
        [formatRichMessage, intl.messages]
    );

    return useMemo(() => {
        return { ...intl, formatRichMessage, safeFormatMessage, safeFormatRichMessage };
    }, [formatRichMessage, intl, safeFormatMessage, safeFormatRichMessage]);
};
