import { DropdownOption } from 'components/List/FilteringDropdown';
import SearchBar from 'components/List/SearchBar';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox/SelectAllCheckbox';
import DropdownItemCheckbox from './DropdownItemCheck';
import DropdownMultiselectMenu from './DropdownMultiselectMenu';
import DropdownMultiselectToggle from './DropdownMultiselectToggle';

interface Props {
    className?: string;
    placeholder?: string;
    placeholderId?: string;
    options: DropdownOption[];
    selected?: DropdownOption[];
    onChange: (selectedOptions: DropdownOption[]) => void;
    searchBar?: boolean;
    selectAll?: boolean;
}

export const DropdownMultiselect: FunctionComponent<Props> = ({
    className,
    placeholder,
    placeholderId,
    options,
    selected,
    onChange,
    searchBar,
    selectAll
}) => {
    // Services
    const intl = useRichIntl();

    // State
    const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | undefined>('');

    // Filtered options
    const filteredOptions = useMemo(() => {
        if (searchBar && searchTerm)
            return options.filter(e => e.name.toLowerCase().includes(searchTerm.toLowerCase()));
        return options;
    }, [searchBar, searchTerm, options]);

    // Hook invoked when an item's selection is toggled
    const handleToggleItem = useCallback(
        (option: DropdownOption) => {
            // Get the item index
            const itemIndex = selectedOptions.findIndex(e => e.id == option.id);
            const updatedOptions = [...selectedOptions];

            // If the item was not selected
            if (itemIndex == -1) {
                // Push it to the selected options
                updatedOptions.push(option);
            } else {
                // Else remove it from the selected options
                updatedOptions.splice(itemIndex, 1);
            }

            // Update state
            setSelectedOptions(updatedOptions);

            // Notify parent
            onChange(updatedOptions);
        },
        [selectedOptions, onChange]
    );

    // Format the selected options
    // We display the first selected option's name, and '+ 1 other', '+ 2 others' etc..
    const formatValue = useCallback((): string | number | string[] | undefined => {
        const firstSelectedOptionLabel = selectedOptions[0]?.name ?? '';
        const othersCount = selectedOptions.length - 1;
        const othersLabel =
            selectedOptions.length > 1
                ? `, ${intl.formatRichMessage(
                      { id: 'components.dropdown-multiselect.other' },
                      {
                          count: othersCount
                      }
                  )}`
                : '';

        return `${firstSelectedOptionLabel}${othersLabel}`;
    }, [intl, selectedOptions]);

    // Hook invoked when users click the "Select All" checkbox
    const onSelectAllChange = useCallback(
        (selectAll: boolean): void => {
            const updatedOptions = selectAll ? filteredOptions : [];

            // Update state
            setSelectedOptions(updatedOptions);

            // Notify parent
            onChange(updatedOptions);
        },
        [filteredOptions, onChange]
    );

    // Resync
    useEffect(() => {
        if (selected) setSelectedOptions(selected);
    }, [selected]);

    return (
        <Dropdown className="w-100">
            <Dropdown.Toggle as={DropdownMultiselectToggle} className={className}>
                <Form.Control
                    autoComplete="off"
                    placeholder={
                        placeholder ? placeholder : placeholderId ? (intl.messages[placeholderId] as string) : ''
                    }
                    value={formatValue()}
                    className="border-0 px-0 cursor-pointer text-dark"
                    disabled
                />
            </Dropdown.Toggle>
            <Dropdown.Menu as={DropdownMultiselectMenu}>
                {/* Search bar */}
                {searchBar && (
                    <SearchBar
                        className="mx-3 my-2"
                        debounceTime={DEFAULT_DEBOUNCE_TIME}
                        value={searchTerm}
                        onChange={setSearchTerm}
                        placeholderId="components.dropdown-multiselect.search-bar.placeholder"
                    />
                )}

                {/* Select all checkbox */}
                {selectAll && (
                    <>
                        <div className="d-flex w-100 px-3 py-2 align-items-center">
                            <SelectAllCheckbox
                                className="me-2"
                                selectedCount={selectedOptions.length}
                                totalCount={filteredOptions.length}
                                onSelectChange={onSelectAllChange}
                            />
                            <span className="text-dark">All</span>
                        </div>

                        <hr className="my-1" />
                    </>
                )}

                {/* Options */}
                <div className="overflow-y-auto" style={{ maxHeight: 400 }}>
                    {filteredOptions?.length ? (
                        filteredOptions.map(e => (
                            <DropdownItemCheckbox
                                key={e.id}
                                selected={selectedOptions?.findIndex(option => e.id == option.id) != -1}
                                img={e.img}
                                label={e.name}
                                onToggle={() => handleToggleItem(e)}
                            />
                        ))
                    ) : (
                        <div className="px-3 py-2 text-semi-muted">
                            <RichMessage id="components.dropdown-multiselect.filtered-options.no-results" />
                        </div>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
