import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { WidgetFactTemplate } from 'components/Dashboarding/Models/WidgetTemplates';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { WidgetLibrary } from './WidgetLibrary/WidgetLibrary';
import { CSSTransition } from 'react-transition-group';
import { BuilderSelection } from './BuilderSelection';

interface Props {
    dashboardId: string;
    isOpen: boolean;
    onHide: () => void;
    onWidgetAdded: () => Promise<void>;
}

const STEPS = ['LIBRARY', 'CONFIGURATION'];

const AddWidgetModal: FunctionComponent<Props> = ({ dashboardId, isOpen, onHide, onWidgetAdded }) => {
    // State
    const [stepId, setStepId] = useState<number>(0);
    const [widget, setWidget] = useState<WidgetFactTemplate>();

    // The modal title id, depending on the current step
    const modalTitleId = useMemo(() => {
        switch (STEPS[stepId]) {
            case 'LIBRARY':
                return 'dashboarding.add-insight-modal.title';
            case 'CONFIGURATION':
                return 'dashboarding.add-insight-modal.template-title';
            default:
                return '';
        }
    }, [stepId]);

    // The modal size, depending on the current step
    const modalSize = useMemo(() => {
        switch (STEPS[stepId]) {
            case 'LIBRARY':
                return 'xl';
            case 'CONFIGURATION':
                return 'lg';
            default:
                return 'xl';
        }
    }, [stepId]);

    // Hook invoked when a template is selected
    const handleTemplateSelected = useCallback((widget: WidgetFactTemplate) => {
        setWidget(widget);
        setStepId(1);
    }, []);

    return (
        <Modal
            show={isOpen}
            onHide={onHide}
            size={modalSize}
            fullscreen={`${modalSize}-down`}
            dialogClassName="modal-dialog-scrollable"
        >
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1 d-flex align-items-center justify-content-between">
                    <RichMessage id={modalTitleId} />
                    {stepId > 0 && (
                        <div className="modal-btn-back cursor-pointer" onClick={() => setStepId(0)}>
                            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                        </div>
                    )}
                </Modal.Title>
            </Modal.Header>

            {/* Categories & templates */}
            <Modal.Body
                className={classNames('p-0 bg-grey-11 rounded-bottom min-h-0 flex-grow-1 overflow-hidden', {
                    'vh-100': stepId == 0
                })}
            >
                <CSSTransition
                    in={stepId == 0}
                    timeout={500}
                    classNames={{
                        enterActive: 'fadeIn'
                    }}
                    className={classNames({ 'd-none': stepId != 0 })}
                    unmountOnExit
                >
                    <WidgetLibrary
                        dashboardId={dashboardId}
                        onWidgetAdded={onWidgetAdded}
                        onTemplateSelected={handleTemplateSelected}
                    />
                </CSSTransition>
                <CSSTransition
                    in={stepId == 1}
                    timeout={500}
                    classNames={{
                        enterActive: 'fadeIn'
                    }}
                    className={classNames({ 'd-none': stepId != 1 })}
                    unmountOnExit
                >
                    <BuilderSelection
                        widget={widget}
                        dashboardId={dashboardId}
                        onWidgetAdded={async () => {
                            await onWidgetAdded();
                            setStepId(0);
                        }}
                    />
                </CSSTransition>
            </Modal.Body>
        </Modal>
    );
};

export default AddWidgetModal;
