import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardWithLayout } from 'api/hq/queries/Dashboard';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import useAddWidgetToDashboard from 'components/Dashboarding/Hooks/useAddWidgetToDashboard';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { Toast, toastQueryError } from 'components/Toast';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

interface WidgetInstanceButtonProps {
    dashboard: DashboardWithLayout;
    widget: WidgetFactType & {
        dashboards: { id: string; name: string }[];
    };
    refetch: () => Promise<void>;
}

const WidgetInstanceButton: FunctionComponent<WidgetInstanceButtonProps> = ({
    dashboard,
    widget,
    refetch
}: WidgetInstanceButtonProps) => {
    // State
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);

    // Services
    const addWidgetToDashboard = useAddWidgetToDashboard({ dashboard });

    // Hook invoked to add a widget to a dashboard
    const handleAddWidgetToDashboard = useCallback(async (): Promise<void> => {
        // Set action in progress state
        setActionInProgress(true);

        // Add widget to the dashboard
        const resp = await addWidgetToDashboard({ widget });

        // Abort save if the widget could not be added to the dashboard
        if (resp && !resp.success) {
            toastQueryError({ namespace: 'add-widget-to-dashboard' });
            return;
        }

        // Await parent handles this event
        await refetch();

        // Reset action in progress state
        setActionInProgress(false);

        // Success toast
        toast.custom(t => (
            <Toast t={t} type="success">
                <RichMessage id={`dashboarding.add-insight-modal.add-to-dashboard-success`} />
            </Toast>
        ));
    }, [addWidgetToDashboard, refetch, widget]);

    // Has the widget already been added to the dashboard
    const alreadyAdded = widget.dashboards.map(d => d.id).includes(dashboard.id);

    if (alreadyAdded) {
        // Disabled already added button
        return (
            <Button variant="dark" disabled className="rounded-0 rounded-bottom justify-content-center w-100">
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                <RichMessage id="components.library-card.insight-already-added-to-dashboard" />
            </Button>
        );
    } else {
        // Add widget to dashboard button
        return (
            <AsyncButton
                variant="dark"
                onClick={handleAddWidgetToDashboard}
                disabled={actionInProgress}
                className="rounded-0 rounded-bottom justify-content-center w-100"
                loading={actionInProgress}
                messageProps={{ id: 'components.library-card.add-insight-to-dashboard' }}
                loadingMessageProps={{ id: 'components.library-card.adding-insight-to-dashboard' }}
            />
        );
    }
};

export default WidgetInstanceButton;
