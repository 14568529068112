import { combineReducers } from 'redux';
import settings, { SettingsState } from './settings/reducer';
import authUser, { UserState } from './auth/reducer';

export interface ReduxState {
    authUser: UserState;
    settings: SettingsState;
}

const reducers = combineReducers({
    settings,
    authUser
});

export default reducers;
