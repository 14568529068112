import { ReportDimension, ReportNamedFunction, useGenericReport } from 'api/viz/hooks/useGenericReport';
import { DIMENSION_IDENTIFIER, METRIC_IDENTIFIER } from 'constants/defaultValues';
import { Coordinates } from 'contexts/DrilldownContext';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { QueryOpFact } from '../DataSource';
import { QUERY_OP_FORMULA_FUNCTION, QUERY_OP_NATIVE_FORMATTER } from '../DataSource/QueryOperators';
import { useParseFormulaAsQuery } from '../FormulaEditor/useParseFormulaAsQuery';
import { ChartSeriesType } from '../Models/Widget';
import { useQueryOpFields } from './useQueryOpFields';

// Default number of records fetched when no limit is specified
export const DEFAULT_QUERY_LIMIT = 500;

interface Props {
    fact: QueryOpFact;
    series?: ChartSeriesType;
    offset?: number;
    skip?: boolean;
    pickedCoordinates?: Coordinates;
}

interface Results {
    records: Record<string, string>[];
    totalRecords?: number;
    initialLoading: boolean;
    reloading: boolean;
}

// Fetch widget data according to the widget configuration
// The records will be returned using indexed names with:
// - Dimension: 'd' as prefix
// - Metric: 'm' as prefix
//
// E.g.
// [
//     { d0: 0, m0: 10, m1: 12 },
//     { d0: 1, m0: 11, m1: 22 },
//     { d0: 1, m0: 14, m1: 24 }
// ]
export function useFetchSeries({ fact, series, skip, offset, pickedCoordinates }: Props): Results {
    // Redux state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Extract widget data
    const metrics = useMemo(() => series?.metrics || [], [series?.metrics]);
    const dimensions = useMemo(() => series?.dimensions || [], [series?.dimensions]);
    const filters = series?.filters;
    const limit = series?.limit || DEFAULT_QUERY_LIMIT;

    // Get fact-specific fields
    const { queryOpFields: factMetricFields } = useQueryOpFields({ fact, usageScope: 'metric' });
    const { queryOpFields: factDimensionFields } = useQueryOpFields({ fact, usageScope: 'dimension' });

    // Get fact-specific formula parser
    const parseFormulaAsQuery = useParseFormulaAsQuery({ fact });

    // Format defined metrics into query compatible metrics
    const queryMetrics = useMemo(() => {
        return metrics
            .map((metric, i) => {
                const metricField = factMetricFields.find(e => e.id === metric?.ref);

                if (metric.function === QUERY_OP_FORMULA_FUNCTION) {
                    try {
                        const parsedFormula = parseFormulaAsQuery(metric.ref);
                        return parsedFormula
                            ? ({
                                  label: `${METRIC_IDENTIFIER}${i}`,
                                  function: metric.function,
                                  args: [parsedFormula],
                                  sort: metric.sort
                              } as ReportNamedFunction)
                            : undefined;
                    } catch {
                        return undefined;
                    }
                } else if (metric && metricField) {
                    return {
                        label: `${METRIC_IDENTIFIER}${i}`,
                        function: metric.function,
                        args: [{ field: metricField?.name }],
                        sort: metric.sort
                    } as ReportNamedFunction;
                } else {
                    return undefined;
                }
            })
            .filter((e): e is ReportNamedFunction => !!e);
    }, [factMetricFields, metrics, parseFormulaAsQuery]);

    // Dimensions
    const queryDimensions = useMemo(() => {
        return dimensions
            .map((dimension, i) => {
                const dimensionField = factDimensionFields.find(e => e.id === dimension?.ref);
                const dimensionFunction =
                    dimension?.function && dimension.function != QUERY_OP_NATIVE_FORMATTER
                        ? dimension.function
                        : undefined;

                if (dimensionFunction == QUERY_OP_FORMULA_FUNCTION && dimension) {
                    // The dimension is a formula
                    try {
                        const parsedFormula = parseFormulaAsQuery(dimension.ref);
                        return parsedFormula
                            ? ({
                                  label: `${DIMENSION_IDENTIFIER}${i}`,
                                  function: dimensionFunction,
                                  args: [parsedFormula],
                                  sort: dimension.sort
                              } as ReportNamedFunction)
                            : undefined;
                    } catch {
                        return undefined;
                    }
                } else if (dimensionFunction && dimensionField) {
                    // The dimension uses a prebuilt function
                    return {
                        label: `${DIMENSION_IDENTIFIER}${i}`,
                        function: dimensionFunction,
                        args: [{ field: dimensionField.name }],
                        sort: dimension.sort
                    } as ReportNamedFunction;
                } else if (dimensionField) {
                    // The dimension field is used as-is
                    return {
                        label: `${DIMENSION_IDENTIFIER}${i}`,
                        field: dimensionField.name,
                        sort: dimension.sort
                    } as ReportDimension;
                } else {
                    return undefined;
                }
            })
            .filter((e): e is ReportDimension | ReportNamedFunction => !!e);
    }, [dimensions, factDimensionFields, parseFormulaAsQuery]);

    // Perform query
    const { normalized: records, data, loading, refreshing, initialLoading } = useGenericReport({
        dimensions: queryDimensions,
        fact,
        filters,
        limit,
        offset,
        metrics: queryMetrics,
        userId: user?.id,
        companyId: company?.id,
        pickedCoordinates,
        skip: skip || (metrics.length === 0 && dimensions.length === 0)
    });
    const reloading = loading || refreshing;

    // Return results
    return {
        records,
        totalRecords: data?.report.pageInfo?.rs?.total_count,
        initialLoading,
        reloading
    };
}
