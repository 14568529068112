import React, { FunctionComponent, useCallback } from 'react';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { Stepper } from 'components/Stepper';
import {
    dashboardViewPath,
    WIDGET_ASSISTANT_PATH,
    WIDGET_ASSISTANT_STEPS,
    WIDGET_TMPL_ID_KEY
} from 'constants/routeBuilders';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';
import { useTemplateAssistant } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import { ASSISTANT_WIDGET_QNS_KEY, ASSISTANT_WIDGET_TMPL_KEY } from 'constants/localStorageKeys';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { useLocalStorage } from 'react-use';

const Header: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();

    // Extract params
    const params = useParams() as { dashboardId: string };
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(WIDGET_TMPL_ID_KEY);
    const [, , clearWidget] = useLocalStorage<WidgetFactType>(ASSISTANT_WIDGET_TMPL_KEY);
    const dashboardId = params.dashboardId;

    // Template customizer hook
    const { clearQuestions } = useTemplateAssistant(ASSISTANT_WIDGET_QNS_KEY, templateId, 'WIDGET', 2);

    // Hook invoked on cancel click
    const handleCancelClick = useCallback(() => {
        // Clear widget from local storage
        clearWidget();

        // Clear questions from local storage
        clearQuestions();

        // Redirect to dashboard
        navigate(dashboardViewPath({ dashboardId }));
    }, [clearQuestions, clearWidget, dashboardId, navigate]);

    return (
        <div className="splitscreen-flow-header d-flex align-items-center p-4">
            {/* Logo */}
            <div className="flex-grow-1 flex-basis-0">
                <KeypupLogoFull />
            </div>

            {/* Stepper */}
            <Stepper
                basePath={WIDGET_ASSISTANT_PATH}
                steps={WIDGET_ASSISTANT_STEPS}
                messageId="dashboarding.widget-assistant.header"
            />

            {/* Cancel button */}
            <div className="flex-grow-1 flex-basis-0">
                <Button variant="link" className="justify-content-end p-0 w-100" onClick={handleCancelClick}>
                    <RichMessage id="dashboarding.widget-assistant.header.cancel" />
                </Button>
            </div>
        </div>
    );
};

export default Header;
