import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { GET_CURRENT_API_USER, GetCurrentApiUserResp, CurrentUserMembership } from 'api/hq/queries/CurrentApiUser';
import { COMPANY_DISABLED_PATH } from 'constants/routeBuilders';
import { MEMBERSHIP_DISABLED_STATUS, MEMBERSHIP_ENABLED_STATUS } from 'constants/defaultValues';
import { ErrorPage } from 'components/ErrorManagement';
import { LOGIN_USER_SUCCESS } from 'constants/actionTypes';
import { Outlet, useNavigate } from 'react-router-dom';
import { useOnboardingNavigate } from 'util/useOnboardingNavigate';
import FullPageLoader from 'components/Design/FullPageLoader';
import { CURRENT_COMPANY_ID_KEY } from 'constants/localStorageKeys';

// How often to ping user for session status (30 seconds)
const USER_POLL_INTERVAL = 30000;

interface Props {
    companyRequired?: boolean;
}

//
// Wrapper used in routes to verify and login the current user.
//
// NOTE: There is no need to handle the case where the API response is 401 (unauthenticated user)
// as this is directly handled by the low level client. See clients/AuthErrorLink.ts
//
const AuthGuard: FunctionComponent<Props> = ({ companyRequired }: Props) => {
    // Services
    const navigate = useNavigate();
    const navigateToOnboarding = useOnboardingNavigate();

    // Get redux dispatch
    const dispatch = useDispatch();

    // NOTE: the long polling mechanism acts as a session keep-alive. If the user JWT expires (after 24h) then
    // the API returns a 401 response which automatically triggers re-authentication (see clients/AuthErrorLink.ts)
    const { data, loading, error } = useQuery<GetCurrentApiUserResp>(GET_CURRENT_API_USER, {
        pollInterval: USER_POLL_INTERVAL
    });
    const apiUser = data?.currentApiUser;

    // Get current company ID
    // If no company is currently selected then we try to select the first active company membership
    // and if not available we revert to the first membership.
    const currentMembership = useMemo(() => {
        const memberships = apiUser?.user?.memberships.nodes || [];
        const selectedCompanyId = localStorage.getItem(CURRENT_COMPANY_ID_KEY) || memberships.map(e => e.company.id)[0];
        return (memberships.find(e => e.company.id === selectedCompanyId) ||
            memberships.find(e => e.status === MEMBERSHIP_ENABLED_STATUS) ||
            memberships[0]) as CurrentUserMembership | undefined;
    }, [apiUser?.user?.memberships.nodes]);
    const currentCompany = currentMembership?.company;

    // Flow state
    const isOnboardingRequired = companyRequired && !currentCompany;
    const isAccessRevocationPageRequired = companyRequired && currentMembership?.status == MEMBERSHIP_DISABLED_STATUS;
    const isRedirectRequired = isOnboardingRequired || isAccessRevocationPageRequired;

    // Invoke login success action
    // Note that if the user is not logged in then the API will return a 401 which
    // will in turn trigger the login process. See: clients/AuthErrorLink.ts
    useEffect(() => {
        if (!apiUser) return;

        // Dispatch login event
        dispatch({ type: LOGIN_USER_SUCCESS, payload: { ...apiUser, currentCompanyId: currentCompany?.id } });
    }, [apiUser, currentCompany?.id, dispatch]);

    // Redirect user to onboarding path if the user has no company and a company is required
    // for the current page
    useEffect(() => {
        if (loading || error) return;
        if (!isOnboardingRequired) return;

        // Redirect user
        navigateToOnboarding();
    }, [error, isOnboardingRequired, loading, navigateToOnboarding]);

    // Redirect user to wall page if his access to the company is disabled
    useEffect(() => {
        if (loading || error) return;
        if (!currentMembership || !isAccessRevocationPageRequired) return;

        // Redirect to company disabled path
        const disabledPath = [COMPANY_DISABLED_PATH, currentMembership.company.id].join('/');
        navigate(disabledPath);
    }, [currentMembership, error, isAccessRevocationPageRequired, loading, navigate]);

    // Loading state
    if (loading) return <FullPageLoader />;

    // Abort on error
    if (error) return <ErrorPage error={{ code: 'cannot-load-account' }} />;

    // Abort if redirect is required
    if (isRedirectRequired) return null;

    // Render route child
    return <Outlet />;
};

export default AuthGuard;
