import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { NavLink, Path, To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCommentDots, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RichMessage, useRichIntl } from 'components/RichMessage';

import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { COLLECTIONS_ROOT_PATH, dashboardViewPath, NEW_DASHBOARD_MODAL_OPEN_PARAM } from 'constants/routeBuilders';
import SettingsMenu from './SettingsMenu';
import classNames from 'classnames';
import NewDashboardModal from 'components/Dashboarding/NewDashboardModal';
import { useDashboards } from 'api/hq/hooks/useDashboards';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import DashboardLinkItem from './DashboardLinkItem';
import { Spinner } from 'react-bootstrap';
import { kpCollections, kpPin } from 'util/customIcons';
import { ELEVIO_FEEDBACK_ID, ElevioClient } from 'util/useElevio';

interface Props {
    className?: string;
    expanded?: boolean;
    toggleExpand?: () => void;
}

// The Sidebar menu is displayed in tablet/desktop version.
// For the mobile menu, see TopNav.
const Sidebar: FunctionComponent<Props> = ({ className, expanded, toggleExpand }: Props) => {
    // Services
    const location = useLocation();
    const navigate = useNavigate();
    const intl = useRichIntl();

    // Search params
    const [searchParams, setSearchParams] = useSearchParams();
    const newDashboardModalOpenSearchParam = useMemo(
        () =>
            searchParams?.get(NEW_DASHBOARD_MODAL_OPEN_PARAM)
                ? searchParams?.get(NEW_DASHBOARD_MODAL_OPEN_PARAM) == 'true'
                : false,
        [searchParams]
    );

    // State
    const [isNewDashboardModalOpen, setIsNewDashboardModalOpen] = useState<boolean>(newDashboardModalOpenSearchParam);

    // Load the dashboards
    const { normalized: dashboards, loading: dashboardsLoading, refetch } = useDashboards({
        pinned: true
    });

    // Return true if the top menu passed as parameter is currently active
    const isMenuActive = (path: To): boolean => {
        return (
            location.pathname.startsWith(path as string) ||
            location.pathname.startsWith((path as Partial<Path>).pathname || '')
        );
    };

    // Hook invoked when opening/closing the new dashboard modal
    const updateNewDashboardModalState = useCallback(
        (isOpen: boolean) => {
            // Update the state
            setIsNewDashboardModalOpen(isOpen);

            // Create new search params from the current ones
            const newParams = new URLSearchParams(searchParams);

            // Overwrite the `new-dashboard-modal-open` search param
            if (isOpen) {
                newParams.set(NEW_DASHBOARD_MODAL_OPEN_PARAM, 'true');
            } else {
                newParams.delete(NEW_DASHBOARD_MODAL_OPEN_PARAM);
            }
            // Set search params state
            setSearchParams(newParams);
        },
        [searchParams, setSearchParams]
    );

    // Navigate to the first pinned dashboard or to collections page
    const handleLogoClick = useCallback(
        () =>
            navigate(dashboards.length ? dashboardViewPath({ dashboardId: dashboards[0].id }) : COLLECTIONS_ROOT_PATH),
        [dashboards, navigate]
    );

    return (
        <>
            <div className={classNames('sidebar', className)}>
                <div className={classNames('main-menu', { expanded })}>
                    <Nav className="list-unstyled h-100 flex-column">
                        {/* Logo */}
                        <Nav.Item className="menu-top-logo-collapsed">
                            <KeypupLogo height={32} className="cursor-pointer" onClick={handleLogoClick} />

                            {/* Expand sidebar */}
                            <div className="toggle-button" role="button" onClick={toggleExpand}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </Nav.Item>
                        <Nav.Item className="menu-top-logo">
                            <KeypupLogoFull
                                height={32}
                                fill="white"
                                className="cursor-pointer"
                                onClick={handleLogoClick}
                            />

                            {/* Collapse sidebar */}
                            <div className="toggle-button" role="button" onClick={toggleExpand}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                        </Nav.Item>

                        <Nav.Item className="menu-separator" />

                        {/* Scrollable section */}
                        <div className="overflow-y-auto d-flex flex-column w-100 flex-1">
                            {/* New dashboard button */}
                            {expanded && (
                                <div className="p-4 d-flex justify-content-center align-items-center">
                                    <Button
                                        className="w-100 d-flex justify-content-center"
                                        variant="outline-light"
                                        onClick={() => updateNewDashboardModalState(true)}
                                    >
                                        <FontAwesomeIcon className="me-2" icon={faPlus} />
                                        <RichMessage id="menu.new-dashboard" />
                                    </Button>
                                </div>
                            )}

                            {/* Pinned dashboard */}
                            <Nav.Item className="menu-header-item">
                                <div
                                    className="menu-icon-collapsed menu-plus"
                                    role="button"
                                    onClick={() => updateNewDashboardModalState(true)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                                <div className="menu-icon">
                                    <FontAwesomeIcon icon={kpPin} />
                                </div>
                                <div className="menu-label opacity-50">
                                    <RichMessage id="menu.pinned-dashboards" />
                                </div>
                            </Nav.Item>

                            {/* List of dashboards */}
                            {dashboardsLoading ? (
                                <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" variant="white" className="mx-auto mt-2" />
                                </div>
                            ) : dashboards.length > 0 ? (
                                dashboards.map(e => (
                                    <Nav.Item
                                        key={e.id}
                                        className={classNames('selectable-item w-100', {
                                            active: isMenuActive(dashboardViewPath({ dashboardId: e.id }))
                                        })}
                                        title={e.name}
                                    >
                                        <DashboardLinkItem dashboard={e} onUnpin={refetch} />
                                    </Nav.Item>
                                ))
                            ) : expanded ? (
                                // No dashboards
                                <div className="mx-5 text-light">
                                    <small className="mx-2">
                                        <span>
                                            <RichMessage id="menu.no-pinned-dashboards" />
                                            <NavLink to={COLLECTIONS_ROOT_PATH} className="text-light">
                                                <RichMessage id="menu.see-all-dashboards" />
                                            </NavLink>
                                        </span>
                                    </small>
                                </div>
                            ) : null}
                        </div>

                        {/* Bottom section */}
                        <Nav.Item className="menu-separator mt-auto" />

                        <div
                            className={classNames(
                                'px-4 py-3 w-100 text-white d-grid gap-3',
                                !expanded && 'justify-content-center'
                            )}
                        >
                            {/* Collections */}
                            <NavLink to={COLLECTIONS_ROOT_PATH} className="d-flex cursor-pointer text-white">
                                <div className={classNames(expanded ? 'menu-icon' : 'menu-icon-collapsed menu-plus')}>
                                    <FontAwesomeIcon icon={kpCollections} />
                                </div>
                                {expanded && (
                                    <div className="menu-label fw-semibold text-nowrap">
                                        <RichMessage id="menu.collections" />
                                    </div>
                                )}
                            </NavLink>

                            {/* Feedback */}
                            <div
                                className="d-flex cursor-pointer"
                                onClick={() => ElevioClient.openModule(ELEVIO_FEEDBACK_ID)}
                                title={intl.formatMessage({ id: 'menu.feedback' })}
                            >
                                <div className={classNames(expanded ? 'menu-icon' : 'menu-icon-collapsed menu-plus')}>
                                    <FontAwesomeIcon icon={faCommentDots} />
                                </div>
                                {expanded && (
                                    <div className="menu-label fw-semibold text-nowrap">
                                        <RichMessage id="menu.feedback" />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Settings Menu */}
                        <Nav.Item>
                            <SettingsMenu />
                        </Nav.Item>
                    </Nav>
                </div>
            </div>

            {/* New dashboard modal */}
            <NewDashboardModal
                isOpen={isNewDashboardModalOpen}
                onCreate={dashboardId => {
                    updateNewDashboardModalState(false);
                    refetch();
                    navigate(dashboardViewPath({ dashboardId }));
                }}
                onHide={() => updateNewDashboardModalState(false)}
            />
        </>
    );
};

export default Sidebar;
