import { useMeasure } from 'react-use';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { WidgetKpiConfigType, WidgetKpiType } from 'components/Dashboarding/Models/Widget';
import { useFetchSeries } from 'components/Dashboarding/Hooks/useFetchSeries';
import { PickEvent, useHighchartsOptions } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import Spinner from 'react-bootstrap/Spinner';
import { NEUTRAL_GREY_6, NEUTRAL_GREY_7 } from 'constants/colors';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import classNames from 'classnames';

// An estimated size ratio for numbers with the font we use
const NUMBER_HEIGHT_WIDTH_RATIO = 1.5;

// Color used when there is no data to display
const PLACEHOLDER_TEXT_COLOR = NEUTRAL_GREY_7;

// List of KPIs with progression displayed below the key metric
const SPARK_KPI_TYPES: WidgetKpiConfigType[] = ['SPARKCOLUMNKPI', 'SPARKLINEKPI'];

interface Props {
    onReloading: (reloading: boolean) => void;
    widget: WidgetKpiType;
    forcedMessageId?: string;
    onPick?: (event: PickEvent) => void;
    tooltipZIndex?: number;
}

const MetricKpiWidget: FunctionComponent<Props> = ({
    onReloading,
    widget,
    forcedMessageId,
    onPick,
    tooltipZIndex
}: Props) => {
    // Services
    const intl = useRichIntl();

    // Reference to wrapper to detect changes of size
    const [widgetWrapperRef, { width: widgetWidth, height: widgetHeight }] = useMeasure<HTMLDivElement>();
    const [chartWrapperRef, { width: chartWidth, height: chartHeight }] = useMeasure<HTMLDivElement>();

    // Get configuration
    const fact = widget.config.fact;
    const [metricSeries, trendSeries] = widget.config.series;

    // Get metric data
    const {
        records: metricRecords,
        initialLoading: metricInitialLoading,
        reloading: metricReloading
    } = useFetchSeries({ fact, series: metricSeries });

    // Get trend data
    const { records: trendRecords, initialLoading: trendInitialLoading, reloading: trendReloading } = useFetchSeries({
        fact,
        series: trendSeries,
        skip: !trendSeries
    });

    // Formatting options
    const trendProgressionEnabled = widget.config.formatting?.showTrendProgression;
    const hasRightSection = widget.configType != 'NUMBERKPI';
    const hasTrendProgression = SPARK_KPI_TYPES.includes(widget.configType) && trendProgressionEnabled;
    const contentHeight = widgetHeight / 2;

    // Get KPI metric and formatting
    const metric = metricRecords[0]?.['m0'];
    const metricAsNumber = parseFloat(metric || '0');
    const metricLabel = metricSeries.metrics[0].formatting?.label;
    const metricLabelColor = metric == undefined ? PLACEHOLDER_TEXT_COLOR : NEUTRAL_GREY_6;
    const metricLegend =
        metric == undefined ? intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }) : undefined;
    const formattedMetric =
        metric != undefined ? metricAsNumber.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '_';
    const metricMaxWidth = hasRightSection
        ? widgetWidth * (metricLabel ? 0.3 : 0.5)
        : widgetWidth * (metricLabel ? 0.75 : 1);
    const metricMaxHeight = hasTrendProgression ? (contentHeight * 2) / 3 : contentHeight;
    const metricFontSize = useMemo(
        () =>
            Math.min(
                metricMaxHeight,
                Math.floor((metricMaxWidth * NUMBER_HEIGHT_WIDTH_RATIO) / (formattedMetric.length || 1))
            ),
        [formattedMetric.length, metricMaxHeight, metricMaxWidth]
    );
    const metricColor = useMemo(() => {
        // When no data, use placeholder color
        if (metric == undefined) return PLACEHOLDER_TEXT_COLOR;

        // Evaluate applicable range
        const ranges = metricSeries.metrics[0].formatting?.colorRanges || [];
        const inversedRanges = [...ranges].reverse();
        const applicableRange = inversedRanges.find(r => r[1] == undefined || metricAsNumber >= r[1]);

        return applicableRange?.[0] || ranges[0]?.[0] || metricSeries.metrics[0].formatting?.color;
    }, [metric, metricAsNumber, metricSeries.metrics]);
    const labelFontSize = useMemo(() => metricFontSize / 3, [metricFontSize]);

    // Get trend progression
    const trendLast = trendRecords[0]?.['m0'] != undefined ? parseFloat(trendRecords[0]?.['m0']) : undefined;
    const trendPrev = trendRecords[1]?.['m0'] != undefined ? parseFloat(trendRecords[1]?.['m0']) : undefined;
    const trendProgression =
        trendLast != undefined && trendPrev != undefined ? ((trendLast - trendPrev) / trendPrev) * 100 : undefined;
    const trendProgressionLegend =
        trendProgression == undefined ? intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }) : undefined;
    const formattedTrendProgression =
        trendProgression != undefined
            ? `${trendProgression > 0 ? '+' : ''}${trendProgression.toLocaleString(undefined, {
                  maximumFractionDigits: 0
              })}%`
            : '_%';

    // Get trend progression sizing
    const trendMaxWidth = widgetWidth * 0.4;
    const trendMaxHeight = hasTrendProgression ? contentHeight - metricMaxHeight : contentHeight;
    const trendFontSize = useMemo(
        () =>
            Math.min(
                trendMaxHeight,
                Math.floor((trendMaxWidth * NUMBER_HEIGHT_WIDTH_RATIO) / (formattedTrendProgression.length || 1))
            ),
        [formattedTrendProgression.length, trendMaxHeight, trendMaxWidth]
    );

    // Get trend plot data by reversing the order of the trend data.
    // Trend data are expected to be received in descending order (most recent first) so
    // for plotting purpose they must be reversed.
    const trendPlotRecords = useMemo(() => [...trendRecords].reverse(), [trendRecords]);

    // Get trend color
    const trendColor = useMemo(() => {
        // If no data, use placeholder color
        if (trendProgression == undefined) return PLACEHOLDER_TEXT_COLOR;

        // Evaluate applicable range
        const ranges = trendSeries?.metrics?.[0].formatting?.colorRanges || [];
        const inversedRanges = [...ranges].reverse();
        const applicableRange =
            trendProgression != undefined
                ? inversedRanges.find(r => r[1] == undefined || trendProgression >= r[1])
                : undefined;

        return applicableRange?.[0] || ranges[0]?.[0] || trendSeries?.metrics[0].formatting?.color;
    }, [trendProgression, trendSeries?.metrics]);
    const formattedTrendSeries: typeof trendSeries = useMemo(() => {
        if (!trendSeries) return trendSeries;

        // Set the applicable range color as main color
        return {
            ...trendSeries,
            metrics: [
                { ...trendSeries.metrics[0], formatting: { ...trendSeries.metrics[0].formatting, color: trendColor } }
            ]
        };
    }, [trendColor, trendSeries]);

    // Avoid unnecessary animated reflows while the height/width get discovered
    const isChartReady = useMemo(() => chartHeight > 0 && chartWidth > 0, [chartHeight, chartWidth]);

    // Highcharts options calculate
    const [highchartsOptions, fullChartRedraw] = useHighchartsOptions({
        chartType: widget.configType,
        // We do not want highchart to automagically sort data for time series
        // We want to display the trend points as they were sorted
        forceXAxisCategories: true,
        records: trendPlotRecords,
        series: formattedTrendSeries,
        options: {
            chart: { height: chartHeight, width: chartWidth },
            colors: widget.config.formatting?.baseColors,
            legend: { enabled: widget.config.formatting?.showChartLegend },
            xAxis: {
                title: {
                    text: widget.config.formatting?.xAxis?.show ? widget.config.formatting?.xAxis?.label : undefined
                }
            },
            yAxis: {
                title: {
                    text: widget.config.formatting?.yAxis?.show ? widget.config.formatting?.yAxis?.label : undefined
                }
            },
            tooltip: {
                style: {
                    zIndex: tooltipZIndex
                }
            }
        },
        onPick,
        forcedMessageId
    });

    // Update parent state when widget reloads
    useEffect(() => onReloading(metricReloading || trendReloading), [onReloading, metricReloading, trendReloading]);

    // Render chart
    return (
        <div ref={widgetWrapperRef} className="d-flex h-100 w-100 justify-content-center align-items-center">
            {metricInitialLoading || trendInitialLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : forcedMessageId ? (
                <div className="fw-bold fs-2 text-grey-7">
                    <RichMessage id={forcedMessageId} />
                </div>
            ) : widget.configType == 'NUMBERKPI' ? (
                <div
                    className={classNames('text-center text-truncate', { 'fw-bold': metric != undefined })}
                    style={{ color: metricLabelColor, lineHeight: 1, fontSize: labelFontSize }}
                    title={metricLegend}
                >
                    <span style={{ fontSize: metricFontSize, color: metricColor }}>{formattedMetric}</span>
                    {metricLabel && (
                        <span className="ms-1" title={metricLabel}>
                            {metricLabel}
                        </span>
                    )}
                </div>
            ) : widget.configType == 'SPARKLINEKPI' || widget.configType == 'SPARKCOLUMNKPI' ? (
                <div className="h-100 w-100 d-flex align-items-end">
                    <div className="ps-2">
                        <div
                            className={classNames({ 'fw-bold text-truncate': metric != undefined })}
                            style={{ color: metricLabelColor, lineHeight: 1, fontSize: labelFontSize }}
                            title={metricLegend}
                        >
                            <span style={{ lineHeight: 1.1, fontSize: metricFontSize, color: metricColor }}>
                                {formattedMetric}
                            </span>
                            {metricLabel && (
                                <span className="ms-1" title={metricLabel}>
                                    {metricLabel}
                                </span>
                            )}
                        </div>
                        {hasTrendProgression && (
                            <div
                                className={classNames({ 'fw-bold': trendProgression != undefined })}
                                style={{ fontSize: trendFontSize, color: trendColor }}
                                title={trendProgressionLegend}
                            >
                                {formattedTrendProgression}
                            </div>
                        )}
                    </div>

                    {/* Absolute positioning ensures that the chart size is dictated by the parent, not by the content */}
                    <div className="flex-grow-1 h-100" ref={chartWrapperRef}>
                        <div className="position-absolute">
                            {isChartReady && (
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={highchartsOptions}
                                    immutable={fullChartRedraw}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : widget.configType == 'TRENDKPI' ? (
                <div className="pb-2 w-100 d-flex align-items-end">
                    <div
                        className={classNames('w-60 text-center text-truncate', { 'fw-bold': metric != undefined })}
                        style={{ color: metricLabelColor, lineHeight: 1, fontSize: labelFontSize }}
                        title={metricLegend}
                    >
                        <span style={{ lineHeight: 0.8, fontSize: metricFontSize, color: metricColor }}>
                            {formattedMetric}
                        </span>
                        {metricLabel && (
                            <span className="ms-1" title={metricLabel}>
                                {metricLabel}
                            </span>
                        )}
                    </div>

                    <div
                        className={classNames('w-40 text-center', { 'fw-bold': trendProgression != undefined })}
                        style={{ lineHeight: 0.8, fontSize: trendFontSize, color: trendColor }}
                        title={trendProgressionLegend}
                    >
                        {formattedTrendProgression}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MetricKpiWidget;
