// ================================================
// General
// ================================================
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' }
];

// Breakpoint from which the sidebar menu should be automatically expanded on page load
export const SIDEBAR_EXPANDED_BREAKPOINT = 992; // LG (desktop large)

// ================================================
// Onboarding
// ================================================
// Onboarding: the threshold below which projects are automatically
// ticked for import
export const ONBOARDING_AUTO_TICK_THRESHOLD = 15;

// The list of default dashboard templates to create for every user
export const ONBOARDING_DEFAULT_DASHBOARD_TEMPLATE_IDS = [
    'dashboard-audit-compliance',
    'dashboard-delivery',
    'dashboard-dora-metrics',
    'dashboard-my-development-assistant',
    'dashboard-quality',
    'dashboard-sprint-overview'
];

// ================================================
// App & Projects
// ================================================
// Images
export const APP_IMAGES: { [key: string]: string } = {
    bitbucket: require('assets/img/apps/bitbucket.png'),
    clickup: require('assets/img/apps/clickup.png'),
    github: require('assets/img/apps/github.png'),
    gitlab: require('assets/img/apps/gitlab.png'),
    'gitlab-hosted': require('assets/img/apps/gitlab-hosted.png'),
    jira: require('assets/img/apps/jira.png'),
    msteams: require('assets/img/apps/msteams.png'),
    slack: require('assets/img/apps/slack.png'),
    trello: require('assets/img/apps/trello.png')
};

// Which categories should we redirect to the project list
// page after connection
export const APP_CATEGORIES_WITH_PROJECTS = ['CODE_MANAGEMENT', 'PROJECT_MANAGEMENT'];
export const APP_CATEGORIES_WITH_IDENTITIES = ['CODE_MANAGEMENT', 'PROJECT_MANAGEMENT'];

// Integration statuses
export const INTEGRATION_DISABLED_STATUS = 'DISABLED';
export const INTEGRATION_DISCONNECTED_STATUS = 'DISCONNECTED';
export const INTEGRATION_ACTIVE_STATUS = 'ACTIVE';
export const INTEGRATION_DISCOVERING_STATUSES = ['STAGED', 'DISCOVERING'];

// Project statuses
export const PROJECT_DISABLED_STATUS = 'DISABLED';
export const PROJECT_DISCONNECTED_STATUS = 'DISCONNECTED';
export const PROJECT_PENDING_STATUS = 'PENDING';
export const PROJECT_IMPORTING_STATUS = 'IMPORTING';
export const PROJECT_ACTIVE_STATUS = 'ACTIVE';

// Project Action statuses
export const PROJECT_ENABLEMENT_STATUSES = ['DISABLED'];
export const PROJECT_DEACTIVATION_STATUSES = ['ACTIVE', 'DISCONNECTED'];

// Sharing scopes for prioritization rules and reports
export const SHARING_SCOPES = ['USER', 'COMPANY'];
export const SHARING_SCOPE_COMPANY = 'COMPANY';

// App stages
export const APP_ALPHA_STAGE = 'ALPHA';
export const APP_BETA_STAGE = 'BETA';
export const APP_PROD_STAGE = 'PRODUCTION';

// App-specific features
interface AppFeatures {
    addInstance: boolean;
}

export const APP_FEATURES: { [key: string]: AppFeatures } = {
    jira: { addInstance: true }
};

// ================================================
// Team
// ================================================
// Company membership statuses
export const MEMBERSHIP_ENABLED_STATUS = 'ENABLED';
export const MEMBERSHIP_DISABLED_STATUS = 'DISABLED';

// Role colors
export const COMPANY_ROLE_COLORS: { [key: string]: string } = {
    ADMIN: 'warning',
    MEMBER: 'primary'
};
export const DEFAULT_COMPANY_ROLE_COLOR = 'primary';
export const DISABLED_COMPANY_ROLE_COLOR = 'light';

// ================================================
// Session/Local storage keys
// ================================================
// Key used to store the onboarding variant. For tracking purpose.
export const LS_ONBOARDING_VARIANT_KEY = 'onboarding_variant';

// Store UTM parameters from /signup page
export const LS_INITIAL_UTM_KEY = 'initial_utm';

// ================================================
// Toasts
// ================================================
export const DEFAULT_TOAST_DURATION = 3200;
export const ERROR_TOAST_DURATION = 10000;

// ================================================
// Default debounce time
// ================================================

export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_AUTOCOMMIT_TIME = 1000;

// ================================================
// Widget editor
// ================================================
export const CHART_SERIES_INDEX = 0;
export const KPI_METRIC_SERIES_INDEX = 0;
export const KPI_TREND_SERIES_INDEX = 1;
export const METRIC_IDENTIFIER = 'm';
export const DIMENSION_IDENTIFIER = 'd';

// ================================================
// Library cards
// ================================================
export const LIBRARY_CARD_DESCRIPTION_MAX_LENGTH = 170;

// ================================================
// Modal
// ================================================
export const MODAL_SIZE_LG = 800; // px
export const MODAL_SIZE_DEFAULT = 500; // px
export const FIRST_MODAL_Z_INDEX = 1038;
