import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';

interface Props {
    className?: string;
    onSelectChange: (selectAll: boolean) => void;
    selectedCount: number;
    totalCount: number;
}

export const SelectAllCheckbox: FunctionComponent<Props> = ({
    className,
    onSelectChange,
    selectedCount,
    totalCount
}: Props) => {
    // Reference
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    // Select/unselect all when clicked
    const handleChangeSelectAll = useCallback((): void => {
        onSelectChange(selectedCount < totalCount);
    }, [onSelectChange, selectedCount, totalCount]);

    // Set checkbox to indeterminate when the selectedCount
    // is positive but strictly below the total count
    useEffect(() => {
        if (!checkboxRef.current) return;

        if (selectedCount && selectedCount >= totalCount) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (selectedCount > 0) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        } else {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        }
    }, [selectedCount, totalCount]);

    // Render
    return (
        <Form.Check
            type="checkbox"
            onChange={handleChangeSelectAll}
            ref={checkboxRef}
            className={classNames('fs-xs', className)}
        />
    );
};
