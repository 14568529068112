import React, { FunctionComponent, Fragment, useState, useMemo } from 'react';
import Gravatar from 'react-gravatar';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { Membership, UPDATE_MEMBERSHIP, REMOVE_MEMBERSHIP } from 'api/hq/queries/CompanyMembership';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import {
    DEFAULT_COMPANY_ROLE_COLOR,
    DISABLED_COMPANY_ROLE_COLOR,
    MEMBERSHIP_DISABLED_STATUS,
    COMPANY_ROLE_COLORS
} from 'constants/defaultValues';
import { NEUTRAL_GREY_4 } from 'constants/colors';
import Spinner from 'react-bootstrap/Spinner';
import { toastQueryError } from 'components/Toast';

interface Props {
    membership: Membership;
    enableActions?: boolean;
    onChange?: (e: Membership | null) => void;
    currentTab?: string;
}

const TeamUserItem: FunctionComponent<Props> = ({ membership, enableActions, onChange }: Props) => {
    // State
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    // Infer state
    const isDisabled = membership.status === MEMBERSHIP_DISABLED_STATUS;
    const roleColor = isDisabled
        ? DISABLED_COMPANY_ROLE_COLOR
        : COMPANY_ROLE_COLORS[membership.role] || DEFAULT_COMPANY_ROLE_COLOR;

    // Swtich Values
    const switchMembershipValue = useMemo(() => (membership.role === 'ADMIN' ? 'MEMBER' : 'ADMIN'), [membership.role]);
    const switchStatusValue = useMemo(
        () => (membership.status === MEMBERSHIP_DISABLED_STATUS ? 'ENABLED' : 'DISABLED'),
        [membership.status]
    );

    // Declare update invite mutation
    const [updateMembershipQuery] = useMutation(UPDATE_MEMBERSHIP);

    // Declare cancel invite mutation
    const [removeMembershipQuery] = useMutation(REMOVE_MEMBERSHIP);

    // Update user role
    const updateMembership = async ({ role, status }: { role?: string; status?: string }): Promise<void> => {
        setUpdateInProgress(true);

        try {
            await updateMembershipQuery({ variables: { id: membership.id, role: role, status: status } });
            onChange && onChange({ ...membership, role: role || membership.role, status: status || membership.status });
        } catch {
            toastQueryError({ namespace: 'update-team-membership', code: 'update-rejected' });
        } finally {
            setUpdateInProgress(false);
        }
    };

    // Remove a user from the company
    const removeMembership = async (): Promise<void> => {
        try {
            await removeMembershipQuery({ variables: { id: membership.id } });
            onChange && onChange(null);
        } catch {
            toastQueryError({ namespace: 'update-team-membership' });
        }
    };

    return (
        <Fragment>
            {/* User item content */}
            <td className="fw-bold text-truncate py-4">
                <Gravatar email={membership.user?.email} size={40} default="wavatar" className="rounded-circle me-3" />
                {membership.user.firstName}
            </td>
            <td className="text-truncate">{membership.user.email}</td>
            <td className="py-4">
                <Badge className={`text-${roleColor} bg-${roleColor} bg-opacity-10`} pill>
                    <FormattedMessage id={`settings.team-management.team-list.role.${membership.role}`} />
                    {isDisabled && (
                        <Fragment>
                            {' '}
                            / <FormattedMessage id={`settings.team-management.team-list.status.DISABLED`} />
                        </Fragment>
                    )}
                </Badge>
            </td>

            {/* Item actions */}
            <td>
                {enableActions && (
                    <>
                        <Dropdown align="end">
                            <Dropdown.Toggle as="div" disabled={updateInProgress} bsPrefix="none" role="button">
                                {updateInProgress ? (
                                    <Spinner animation="border" size="sm" variant="dark" />
                                ) : (
                                    <FontAwesomeIcon icon={faEllipsisH} color={NEUTRAL_GREY_4} />
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* Change role */}
                                <Dropdown.Item onClick={() => updateMembership({ role: switchMembershipValue })}>
                                    <FormattedMessage
                                        id={`settings.team-management.team-list.user-item.action-switch-${switchMembershipValue.toLowerCase()}`}
                                    />
                                </Dropdown.Item>

                                {/* Change status */}
                                <Dropdown.Item onClick={() => updateMembership({ status: switchStatusValue })}>
                                    <FormattedMessage
                                        id={`settings.team-management.team-list.user-item.action-switch-${switchStatusValue.toLowerCase()}`}
                                    />
                                </Dropdown.Item>

                                <Dropdown.Divider />
                                {/* Remove user */}
                                <Dropdown.Item onClick={removeMembership} className="text-danger">
                                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                                    <FormattedMessage id="settings.team-management.team-list.user-item.action-remove" />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )}
            </td>
        </Fragment>
    );
};

export default TeamUserItem;
