import { useMemo } from 'react';
import { GetItemReviewsResp, GET_ITEM_REVIEWS, ItemReview } from '../queries/Review';
import { useReport, UseReportResult } from './useReport';

interface UseItemReviewsOps {
    skip?: boolean | null;
    noSubscribe?: boolean;
    parentSysIds: string[];
}

interface UseItemReviewsQueryResults extends UseReportResult<GetItemReviewsResp> {
    normalized: ItemReview[];
}

export function useItemReviews({ skip, noSubscribe, parentSysIds }: UseItemReviewsOps): UseItemReviewsQueryResults {
    // Fetch items
    const reportQuery = useReport<GetItemReviewsResp>(GET_ITEM_REVIEWS, {
        skip: skip || parentSysIds.length == 0,
        noSubscribe: noSubscribe,
        variables: {
            parentSysIds
        }
    });

    const normalized = useMemo(() => reportQuery.data?.report.results.reviews || [], [
        reportQuery.data?.report.results.reviews
    ]);

    return { ...reportQuery, normalized };
}
