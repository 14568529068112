import { useMutation } from '@apollo/react-hooks';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCompanyMemberships } from 'api/hq/hooks/useCompanyMemberships';
import { useResourceRoles } from 'api/hq/hooks/useResourceRoles';
import { Membership } from 'api/hq/queries/CompanyMembership';
import { ResourceRoleName, SetResourceRoleResp, SET_RESOURCE_ROLE } from 'api/hq/queries/ResourceRole';
import { AsyncButton } from 'components/AsyncButton';
import { toastQueryError } from 'components/Toast';
import { ExecutionResult } from 'graphql';
import { cloneDeep, sortBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import AddResourceRole from '../AddResourceRole';
import UpdateResourceRole from '../UpdateResourceRole';

interface Props {
    dashboardId: string;
    isOpen: boolean;
    onHide: () => void;
}

export const ALLOWED_ROLES: ResourceRoleName[] = ['EDITOR', 'VIEWER'];

export const REMOVED_ROLE = 'NONE' as const;
export type RemovedRoleType = typeof REMOVED_ROLE[number];

export const ShareDashboardModal: FunctionComponent<Props> = ({ dashboardId, isOpen, onHide }: Props) => {
    // State
    const [draftRoles, setDraftRoles] = useState<
        (Membership & { roleName?: ResourceRoleName | RemovedRoleType; isEdited: boolean })[]
    >([]);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve memberships
    const { normalized: companyMembers, loading: membersLoading, refetch: refetchMembers } = useCompanyMemberships({
        companyId: company?.id
    });

    // Fetch the roles for this dashboard
    const { normalized: roles, loading: loadingRoles, refetch: refetchRoles } = useResourceRoles({
        resourceId: dashboardId,
        resourceType: 'DASHBOARD'
    });

    // Filtered company users
    const filteredCompanyMembers = useMemo(
        () =>
            companyMembers.filter(
                m =>
                    !draftRoles
                        .filter(e => e.roleName != undefined)
                        .map(r => r.user.id)
                        .includes(m.user.id)
            ),
        [companyMembers, draftRoles]
    );

    // Extract data from responses
    const memberships = useMemo(() => sortBy(filteredCompanyMembers, 'firstName'), [filteredCompanyMembers]);

    // Existing dashboard roles
    const persistedRoles: (Membership & { roleName: ResourceRoleName })[] = useMemo(
        () =>
            roles.reduce((array: (Membership & { roleName: ResourceRoleName })[], currentRole) => {
                const member = companyMembers.find(m => m.user.id == currentRole.actorId);
                if (member) {
                    array.push({
                        ...member,
                        roleName: currentRole.name
                    });
                }
                return array;
            }, []),
        [companyMembers, roles]
    );

    // Show save button if one of the persisted roles has been edited
    const isEdited = useMemo(() => draftRoles.some(r => r.isEdited), [draftRoles]);

    // Invoked when the user selects a role
    const onRoleAddedOrUpdated = useCallback(
        (membership, newRole) => {
            // Shallow copy of the roles
            const items = [...draftRoles];

            // Find the index of the role to update
            const itemToUpdateIndex = items.findIndex(m => m.user.id == membership.user.id);

            if (itemToUpdateIndex != -1) {
                // Update the role
                const updatedItem = { ...items[itemToUpdateIndex], roleName: newRole };

                // Find the persisted role
                const persistedRole = persistedRoles.find(r => r.user.id == membership.user.id);

                if (persistedRole) {
                    // Is the role different from the persisted one
                    updatedItem.isEdited = persistedRole.roleName != newRole;
                }

                // Put it back in the roles array
                items[itemToUpdateIndex] = updatedItem;
            } else {
                // Add the role
                items.push({ ...membership, roleName: newRole, isEdited: true });
            }

            // Update state
            setDraftRoles(items);
        },
        [draftRoles, persistedRoles]
    );

    // Refetch the roles and the members
    const refetchAll = useCallback(() => {
        refetchRoles();
        refetchMembers();
    }, [refetchRoles, refetchMembers]);

    // Mutations
    const [setRole] = useMutation<SetResourceRoleResp>(SET_RESOURCE_ROLE);

    // Invoked when the user clicks the save button
    const handleOnSave = useCallback(() => {
        setSaveInProgress(true);

        // Prepare all the promises
        const updatePromises = draftRoles.reduce((promises: Promise<ExecutionResult<SetResourceRoleResp>>[], r) => {
            // Skip preparing a promise for unedited roles
            if (!r.isEdited) return promises;

            // Prepare the promise
            promises.push(
                setRole({
                    variables: {
                        input: {
                            actorId: r.user.id,
                            actorType: 'USER',
                            name: r.roleName,
                            resourceId: dashboardId,
                            resourceType: 'DASHBOARD'
                        }
                    }
                })
            );

            return promises;
        }, []);

        // Resolve the promises
        Promise.all(updatePromises)
            .then(() => {
                refetchAll();
                setSaveInProgress(false);
                onHide();
            })
            .catch(() => {
                toastQueryError({ namespace: 'share-unshare-dashboard' });
                refetchAll();
                setSaveInProgress(false);
            });
    }, [setRole, onHide, refetchAll, dashboardId, draftRoles]);

    // Whenever the persisted roles are refetched from the server, build the draft roles from it and set each item's isEdited property to false
    useEffect(() => setDraftRoles(cloneDeep(persistedRoles).map(r => ({ ...r, isEdited: false }))), [persistedRoles]);

    return (
        <Modal show={isOpen} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* Icon */}
                    <FontAwesomeIcon icon={faShareAlt} className="me-3" />

                    {/* Title */}
                    <FormattedMessage id="dashboarding.share-dashboard-modal.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 pb-3">
                {membersLoading || loadingRoles ? (
                    <Spinner animation="border" />
                ) : (
                    <>
                        <AddResourceRole
                            memberships={memberships}
                            className="mb-5"
                            onRoleAdded={onRoleAddedOrUpdated}
                        />
                        <div>
                            <h4 className="mb-3">
                                <FormattedMessage id="dashboarding.share-dashboard-modal.shared-with" />
                            </h4>

                            {draftRoles.length > 0 ? (
                                <Table>
                                    <tbody>
                                        {/* Roles */}
                                        {draftRoles.map(membership => (
                                            <UpdateResourceRole
                                                key={membership.id}
                                                membership={membership}
                                                roleName={membership.roleName}
                                                disabled={saveInProgress}
                                                onRoleUpdated={newRole => onRoleAddedOrUpdated(membership, newRole)}
                                            />
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className="text-grey-1">
                                    <FormattedMessage id="dashboarding.share-dashboard-modal.not-shared-yet" />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100">
                    <Col md={{ span: 3, offset: 6 }}>
                        {/* Cancel button */}
                        <Button variant="outline-dark" className="w-100 justify-content-center" onClick={onHide}>
                            <FormattedMessage id="dashboarding.share-dashboard-modal.cancel" />
                        </Button>
                    </Col>
                    <Col md="3">
                        {/* Save or Done button */}
                        {isEdited ? (
                            <AsyncButton
                                variant="primary"
                                className="w-100 justify-content-center"
                                onClick={handleOnSave}
                                disabled={saveInProgress}
                                loading={saveInProgress}
                                messageProps={{ id: 'dashboarding.share-dashboard-modal.save' }}
                                loadingMessageProps={{ id: 'dashboarding.share-dashboard-modal.saving' }}
                            />
                        ) : (
                            <Button variant="primary" className="w-100 justify-content-center" onClick={onHide}>
                                <FormattedMessage id="dashboarding.share-dashboard-modal.done" />
                            </Button>
                        )}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
