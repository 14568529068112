import gql from 'graphql-tag';
import { MutationError, MutationResp, SprintSettings } from './Shared';

export interface CompanyResp {
    id: string;
    name: string;
    sprintSettings: SprintSettings;
}

export interface GetCompanyResp {
    company?: CompanyResp;
}

export interface UpdateCompanyResp {
    updateCompany: MutationResp;
}

export const GET_COMPANY = gql`
    query($id: ID) {
        company(id: $id) {
            id
            name
            sprintSettings {
                methodology
                timerStartOn
                timerDuration
            }
        }
    }
`;

export const SUBSCRIBE_TO_COMPANY_UPDATED = gql`
    subscription($companyId: ID!) {
        companyUpdated(companyId: $companyId) {
            id
        }
    }
`;

export interface CreateCompanyResp {
    createCompany: {
        success: boolean;
        errors: MutationError[];
        company: {
            id: string;
        };
    };
}

export const CREATE_COMPANY = gql`
    mutation($name: String!) {
        createCompany(input: { name: $name }) {
            success
            errors {
                code
                message
                path
            }
            company {
                id
            }
        }
    }
`;

export const UPDATE_COMPANY = gql`
    mutation($id: ID!, $name: String, $sprintSettings: SprintSettingsInput) {
        updateCompany(input: { id: $id, name: $name, sprintSettings: $sprintSettings }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
