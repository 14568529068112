import React, { FunctionComponent, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

interface Props {
    basePath: string;
    steps: readonly string[];
    messageId: string;
}

export const Stepper: FunctionComponent<Props> = ({ basePath, steps, messageId }: Props) => {
    // Services
    const location = useLocation();
    const regex = useMemo(() => new RegExp(`(.*)(${basePath}/)([a-z\\-]*)(/.*)?`), [basePath]);

    // Returns true if the path passed as parameter is active
    const isActiveStep = useCallback(
        (step: string): boolean => {
            const currentStep = location.pathname.match(regex)?.[3] ?? '';
            return steps.indexOf(currentStep) >= steps.indexOf(step);
        },
        [location.pathname, regex, steps]
    );

    // Returns true if the path passed as parameter is the current one
    const isCurrentStep = useCallback(
        (step: string): boolean => {
            const currentStep = location.pathname.match(regex)?.[3] ?? '';
            return currentStep.includes(step);
        },
        [location.pathname, regex]
    );

    return (
        <>
            {/* Steps */}
            <div className="d-flex fs-6 fw-bold text-grey-4 align-self-center align-items-center justify-content-center mx-2">
                {steps.map((step, index) => {
                    return (
                        <div key={step} className="d-flex align-items-center">
                            <div
                                className={classNames('d-flex mx-2 align-items-center', {
                                    active: isActiveStep(step)
                                })}
                            >
                                <div className="step-bullet">{index + 1}</div>
                                <div
                                    className={classNames('step-name ms-3', {
                                        current: isCurrentStep(step)
                                    })}
                                >
                                    <FormattedMessage id={`${messageId}.stepper.${step}`} />
                                </div>
                            </div>
                            {index != steps.length - 1 && <>&mdash;</>}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
