import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface Props {
    alt?: string;
    className?: string;
    size?: number;
    src: string;
}

const AppIcon: FunctionComponent<Props> = ({ alt, className, size = 35, src }: Props) => {
    return (
        <div
            className={classNames('d-flex align-items-center justify-content-center rounded-circle', className)}
            style={{ height: size, width: size, minHeight: size, minWidth: size }}
        >
            <img alt={alt} src={src} className="w-60" />
        </div>
    );
};

export default AppIcon;
