import { LineLoadIndicator } from 'components/LineLoadIndicator';
import React, { Fragment, FunctionComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InboxItem } from 'api/viz/queries/InboxItem';
import InboxCard from '.';
import { useGetInboxItem } from 'api/viz/hooks/useGetInboxItem';
import InboxCardLoader from './InboxCardLoader';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    item?: InboxItem;
    itemSysid?: string;
    refreshing?: boolean;
}

// Display the full inbox item in a modal
// If only a system ID is passed then the item is remotely fetched
const InboxCardModal: FunctionComponent<Props> = ({ itemSysid, isOpen, toggle, item, refreshing }: Props) => {
    // Fetch item remotely if only a itemSysid was specified
    const { normalized: fetchedItem, initialLoading: itemLoading } = useGetInboxItem({
        systemId: itemSysid,
        skip: !!item
    });

    // Abort if no item reference passed
    if (!item && !itemSysid) return null;

    // Get the item to display
    const actualItem = item || fetchedItem;

    // Render
    return (
        <Modal show={isOpen} onHide={toggle} size="xl">
            {!actualItem || itemLoading ? (
                <InboxCardLoader />
            ) : (
                <Fragment>
                    {/*  Report refreshing indicator */}
                    <LineLoadIndicator show={refreshing} wrap={true} />

                    {/* Card */}
                    <InboxCard item={actualItem} />
                </Fragment>
            )}
        </Modal>
    );
};

export default InboxCardModal;
