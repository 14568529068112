import classNames from 'classnames';
import React, { ForwardRefExoticComponent, forwardRef, ReactNode, RefAttributes } from 'react';

interface Props {
    className?: string;
    children: ReactNode;
    onClick: () => void;
}

const DropdownMultiselectToggle: ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> = forwardRef(
    ({ className, children, onClick }: Props, ref) => {
        return (
            <div
                ref={ref}
                className={classNames('dropdown-multiselect-toggle px-3 d-flex align-items-center bg-white', className)}
                onClick={onClick}
            >
                {children}
            </div>
        );
    }
);

export default DropdownMultiselectToggle;
