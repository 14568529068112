import { DependencyList, useMemo, useEffect } from 'react';
import { createGlobalState } from 'react-use';

// Declare the cache store
const useCacheState = createGlobalState<{ [key: string]: unknown | undefined }>({});

// Retrieve a cached value
export function useCachedValue<T>(namespace: string, cacheDependencyList: DependencyList): [T | undefined, string] {
    // Get cache entry
    const cacheKey = useMemo(() => `${namespace}-${JSON.stringify(cacheDependencyList)}`, [
        cacheDependencyList,
        namespace
    ]);
    const [cacheState] = useCacheState();

    // Get cache value
    const cacheValue = cacheState[cacheKey] as T | undefined;

    // Return the cache value and the cache key
    return [cacheValue, cacheKey];
}

// Declare a definition for a cached value
export function useCacheDefinition<T>(memoFn: () => T | undefined, cacheKey: string): void {
    const [cacheState, setCacheState] = useCacheState();
    const cacheEntry = cacheState[cacheKey] as T | undefined;

    useEffect(() => {
        if (!cacheEntry) {
            const cacheEntryValue = memoFn();
            if (cacheEntryValue !== undefined) setCacheState({ ...cacheState, [cacheKey]: cacheEntryValue });
        }
    });
}
