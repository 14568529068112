import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';

interface ExternalLinkProps {
    href: string;
    children: ReactNode | ReactNodeArray;
}

export const ExternalLink: FunctionComponent<ExternalLinkProps> = ({ href, children }: ExternalLinkProps) => {
    return (
        <a
            href={href}
            className="hover-underline text-primary"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
        >
            {children}
        </a>
    );
};
