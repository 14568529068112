import React, { FunctionComponent, useMemo, useState } from 'react';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import TopNavBar from 'components/Design/TopNavBar';
import { useTitleLabel } from 'util/useTitleLabel';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDashboards } from 'api/hq/hooks/useDashboards';
import SearchBar from 'components/List/SearchBar';
import { DropdownMultiselect } from 'components/DropdownMultiselect';
import { useWidgets } from 'api/hq/hooks/useWidgets';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { DashboardWithHeaders, DeleteDashboardResp, DELETE_DASHBOARD } from 'api/hq/queries/Dashboard';
import { RESOURCE_TYPES } from 'api/hq/queries/ResourceRole';
import { DashboardLibraryCard } from '../../components/LibraryCard/DashboardLibraryCard';
import { DropdownOption } from 'components/List/FilteringDropdown';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox/SelectAllCheckbox';
import DashboardRowItem from './DashboardRowItem/DashboardRowItem';
import WidgetRowItem from './WidgetRowItem/WidgetRowItem';
import { SECONDARY_RED } from 'constants/colors';
import { useMutation } from '@apollo/react-hooks';
import { DeleteWidgetResp, DELETE_WIDGET } from 'api/hq/queries/Widget';
import { toastQueryError } from 'components/Toast';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { SortableHeader, SortConfig } from 'components/SortableHeader';
import { getNestedPropertyValue } from 'util/ObjectOperators';
import { sortBy } from 'lodash';
import { useLocalStorage, useMeasure } from 'react-use';
import { COLLECTIONS_VIEW_MODE_KEY } from 'constants/localStorageKeys';
import { dashboardViewPath } from 'constants/routeBuilders';
import { WidgetLibraryCard } from 'components/LibraryCard/WidgetLibraryCard';
import WidgetPreviewModal from './WidgetPreviewModal/WidgetPreviewModal';
import { LIBRARY_CARD_DEFAULT_WIDTH, LIBRARY_CARD_MARGIN } from 'components/LibraryCard';
import { computeCardsContainerMaxWidth } from 'components/LibraryCard/LibraryCardUtils';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

// Columns identifiers
const NAME_COLUMN = 'name';
const OWNER_COLUMN = 'user.firstName';
const UPDATED_AT_COLUMN = 'updatedAt';

export type DashboardItem = DashboardWithHeaders & { type: 'DASHBOARD' };
export type WidgetItem = WidgetFactType & {
    type: 'WIDGET';
    dashboards: { id: string; name: string }[];
};
type ResourceItem = DashboardItem | WidgetItem;

const includesSearchTerm = (item: ResourceItem, searchTerm: string): boolean | undefined =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description?.toLowerCase().includes(searchTerm.toLowerCase());

const filterByResourceType = (item: ResourceItem, filters: string[]): boolean | undefined =>
    !filters?.length ||
    (filters.includes('dashboards') && item.type == 'DASHBOARD') ||
    (filters.includes('widgets') && item.type == 'WIDGET');

const filterByResourceAttr = (item: ResourceItem, filters: string[]): boolean | undefined =>
    !filters?.length ||
    (filters.includes('personal') && item.currentUserRole?.name == 'OWNER') ||
    (filters.includes('shared') && item.currentUserRole && ['EDITOR', 'VIEWER'].includes(item.currentUserRole.name));

const CollectionsWidgetMainActionButton: FunctionComponent<{ widget: WidgetFactType }> = ({ widget }) => {
    // State
    const [widgetPreviewModalOpen, setWidgetPreviewModalOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                variant="dark"
                onClick={() => setWidgetPreviewModalOpen(true)}
                className="rounded-0 rounded-bottom justify-content-center w-100"
            >
                <RichMessage id="components.library-card.open-insight" />
            </Button>
            <WidgetPreviewModal
                isOpen={widgetPreviewModalOpen}
                widget={widget}
                onHide={() => setWidgetPreviewModalOpen(false)}
            />
        </>
    );
};

export const Collections: FunctionComponent = () => {
    // Page title
    useTitleLabel('collections.title');

    // State
    const [selectedResources, setSelectedResources] = useState<ResourceItem[]>([]);
    const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
    const [cleanupOrphanWidgets, setCleanupOrphanWidgets] = useState<boolean>(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<SortConfig>();
    const [viewMode, setViewMode] = useLocalStorage<string>(COLLECTIONS_VIEW_MODE_KEY, 'list');

    // Refs
    const [parentContainerRef, { width: parentContainerWidth }] = useMeasure<HTMLDivElement>();

    // Mutations
    const [deleteDashboard] = useMutation<DeleteDashboardResp>(DELETE_DASHBOARD);
    const [deleteWidget] = useMutation<DeleteWidgetResp>(DELETE_WIDGET);

    const selectedResourceIds = useMemo(() => selectedResources.map(e => e.id), [selectedResources]);

    // Extract URL search params
    const [searchParams, setSearchParams] = useSearchParams();

    // Services
    const intl = useRichIntl();

    // Load the dashboards
    const { normalized: dashboards, loading: loadingDashboards, refetch: refetchDashboards } = useDashboards();

    // Refetch the pinned dashboards (on new dashboard creation)
    const { refetch: refetchPinnedDashboards } = useDashboards({ pinned: true });

    // Load the widgets
    const { normalized: widgets, loading: loadingWidgets } = useWidgets({
        excludeDecorators: true
    });

    // Loading state
    const loading = useMemo(() => loadingDashboards || loadingWidgets, [loadingDashboards, loadingWidgets]);

    // Type filtering options
    const resourceTypeFilteringOptions = useMemo(
        () => [
            { id: 'dashboards', name: intl.formatMessage({ id: 'collections.filters.type.dashboards' }) },
            { id: 'widgets', name: intl.formatMessage({ id: 'collections.filters.type.widgets' }) }
        ],
        [intl]
    );

    // Type filters from search params
    const selectedTypeFilters: DropdownOption[] | undefined = useMemo(() => {
        const filters = searchParams.get('type')?.split(',');
        if (!filters || !filters.length) return;

        const selectedOptions = filters.reduce((array: DropdownOption[], e) => {
            const option = resourceTypeFilteringOptions.find(o => o.id == e);
            if (option) array.push(option);
            return array;
        }, []);

        return selectedOptions.length ? selectedOptions : undefined;
    }, [resourceTypeFilteringOptions, searchParams]);

    // Ownership filtering options
    const resourceOwnershipFilteringOptions: DropdownOption[] = useMemo(
        () => [
            { id: 'personal', name: intl.formatMessage({ id: 'collections.filters.ownership.personal' }) },
            { id: 'shared', name: intl.formatMessage({ id: 'collections.filters.ownership.shared' }) }
        ],
        [intl]
    );

    // Ownership filters from search params
    const selectedOwnershipFilters: DropdownOption[] | undefined = useMemo(() => {
        const filters = searchParams.get('ownership')?.split(',');
        if (!filters || !filters.length) return;

        const selectedOptions = filters.reduce((array: DropdownOption[], e) => {
            const option = resourceOwnershipFilteringOptions.find(o => o.id == e);
            if (option) array.push(option);
            return array;
        }, []);

        return selectedOptions.length ? selectedOptions : undefined;
    }, [resourceOwnershipFilteringOptions, searchParams]);

    const resources: ResourceItem[] = useMemo(() => {
        return [
            ...sortBy(dashboards, e => e.pinned != true).map(d => ({ ...d, type: RESOURCE_TYPES[0] })),
            ...widgets.map(d => ({ ...d, type: RESOURCE_TYPES[1] }))
        ];
    }, [dashboards, widgets]);

    // Evaluate filtered projects
    const filteredResources: ResourceItem[] = useMemo(() => {
        return (
            resources
                .filter(e => filterByResourceType(e, searchParams.get('type')?.split(',') ?? []))
                // .filter(e => filterByPinned(e, showPinnedOnly))
                .filter(e => filterByResourceAttr(e, searchParams.get('ownership')?.split(',') ?? []))
                .filter(e => includesSearchTerm(e, searchParams.get('search') ?? ''))
                .sort((e1, e2) => {
                    if (sort)
                        return getNestedPropertyValue(e1, sort.columnId) < getNestedPropertyValue(e2, sort.columnId)
                            ? sort.asc
                                ? -1
                                : 1
                            : getNestedPropertyValue(e1, sort.columnId) > getNestedPropertyValue(e2, sort.columnId)
                            ? sort.asc
                                ? 1
                                : -1
                            : 0;
                    return 0;
                })
        );
    }, [resources, searchParams, sort]);

    // Update path to reflect the new filtering params
    const updateFilter = useCallback(
        (param: string, value?: string): void => {
            const params = new URLSearchParams(searchParams);
            value == undefined || value == '' ? params.delete(param) : params.set(param, value);
            setSearchParams(params);
        },
        [searchParams, setSearchParams]
    );

    // Handler called when resources are selected/deselected
    const onSelectChange = useCallback(
        (item: ResourceItem, selected: boolean) => {
            if (selected && selectedResourceIds.indexOf(item.id) < 0) {
                setSelectedResources([...selectedResources, item]);
            } else if (!selected && selectedResourceIds.indexOf(item.id) >= 0) {
                setSelectedResources(selectedResources.filter(e => e.id !== item.id));
            }
        },
        [selectedResourceIds, selectedResources]
    );

    // Hook invoked when users click the "Select All" checkbox
    const onSelectAllChange = useCallback(
        (selectAll: boolean): void => setSelectedResources(selectAll ? filteredResources : []),
        [filteredResources]
    );

    // Delete selected resources
    const deleteResources = useCallback(async () => {
        // Split resources
        const dashboards = selectedResources.filter(e => e.type == 'DASHBOARD');
        const widgets = selectedResources.filter(e => e.type == 'WIDGET');

        // Set loading state
        setDeletionInProgress(true);

        // First, delete the widgets in parallel
        await Promise.all(widgets.map(async e => await deleteWidget({ variables: { id: e.id } })))
            .then(async () => {
                // Then, delete the dashboards sequentially
                for (const e of dashboards) {
                    try {
                        const resp = (await deleteDashboard({ variables: { id: e.id, cleanupOrphanWidgets } })).data
                            ?.deleteDashboard;

                        if (!resp?.success) {
                            toastQueryError({ ...resp?.errors[0], namespace: 'delete-dashboard' });
                        }
                    } catch (error) {
                        toastQueryError({ namespace: 'delete-dashboard' });
                    }
                }

                // Reset loading state
                setDeletionInProgress(false);

                // Close the confirmation modal
                setIsDeleteConfirmationModalOpen(false);

                // Reset selected items state
                setSelectedResources([]);
            })
            .catch(() => {
                toastQueryError({ namespace: 'delete-widget' });
                setDeletionInProgress(false);
            });
    }, [cleanupOrphanWidgets, deleteDashboard, deleteWidget, selectedResources]);

    // Deletion confirmation modal content
    const deleteModalContent = useMemo(() => {
        return (
            <>
                <RichMessage
                    id="collections.confirmation-modal.bulk-delete.content"
                    values={{ count: selectedResources.length }}
                />
                {selectedResources.some(e => e.type == 'DASHBOARD') && (
                    <Form.Check
                        className="mt-4 sm-check"
                        type="checkbox"
                        label={
                            <RichMessage
                                id="collections.confirmation-modal.bulk-delete.delete-dashboards-widgets"
                                values={{ dashboardCount: selectedResources.filter(e => e.type == 'DASHBOARD').length }}
                            />
                        }
                        onClick={() => setCleanupOrphanWidgets(!cleanupOrphanWidgets)}
                    />
                )}
            </>
        );
    }, [cleanupOrphanWidgets, selectedResources]);

    // Deletion confirmation modal confirm button
    const deleteModalConfirmButton = useMemo(() => {
        return (
            <AsyncButton
                variant="danger"
                onClick={deleteResources}
                disabled={deletionInProgress}
                className="w-100 justify-content-center"
                loading={deletionInProgress}
                messageProps={{
                    id: 'collections.confirmation-modal.bulk-delete.confirm-button',
                    values: { count: selectedResources.length }
                }}
                loadingMessageProps={{
                    id: 'collections.confirmation-modal.bulk-delete.deletion-in-progress',
                    values: { count: selectedResources.length }
                }}
            />
        );
    }, [deleteResources, deletionInProgress, selectedResources.length]);

    // Hook invoked when user clicks a header of the table
    const handleHeaderClick = useCallback(
        (columnId: string) => {
            if (!sort || sort.columnId != columnId) {
                setSort({ columnId, asc: true });
                return;
            }

            if (!sort.asc) {
                setSort(undefined);
                return;
            }

            setSort({ ...sort, asc: !sort.asc });
        },
        [sort]
    );

    return (
        <>
            {/* Header bar */}
            <TopNavBar>
                <h4 className="flex-grow-1">
                    <RichMessage id="collections.title" />
                </h4>
            </TopNavBar>
            <Container>
                {/* Filters and controls */}
                <div className="mt-2">
                    <div className="d-lg-flex d-block align-items-center justify-content-between">
                        {/* Search */}
                        <div className="flex-grow-1 m-2">
                            <SearchBar
                                debounceTime={200}
                                value={searchParams.get('search')}
                                onChange={e => updateFilter('search', e)}
                            />
                        </div>

                        <div className="flex-grow-0 d-block d-lg-flex align-items-center justify-content-between">
                            {/* Filter resource type */}
                            <DropdownMultiselect
                                className="m-2"
                                placeholderId="collections.filters.type.placeholder"
                                options={resourceTypeFilteringOptions}
                                selected={selectedTypeFilters}
                                onChange={selectedFilters =>
                                    updateFilter('type', selectedFilters.map(e => e.id).join(','))
                                }
                            />

                            {/* Filter resource ownership */}
                            <DropdownMultiselect
                                className="m-2"
                                placeholderId="collections.filters.ownership.placeholder"
                                options={resourceOwnershipFilteringOptions}
                                selected={selectedOwnershipFilters}
                                onChange={selectedFilters =>
                                    updateFilter('ownership', selectedFilters.map(e => e.id).join(','))
                                }
                            />

                            <div className="m-2 d-flex">
                                {viewMode == 'list' ? (
                                    <>
                                        {/* Grid view button */}
                                        <Button
                                            className="me-2"
                                            variant="link"
                                            title={intl.formatMessage({ id: 'collections.view-mode.grid' })}
                                            onClick={() => setViewMode('grid')}
                                        >
                                            <FontAwesomeIcon icon={faTh} />
                                        </Button>
                                        {/* Bulk delete button */}
                                        {selectedResourceIds.length > 0 && (
                                            <Button
                                                variant="link"
                                                onClick={() => setIsDeleteConfirmationModalOpen(true)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} color={SECONDARY_RED} />
                                            </Button>
                                        )}
                                    </>
                                ) : viewMode == 'grid' ? (
                                    <>
                                        {/* List view button */}
                                        <Button
                                            className="me-2"
                                            variant="link"
                                            title={intl.formatMessage({ id: 'collections.view-mode.list' })}
                                            onClick={() => setViewMode('list')}
                                        >
                                            <FontAwesomeIcon icon={faList} />
                                        </Button>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Collections content */}
                <div className="mt-4" ref={parentContainerRef}>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" className="m-auto" size="sm" />
                        </div>
                    ) : viewMode == 'grid' ? (
                        <>
                            {/* Grid view mode */}
                            <Row
                                className="mx-auto"
                                xs="auto"
                                style={{
                                    maxWidth: computeCardsContainerMaxWidth(
                                        parentContainerWidth,
                                        LIBRARY_CARD_DEFAULT_WIDTH + 2 * LIBRARY_CARD_MARGIN
                                    )
                                }}
                            >
                                {resources.length > 0 && filteredResources.length > 0 ? (
                                    filteredResources.map(e => (
                                        <Col key={e.id} className="p-0">
                                            {e.type == 'DASHBOARD' ? (
                                                <DashboardLibraryCard
                                                    dashboard={e}
                                                    onPinChange={() => {
                                                        refetchDashboards();
                                                        refetchPinnedDashboards();
                                                    }}
                                                >
                                                    <Button
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        as={Link as any}
                                                        variant="dark"
                                                        to={dashboardViewPath({ dashboardId: e.id })}
                                                        className="rounded-0 rounded-bottom justify-content-center w-100"
                                                        style={{ bottom: 0, left: 0, right: 0 }}
                                                    >
                                                        <RichMessage id="components.library-card.open-this-dashboard" />
                                                    </Button>
                                                </DashboardLibraryCard>
                                            ) : e.type == 'WIDGET' ? (
                                                <WidgetLibraryCard widget={e}>
                                                    <CollectionsWidgetMainActionButton widget={e} />
                                                </WidgetLibraryCard>
                                            ) : null}
                                        </Col>
                                    ))
                                ) : (
                                    <div className="text-center text-light py-4">
                                        {resources.length > 0 && filteredResources.length === 0 ? (
                                            <RichMessage id="collections.empty-filtered-resources" />
                                        ) : (
                                            <RichMessage id="collections.empty-resources" />
                                        )}
                                    </div>
                                )}
                            </Row>
                        </>
                    ) : viewMode == 'list' ? (
                        <>
                            {/* List view mode */}
                            <Table className="collections-table align-middle mt-4">
                                {/* Table header */}
                                <thead className="bg-grey-tone-12">
                                    <tr className="align-middle">
                                        {/* Select/deslect all resources */}
                                        <th className="text-center checkbox">
                                            {filteredResources.length > 0 && (
                                                <SelectAllCheckbox
                                                    selectedCount={selectedResources.length}
                                                    totalCount={filteredResources.length}
                                                    onSelectChange={onSelectAllChange}
                                                />
                                            )}
                                        </th>

                                        {/* Icon / Name */}
                                        <SortableHeader
                                            id={NAME_COLUMN}
                                            sort={sort}
                                            onClick={() => handleHeaderClick(NAME_COLUMN)}
                                        >
                                            <RichMessage id="collections.list-view.header.name" />
                                        </SortableHeader>

                                        {/* Owner */}
                                        <SortableHeader
                                            id={OWNER_COLUMN}
                                            className="text-center d-lg-table-cell d-none"
                                            sort={sort}
                                            onClick={() => handleHeaderClick(OWNER_COLUMN)}
                                        >
                                            <RichMessage id="collections.list-view.header.owner" />
                                        </SortableHeader>

                                        {/* Last modified */}
                                        <SortableHeader
                                            id={UPDATED_AT_COLUMN}
                                            className="text-center d-lg-table-cell d-none"
                                            sort={sort}
                                            onClick={() => handleHeaderClick(UPDATED_AT_COLUMN)}
                                        >
                                            <RichMessage id="collections.list-view.header.last-modified" />
                                        </SortableHeader>

                                        {/* More button */}
                                        <th></th>
                                    </tr>
                                </thead>

                                {/* Table body */}
                                <tbody>
                                    {resources.length > 0 && filteredResources.length > 0 ? (
                                        // Project list
                                        filteredResources.map(e =>
                                            e.type == 'DASHBOARD' ? (
                                                <DashboardRowItem
                                                    key={e.id}
                                                    dashboard={e}
                                                    selected={selectedResourceIds.indexOf(e.id) >= 0}
                                                    onSelectChange={onSelectChange}
                                                    onPinChange={() => {
                                                        refetchDashboards();
                                                        refetchPinnedDashboards();
                                                    }}
                                                />
                                            ) : e.type == 'WIDGET' ? (
                                                <WidgetRowItem
                                                    key={e.id}
                                                    widget={e}
                                                    selected={selectedResourceIds.indexOf(e.id) >= 0}
                                                    onSelectChange={onSelectChange}
                                                />
                                            ) : null
                                        )
                                    ) : (
                                        // Empty list
                                        <tr className="text-center">
                                            <td className="text-light py-4" colSpan={4}>
                                                {resources.length > 0 && filteredResources.length === 0 ? (
                                                    <RichMessage id="collections.empty-filtered-resources" />
                                                ) : (
                                                    <RichMessage id="collections.empty-resources" />
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            {/* Delete confirmation modal */}
                            {isDeleteConfirmationModalOpen && (
                                <ConfirmationModal
                                    isOpen={isDeleteConfirmationModalOpen}
                                    icon={faTrash}
                                    iconColor={SECONDARY_RED}
                                    title={
                                        <RichMessage
                                            id="collections.confirmation-modal.bulk-delete.title"
                                            values={{ count: selectedResources.length }}
                                        />
                                    }
                                    content={deleteModalContent}
                                    customConfirmButton={deleteModalConfirmButton}
                                    onCancel={() => setIsDeleteConfirmationModalOpen(false)}
                                />
                            )}
                        </>
                    ) : null}
                </div>
            </Container>
        </>
    );
};
