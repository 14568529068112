import React, { FunctionComponent, useState } from 'react';
import {
    isChartWidget,
    isKpiWidget,
    isLargeCardWidget,
    isMiniCardWidget,
    WidgetFactType
} from 'components/Dashboarding/Models/Widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';
import ConfigureSource from './ConfigureSource';
import ConfigureTable from './ConfigureTable';
import CustomizeChart from './CustomizeChart';
import ConfigureFilters from './ConfigureFilters';
import ConfigureColumns from './ConfigureColumns';
import Nav from 'react-bootstrap/Nav';
import ConfigureVisualization from './ConfigureVisualization';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomizeList from './CustomizeList';
import ConfigureKpiMetricTable from './ConfigureTable/ConfigureKpiMetricTable';
import ConfigureKpiTrendTable from './ConfigureTable/ConfigureKpiTrendTable';
import CustomizeKpi from './CustomizeKpi';
import { MenuConfig } from 'util/useConfigurationPanelMenu';
import DocumentInsight from './DocumentInsight';
import ConfigureDrilldown from './ConfigureDrilldown';
import CustomizeHeatmap from './CustomizeHeatmap';

interface Props {
    onMenuSelect: (menu: string | null) => void;
    onSubMenuSelect: (index: number) => void;
    onChange: (widget: WidgetFactType) => void;
    selectedMenu: string | null;
    selectedSubMenu: number | undefined;
    applicableMenus: MenuConfig[];
    widget: WidgetFactType;
}

const ConfigurationPanel: FunctionComponent<Props> = ({
    onChange,
    onMenuSelect,
    onSubMenuSelect,
    selectedMenu,
    selectedSubMenu,
    applicableMenus,
    widget
}: Props) => {
    // Keep the state of the fields visibility in this component, so that it is preserved when switching tabs
    const [showAvailableFields, setShowAvailableFields] = useState<boolean>(true);

    // Abort if no menu selected yet
    if (!selectedMenu) return null;

    // Render
    return (
        <div className="h-100">
            <Row className="g-0 h-100">
                {/* Left Menu */}
                <Col xs="4" md="3" xl="2" style={{ zIndex: 1 }}>
                    <Nav activeKey={selectedMenu} className="vertical-menu border-right h-100" onSelect={onMenuSelect}>
                        {applicableMenus.map(e => {
                            return (
                                <Nav.Link key={e.id} eventKey={e.id}>
                                    <FontAwesomeIcon icon={e.icon} className="me-2" size="lg" />
                                    <span>
                                        <RichMessage id={`dashboarding.widget-editor.dock.${e.id}`} />
                                        {e.stage == 'beta' && (
                                            <sup className="text-warning font-weight-bold ms-1">
                                                (<RichMessage id="badge.beta" />)
                                            </sup>
                                        )}
                                    </span>
                                    <FontAwesomeIcon icon={faChevronRight} className="link-chevron" />
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Col>

                <Col xs="8" md="9" xl="10">
                    {/* Panel Content */}
                    <div className="flex-grow-1 pt-4 px-4 h-100 overflow-auto">
                        {selectedMenu == 'configure-columns' && isMiniCardWidget(widget) ? (
                            <ConfigureColumns widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-filters' && isLargeCardWidget(widget) ? (
                            <ConfigureFilters widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-visualization' ? (
                            <ConfigureVisualization widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-source' ? (
                            <ConfigureSource
                                widget={widget}
                                onChange={onChange}
                                showAvailableFields={showAvailableFields}
                                onToggleShowAvailableFields={() => setShowAvailableFields(!showAvailableFields)}
                            />
                        ) : selectedMenu == 'configure-table' && isChartWidget(widget) ? (
                            <ConfigureTable widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-kpi-metric-table' && isKpiWidget(widget) ? (
                            <ConfigureKpiMetricTable widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-kpi-trend-table' && isKpiWidget(widget) ? (
                            <ConfigureKpiTrendTable widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'configure-drilldown' &&
                          (isChartWidget(widget) ||
                              (isKpiWidget(widget) && widget.configType == 'SPARKCOLUMNKPI') ||
                              widget.configType == 'SPARKLINEKPI') ? (
                            <ConfigureDrilldown
                                widget={widget}
                                onChange={onChange}
                                onSubMenuSelect={onSubMenuSelect}
                                selectedSubMenu={selectedSubMenu}
                            />
                        ) : selectedMenu == 'customize-list' && widget.configType == 'LIST' ? (
                            <CustomizeList widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'customize-chart' && isKpiWidget(widget) ? (
                            <CustomizeKpi widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'customize-chart' && widget.configType == 'HEATMAPCHART' ? (
                            <CustomizeHeatmap widget={widget} onChange={onChange} />
                        ) : selectedMenu == 'customize-chart' && isChartWidget(widget) ? (
                            <CustomizeChart widget={widget} onChange={onChange} />
                        ) : null}

                        {/* Document Insight */}
                        {selectedMenu == 'document-insight' && <DocumentInsight widget={widget} onChange={onChange} />}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ConfigurationPanel;
