import React, { FunctionComponent } from 'react';
import { Toast as ReactHotToast } from 'react-hot-toast';
import { RichMessage } from 'components/RichMessage';
import { Toast } from 'components/Toast';

interface Props {
    t: ReactHotToast;
    projectsCount: number;
}

const ProjectsImportedToast: FunctionComponent<Props> = ({ t, projectsCount }: Props) => {
    return (
        <Toast t={t} type="success">
            <div>
                <RichMessage id="priority-inbox.notification.projects-imported.message" values={{ projectsCount }} />
            </div>
        </Toast>
    );
};

export default ProjectsImportedToast;
