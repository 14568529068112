import classNames from 'classnames';
import { useRichIntl } from 'components/RichMessage';
import { DEFAULT_AUTOCOMMIT_TIME } from 'constants/defaultValues';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
    className?: string;
    style?: object;
    disabled?: boolean;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: string) => void;
    onCommit?: (e: string) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholderId?: string;
    value?: string;
    variant?: 'regular' | 'light' | 'dark' | 'grey-1' | 'none';
}

const TransparentInput: FunctionComponent<Props> = ({
    className,
    style,
    disabled,
    onBlur,
    onChange,
    onCommit,
    onFocus,
    placeholderId,
    value,
    variant = 'dark'
}) => {
    // Services
    const intl = useRichIntl();

    // State
    const [localValue, setLocalValue] = useState<string>(value || '');
    const [isResyncLocked, setIsResyncLocked] = useState<boolean>(false);

    // Evaluate placeholder
    const placeholder = placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined;

    // Get debounced version of onCommit
    const debouncedOnCommit = useMemo(() => onCommit && debounce(onCommit, DEFAULT_AUTOCOMMIT_TIME), [onCommit]);

    // Hook invoked when the input content changes
    const handleOnChange = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            // Propagate event
            setLocalValue(event.target.value);
            onChange && onChange(event.target.value);

            // Auto-commit regularly
            debouncedOnCommit && debouncedOnCommit(event.target.value);
        },
        [debouncedOnCommit, onChange]
    );

    // Hook invoked when the input gets focus
    const handleOnFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            // Prevent resyncs from parent
            setIsResyncLocked(true);

            // Propagate event
            onFocus && onFocus(event);
        },
        [onFocus]
    );

    // Hook invoked when the input loses focus
    const handleOnBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            // Progagate event
            onBlur && onBlur(event);

            // Cancel the next debounce iteration and commit immediately
            // The debounce cancellation ensures we do not end up with
            // two successive identical updates
            debouncedOnCommit?.cancel();
            onCommit && value != localValue && onCommit(localValue);

            // Allow resyncs from parent
            setIsResyncLocked(false);
        },
        [debouncedOnCommit, localValue, onBlur, onCommit, value]
    );

    // Resync local value from attribute when the input is unlocked
    useEffect(() => {
        !isResyncLocked && setLocalValue(value || '');
    }, [isResyncLocked, value]);

    // Render
    return (
        <input
            type="text"
            style={style}
            value={localValue}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            placeholder={placeholder}
            className={classNames(
                'transparent-input',
                'text-truncate',
                {
                    'text-light': variant == 'light',
                    'text-grey-1': variant == 'grey-1',
                    'text-dark': variant == 'dark'
                },
                className
            )}
            disabled={disabled}
        />
    );
};

export default TransparentInput;
