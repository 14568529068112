import { GetInboxItemsResp, GET_INBOX_ITEMS, InboxItem } from '../queries/InboxItem';
import { RuleGroupType } from 'react-querybuilder';
import { UseReportResult, useReport } from './useReport';
import { useMemo } from 'react';
import { useFormatRuleset } from './useFormatRuleset';
import { isEmpty } from 'lodash';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useRulesetValidator } from 'components/RulesetBuilder';
import { WidgetDimensionField } from 'components/Dashboarding/Models/Widget';
interface ExplorerReportQueryResults extends UseReportResult<GetInboxItemsResp> {
    normalized: InboxItem[];
}

export const DEFAULT_DIMENSIONS: WidgetDimensionField[] = [
    { ref: '_system_id' },
    { ref: 'id' },
    { ref: 'author' },
    { ref: 'body' },
    { ref: 'build_status' },
    { ref: 'created_at' },
    { ref: 'key' },
    { ref: 'labels' },
    { ref: 'merged_at' },
    { ref: 'milestone' },
    { ref: 'project' },
    { ref: 'recommended_action' },
    { ref: 'recommended_actors' },
    { ref: 'required_approval_remaining_count' },
    { ref: 'resolved_issue_sysids' },
    { ref: 'resolving_pull_request_sysids' },
    { ref: 'resolution_state' },
    { ref: 'reviews' },
    { ref: 'title' },
    { ref: 'type' },
    { ref: 'url' },
    { ref: 'due_on', sort: 'ASC' },
    { ref: 'updated_at' }
];

interface UseExplorerReportOpts {
    fact: QueryOpFact;
    filters?: RuleGroupType | undefined;
    dimensions?: WidgetDimensionField[];
    userId?: string | null;
    companyId?: string | null;
    skip?: boolean | null;
    noSubscribe?: boolean;
    cacheDuration?: number;
}

export function useExplorerReport({
    fact,
    filters,
    dimensions = DEFAULT_DIMENSIONS,
    userId,
    companyId,
    skip,
    noSubscribe,
    cacheDuration
}: UseExplorerReportOpts): ExplorerReportQueryResults {
    // Check filters are valid
    const isFiltersValid = useRulesetValidator({
        fact,
        ruleset: filters
    });

    // Transform filter ruleset into query filters
    const formattedFilters = useFormatRuleset({ context: { userId }, filters });

    // Fetch items
    const reportQuery = useReport<GetInboxItemsResp>(GET_INBOX_ITEMS, {
        skip: skip || !companyId || !isFiltersValid,
        noSubscribe: noSubscribe,
        variables: {
            companyId: companyId,
            dimensions: dimensions.map(e => ({ field: e.ref, sort: e.sort })),
            filters: !isEmpty(formattedFilters) ? [formattedFilters] : undefined,
            cacheDuration: cacheDuration
        }
    });

    // Get normalized version
    const normalized = useMemo(() => reportQuery.data?.report.results.queriedItems || [], [
        reportQuery.data?.report.results.queriedItems
    ]);

    // Memoize result
    const rs = useMemo(() => {
        return {
            ...reportQuery,
            normalized
        };
    }, [reportQuery, normalized]);

    return rs;
}
