import { RuleGroupType } from 'react-querybuilder';
import { UseReportResult, useReport } from './useReport';
import { gql } from 'apollo-boost';
import { useFormatRuleset } from './useFormatRuleset';
import { useRulesetValidator } from 'components/RulesetBuilder';
import { isEmpty } from 'lodash';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { Coordinates } from 'contexts/DrilldownContext';

export interface GetGenericReportResp<T> {
    rs: T[];
}

export interface ReportDimension {
    field: string;
    label: string;
}

export type ReportFunctionArg =
    | { field: string }
    | { int: number }
    | { string: string }
    | { float: number }
    | { ts: string }
    | { bool: boolean }
    | { userRefs: string }
    | { stringL: string[] }
    | { intL: number[] }
    | { floatL: number[] }
    | { tsL: string[] };

export interface ReportFunction {
    function: string;
    args: (ReportFunctionArg | ReportFunction)[];
}

export interface ReportNamedFunction extends ReportFunction {
    label: string;
}

// Retrieve report
export const GET_GENERIC_REPORT_QUERY = gql`
    query(
        $fact: FactEnum!
        $metrics: [MetricInput!]
        $dimensions: [DimensionInput!]
        $filters: [FilterInput!]
        $cacheDuration: Int
        $companyId: String!
        $limit: Int
        $offset: Int
    ) {
        report(
            cacheDuration: $cacheDuration
            queries: [
                {
                    companyScopes: [$companyId]
                    target: "rs"
                    fact: $fact
                    metrics: $metrics
                    dimensions: $dimensions
                    filters: $filters
                    limit: $limit
                    offset: $offset
                }
            ]
        ) {
            id
            results
            pageInfo
            errors {
                message
            }
        }
    }
`;

interface UseGenericReportQueryResults<T> extends UseReportResult<GetGenericReportResp<T>> {
    normalized: T[];
}

interface UseGenericReportOpts {
    cacheDuration?: number;
    companyId?: string | null;
    dimensions: (ReportDimension | ReportNamedFunction)[];
    fact: QueryOpFact;
    filters?: RuleGroupType | undefined;
    limit?: number;
    offset?: number;
    metrics?: ReportNamedFunction[];
    noSubscribe?: boolean;
    userId?: string | null;
    pickedCoordinates?: Coordinates;
    skip?: boolean | null;
}

export function useGenericReport<T = Record<string, string>>({
    cacheDuration,
    companyId,
    dimensions,
    fact,
    filters,
    metrics,
    limit,
    offset,
    noSubscribe,
    userId,
    pickedCoordinates,
    skip
}: UseGenericReportOpts): UseGenericReportQueryResults<T> {
    // Check filters are valid
    const isFiltersValid = useRulesetValidator({
        fact,
        ruleset: filters
    });

    // Transform filter ruleset into query filters
    const formattedFilters = useFormatRuleset({ context: { userId }, filters, pickedCoordinates });

    // Fetch items
    const reportQuery = useReport<GetGenericReportResp<T>>(GET_GENERIC_REPORT_QUERY, {
        skip: skip || !companyId || !isFiltersValid,
        noSubscribe: noSubscribe,
        variables: {
            dimensions,
            fact,
            filters: !isEmpty(formattedFilters) ? [formattedFilters] : undefined,
            limit,
            offset,
            metrics,
            cacheDuration,
            companyId
        }
    });

    // Get normalized version
    const normalized = reportQuery.data?.report.results.rs || [];

    return {
        ...reportQuery,
        normalized: normalized
    };
}
