import { CurrentApiUserResp } from 'api/hq/queries/CurrentApiUser';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export interface SettingsActionTypes {
    type: typeof CHANGE_LOCALE;
    payload: string;
}

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';

export interface LoggedInUser extends CurrentApiUserResp {
    currentCompanyId?: string;
}

export interface UserStateActionTypes {
    type: typeof LOGIN_USER_SUCCESS;
    payload: LoggedInUser;
}

export interface UserTransitionActionTypes {
    type: typeof LOGIN_USER | typeof LOGOUT_USER;
}

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

interface MenuAction {
    type: typeof MENU_SET_CLASSNAMES | typeof MENU_CLICK_MOBILE_MENU;
    payload: { containerClassnames: string; menuClickCount: number };
}

interface MenuChangeAction {
    type: typeof MENU_CHANGE_HAS_SUB_ITEM_STATUS;
    payload: boolean;
}

interface MenuClassAction {
    type: typeof MENU_CONTAINER_ADD_CLASSNAME;
    payload: string;
}

export type MenuActionTypes = MenuAction | MenuClassAction | MenuChangeAction;
