import React, { ReactElement, useCallback, useMemo } from 'react';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { QueryOpDisplayFormatter, QUERY_OP_NATIVE_FORMATTER } from 'components/Dashboarding/DataSource/QueryOperators';
import Dropdown from 'react-bootstrap/Dropdown';
import { RichMessage } from 'components/RichMessage';
import { WidgetDimensionField, WidgetMetricField } from 'components/Dashboarding/Models/Widget';

interface Props<TField extends WidgetMetricField | WidgetDimensionField> {
    fact: QueryOpFact;
    field: TField;
    onChange: (e: TField) => void;
    className?: string;
}

function WidgetDisplayFormatterSelector<TField extends WidgetMetricField | WidgetDimensionField>({
    fact,
    field,
    onChange,
    className
}: Props<TField>): ReactElement<Props<TField>> | null {
    // Get all relevant fields
    const { queryOpFields } = useQueryOpFields({ fact });

    // Get currently selected field configuration
    const fieldConfig = useMemo(() => queryOpFields.find(e => e.id === field?.ref), [queryOpFields, field?.ref]);

    // Get currently selected display formatter
    const displayFormatter =
        field?.formatting?.formatter || fieldConfig?.defaultDisplayFormatter || QUERY_OP_NATIVE_FORMATTER;

    // Get list of available display formatters for the current field
    const displayFormatterList = useMemo(() => fieldConfig?.displayFormatters || [], [fieldConfig?.displayFormatters]);

    // Hook invoked when dimension formula is updated
    const onDisplayFormatterChange = useCallback(
        (e: QueryOpDisplayFormatter): void => onChange({ ...field, formatting: { ...field.formatting, formatter: e } }),
        [field, onChange]
    );

    // Abort if no config found or no display formatters are available or the function is an aggregator function
    if (!fieldConfig || displayFormatterList.length == 0 || (field.function && field.function != 'FORMULA'))
        return null;

    return (
        <Dropdown className={className}>
            <Dropdown.Toggle
                as="div"
                role="button"
                className="d-flex justify-content-between align-items-center h-100 p-3 text-dark border rounded"
            >
                <RichMessage id={`dashboarding.widget-display-formatter-selector.formatter.${displayFormatter}`} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
            >
                {/* Available formatters */}
                {displayFormatterList.map(e => {
                    return (
                        <Dropdown.Item key={e} onClick={() => onDisplayFormatterChange(e)}>
                            <RichMessage id={`dashboarding.widget-display-formatter-selector.formatter.${e}`} />
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default WidgetDisplayFormatterSelector;
