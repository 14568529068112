import React, { Dispatch, FunctionComponent, ReactNode, ReactNodeArray, useMemo, useState } from 'react';

export type Coordinates = (string | number | undefined)[];

export interface PickableData {
    id: string;
    type: string;
    index: number;
    label: string;
}

interface DrilldownContextProps {
    // The picked metric index from the drilled widget
    pickedMetricIndex: number;
    setPickedMetricIndex: Dispatch<React.SetStateAction<number>>;

    // The picked coordinates of the datapoint clicked on the drilled widget
    pickedCoordinates: Coordinates | undefined;
    setPickedCoordinates: Dispatch<React.SetStateAction<Coordinates | undefined>>;

    // The coordinates of the first record
    firstRecordCoordinates: Coordinates | undefined;
    setFirstRecordCoordinates: Dispatch<React.SetStateAction<Coordinates | undefined>>;

    // The coordinates to use for querying the data of the drilldown report.
    // Equals to `pickedCoordinates` if defined, otherwise equals to `firstRecordCoordinates`
    coordinates: Coordinates | undefined;

    // The pickable data of the widget, e.g. an array of the dimensions and metrics of the drilled widget
    pickableData: PickableData[] | undefined;
    setPickableData: Dispatch<React.SetStateAction<PickableData[] | undefined>>;
}

// Any component that subscribes to the drilldown context will be able to read the current
// context value from the closest matching `DrilldownContextProvider` above it in tree.
export const DrilldownContext = React.createContext({
    pickedMetricIndex: 0,
    setPickedMetricIndex: () => undefined,
    pickedCoordinates: undefined,
    setPickedCoordinates: () => undefined,
    firstRecordCoordinates: undefined,
    setFirstRecordCoordinates: () => undefined,
    coordinates: undefined,
    pickableData: undefined,
    setPickableData: () => undefined
} as DrilldownContextProps);

interface DrilldownContextProviderProps {
    children: ReactNodeArray | ReactNode;
}

// The drilldown context provider. We wrap the `DashboardWidget` and `DashboardWidgetEdit` components in it,
// so that we can access the drilldown data anywhere from the component tree.
export const DrilldownContextProvider: FunctionComponent<DrilldownContextProviderProps> = ({ children }) => {
    // State
    const [pickedMetricIndex, setPickedMetricIndex] = useState<number>(0);
    const [pickedCoordinates, setPickedCoordinates] = useState<Coordinates>();
    const [firstRecordCoordinates, setFirstRecordCoordinates] = useState<Coordinates>();
    const [pickableData, setPickableData] = useState<PickableData[]>();

    const coordinates = useMemo(() => pickedCoordinates || firstRecordCoordinates, [
        firstRecordCoordinates,
        pickedCoordinates
    ]);

    return (
        <DrilldownContext.Provider
            value={{
                pickedMetricIndex,
                setPickedMetricIndex,
                pickedCoordinates,
                setPickedCoordinates,
                firstRecordCoordinates,
                setFirstRecordCoordinates,
                coordinates,
                pickableData,
                setPickableData
            }}
        >
            {children}
        </DrilldownContext.Provider>
    );
};
