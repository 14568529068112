import React, { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardWithHeaders } from 'api/hq/queries/Dashboard';
import IconPicker from 'components/Design/ColorPickers/IconPicker';
import { useRichIntl } from 'components/RichMessage';
import { dashboardViewPath, DASHBOARD_ROOT_PATH } from 'constants/routeBuilders';
import { NavLink, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { kpUnpin } from 'util/customIcons';
import { useResourcePin } from 'api/hq/hooks/useResourcePin';

interface Props {
    dashboard: DashboardWithHeaders;
    onUnpin: () => void;
}

// Nav item used for dashboards in the Sidebar
const DashboardLinkItem: FunctionComponent<Props> = ({ dashboard, onUnpin }: Props) => {
    // Services
    const navigate = useNavigate();
    const intl = useRichIntl();

    // Unpinning success callback
    const onSuccess = useCallback(() => {
        // Notify parent
        onUnpin();

        // Redirect to root path
        navigate(DASHBOARD_ROOT_PATH);
    }, [navigate, onUnpin]);

    // Unpinning service
    const { loading: pinLoading, setPin } = useResourcePin({
        resourceType: 'DASHBOARD',
        resourceId: dashboard.id,
        onSuccess
    });

    // Render
    return (
        <NavLink to={dashboardViewPath({ dashboardId: dashboard.id })}>
            <div className="menu-icon-collapsed">
                <IconPicker icon={dashboard.icon} />
            </div>
            <div className="menu-icon">
                <IconPicker icon={dashboard.icon} />
            </div>
            <div className="menu-label text-start text-truncate">{dashboard.name}</div>
            <div className="menu-action">
                {pinLoading ? (
                    <Spinner animation="border" size="sm" variant="white" />
                ) : (
                    <FontAwesomeIcon
                        onClick={() => setPin(false)}
                        icon={kpUnpin}
                        className="me-2"
                        title={intl.formatMessage({
                            id: `menu.dashboard-menu.unpin`
                        })}
                    />
                )}
            </div>
        </NavLink>
    );
};

export default DashboardLinkItem;
