import React, { FunctionComponent, useMemo } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { InboxItem, InboxItemRelated, InboxItemWithRelated } from 'api/viz/queries/InboxItem';
import InboxRegularFeed from './InboxRegularFeed';
import InboxUnifiedFeed from './InboxUnifiedFeed';
import { useInView } from 'react-intersection-observer';
import { sortBy } from 'lodash';

interface Props {
    item: InboxItemWithRelated;
    tabId: string;
}

const InboxFeedTabContent: FunctionComponent<Props> = ({ item, tabId }: Props) => {
    // Services
    const { ref, inView } = useInView({ triggerOnce: true });

    // Extract sub items
    const subItems = useMemo(
        () => (item.type === 'pull_request' ? item.resolved_issues : item.resolving_pull_requests),
        [item.resolved_issues, item.resolving_pull_requests, item.type]
    );
    const hasSubItems = subItems.length > 0;

    // Main item
    const allItems = useMemo(() => [item as InboxItem | InboxItemRelated].concat(sortBy(subItems, 'created_at')), [
        item,
        subItems
    ]);

    //
    return (
        <div ref={ref}>
            <TabContent activeTab={tabId}>
                {/* Unified feed OR main feed (if no sub-items) */}
                <TabPane tabId="-1">
                    <InboxUnifiedFeed item={item} inView={inView} />
                </TabPane>

                {/* Feed for each individual item */}
                {hasSubItems &&
                    allItems.map((e, index) => {
                        return (
                            <TabPane tabId={index.toString()} key={e._system_id}>
                                <InboxRegularFeed item={e} inView={inView} />
                            </TabPane>
                        );
                    })}
            </TabContent>
        </div>
    );
};

export default InboxFeedTabContent;
