import React, { FunctionComponent, useState, Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import { appConnectionPath } from 'constants/routeBuilders';
import {
    INTEGRATION_DISABLED_STATUS,
    INTEGRATION_DISCONNECTED_STATUS,
    INTEGRATION_ACTIVE_STATUS,
    INTEGRATION_DISCOVERING_STATUSES
} from 'constants/defaultValues';
import { CompanyState } from 'redux/auth/reducer';
import DisconnectAppModal from './DisconnectAppModal';
import { RichMessage, RichTooltip } from 'components/RichMessage';
import { AppResp } from 'api/hq/queries/App';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    status: string;
    app: AppResp;
    company: CompanyState;
    onDisconnect: () => void;
    integrationId?: string;
    postConnectRedirectUri?: string;
    skipDiscoveryStatus?: boolean;
    className?: string;
}

const AppActionBtn: FunctionComponent<Props> = ({
    status,
    app,
    company,
    onDisconnect,
    integrationId,
    postConnectRedirectUri,
    skipDiscoveryStatus,
    className
}: Props) => {
    // Services
    const [isConnecting, setIsConnecting] = useState<boolean>(false);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState<boolean>(false);

    // Extract data
    const isAdmin = company.role == 'ADMIN';
    const connectLink = appConnectionPath({
        provider: app.provider,
        companyId: company.id,
        returnTo: postConnectRedirectUri
    });

    // Evaluate real status used for display
    // if skipDiscoveryStatus is set to true the integration will be considered as 'ACTIVE' even
    // if it is still discovering
    const realStatus =
        skipDiscoveryStatus && INTEGRATION_DISCOVERING_STATUSES.includes(status) ? INTEGRATION_ACTIVE_STATUS : status;

    // Action performed when connecting an app
    const connectApp = (): void => {
        setIsConnecting(true);
        window.location.href = connectLink;
    };

    switch (realStatus) {
        case INTEGRATION_ACTIVE_STATUS:
        case INTEGRATION_DISCONNECTED_STATUS:
            return (
                <>
                    {/* Button with explanation tooltip when the button is disabled (not admin) */}
                    <RichTooltip id="components.app-connect-item.action-forbidden" disabled={isAdmin}>
                        <Button
                            variant="outline-danger"
                            onClick={() => setDisconnectModalOpen(true)}
                            disabled={!isAdmin}
                            track-id={`click-app-disconnect-${app.provider}`}
                            className={className}
                        >
                            <RichMessage id="components.app-connect-item.disconnect" />
                        </Button>
                    </RichTooltip>

                    {/* Disconnect modal */}
                    <DisconnectAppModal
                        isOpen={disconnectModalOpen}
                        toggle={() => setDisconnectModalOpen(!disconnectModalOpen)}
                        integrationId={integrationId || '-'}
                        onSuccess={onDisconnect}
                    />
                </>
            );
        case INTEGRATION_DISABLED_STATUS:
            return (
                // Button with explanation tooltip when the button is disabled (not admin)
                <RichTooltip id="components.app-connect-item.action-forbidden" disabled={isAdmin}>
                    <AsyncButton
                        variant="outline-dark"
                        onClick={connectApp}
                        disabled={!isAdmin || isConnecting}
                        track-id={`click-app-connect-${app.provider}`}
                        className={className}
                        messageProps={{ id: 'components.app-connect-item.connect' }}
                        loading={isConnecting}
                        loadingMessageProps={{ id: 'components.app-connect-item.connecting' }}
                    />
                </RichTooltip>
            );
        default:
            return (
                <div className={classNames('d-flex align-items-center justify-content-center', className)}>
                    <Spinner animation="border" size="sm" variant="dark" className="me-2" />
                    <div className="text-light">
                        <RichMessage id="components.app-connect-item.discovering" />
                    </div>
                </div>
            );
    }
};

export default AppActionBtn;
