import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { BuildIcon } from './ItemTitle';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { ItemUser } from 'api/viz/queries/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

interface ActionableItem {
    url: string | undefined;
    author: ItemUser;
    recommended_action?: string;
    build_status?: string;
    required_approval_remaining_count: number;
}

interface Props {
    item: ActionableItem;
    className?: string;
    short?: boolean;
    showBuildInProgress?: boolean;
}

const ActionLabel: FunctionComponent<Props> = ({ item, short, showBuildInProgress }: Props) => {
    // Services
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);

    // Abort if item has no recommended action
    if (!item.recommended_action || item.recommended_action === 'NONE') return <span className="text-muted">-</span>;

    // Messaging namespace
    const msgNs = short ? 'title-short' : 'title';

    // Contextualize the recommended action
    const userRefs = user?.identities.nodes.map(e => `${e.provider}:${e.datalinkId}`) || [];
    const recommendedAction =
        item.recommended_action === 'REVIEW' && userRefs?.includes(item.author.ref)
            ? 'WAIT_FOR_REVIEW'
            : item.recommended_action;

    // Render component
    return (
        <Button
            as="a"
            variant="outline-primary"
            className="d-inline-block d-md-flex fw-bold"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {/* Action label */}
            {showBuildInProgress && item.build_status && item.build_status === 'IN_PROGRESS' ? (
                <BuildIcon className="align-middle" item={item} />
            ) : (
                <span className="me-2">
                    <FormattedMessage
                        id={`reports.shared.action-label.${msgNs}.${recommendedAction}`}
                        values={{ required_approval_remaining_count: item.required_approval_remaining_count }}
                    />
                </span>
            )}
            <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Button>
    );
};

export default ActionLabel;
