import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import Card from 'react-bootstrap/Card';

const BoardCardLoader: FunctionComponent = () => {
    return (
        <Card className="board-card mb-3">
            <Card.Body className="d-flex justify-content-center align-items-center m-0">
                <ContentLoader
                    speed={2}
                    width={200}
                    height={150}
                    viewBox="0 0 200 150"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#ededed"
                >
                    <rect x="31" y="7" rx="3" ry="3" width="88" height="6" />
                    <rect x="3" y="29" rx="3" ry="3" width="52" height="9" />
                    <rect x="0" y="56" rx="3" ry="3" width="200" height="6" />
                    <rect x="-1" y="73" rx="3" ry="3" width="178" height="6" />
                    <circle cx="10" cy="10" r="10" />
                    <circle cx="186" cy="134" r="10" />
                    <circle cx="11" cy="135" r="10" />
                </ContentLoader>
            </Card.Body>
        </Card>
    );
};

export default BoardCardLoader;
