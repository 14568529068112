import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import QueryBuilder, { RuleGroupType, RuleType } from 'react-querybuilder';
import RulesetOperator from './RulesetOperator';
import RulesetFieldSelector from './RulesetFieldSelector';
import { useRulesetFields } from './useRulesetFields';
import {
    QueryOpFilterDisplayType,
    QueryOpFilterScope,
    QUERY_OP_FILTER_COMBINATORS,
    QUERY_OP_FILTER_FUNCTIONS
} from 'components/Dashboarding/DataSource/QueryOperators';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { debounce } from 'lodash';
import AddRuleAction from './AddRuleAction';
import AddGroupAction from './AddGroupAction';
import RemoveAction from './RemoveAction';
import DragHandle from './DragHandle';
import CombinatorSelector from './CombinatorSelector';
import RulesetValueEditor from './RulesetValueEditor';
import { RuleGroup } from './RuleGroup';
import { RuleValue } from './useRulesetValidator';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';

export interface RulesetBuilderContext {
    fact: QueryOpFact;
    drilldownEnabled?: boolean;
}

interface Props {
    fact: QueryOpFact;
    ruleset?: RuleGroupType;
    onChange: (e: RuleGroupType) => void;
    scope: QueryOpFilterScope;
    drilldownEnabled?: boolean;
}

export const RulesetBuilder: FunctionComponent<Props> = ({
    fact,
    ruleset,
    onChange,
    scope,
    drilldownEnabled
}: Props) => {
    const [localRuleset, setLocalRuleset] = useState<RuleGroupType | undefined>(ruleset);

    // Get debounced version of onChange
    // This is desirable as the downstream value selectors may issue successive
    // updates - especially to select default values for enums (select fields)
    const debouncedOnChange = useMemo(() => debounce(onChange, 50), [onChange]);

    // Get all ruleset fields (native and custom)
    const { rulesetFields } = useRulesetFields({ fact, scope });

    // Retrieve the fact's fields
    const { queryOpFields } = useQueryOpFields({ fact });

    // Invoke parent hook
    const onQueryChange = useCallback(
        (e: RuleGroupType): void => {
            setLocalRuleset(e);
            debouncedOnChange(e);
        },
        [debouncedOnChange]
    );

    // This method is invoked by the querybuilder library on operator change,
    // if the `resetOnOperatorChange` option is enabled
    const getDefaultValue = useCallback(
        (rule: RuleType): RuleValue | undefined => {
            // Extract field config
            const fieldConfig = queryOpFields.find(e => e.name == rule.field);

            // Extract operator config
            const operatorConfig = QUERY_OP_FILTER_FUNCTIONS.find(e => e.name === rule.operator);
            const displayType: QueryOpFilterDisplayType =
                operatorConfig?.displayType || fieldConfig?.filterDisplayType || 'text';

            // Extract the previous type and value
            const ruleValueType = rule.value?.type;
            const ruleValueValue = rule.value?.value;

            // Set the new value, depending on the display type
            switch (displayType) {
                case 'no-arg':
                    return undefined;
                case 'text':
                case 'radio':
                case 'select':
                case 'select-company-user':
                    return {
                        type: 'string',
                        value: ''
                    };
                case 'text-list':
                    return {
                        type: 'stringL',
                        value: ''
                    };
                case 'number':
                    return {
                        type: 'int',
                        value: undefined
                    };
                case 'checkbox':
                    return { type: 'bool', value: false };
                case 'datepicker':
                    // Try to re-use the previous value if the previous ruleValue type was `ts`
                    return {
                        type: 'ts',
                        value: {
                            value: (ruleValueType == 'ts' && ruleValueValue.value) || '',
                            granularity: (ruleValueType == 'ts' && ruleValueValue.granularity) || 'YEAR_MONTH_DAY'
                        }
                    };
                case 'daterangepicker':
                    return { type: 'rts', value: { fromDate: '', toDate: '', granularity: 'YEAR_MONTH_DAY' } };
                case 'timeperiod':
                    // Try to re-use the previous values if the previous ruleValue type was `otp` or `tp`
                    return {
                        type: 'tp',
                        value: {
                            amount: (['otp', 'tp'].includes(ruleValueType) && ruleValueValue?.amount) || undefined,
                            timePeriod: (['otp', 'tp'].includes(ruleValueType) && ruleValueValue?.timePeriod) || 'HOUR'
                        }
                    };
                case 'timeperiod-with-offset':
                    // Try to re-use the previous values if the previous ruleValue type was `otp` or `tp`
                    return {
                        type: 'otp',
                        value: {
                            amount: (['otp', 'tp'].includes(ruleValueType) && ruleValueValue?.amount) || undefined,
                            timePeriod: (['otp', 'tp'].includes(ruleValueType) && ruleValueValue?.timePeriod) || 'HOUR',
                            offset: (['otp', 'tp'].includes(ruleValueType) && ruleValueValue?.offset) || 'AGO'
                        }
                    };
            }
        },
        [queryOpFields]
    );

    // Resync local state when parent gets updated
    useEffect(() => setLocalRuleset(ruleset), [ruleset]);

    // Render query builder
    return (
        <QueryBuilder
            query={localRuleset}
            context={{ fact, drilldownEnabled }}
            combinators={QUERY_OP_FILTER_COMBINATORS}
            operators={QUERY_OP_FILTER_FUNCTIONS}
            fields={rulesetFields}
            enableDragAndDrop={true}
            onQueryChange={onQueryChange}
            getDefaultValue={getDefaultValue}
            // The `resetOnOperatorChange` option must be enabled
            // to execute the `getDefaultValue()` function on operator change
            resetOnOperatorChange={true}
            enableMountQueryChange={false}
            showCombinatorsBetweenRules={true}
            controlElements={{
                fieldSelector: RulesetFieldSelector,
                operatorSelector: RulesetOperator,
                valueEditor: RulesetValueEditor,
                addRuleAction: AddRuleAction,
                addGroupAction: AddGroupAction,
                removeRuleAction: RemoveAction,
                removeGroupAction: RemoveAction,
                dragHandle: DragHandle,
                combinatorSelector: CombinatorSelector,

                // We encountered state issues due to the use of indexes as key when iterating on rules.
                // An issue has been opened on their github: https://github.com/react-querybuilder/react-querybuilder/issues/374
                // In the mean time, we override the RuleGroup with a copy/paste of the library's native RuleGroup,
                // but in this version we use rule ids as key which fixes the issue.
                // When `react-querybuilder` releases a fix and we upgrade to that release, we can delete this.
                ruleGroup: RuleGroup
            }}
        />
    );
};
