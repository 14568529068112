import { useRichIntl } from 'components/RichMessage';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRenderedTextWidth } from 'util/useRenderedTextWidth';
import TransparentInput from './TransparentInput';

interface Props {
    className?: string;
    style?: object;
    disabled?: boolean;
    onChange?: (e: string) => void;
    onCommit?: (e: string) => void;
    placeholderId?: string;
    value?: string;
    variant?: 'regular' | 'light' | 'dark' | 'grey-1' | 'none';
}

const DynamicWidthTransparentInput: FunctionComponent<Props> = ({
    className,
    style,
    disabled,
    onChange,
    onCommit,
    placeholderId,
    value,
    variant = 'dark'
}) => {
    // Services
    const intl = useRichIntl();

    // State
    const [textValue, setTextValue] = useState<string>(value || '');

    // Evaluate placeholder
    const placeholder = placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined;

    // Compute the width of the rendered text
    const renderedTextWidth = useRenderedTextWidth(textValue, placeholder, className);

    // Hook invoked when the value changes
    const onValueChange = useCallback(
        (val: string): void => {
            setTextValue(val);
            onChange && onChange(val);
        },
        [onChange]
    );

    // Resync local state from parent
    useEffect(() => setTextValue(value || ''), [value]);

    // Render
    return (
        <TransparentInput
            style={{ ...style, width: `min(${renderedTextWidth}px, 100%)` }}
            value={value}
            placeholderId={placeholderId}
            onChange={onValueChange}
            onCommit={onCommit}
            className={className}
            variant={variant}
            disabled={disabled}
        />
    );
};

export default DynamicWidthTransparentInput;
