import React, { FunctionComponent, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage } from 'components/RichMessage';
import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import ProjectListItem from 'routes/settings/AppManagement/ProjectList/ProjectListItem';
import { ProjectResp, EnableProjectsResp, ENABLE_PROJECTS } from 'api/hq/queries/Project';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox/SelectAllCheckbox';
import SearchBar from 'components/List/SearchBar';
import { useMutation } from '@apollo/react-hooks';
import { useSafeState } from 'util/useSafeState';
import { DropdownOption } from 'components/List/FilteringDropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';
import { useCompanyIntegrations } from 'api/hq/hooks/useCompanyIntegrations';
import {
    APP_IMAGES,
    INTEGRATION_DISCOVERING_STATUSES,
    ONBOARDING_AUTO_TICK_THRESHOLD,
    PROJECT_DISABLED_STATUS
} from 'constants/defaultValues';
import { faChevronLeft, faComment, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { sortBy, uniqBy } from 'lodash';
import FullPageLoader from 'components/Design/FullPageLoader';
import { Alert } from 'react-bootstrap';
import { DropdownMultiselect } from 'components/DropdownMultiselect';
import AppIcon from 'components/Design/AppIcon';
import { ReactComponent as ProjectsDiagram } from 'assets/img/onboarding/projects-diagram.svg';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { getNestedPropertyValue } from 'util/ObjectOperators';
import { toastQueryError } from 'components/Toast';
import { ONBOARDING1_STEP2, ONBOARDING_SKIPPED_STEP_KEY } from 'constants/routeBuilders';
import { useMeasure } from 'react-use';
import Header from './Header';
import { SortableHeader, SortConfig } from 'components/SortableHeader';

// Only consider data source integrations on this page
// Ignore other types
const INTEGRATION_CATEGORIES = ['CODE_MANAGEMENT', 'PROJECT_MANAGEMENT'];

// Where to go before/after this step
const BACK_STEP_PATH = '/flows/onboarding/app-selection';
const NEXT_STEP_PATH = '/flows/onboarding/configure-dashboard';
const NEXT_STEP_SKIPPED_PATH = `/flows/onboarding/configure-dashboard/template-selection?${ONBOARDING_SKIPPED_STEP_KEY}=${ONBOARDING1_STEP2}`;

// Columns identifiers
const PROJECT_COLUMN = 'name';
const OWNER_COLUMN = 'account.name';

// Concatenate the project's app name with the project's account name
const concatAppAccount = (e: ProjectResp): string => `${e.app.name}/${e.account.name}`;

// App filter function
const filterByApps = (filterValue: string[], e: ProjectResp): boolean => {
    return filterValue.length == 0 || filterValue.includes(concatAppAccount(e));
};

const Step2ProjectImport: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();

    // State
    const [selectedProjects, setSelectedProjects] = useState<ProjectResp[]>([]);
    const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
    const [appsFilter, setAppsFilter] = useState<string[]>([]);
    const [enablementInProgress, setEnablementInProgress] = useSafeState<boolean>(false);
    const [sort, setSort] = useState<SortConfig>();

    // Height of the `Next` section
    const [ref, { height: nextHeight }] = useMeasure<HTMLDivElement>();

    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Fetch integrations
    const { data: integrationsData, loading: integrationsLoading } = useCompanyIntegrations({
        companyId: company?.id,
        categories: INTEGRATION_CATEGORIES
    });

    // Get projects
    const { data: projectData, loading: projectLoading } = useCompanyProjects({ companyId: company?.id });

    // Mutation Declarations
    const [enableProjects] = useMutation<EnableProjectsResp>(ENABLE_PROJECTS);

    // Check if integration is currently discovering
    const integrationList = useMemo(() => integrationsData?.integrations.nodes || [], [
        integrationsData?.integrations.nodes
    ]);
    const areIntegrationsDiscovering = useMemo(
        () =>
            integrationList.reduce((memo, elem): boolean => {
                return memo || INTEGRATION_DISCOVERING_STATUSES.includes(elem.status);
            }, false),
        [integrationList]
    );

    // Get provider projects and filtered projects
    const projectList = useMemo(() => sortBy(projectData?.company.projects.nodes || [], 'name'), [
        projectData?.company.projects.nodes
    ]);

    // Extract App List and generate dropdown options
    const appAccountFilterOptions: DropdownOption[] = useMemo(
        () =>
            sortBy(
                uniqBy(
                    projectList.map(e => ({
                        id: concatAppAccount(e),
                        name: concatAppAccount(e),
                        img: <AppIcon src={APP_IMAGES[e.app.provider]} />
                    })),
                    'name'
                )
            ),
        [projectList]
    );

    const filteredProjectList: ProjectResp[] = useMemo(
        () =>
            projectList
                .map(e => ({ ...e, name: e.name.replace(`${e.account.name}/`, '') }))
                .filter(e => filterByApps(appsFilter, e))
                .filter(e => {
                    return !searchFilter || e.name.toLowerCase().includes(searchFilter.toLowerCase());
                })
                .sort((e1, e2) => {
                    if (sort)
                        return getNestedPropertyValue(e1, sort.columnId) < getNestedPropertyValue(e2, sort.columnId)
                            ? sort.asc
                                ? -1
                                : 1
                            : getNestedPropertyValue(e1, sort.columnId) > getNestedPropertyValue(e2, sort.columnId)
                            ? sort.asc
                                ? 1
                                : -1
                            : 0;
                    return 0;
                }),
        [appsFilter, projectList, searchFilter, sort]
    );

    const selectedProjectIds = useMemo(() => selectedProjects.map(e => e.id), [selectedProjects]);

    // Auto-tick projects by default if user has a small account (X projects or less)
    const isAutoTickEnabled = useMemo(
        () =>
            !integrationsLoading &&
            !projectLoading &&
            !areIntegrationsDiscovering &&
            projectList.length > 0 &&
            projectList.length <= ONBOARDING_AUTO_TICK_THRESHOLD,
        [areIntegrationsDiscovering, integrationsLoading, projectList.length, projectLoading]
    );

    // Enable 'Next' button if any project has been selected or enabled already
    // We wait for at least X projects to be loaded or the discovery to be finished
    const isNextEnabled = useMemo(
        () =>
            !integrationsLoading &&
            !projectLoading &&
            (projectList.length >= ONBOARDING_AUTO_TICK_THRESHOLD || !areIntegrationsDiscovering) &&
            (selectedProjects.length > 0 || projectList.some(e => e.status != PROJECT_DISABLED_STATUS)),
        [areIntegrationsDiscovering, integrationsLoading, projectList, projectLoading, selectedProjects.length]
    );

    // Show skip button only if no projects have been selected and 0 project has been imported yet
    // Also we require that at least X projects have been loaded or that the integration
    // has finished discovering
    const showSkipButton = useMemo(
        () =>
            !integrationsLoading &&
            !projectLoading &&
            selectedProjects.length == 0 &&
            (projectList.length > ONBOARDING_AUTO_TICK_THRESHOLD || !areIntegrationsDiscovering) &&
            !projectList.some(e => e.status != PROJECT_DISABLED_STATUS),
        [areIntegrationsDiscovering, integrationsLoading, projectList, projectLoading, selectedProjects.length]
    );

    // Hook invoked when users click the "Select All" checkbox
    const onSelectAllChange = useCallback(
        (selectAll: boolean) => {
            selectAll
                ? setSelectedProjects(filteredProjectList.filter(e => e.status == PROJECT_DISABLED_STATUS))
                : setSelectedProjects([]);
        },
        [filteredProjectList]
    );

    // Handler called when projects are selected/deselected
    const onSelectChange = useCallback(
        (project: ProjectResp, selected: boolean): void => {
            if (selected && selectedProjectIds.indexOf(project.id) < 0) {
                setSelectedProjects([...selectedProjects, project]);
            }

            if (!selected && selectedProjectIds.indexOf(project.id) >= 0) {
                setSelectedProjects(selectedProjects.filter(e => e.id !== project.id));
            }
        },
        [selectedProjects, selectedProjectIds]
    );

    // Send request to enable the selected projects
    const enableSelected = useCallback(async () => {
        setEnablementInProgress(true);

        try {
            if (selectedProjects.length > 0) {
                const resp = await enableProjects({ variables: { ids: selectedProjectIds } });
                const enableResp = resp.data?.enableProjects;

                if (enableResp?.success) {
                    setSelectedProjects([]);
                } else {
                    toastQueryError({ ...enableResp?.errors[0], namespace: 'enable-projects' });
                }
            }

            navigate(NEXT_STEP_PATH);
        } catch {
            toastQueryError({ namespace: 'enable-project' });
        } finally {
            setEnablementInProgress(false);
        }
    }, [enableProjects, navigate, selectedProjectIds, selectedProjects.length, setEnablementInProgress]);

    // Hook invoked when user clicks a header of the table
    const handleHeaderClick = useCallback(
        (columnId: string) => {
            if (!sort || sort.columnId != columnId) {
                setSort({ columnId, asc: true });
                return;
            }

            if (!sort.asc) {
                setSort(undefined);
                return;
            }

            setSort({ ...sort, asc: !sort.asc });
        },
        [sort]
    );

    // Select all projects by default if auto-tick is to be enabled
    useEffect(() => {
        if (isAutoTickEnabled) setSelectedProjects(projectList.filter(e => e.status == PROJECT_DISABLED_STATUS));
    }, [isAutoTickEnabled, projectList]);

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675, minHeight: 750 }}>
            {/* Left section */}
            <>
                {projectLoading || !company || integrationsLoading ? (
                    <>
                        {/* Display loading screen if projects are still loading */}
                        <FullPageLoader />
                    </>
                ) : integrationList.length === 0 ? (
                    <>
                        {/* The user has not connected any app. Skip this step. */}
                        <Navigate to={NEXT_STEP_PATH} />
                    </>
                ) : (
                    <>
                        {/* The user has connected apps. Display the list of projects they can import. */}
                        <div
                            className="flex-grow-1 d-flex flex-column"
                            style={{ height: `calc(100% - ${nextHeight}px` }}
                        >
                            {/* Title */}
                            <div className="flex-grow-0 d-flex align-items-baseline mt-4 mb-5">
                                <Button
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    as={Link as any}
                                    to={BACK_STEP_PATH}
                                    variant="link"
                                    className="p-0"
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                                </Button>
                                <h1 className="fw-bolder">
                                    <RichMessage id="flows.onboarding.project-import.title" />
                                </h1>
                            </div>

                            {/* Info */}
                            <Alert
                                className="d-none d-sm-block flex-grow-0 w-100 px-5 mb-4 border-0 text-primary rounded-4"
                                style={{ backgroundColor: '#eff5fe' }}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="position-absolute my-4 mx-3"
                                    size="lg"
                                    style={{ top: 0, left: 0 }}
                                />
                                <h5 className="my-2 fw-500">
                                    <RichMessage id="flows.onboarding.project-import.info.title" />
                                </h5>
                                <div className="text-dark" style={{ opacity: 0.8, textAlign: 'justify' }}>
                                    <RichMessage id="flows.onboarding.project-import.info.content" />
                                </div>
                            </Alert>

                            {/* Loader displayed when integrations are discovering */}
                            {areIntegrationsDiscovering && (
                                <Row className="flex-grow-0 mb-4">
                                    <Col xs="12">
                                        <Card className="border-0">
                                            <Card.Body className="text-center">
                                                <Spinner animation="border" variant="primary" />
                                                <div className="text-primary">
                                                    <RichMessage id="flows.onboarding.project-import.list.discovering" />
                                                    ({projectList.length})
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )}

                            {/* Filters */}
                            {projectList.length > 0 && (
                                <Row
                                    className="flex-grow-0 mx-0 mb-4 bg-grey-tone-12 rounded-4"
                                    style={{ padding: '0.75rem 1rem' }}
                                    xs={1}
                                    lg={appAccountFilterOptions.length > 1 ? 2 : 1}
                                >
                                    {/* Search on project full name */}
                                    <Col className="p-0">
                                        <SearchBar
                                            className="m-1"
                                            debounceTime={200}
                                            onChange={setSearchFilter}
                                            value={searchFilter}
                                        />
                                    </Col>

                                    {/* Filter by App/Account */}
                                    <Col className="p-0">
                                        {appAccountFilterOptions.length > 1 && (
                                            <DropdownMultiselect
                                                className="m-1"
                                                placeholderId="flows.onboarding.project-import.filter.placeholder"
                                                options={appAccountFilterOptions}
                                                onChange={selectedFilters =>
                                                    setAppsFilter(selectedFilters.map(e => e.id))
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                            )}

                            {/* Project List */}
                            <div className="flex-grow-1 overflow-y-auto mb-5">
                                <table className="w-100 onboarding-projects-table">
                                    <thead>
                                        <tr className="align-middle text-dark">
                                            <th className="text-center checkbox">
                                                {filteredProjectList.length > 0 && (
                                                    <SelectAllCheckbox
                                                        selectedCount={selectedProjects.length}
                                                        totalCount={
                                                            filteredProjectList.filter(
                                                                e => e.status == PROJECT_DISABLED_STATUS
                                                            ).length
                                                        }
                                                        onSelectChange={onSelectAllChange}
                                                    />
                                                )}
                                            </th>
                                            <SortableHeader
                                                id={PROJECT_COLUMN}
                                                sort={sort}
                                                onClick={() => handleHeaderClick(PROJECT_COLUMN)}
                                            >
                                                <RichMessage id="settings.project-list.table.heading.project-name" />
                                            </SortableHeader>
                                            <SortableHeader
                                                id={OWNER_COLUMN}
                                                sort={sort}
                                                onClick={() => handleHeaderClick(OWNER_COLUMN)}
                                            >
                                                <RichMessage id="settings.project-list.table.heading.owner" />
                                            </SortableHeader>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectList.length > 0 && filteredProjectList.length > 0 ? (
                                            // Project list
                                            filteredProjectList.map(project => (
                                                <ProjectListItem
                                                    key={project.id}
                                                    project={project}
                                                    onSelectChange={onSelectChange}
                                                    selected={selectedProjectIds.indexOf(project.id) >= 0}
                                                    compact={true}
                                                />
                                            ))
                                        ) : projectList.length > 0 && filteredProjectList.length === 0 ? (
                                            // Empty search list
                                            <tr className="text-center">
                                                <td className="text-light py-4" colSpan={4}>
                                                    <RichMessage id="settings.project-list.filtered-empty" />
                                                </td>
                                            </tr>
                                        ) : integrationList.length === 0 ? (
                                            // No apps connected
                                            <tr className="text-center mt-5">
                                                <td colSpan={4}>
                                                    <FontAwesomeIcon
                                                        icon={faComment}
                                                        className="display-2 text-primary mb-2"
                                                    />
                                                    <br />
                                                    <div className="h5">
                                                        <RichMessage id="settings.project-list.integrations-empty" />
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <RichMessage id="settings.project-list.integrations-empty-explanation" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : !areIntegrationsDiscovering ? (
                                            // No projects
                                            <tr className="text-center mt-5">
                                                <td colSpan={4}>
                                                    <FontAwesomeIcon
                                                        icon={faAddressBook}
                                                        className="display-2 text-primary mb-2"
                                                    />
                                                    <br />
                                                    <div className="h4">
                                                        <RichMessage id="settings.project-list.empty" />
                                                        <br />
                                                    </div>
                                                    <div className="h6">
                                                        <RichMessage id="settings.project-list.empty-explanation" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}

                {/* Continue / Skip buttons */}
                <div className="flex-grow-0" ref={ref}>
                    <div className="mb-4">
                        <Button
                            variant="dark"
                            track-id="onboarding/click-projects-enable"
                            onClick={enableSelected}
                            disabled={enablementInProgress || !isNextEnabled}
                            className="justify-content-center w-100"
                        >
                            <RichMessage
                                id="flows.onboarding.project-import.action.import"
                                values={{ projectsCount: selectedProjects.length }}
                            />
                        </Button>
                        {showSkipButton && (
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                variant="link"
                                to={NEXT_STEP_SKIPPED_PATH}
                                className="justify-content-center text-grey-1 fw-500"
                            >
                                <RichMessage id="flows.onboarding.project-import.action.skip" />
                            </Button>
                        )}
                    </div>
                </div>
            </>

            {/* Right section */}
            <ProjectsDiagram
                className="m-auto"
                style={{ width: '75%', height: '75%', maxHeight: '500px', maxWidth: '500px' }}
            />
        </LayoutSplitScreen>
    );
};

export default Step2ProjectImport;
