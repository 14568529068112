import React, { FunctionComponent, useMemo } from 'react';

import { Converter, ConverterOptions, getDefaultOptions } from 'showdown';

interface Props {
    className?: string;
    markup: string;
    flavor?: string;
}

// See {@link https://github.com/showdownjs/showdown/blob/master/src/showdown.js#L10}
const FLAVORS: { [key: string]: ConverterOptions } = {
    github: {
        omitExtraWLInCodeBlocks: true,
        simplifiedAutoLink: true,
        literalMidWordUnderscores: true,
        strikethrough: true,
        tables: true,
        tablesHeaderId: true,
        ghCodeBlocks: true,
        tasklists: true,
        disableForced4SpacesIndentedSublists: true,
        simpleLineBreaks: true,
        requireSpaceBeforeHeadingText: true,
        ghCompatibleHeaderId: true,
        ghMentions: true,
        backslashEscapesHTMLTags: true,
        emoji: true,
        splitAdjacentBlockquotes: true,
        openLinksInNewWindow: true
    },
    // added
    trello: {
        ghMentions: true,
        ghMentionsLink: '//trello.com/{u}',
        openLinksInNewWindow: true
    },
    // added
    gitlab: {
        ghMentions: true,
        ghMentionsLink: '//gitlab.com/{u}',
        openLinksInNewWindow: true
    },
    // added
    clickup: {
        ghMentions: false,
        openLinksInNewWindow: true
    }
};

// Github markdown processor based on showdownjs
export const GithubMarkdown: FunctionComponent<Props> = ({ className, markup, flavor = 'github' }: Props) => {
    // Initialize converter
    const flavorOptions = useMemo(() => Object.assign({}, getDefaultOptions(), FLAVORS[flavor]), [flavor]);
    const converter = useMemo(() => new Converter(flavorOptions), [flavorOptions]);

    // NOTE: The below renderer relies on the showdown converter to be secure and prevent XSS injections
    // This may be revisited in the future.
    return <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(markup) }} className={className} />;
};
