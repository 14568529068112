import React, { FunctionComponent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    APP_ALPHA_STAGE,
    APP_BETA_STAGE,
    APP_IMAGES,
    INTEGRATION_ACTIVE_STATUS,
    INTEGRATION_DISABLED_STATUS,
    INTEGRATION_DISCONNECTED_STATUS,
    INTEGRATION_DISCOVERING_STATUSES
} from 'constants/defaultValues';
import { CompanyState } from 'redux/auth/reducer';
import { ErrorMessage } from 'components/ErrorManagement';
import { RichMessage, HelpIcon } from 'components/RichMessage';
import { AppResp } from 'api/hq/queries/App';
import {
    GetIntegrationsResp,
    GET_INTEGRATIONS_BY_APP,
    SUBSCRIBE_TO_INTEGRATION_UPDATED
} from 'api/hq/queries/Integration';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import AppIcon from 'components/Design/AppIcon';
import Spinner from 'react-bootstrap/Spinner';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import { appConnectionPath } from 'constants/routeBuilders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_ERROR_NAMESPACE = 'app-connect-item';

interface Props {
    className?: string;
    company: CompanyState;
    app: AppResp;
    errorNamespace?: string;
    postConnectRedirectUri?: string;
    skipDiscoveryStatus?: boolean;
}

const AppConnectCardItem: FunctionComponent<Props> = ({
    className,
    company,
    app,
    errorNamespace,
    postConnectRedirectUri,
    skipDiscoveryStatus
}: Props) => {
    // State
    const [isConnecting, setIsConnecting] = useState<boolean>(false);

    // Retrieve subscription and subscribe to updates
    const { data: integrationData, loading, error } = useQuery<GetIntegrationsResp>(GET_INTEGRATIONS_BY_APP, {
        variables: { companyId: company.id, appId: app.id }
    });
    useSafeSubscription(SUBSCRIBE_TO_INTEGRATION_UPDATED, { variables: { companyId: company.id } });

    // Error namespace
    const errorNs = errorNamespace || DEFAULT_ERROR_NAMESPACE;

    // Extract data
    const connectLink = appConnectionPath({
        provider: app.provider,
        companyId: company.id,
        returnTo: postConnectRedirectUri
    });

    // Extract component data
    const appImg = APP_IMAGES[app.provider];
    const integration = integrationData && integrationData.integrations.nodes[0];
    const appStatus = (integration && integration.status) || INTEGRATION_DISABLED_STATUS;

    // Evaluate real status used for display
    // if skipDiscoveryStatus is set to true the integration will be considered as 'ACTIVE' even
    // if it is still discovering
    const realStatus =
        skipDiscoveryStatus && INTEGRATION_DISCOVERING_STATUSES.includes(appStatus)
            ? INTEGRATION_ACTIVE_STATUS
            : appStatus;

    // Action performed when clicking an app's card
    const handleAppClick = useCallback(() => {
        // If app is already connected or is connecting, abort
        if ([INTEGRATION_ACTIVE_STATUS, INTEGRATION_DISCOVERING_STATUSES].includes(realStatus) || isConnecting) return;

        // Start the connect process
        setIsConnecting(true);
        window.location.href = connectLink;
    }, [connectLink, isConnecting, realStatus]);

    // Render card
    return (
        <Card
            className={classNames('bg-grey-tone-11 border-0 text-center cursor-pointer', className, {
                'border-2 border-success': ![INTEGRATION_DISABLED_STATUS, INTEGRATION_DISCONNECTED_STATUS].includes(
                    appStatus
                )
            })}
            style={{ width: 133, minHeight: 140 }}
            onClick={handleAppClick}
        >
            {loading ? (
                <Spinner animation="border" variant="dark" className="m-auto" />
            ) : error ? (
                <ErrorMessage
                    className="text-danger m-auto"
                    error={{ code: 'fetch-integration', namespace: errorNs }}
                />
            ) : (
                <>
                    {/* Doc */}
                    <HelpIcon
                        id={`components.app-connect-item.app-help-detailed.${app.provider}`}
                        className="position-absolute pt-1 pe-1 text-grey-4"
                        style={{ right: -7, top: 0 }}
                    />

                    {/* App image */}
                    <AppIcon src={appImg} alt={app.name} size={96} className="mx-auto border-0" />

                    {/* App name */}
                    <h6 className="my-2 text-dark fw-500">
                        {app.name}

                        {/* Stage */}
                        {app.stage === APP_ALPHA_STAGE ? (
                            <span className="text-secondary font-weight-bold ms-1">
                                (<RichMessage id="badge.alpha" />)
                            </span>
                        ) : app.stage === APP_BETA_STAGE ? (
                            <span className="text-warning font-weight-bold ms-1">
                                (<RichMessage id="badge.beta" />)
                            </span>
                        ) : null}
                    </h6>

                    {/* Connection status */}
                    <div className="d-flex align-items-center mx-auto mb-3 mt-auto">
                        {isConnecting ? (
                            <Spinner animation={'border'} size="sm" />
                        ) : appStatus == INTEGRATION_DISABLED_STATUS ? (
                            <span className="text-grey-4">
                                <RichMessage id="components.app-connect-item.disabled" />
                            </span>
                        ) : appStatus == INTEGRATION_DISCONNECTED_STATUS ? (
                            <>
                                <span className="ms-2 text-danger">
                                    <RichMessage id="components.app-connect-item.disconnected" />
                                </span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faCheck} className="text-success" />
                                <span className="ms-2">
                                    <RichMessage id="components.app-connect-item.connected" />
                                </span>
                            </>
                        )}
                    </div>
                </>
            )}
        </Card>
    );
};

export default AppConnectCardItem;
