import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { UNENROLL_USER } from 'api/hq/queries/CurrentApiUser';
import { useMutation } from '@apollo/react-hooks';
import { LOGOUT_REDIRECTION_PATH } from 'constants/routeBuilders';
import { RichMessage } from 'components/RichMessage';
import Gravatar from 'react-gravatar';
import { LOGOUT_USER } from 'constants/actionTypes';
import { ReduxState } from 'redux/reducers';
import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import AccountSettingsLayout from 'routes/settings/AccountSettingsLayout';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SECONDARY_RED } from 'constants/colors';
import { AsyncButton } from 'components/AsyncButton';

const UserAccount: FunctionComponent = () => {
    // Context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const dispatch = useDispatch();

    // State
    const [unenrollInProgress, setUnenrollInProgress] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // Mutations
    const [unenrollUser] = useMutation(UNENROLL_USER);

    // Unenroll user and log it out
    const handleUserUnenroll = useCallback(async () => {
        setUnenrollInProgress(true);
        await unenrollUser({ variables: { id: user?.id } });
        dispatch({ type: LOGOUT_USER });
        window.location.href = LOGOUT_REDIRECTION_PATH;
    }, [dispatch, unenrollUser, user?.id]);

    // Render
    return (
        <AccountSettingsLayout id="general">
            {/* User Profile */}
            <div className="mb-4">
                <div>
                    <Form>
                        {/* Profile picture */}
                        <div className="fw-bold mb-4 fs-4">
                            <RichMessage id="settings.user-account.form.profile-picture" />
                        </div>
                        <Form.Group className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <Gravatar
                                    email={user?.email}
                                    size={64}
                                    default="wavatar"
                                    className="rounded-circle mb-2"
                                />
                                <div className="d-flex flex-column ms-3">
                                    <span className="fw-bold mb-2">
                                        <FormattedMessage id="settings.user-account.form.upload-picture" />
                                    </span>
                                    <a
                                        href={`https://en.gravatar.com/emails/${user?.email}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn-link fs-sm text-decoration-underline"
                                    >
                                        <RichMessage id="settings.user-account.form.edit-gravatar" />
                                    </a>
                                </div>
                            </div>
                        </Form.Group>

                        {/* Basic information */}
                        <Row>
                            <div className="fw-bold fs-4 my-4">
                                <FormattedMessage id="settings.user-account.basic-information" />
                            </div>
                        </Row>

                        {/* Form */}
                        <Row>
                            {/* Basic Information */}
                            <Col md={6}>
                                {/* Name */}
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        <RichMessage id="settings.user-account.form.name" />
                                    </Form.Label>
                                    <Form.Control disabled value={user?.firstName || ''} />
                                </Form.Group>

                                {/* Email */}
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        <RichMessage id="settings.user-account.form.email" />
                                    </Form.Label>
                                    <Form.Control type="email" disabled value={user?.email || ''} />
                                </Form.Group>
                            </Col>

                            {/* Change password column */}
                            <Col md={6} />
                        </Row>
                    </Form>
                </div>
            </div>

            {/* Danger Zone */}
            <div className="border bg-white rounded border-grey-7 w-100 mt-5">
                <div className="leave-keypup d-flex p-4 align-items-center">
                    <div className="flex-shrink-1 me-2">
                        <KeypupLogo width={50} height={50} />
                    </div>
                    <div className="d-flex flex-column flex-grow-1 p-3">
                        <span className="fs-5 fw-bolder mb-2">
                            <FormattedMessage id="settings.user-account.danger-zone.title" />
                        </span>
                        <RichMessage id="settings.user-account.danger-zone.description" />
                    </div>
                    <Button
                        track-id="click-user-account-unenroll"
                        className="d-flex fw-bold"
                        variant="outline-danger"
                        onClick={() => setIsModalOpen(true)}
                        disabled={unenrollInProgress}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                        <RichMessage id="settings.user-account.action-unenroll" />
                    </Button>
                </div>
            </div>

            {/* Unenroll confirmation modal */}
            <ConfirmationModal
                isOpen={isModalOpen}
                icon={faExclamationTriangle}
                iconColor={SECONDARY_RED}
                title={<RichMessage id="settings.user-account.unenroll-modal.title" />}
                content={<RichMessage id="settings.user-account.unenroll-modal.body" />}
                customConfirmButton={
                    <AsyncButton
                        track-id="click-subscription-downgrade"
                        variant="danger"
                        className="w-100 justify-content-center"
                        onClick={handleUserUnenroll}
                        disabled={unenrollInProgress}
                        loading={unenrollInProgress}
                        messageProps={{ id: 'settings.user-account.unenroll-modal.btn-confirm' }}
                        loadingMessageProps={{ id: 'settings.user-account.unenroll-modal.btn-unenrolling' }}
                    />
                }
                onCancel={() => setIsModalOpen(false)}
            />
        </AccountSettingsLayout>
    );
};

export default UserAccount;
