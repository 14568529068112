import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PickedDataWrapper from './PickedDataWrapper';
import SelectValueField from './SelectValueField';
import { QUERY_OP_TS_DIMENSION_FORMATTERS } from 'components/Dashboarding/DataSource/QueryOperators';
import { useIntl } from 'react-intl';
import { DateValue } from '../useRulesetValidator';
import { RulesetValueFieldProps } from './RulesetValueField';

interface Props extends RulesetValueFieldProps {
    className: string | undefined;
    value: DateValue;
    onChange: (value: DateValue) => void;
}

const DatePickerValueField: FunctionComponent<Props> = ({ className, value, onChange, drilldownEnabled }: Props) => {
    // State
    const [date, setDate] = useState<Date>();

    // Services
    const intl = useIntl();

    // Handle date value change
    const handleDateChange = useCallback(
        (date: Date | null): void => {
            const momentDate = moment(date);
            const dateInputValue = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
            onChange({
                ...value,
                value: dateInputValue,
                pickedCoordinateIndex: undefined
            } as DateValue);
        },
        [onChange, value]
    );

    // Handle granularity value change
    const handeGranularityChange = useCallback(
        (granularity: string | undefined) => {
            onChange({
                ...value,
                granularity
            } as DateValue);
        },
        [onChange, value]
    );

    // Handle picked coordinate index selection
    const handlePickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange({
                ...value,
                value: '',
                pickedCoordinateIndex: e == null ? undefined : e
            });
        },
        [onChange, value]
    );

    // Perform date validity checks on input value changes
    useEffect(() => {
        if (value?.value && moment(value?.value).isValid()) {
            setDate(moment(value?.value).toDate());
        } else {
            setDate(undefined);
        }
    }, [value?.value]);

    return (
        <>
            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={value?.pickedCoordinateIndex}
                onChange={handlePickedCoordinateIndexChange}
            >
                {/* DatePicker */}
                <DatePicker className={className} selected={date} onChange={handleDateChange} />
            </PickedDataWrapper>

            {/* Granularity */}
            <SelectValueField
                className={className}
                onChange={handeGranularityChange}
                value={value?.granularity}
                values={QUERY_OP_TS_DIMENSION_FORMATTERS.map(e => ({
                    label: intl.formatMessage({ id: `dashboarding.widget-field-selector.dimension-function.${e}` }),
                    name: e
                }))}
            />
        </>
    );
};

export default DatePickerValueField;
