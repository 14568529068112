import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';

type Nodes = ReactNode | ReactNodeArray;
interface Props {
    children?: Nodes;
}

const TopNavBar: FunctionComponent<Props> = ({ children }) => {
    return (
        <div className="d-flex align-items-center shadow-sm bg-white p-4 text-dark mb-4" style={{ minHeight: 80 }}>
            {children}
        </div>
    );
};

export default TopNavBar;
