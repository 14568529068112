import { gql } from 'apollo-boost';
import { MutationResp } from './Shared';

//============================================
// Mutations
//============================================
export interface SetResourcePinResp {
    setPin: MutationResp;
}

export const SET_RESOURCE_PIN = gql`
    mutation setResourcePin($resourceId: ID!, $resourceType: PinResourceEnum!, $pinned: Boolean) {
        setPin(input: { resourceId: $resourceId, resourceType: $resourceType, pinned: $pinned }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
