import { WidgetConfigType, WidgetType } from 'components/Dashboarding/Models/Widget';
import { IconPickerConfig } from 'components/Design/ColorPickers/IconPicker';
import gql from 'graphql-tag';
import { Layout } from 'react-grid-layout';
import { CompanyUser } from './CompanyUser';
import { ResourceRole, RESOLVED_ROLE_ATTRIBUTES } from './ResourceRole';
import { MutationResp } from './Shared';
import { WIDGET_ATTRIBUTES } from './Widget';

//============================================
// Interfaces
//============================================
export interface CompanyDashboardResp {
    id: string;
    name: string;
    icon?: IconPickerConfig;
    createdAt?: string;
    updatedAt?: string;
    description?: string;
    pins: {
        nodes: { id: string; pinned: boolean }[];
    };
}

export interface DashboardWithHeaders {
    id: string;
    name: string;
    icon?: IconPickerConfig;
    createdAt?: string;
    updatedAt?: string;
    description?: string;
    imgDataUrl?: string;
    currentUserRole?: {
        name: string;
    };
    user: CompanyUser;
    pinned: boolean;
}

export interface DashboardWithLayout extends DashboardWithHeaders {
    layout: (Layout & { configType?: WidgetConfigType })[];
}

export interface DashboardWithLayoutAndWidgets extends DashboardWithLayout {
    widgets: {
        nodes: WidgetType[];
    };
}

export interface DashboardWithLayoutAndWidgetsAndResolvedRoles extends DashboardWithLayoutAndWidgets {
    resolvedRoles: ResourceRole[];
}

//============================================
// Fetch collection
//============================================
export interface GetCompanyDashboardsResp {
    dashboards: {
        nodes: CompanyDashboardResp[];
    };
}

export const GET_COMPANY_DASHBOARDS = gql`
    query getCompanyDashboards($companyId: ID!, $currentUserPinned: Boolean, $currentUserRoles: [RoleNameEnum!]) {
        dashboards(companyId: $companyId, currentUserPinned: $currentUserPinned, currentUserRoles: $currentUserRoles) {
            nodes {
                id
                name
                createdAt
                updatedAt
                description
                icon {
                    ref
                    color
                }
                currentUserRole {
                    id
                    name
                }
                user {
                    id
                    firstName
                }
                pins {
                    nodes {
                        pinned
                        user {
                            id
                        }
                    }
                }
            }
        }
    }
`;

//============================================
// Fetch single
//============================================
export interface GetDashboardResp {
    dashboard?: DashboardWithLayout & {
        pins: {
            nodes: { id: string; pinned: boolean }[];
        };
    };
}

export const GET_DASHBOARD = gql`
    query getDashboard($id: ID!) {
        dashboard(id: $id) {
            id
            icon {
                ref
                color
            }
            name
            description
            layout {
                i
                h
                w
                x
                y
            }
            pins {
                nodes {
                    pinned
                    user {
                        id
                    }
                }
            }
        }
    }
`;

//============================================
// Fetch single
//============================================
export interface GetDashboardWithWidgetsResp {
    dashboard?: DashboardWithLayoutAndWidgets;
}

export const GET_DASHBOARD_WITH_WIDGETS = gql`
    query getDashboardWithWidgets($id: ID!) {
        dashboard(id: $id) {
            id
            icon {
                ref
                color
            }
            name
            description
            layout {
                i
                h
                w
                x
                y
            }
            widgets {
                nodes {
                    ...widgetAttributes
                }
            }
        }
    }
    ${WIDGET_ATTRIBUTES}
`;

export interface GetDashboardWithWidgetsAndResolvedRolesResp {
    dashboard?: DashboardWithLayoutAndWidgetsAndResolvedRoles;
}

export const GET_DASHBOARD_WITH_WIDGETS_AND_RESOLVED_ROLES = gql`
    query getDashboardWithWidgets($id: ID!) {
        dashboard(id: $id) {
            id
            icon {
                ref
                color
            }
            name
            description
            layout {
                i
                h
                w
                x
                y
            }
            widgets {
                nodes {
                    ...widgetAttributes
                }
            }
            resolvedRoles {
                ...resolvedRoleAttributes
            }
        }
    }
    ${WIDGET_ATTRIBUTES}
    ${RESOLVED_ROLE_ATTRIBUTES}
`;
//============================================
// Mutations
//============================================
export const DASHBOARD_CREATE_ATTRS = ['icon', 'name', 'description', 'layout'] as const;
export const DASHBOARD_UPDATE_ATTRS = ['icon', 'name', 'description', 'layout', 'id'] as const;
export interface CreateDashboardResp {
    createDashboard: MutationResp & {
        dashboard?: {
            id: string;
        };
    };
}

export const CREATE_DASHBOARD = gql`
    mutation($input: CreateDashboardInput!) {
        createDashboard(input: $input) {
            success
            dashboard {
                id
            }
            errors {
                path
                message
            }
        }
    }
`;

export interface UpdateDashboardResp {
    updateDashboard: MutationResp;
}

export const UPDATE_DASHBOARD = gql`
    mutation($input: UpdateDashboardInput!) {
        updateDashboard(input: $input) {
            success
            errors {
                path
                message
            }
        }
    }
`;

export interface DeleteDashboardResp {
    deleteDashboard: MutationResp;
}

export const DELETE_DASHBOARD = gql`
    mutation($id: ID!, $cleanupOrphanWidgets: Boolean) {
        deleteDashboard(input: { id: $id, cleanupOrphanWidgets: $cleanupOrphanWidgets }) {
            success
            errors {
                path
                message
            }
        }
    }
`;
export interface SetDashboardPinResp {
    setPin: MutationResp;
}

export const SET_DASHBOARD_PIN = gql`
    mutation setPin($resourceId: ID!, $resourceType: PinResourceEnum!, $pinned: Boolean) {
        setPin(input: { resourceId: $resourceId, resourceType: $resourceType, pinned: $pinned }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

//============================================
// Subscriptions
//============================================
export const SUBSCRIBE_TO_DASHBOARD_UPDATED = gql`
    subscription($companyId: ID!) {
        dashboardUpdated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_DASHBOARD_CREATED = gql`
    subscription($companyId: ID!) {
        dashboardCreated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_DASHBOARD_DELETED = gql`
    subscription($companyId: ID!) {
        dashboardDeleted(companyId: $companyId) {
            id
        }
    }
`;
