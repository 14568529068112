import classNames from 'classnames';
import { ConfigOption, ConfigQnSingleSelect } from 'components/Dashboarding/Models/ConfigQns';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Label } from '../Label';

interface OptionProps {
    label: string;
    selected: boolean;
    onClick: () => void;
}

const Option: FunctionComponent<OptionProps> = ({ label, selected, onClick }) => {
    return (
        <div
            className={classNames(
                'd-flex flex-column justify-content-center cursor-pointer h-100 p-4 mx-auto text-center border fw-500',
                {
                    'border-primary bg-primary-light text-primary': selected,
                    'bg-transparent text-dark': !selected
                }
            )}
            style={{ maxWidth: '325px' }}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

interface Props {
    config: ConfigQnSingleSelect;
    onChange: (e: ConfigQnSingleSelect) => void;
}

export const SingleSelect: FunctionComponent<Props> = ({ config, onChange }) => {
    // State
    const [selectedOption, setSelectedOption] = useState<ConfigOption>();

    // Hook invoked when an option is clicked
    const handleOptionClick = useCallback(
        (option: ConfigOption) => {
            setSelectedOption(option);
            onChange({ ...config, result: option });
        },
        [onChange, config]
    );

    // Resync selected option from parent update
    useEffect(() => setSelectedOption(config.result), [config.result]);

    return (
        <Form.Group>
            <Label config={config} />
            <Row xs={1} sm={2}>
                {config.options?.map(e => (
                    <Col key={e.id} className="mb-4 px-2">
                        <Option
                            label={e.label}
                            selected={selectedOption?.id == e?.id}
                            onClick={() => handleOptionClick(e)}
                        />
                    </Col>
                ))}
            </Row>
        </Form.Group>
    );
};
