import React, { Fragment, ReactElement, ReactNode, ReactNodeArray } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'react-bootstrap/Dropdown';
import classNames from 'classnames';

export interface DropdownOption {
    id: string;
    name: string;
    img?: ReactNode | ReactNodeArray;
}

interface InternalDropdownOption extends DropdownOption {
    count: number;
}

interface Props<TItem> {
    filterValue?: string | null;
    filterFn: (filterValue: string | null | undefined, item: TItem) => boolean;
    labelId: string;
    onChange: (filterValue?: string) => void;
    options: DropdownOption[];
    items: TItem[];
}

function FilteringDropdown<TItem>({
    labelId: labelKey,
    options,
    items,
    onChange,
    filterValue,
    filterFn
}: Props<TItem>): ReactElement<Props<TItem>> {
    // Extract current filter
    const selectedOption = options.find(e => e.id === filterValue);
    const selectedOptionWithCount: InternalDropdownOption | undefined = selectedOption
        ? { ...selectedOption, count: items.filter(e => filterFn(filterValue, e)).length }
        : undefined;

    // Add count of items on each filter option
    const optionListWithCount: InternalDropdownOption[] = options.map(f => {
        return { ...f, count: items.filter(e => filterFn(f.id, e)).length };
    });

    // Render
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="outline-dark"
                role="button"
                className={classNames({ 'text-primary': selectedOptionWithCount })}
            >
                <span className="me-1">
                    <FormattedMessage id={labelKey} />:
                </span>
                {selectedOptionWithCount ? (
                    <span>
                        {selectedOptionWithCount.name} <small>({selectedOptionWithCount.count})</small>
                    </span>
                ) : (
                    <span>
                        <FormattedMessage id="component.list.filtering-dropdown.all" /> <small>({items.length})</small>
                    </span>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {selectedOption && (
                    <Fragment>
                        <Dropdown.Item onClick={() => onChange(undefined)}>
                            <FormattedMessage id="component.list.filtering-dropdown.all" />{' '}
                            <small>({items.length})</small>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </Fragment>
                )}
                {optionListWithCount.map(filter => {
                    return (
                        <Dropdown.Item key={filter.id} onClick={() => onChange(filter.id)}>
                            {filter.name} <small>({filter.count})</small>
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default FilteringDropdown;
