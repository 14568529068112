import { useKey } from 'react-use';

const keyboardUndoPredicate = (event: KeyboardEvent): boolean =>
    (event.ctrlKey || event.metaKey) && !event.shiftKey && event.key == 'z';
const widgetEditorRedoPredicate = (event: KeyboardEvent): boolean =>
    (event.ctrlKey || event.metaKey) && ((event.key == 'z' && event.shiftKey) || event.key == 'Z');

// Specify actions to take when users hit ctrl+(shift)+z
export function useUndoRedo(undoAction: (e: KeyboardEvent) => void, redoAction: (e: KeyboardEvent) => void): void {
    // Intercept undo/redo actions
    useKey(keyboardUndoPredicate, undoAction, {}, [undoAction]);
    useKey(widgetEditorRedoPredicate, redoAction, {}, [redoAction]);
}
