import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResourceRoles } from 'api/hq/hooks/useResourceRoles';
import { DashboardWithHeaders } from 'api/hq/queries/Dashboard';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useMemo } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { kpShared } from 'util/customIcons';
import LibraryCard from 'components/LibraryCard/LibraryCard';
import DashboardActions from './DashboardActions';
import LibraryCardHeader from '../LibraryCardHeader';
import { RichMessage } from 'components/RichMessage';
import { LIBRARY_CARD_DESCRIPTION_MAX_LENGTH } from 'constants/defaultValues';
import { truncate } from 'lodash';

interface Props {
    dashboard: DashboardWithHeaders;
    children: ReactNode | ReactNodeArray;
    onPinChange?: () => void;
}

export const DashboardLibraryCard: FunctionComponent<Props> = ({ dashboard, children, onPinChange }: Props) => {
    // Load the roles
    const { isShared, loading: loadingRoles } = useResourceRoles({
        resourceId: dashboard.id,
        resourceType: 'DASHBOARD'
    });

    // Content formatting
    const truncatedDescription = useMemo(
        () => truncate(dashboard.description, { length: LIBRARY_CARD_DESCRIPTION_MAX_LENGTH }),
        [dashboard.description]
    );

    return (
        <LibraryCard>
            {/* Header */}
            <LibraryCardHeader
                config={{
                    variant: 'rectangle-icon',
                    icon: dashboard.icon,
                    backgroundColor: dashboard.icon?.color
                }}
            />

            {/* Body */}
            <>
                {/* Title */}
                <Card.Title className="d-flex align-items-center mw-100">
                    <div>
                        {loadingRoles ? (
                            <Spinner animation="border" size="sm" className="me-2" />
                        ) : isShared ? (
                            <FontAwesomeIcon icon={kpShared} size="sm" className="me-2" />
                        ) : null}
                    </div>
                    <div className="text-truncate" title={dashboard.name}>
                        {dashboard.name}
                    </div>
                </Card.Title>

                {/* Updated at */}
                {dashboard.updatedAt && (
                    <p className="small text-grey-4">
                        <RichMessage id="components.library-card.updated-on" values={{ date: dashboard.updatedAt }} />
                    </p>
                )}

                {/* Description */}
                <Card.Text className="text-dark" title={dashboard.description}>
                    {truncatedDescription}
                </Card.Text>
            </>

            {/* Main action button */}
            {children}

            {/* Actions */}
            <DashboardActions
                variant="buttons"
                style={{ top: '0.5rem', right: '0.4rem' }}
                dashboard={dashboard}
                onPinChange={onPinChange}
            />
        </LibraryCard>
    );
};
