import { WidgetFactType, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import React, { FunctionComponent, useCallback, useState } from 'react';
import MiniCardColumn from './MiniCardColumn';

interface Props {
    widget: WidgetMiniCard;
    onChange?: (e: WidgetFactType) => void;
    onReloading: (reloading: boolean) => void;
    forcedMessageId?: string;
}

const MiniCardWidget: FunctionComponent<Props> = ({ widget, onReloading, forcedMessageId, onChange }: Props) => {
    // State
    const [columnLoadingStates, setColumnLoadingStates] = useState<boolean[]>(widget.config.series.map(() => false));

    // Hook invoked when a column is loading
    const onColumnReloading = useCallback(
        (colIndex: number, state: boolean): void => {
            // Abort if no changes
            if (columnLoadingStates[colIndex] == state) return;

            // Update state of provided column index
            const updatedStates = [...columnLoadingStates];
            updatedStates[colIndex] = state;
            setColumnLoadingStates(updatedStates);

            // Notify parent
            onReloading(updatedStates.reduce((memo, elem) => memo || elem));
        },
        [columnLoadingStates, onReloading]
    );

    // Display board view
    return (
        <div className="d-flex h-100 overflow-x-auto">
            {widget.config.series.map((column, index) => (
                <div key={index} className="mx-1">
                    <MiniCardColumn
                        column={column}
                        colIndex={index}
                        colCount={widget.config.series.length}
                        onChange={onChange}
                        onReloading={state => onColumnReloading(index, state)}
                        widget={widget}
                        forcedMessageId={forcedMessageId}
                    />
                </div>
            ))}
        </div>
    );
};

export default MiniCardWidget;
