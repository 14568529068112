import React, { FunctionComponent, useState, Fragment, useCallback } from 'react';
import gql from 'graphql-tag';
import { InboxItem, InboxItemRelated } from 'api/viz/queries/InboxItem';
import { useMutation } from '@apollo/react-hooks';
import { vizApolloClient } from 'clients/vizApolloClient';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { identityConnectionPath } from 'constants/routeBuilders';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import { RichMessage } from 'components/RichMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { MutationResp } from 'api/hq/queries/Shared';
import { ItemComment } from 'api/viz/queries/Comment';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, FormControl, Spinner } from 'react-bootstrap';

const CREATE_COMMENT = gql`
    mutation($body: String!, $topicId: String!, $topicType: ProviderCommentTopicTypeEnum!) {
        providerCreateComment(input: { body: $body, topicSysid: $topicId, topicType: $topicType }) {
            success
            errors {
                code
                path
                message
            }
            response
        }
    }
`;

interface CreateCommentResp {
    providerCreateComment: MutationResp & {
        response: ItemComment;
    };
}

interface Props {
    parent: InboxItem | InboxItemRelated;
    onCreate?: (parent: InboxItem | InboxItemRelated, comment: ItemComment) => void;
    demoMode?: boolean;
}

const CommentInput: FunctionComponent<Props> = ({ parent, onCreate, demoMode }: Props) => {
    // State
    const [createInProgress, setCreateInProgress] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [createError, setCreateError] = useState<QueryError | undefined>(undefined);
    const [demoMsgSent, setDemoMsgSent] = useState<boolean>(false);

    // Services
    const intl = useIntl();

    // Global state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);

    // Declare comment create mutation
    const [createComment] = useMutation<CreateCommentResp>(CREATE_COMMENT, { client: vizApolloClient });

    // Check if the user has connected their identity
    const userIdentityProviders = user?.identities.nodes.map(e => e.provider) || [];
    const isIdentitySetup = userIdentityProviders.indexOf(parent.project.provider) >= 0;
    const connectLink = identityConnectionPath({ provider: parent.project.provider });

    // Format the item key (prefix with # when plain number)
    const naturalKey = isNaN(parseInt(parent.key)) ? parent.key : `#${parent.key}`;
    const placeholder = demoMode
        ? intl.formatMessage({ id: 'priority-inbox.comment-input.reply-placeholder.demo' })
        : isIdentitySetup
        ? intl.formatMessage({ id: `priority-inbox.comment-input.reply-placeholder` }, { key: naturalKey })
        : undefined;

    const handleCreateComment = useCallback(async (): Promise<void> => {
        // Create comment and handle response/error
        try {
            const resp = await createComment({
                variables: { topicId: parent._system_id, topicType: 'INBOX_ITEM', body: message }
            });
            const createResp = resp.data?.providerCreateComment;
            setCreateInProgress(false);

            if (createResp?.success) {
                setMessage('');
                setCreateError(undefined);
                resp.data && onCreate && onCreate(parent, createResp.response);
            } else {
                setCreateError(createResp?.errors[0]);
            }
        } catch {
            setCreateInProgress(false);
            setCreateError({ code: 'default' });
        }
    }, [createComment, message, onCreate, parent]);

    // Submit on enter
    const handleKeydown = async (e: React.KeyboardEvent): Promise<void> => {
        if (e.key !== 'Enter') return;

        // Handle message sending in demo mode
        if (demoMode) {
            setDemoMsgSent(true);
            return;
        }

        // Flag action as in progress and block editing
        setCreateInProgress(true);
        return handleCreateComment();
    };

    return (
        <div className="position-relative">
            {/* Overlay displayed when users have not connected their identity for the provider of the item */}
            {!demoMode && !isIdentitySetup && (
                <div className="d-overlay text-muted">
                    <a href={connectLink} className="hover-underline">
                        <FormattedMessage
                            id={`priority-inbox.comment-input.connect-identity`}
                            values={{ provider: parent.project.provider }}
                        ></FormattedMessage>
                    </a>
                </div>
            )}

            {demoMode && demoMsgSent ? (
                <span className="text-success">
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                    <RichMessage id="priority-inbox.comment-input.demo-msg-sent" />
                </span>
            ) : (
                <Fragment>
                    {/* Comment form */}
                    <div className="d-flex align-items-center">
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={placeholder}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                onKeyPress={handleKeydown}
                                disabled={createInProgress}
                                className="px-3 border-0 shadow-none rounded bg-grey-6 bg-opacity-10"
                            />
                            <Button
                                id="button-addon2"
                                className="ms-2 px-3 py-2 bg-primary rounded justify-content-center text-white"
                                onClick={handleCreateComment}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </InputGroup>
                        {createInProgress && (
                            <Spinner animation="border" size="sm" className="ms-2" style={{ marginRight: '-0.6rem' }} />
                        )}
                    </div>

                    {createError && (
                        <ErrorMessage
                            className="text-danger"
                            error={{
                                namespace: 'create-comment',
                                context: { provider: parent.project.provider },
                                ...createError
                            }}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default CommentInput;
