import {
    WIDGET_DISPLAY_CONFIGS,
    WidgetFormattingOptions,
    MetricFormattingOptions,
    WidgetMetricField,
    WidgetChartType,
    WidgetDisplayConfig,
    ChartSeriesType
} from 'components/Dashboarding/Models/Widget';
import { merge } from 'lodash';
import { useState, useMemo, useCallback, useEffect } from 'react';

interface Results<TWidget extends WidgetChartType> {
    localWidget: TWidget;
    mainSeries: ChartSeriesType;
    displayConfig: WidgetDisplayConfig;
    onWidgetFormattingChange: (addedOpts: Partial<WidgetFormattingOptions>) => void;
    onMetricFormattingChange: (index: number, addedOpts: Partial<MetricFormattingOptions>) => void;
}

export function useCustomizeChart<TWidget extends WidgetChartType>(
    widget: TWidget,
    onChange: (widget: TWidget) => void
): Results<TWidget> {
    // Local widget. Used for use in combination with debounced functions
    const [localWidget, setLocalWidget] = useState<TWidget>(widget);

    // Get main series
    const mainSeries = localWidget.config.series[0];

    // Get general type configuration
    const displayConfig = useMemo(() => WIDGET_DISPLAY_CONFIGS[widget.configType], [widget.configType]);

    // Hook invoked when the general formatting is updated
    const onWidgetFormattingChange = useCallback(
        (addedOpts: Partial<WidgetFormattingOptions>) => {
            const updatedFormatting = merge({}, localWidget.config.formatting, addedOpts);
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, formatting: updatedFormatting } };
            setLocalWidget(updatedWidget);
            onChange(updatedWidget);
        },
        [localWidget, onChange]
    );

    // Hook invoked when the formatting of a metric is updated
    const onMetricFormattingChange = useCallback(
        (index: number, addedOpts: Partial<MetricFormattingOptions>) => {
            // Update the target metric
            const metric = mainSeries.metrics[index];
            const updatedMetric: WidgetMetricField = { ...metric, formatting: { ...metric.formatting, ...addedOpts } };

            // Generate the updated list
            const updatedMetricList = [...mainSeries.metrics];
            updatedMetricList[index] = updatedMetric;

            // Apply the changes
            const updatedWidget = {
                ...localWidget,
                config: { ...localWidget.config, series: [{ ...mainSeries, metrics: updatedMetricList }] }
            } as TWidget;
            setLocalWidget(updatedWidget);
            onChange(updatedWidget);
        },
        [localWidget, mainSeries, onChange]
    );

    // Resync local state when parent gets updated
    useEffect(() => setLocalWidget(widget), [widget]);

    return {
        localWidget,
        mainSeries,
        displayConfig,
        onWidgetFormattingChange,
        onMetricFormattingChange
    };
}
