import { getSubscriptionTier, useBillingSubscription } from 'api/hq/hooks/useBillingSubscription';
import { BILLING_SUBSCRIPTION_REF_FREE, BILLING_CARD_SETUP_STATUS_SUCCEEDED } from 'api/hq/queries/BillingSubscription';
import moment from 'moment';
import React, { FunctionComponent, Fragment, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useSafeState } from 'util/useSafeState';
import CreditCardRequiredModal from './CreditCardRequiredModal';
import PlanRequiredModal from './PlanRequiredModal';
import TierChangeWarningModal from './TierChangeWarningModal';

type GuardType = 'allowed' | 'tier-change-confirmation' | 'plan-required' | 'credit-card-required' | 'allowed';

interface Props {
    actionType: 'upgrade' | 'enable-projects' | 'disable-projects';
    addCount?: number;
    children: React.ReactElement;
}

// This component is responsible for displaying an upgrade modal when the user
// attempts to take an action that would exceeds the currently allowed limits on their
// plan.
export const BillingGuard: FunctionComponent<Props> = ({ addCount, children, actionType }: Props) => {
    // State
    const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(false);

    // Get company
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Get company billing subscription
    const { loading: billingSubLoading, normalized: billingSubscription } = useBillingSubscription({
        companyId: company?.id
    });

    // Infer transition context
    const targetActivatedProjectCount = (billingSubscription?.pricingQuantity || 0) + (addCount || 0);
    const currentTier = billingSubscription?.tier;
    const targetTier = useMemo(() => getSubscriptionTier(billingSubscription, targetActivatedProjectCount), [
        billingSubscription,
        targetActivatedProjectCount
    ]);

    // The type of guard to activate
    const guardType: GuardType = useMemo(() => {
        const isPaidAccount =
            billingSubscription && billingSubscription.subscriptionRef != BILLING_SUBSCRIPTION_REF_FREE;
        const freeQuota = billingSubscription?.freeQuota ?? 0;
        const isInTrialPeriod = moment(billingSubscription?.freeTrialEndAt) > moment();
        const isPayingTierChanging = isPaidAccount && currentTier?.price != targetTier.price;

        switch (actionType) {
            case 'disable-projects':
                return isPayingTierChanging ? 'tier-change-confirmation' : 'allowed';
            case 'enable-projects':
                return isPayingTierChanging
                    ? 'tier-change-confirmation'
                    : isPaidAccount || targetActivatedProjectCount <= freeQuota
                    ? 'allowed'
                    : 'plan-required';
            case 'upgrade':
                return isInTrialPeriod ||
                    billingSubscription?.billingCard?.setupStatus == BILLING_CARD_SETUP_STATUS_SUCCEEDED
                    ? 'allowed'
                    : 'credit-card-required';
        }
    }, [actionType, billingSubscription, currentTier?.price, targetActivatedProjectCount, targetTier.price]);

    // Toggle upgrade modal
    const togglePromptModal = useCallback((): void => setIsModalOpen(!isModalOpen), [isModalOpen, setIsModalOpen]);

    // Return the original button in guarded mode
    // Copy the child element and open the guard modal on click instead:
    // For Link components we override the 'to' destination to be a no-operation so as
    // to give precedence to the onClick action.
    const guardedButton = useMemo(() => React.cloneElement(children, { onClick: togglePromptModal, to: '#' }), [
        children,
        togglePromptModal
    ]);

    // Render based on state and expected change
    if (billingSubLoading || !billingSubscription || !currentTier) {
        // Display the original button in a disabled state
        return React.cloneElement(children, { disabled: true });
    } else if (guardType == 'tier-change-confirmation') {
        // Plan or charges are about to change. Ask user to confirm the action.
        return (
            <>
                {guardedButton}
                <TierChangeWarningModal
                    isOpen={isModalOpen}
                    toggle={togglePromptModal}
                    currentTier={currentTier}
                    targetTier={targetTier}
                    onContinue={children.props['onClick']}
                />
            </>
        );
    } else if (guardType == 'plan-required') {
        // The user must take a paid plan to proceed
        return (
            <>
                {guardedButton}
                <PlanRequiredModal
                    isOpen={isModalOpen}
                    toggle={togglePromptModal}
                    billingSubscription={billingSubscription}
                    onContinue={children.props['onClick']}
                />
            </>
        );
    } else if (guardType == 'credit-card-required') {
        // The user must enter a credit card
        return (
            <>
                {guardedButton}
                <CreditCardRequiredModal
                    isOpen={isModalOpen}
                    toggle={togglePromptModal}
                    billingSubscription={billingSubscription}
                    onContinue={children.props['onClick']}
                />
            </>
        );
    } else {
        // The user is allowed to proceed with the action as-is
        // Return the child element without modification
        return children;
    }
};
