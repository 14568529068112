import {
    replicateSeriesConfiguration,
    Series01Dimension1Metric,
    WidgetConfigKpiWithTrend,
    WidgetKpiType,
    WIDGET_DISPLAY_CONFIGS
} from 'components/Dashboarding/Models/Widget';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import PreviewTable from './PreviewTable';
import { RichMessage } from 'components/RichMessage';
import FilterBoxButton from '../FilterBoxButton';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { KPI_METRIC_SERIES_INDEX, KPI_TREND_SERIES_INDEX } from 'constants/defaultValues';
import { toastWidgetEditorSuccess } from '../..';

interface Props {
    onChange: (widget: WidgetKpiType) => void;
    widget: WidgetKpiType;
}

// Allows the user to configure the dimensions and metrics to be used by
// the widget and visualize the data in a table.
const ConfigureKpiTrendTable: FunctionComponent<Props> = ({ onChange, widget }: Props) => {
    // State
    const [localWidget, setLocalWidget] = useState<WidgetKpiType>(widget);

    // Get series to configure
    const seriesConfig = WIDGET_DISPLAY_CONFIGS[widget.configType];
    const series = (localWidget.config as WidgetConfigKpiWithTrend).series[KPI_TREND_SERIES_INDEX];
    const metricSeries = localWidget.config.series[KPI_METRIC_SERIES_INDEX];
    const highlightCount = series.limit || seriesConfig.trendTrailingPoints || 1;

    // Debounced onChange
    // This is used in combination with a local state to apply updates on screen faster
    const debouncedOnChange = useMemo(() => debounce(onChange), [onChange]);

    // Hook invoked when the main series get updated
    const onSeriesChange = useCallback(
        (series: Series01Dimension1Metric) => {
            // Update the series at index
            const updatedSeries = [...localWidget.config.series];
            updatedSeries[KPI_TREND_SERIES_INDEX] = series;

            // Update widget
            const updatedWidget = {
                ...localWidget,
                config: { ...localWidget.config, series: updatedSeries }
            } as WidgetKpiType;

            // Apply changes
            setLocalWidget(updatedWidget);
            debouncedOnChange(updatedWidget);
        },
        [debouncedOnChange, localWidget]
    );

    // Hook invoked when the KPI configuration gets replicated
    const onReplicateKPIConfiguration = useCallback(() => {
        if (!metricSeries) return;

        // Build the new series with the replicated configuration of the KPI
        const updatedSeries = replicateSeriesConfiguration(series, metricSeries, {
            toastWidgetEditorSuccess
        });

        // Apply changes
        onSeriesChange(updatedSeries);

        // Toast the configuration has been replicated
        toastWidgetEditorSuccess('replicate-kpi-configuration');
    }, [metricSeries, onSeriesChange, series]);

    // Resync local state from parent
    useEffect(() => setLocalWidget(widget), [widget]);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h4 className="text-dark mb-1">
                        <span className="elevio-configure-kpi-trend-table-title">
                            <RichMessage id="dashboarding.widget-editor.configure-kpi-trend-table.heading.title" />
                        </span>
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage
                            id="dashboarding.widget-editor.configure-kpi-trend-table.heading.description"
                            values={{ highlightCount }}
                        />
                    </div>
                </div>
                <div className="d-flex">
                    {metricSeries && (
                        <Button variant="outline-dark" className="me-4" onClick={onReplicateKPIConfiguration}>
                            <FontAwesomeIcon icon={faClone} className="me-2" />
                            <RichMessage id="dashboarding.widget-editor.replicate-configuration.replicate-kpi" />
                        </Button>
                    )}
                    <FilterBoxButton
                        className="me-4"
                        fact={localWidget.config.fact}
                        onChange={onSeriesChange}
                        series={series}
                        variant="outline-dark"
                    />
                </div>
            </div>

            <PreviewTable
                fact={localWidget.config.fact}
                configType={localWidget.configType}
                series={series}
                onChange={onSeriesChange}
                highlightFirst={highlightCount}
            />
        </div>
    );
};

export default ConfigureKpiTrendTable;
