import React, { Fragment, FunctionComponent, useState, useEffect, useMemo, useCallback } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import FilteringDropdown, { DropdownOption } from 'components/List/FilteringDropdown';
import SearchBar from 'components/List/SearchBar';
import ProjectListItem from './ProjectListItem';
import TrackProjectBtn from './TrackProjectBtn';
import { ErrorFragment } from 'components/ErrorManagement';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import {
    ProjectResp,
    EnableProjectsResp,
    ENABLE_PROJECTS,
    DisableProjectsResp,
    DISABLE_PROJECTS
} from 'api/hq/queries/Project';
import { COMPANY_SETTINGS_PATH } from 'constants/routeBuilders';
import {
    INTEGRATION_DISCOVERING_STATUSES,
    PROJECT_DEACTIVATION_STATUSES,
    PROJECT_DISABLED_STATUS,
    PROJECT_ENABLEMENT_STATUSES,
    PROJECT_IMPORTING_STATUS,
    PROJECT_PENDING_STATUS
} from 'constants/defaultValues';
import { Link, useSearchParams } from 'react-router-dom';
import { useCompanyIntegrations } from 'api/hq/hooks/useCompanyIntegrations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faLink } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { sortBy, uniqBy } from 'lodash';
import { BillingGuard } from 'components/BillingGuard';
import FullPageLoader from 'components/Design/FullPageLoader';
import { AsyncButton } from 'components/AsyncButton';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';
import { toastQueryError } from 'components/Toast';

// Only consider data source integrations on this page
// Ignore other types
const INTEGRATION_CATEGORIES = ['CODE_MANAGEMENT', 'PROJECT_MANAGEMENT'];

// Integration types for which new projects can be added
const INTEGRATION_WITH_TRACK_NEW = ['CODE_MANAGEMENT'];

// List of filters enabled for statuses
const STATUS_FILTER_OPTIONS: DropdownOption[] = [
    {
        id: 'active',
        name: 'active'
    },
    {
        id: 'importing',
        name: 'importing'
    },
    {
        id: 'disabled',
        name: 'disabled'
    }
];

// App Filter function
const filterByApp = (filterValue: string | null | undefined, e: ProjectResp): boolean =>
    !filterValue || e.app.provider === filterValue;

// Account Filter function
const filterByAccount = (filterValue: string | null | undefined, e: ProjectResp): boolean =>
    !filterValue || e.account.id === filterValue;

// Status Filter function
const filterByStatus = (filterValue: string | null | undefined, e: ProjectResp): boolean => {
    if (filterValue == 'active') {
        return e.status != PROJECT_DISABLED_STATUS;
    } else if (filterValue == 'importing') {
        return e.status == PROJECT_IMPORTING_STATUS || e.status == PROJECT_PENDING_STATUS;
    } else if (filterValue == 'disabled') {
        return e.status == PROJECT_DISABLED_STATUS;
    } else {
        return true;
    }
};

// Search filter function
const filterBySearch = (filterValue: string | null | undefined, e: ProjectResp): boolean =>
    !filterValue || e.name.toLowerCase().includes(filterValue?.toLowerCase() || '');

const ProjectList: FunctionComponent = () => {
    // Services
    const intl = useRichIntl();

    // Component state
    const [enablementInProgress, setEnablementInProgress] = useState<boolean>(false);
    const [deactivationInProgress, setDeactivationInProgress] = useState<boolean>(false);
    const [selectedProjects, setSelectedProjects] = useState<ProjectResp[]>([]);

    // Extract URL search params
    const [searchParams, setSearchParams] = useSearchParams();

    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Integrations data fetching and subscriptions
    // Note: we do not fetch paginated pages on integrations because it is not expected
    // that companies will have more than 100 integrations.
    const { data: integrationsData, loading: integrationsLoading, error: integrationError } = useCompanyIntegrations({
        companyId: company?.id,
        categories: INTEGRATION_CATEGORIES
    });

    // Project data fetching and subscriptions
    const {
        data: projectsData,
        loading: projectsLoading,
        error: projectsError,
        refetch: projectRefetch
    } = useCompanyProjects({ companyId: company?.id });

    // Mutation Declarations
    const [enableProjects] = useMutation<EnableProjectsResp>(ENABLE_PROJECTS);
    const [disableProjects] = useMutation<DisableProjectsResp>(DISABLE_PROJECTS);

    // Extract route query parameters
    const paramAppId = searchParams.get('app');

    // User role
    const isAdmin = company?.role === 'ADMIN';

    // Extract Integration Data
    const integrationList = useMemo(() => integrationsData?.integrations.nodes || [], [integrationsData]);
    const integrationsWithTrackNew = useMemo(
        () => integrationList.filter(e => INTEGRATION_WITH_TRACK_NEW.includes(e.app.category)),
        [integrationList]
    );
    const areIntegrationsDiscovering = useMemo(
        () =>
            integrationList.reduce((memo, elem): boolean => {
                return memo || INTEGRATION_DISCOVERING_STATUSES.includes(elem.status);
            }, false),
        [integrationList]
    );

    // Extract Project List
    const projectList = useMemo(() => sortBy(projectsData?.company.projects.nodes || [], 'name'), [projectsData]);
    const projectsDiscoveringCount = useMemo(
        () => projectList.filter(e => INTEGRATION_DISCOVERING_STATUSES.includes(e.integration.status)).length,
        [projectList]
    );
    const selectedProjectIds = useMemo(() => selectedProjects.map(e => e.id), [selectedProjects]);
    const selectedProjectsToEnableCount = useMemo(
        () => selectedProjects.filter(e => PROJECT_ENABLEMENT_STATUSES.includes(e.status)).length,
        [selectedProjects]
    );
    const selectedProjectsToDisableCount = useMemo(
        () => selectedProjects.filter(e => PROJECT_DEACTIVATION_STATUSES.includes(e.status)).length,
        [selectedProjects]
    );

    // Evaluate if a protip should be displayed for the selected app
    const protipMsgId = useMemo(() => {
        const protipRawMsgId = paramAppId ? `settings.project-list.protip.${paramAppId}` : null;
        return protipRawMsgId && intl.messages[protipRawMsgId] ? protipRawMsgId : null;
    }, [intl.messages, paramAppId]);

    // Extract Account List
    const accountList = useMemo(
        () =>
            sortBy(
                uniqBy(
                    projectList.map(e => e.account),
                    'name'
                ),
                'name'
            ),
        [projectList]
    );

    // Extract App List
    const appList = useMemo(
        () =>
            sortBy(
                uniqBy(
                    integrationList.map(e => e.app),
                    'provider'
                ),
                'name'
            ),
        [integrationList]
    );

    // Filter options
    const appFilterOptions: DropdownOption[] = useMemo(() => appList.map(a => ({ id: a.provider, name: a.name })), [
        appList
    ]);
    const accountFilterOptions: DropdownOption[] = useMemo(() => accountList.map(a => ({ id: a.id, name: a.name })), [
        accountList
    ]);

    // Evaluate filtered projects
    const filteredProjectList = useMemo(
        () =>
            projectList
                .map(e => ({ ...e, name: e.name.replace(`${e.account.name}/`, '') }))
                .filter(e => filterByApp(paramAppId, e))
                .filter(e => filterByAccount(searchParams.get('account'), e))
                .filter(e => filterByStatus(searchParams.get('status'), e))
                .filter(e => filterBySearch(searchParams.get('search'), e)),
        [paramAppId, projectList, searchParams]
    );

    // Update path with a single filter
    const setFiltering = useCallback(
        (param: string, value: string): void => {
            setSearchParams({ [param]: value });
        },
        [setSearchParams]
    );

    // Update path to reflect the new filtering params
    const updateFilter = useCallback(
        (param: string, value?: string): void => {
            const params = new URLSearchParams(searchParams);
            value == undefined || value == '' ? params.delete(param) : params.set(param, value);
            setSearchParams(params);
        },
        [searchParams, setSearchParams]
    );

    // Handler called when projects are selected/deselected
    const onSelectChange = useCallback(
        (project: ProjectResp, selected: boolean) => {
            if (selected && selectedProjectIds.indexOf(project.id) < 0) {
                setSelectedProjects([...selectedProjects, project]);
            } else if (!selected && selectedProjectIds.indexOf(project.id) >= 0) {
                setSelectedProjects(selectedProjects.filter(e => e.id !== project.id));
            }
        },
        [selectedProjectIds, selectedProjects]
    );

    // Hook invoked when users click the "Select All" checkbox
    const onSelectAllChange = useCallback(
        (selectAll: boolean): void => setSelectedProjects(selectAll ? filteredProjectList : []),
        [filteredProjectList]
    );

    // Send request to disable the selected projects
    const disableSelected = useCallback(async () => {
        setDeactivationInProgress(true);

        try {
            const resp = await disableProjects({ variables: { ids: selectedProjectIds } });
            const disableResp = resp.data?.disableProjects;

            if (disableResp?.success) {
                const activeCount =
                    projectList.filter(e => e.status != PROJECT_DISABLED_STATUS).length - selectedProjectIds.length;
                setSelectedProjects([]);
                await projectRefetch();

                // Redirect based on context
                if (activeCount === 0) setSearchParams({});
            } else {
                toastQueryError({ ...disableResp?.errors[0], namespace: 'disable-projects' });
            }
        } catch {
            toastQueryError({ namespace: 'disable-projects' });
        } finally {
            setDeactivationInProgress(false);
        }
    }, [disableProjects, projectList, projectRefetch, selectedProjectIds, setSearchParams]);

    // Send request to enable the selected projects
    const enableSelected = useCallback(async () => {
        setEnablementInProgress(true);

        try {
            const resp = await enableProjects({ variables: { ids: selectedProjectIds } });
            const enableResp = resp.data?.enableProjects;

            if (enableResp?.success) {
                setSelectedProjects([]);
                await projectRefetch();

                // Redirect to active projects mode
                setFiltering('status', 'active');
            } else {
                toastQueryError({ ...enableResp?.errors[0], namespace: 'enable-projects' });
            }
        } catch {
            toastQueryError({ namespace: 'enable-projects' });
        } finally {
            setEnablementInProgress(false);
        }
    }, [enableProjects, projectRefetch, selectedProjectIds, setFiltering]);

    // If 'auto' mode is specified in the URL then redirect user to full project list or active projects
    // based on whether the user has active projects already.
    useEffect(() => {
        if (searchParams.get('display') !== 'auto' || projectsLoading) return;

        // Evaluate context parameters
        const activeProjectCount: number =
            projectsData?.company.projects.nodes.filter(e => e.status != PROJECT_DISABLED_STATUS).length || 0;

        // Format params
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('display');
        if (activeProjectCount > 0) newParams.set('status', 'active');

        // Redirect
        setSearchParams(newParams);
    }, [searchParams, projectsLoading, projectsData, setSearchParams]);

    // Display loading screen if:
    // - the 'auto' mode is used and a redirect is expected
    // - the initial query is loading
    // - the fetchMore query is still going through pagination
    if (searchParams.get('display') === 'auto' || integrationsLoading || projectsLoading) {
        return <FullPageLoader vh={75} />;
    }

    // Display error message
    if (integrationError || projectsError || !company) {
        const code = integrationError
            ? 'cannot-fetch-integrations'
            : projectsError
            ? 'cannot-fetch-projects'
            : 'default';
        return <ErrorFragment error={{ code: code, namespace: 'fetch-project-list' }} />;
    }

    return (
        <>
            {/* Header with search, filters and actions */}
            <div className="mt-2">
                {/* Filters and controls */}
                <div className="d-flex align-items-center justify-content-between">
                    {/* Search on project full name or attributes */}
                    <div className="flex-grow-1">
                        <SearchBar
                            debounceTime={200}
                            value={searchParams.get('search')}
                            onChange={e => updateFilter('search', e)}
                        >
                            {/* Filter by App */}
                            {appList.length > 1 && (
                                <FilteringDropdown
                                    filterValue={paramAppId}
                                    filterFn={filterByApp}
                                    items={projectList}
                                    labelId="settings.project-list.filter-by-app"
                                    onChange={e => updateFilter('app', e)}
                                    options={appFilterOptions}
                                />
                            )}

                            {/* Filter by Account */}
                            {accountList.length > 1 && (
                                <FilteringDropdown
                                    filterValue={searchParams.get('account')}
                                    filterFn={filterByAccount}
                                    items={projectList}
                                    labelId="settings.project-list.filter-by-account"
                                    onChange={e => updateFilter('account', e)}
                                    options={accountFilterOptions}
                                />
                            )}

                            {/* Filter by Status */}
                            <FilteringDropdown
                                filterValue={searchParams.get('status')}
                                filterFn={filterByStatus}
                                items={projectList}
                                labelId="settings.project-list.filter-by-status"
                                onChange={e => updateFilter('status', e)}
                                options={STATUS_FILTER_OPTIONS}
                            />
                        </SearchBar>
                    </div>

                    {/* Enable/Disable/Select actions */}
                    {isAdmin && (
                        <div className="d-flex align-items-start">
                            {/* Enable Projects */}
                            <BillingGuard actionType="enable-projects" addCount={selectedProjectsToEnableCount}>
                                <AsyncButton
                                    track-id="click-projects-enable"
                                    variant="dark"
                                    onClick={enableSelected}
                                    className="ms-2"
                                    disabled={enablementInProgress || deactivationInProgress}
                                    loading={enablementInProgress}
                                    messageProps={{ id: 'settings.project-list.action-import' }}
                                    loadingMessageProps={{ id: 'settings.project-list.action-importing' }}
                                    hidden={selectedProjectsToEnableCount == 0}
                                />
                            </BillingGuard>

                            {/* Disable Projects */}
                            <BillingGuard actionType="disable-projects" addCount={-selectedProjectsToDisableCount}>
                                <AsyncButton
                                    track-id="click-projects-disable"
                                    variant="dark"
                                    onClick={disableSelected}
                                    className="ms-2"
                                    disabled={enablementInProgress || deactivationInProgress}
                                    loading={deactivationInProgress}
                                    messageProps={{ id: 'settings.project-list.action-disable' }}
                                    loadingMessageProps={{ id: 'settings.project-list.action-disabling' }}
                                    hidden={selectedProjectsToDisableCount == 0}
                                />
                            </BillingGuard>
                        </div>
                    )}
                </div>

                {/* Help text + add project manually + protip */}
                {isAdmin && integrationList.length > 0 && (
                    <div className="text-light mt-2">
                        <div className="d-flex align-items-center">
                            <RichMessage
                                id="settings.project-list.help-text"
                                values={{
                                    integrationsWithTrackNewCount: integrationsWithTrackNew.length,
                                    trackProjectBtn: (
                                        <TrackProjectBtn
                                            variant="link"
                                            className="p-0 ms-1 d-inline-block"
                                            integrations={integrationsWithTrackNew}
                                            onTrack={e => setFiltering('search', e)}
                                        ></TrackProjectBtn>
                                    )
                                }}
                            />
                        </div>

                        {/* Protip */}
                        {protipMsgId && (
                            <div className="mt-2">
                                <RichMessage id={protipMsgId} />
                            </div>
                        )}
                    </div>
                )}
            </div>

            {/* Loader displayed when integrations are discovering */}
            {areIntegrationsDiscovering && (
                <Card className="mt-3">
                    <Card.Body className="text-center">
                        <Spinner animation="border" size="sm" variant="primary" />
                        <div className="text-primary">
                            <RichMessage id="settings.project-list.discovering" />({projectsDiscoveringCount})
                        </div>
                    </Card.Body>
                </Card>
            )}

            {/* Project List */}
            <Table className="align-middle mt-4">
                <thead className="bg-grey-tone-12">
                    <tr className="align-middle">
                        {/* Select/deslect all projects */}
                        <th className="text-center checkbox">
                            {isAdmin && filteredProjectList.length > 0 && (
                                <SelectAllCheckbox
                                    selectedCount={selectedProjects.length}
                                    totalCount={filteredProjectList.length}
                                    onSelectChange={onSelectAllChange}
                                />
                            )}
                        </th>
                        <th>
                            <RichMessage id="settings.project-list.table.heading.project-name" />
                        </th>
                        <th>
                            <RichMessage id="settings.project-list.table.heading.owner" />
                        </th>
                        <th className="text-center">
                            <RichMessage id="settings.project-list.table.heading.status" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {projectList.length > 0 && filteredProjectList.length > 0 ? (
                        // Project list
                        filteredProjectList.map(project => (
                            <ProjectListItem
                                key={project.id}
                                project={project}
                                onSelectChange={onSelectChange}
                                selected={selectedProjectIds.indexOf(project.id) >= 0}
                            />
                        ))
                    ) : projectList.length > 0 && filteredProjectList.length === 0 ? (
                        // Empty search list
                        <tr className="text-center">
                            <td className="text-light py-4" colSpan={4}>
                                <RichMessage id="settings.project-list.filtered-empty" />
                            </td>
                        </tr>
                    ) : integrationList.length === 0 ? (
                        // No apps connected
                        <tr className="text-center mt-5">
                            <td colSpan={4}>
                                <FontAwesomeIcon icon={faComment} className="display-2 text-primary mb-2" />
                                <br />
                                <div className="h5">
                                    <RichMessage id="settings.project-list.integrations-empty" />
                                    <br />
                                </div>
                                <div>
                                    <RichMessage id="settings.project-list.integrations-empty-explanation" />
                                </div>
                                <div className="mt-5">
                                    <Link to={COMPANY_SETTINGS_PATH}>
                                        <Button variant="primary" size="lg">
                                            <FontAwesomeIcon icon={faLink} className="me-2" />
                                            <RichMessage id="settings.project-list.action-connect-apps" />
                                        </Button>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ) : !areIntegrationsDiscovering ? (
                        // No projects
                        <tr className="text-center mt-5">
                            <td colSpan={4}>
                                <FontAwesomeIcon icon={faAddressBook} className="display-2 text-primary mb-2" />
                                <br />
                                <div className="h4">
                                    <RichMessage id="settings.project-list.empty" />
                                    <br />
                                </div>
                                <div className="h6">
                                    <RichMessage id="settings.project-list.empty-explanation" />
                                </div>
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </Table>
        </>
    );
};

export default ProjectList;
