import React, { FunctionComponent, useCallback, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { CANCEL_INVITE, InviteResp } from 'api/hq/queries/CompanyInvite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { NEUTRAL_GREY_4 } from 'constants/colors';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    invite: InviteResp;
    enableActions?: boolean;
    onChange?: (e: InviteResp | null) => void;
}

const TeamInviteItem: FunctionComponent<Props> = ({ invite, enableActions, onChange }: Props) => {
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    // Declare cancel invite mutation
    const [cancelInviteQuery] = useMutation(CANCEL_INVITE);

    // TODO: error handling
    // Cancel an invite and notify parent
    const cancelInvite = useCallback(async (): Promise<void> => {
        setUpdateInProgress(true);
        await cancelInviteQuery({ variables: { id: invite.id } });
        onChange && onChange(null);
        setUpdateInProgress(false);
    }, [cancelInviteQuery, invite.id, onChange]);

    return (
        <>
            <td className="text-truncate text-muted">
                <em>
                    <FormattedMessage id="settings.team-management.team-list.pending-invite" />
                </em>
            </td>
            <td className="text-truncate">{invite.email}</td>
            <td className="py-4">
                <Badge className="text-primary bg-primary bg-opacity-10" pill>
                    <FormattedMessage id="settings.team-management.team-list.role.MEMBER" />
                </Badge>
            </td>

            <td>
                {/* Item actions */}
                <Dropdown>
                    <Dropdown.Toggle as="div" disabled={!enableActions} bsPrefix="none" role="button">
                        {updateInProgress ? (
                            <Spinner animation="border" size="sm" variant="dark" />
                        ) : (
                            <FontAwesomeIcon icon={faEllipsisH} color={NEUTRAL_GREY_4} />
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={cancelInvite}>
                            <FormattedMessage id="settings.team-management.team-list.invite-item.action-cancel" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </>
    );
};

export default TeamInviteItem;
