import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MetricFormattingOptions,
    WidgetFormattingOptions,
    WidgetKpiType,
    WidgetMetricField,
    WIDGET_DISPLAY_CONFIGS
} from 'components/Dashboarding/Models/Widget';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { merge } from 'lodash';
import Form from 'react-bootstrap/Form';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';
import ThresholdColorConfig from 'components/Design/ColorPickers/ThresholdColorConfig';
import { useColorRanges } from './useColorRanges';

interface Props<TWidget extends WidgetKpiType> {
    widget: TWidget;
    onChange: (widget: TWidget) => void;
}

function CustomizeKpi<TWidget extends WidgetKpiType>({
    widget,
    onChange
}: Props<TWidget>): ReactElement<Props<TWidget>> {
    // Services
    const intl = useRichIntl();

    // Local widget. Used for use in combination with debounced functions
    const [localWidget, setLocalWidget] = useState<TWidget>(widget);

    // Get main series
    const [metric] = localWidget.config.series[0].metrics;
    const [trend] = localWidget.config.series[1]?.metrics || [];

    // Get general type configuration
    const displayConfig = useMemo(() => WIDGET_DISPLAY_CONFIGS[widget.configType], [widget.configType]);

    // Hook invoked when the formatting of a metric is updated
    const onMetricFormattingChange = useCallback(
        (seriesIndex: number, addedOpts: Partial<MetricFormattingOptions>) => {
            // Update the target metric
            const series = localWidget.config.series[seriesIndex];
            const [metric] = series.metrics;
            const updatedMetric: WidgetMetricField = { ...metric, formatting: { ...metric.formatting, ...addedOpts } };

            // Update the series and series list
            const updatedSeriesList = [...localWidget.config.series];
            updatedSeriesList[seriesIndex] = { ...series, metrics: [updatedMetric] };

            // Apply the changes
            const updatedWidget = {
                ...localWidget,
                config: { ...localWidget.config, series: updatedSeriesList }
            };
            setLocalWidget(updatedWidget);
            onChange(updatedWidget);
        },
        [localWidget, onChange]
    );

    const { onAddRange, onRangeUpdate } = useColorRanges(widget, onMetricFormattingChange);

    // Hook invoked when the general formatting is updated
    const onWidgetFormattingChange = useCallback(
        (addedOpts: Partial<WidgetFormattingOptions>) => {
            const updatedFormatting = merge({}, localWidget.config.formatting, addedOpts);
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, formatting: updatedFormatting } };
            setLocalWidget(updatedWidget);
            onChange(updatedWidget);
        },
        [localWidget, onChange]
    );

    // Resync local state when parent gets updated
    useEffect(() => setLocalWidget(widget), [widget]);

    // Render
    return (
        <div>
            <Row className="mb-4">
                {/* KPI configuration */}
                <Col xs="6" className="pe-4">
                    <div>
                        <h4 className="text-dark mb-4">
                            <span className="elevio-customize-kpi-title">
                                <RichMessage id="dashboarding.widget-editor.customize-kpi.metric.title" />
                            </span>
                        </h4>
                    </div>

                    {/* Metric label */}
                    <div className="mb-4">
                        <Form.Label htmlFor="trendLabelInput" className="d-block">
                            <RichMessage id="dashboarding.widget-editor.customize-kpi.metric.name-title" />
                        </Form.Label>
                        <FormControlWithCommit
                            id="trendLabelInput"
                            value={metric.formatting?.label || ''}
                            placeholder={intl.formatMessage({
                                id: 'dashboarding.widget-editor.customize-kpi.metric.name-placeholder'
                            })}
                            onCommit={val => onMetricFormattingChange(0, { label: val })}
                        />
                    </div>

                    {/* Metric color */}
                    <Form.Label className="d-block">
                        <RichMessage id="dashboarding.widget-editor.customize.kpi-color" />
                        <RichMessage id="dashboarding.widget-editor.customize.color-suffix.threshold-based" />
                    </Form.Label>

                    {/* Threshold-based coloring */}
                    <ThresholdColorConfig
                        colorRanges={metric.formatting?.colorRanges || []}
                        onRangeUpdate={(rangeIndex, range) => onRangeUpdate('threshold', 0, rangeIndex, range)}
                        onAddRange={() => onAddRange('threshold', 0)}
                    />
                </Col>

                {/* Trend Configuration */}
                {displayConfig.colorTrend && trend && (
                    <Col xs="6" className="pe-4">
                        <div>
                            <h4 className="text-dark mb-4">
                                <RichMessage id="dashboarding.widget-editor.customize-kpi.trend.title" />
                            </h4>
                        </div>

                        {/* Trend label */}
                        <div className="mb-4">
                            <Form.Label htmlFor="trendLabelInput" className="d-block">
                                <RichMessage id="dashboarding.widget-editor.customize.metric-label" />
                            </Form.Label>
                            <FormControlWithCommit
                                id="trendLabelInput"
                                value={trend.formatting?.label || ''}
                                placeholder={intl.formatMessage({
                                    id: 'dashboarding.widget-editor.customize-kpi.trend.name-placeholder'
                                })}
                                onCommit={val => onMetricFormattingChange(1, { label: val })}
                            />
                        </div>

                        {/* Trend Progression Enabled */}
                        {displayConfig.customizeTrendProgression && (
                            <div className="d-flex justify-content-between mb-2">
                                <Form.Label htmlFor="trendProgressionSwitch" className="d-block">
                                    <RichMessage id="dashboarding.widget-editor.customize-kpi.trend.progression-enabled" />
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="trendProgressionSwitch"
                                    aria-describedby="legendSwitchHelp"
                                    role="button"
                                    checked={!!localWidget.config.formatting?.showTrendProgression}
                                    onChange={e => onWidgetFormattingChange({ showTrendProgression: e.target.checked })}
                                />
                            </div>
                        )}

                        {/* Metric color */}
                        <Form.Label className="d-block">
                            <RichMessage id="dashboarding.widget-editor.customize.metric-color" />
                            <RichMessage id="dashboarding.widget-editor.customize.color-suffix.threshold-based" />
                        </Form.Label>

                        {/* Threshold-based coloring */}
                        <ThresholdColorConfig
                            colorRanges={trend.formatting?.colorRanges || []}
                            onRangeUpdate={(rangeIndex, range) => onRangeUpdate('threshold', 1, rangeIndex, range)}
                            onAddRange={() => onAddRange('threshold', 1)}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default CustomizeKpi;
