import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { APP_ALPHA_STAGE, APP_BETA_STAGE, APP_IMAGES, INTEGRATION_DISABLED_STATUS } from 'constants/defaultValues';
import { CompanyState } from 'redux/auth/reducer';
import { ErrorMessage } from 'components/ErrorManagement';
import { RichMessage, HelpIcon } from 'components/RichMessage';
import AppActionBtn from './AppActionBtn';
import AppConnectionStatus from './AppConnectionStatus';
import { AppResp } from 'api/hq/queries/App';
import {
    GetIntegrationsResp,
    GET_INTEGRATIONS_BY_APP,
    SUBSCRIBE_TO_INTEGRATION_UPDATED
} from 'api/hq/queries/Integration';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import AppAddInstanceBtn from './AppAddInstanceBtn';
import AppIcon from 'components/Design/AppIcon';
import Spinner from 'react-bootstrap/Spinner';

const DEFAULT_ERROR_NAMESPACE = 'app-connect-item';

interface Props {
    company: CompanyState;
    app: AppResp;
    errorNamespace?: string;
    postConnectRedirectUri?: string;
    skipDiscoveryStatus?: boolean;
    skipAddInstance?: boolean;
}

const AppConnectRowItem: FunctionComponent<Props> = ({
    company,
    app,
    errorNamespace,
    postConnectRedirectUri,
    skipDiscoveryStatus,
    skipAddInstance
}: Props) => {
    // User role
    const isAdmin = company?.role == 'ADMIN';

    // Retrieve subscription and subscribe to updates
    const { data: integrationData, loading, refetch, error } = useQuery<GetIntegrationsResp>(GET_INTEGRATIONS_BY_APP, {
        variables: { companyId: company.id, appId: app.id }
    });
    useSafeSubscription(SUBSCRIBE_TO_INTEGRATION_UPDATED, { variables: { companyId: company.id } });

    // Error namespace
    const errorNs = errorNamespace || DEFAULT_ERROR_NAMESPACE;

    // Show row as loading
    if (loading) {
        return (
            <tr>
                <td colSpan={4}>
                    <Spinner animation="border" variant="dark" />
                </td>
            </tr>
        );
    }

    // Handle fetch error
    if (error) {
        return (
            <tr>
                <td colSpan={4}>
                    <ErrorMessage error={{ code: 'fetch-integration', namespace: errorNs }} />
                </td>
            </tr>
        );
    }

    // Extract component data
    const appImg = APP_IMAGES[app.provider];
    const integration = integrationData && integrationData.integrations.nodes[0];
    const appStatus = (integration && integration.status) || INTEGRATION_DISABLED_STATUS;

    // Render row
    return (
        <tr>
            {/* App name */}
            <td className="w-20">
                <div className="d-flex align-items-center">
                    {/* App image */}
                    <AppIcon src={appImg} alt={app.name} className="me-3" />

                    {/* Name */}
                    <span className="ms-3">{app.name}</span>

                    {/* Stage */}
                    {app.stage === APP_ALPHA_STAGE ? (
                        <span className="text-secondary font-weight-bold ms-1">
                            (<RichMessage id="badge.alpha" />)
                        </span>
                    ) : app.stage === APP_BETA_STAGE ? (
                        <span className="text-warning font-weight-bold ms-1">
                            (<RichMessage id="badge.beta" />)
                        </span>
                    ) : null}
                </div>
            </td>

            {/* Explanation */}
            <td className="w-40">
                <RichMessage id={`components.app-connect-item.app-help.${app.provider}`} />
                <HelpIcon id={`components.app-connect-item.app-help-detailed.${app.provider}`} className="ms-2" />
            </td>

            {/* Connection status */}
            <td className="w-20">
                <AppConnectionStatus integration={integration} />
            </td>

            {/* Action: Connect/Disconnect */}
            <td className="w-20">
                <div className="d-flex justify-content-center align-items-center">
                    {/* Main action (connect/disconnect) */}
                    {isAdmin && (
                        <AppActionBtn
                            status={appStatus}
                            app={app}
                            company={company}
                            integrationId={integration && integration.id}
                            onDisconnect={refetch}
                            postConnectRedirectUri={postConnectRedirectUri}
                            skipDiscoveryStatus={skipDiscoveryStatus}
                            className="w-75 justify-content-center"
                        />
                    )}

                    {/* Add instance */}
                    {!skipAddInstance && (
                        <AppAddInstanceBtn
                            status={appStatus}
                            app={app}
                            company={company}
                            postConnectRedirectUri={postConnectRedirectUri}
                            skipDiscoveryStatus={skipDiscoveryStatus}
                            className="ms-2"
                        />
                    )}
                </div>
            </td>
        </tr>
    );
};

export default AppConnectRowItem;
