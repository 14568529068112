import React, { FunctionComponent, useMemo } from 'react';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { QueryOpField, QueryOpFieldType } from 'components/Dashboarding/DataSource/QueryOperators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFont, faSquareRootAlt, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { max } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMeasure } from 'react-use';
import { RichMessage } from 'components/RichMessage';
import FieldTypeIcon from 'components/Dashboarding/FieldTypeIcon';

// Estimated sizes. Used for column adjustment purpose.
const FIELD_HEIGHT = 32;
const COL_WIDTH = 280;

// Field type to icon mapping
const TYPE_ICON_MAPPING: { [key in QueryOpFieldType]?: IconDefinition } = {
    bool: faToggleOff,
    string: faFont,
    float: faSquareRootAlt,
    int: faSquareRootAlt,
    ts: faCalendarAlt
};

interface Props {
    fact: QueryOpFact;
}

const FactFieldList: FunctionComponent<Props> = ({ fact }: Props) => {
    // Restrict to simple fields (metric/dimension)
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: ['metric', 'dimension'] });

    // Detect changes in width/height to adapt the number of (1) fields displayed per column and (2) the number of columns
    const [wrapperRef, { width: wrapperWidth, height: wrapperHeight }] = useMeasure<HTMLDivElement>();
    const maxScreenCol = Math.floor(wrapperWidth / COL_WIDTH);
    const maxScreenFields = Math.floor(wrapperHeight / FIELD_HEIGHT);

    // Build the field columns
    // We try to pack each column with as many visible fields as possible. If there are too many fields
    // then all columns will be filled with as many required fields to fit the max number of columns and
    // vertical scrolling will be allowed.
    const fieldColumns = useMemo(() => {
        const maxFieldsPerCol = max([maxScreenFields, Math.ceil(queryOpFields.length / maxScreenCol)]) as number;

        return queryOpFields.reduce((memo, elem, index) => {
            const colIndex = Math.floor(index / maxFieldsPerCol);
            memo[colIndex] = memo[colIndex] || [];
            memo[colIndex].push(elem);
            return memo;
        }, [] as QueryOpField[][]);
    }, [maxScreenCol, maxScreenFields, queryOpFields]);

    // Render the list of fields
    return (
        <div className="border rounded">
            <div className="bg-grey-10 px-2 py-3 rounded-top">
                <h6 className="text-uppercase text-dark fw-bold">
                    <RichMessage id="dashboarding.widget-editor.configure-table.field-list-title" />
                </h6>
            </div>
            <Row className="vh-30 overflow-auto g-0 pt-3 px-3" ref={wrapperRef}>
                {fieldColumns.map((fieldList, colIndex) => {
                    return (
                        <Col key={colIndex}>
                            {fieldList.map(e => {
                                const icon = TYPE_ICON_MAPPING[e.type];
                                if (!icon) return;

                                return (
                                    <div key={e.id} className="pb-1 mb-2 d-flex">
                                        <FieldTypeIcon field={e} className="me-2" />
                                        <span className="text-uppercase text-grey-1">{e.label}</span>
                                    </div>
                                );
                            })}
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default FactFieldList;
