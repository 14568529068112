import { DashboardWithHeaders } from 'api/hq/queries/Dashboard';
import { Layout } from 'react-grid-layout';
import { WidgetType } from './Widget';
import data from './DashboardTemplateData.json';
import { ONBOARDING_DEFAULT_DASHBOARD_TEMPLATE_IDS } from 'constants/defaultValues';

export interface DashboardTemplateWidget extends Layout {
    widget: WidgetType;
}

export interface DashboardTemplate extends DashboardWithHeaders {
    layout: DashboardTemplateWidget[];
}

// All available dashboard templates
// These templates are maintained at https://github.com/keypup-io/dashboard-templates/tree/master/dashboards
export const DASHBOARD_TEMPLATES = (data as unknown) as DashboardTemplate[];

export const BLANK_DASHBOARD_TEMPLATE = ({
    id: 'blank-dashboard',
    icon: {
        ref: 'file',
        color: '#c0eeda'
    },
    name: 'Blank dashboard',
    description: 'Start from a plain & empty dashboard',
    layout: [],
    imgDataUrl: ''
} as unknown) as DashboardTemplate;

// The list of templates that users should always start with
export const DASHBOARD_ONBOARDING_TEMPLATES = DASHBOARD_TEMPLATES.filter(e =>
    ONBOARDING_DEFAULT_DASHBOARD_TEMPLATE_IDS.includes(e.id)
);
