import { QueryOpFact } from 'components/Dashboarding/DataSource';
import {
    OffsetFilter,
    QueryOpDimensionFormatter,
    QueryOpField,
    QueryOpFilterDisplayType,
    QUERY_OP_FILTER_FUNCTIONS,
    TimePeriodFilter
} from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { RuleGroupType, RuleType } from 'react-querybuilder';

export interface DateValue {
    value: string;
    granularity: QueryOpDimensionFormatter;
    pickedCoordinateIndex?: number;
}

export interface DateRangeValue {
    fromDate: string;
    toDate: string;
    granularity: QueryOpDimensionFormatter;
    fromPickedCoordinateIndex?: number;
    toPickedCoordinateIndex?: number;
}

export interface TimePeriodValue {
    timePeriod: TimePeriodFilter;
    amount?: number;
    offset?: OffsetFilter;
    pickedCoordinateIndex?: number;
}

export type ComplexValue = DateValue | DateRangeValue | TimePeriodValue;

export type RuleValueType = 'string' | 'stringL' | 'int' | 'float' | 'ts' | 'bool' | 'rts' | 'tp' | 'otp';
export interface RuleValue {
    type: RuleValueType;
    value: string | boolean | number | undefined | ComplexValue;
    pickedCoordinateIndex?: number;
}

const validateRuleset = (ruleset: RuleGroupType | RuleType, queryOpFields?: QueryOpField[]): boolean => {
    const group = ruleset as RuleGroupType;
    const rule = ruleset as RuleType<string, string, RuleValue | undefined>;

    if (group.combinator) {
        return group.rules.reduce((prev: boolean, curr) => prev && validateRuleset(curr, queryOpFields), true);
    }

    if (rule.operator) {
        // Retrieve field configuration
        const fieldConfig = queryOpFields?.find(e => e.name == rule.field);

        // Retrieve operator configuration
        const operatorConfig = QUERY_OP_FILTER_FUNCTIONS.find(e => e.name === rule.operator);

        // Determine display type
        const displayType: QueryOpFilterDisplayType =
            operatorConfig?.displayType || fieldConfig?.filterDisplayType || 'text';

        // Validate
        const ruleValue = rule.value;
        let complexValueContent;
        switch (displayType) {
            case 'no-arg':
                return ruleValue == undefined;
            case 'datepicker':
                complexValueContent = ruleValue?.value as DateValue;
                return (
                    (complexValueContent?.pickedCoordinateIndex != undefined || !!complexValueContent.value) &&
                    !!complexValueContent.granularity
                );
            case 'daterangepicker':
                complexValueContent = ruleValue?.value as DateRangeValue;
                return (
                    (complexValueContent?.fromPickedCoordinateIndex != undefined || !!complexValueContent.fromDate) &&
                    (complexValueContent?.toPickedCoordinateIndex != undefined || !!complexValueContent.toDate) &&
                    !!complexValueContent.granularity
                );
            case 'timeperiod':
                complexValueContent = ruleValue?.value as TimePeriodValue;
                return (
                    (complexValueContent?.pickedCoordinateIndex != undefined ||
                        complexValueContent.amount != undefined) &&
                    !!complexValueContent.timePeriod
                );
            case 'timeperiod-with-offset':
                complexValueContent = ruleValue?.value as TimePeriodValue;
                return (
                    (complexValueContent?.pickedCoordinateIndex != undefined ||
                        complexValueContent?.amount != undefined) &&
                    !!complexValueContent?.timePeriod &&
                    !!complexValueContent?.offset
                );
            case 'number':
                return ruleValue?.pickedCoordinateIndex != undefined || ruleValue?.value != undefined;
            default:
                return ruleValue?.pickedCoordinateIndex != undefined || !!ruleValue?.value;
        }
    }

    return false;
};

export const useRulesetValidator = ({
    fact,
    ruleset
}: {
    fact: QueryOpFact;
    ruleset: RuleGroupType | RuleType | undefined;
}): boolean => {
    // Retrieve the fact's fields
    const { queryOpFields } = useQueryOpFields({ fact });

    // If no ruleset, return valid
    if (!ruleset) return true;

    // Validate the ruleset
    return validateRuleset(ruleset, queryOpFields);
};
