import { useMutation } from '@apollo/react-hooks';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faTrash, faShareAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResourcePin } from 'api/hq/hooks/useResourcePin';
import { useUserRoleForResource } from 'api/hq/hooks/useUserRoleForResource';
import { DashboardWithHeaders, DeleteDashboardResp, DELETE_DASHBOARD } from 'api/hq/queries/Dashboard';
import classNames from 'classnames';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import { ConfirmationModal } from 'components/ConfirmationModal';
import DuplicateDashboardModal from 'components/Dashboarding/Duplicating/DuplicateDashboardModal';
import { ShareDashboardModal } from 'components/Dashboarding/Sharing/ShareDashboardModal';
import { RichMessage } from 'components/RichMessage';
import { toastQueryError } from 'components/Toast';
import { SECONDARY_RED, PRIMARY_DARK_BLUE } from 'constants/colors';
import { dashboardViewPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { kpUnpin, kpPin, kpPen } from 'util/customIcons';

export interface Props {
    variant: 'buttons' | 'dropdown';
    style?: object;
    dashboard: DashboardWithHeaders;
    onPinChange?: () => void;
}

const DashboardActions: FunctionComponent<Props> = ({ variant, style, dashboard, onPinChange }: Props) => {
    // State
    const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [cleanupOrphanWidgets, setCleanupOrphanWidgets] = useState<boolean>(false);
    const [isShareDashboardModalOpen, setIsShareDashboardModalOpen] = useState<boolean>(false);
    const [isDuplicateDashboardModalOpen, setIsDuplicateDashboardModalOpen] = useState<boolean>(false);

    // Load the user roles
    const { canEdit, canDelete, loading } = useUserRoleForResource({
        resourceId: dashboard.id,
        resourceType: 'DASHBOARD'
    });

    // Navigate hook
    const navigate = useNavigate();

    // Location
    const location = useLocation();

    // I18n
    const intl = useIntl();

    // Navigate to dashboard
    const navigateToDashboard = useCallback(
        (dashboardId: string, edit?: boolean, redirectTo?: string): void =>
            navigate(dashboardViewPath({ dashboardId, edit, redirectTo })),
        [navigate]
    );

    // Mutations
    const [deleteDashboard] = useMutation<DeleteDashboardResp>(DELETE_DASHBOARD);

    // Delete dashboard
    const removeDashboard = useCallback(async () => {
        try {
            setDeletionInProgress(true);

            // Delete the dashboard
            const resp = (
                await deleteDashboard({ variables: { id: dashboard.id, cleanupOrphanWidgets: cleanupOrphanWidgets } })
            ).data?.deleteDashboard;

            if (resp?.success) {
                setIsDeleteConfirmationModalOpen(false);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'delete-dashboard' });
            }
        } catch (error) {
            toastQueryError({ namespace: 'delete-dashboard' });
        } finally {
            setDeletionInProgress(false);
        }
    }, [deleteDashboard, dashboard.id, cleanupOrphanWidgets]);

    // Pinning / unpinning
    const { loading: pinLoading, setPin } = useResourcePin({
        resourceType: 'DASHBOARD',
        resourceId: dashboard.id,
        onSuccess: onPinChange
    });

    // Deletion confirmation modal content
    const deleteModalContent = useMemo(() => {
        return (
            <>
                <RichMessage id="collections.confirmation-modal.delete-dashboard.content" />
                <Form.Check
                    className="mt-4 sm-check"
                    type="checkbox"
                    label={
                        <RichMessage id="collections.confirmation-modal.delete-dashboard.delete-dashboards-widgets" />
                    }
                    onClick={() => setCleanupOrphanWidgets(!cleanupOrphanWidgets)}
                />
            </>
        );
    }, [cleanupOrphanWidgets]);

    // Deletion confirmation modal confirm button
    const deleteModalConfirmButton = useMemo(() => {
        return (
            <AsyncButton
                variant="danger"
                onClick={removeDashboard}
                disabled={deletionInProgress}
                className="w-100 justify-content-center"
                loading={deletionInProgress}
                messageProps={{ id: 'collections.confirmation-modal.delete-dashboard.confirm-button' }}
                loadingMessageProps={{ id: 'collections.confirmation-modal.delete-dashboard.deletion-in-progress' }}
            />
        );
    }, [deletionInProgress, removeDashboard]);

    if (loading) return <Spinner animation="border" size="sm" />;

    // Render
    return (
        <>
            {variant == 'buttons' ? (
                <div className="d-flex" style={style}>
                    {/* Edit button */}
                    <Button
                        className="btn-action btn-sm btn-white border-1 border-grey-6"
                        title={intl.formatMessage({
                            id: `collections.actions.edit`
                        })}
                        disabled={!canEdit}
                        onClick={() => navigateToDashboard(dashboard.id, true, location.pathname)}
                    >
                        <FontAwesomeIcon icon={kpPen} color={PRIMARY_DARK_BLUE} />
                    </Button>

                    {/* Pin/Unpin button */}
                    <Button
                        className="btn-action btn-sm btn-white border-1 border-grey-6"
                        title={intl.formatMessage({
                            id: `collections.actions.${dashboard.pinned ? 'unpin' : 'pin'}`
                        })}
                        onClick={() => !pinLoading && setPin(!dashboard.pinned)}
                    >
                        {pinLoading ? (
                            <Spinner animation="border" size="sm" variant="dark" />
                        ) : (
                            <FontAwesomeIcon icon={dashboard.pinned ? kpUnpin : kpPin} color={PRIMARY_DARK_BLUE} />
                        )}
                    </Button>

                    {/* Duplicate button */}
                    <Button
                        className="btn-action btn-sm btn-white border-1 border-grey-6"
                        title={intl.formatMessage({
                            id: `collections.actions.duplicate`
                        })}
                        onClick={() => setIsDuplicateDashboardModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faCopy} color={PRIMARY_DARK_BLUE} />
                    </Button>

                    {/* Share button */}
                    <Button
                        className="btn-action btn-sm btn-white border-1 border-grey-6"
                        title={intl.formatMessage({
                            id: `collections.actions.share`
                        })}
                        disabled={!canEdit}
                        onClick={() => setIsShareDashboardModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faShareAlt} color={PRIMARY_DARK_BLUE} />
                    </Button>

                    {/* Delete button */}
                    <Button
                        className="btn-action btn-sm btn-white border-1 border-grey-6"
                        title={intl.formatMessage({
                            id: `collections.actions.delete`
                        })}
                        disabled={!canDelete}
                        onClick={() => setIsDeleteConfirmationModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faTrash} color={SECONDARY_RED} />
                    </Button>
                </div>
            ) : variant == 'dropdown' ? (
                <Dropdown>
                    <Dropdown.Toggle as="div" bsPrefix="none" role="button" className="text-light">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                        <Dropdown.Item
                            className={classNames({ 'text-dark': canEdit })}
                            as="div"
                            disabled={!canEdit}
                            onClick={() => navigateToDashboard(dashboard.id, true, location.pathname)}
                            role="button"
                        >
                            <FontAwesomeIcon className="me-2" icon={kpPen} />
                            <RichMessage id="collections.actions.edit" />
                        </Dropdown.Item>
                        <Dropdown.Divider className="d-none d-md-block" />
                        <Dropdown.Item as="div" onClick={() => !pinLoading && setPin(!dashboard.pinned)} role="button">
                            {pinLoading ? (
                                <Spinner animation="border" size="sm" variant="dark" />
                            ) : (
                                <>
                                    <FontAwesomeIcon className="me-2" icon={dashboard.pinned ? kpUnpin : kpPin} />
                                    <RichMessage id={`collections.actions.${dashboard.pinned ? 'unpin' : 'pin'}`} />
                                </>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Divider className="d-none d-md-block" />
                        <Dropdown.Item as="div" onClick={() => setIsDuplicateDashboardModalOpen(true)} role="button">
                            <FontAwesomeIcon className="me-2" icon={faCopy} />
                            <RichMessage id="collections.actions.duplicate" />
                        </Dropdown.Item>
                        <Dropdown.Divider className="d-none d-md-block" />
                        <Dropdown.Item as="div" onClick={() => setIsShareDashboardModalOpen(true)} role="button">
                            <FontAwesomeIcon className="me-2" icon={faShareAlt} />
                            <RichMessage id="collections.actions.share" />
                        </Dropdown.Item>
                        <Dropdown.Divider className="d-none d-md-block" />
                        <Dropdown.Item
                            as="div"
                            className={classNames({ 'text-danger': canDelete })}
                            role="button"
                            disabled={!canDelete}
                            onClick={() => setIsDeleteConfirmationModalOpen(true)}
                        >
                            <FontAwesomeIcon className="me-2" icon={faTrash} />
                            <RichMessage id="collections.actions.delete" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}

            {/* Delete confirmation modal */}
            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    isOpen={isDeleteConfirmationModalOpen}
                    icon={faTrash}
                    iconColor={SECONDARY_RED}
                    title={<RichMessage id="collections.confirmation-modal.delete-dashboard.title" />}
                    content={deleteModalContent}
                    customConfirmButton={deleteModalConfirmButton}
                    onCancel={() => setIsDeleteConfirmationModalOpen(false)}
                />
            )}

            {/* Share Dashboard Modal */}
            {isShareDashboardModalOpen && (
                <ShareDashboardModal
                    isOpen={isShareDashboardModalOpen}
                    onHide={() => setIsShareDashboardModalOpen(false)}
                    dashboardId={dashboard.id}
                />
            )}

            {/* Duplicate Dashboard Modal */}
            {isDuplicateDashboardModalOpen && (
                <DuplicateDashboardModal
                    isOpen={isDuplicateDashboardModalOpen}
                    onHide={() => setIsDuplicateDashboardModalOpen(false)}
                    dashboard={dashboard}
                />
            )}
        </>
    );
};

export default DashboardActions;
