import classNames from 'classnames';
import {
    PRIMARY_DARK_BLUE,
    PRIMARY_NORMAL_BLUE,
    PRIMARY_NORMAL_PURPLE,
    SECONDARY_DARK_RED,
    SECONDARY_RED,
    SECONDARY_PINK,
    SECONDARY_ORANGE,
    SECONDARY_YELLOW,
    SECONDARY_ANIS,
    SECONDARY_GREEN,
    SECONDARY_GREY_BLUE
} from 'constants/colors';
import React, { FunctionComponent } from 'react';
import { shadeHexColor } from 'util/ColorOperators';

// The size of the color chip
const COLOR_CHIP_SIZE = 20; //px

// This is the list of main colors proposed to the user
export const MAIN_PICKER_COLORS = [
    PRIMARY_DARK_BLUE,
    PRIMARY_NORMAL_BLUE,
    PRIMARY_NORMAL_PURPLE,
    SECONDARY_DARK_RED,
    SECONDARY_RED,
    SECONDARY_PINK,
    SECONDARY_ORANGE,
    SECONDARY_YELLOW,
    SECONDARY_ANIS,
    SECONDARY_GREEN,
    SECONDARY_GREY_BLUE
];

// Build the color matrix
// For each main color, we return the main color and two lightened variants
const COLOR_MATRIX: [string, ...string[]][] = MAIN_PICKER_COLORS.map(color => {
    return [color, shadeHexColor(color, 0.3), shadeHexColor(color, 0.6)];
});

interface Props {
    onChange: (e: string) => void;
}

const ColorMatrix: FunctionComponent<Props> = ({ onChange }: Props) => {
    return (
        <div className="d-flex">
            {COLOR_MATRIX.map((colorGroup, colorGroupIndex) => {
                return (
                    <div
                        key={colorGroup[0]}
                        className={classNames('d-flex flex-column', { 'ms-2': colorGroupIndex > 0 })}
                    >
                        {colorGroup.map((color, colorIndex) => {
                            return (
                                <div
                                    key={color}
                                    className={classNames('rounded-circle', {
                                        'mt-2': colorIndex > 0
                                    })}
                                    onClick={() => onChange(color)}
                                    style={{
                                        backgroundColor: color,
                                        width: COLOR_CHIP_SIZE,
                                        height: COLOR_CHIP_SIZE
                                    }}
                                    role="button"
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default ColorMatrix;
