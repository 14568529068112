import { LS_ONBOARDING_VARIANT_KEY } from 'constants/defaultValues';
import { ONBOARDING_VARIANTS } from 'constants/routeBuilders';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

// Provides a function auto-selecting an onboarding path and storing
// the variant in local storage
export function useOnboardingNavigate(): () => void {
    // Services
    const navigate = useNavigate();

    // Session storage
    const [, setOnboardingVariant] = useLocalStorage<string>(LS_ONBOARDING_VARIANT_KEY);

    return useCallback(() => {
        // Select onboarding variant and store reference in local storage
        const randIndex = new Date().getSeconds() % ONBOARDING_VARIANTS.length;
        const onboarding = ONBOARDING_VARIANTS[randIndex];
        setOnboardingVariant(onboarding.name);

        navigate(onboarding.path);
    }, [navigate, setOnboardingVariant]);
}
