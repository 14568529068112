import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { FIRST_MODAL_Z_INDEX } from 'constants/defaultValues';
import React, { FunctionComponent, useMemo } from 'react';
import WidgetCard from '../DashboardWidget/WidgetCard';

const DEFAULT_MAX_WIDTH = 500;
const KPI_DEFAULT_MAX_WIDTH = 350;

interface Props {
    widget: WidgetFactType;
    forcedMessageId?: string;
}

const WidgetPreview: FunctionComponent<Props> = ({ widget, forcedMessageId }) => {
    // Adjust the style of the preview, depending on which type of widget we're previewing
    const previewStyle = useMemo(() => {
        switch (widget?.configType) {
            case 'MINICARD':
                return {
                    aspectRatio: '2 / 1'
                };
            case 'LARGECARD':
                return { aspectRatio: '3 / 2' };
            case 'NUMBERKPI':
            case 'SPARKCOLUMNKPI':
            case 'SPARKLINEKPI':
            case 'TRENDKPI':
                return {
                    aspectRatio: '1',
                    maxWidth: KPI_DEFAULT_MAX_WIDTH
                };
            default:
                return {
                    aspectRatio: '1',
                    maxWidth: DEFAULT_MAX_WIDTH
                };
        }
    }, [widget]);

    // Render
    return (
        <div className="w-75 m-auto" style={previewStyle}>
            <WidgetCard
                widget={widget}
                className="shadow-sm"
                hideMoreButton
                forcedMessageId={forcedMessageId}
                tooltipZIndex={FIRST_MODAL_Z_INDEX}
            />
        </div>
    );
};

export default WidgetPreview;
