import { useCallback, useEffect, useState } from 'react';

const MARGIN = 8;

/**
 * Hook that computes the width of a given input when rendered
 * @param value the value of the input
 * @param placeholder the placeholder of the input
 * @param className the CSS classes of the input
 * @returns the computed width
 */
export function useRenderedTextWidth(
    value: string | undefined,
    placeholder: string | undefined,
    className: string | undefined
): number {
    const [width, setWidth] = useState<number>(0);

    const computeTextWidth = useCallback(
        (value: string | undefined, placeholder: string | undefined, className: string | undefined): void => {
            // Create a temporary span element
            const tmp = document.createElement('span');

            // If classes are provided, add them to temporary element
            if (className) {
                tmp.className = className;
            }

            // Borders are excluded of the width => set them to none in case some CSS class was providing some
            tmp.style.border = 'none';

            // Set the innerHTML to the value if present, placeholder otherwise, empty string otherwise
            tmp.innerHTML = value ? value : placeholder ? placeholder : '';

            // Get the width of the rendered element
            document.body.appendChild(tmp);
            const width = tmp.getBoundingClientRect().width;
            document.body.removeChild(tmp);

            // Total width is equal to width + padding
            const totalWidth = width + +tmp.style.paddingLeft + +tmp.style.paddingRight;

            // Set width state
            setWidth(totalWidth + MARGIN);
        },
        []
    );

    useEffect(() => computeTextWidth(value, placeholder, className), [computeTextWidth, value, placeholder, className]);

    return width;
}
