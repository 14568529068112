import React, { FunctionComponent } from 'react';
import { RichMessage } from 'components/RichMessage';
import { FallbackProps } from 'react-error-boundary';

const WidgetErrorFallback: FunctionComponent<FallbackProps> = ({ error }: FallbackProps) => {
    return (
        <div role="alert">
            <p className="text-danger">
                <RichMessage id="dashboarding.widget.error-fallback.title" />
            </p>
            <pre>{error.message}</pre>
        </div>
    );
};

export default WidgetErrorFallback;
