import gql from 'graphql-tag';

export interface CurrentUserMembershipCompany {
    id: string;
    name: string;
}

export interface CurrentUserMembership {
    role: string;
    status: string;
    company: CurrentUserMembershipCompany;
}

export interface CurrentUserIdentity {
    provider: string;
    datalinkId: string;
}

export interface CurrentUserResp {
    id: string;
    firstName: string;
    email: string;
    createdAt: string;
    identities: {
        nodes: CurrentUserIdentity[];
    };
    memberships: {
        nodes: CurrentUserMembership[];
    };
}

export interface CurrentApiUserResp {
    token: string;
    elevioHash: string;
    intercomHash: string;
    identity: {
        id: string;
        provider: string;
    };
    user: CurrentUserResp;
}

export interface GetCurrentApiUserResp {
    currentApiUser: CurrentApiUserResp;
}

export const GET_CURRENT_API_USER = gql`
    query getCurrentApiUser {
        currentApiUser {
            token
            elevioHash
            intercomHash

            identity {
                id
                provider
            }

            user {
                id
                firstName
                email
                createdAt
                identities {
                    nodes {
                        provider
                        datalinkId
                    }
                }

                memberships {
                    nodes {
                        role
                        status
                        company {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const UNENROLL_USER = gql`
    mutation unenrollUser($id: ID!) {
        unenrolUser(input: { id: $id }) {
            success
        }
    }
`;
