import { useMutation } from '@apollo/react-hooks';
import { faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserRoleForResource } from 'api/hq/hooks/useUserRoleForResource';
import { DeleteWidgetResp, DELETE_WIDGET } from 'api/hq/queries/Widget';
import classNames from 'classnames';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { toastQueryError } from 'components/Toast';
import { SECONDARY_RED, PRIMARY_DARK_BLUE } from 'constants/colors';
import { widgetEditPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { kpPen } from 'util/customIcons';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

export type WidgetActionButtonsType = 'edit' | 'duplicate' | 'delete';
export interface Props {
    variant: 'buttons' | 'dropdown';
    dashboardId?: string;
    widget: WidgetFactType;
    actions?: WidgetActionButtonsType[];
    onDelete?: () => void;
}

const WidgetActions: FunctionComponent<Props> = ({
    variant,
    dashboardId,
    widget,
    onDelete,
    actions = ['edit', 'duplicate', 'delete']
}: Props) => {
    // State
    const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);

    // Load the user roles
    const { canEdit, canDelete, loading } = useUserRoleForResource({
        resourceId: widget.id,
        resourceType: 'WIDGET'
    });

    // Location
    const location = useLocation();

    // I18n
    const intl = useIntl();

    // Navigate hook
    const navigate = useNavigate();

    // Navigate to widget
    const navigateToWidget = useCallback(
        (widget: WidgetFactType, redirectTo?: string): void =>
            navigate(widgetEditPath({ dashboardId, widget, redirectTo })),
        [dashboardId, navigate]
    );

    // Delete a widget
    const [deleteWidget] = useMutation<DeleteWidgetResp>(DELETE_WIDGET);

    // Delete widget
    const removeWidget = useCallback(async () => {
        try {
            setDeletionInProgress(true);

            // Delete the widget
            const resp = (await deleteWidget({ variables: { id: widget.id } })).data?.deleteWidget;

            if (resp?.success) {
                if (onDelete) onDelete();
                setIsDeleteConfirmationModalOpen(false);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'delete-widget' });
            }
        } catch (error) {
            toastQueryError({ namespace: 'delete-widget' });
        } finally {
            setDeletionInProgress(false);
        }
    }, [deleteWidget, onDelete, widget.id]);

    // Deletion confirmation modal confirm button
    const deleteModalConfirmButton = useMemo(() => {
        return (
            <AsyncButton
                variant="danger"
                onClick={removeWidget}
                disabled={deletionInProgress}
                className="w-100 justify-content-center"
                loading={deletionInProgress}
                messageProps={{ id: 'collections.confirmation-modal.delete-widget.confirm-button' }}
                loadingMessageProps={{ id: 'collections.confirmation-modal.delete-widget.deletion-in-progress' }}
            />
        );
    }, [deletionInProgress, removeWidget]);

    if (loading) return <Spinner animation="border" size="sm" />;

    return (
        <>
            {variant == 'buttons' ? (
                <div className="d-flex btn-actions">
                    {/* Edit button */}
                    {actions.includes('edit') && (
                        <Button
                            className="btn-action btn-sm btn-white border-1 border-grey-6"
                            title={intl.formatMessage({
                                id: `collections.actions.edit`
                            })}
                            disabled={!canEdit}
                            onClick={() => navigateToWidget(widget as WidgetFactType, location.pathname)}
                        >
                            <FontAwesomeIcon icon={kpPen} color={PRIMARY_DARK_BLUE} />
                        </Button>
                    )}

                    {/* Duplicate button */}
                    {actions.includes('duplicate') && (
                        <Button
                            className="btn-action btn-sm btn-white border-1 border-grey-6"
                            title={intl.formatMessage({
                                id: `collections.actions.duplicate`
                            })}
                            onClick={() =>
                                navigate(
                                    widgetEditPath({
                                        dashboardId,
                                        widget: {
                                            ...widget,
                                            id: '',
                                            name: intl.formatMessage(
                                                { id: 'generic.with-copy-suffix' },
                                                { name: widget.name }
                                            ),
                                            templateSource: widget.id,
                                            actionSource: 'duplicate'
                                        },
                                        redirectTo: location.pathname
                                    })
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faClone} color={PRIMARY_DARK_BLUE} />
                        </Button>
                    )}

                    {/* Delete button */}
                    {actions.includes('delete') && (
                        <Button
                            className="btn-action btn-sm btn-white border-1 border-grey-6"
                            title={intl.formatMessage({
                                id: `collections.actions.delete`
                            })}
                            disabled={!canDelete}
                            onClick={() => setIsDeleteConfirmationModalOpen(true)}
                        >
                            <FontAwesomeIcon icon={faTrash} color={SECONDARY_RED} />
                        </Button>
                    )}
                </div>
            ) : variant == 'dropdown' ? (
                <Dropdown>
                    <Dropdown.Toggle as="div" bsPrefix="none" role="button" className="text-light">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                        {actions
                            .map<React.ReactNode>(e => {
                                switch (e) {
                                    case 'edit':
                                        return (
                                            <Dropdown.Item
                                                key={e}
                                                as="div"
                                                role="button"
                                                className={classNames({ 'text-dark': canEdit })}
                                                disabled={!canEdit}
                                                onClick={() =>
                                                    navigateToWidget(widget as WidgetFactType, location.pathname)
                                                }
                                            >
                                                <FontAwesomeIcon className="me-2" icon={kpPen} />
                                                <RichMessage id="collections.actions.edit" />
                                            </Dropdown.Item>
                                        );
                                    case 'duplicate':
                                        return (
                                            <Dropdown.Item
                                                key={e}
                                                as="div"
                                                role="button"
                                                className="text-dark"
                                                onClick={() =>
                                                    navigate(
                                                        widgetEditPath({
                                                            widget: {
                                                                ...widget,
                                                                id: '',
                                                                name: intl.formatMessage(
                                                                    { id: 'generic.with-copy-suffix' },
                                                                    { name: widget.name }
                                                                ),
                                                                templateSource: widget.id,
                                                                actionSource: 'duplicate'
                                                            },
                                                            redirectTo: location.pathname
                                                        })
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon className="me-2" icon={faClone} />
                                                <RichMessage id="collections.actions.duplicate" />
                                            </Dropdown.Item>
                                        );
                                    case 'delete':
                                        return (
                                            <Dropdown.Item
                                                key={e}
                                                as="div"
                                                role="button"
                                                className={classNames({ 'text-danger': canDelete })}
                                                disabled={!canDelete}
                                                onClick={() => setIsDeleteConfirmationModalOpen(true)}
                                            >
                                                <FontAwesomeIcon className="me-2" icon={faTrash} />
                                                <RichMessage id="collections.actions.delete" />
                                            </Dropdown.Item>
                                        );
                                }
                            })
                            .reduce((previous, current, currentIndex) => [
                                previous,
                                <Dropdown.Divider key={currentIndex} className="d-none d-md-block" />,
                                current
                            ])}
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}

            {/* Delete confirmation modal */}
            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    isOpen={isDeleteConfirmationModalOpen}
                    icon={faTrash}
                    iconColor={SECONDARY_RED}
                    title={<RichMessage id="collections.confirmation-modal.delete-widget.title" />}
                    content={<RichMessage id="collections.confirmation-modal.delete-widget.content" />}
                    customConfirmButton={deleteModalConfirmButton}
                    onCancel={() => setIsDeleteConfirmationModalOpen(false)}
                />
            )}
        </>
    );
};

export default WidgetActions;
