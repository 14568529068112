import { ConfigQnDbNaming } from 'components/Dashboarding/Models/ConfigQns';
import IconPicker, { IconPickerConfig } from 'components/Design/ColorPickers/IconPicker';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Label } from '../Label';

interface Props {
    config: ConfigQnDbNaming;
    onChange: (e: ConfigQnDbNaming) => void;
}

export const DashboardNaming: FunctionComponent<Props> = ({ config, onChange }: Props) => {
    const [icon, setIcon] = useState<IconPickerConfig | undefined>();
    const [name, setName] = useState<string>('');

    // Debounced version of onChange
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Hook invoked when the input changes
    const handleOnNameChange = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            setName(event.target.value);
            debouncedOnChange({ ...config, result: { icon, name: event.target.value } });
        },
        [debouncedOnChange, config, icon]
    );

    const handleOnIconChange = useCallback(
        (icon: IconPickerConfig) => {
            setIcon(icon);
            onChange({ ...config, result: { name, icon } });
        },
        [onChange, config, name]
    );

    // Resync icon from parent update
    useEffect(() => setIcon(config.result.icon), [config.result.icon]);

    // Resync name from parent update
    useEffect(() => setName(config.result.name), [config.result.name]);

    return (
        <Form.Group>
            <Label config={config} />
            <div className="d-flex">
                {/* Icon */}
                <IconPicker icon={icon} editable={true} onChange={handleOnIconChange} className="me-3" size={45} />
                {/* Name */}
                <Form.Control type="input" value={name} onChange={handleOnNameChange} />
            </div>
        </Form.Group>
    );
};
