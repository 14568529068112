import React, { FunctionComponent, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RichMessage } from 'components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BillingSubscriptionResp } from 'api/hq/queries/BillingSubscription';
import CreditCardForm from 'components/CreditCard/CreditCardForm';
import { useSafeState } from 'util/useSafeState';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    billingSubscription: BillingSubscriptionResp;
    onContinue?: () => void | Promise<void>;
}

const CreditCardRequiredModal: FunctionComponent<Props> = ({
    isOpen,
    toggle,
    billingSubscription,
    onContinue
}: Props) => {
    // State
    const [isProcessing, setIsProcessing] = useSafeState<boolean>(false);

    // On proceed we invoke the onContinue hook (subscription upgrade)
    // and close the modal.
    const handleProceed = useCallback(async () => {
        setIsProcessing(true);
        onContinue && (await onContinue());
        toggle();
        setIsProcessing(false);
    }, [onContinue, setIsProcessing, toggle]);

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            {/* Modal Title */}
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-primary me-3" size="2x" />
                    <RichMessage id="components.billing-guard.credit-card-required-modal.title" />
                </Modal.Title>
            </Modal.Header>

            {/* Modal Body */}
            <Modal.Body className="px-5 pb-3">
                {/* Explanation on why they need to enter a credit card */}
                <div className="mb-4">
                    <RichMessage id="components.billing-guard.credit-card-required-modal.body" />
                </div>
                {/* Credit card form */}
                <CreditCardForm
                    billingSubscription={billingSubscription}
                    onSave={handleProceed}
                    onCancelNew={toggle}
                    displayMode="compact"
                    btnConfig={{
                        saveKey: 'components.billing-guard.credit-card-required-modal.btn-proceed',
                        savingKey: 'components.billing-guard.credit-card-required-modal.btn-proceeding'
                    }}
                    disabled={isProcessing}
                />
            </Modal.Body>
        </Modal>
    );
};

export default CreditCardRequiredModal;
