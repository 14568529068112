import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryError, ErrorPage } from 'components/ErrorManagement';

interface Props {
    code?: string;
    description?: string;
}

const PublicError: FunctionComponent<Props> = ({ code }: Props) => {
    // Services
    const [searchParams] = useSearchParams();

    // Check if the error description has the format of an error code.
    // If so we include this sub-code as part of the full code retrieved from query params.
    //
    // The 'code' is always set by Auth0 and cannot be overriden
    // The 'description' may be set by the provided itself to either provide
    // an error description or a sub code.
    const errorDesc = searchParams.get('description');
    const errorSubCode = errorDesc?.match(/^([a-z]|\d|-|_)+$/) ? errorDesc : null;
    const codeFromQueryParams = [searchParams.get('code'), errorSubCode].filter(e => e).join(':');
    const origin = searchParams.get('origin');

    // Format actual error
    const error: QueryError = {
        code: code || codeFromQueryParams || 'default',
        namespace: 'public',
        context: {},
        origin
    };

    // Setup context variables
    // (used by error components as translation vars)
    searchParams.forEach((val, key) => {
        if (error.context && key !== 'code') {
            error.context[key] = val;
        }
    });

    return <ErrorPage error={error} />;
};

export default PublicError;
