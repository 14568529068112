import { useEffect, useRef, MutableRefObject } from 'react';

// Return a reference specifying if the component is mounted or unmounted
// Knowing the mount state of a component is useful when the component
// state must be modified after a parent callback which may or may not
// remove the component itself.
export const useIsMounted = (): MutableRefObject<boolean> => {
    // Capture mount state
    const _isMounted = useRef(true);

    // Use cleanup function to detect when the component is unmounted.
    useEffect(() => {
        return () => {
            _isMounted.current = false;
        };
    }, []);

    return _isMounted;
};
