import { useCallback, useEffect, useState } from 'react';
import { WidgetDimensionField } from 'components/Dashboarding/Models/Widget';
import { DEFAULT_DIMENSIONS } from './useExplorerReport';

export interface Props {
    dimensions?: WidgetDimensionField[];
    onChange?: (dimensions: WidgetDimensionField[]) => void;
}

export interface ResultHandlers {
    handleSortBy: (e: { field: string; sort: 'ASC' | 'DESC' }) => void;
    setFormattedDimensions: React.Dispatch<React.SetStateAction<WidgetDimensionField[]>>;
}

export type Result = [WidgetDimensionField[], ResultHandlers];

// Format the dimensions with sorting
export function useFormatDimensions({ dimensions, onChange }: Props): Result {
    const [formattedDimensions, setFormattedDimensions] = useState<WidgetDimensionField[]>(DEFAULT_DIMENSIONS);

    // Set the updated dimensions
    useEffect(() => dimensions?.find(e => e.sort) && setFormattedDimensions(dimensions), [dimensions]);

    // Construct the dimension query with sorting by one field
    const handleSortBy = useCallback(
        ({ field, sort }) => {
            const newFormattedDimensions = formattedDimensions.map(e =>
                e.ref == field ? { ...e, sort } : { ...e, sort: null }
            );
            setFormattedDimensions(newFormattedDimensions);

            // Callback
            if (onChange) onChange(newFormattedDimensions);
        },
        [formattedDimensions, onChange]
    );

    return [formattedDimensions, { handleSortBy, setFormattedDimensions }];
}
