import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadCry, IconDefinition } from '@fortawesome/free-regular-svg-icons';

interface Props {
    error?: QueryError;
    icon?: IconDefinition;
}

export const ErrorFragment: FunctionComponent<Props> = ({ error, icon }: Props) => {
    return (
        <div className="text-center">
            <div className="mb-2">
                <FontAwesomeIcon icon={icon || faSadCry} size="4x" className="text-primary mb-2" />
                <h2>
                    <FormattedMessage id="error-fragment.title" />
                </h2>
            </div>
            <p>
                <ErrorMessage error={error} />
            </p>
        </div>
    );
};
