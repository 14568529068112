import classNames from 'classnames';
import { chunk } from 'lodash';
import React, { FunctionComponent } from 'react';
import { MAIN_PICKER_COLORS } from './ColorMatrix';
import IconRenderer, { DEFAULT_PICKER_ICON, SupportedPickerIconType, SUPPORTED_PICKER_ICONS } from './IconRenderer';

// The size of the icon
const ICON_SIZE = 20; //px

// The icon matrix is built in chunks so as to display
// the same number of colums as the color matrix
const ICON_MATRIX = chunk(SUPPORTED_PICKER_ICONS, MAIN_PICKER_COLORS.length);

interface Props {
    value?: SupportedPickerIconType;
    onChange: (e: SupportedPickerIconType) => void;
}

const IconMatrix: FunctionComponent<Props> = ({ value, onChange }: Props) => {
    // Get the actual value for comparison purpose
    const selectedValue = value || DEFAULT_PICKER_ICON;

    // Render
    return (
        <div>
            {ICON_MATRIX.map((iconGroup, iconGroupIndex) => {
                return (
                    <div key={iconGroup[0]} className={classNames('d-flex', { 'mt-2': iconGroupIndex > 0 })}>
                        {iconGroup.map((icon, iconIndex) => {
                            return (
                                <div
                                    key={icon}
                                    className={classNames({
                                        'ms-2': iconIndex > 0,
                                        'text-light': icon != selectedValue
                                    })}
                                    onClick={() => onChange(icon)}
                                    role="button"
                                >
                                    <IconRenderer icon={icon} size={ICON_SIZE} />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default IconMatrix;
