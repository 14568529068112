import React, { ReactElement } from 'react';
import { WidgetChartType } from 'components/Dashboarding/Models/Widget';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import Form from 'react-bootstrap/Form';
import PaletteColorPicker from 'components/Design/ColorPickers/PaletteColorPicker';
import SeriesLineStylePicker from 'components/Dashboarding/SeriesLineStylePicker';
import PalettePicker from 'components/Design/ColorPickers/PalettePicker';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';
import { useCustomizeChart } from './useCustomizeChart';

interface Props<TWidget extends WidgetChartType> {
    widget: TWidget;
    onChange: (widget: TWidget) => void;
}

function CustomizeChart<TWidget extends WidgetChartType>({
    widget,
    onChange
}: Props<TWidget>): ReactElement<Props<TWidget>> {
    // Services
    const intl = useRichIntl();

    const {
        localWidget,
        mainSeries,
        displayConfig,
        onWidgetFormattingChange,
        onMetricFormattingChange
    } = useCustomizeChart<TWidget>(widget, onChange);

    // Render
    return (
        <div>
            {/* Metrics section */}
            <div>
                {/* Title */}
                <div>
                    <h4 className="text-dark mb-4">
                        <span className="elevio-customize-chart-title">
                            <RichMessage
                                id="dashboarding.widget-editor.customize-chart.metrics-customization"
                                values={{ count: mainSeries.metrics.length }}
                            />
                        </span>
                    </h4>
                </div>

                {/* Metrics section inner */}
                <Row className="mb-4">
                    <Col xs="6" className="pe-4">
                        {mainSeries.metrics.map((metric, index) => (
                            <div key={index} className="d-flex mb-2">
                                {/* Metric color: individual */}
                                {displayConfig.customizeMetricColorMode == 'individual' && (
                                    <PaletteColorPicker
                                        color={metric.formatting?.color}
                                        onChange={e => onMetricFormattingChange(index, { color: e })}
                                        className="me-2"
                                    />
                                )}

                                {/* Metric color: global */}
                                {displayConfig.customizeMetricColorMode == 'palette' && (
                                    <PalettePicker
                                        colors={localWidget.config.formatting?.baseColors}
                                        onChange={e => onWidgetFormattingChange({ baseColors: e })}
                                        className="me-2"
                                    />
                                )}

                                {/* Metric line style */}
                                {displayConfig.customizeMetricLineStyle && (
                                    <SeriesLineStylePicker
                                        color={metric.formatting?.color}
                                        lineStyle={metric.formatting?.lineStyle}
                                        onChange={e => onMetricFormattingChange(index, { lineStyle: e })}
                                        className="me-2"
                                    />
                                )}

                                {/* Metric label */}
                                <FormControlWithCommit
                                    value={metric.formatting?.label || ''}
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.metric-legend-placeholder'
                                    })}
                                    onCommit={val => onMetricFormattingChange(index, { label: val })}
                                />
                            </div>
                        ))}
                    </Col>
                    <Col xs="6" className="ps-4">
                        {/* Legend switch */}
                        <div className="d-flex justify-content-between mb-3">
                            <div className="me-2">
                                <Form.Label htmlFor="legendSwitchInput" className="d-block mb-0">
                                    <RichMessage
                                        id="dashboarding.widget-editor.customize-chart.show-metric-legend.title"
                                        values={{ count: mainSeries.metrics.length }}
                                    />
                                </Form.Label>
                                <Form.Text id="legendSwitchHelp" muted>
                                    <RichMessage id="dashboarding.widget-editor.customize-chart.show-metric-legend.description" />
                                </Form.Text>
                            </div>
                            <Form.Check
                                type="switch"
                                id="legendSwitchInput"
                                aria-describedby="legendSwitchHelp"
                                role="button"
                                checked={!!localWidget.config.formatting?.showChartLegend}
                                onChange={e => onWidgetFormattingChange({ showChartLegend: e.target.checked })}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Axis & Legend Options */}
            {displayConfig.customizeAxis && (
                <div>
                    {/* Title */}
                    <div>
                        <h4 className="text-dark mb-4">
                            <RichMessage id="dashboarding.widget-editor.customize-chart.axis-legend-options" />
                        </h4>
                    </div>

                    {/* Axis section inner */}
                    <Row className="mb-4">
                        {/* X Axis */}
                        <Col xs="6" className="pe-4">
                            {/* Label */}
                            <Form.Label htmlFor="axisXLabelInput" className="d-block">
                                <RichMessage id="dashboarding.widget-editor.customize-chart.axis-x.label" />
                            </Form.Label>

                            {/* Control */}
                            <div className="d-flex align-items-center mb-2">
                                <FormControlWithCommit
                                    value={localWidget.config.formatting?.xAxis?.label || ''}
                                    id="axisXLabelInput"
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.axis-x.placeholder'
                                    })}
                                    onCommit={val => onWidgetFormattingChange({ xAxis: { label: val } })}
                                    className="flex-grow-1 me-4"
                                />
                                <Form.Check
                                    type="switch"
                                    role="button"
                                    checked={!!localWidget.config.formatting?.xAxis?.show}
                                    onChange={e => onWidgetFormattingChange({ xAxis: { show: e.target.checked } })}
                                />
                            </div>
                        </Col>

                        {/* Y Axis */}
                        <Col xs="6" className="pe-4">
                            {/* Label */}
                            <Form.Label htmlFor="axisYLabelInput" className="d-block">
                                <RichMessage id="dashboarding.widget-editor.customize-chart.axis-y.label" />
                            </Form.Label>

                            {/* Control */}
                            <div className="d-flex align-items-center mb-2">
                                <FormControlWithCommit
                                    value={localWidget.config.formatting?.yAxis?.label || ''}
                                    id="axisYLabelInput"
                                    placeholder={intl.formatMessage({
                                        id: 'dashboarding.widget-editor.customize-chart.axis-y.placeholder'
                                    })}
                                    onCommit={val => onWidgetFormattingChange({ yAxis: { label: val } })}
                                    className="flex-grow-1 me-4"
                                />
                                <Form.Check
                                    type="switch"
                                    role="button"
                                    checked={!!localWidget.config.formatting?.yAxis?.show}
                                    onChange={e => onWidgetFormattingChange({ yAxis: { show: e.target.checked } })}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default CustomizeChart;
