import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { NameLabelPair } from 'react-querybuilder';
import PickedDataWrapper from './PickedDataWrapper';
import { RulesetValueFieldProps } from './RulesetValueField';

interface Props extends RulesetValueFieldProps {
    className?: string;
    title?: string;
    onChange: (value: string | undefined, pickedCoordinateIndex?: number) => void;
    value: string | undefined;
    values: NameLabelPair[];
    pickedCoordinateIndex?: number;
}

const SelectValueField: FunctionComponent<Props> = ({
    className,
    title,
    onChange,
    value,
    values,
    pickedCoordinateIndex,
    drilldownEnabled
}: Props) => {
    // Get the selected label within the provided values
    const [selectedLabel, setSelectedLabel] = useState<string>(values.find(v => v.name == value)?.label ?? '');

    // Handle field selection
    const handleSelection = useCallback(
        (name: string | null): void => {
            if (name === null) return;
            onChange(name);
        },
        [onChange]
    );

    // Handle picked coordinate index selection
    const handlePickedCoordinateIndexChange = useCallback(
        (e: number | null) => {
            onChange(undefined, e == null ? undefined : e);
        },
        [onChange]
    );

    // Set default value
    useEffect(() => {
        if (values.length > 0) {
            setSelectedLabel(values.find(e => e.name == value)?.label ?? '');
        }
    }, [values, value]);

    return (
        <>
            {/* Picked data wrapper */}
            <PickedDataWrapper
                drilldownEnabled={!!drilldownEnabled}
                pickedCoordinateIndex={pickedCoordinateIndex}
                onChange={handlePickedCoordinateIndexChange}
            >
                <Dropdown onSelect={handleSelection}>
                    <Dropdown.Toggle
                        as="div"
                        role="button"
                        className={classNames(className, 'd-flex justify-content-between align-items-center h-100')}
                        title={title}
                    >
                        {selectedLabel || '-'}
                    </Dropdown.Toggle>

                    {/* List of values */}
                    <Dropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        {values.map(v => {
                            return (
                                <Dropdown.Item key={v.name} eventKey={v.name}>
                                    {v.label}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </PickedDataWrapper>
        </>
    );
};

export default SelectValueField;
