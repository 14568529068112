import { SprintSettings, MutationResp } from 'api/hq/queries/Shared';
import { useRichIntl, RichMessage } from 'components/RichMessage';
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSafeState } from 'util/useSafeState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSave } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AsyncButton } from 'components/AsyncButton';
import { toastQueryError } from 'components/Toast';

const DEFAULT_METHODOLOGY = 'SCRUM';
const DEFAULT_START_ON = 'ASSIGNED_AT';

interface Props {
    sprintSettings: SprintSettings;
    onSubmit: (e: SprintSettings | null) => Promise<MutationResp | undefined>;
    hasReset?: boolean;
    canEdit?: boolean;
}

const SprintSettingsForm: FunctionComponent<Props> = ({
    sprintSettings,
    onSubmit,
    hasReset = false,
    canEdit = false
}: Props) => {
    // Services
    const intl = useRichIntl();

    const [updateInProgress, setUpdateInProgress] = useSafeState<boolean>(false);
    const [sprintMethodology, setSprintMethodology] = useSafeState<string | undefined>(undefined);
    const [sprintStartOn, setSprintStartOn] = useSafeState<string | undefined>(undefined);
    const [sprintDurationType, setSprintDurationType] = useSafeState<string | undefined>(undefined);
    const [sprintDurationDisplay, setSprintDurationDisplay] = useSafeState<number | undefined>(undefined);

    // Extract default values
    const baseMethodology = sprintSettings.methodology || DEFAULT_METHODOLOGY;
    const baseStartOn = sprintSettings.timerStartOn || DEFAULT_START_ON;
    const baseDuration = sprintSettings.timerDuration || 0;
    const baseDurationType = baseDuration % (24 * 60) === 0 ? 'DAYS' : baseDuration % 60 === 0 ? 'HOURS' : 'MINUTES';
    const baseDurationDisplay =
        baseDurationType === 'DAYS'
            ? baseDuration / (24 * 60)
            : baseDurationType === 'HOURS'
            ? baseDuration / 60
            : baseDuration;

    // Form values
    const formMethodology = sprintMethodology || baseMethodology;
    const formStartOn = sprintStartOn || baseStartOn;
    const formDurationType = sprintDurationType || baseDurationType;
    const formDurationDisplay = sprintDurationDisplay || baseDurationDisplay;
    const formDuration =
        formDurationType === 'DAYS'
            ? formDurationDisplay * 24 * 60
            : formDurationType === 'HOURS'
            ? formDurationDisplay * 60
            : formDurationDisplay;

    // Display logic
    const isKanban = formMethodology === 'KANBAN';
    const isSaveEnabled =
        formMethodology !== baseMethodology || formStartOn !== baseStartOn || formDuration !== baseDuration;

    // Form hook: methodology
    const handleMethodologyChange = useCallback(
        (value: string): void => {
            setSprintMethodology(value);
        },
        [setSprintMethodology]
    );

    // Form hook: start on
    const handleStartOnChange = useCallback(
        (value: string): void => {
            setSprintStartOn(value);
        },
        [setSprintStartOn]
    );

    // Form hook: duration type
    const handleDurationTypeChange = useCallback(
        (value: string): void => {
            setSprintDurationType(value);
        },
        [setSprintDurationType]
    );

    // Form hook: duration display change
    const handleDurationDisplayChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            setSprintDurationDisplay(event.target.valueAsNumber);
        },
        [setSprintDurationDisplay]
    );

    // Reset form values
    const resetForm = useCallback((): void => {
        setSprintMethodology(undefined);
        setSprintStartOn(undefined);
        setSprintDurationType(undefined);
        setSprintDurationDisplay(undefined);
    }, [setSprintDurationDisplay, setSprintDurationType, setSprintMethodology, setSprintStartOn]);

    // Reset function
    const handleReset = useCallback(async (): Promise<void> => {
        setUpdateInProgress(true);

        try {
            const updateResp = await onSubmit(null);
            setUpdateInProgress(false);

            if (updateResp?.success) {
                setUpdateInProgress(false);
            } else {
                toastQueryError({ ...updateResp?.errors[0], namespace: 'update-sprint-settings' });
            }
        } catch {
            toastQueryError({ namespace: 'update-sprint-settings' });
        } finally {
            resetForm();
        }
    }, [onSubmit, resetForm, setUpdateInProgress]);

    // Save function
    const handleSubmit = useCallback(async (): Promise<void> => {
        setUpdateInProgress(true);

        // Build sprint settings
        const sprintSettings: SprintSettings = {
            methodology: formMethodology,
            timerStartOn: formStartOn,
            timerDuration: formDuration
        };

        try {
            const updateResp = await onSubmit(sprintSettings);
            setUpdateInProgress(false);

            if (updateResp?.success) {
                setUpdateInProgress(false);
            } else {
                toastQueryError({ ...updateResp?.errors[0], namespace: 'update-sprint-settings' });
            }
        } catch {
            setUpdateInProgress(false);
            toastQueryError({ namespace: 'update-sprint-settings' });
        } finally {
            resetForm();
        }
    }, [formDuration, formMethodology, formStartOn, onSubmit, resetForm, setUpdateInProgress]);

    return (
        <>
            {/* Non-admin warning */}
            {!canEdit && (
                <div className="text-muted mb-4">
                    <RichMessage id="settings.team-management.team-sprint-settings.non-admin" />
                </div>
            )}

            <div>
                <Form onSubmit={handleSubmit} className="d-block d-lg-flex align-items-center mb-5">
                    {/* Select sprint methodology */}
                    <div
                        className={classNames('d-flex justify-content-lg-center mb-4 mb-lg-0', {
                            'flex-grow-1 ': isKanban
                        })}
                    >
                        <Form.Group>
                            <Form.Label htmlFor="sprintMethodology">
                                <RichMessage id="settings.team-management.team-sprint-settings.form.sprint-methodology" />
                            </Form.Label>
                            <Dropdown id="sprintMethodology">
                                <Dropdown.Toggle variant="outline-light" className="text-dark" disabled={!canEdit}>
                                    <RichMessage
                                        id={`settings.team-management.team-sprint-settings.form.sprint-methodology.${formMethodology}`}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleMethodologyChange('SCRUM')}>
                                        <RichMessage id="settings.team-management.team-sprint-settings.form.sprint-methodology.SCRUM" />
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleMethodologyChange('KANBAN')}>
                                        <RichMessage id="settings.team-management.team-sprint-settings.form.sprint-methodology.KANBAN" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </div>

                    {/* Which field should the timer be based on */}
                    {isKanban && (
                        <>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                size="2x"
                                className="text-grey-4 mx-4 d-none d-lg-block"
                            />

                            {/* Select timer field */}
                            <div className="flex-grow-1 d-flex justify-content-lg-center mb-4 mb-lg-0">
                                <Form.Group>
                                    <Form.Label htmlFor="sprintTimerOn">
                                        <RichMessage id="settings.team-management.team-sprint-settings.form.timer-from" />
                                    </Form.Label>
                                    <Dropdown id="sprintTimerOn">
                                        <Dropdown.Toggle
                                            variant="outline-light"
                                            className="text-dark"
                                            disabled={!canEdit}
                                        >
                                            <RichMessage
                                                id={`settings.team-management.team-sprint-settings.form.timer-from.${formStartOn}`}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleStartOnChange('ASSIGNED_AT')}>
                                                <RichMessage id="settings.team-management.team-sprint-settings.form.timer-from.ASSIGNED_AT" />
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleStartOnChange('CREATED_AT')}>
                                                <RichMessage id="settings.team-management.team-sprint-settings.form.timer-from.CREATED_AT" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </div>

                            <FontAwesomeIcon
                                icon={faChevronRight}
                                size="2x"
                                className="text-grey-4 mx-4 d-none d-lg-block"
                            />

                            {/* Select timer duration */}
                            <div className="flex-grow-1 d-flex justify-content-lg-center">
                                <Form.Group>
                                    <Form.Label>
                                        <RichMessage
                                            id={`settings.team-management.team-sprint-settings.form.timer-duration-with.${formStartOn}`}
                                        />
                                    </Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            name="Time duration"
                                            type="number"
                                            value={formDurationDisplay}
                                            onChange={handleDurationDisplayChange}
                                            style={{ maxWidth: '100px' }}
                                            className="me-1"
                                            disabled={!canEdit}
                                        />
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-dark" disabled={!canEdit}>
                                                {intl.formatRichMessage({
                                                    id: `settings.team-management.team-sprint-settings.form.timer-duration-type.${formDurationType}`
                                                })}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleDurationTypeChange('DAYS')}>
                                                    {intl.formatRichMessage({
                                                        id:
                                                            'settings.team-management.team-sprint-settings.form.timer-duration-type.DAYS'
                                                    })}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDurationTypeChange('HOURS')}>
                                                    {intl.formatRichMessage({
                                                        id:
                                                            'settings.team-management.team-sprint-settings.form.timer-duration-type.HOURS'
                                                    })}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDurationTypeChange('MINUTES')}>
                                                    {intl.formatRichMessage({
                                                        id:
                                                            'settings.team-management.team-sprint-settings.form.timer-duration-type.MINUTES'
                                                    })}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Form.Group>
                            </div>
                        </>
                    )}
                </Form>

                <div className="d-flex justify-content-end">
                    {/* Save button */}
                    {canEdit && isSaveEnabled && (
                        <>
                            {/* Cancel button */}
                            <Button variant="link" onClick={resetForm} className="ms-2">
                                <RichMessage id="settings.team-management.team-sprint-settings.action.cancel" />
                            </Button>

                            {/* Save button */}
                            <Button
                                variant="dark"
                                className="px-5"
                                onClick={handleSubmit}
                                disabled={updateInProgress || !isSaveEnabled}
                            >
                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                <RichMessage
                                    id={
                                        updateInProgress
                                            ? 'settings.team-management.team-sprint-settings.action.saving'
                                            : 'settings.team-management.team-sprint-settings.action.save'
                                    }
                                />
                            </Button>
                        </>
                    )}

                    {/* Reset button */}
                    {canEdit && hasReset && (
                        <AsyncButton
                            variant="outline-dark"
                            onClick={handleReset}
                            disabled={updateInProgress}
                            className="ms-2"
                            loading={updateInProgress}
                            messageProps={{ id: 'settings.team-management.team-sprint-settings.action.reset' }}
                            loadingMessageProps={{
                                id: 'settings.team-management.team-sprint-settings.action.resetting'
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default SprintSettingsForm;
