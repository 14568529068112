import React, { FunctionComponent, ReactElement } from 'react';
import { IntercomProvider } from 'react-use-intercom';

// Intercom App ID
const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;

interface Props {
    children: ReactElement;
}

const IntercomWrapper: FunctionComponent<Props> = ({ children }: Props) => {
    if (INTERCOM_ID) {
        return <IntercomProvider appId={INTERCOM_ID}>{children}</IntercomProvider>;
    } else {
        return children;
    }
};

export default IntercomWrapper;
