import React, { FunctionComponent, useContext } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { CompanyState } from 'redux/auth/reducer';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { FormattedMessage } from 'react-intl';
import { useCompanyUsers } from 'api/hq/hooks/useCompanyUsers';
import {
    QueryOpFilterDisplayType,
    QUERY_OP_FIELD_WILDCARD_SELECTOR,
    QUERY_OP_FILTER_COMBINATORS,
    QUERY_OP_FILTER_FUNCTIONS
} from 'components/Dashboarding/DataSource/QueryOperators';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { childFieldValueChain, useQueryOpFieldLookup } from 'components/Dashboarding/Hooks/useQueryOpFieldLookup';
import { DateRangeValue, DateValue, RuleValue, TimePeriodValue } from './useRulesetValidator';
import classNames from 'classnames';
import { DrilldownContext } from 'contexts/DrilldownContext';

const isRuleEmpty = (rule: RuleGroupType | RuleType): boolean => {
    return !((rule as RuleGroupType).rules?.length > 0 || (rule as RuleType).operator);
};

interface Props {
    fact: QueryOpFact;
    ruleset?: RuleGroupType | RuleType;
    level?: number;
    className?: string;
    placeholder?: string;
}

export const RulesetExplainer: FunctionComponent<Props> = ({
    fact,
    ruleset,
    level = 0,
    className,
    placeholder
}: Props) => {
    // Get company
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Extract level information
    const rootLevel = level === 0;
    const subLevel = level > 1;
    const noContentElem = level === 1 ? <span>{placeholder}</span> : null;

    if (rootLevel) {
        return (
            <pre className={classNames('ruleset-explainer', className)}>
                <RulesetExplainer fact={fact} ruleset={ruleset} level={level + 1} placeholder={placeholder} />
            </pre>
        );
    }

    // Abort ruleset parsing
    if (!ruleset) return noContentElem;

    // Extract properly formatted rulesets
    const group = ruleset as RuleGroupType;
    const rule = ruleset as RuleType<string, string, RuleValue | undefined>;

    // Join rules based on combinator
    if (group.combinator) {
        if (!group.rules || group.rules.length === 0) return noContentElem;

        // Extract combinator label and separator
        const combinator =
            QUERY_OP_FILTER_COMBINATORS.find(e => e.name === group.combinator)?.label || group.combinator;
        const separator = [
            ['[', ']'],
            ['{', '}'],
            ['(', ')']
        ][level % 3];
        const displayedRules = group.rules.filter(e => !isRuleEmpty(e));

        // Abort if no rules to display
        if (displayedRules.length === 0) {
            return null;
        }

        // Combine rules
        return (
            <div className="statement-block">
                {subLevel && <span className="statement-separator">{separator[0]}</span>}
                {displayedRules
                    .map((e, i) => <RulesetExplainer fact={fact} ruleset={e} key={i} level={level + 1} />)
                    .reduce((prev, curr) => (
                        <>
                            {prev} <b>{combinator}</b> {curr}
                        </>
                    ))}
                {subLevel && <span className="statement-separator">{separator[1]}</span>}
            </div>
        );
    }

    // Translate rule based on operator
    if (rule.operator) {
        return (
            <OperationLabel
                company={company}
                fact={fact}
                fieldUri={rule.field}
                operator={rule.operator}
                value={rule.value}
            />
        );
    }

    return noContentElem;
};

interface OperationLabelProps {
    fact: QueryOpFact;
    fieldUri: string;
    operator: string;
    value?: RuleValue;
    company: CompanyState | null;
}

const OperationLabel: FunctionComponent<OperationLabelProps> = ({
    fact,
    fieldUri,
    operator,
    value: ruleValue,
    company
}: OperationLabelProps) => {
    // Get parent field
    const parentField = useQueryOpFieldLookup({ fact, fieldUri, matchParent: true });

    // Retrieve pickable data from drilldown context
    const { pickableData } = useContext(DrilldownContext);

    // Retrieve company members (used to populate values when *_IS_USER or *_INCLUDES_USER operators are used)
    const { loading: membersLoading, normalized: companyMembers } = useCompanyUsers({ companyId: company?.id });

    // Format base label for the parent field
    const sectionLabel = parentField?.filterSection?.toUpperCase();
    const baseFieldLabel = [sectionLabel, (parentField?.id || fieldUri).toLowerCase().replace(' ', '_')]
        .filter(e => !!e)
        .join('.');

    // Add nested fields in dot notation form
    const nestedChain = childFieldValueChain(parentField, fieldUri).split('.');
    const fieldLabel = nestedChain.reduce((prev, curr) => {
        if (curr === '') return prev;
        return isNaN(+curr) && curr !== QUERY_OP_FIELD_WILDCARD_SELECTOR ? [prev, curr].join('.') : `${prev}[${curr}]`;
    }, baseFieldLabel);

    // Get field operator configuration
    const opConfig = QUERY_OP_FILTER_FUNCTIONS.find(e => e.name === operator) || {
        label: operator,
        unit: null,
        explainer: null,
        explainerFn: null,
        displayType: null
    };
    const opLabel = (opConfig.explainer || opConfig.label).toLowerCase();
    let formattedValueContent = '?';
    let isRegex;
    const fieldLabelWithFn = opConfig.explainerFn ? `${opConfig.explainerFn}(${fieldLabel})` : fieldLabel;

    const displayType: QueryOpFilterDisplayType = opConfig?.displayType || parentField?.filterDisplayType || 'text';

    if (displayType === 'select-company-user') {
        const valueContent = ruleValue;
        // Display loading message if the initial query is loading
        if (membersLoading || !company?.id) {
            return <FormattedMessage id="components.ruleset-builder.explainer.loading" />;
        }

        // Format value using user name if the operator is a select user one
        const member = companyMembers.find(e => e.id === valueContent?.value);
        formattedValueContent = member ? member.firstName : ((valueContent?.value || '?') as string);
    } else if (displayType === 'daterangepicker') {
        const valueContent = ruleValue?.value as DateRangeValue;
        const fromPickedData =
            pickableData && valueContent?.fromPickedCoordinateIndex != undefined
                ? pickableData[valueContent?.fromPickedCoordinateIndex].id
                : null;
        const toPickedData =
            pickableData && valueContent?.toPickedCoordinateIndex != undefined
                ? pickableData[valueContent?.toPickedCoordinateIndex].id
                : null;
        const fromDate = fromPickedData || valueContent?.fromDate || '?';
        const toDate = toPickedData || valueContent?.toDate || '?';
        const granularity = valueContent?.granularity || '?';
        formattedValueContent = `${granularity}(${fromDate}) and ${granularity}(${toDate})`;
    } else if (displayType === 'timeperiod') {
        const valueContent = ruleValue?.value as TimePeriodValue;
        const pickedData =
            pickableData && valueContent?.pickedCoordinateIndex != undefined
                ? pickableData[valueContent?.pickedCoordinateIndex].id
                : null;
        const units = pickedData ?? valueContent?.amount ?? '?';
        const timePeriod = valueContent?.timePeriod?.toLowerCase() || '?';
        formattedValueContent = `${pickedData ? pickedData : units} ${timePeriod}`;
    } else if (displayType === 'timeperiod-with-offset') {
        const valueContent = ruleValue?.value as TimePeriodValue;
        const pickedData =
            pickableData && valueContent?.pickedCoordinateIndex != undefined
                ? pickableData[valueContent?.pickedCoordinateIndex].id
                : null;
        const units = pickedData ?? valueContent?.amount ?? '?';
        const timePeriod = valueContent?.timePeriod?.toLowerCase() || '?';
        const offset = (valueContent?.offset as string)?.toLowerCase() || '?';
        formattedValueContent = `${units} ${timePeriod} ${offset}`;
    } else if (displayType == 'datepicker') {
        const valueContent = ruleValue?.value as DateValue;
        const pickedData =
            pickableData && valueContent?.pickedCoordinateIndex != undefined
                ? pickableData[valueContent?.pickedCoordinateIndex].id
                : null;
        const granularity = valueContent?.granularity || '?';
        const date = pickedData || valueContent?.value || '?';
        formattedValueContent = `${granularity}(${date})`;
    } else if (displayType === 'select') {
        const valueContent = ruleValue?.value as string;
        const pickedData =
            pickableData && ruleValue?.pickedCoordinateIndex != undefined
                ? pickableData[ruleValue?.pickedCoordinateIndex].id
                : null;
        formattedValueContent = pickedData || valueContent?.toLowerCase() || '?';
    } else if (displayType == 'number') {
        const pickedData =
            pickableData && ruleValue?.pickedCoordinateIndex != undefined
                ? pickableData[ruleValue?.pickedCoordinateIndex].id
                : null;
        formattedValueContent = pickedData ?? ((ruleValue?.value as string) || '?');
    } else if (displayType == 'text') {
        const pickedData =
            pickableData && ruleValue?.pickedCoordinateIndex != undefined
                ? pickableData[ruleValue?.pickedCoordinateIndex].id
                : null;
        formattedValueContent = pickedData ?? ((ruleValue?.value as string) || '?');
    } else if (displayType !== 'no-arg') {
        const valueContent = ruleValue?.value as string;
        isRegex = valueContent && ['MATCH', 'NOT_MATCH'].includes(operator);
        formattedValueContent = valueContent || '?';
    }

    return (
        <span>
            <span className="text-primary">{fieldLabelWithFn}</span> <span>{opLabel}</span>{' '}
            {displayType !== 'no-arg' && (
                <span className="text-secondary">
                    {isRegex && '/'}
                    {formattedValueContent}
                    {isRegex && '/'}
                </span>
            )}
        </span>
    );
};
