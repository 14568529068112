import { useMeasure } from 'react-use';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import {
    WidgetColumnChart,
    WidgetLineChart,
    WidgetPieChart,
    WidgetAreaChart,
    WidgetBarChart,
    WidgetHeatmapChart
} from 'components/Dashboarding/Models/Widget';
import { useFetchSeries } from 'components/Dashboarding/Hooks/useFetchSeries';
import { PickEvent, useHighchartsOptions } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    widget:
        | WidgetAreaChart
        | WidgetBarChart
        | WidgetLineChart
        | WidgetColumnChart
        | WidgetPieChart
        | WidgetHeatmapChart;
    onReloading: (reloading: boolean) => void;
    forcedMessageId?: string;
    onPick?: (event: PickEvent) => void;
    tooltipZIndex?: number;
}

const MetricChartWidget: FunctionComponent<Props> = ({
    widget,
    onReloading,
    forcedMessageId,
    onPick,
    tooltipZIndex
}: Props) => {
    // Reference to wrapper to detect changes of size
    const [chartWrapperRef, { width: chartWidth, height: chartHeight }] = useMeasure<HTMLDivElement>();

    // Get configuration
    const fact = widget.config.fact;
    const [series] = widget.config.series;

    // Perform query
    const { records, initialLoading, reloading } = useFetchSeries({
        fact,
        series
    });

    // Avoid unnecessary animated reflows while the height/width get discovered
    const isChartReady = useMemo(() => chartHeight > 0 && chartWidth > 0, [chartHeight, chartWidth]);

    // Highcharts options calculate
    const [highchartsOptions, fullChartRedraw] = useHighchartsOptions({
        chartType: widget.configType,
        records,
        series,
        showDataLabel: widget.config.formatting?.showDataLabel,
        options: {
            chart: { height: chartHeight, width: chartWidth },
            colors: widget.config.formatting?.baseColors,
            legend: { enabled: widget.config.formatting?.showChartLegend },
            xAxis: {
                title: {
                    text: widget.config.formatting?.xAxis?.show ? widget.config.formatting?.xAxis?.label : undefined
                }
            },
            yAxis: {
                title: {
                    text: widget.config.formatting?.yAxis?.show ? widget.config.formatting?.yAxis?.label : undefined
                }
            },
            tooltip: {
                style: {
                    zIndex: tooltipZIndex
                }
            }
        },
        onPick,
        forcedMessageId
    });

    // Update parent state when widget reloads
    useEffect(() => onReloading(reloading), [onReloading, reloading]);

    // Render chart
    return (
        <div ref={chartWrapperRef} className="d-flex h-100 w-100 justify-content-center align-items-center">
            {initialLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                // Absolute positioning ensures that the chart size is dictated by the parent, not by the content
                <div className="position-absolute">
                    {isChartReady && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={highchartsOptions}
                            immutable={fullChartRedraw}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default MetricChartWidget;
