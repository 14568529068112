import { faCrosshairs, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useCallback, useContext } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { truncate } from 'lodash';
import { Coordinates, DrilldownContext } from 'contexts/DrilldownContext';
import { RichMessage } from 'components/RichMessage';

const PICKED_DATA_MAX_LENGTH = 32;

// Format the picked value which is displayed in the drilldown dropdown menu
const formatPickedValue = (coordinates: Coordinates | undefined, index: number): string | undefined => {
    if (!coordinates || coordinates[index] == undefined) return;

    return truncate(coordinates[index] as string, {
        length: PICKED_DATA_MAX_LENGTH
    });
};

interface Props {
    drilldownEnabled: boolean;
    pickedCoordinateIndex: number | undefined;
    onChange: (id: number | null) => void;
    children: ReactNodeArray | ReactNode;
}

const PickedDataWrapper: FunctionComponent<Props> = ({
    drilldownEnabled,
    pickedCoordinateIndex,
    onChange,
    children
}) => {
    // Drilldown context
    const { coordinates, pickableData } = useContext(DrilldownContext);

    // Handle picked coordinate selection
    const handleSelect = useCallback(
        (e: string | null) => {
            // Force conversion of string integer to integer
            onChange(e == null ? null : +e);
        },
        [onChange]
    );

    if (!drilldownEnabled) return <>{children}</>;

    return (
        <div>
            <InputGroup className="transparent-addons flex-nowrap">
                {/* Input or chosen drilled value */}
                {pickedCoordinateIndex != undefined ? (
                    <div className="position-relative">
                        <Form.Control
                            className="text-truncate rule-value"
                            disabled
                            value={pickableData?.[pickedCoordinateIndex]?.id}
                        />

                        <FontAwesomeIcon
                            className="text-grey-4 position-absolute me-2 cursor-pointer"
                            icon={faTimes}
                            onClick={() => onChange(null)}
                            style={{ top: 14, right: 0 }}
                        />
                    </div>
                ) : (
                    children
                )}

                {/* Dropdown for drilled values */}
                <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                        split
                        variant="outline-dark"
                        role="button"
                        className="py-0 drilldown-dropdown-toggle"
                    >
                        <FontAwesomeIcon icon={faCrosshairs} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                        {pickableData?.map((v, index) => {
                            const formattedValue = formatPickedValue(coordinates, index);
                            return (
                                <Dropdown.Item className="py-3" key={v.id} eventKey={index}>
                                    <div className="fs-sm">
                                        <RichMessage id="dashboarding.widget-editor.configure-drilldown.drilldown-dropdown.item-title" />
                                        &nbsp;{v.type} {v.index}
                                    </div>
                                    <div>
                                        {v.label}&nbsp;
                                        <span className="text-grey-4">
                                            (
                                            {formattedValue || (
                                                <RichMessage id="dashboarding.widget-editor.configure-drilldown.drilldown-dropdown.no-picked-value" />
                                            )}
                                            )
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>
        </div>
    );
};

export default PickedDataWrapper;
