import gql from 'graphql-tag';
import { MutationResp } from './Shared';

//============================================
// Types
//============================================
export const RESOURCE_TYPES = ['DASHBOARD', 'WIDGET'] as const;
export type ResourceType = typeof RESOURCE_TYPES[number];

export const RESOURCE_ROLES = ['OWNER', 'EDITOR', 'VIEWER'] as const;
export type ResourceRoleName = typeof RESOURCE_ROLES[number];
export const RESOURCE_ROLES_WITH_EDIT = ['OWNER', 'EDITOR'];

export const ACTOR_TYPES = ['USER', 'COMPANY'] as const;
export type ActorType = typeof ACTOR_TYPES[number];

//============================================
// Interfaces
//============================================
export interface ResourceRole {
    id: string;
    inheritedFromParent?: boolean | null;
    actorId: string;
    actorType: string;
    resourceId: string;
    resourceType: ResourceType;
    name: ResourceRoleName;
}

//============================================
// Queries
//============================================

export const RESOLVED_ROLE_ATTRIBUTES = gql`
    fragment resolvedRoleAttributes on ResolvedRole {
        id
        resourceId
        resourceType
        actorId
        actorType
        inheritedFrom {
            resourceId
            resourceType
            actorId
            actorType
            name
        }
        name
    }
`;

//============================================
// Fetch user roles for a resource
//============================================
export interface GetUserRoleForResourceResp {
    resource: {
        role?: ResourceRole;
    };
}

export const GET_USER_ROLES_FOR_DASHBOARD = gql`
    query getUserRolesForDashboard($resourceId: ID!) {
        resource: dashboard(id: $resourceId) {
            id
            role: currentUserRole {
                id
                actorId
                actorType
                resourceId
                resourceType
                name
            }
        }
    }
`;

export const GET_USER_ROLES_FOR_WIDGET = gql`
    query getUserRolesForWidget($resourceId: ID!) {
        resource: widget(id: $resourceId) {
            id
            role: currentUserRole {
                id
                actorId
                actorType
                resourceId
                resourceType
                name
            }
        }
    }
`;

//============================================
// Fetch collection
//============================================
export interface GetResourceRolesResp {
    resource: {
        roles: ResourceRole[];
    };
}

export const GET_DASHBOARD_RESOLVED_ROLES = gql`
    query getDashboardResolvedRoles($resourceId: ID!) {
        resource: dashboard(id: $resourceId) {
            id
            roles: resolvedRoles {
                id
                actorId
                actorType
                resourceId
                resourceType
                name
            }
        }
    }
`;

export const GET_WIDGET_RESOLVED_ROLES = gql`
    query getWidgetResolvedRoles($resourceId: ID!) {
        resource: widget(id: $resourceId) {
            id
            roles: resolvedRoles {
                id
                actorId
                actorType
                resourceId
                resourceType
                name
            }
        }
    }
`;

//============================================
// Mutations
//============================================
export interface SetResourceRoleResp {
    setRole: MutationResp;
}

export const SET_ROLE_ATTRIBUTES = ['actorId', 'actorType', 'name', 'resourceId', 'resourceType'];
export const SET_RESOURCE_ROLE = gql`
    mutation setRole($input: SetRoleInput!) {
        setRole(input: $input) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

//============================================
// Subscriptions
//============================================
export interface ResourceRoleUpsertedSubscription {
    role?: ResourceRole;
}

export interface ResourceRoleDeletedSubscription {
    role?: {
        id: string;
    };
}

export const SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED = gql`
    subscription($companyId: ID!) {
        role: roleUpdated(companyId: $companyId) {
            id
            actorId
            actorType
            resourceId
            resourceType
            name
        }
    }
`;

export const SUBSCRIBE_TO_RESOURCE_ROLE_CREATED = gql`
    subscription($companyId: ID!) {
        role: roleCreated(companyId: $companyId) {
            id
            actorId
            actorType
            resourceId
            resourceType
            name
        }
    }
`;

export const SUBSCRIBE_TO_RESOURCE_ROLE_DELETED = gql`
    subscription($companyId: ID!) {
        role: roleDeleted(companyId: $companyId) {
            id
        }
    }
`;
