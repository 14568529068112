import gql from 'graphql-tag';

export interface AppResp {
    id: string;
    category: string;
    name: string;
    provider: string;
    stage: string;
}

export interface GetAppsResp {
    apps: {
        nodes: AppResp[];
    };
}

export const GET_APPS = gql`
    query($stages: [AppStageEnum!]) {
        apps(stageIn: $stages) {
            nodes {
                id
                name
                provider
                category
                stage
            }
        }
    }
`;

export interface GetAppResp {
    app: AppResp;
}

export const GET_APP_BY_PROVIDER = gql`
    query($provider: ID!) {
        app(provider: $provider) {
            id
            name
            provider
            category
            stage
        }
    }
`;
