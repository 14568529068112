import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CreateCompanyResp, CREATE_COMPANY } from 'api/hq/queries/Company';
import { useSafeState } from 'util/useSafeState';
import { LOGIN_USER_SUCCESS } from 'constants/actionTypes';
import { GetCurrentApiUserResp, GET_CURRENT_API_USER } from 'api/hq/queries/CurrentApiUser';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FullPageLoader from 'components/Design/FullPageLoader';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import keypupPreviewImg from 'assets/img/onboarding/keypup-preview.png';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { toastQueryError } from 'components/Toast';

// Where to go after this step
const NEXT_STEP_PATH = '/flows/onboarding/whats-next';

const Step0aCompanyCreation: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();
    const intl = useRichIntl();

    // Redux event dispatcher
    const dispatch = useDispatch();

    // State
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [createInProgress, setCreateInProgress] = useSafeState<boolean>(false);

    // Retrieve current user
    const { data: userData, loading: userLoading, refetch: userRefetch } = useQuery<GetCurrentApiUserResp>(
        GET_CURRENT_API_USER
    );

    // Declare company create mutation
    const [createCompanyQuery] = useMutation<CreateCompanyResp>(CREATE_COMPANY);

    // Extract User information
    const user = userData?.currentApiUser.user;

    // Check form validity
    const isCompanyNameValid = useCallback(() => !!companyName && companyName.length > 0, [companyName]);

    // Handle company creation
    const createCompany = useCallback(async () => {
        setCreateInProgress(true);

        try {
            const resp = await createCompanyQuery({ variables: { name: companyName } });
            const companyResp = resp.data && resp.data.createCompany;

            if (companyResp?.success) {
                const userResp = await userRefetch();
                const apiUser = userResp.data.currentApiUser;
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: { ...apiUser, currentCompanyId: companyResp.company.id }
                });

                // Redirect to project list screen
                navigate(NEXT_STEP_PATH, { replace: true });
            } else {
                toastQueryError({ ...companyResp?.errors[0], namespace: 'create-company' });
            }
        } catch {
            toastQueryError({ namespace: 'create-company' });
        } finally {
            // Stop loading state
            setCreateInProgress(false);
        }
    }, [companyName, createCompanyQuery, dispatch, navigate, setCreateInProgress, userRefetch]);

    // Skip step if user has a company already
    useEffect(() => {
        if ((userData?.currentApiUser.user.memberships.nodes || []).length > 0) {
            // Redirect to project list screen
            navigate(NEXT_STEP_PATH, { replace: true });
        }
    }, [navigate, userData]);

    // Display loading screen if the initial query is loading
    if (userLoading || createInProgress) return <FullPageLoader />;

    // User is not logged in. Auth page will be displayed as a result of AuthGuard.
    if (!user) return null;

    // Render
    return (
        <LayoutSplitScreen leftSectionStyle={{ maxWidth: 440 }} leftSectionClassName="justify-content-center">
            {/* Left section */}
            {userLoading || createInProgress ? (
                <>
                    {/* Display loading screen if the initial query is loading */}
                    <FullPageLoader />
                </>
            ) : !user ? (
                <>{/* User is not logged in. Auth page will be displayed as a result of AuthGuard. */}</>
            ) : (
                <>
                    {/* Logo */}
                    <KeypupLogoFull className="mb-5" width={171} height={48} />

                    {/* Title */}
                    <h1 className="fw-bolder mb-3">
                        <RichMessage id="flows.onboarding.company-creation.title" />
                    </h1>

                    {/* Subtitle */}
                    <h4 className="text-primary mb-5">
                        <FormattedMessage id="flows.onboarding.company-creation.subtitle" />
                    </h4>

                    {/* Form */}
                    <Form onSubmit={createCompany} className="w-100 mb-5 mx-auto">
                        <Form.Group>
                            {/* Label */}
                            <Form.Label>
                                <RichMessage id="flows.onboarding.company-creation.form.teamname" />
                            </Form.Label>

                            {/* Input */}
                            <Form.Control
                                placeholder={intl.formatMessage({
                                    id: 'flows.onboarding.company-creation.form.teamname.placeholder'
                                })}
                                isValid={isCompanyNameValid()}
                                onChange={e => setCompanyName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>

                    {/* Next button */}
                    <Button
                        track-id="onboarding/click-create-company"
                        variant="dark"
                        className="w-100 justify-content-center"
                        onClick={createCompany}
                        disabled={!isCompanyNameValid()}
                    >
                        <FormattedMessage id="flows.onboarding.company-creation.form.next" />
                    </Button>
                </>
            )}

            {/* Right section */}
            <img className="vh-65" style={{ marginLeft: '30%' }} src={keypupPreviewImg} />
        </LayoutSplitScreen>
    );
};

export default Step0aCompanyCreation;
