import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cleanupTemplateMarkup, useTemplateAssistant } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import FullPageLoader from 'components/Design/FullPageLoader';
import { Questions } from 'components/Questions';
import { RichMessage } from 'components/RichMessage';
import { ASSISTANT_WIDGET_QNS_KEY, ASSISTANT_WIDGET_TMPL_KEY } from 'constants/localStorageKeys';
import { widgetAssistantStepPath, WIDGET_ASSISTANT_STEP2, WIDGET_TMPL_ID_KEY } from 'constants/routeBuilders';
import { omit } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { useTitleLabel } from 'util/useTitleLabel';
import Header from './Header';
import WidgetPreview from './WidgetPreview';

export const Step1Configure: FunctionComponent = () => {
    // Page title
    useTitleLabel('dashboarding.widget-assistant.title');

    // State
    const [, setAssistantWidgetTemplate, clearAssistantWidgetTemplate] = useLocalStorage<WidgetFactType>(
        ASSISTANT_WIDGET_TMPL_KEY
    );

    // Services
    const navigate = useNavigate();

    // Extract params
    const params = useParams() as { dashboardId: string };
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(WIDGET_TMPL_ID_KEY);
    const dashboardId = params.dashboardId;

    // Template customizer hook
    const { loading, resultTemplate, questions, updateQuestion, resetQuestions, clearQuestions } = useTemplateAssistant(
        ASSISTANT_WIDGET_QNS_KEY,
        templateId,
        'WIDGET',
        2
    );

    // Formatted template
    const formattedTemplate = useMemo(() => {
        // Abort if no result template
        if (!resultTemplate) return;

        // Template cleanup
        const formattedTemplate = omit(
            cleanupTemplateMarkup(resultTemplate as WidgetFactType),
            'imgDataUrl'
        ) as WidgetFactType;

        // Put the template in local storage
        setAssistantWidgetTemplate(formattedTemplate);

        return formattedTemplate;
    }, [resultTemplate, setAssistantWidgetTemplate]);

    // Invalid form
    const isFormInvalid = useMemo(() => !questions || Array.from(questions.values()).some(e => !e.isValid), [
        questions
    ]);

    // Hook invoked on back click
    const handleBackClick = useCallback(() => {
        // Clear widget from local storage
        clearAssistantWidgetTemplate();

        // Clear questions from local storage
        clearQuestions();

        // Navigate to previous history stack
        navigate(-1);
    }, [clearAssistantWidgetTemplate, clearQuestions, navigate]);

    // Hook invoked user clicks the `Next` button
    const handleNextClick = useCallback(() => {
        // Redirect to `document` step
        navigate(widgetAssistantStepPath(WIDGET_ASSISTANT_STEP2, templateId, dashboardId));
    }, [dashboardId, navigate, templateId]);

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            {loading ? (
                <>
                    {/* Display loading screen if questions are still loading */}
                    <FullPageLoader />
                </>
            ) : !questions || Array.from(questions.keys()).length == 0 ? (
                <>
                    {/* There are no questions to answer, navigate to document step */}
                    <Navigate to={widgetAssistantStepPath(WIDGET_ASSISTANT_STEP2, templateId, dashboardId)} />
                </>
            ) : (
                <>
                    <div className="flex-grow-1">
                        {/* Title */}
                        <div className="d-flex align-items-baseline mt-4 mb-5">
                            <Button onClick={handleBackClick} variant="link" className="p-0">
                                <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                            </Button>
                            <h1 className="fw-bolder">
                                <RichMessage id="dashboarding.widget-assistant.configure.title" />
                            </h1>
                        </div>

                        {/* Questions */}
                        <div className="mb-5">
                            <Questions
                                questions={questions}
                                updateQuestion={updateQuestion}
                                resetQuestions={resetQuestions}
                            />
                        </div>
                    </div>

                    {/* Next button */}
                    <div className="flex-grow-0">
                        <Button
                            className="justify-content-center w-100 mb-4"
                            variant="dark"
                            onClick={handleNextClick}
                            disabled={isFormInvalid}
                        >
                            <RichMessage id="dashboarding.widget-assistant.configure.next" />
                        </Button>
                    </div>
                </>
            )}

            {/* Right section */}
            {formattedTemplate && (
                <WidgetPreview
                    widget={formattedTemplate}
                    forcedMessageId={
                        isFormInvalid ? 'dashboarding.widget-assistant.widget-preview.invalid-form' : undefined
                    }
                />
            )}
        </LayoutSplitScreen>
    );
};
