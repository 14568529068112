import {
    DEFAULT_MARKDOWN_TEMPLATE,
    DEFAULT_SEPARATOR_TEMPLATES,
    WIDGET_BLANK_TEMPLATES
} from 'components/Dashboarding/Models/WidgetTemplates';
import {
    COLLECTIONS_ROOT_PATH,
    dashboardWidgetNewPath,
    DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM,
    DASHBOARD_EDIT_MODE_PARAM,
    REDIRECT_TO_PARAM
} from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useState, useEffect, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import DashboardGrid from './DashboardGrid';
import IconPicker, { IconPickerConfig } from 'components/Design/ColorPickers/IconPicker';
import {
    DashboardWithLayout,
    DeleteDashboardResp,
    DELETE_DASHBOARD,
    UpdateDashboardResp,
    UPDATE_DASHBOARD,
    DASHBOARD_UPDATE_ATTRS
} from 'api/hq/queries/Dashboard';
import { RichMessage } from 'components/RichMessage';
import TransparentInput from 'components/Design/TransparentInput';
import { ErrorFragment } from 'components/ErrorManagement';
import { useSafeState } from 'util/useSafeState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useMutation } from '@apollo/react-hooks';
import { useDashboard } from 'api/hq/hooks/useDashboard';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faEdit, faSave } from '@fortawesome/free-regular-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { faAlignJustify, faBan, faPlus, faShareAlt, faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTitle } from 'react-use';
import useAddNewWidgetToDashboard, { AddNewWidgetType } from 'components/Dashboarding/Hooks/useAddNewWidgetToDashboard';
import { WidgetSeparatorConfigType } from 'components/Dashboarding/Models/Widget';
import FullPageLoader from 'components/Design/FullPageLoader';
import { kpHDivider, kpNewCards, kpNewChart, kpNewKpi, kpPin, kpUnpin, kpVDivider } from 'util/customIcons';
import DynamicWidthTransparentInput from 'components/Design/DynamicWidthTransparentInput';
import { ShareDashboardModal } from 'components/Dashboarding/Sharing/ShareDashboardModal';
import { useUserRoleForResource } from 'api/hq/hooks/useUserRoleForResource';
import DuplicateDashboardModal from 'components/Dashboarding/Duplicating/DuplicateDashboardModal';
import { toastQueryError } from 'components/Toast';
import { Form } from 'react-bootstrap';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { SECONDARY_RED } from 'constants/colors';
import { useDashboards } from 'api/hq/hooks/useDashboards';
import { useResourcePin } from 'api/hq/hooks/useResourcePin';
import { pick } from 'lodash';
import AddWidgetModal from './AddWidgetModal/AddWidgetModal';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

// Wrapper component to view and edit dashboards
const DashboardView: FunctionComponent = () => {
    // Services
    const params = useParams() as { dashboardId: string };

    // Search params
    const [searchParams, setSearchParams] = useSearchParams();
    const editMode = useMemo(
        () =>
            searchParams?.get(DASHBOARD_EDIT_MODE_PARAM)
                ? searchParams?.get(DASHBOARD_EDIT_MODE_PARAM) == 'true'
                : false,
        [searchParams]
    );
    const addWidgetModalOpenSearchParam = useMemo(
        () =>
            searchParams?.get(DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM)
                ? searchParams?.get(DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM) == 'true'
                : false,
        [searchParams]
    );
    const redirectTo = useMemo(() => searchParams?.get(REDIRECT_TO_PARAM), [searchParams]);

    // Navigate hook
    const navigate = useNavigate();

    // State
    const [isEditing, setIsEditing] = useState<boolean>(editMode);
    const [actionInProgress, setActionInProgress] = useSafeState<boolean>(false);
    const [savedDashboard, setSavedDashboard] = useState<DashboardWithLayout | undefined>(undefined);
    const [editedDashboard, setEditedDashboard] = useState<DashboardWithLayout | undefined>(undefined);
    const [isAddWidgetModalOpen, setIsAddWidgetModalOpen] = useState<boolean>(addWidgetModalOpenSearchParam);
    const [isShareDashboardModalOpen, setIsShareDashboardModalOpen] = useState<boolean>(false);
    const [isDuplicateDashboardModalOpen, setIsDuplicateDashboardModalOpen] = useState<boolean>(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // User roles
    const { canView, canEdit, loading: loadingUserRole } = useUserRoleForResource({
        resourceId: params.dashboardId,
        resourceType: 'DASHBOARD'
    });

    // Pin
    const { refetch: refetchDashboards } = useDashboards({ pinned: true });

    // Mutation
    const [updateDashboard] = useMutation<UpdateDashboardResp>(UPDATE_DASHBOARD);

    // Route params
    const dashboardId = params.dashboardId;
    const addChartWidgetPath = useMemo(() => {
        const template = WIDGET_BLANK_TEMPLATES['LINECHART'];

        return dashboardWidgetNewPath({
            dashboardId,
            template: { ...template, templateSource: template.id, actionSource: 'manual' }
        });
    }, [dashboardId]);
    const addKpiWidgetPath = useMemo(() => {
        const template = WIDGET_BLANK_TEMPLATES['SPARKLINEKPI'];

        return dashboardWidgetNewPath({
            dashboardId,
            template: { ...template, templateSource: template.id, actionSource: 'manual' }
        });
    }, [dashboardId]);
    const addCardsWidgetPath = useMemo(() => {
        const template = WIDGET_BLANK_TEMPLATES['LARGECARD'];

        return dashboardWidgetNewPath({
            dashboardId,
            template: { ...template, templateSource: template.id, actionSource: 'manual' }
        });
    }, [dashboardId]);
    const addListWidgetPath = useMemo(() => {
        const template = WIDGET_BLANK_TEMPLATES['LIST'];

        return dashboardWidgetNewPath({
            dashboardId,
            template: { ...template, templateSource: template.id, actionSource: 'manual' }
        });
    }, [dashboardId]);

    // Load dashboard
    const { normalized: dashboard, loading: dashboardLoading, refetchNormalized: refetchDashboard } = useDashboard({
        id: dashboardId
    });

    const addWidgetToDashboard = useAddNewWidgetToDashboard({ dashboard: savedDashboard || dashboard });

    // Set page title
    useTitle(dashboard?.name || '-');

    // Pinning / unpinning
    const onSuccess = useCallback(() => {
        refetchDashboard();
        refetchDashboards();
    }, [refetchDashboard, refetchDashboards]);

    const { loading: pinLoading, setPin } = useResourcePin({
        resourceType: 'DASHBOARD',
        resourceId: dashboard?.id,
        onSuccess
    });

    const addWidget = async (widget: AddNewWidgetType): Promise<void> => {
        // Abort if no dashboard
        if (!dashboard) throw new Error('dashboard is not provided');

        // Set state
        setActionInProgress(true);

        // Add widget to the dashboard
        const payload = await addWidgetToDashboard({ widget });

        if (payload?.success && payload.dashboard?.layout) {
            const editedDashboard = { ...dashboard, layout: payload.dashboard.layout };
            setEditedDashboard(editedDashboard);

            // Commit saved version
            setSavedDashboard(editedDashboard);
            setIsEditing(true);
        } else {
            toastQueryError({ ...payload.resp?.errors[0], namespace: 'save-dashboard' });
        }
        setActionInProgress(false);
    };

    const addMarkdownWidget = async (): Promise<void> => {
        // Set widget template
        const template = DEFAULT_MARKDOWN_TEMPLATE;

        // Add markdown widget
        await addWidget({ ...template, templateSource: template.id, actionSource: 'manual' });
    };

    const addSeparatorWidget = async (configType: WidgetSeparatorConfigType): Promise<void> => {
        // Set widget params
        const template = DEFAULT_SEPARATOR_TEMPLATES[configType];

        // Add separator widget
        await addWidget({ ...template, templateSource: template.id, actionSource: 'manual' });
    };

    // Fill initial state
    useEffect(() => {
        if (dashboardLoading || !dashboard) return;

        // Load initial state if the viewed dashboard has changed
        if (savedDashboard?.id != dashboard.id) {
            setSavedDashboard(dashboard);
            setEditedDashboard(dashboard);
        }

        return () => {
            // This line of code to fix when the duplication succeed and navigates to the new dashboard
            // The modal is still be opened
            setIsDuplicateDashboardModalOpen(false);
        };
    }, [dashboard, dashboardLoading, editedDashboard, savedDashboard]);

    // Hook invoked when the dashboard gets changed
    const onIconChange = useCallback(
        (icon: IconPickerConfig) => {
            editedDashboard && setEditedDashboard({ ...editedDashboard, icon });
        },
        [editedDashboard]
    );

    // Hook invoked when the grid is re-arranged
    const onGridChange = useCallback(
        (newLayout: Layout[]) => {
            setIsEditing(true);
            editedDashboard && setEditedDashboard({ ...editedDashboard, layout: newLayout });
        },
        [editedDashboard]
    );

    // Revert layout to pre-edit version
    const cancelEdit = useCallback((): void => {
        setIsEditing(false);
        setEditedDashboard(savedDashboard);

        // If needed, redirect
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, [savedDashboard, redirectTo, navigate]);

    // Dashboard save function
    const saveDashboard = useCallback(async () => {
        if (!editedDashboard || !company) return;

        // Set state
        setActionInProgress(true);

        try {
            const resp = (
                await updateDashboard({ variables: { input: pick(editedDashboard, DASHBOARD_UPDATE_ATTRS) } })
            ).data?.updateDashboard;

            if (resp?.success) {
                // Commit saved version
                setSavedDashboard(editedDashboard);

                // Back to view mode
                setIsEditing(false);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'save-dashboard' });
            }
        } catch {
            toastQueryError({ namespace: 'save-dashboard' });
        } finally {
            // Stop loading state
            setActionInProgress(false);

            // If needed, redirect
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
    }, [editedDashboard, company, setActionInProgress, updateDashboard, navigate, redirectTo]);

    const [cleanupOrphanWidgets, setCleanupOrphanWidgets] = useState(false);
    const [deletionInProgress, setDeletionInProgress] = useState(false);
    const [deleteDashboard] = useMutation<DeleteDashboardResp>(DELETE_DASHBOARD);

    // Delete dashboard
    const removeDashboard = useCallback(async () => {
        try {
            setDeletionInProgress(true);

            // Delete the dashboard
            const resp = (
                await deleteDashboard({
                    variables: { id: params.dashboardId, cleanupOrphanWidgets: cleanupOrphanWidgets }
                })
            ).data?.deleteDashboard;

            if (resp?.success) {
                navigate(COLLECTIONS_ROOT_PATH);
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'delete-dashboard' });
            }
        } catch (error) {
            toastQueryError({ namespace: 'delete-dashboard' });
        } finally {
            setIsDeleteConfirmationModalOpen(false);
            setDeletionInProgress(false);
        }
    }, [deleteDashboard, params.dashboardId, cleanupOrphanWidgets, navigate]);

    // Deletion confirmation modal content
    const deleteModalContent = useMemo(() => {
        return (
            <>
                <RichMessage id="collections.confirmation-modal.delete-dashboard.content" />
                <Form.Check
                    className="mt-4 fs-xs d-flex align-items-center"
                    type="checkbox"
                    label={
                        <span className="fs-6 ms-2">
                            <RichMessage id="collections.confirmation-modal.delete-dashboard.delete-dashboards-widgets" />
                        </span>
                    }
                    onClick={() => setCleanupOrphanWidgets(!cleanupOrphanWidgets)}
                />
            </>
        );
    }, [cleanupOrphanWidgets]);

    // Deletion confirmation modal confirm button
    const deleteModalConfirmButton = useMemo(() => {
        return (
            <AsyncButton
                variant="danger"
                onClick={removeDashboard}
                disabled={deletionInProgress}
                className="w-100 justify-content-center"
                loading={deletionInProgress}
                messageProps={{ id: 'collections.confirmation-modal.delete-dashboard.confirm-button' }}
                loadingMessageProps={{ id: 'collections.confirmation-modal.delete-dashboard.deletion-in-progress' }}
            />
        );
    }, [deletionInProgress, removeDashboard]);

    // Hook invoked when a widget has been added from the library modal
    const onWidgetAdded = useCallback(async () => {
        // Refetch the dashboard
        const dashboard = await refetchDashboard();

        // Set the saved dashboard state
        setSavedDashboard(dashboard);

        // If not in edit mode, set the edited dashboard state
        if (!editMode) setEditedDashboard(dashboard);
    }, [editMode, refetchDashboard]);

    // Hook invoked when opening/closing the add widget modal
    const updateAddWidgetModalState = useCallback(
        (isOpen: boolean) => {
            // Update the state
            setIsAddWidgetModalOpen(isOpen);

            // Create new search params from the current ones
            const newParams = new URLSearchParams(searchParams);

            // Overwrite the `add-widget-modal-open` search param
            if (isOpen) {
                newParams.set(DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM, 'true');
            } else {
                newParams.delete(DASHBOARD_ADD_WIDGET_MODAL_OPEN_PARAM);
            }
            // Set search params state
            setSearchParams(newParams);
        },
        [searchParams, setSearchParams]
    );

    // Abort if dashboard not loaded or an action is in progress
    if (dashboardLoading || loadingUserRole || deletionInProgress) {
        return <FullPageLoader />;
    }

    // Display error message if the dashboard does not exist
    // or the user does not have access to it
    if (!editedDashboard || !canView) {
        return (
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <ErrorFragment error={{ namespace: 'dashboard-view', code: 'no-access' }} icon={faBan} />
            </div>
        );
    }

    // Render
    return (
        <div>
            {/* Action bar */}
            <div className="d-flex align-items-center shadow-sm bg-white px-4 py-3">
                {/* Icon */}
                <IconPicker
                    icon={editedDashboard?.icon}
                    editable={isEditing}
                    onChange={onIconChange}
                    className="me-3"
                />

                {/* Dashboard name */}
                <div className="flex-grow-1">
                    <div className="d-flex flex-column">
                        {isEditing && (
                            <>
                                {/* Name */}
                                <DynamicWidthTransparentInput
                                    value={editedDashboard.name}
                                    onCommit={val => setEditedDashboard({ ...editedDashboard, name: val })}
                                    placeholderId="dashboarding.dashboard-editor.dashboard-name-placeholder"
                                    disabled={actionInProgress}
                                    className="transparent-framed-input framed-input h4 px-2"
                                />

                                {/* Description */}
                                <DynamicWidthTransparentInput
                                    value={editedDashboard.description || ''}
                                    onCommit={val => setEditedDashboard({ ...editedDashboard, description: val })}
                                    placeholderId="dashboarding.dashboard-editor.dashboard-description-placeholder"
                                    variant="grey-1"
                                    disabled={actionInProgress}
                                    className="transparent-framed-input framed-input px-2"
                                />
                            </>
                        )}
                        {!isEditing && (
                            <>
                                {/* Name */}
                                <TransparentInput
                                    value={editedDashboard.name}
                                    placeholderId="dashboarding.dashboard-editor.dashboard-name-placeholder"
                                    disabled={true}
                                    className="transparent-framed-input w-100 h4 px-2"
                                />

                                {/* Description */}
                                <TransparentInput
                                    value={editedDashboard.description || ''}
                                    placeholderId="dashboarding.dashboard-editor.dashboard-description-placeholder"
                                    disabled={true}
                                    variant="grey-1"
                                    className="transparent-framed-input w-100 px-2"
                                />
                            </>
                        )}
                    </div>
                </div>

                {/* Action buttons */}
                <div>
                    <div className="d-flex align-items-center ">
                        {isEditing ? (
                            <>
                                <Button
                                    track-id="dashboard/click-cancel-edit-mode"
                                    variant="link"
                                    onClick={cancelEdit}
                                    disabled={actionInProgress}
                                >
                                    <RichMessage id="dashboarding.dashboard-editor.cancel-dashboard" />
                                </Button>
                                <Button
                                    track-id="dashboard/click-save-edit-mode"
                                    variant="dark"
                                    onClick={saveDashboard}
                                    className="ms-2"
                                    disabled={actionInProgress}
                                >
                                    {actionInProgress ? (
                                        <Spinner animation="border" size="sm" variant="white" className="me-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                    )}
                                    <RichMessage id="dashboarding.dashboard-editor.save-dashboard" />
                                </Button>
                            </>
                        ) : loadingUserRole ? (
                            <Spinner animation="border" size="sm" style={{ height: '1.325rem', width: '1.325rem' }} />
                        ) : (
                            <>
                                {/* for all users */}
                                {dashboard && (
                                    <Button
                                        track-id={`dashboard/click-${dashboard.pinned ? 'unpin' : 'pin'}`}
                                        variant="outline-dark"
                                        className="me-2 d-none d-md-flex"
                                        onClick={() => !pinLoading && setPin(!dashboard.pinned)}
                                    >
                                        {pinLoading ? (
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                style={{ height: '1.325rem', width: '1.325rem' }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon icon={dashboard.pinned ? kpUnpin : kpPin} />
                                        )}
                                    </Button>
                                )}

                                {/* for viewers only */}
                                {canView && !canEdit && (
                                    <Button
                                        track-id="dashboard/click-open-duplicate-modal"
                                        variant="dark"
                                        onClick={() => setIsDuplicateDashboardModalOpen(true)}
                                        className="d-flex"
                                    >
                                        <FontAwesomeIcon icon={faClone} className="me-2" />
                                        <RichMessage id="dashboarding.dashboard-editor.duplicate-dashboard" />
                                    </Button>
                                )}

                                {/* for editors */}
                                {canEdit && (
                                    <>
                                        {/* Share dashboard */}
                                        <Button
                                            track-id="dashboard/click-open-share-modal"
                                            variant="outline-dark"
                                            onClick={() => setIsShareDashboardModalOpen(true)}
                                            className="me-2 d-none d-md-flex"
                                        >
                                            <FontAwesomeIcon icon={faShareAlt} className="me-2" />
                                            <RichMessage id="dashboarding.dashboard-editor.share-dashboard" />
                                        </Button>

                                        {/* Edit dashboard */}
                                        <div className="me-2 d-none d-md-block">
                                            <Dropdown as={ButtonGroup}>
                                                <Button
                                                    track-id="dashboard/click-start-edit-mode"
                                                    variant="dark"
                                                    onClick={() => setIsEditing(true)}
                                                    className="d-flex"
                                                >
                                                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.edit-dashboard" />
                                                </Button>
                                                <Dropdown.Toggle split variant="twilight" />

                                                <Dropdown.Menu
                                                    className="add-insight-menu"
                                                    variant="dark"
                                                    align={'end'}
                                                    // Fixed strategy is required to avoid issues with container overflow
                                                    popperConfig={{ strategy: 'fixed' }}
                                                    // Fixed strategy is bugged. Need renderOnMount to work properly
                                                    // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                                                    renderOnMount
                                                >
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-duplicate-modal"
                                                        as={Button}
                                                        onClick={() => setIsDuplicateDashboardModalOpen(true)}
                                                    >
                                                        <FontAwesomeIcon icon={faClone} />
                                                        <RichMessage id="dashboarding.dashboard-editor.duplicate-dashboard" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-deletion-modal"
                                                        as={Button}
                                                        onClick={() => setIsDeleteConfirmationModalOpen(true)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                        <RichMessage id="dashboarding.dashboard-editor.delete-dashboard" />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                        {/* Add widget */}
                                        <div className="d-none d-md-block">
                                            <Dropdown as={ButtonGroup}>
                                                {/* See: https://github.com/react-bootstrap/react-bootstrap/issues/6103 */}
                                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                                <Button
                                                    track-id="dashboard/click-open-add-insight-modal"
                                                    variant="dark"
                                                    onClick={() => updateAddWidgetModalState(true)}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                    <RichMessage id="dashboarding.dashboard-editor.add-insight" />
                                                </Button>

                                                <Dropdown.Toggle split variant="twilight" />

                                                <Dropdown.Menu
                                                    className="add-insight-menu"
                                                    variant="dark"
                                                    align={'end'}
                                                    // Fixed strategy is required to avoid issues with container overflow
                                                    popperConfig={{ strategy: 'fixed' }}
                                                    // Fixed strategy is bugged. Need renderOnMount to work properly
                                                    // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                                                    renderOnMount
                                                >
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-insight-builder-with-new-cards"
                                                        as={Link}
                                                        to={addCardsWidgetPath}
                                                    >
                                                        <FontAwesomeIcon icon={kpNewCards} />
                                                        <RichMessage id="dashboarding.dashboard-editor.new-cards" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-insight-builder-with-new-chart"
                                                        as={Link}
                                                        to={addChartWidgetPath}
                                                    >
                                                        <FontAwesomeIcon icon={kpNewChart} />
                                                        <RichMessage id="dashboarding.dashboard-editor.new-chart" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-insight-builder-with-new-kpi"
                                                        as={Link}
                                                        to={addKpiWidgetPath}
                                                    >
                                                        <FontAwesomeIcon icon={kpNewKpi} size="sm" />
                                                        <RichMessage id="dashboarding.dashboard-editor.new-kpi" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-open-insight-builder-with-report"
                                                        as={Link}
                                                        to={addListWidgetPath}
                                                    >
                                                        <FontAwesomeIcon icon={faTable} />
                                                        <RichMessage id="dashboarding.dashboard-editor.new-report" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-add-widget-markdown"
                                                        as={Button}
                                                        onClick={addMarkdownWidget}
                                                    >
                                                        <FontAwesomeIcon icon={faAlignJustify} />
                                                        <RichMessage id="dashboarding.dashboard-editor.new-markdown" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-add-widget-h-separator"
                                                        as={Button}
                                                        onClick={() => addSeparatorWidget('HSEPARATOR')}
                                                    >
                                                        <FontAwesomeIcon icon={kpHDivider} />
                                                        <RichMessage id="dashboarding.dashboard-editor.add-h-separator" />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        track-id="dashboard/click-add-widget-v-separator"
                                                        as={Button}
                                                        onClick={() => addSeparatorWidget('VSEPARATOR')}
                                                    >
                                                        <FontAwesomeIcon icon={kpVDivider} />
                                                        <RichMessage id="dashboarding.dashboard-editor.add-v-separator" />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Display grid */}
            <DashboardGrid
                dashboardId={dashboardId}
                isEditing={isEditing && !actionInProgress}
                canEdit={canEdit}
                layout={editedDashboard.layout}
                onChange={onGridChange}
            />

            {/* Widget Library Modal */}
            {isAddWidgetModalOpen && (
                <AddWidgetModal
                    dashboardId={dashboardId}
                    isOpen={isAddWidgetModalOpen}
                    onHide={() => updateAddWidgetModalState(false)}
                    onWidgetAdded={onWidgetAdded}
                />
            )}

            {/* Share Dashboard Modal */}
            {isShareDashboardModalOpen && (
                <ShareDashboardModal
                    isOpen={isShareDashboardModalOpen}
                    onHide={() => setIsShareDashboardModalOpen(false)}
                    dashboardId={dashboardId}
                />
            )}

            {/* Duplicate Dashboard Modal */}
            {isDuplicateDashboardModalOpen && dashboard && (
                <DuplicateDashboardModal
                    isOpen={isDuplicateDashboardModalOpen}
                    onHide={() => setIsDuplicateDashboardModalOpen(false)}
                    dashboard={dashboard}
                />
            )}

            {/* Delete confirmation modal */}
            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    isOpen={isDeleteConfirmationModalOpen}
                    icon={faTrash}
                    iconColor={SECONDARY_RED}
                    title={<RichMessage id="collections.confirmation-modal.delete-dashboard.title" />}
                    content={deleteModalContent}
                    customConfirmButton={deleteModalConfirmButton}
                    onCancel={() => setIsDeleteConfirmationModalOpen(false)}
                />
            )}
        </div>
    );
};

export default DashboardView;
