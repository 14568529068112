import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppResp } from 'api/hq/queries/App';
import { IdentityResp } from 'api/hq/queries/Identity';
import { IntegrationResp } from 'api/hq/queries/Integration';
import { RichTooltip, useRichIntl } from 'components/RichMessage';
import { APP_FEATURES } from 'constants/defaultValues';
import { identityConnectionPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    integration?: IntegrationResp;
    identity?: IdentityResp;
    app: AppResp;
    className?: string;
}

const IdentityAddInstance: FunctionComponent<Props> = ({ integration, identity, app, className }: Props) => {
    // Services
    const intl = useRichIntl();

    // State
    const [isReconnecting, setIsReconnecting] = useState<boolean>(false);

    // Format connection link
    const connectLink = identityConnectionPath({
        provider: app.provider,
        connectionScope: integration?.apiEndpoint,
        reconnect: true
    });

    // Evaluate tooltip message based on context
    const tooltipAddInstanceId = 'settings.app-management.identity-item.add-instance';
    const tooltipAddInstanceAppSpecificId = `${tooltipAddInstanceId}.${app.provider}`;
    const tooltipMsgId = intl.messages[tooltipAddInstanceAppSpecificId]
        ? tooltipAddInstanceAppSpecificId
        : tooltipAddInstanceId;

    // Action performed when connecting an app
    const reconnectIdentity = useCallback(() => {
        setIsReconnecting(true);
        window.location.href = connectLink;
    }, [connectLink]);

    // Abort if the app does not support the add instance feature
    // or if there are no identities connected
    if (!APP_FEATURES[app.provider]?.addInstance || !identity) return null;

    return (
        <RichTooltip id={tooltipMsgId} placement="bottom">
            <Button
                variant="primary"
                onClick={reconnectIdentity}
                disabled={isReconnecting}
                track-id={`click-identity-add-instance-${app.provider}`}
                className={className}
            >
                {isReconnecting ? (
                    <Spinner animation="border" size="sm" variant="primary" />
                ) : (
                    <FontAwesomeIcon icon={faPlus} />
                )}
            </Button>
        </RichTooltip>
    );
};

export default IdentityAddInstance;
