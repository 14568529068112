import { WidgetFactTemplate } from 'components/Dashboarding/Models/WidgetTemplates';
import WidgetDocumentationTooltip from 'components/Dashboarding/WidgetDocumentationTooltip';
import LibraryCard from 'components/LibraryCard/LibraryCard';
import { useRichIntl } from 'components/RichMessage';
import { LIBRARY_CARD_DESCRIPTION_MAX_LENGTH } from 'constants/defaultValues';
import { truncate } from 'lodash';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import LibraryCardHeader from '../LibraryCardHeader';
import LibraryCardLabels from '../LibraryCardLabels';

const LABELS_MAX_COUNT = 3;
interface Props {
    widget: WidgetFactTemplate;
    width?: number;
    height?: number;
    children: ReactNode | ReactNodeArray;
}

export const WidgetTemplateLibraryCard: FunctionComponent<Props> = ({ widget, width, height, children }: Props) => {
    // Services
    const intl = useRichIntl();

    // Content formatting
    const truncatedDescription = useMemo(
        () => truncate(widget.description, { length: LIBRARY_CARD_DESCRIPTION_MAX_LENGTH }),
        [widget.description]
    );

    return (
        <LibraryCard width={width} height={height}>
            {/* Header */}
            <LibraryCardHeader
                config={{
                    variant: 'rectangle-img',
                    imgDataUrl: widget.imgDataUrl
                }}
                title={intl.formatMessage({ id: `dashboarding.widget-editor.widget-type.${widget.configType}` })}
            />

            {/* Body */}
            <>
                {/* Title */}
                <Card.Title className="d-flex align-items-center mw-100">
                    <div className="text-truncate" title={widget.name}>
                        {widget.name}
                    </div>
                    <WidgetDocumentationTooltip widget={widget} />
                </Card.Title>

                {/* Description */}
                <Card.Text className="text-dark" title={widget.description}>
                    {truncatedDescription}
                </Card.Text>

                <LibraryCardLabels labels={widget.labels} maxCount={LABELS_MAX_COUNT} />
            </>

            {/* Main action button */}
            {children}
        </LibraryCard>
    );
};
