import { useQuery } from '@apollo/react-hooks';
import { vizApolloClient } from 'clients/vizApolloClient';
import { GetCustomFieldsResp, GET_CUSTOM_FIELDS } from '../queries/CustomField';
import { useApps } from 'api/hq/hooks/useApps';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useMemo } from 'react';
import {
    QueryOpField,
    QUERY_OP_CUSTOM_FIELD_PREFIX,
    QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING,
    QUERY_OP_FIELD_ID_TRAVERSING_SEPARATOR,
    QUERY_OP_FIELD_TRAVERSING_SEPARATOR
} from 'components/Dashboarding/DataSource/QueryOperators';
import { useCacheDefinition, useCachedValue } from 'util/useCache';

// Custom fields are only supported on inbox items for now
const FACTS_WITH_CUSTOM_FIELDS: QueryOpFact[] = ['INBOX_ITEMS'];

interface QueryOpts {
    fact: QueryOpFact;
    skip?: boolean;
}

interface QueryRs {
    customFields: QueryOpField[];
    loading: boolean;
}

// TODO: configure subscription for fields and clear cache as a result
export function useCustomFields({ skip, fact }: QueryOpts): QueryRs {
    // Get cached value
    const [cachedValue, cacheKey] = useCachedValue<QueryRs>('useCustomFields', [fact]);

    // Evaluate context
    const skipQuery = skip || !FACTS_WITH_CUSTOM_FIELDS.includes(fact) || !!cachedValue;

    // Report query
    const { data: fieldsData, loading: fieldsLoading } = useQuery<GetCustomFieldsResp>(GET_CUSTOM_FIELDS, {
        client: vizApolloClient,
        skip: skipQuery
    });
    const fields = useMemo(() => fieldsData?.report.results.fields || [], [fieldsData?.report.results.fields]);

    // Apps query
    const { apps, loading: appsLoading } = useApps({ skip: skipQuery });

    // Build full list of custom fields
    useCacheDefinition(() => {
        if (!fieldsLoading && !appsLoading && apps.length > 0 && fields.length > 0) {
            const customFields: QueryOpField[] = fields.map(e => {
                const typeMapping = QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING[e.schema.type];
                const operatorNames = e.schema.array
                    ? QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING['ARRAY'].filters
                    : typeMapping?.filters;

                return {
                    id: [QUERY_OP_CUSTOM_FIELD_PREFIX, e.id].join(QUERY_OP_FIELD_ID_TRAVERSING_SEPARATOR),
                    name: [QUERY_OP_CUSTOM_FIELD_PREFIX, e.id].join(QUERY_OP_FIELD_TRAVERSING_SEPARATOR),
                    label: e.name,
                    type: typeMapping?.type,
                    usageScopes: ['filter'],
                    schema: e.schema,
                    filterDisplayType: typeMapping?.filterDisplayType,
                    filters: operatorNames,
                    filterSection: apps.find(a => a.provider === e.provider)?.name
                };
            });

            return { customFields, loading: false };
        }
    }, cacheKey);

    // Return result
    return cachedValue || { customFields: [], loading: fieldsLoading || appsLoading };
}
