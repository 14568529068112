import React, { ReactElement, ReactNodeArray, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import classNames from 'classnames';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';

interface Props {
    children?: ReactNodeArray | ReactElement | boolean;
    className?: string;
    debounceTime?: number;
    onChange: (a?: string) => void;
    value?: string | null;
    placeholderId?: string;
}

function SearchBar({
    children,
    className,
    debounceTime = 0,
    onChange,
    value = '',
    placeholderId = 'menu.search'
}: Props): ReactElement<Props> {
    // Services
    const intl = useIntl();

    // State
    const [localValue, setLocalValue] = useState<string>(value || '');
    const [lockedUpdates, setLockedUpdates] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    // Get debounced version of onChange
    const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [debounceTime, onChange]);

    // Get debounced version of setLockedUpdates
    const debouncedSetLockedUpdates = useMemo(() => debounce(setLockedUpdates, DEFAULT_DEBOUNCE_TIME), [
        setLockedUpdates
    ]);

    // Hook invoked when the value changes
    const onValueChange = useCallback(
        (val: string): void => {
            // When the user is typing, lock the updates
            if (!lockedUpdates) setLockedUpdates(true);

            // Update local value state
            setLocalValue(val);

            // Propagate value to parent with debouncing
            debouncedOnChange(val);

            // Unlock the updates with the same debouncing
            debouncedSetLockedUpdates(false);
        },
        [debouncedOnChange, debouncedSetLockedUpdates, lockedUpdates]
    );

    // Resync local state from parent
    useEffect(() => {
        // If updates are locked, abort
        if (lockedUpdates || localValue == value) return;

        // Update local state with value
        setLocalValue(value || '');
    }, [localValue, lockedUpdates, value]);

    return (
        <div className={className}>
            <InputGroup className={classNames('transparent-addons', { focus: isFocused })}>
                {/* Search icon */}
                <InputGroup.Text className={classNames('ps-3', { 'text-primary': isFocused })}>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>

                {/* Input */}
                <Form.Control
                    autoComplete="off"
                    placeholder={intl.messages[placeholderId] as string}
                    value={localValue}
                    onChange={e => onValueChange(e.target.value)}
                    className="border-start-0 border-end-0"
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />

                {/* Clear icon */}
                <InputGroup.Text className="pe-3">
                    {value && <FontAwesomeIcon icon={faTimes} role="button" onClick={() => onValueChange('')} />}
                </InputGroup.Text>

                {/* Additional actions */}
                {children}
            </InputGroup>
        </div>
    );
}

export default SearchBar;
