import React, { FunctionComponent, useMemo, useState } from 'react';
import { filterFieldList, SeriesCardColumn, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';

// Max number of fields displayed before we add the sentence "and X others"
const MAX_DISPLAYED_FIELDS = 5;

interface Props {
    column: SeriesCardColumn;
    widget: WidgetMiniCard;
}

// A summary of the filters currently configured
const ColumnEditSummary: FunctionComponent<Props> = ({ column, widget }: Props) => {
    // State
    const [isHover, setIsHover] = useState<boolean>(false);

    // Get all fields defined on the fact
    const { queryOpFields } = useQueryOpFields({ fact: widget.config.fact });

    // Get list of fields involved in the filtering and the rest count
    const fieldList = useMemo(() => filterFieldList(queryOpFields, column.filters, { uniq: true }), [
        queryOpFields,
        column.filters
    ]);
    const restCount = fieldList.length - MAX_DISPLAYED_FIELDS;

    // Render filtering summary
    return (
        <div
            className={classNames('text-center border rounded h-100 w-100 p-2', {
                'border-style-dashed': isHover,
                'border-primary': isHover
            })}
            style={{ minHeight: '14vh' }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {isHover || fieldList.length == 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center text-primary h-100">
                    <FontAwesomeIcon icon={faPenAlt} />
                    <RichMessage id="dashboarding.widget-editor.configure-columns.box.configure-filters" />
                </div>
            ) : (
                <>
                    <div className="text-dark fw-bold mb-1">
                        <RichMessage id="dashboarding.widget-editor.configure-columns.box.filter-text" />
                    </div>
                    <div>
                        {/* First few fields displayed */}
                        {fieldList.slice(0, MAX_DISPLAYED_FIELDS).map(e => (
                            <div key={e}>{e}</div>
                        ))}

                        {/* And X others */}
                        {restCount > 0 && (
                            <div className="fw-bold">
                                <RichMessage
                                    id="dashboarding.widget-editor.configure-columns.box.list-rest"
                                    values={{ count: restCount }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ColumnEditSummary;
