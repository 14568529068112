import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardWithHeaders } from 'api/hq/queries/Dashboard';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import { RichMessage } from 'components/RichMessage';
import { dashboardViewPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDashboardDuplicator } from '../Hooks/useDashboardDuplicator';

interface Props {
    isOpen: boolean;
    onHide: () => void;
    dashboard: DashboardWithHeaders;
}

const DuplicateDashboardModal: FunctionComponent<Props> = ({ onHide, isOpen, dashboard }) => {
    // Services
    const intl = useIntl();
    const navigate = useNavigate();
    const { loading, duplicateDashboard } = useDashboardDuplicator({
        onComplete: dashboardId => navigate(dashboardViewPath({ dashboardId }))
    });

    // State
    const [duplicateWidgets, setDuplicateWidgets] = useState(true);
    const [duplicateRoles, setIsDuplicateRoles] = useState(false);
    const [name, setName] = useState(intl.formatMessage({ id: 'generic.with-copy-suffix' }, { name: dashboard.name }));

    // Duplicate the dashboard with customized name and specific options:
    // - duplicateWidgets: will duplicate all the widgets inside the dashboard
    // - private: all roles will not be copied with the dashboard
    const handleDuplicate = useCallback((): void => {
        duplicateDashboard(
            { id: dashboard.id, name },
            {
                duplicateWidgets,
                duplicateRoles
            }
        );
    }, [dashboard.id, duplicateDashboard, duplicateRoles, duplicateWidgets, name]);

    return (
        <Modal show={isOpen} onHide={onHide}>
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* Icon */}
                    <FontAwesomeIcon icon={faCopy} className="me-3" />

                    {/* Title */}
                    <FormattedMessage id="dashboarding.duplicate-dashboard-modal.title" />
                </Modal.Title>
            </Modal.Header>

            {/* Body */}
            <Modal.Body className="px-5 pb-3">
                {/* Dashboard Name */}
                <div className="d-flex flex-column justify-content-between">
                    {/* Label */}
                    <Form.Label htmlFor="dashboard-name" className="d-block">
                        <RichMessage id="dashboarding.duplicate-dashboard-modal.fields.name" />
                    </Form.Label>

                    {/* Control */}
                    <Form.Control id="dashboard-name" defaultValue={name} onChange={e => setName(e.target.value)} />
                </div>

                {/* insights duplication */}
                <div className="d-flex flex-column mt-5">
                    {/* Label */}
                    <Form.Label htmlFor="insight-duplication" className="d-block mb-3">
                        <span className="elevio-duplicate-dashboard-insights-label">
                            <RichMessage id="dashboarding.duplicate-dashboard-modal.insight-duplication.label" />
                        </span>
                    </Form.Label>

                    {/* Checkboxes */}
                    <div className="d-grid gap-3 text-grey-4">
                        {/* Duplicate all insights */}
                        <Form.Check className="fs-xs">
                            <Form.Check.Input
                                name="insight-duplication"
                                className="mt-0"
                                type="checkbox"
                                checked={duplicateWidgets}
                                onChange={() => setDuplicateWidgets(true)}
                            />
                            <Form.Check.Label className="fs-6 ms-1">
                                <RichMessage id="dashboarding.duplicate-dashboard-modal.insight-duplication.yes" />
                            </Form.Check.Label>
                        </Form.Check>

                        {/* Use the same insights */}
                        <Form.Check className="fs-xs">
                            <Form.Check.Input
                                type="checkbox"
                                className="mt-0"
                                name="insight-duplication"
                                checked={!duplicateWidgets}
                                onChange={() => setDuplicateWidgets(false)}
                            />
                            <Form.Check.Label className="fs-6 ms-1">
                                <RichMessage id="dashboarding.duplicate-dashboard-modal.insight-duplication.no" />
                            </Form.Check.Label>
                        </Form.Check>
                    </div>
                </div>

                {/* dashboard privacy */}
                <div className="d-flex flex-column mt-5">
                    {/* Label */}
                    <Form.Label htmlFor="dashboard-sharing" className="d-block mb-3">
                        <span className="elevio-duplicate-dashboard-permissions-label">
                            <RichMessage id="dashboarding.duplicate-dashboard-modal.dashboard-sharing.label" />
                        </span>
                    </Form.Label>

                    {/* Checkboxes */}
                    <div className="d-grid gap-3 text-grey-4">
                        {/* Duplicate all roles */}
                        <Form.Check className="fs-xs">
                            <Form.Check.Input
                                checked={duplicateRoles}
                                className="mt-0"
                                name="dashboard-sharing"
                                type="checkbox"
                                onChange={() => setIsDuplicateRoles(true)}
                            />
                            <Form.Check.Label className="fs-6 ms-1">
                                <RichMessage id="dashboarding.duplicate-dashboard-modal.dashboard-sharing.yes" />
                            </Form.Check.Label>
                        </Form.Check>

                        {/* private dashboard */}
                        <Form.Check className="fs-xs">
                            <Form.Check.Input
                                checked={!duplicateRoles}
                                className="mt-0"
                                name="dashboard-sharing"
                                type="checkbox"
                                onChange={() => setIsDuplicateRoles(false)}
                            />
                            <Form.Check.Label className="fs-6 ms-1">
                                <RichMessage id="dashboarding.duplicate-dashboard-modal.dashboard-sharing.no" />
                            </Form.Check.Label>
                        </Form.Check>
                    </div>
                </div>
            </Modal.Body>

            {/* Action buttons */}
            <Modal.Footer>
                <Row className="w-100">
                    {/* Cancel */}
                    <Col>
                        {/* Cancel button */}
                        <Button variant="outline-dark" className="w-100 justify-content-center" onClick={onHide}>
                            <FormattedMessage id="dashboarding.duplicate-dashboard-modal.actions.cancel" />
                        </Button>
                    </Col>

                    {/* Duplicate */}
                    <Col>
                        <AsyncButton
                            variant="primary"
                            className="w-100 h-100 justify-content-center"
                            disabled={loading}
                            onClick={handleDuplicate}
                            loading={loading}
                            messageProps={{ id: 'dashboarding.duplicate-dashboard-modal.actions.duplicate' }}
                            loadingMessageProps={{
                                id: 'dashboarding.duplicate-dashboard-modal.actions.duplicate-in-progress'
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default DuplicateDashboardModal;
