import React, { FunctionComponent, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { ErrorFragment } from 'components/ErrorManagement';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { RichMessage } from 'components/RichMessage';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useApps } from 'api/hq/hooks/useApps';
import { sortBy, uniq } from 'lodash';
import FullPageLoader from 'components/Design/FullPageLoader';
import { Alert } from 'react-bootstrap';
import AppConnectCardItem from 'components/AppConnectItem/AppConnectCardItem';
import { ReactComponent as AppsDiagram } from 'assets/img/onboarding/apps-diagram.svg';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { useCompanyIntegrations } from 'api/hq/hooks/useCompanyIntegrations';
import { ONBOARDING1_PATH, ONBOARDING1_STEP1, ONBOARDING_SKIPPED_STEP_KEY } from 'constants/routeBuilders';
import classNames from 'classnames';
import Header from './Header';

// Where to go after this step
const BACK_STEP_PATH = '/flows/onboarding/whats-next';
const NEXT_STEP_PATH = '/flows/onboarding/project-import';
const NEXT_STEP_SKIPPED_PATH = `/flows/onboarding/configure-dashboard/template-selection?${ONBOARDING_SKIPPED_STEP_KEY}=${ONBOARDING1_STEP1}`;

const Step1AppSelection: FunctionComponent = () => {
    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Fetch integrations
    const { data: integrationsData, loading: integrationsLoading } = useCompanyIntegrations({ companyId: company?.id });

    // Fetch apps data
    const { apps, loading: appsLoading, error: appErrors } = useApps();

    // Extract list of categories
    const appCategories = useMemo(() => uniq(apps.map(e => e.category)).sort(), [apps]);

    // Get sorted list of apps
    const sortedApps = useMemo(() => sortBy(apps, 'name'), [apps]);

    // No apps are connected yet
    const noAppsConnected = useMemo(() => integrationsData?.integrations.nodes?.length == 0, [
        integrationsData?.integrations.nodes
    ]);

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            {appsLoading || !apps || !company || integrationsLoading ? (
                <>
                    {/* Display loading screen if the initial query is loading */}
                    <FullPageLoader />
                </>
            ) : appErrors ? (
                <>
                    {/* Display error message */}
                    <ErrorFragment error={{ code: 'fetch-apps', namespace: 'onboarding-app-selection' }} />
                </>
            ) : (
                <>
                    <div className="flex-grow-1">
                        {/* Title */}
                        <div className="d-flex align-items-baseline mt-4 mb-5">
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                to={BACK_STEP_PATH}
                                variant="link"
                                className="p-0"
                            >
                                <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                            </Button>
                            <h1 className="fw-bolder">
                                <RichMessage id="flows.onboarding.app-selection.title" />
                            </h1>
                        </div>

                        {/* Info */}
                        <Alert
                            className="w-100 px-5 mb-4 border-0 text-primary rounded-4"
                            style={{ backgroundColor: '#eff5fe' }}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="position-absolute my-4 mx-3"
                                size="lg"
                                style={{ top: 0, left: 0 }}
                            />
                            <h5 className="my-2 fw-500">
                                <RichMessage id="flows.onboarding.app-selection.info.title" />
                            </h5>
                            <div className="text-dark" style={{ opacity: 0.8, textAlign: 'justify' }}>
                                <RichMessage id="flows.onboarding.app-selection.info.content" />
                            </div>
                        </Alert>

                        {appCategories.map(category => (
                            <div className="mb-4" key={category}>
                                {/* Category title */}
                                <h5 className="text-dark mb-3">
                                    <RichMessage id={`labels.app-categories.${category}`} />
                                </h5>

                                <div className="d-flex flex-wrap">
                                    {sortedApps
                                        .filter(e => e.category === category)
                                        .map(app => (
                                            <AppConnectCardItem
                                                key={app.id}
                                                className="me-3 mb-3"
                                                company={company}
                                                app={app}
                                                errorNamespace="onboarding-app-selection"
                                                skipDiscoveryStatus
                                                postConnectRedirectUri={`${ONBOARDING1_PATH}/${ONBOARDING1_STEP1}`}
                                            />
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Continue / Skip buttons */}
                    <div className="flex-grow-0">
                        <div className="mb-4">
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                variant="dark"
                                to={NEXT_STEP_PATH}
                                disabled={noAppsConnected}
                                className={classNames('justify-content-center', { disabled: noAppsConnected })}
                            >
                                <RichMessage id="flows.onboarding.app-selection.action.next" />
                            </Button>
                            {noAppsConnected && (
                                <Button
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    as={Link as any}
                                    variant="link"
                                    to={NEXT_STEP_SKIPPED_PATH}
                                    className="justify-content-center text-grey-1 fw-500"
                                >
                                    <RichMessage id="flows.onboarding.app-selection.action.skip" />
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* Right section */}
            <AppsDiagram
                className="m-auto"
                style={{ width: '75%', height: '75%', maxHeight: '500px', maxWidth: '500px' }}
            />
        </LayoutSplitScreen>
    );
};

export default Step1AppSelection;
