import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import {
    QueryOpField,
    QueryOpUsageScope,
    QUERY_OP_FORMULA_FUNCTION
} from 'components/Dashboarding/DataSource/QueryOperators';
import { omit, sortBy, truncate } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { WidgetDimensionField } from 'components/Dashboarding/Models/Widget';
import WidgetFormulaComposer, { FormulaComposerState } from './WidgetFormulaComposer';
import classNames from 'classnames';
import FieldTypeIcon from 'components/Dashboarding/FieldTypeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kpFormula } from 'util/customIcons';
import { useNavigableDropdown } from 'components/NavigableDropdown/useNavigableDropdown';
import NavigableDropdown from 'components/NavigableDropdown';

// Max number of characters allowed (function + field label)
const MAX_CONTENT_LENGTH = 32;

interface Props {
    label: ReactNode;
    fact: QueryOpFact;
    field: WidgetDimensionField;
    usageScope: QueryOpUsageScope;
    onChange: (e: WidgetDimensionField) => void;
    className?: string;
}

const WidgetDimensionSelector: FunctionComponent<Props> = ({
    label,
    fact,
    usageScope,
    field,
    onChange,
    className
}: Props) => {
    // Services
    const intl = useRichIntl();

    // State
    const [preFormulaField, setPreFormulaField] = useState<WidgetDimensionField | undefined>(undefined);

    // Get all relevant fields
    const { queryOpFields } = useQueryOpFields({ fact, usageScope });

    // Sort fields by name for display purpose
    const displayQueryOpFields = useMemo(() => sortBy(queryOpFields, ['label']), [queryOpFields]);

    // Get currently selected formatter config
    const currentFormatter = field?.function;
    const isFormula = currentFormatter == QUERY_OP_FORMULA_FUNCTION;
    const currentFieldFormatter = isFormula ? undefined : currentFormatter;

    // Get currently selected field
    const currentField = useMemo(() => (isFormula ? undefined : displayQueryOpFields.find(e => e.id === field?.ref)), [
        displayQueryOpFields,
        isFormula,
        field?.ref
    ]);

    // Get list of available formatters for the current field
    const formatters = useMemo(() => currentField?.dimensionFormatters || [], [currentField?.dimensionFormatters]);

    // Labels
    const formatterLabel = currentFormatter
        ? intl.formatMessage({
              id: `dashboarding.widget-field-selector.dimension-function.${currentFormatter}`
          })
        : '';
    const formatterDisplayLabel = truncate(formatterLabel, {
        length: MAX_CONTENT_LENGTH - (currentField?.label || '').length,
        omission: '..'
    });

    // Keep in memory the last selected field configuration which is not a formula. This
    // is used to restore the last select configuration when users choose to clear/cancel the formula.
    useEffect(() => {
        field.function != QUERY_OP_FORMULA_FUNCTION && setPreFormulaField(field);
    }, [field]);

    // Hook invoked when dimension formula is updated
    const onDimensionChange = useCallback(
        (e: WidgetDimensionField): void => {
            const targetFunction = e.function || field.function;

            if (e.function == QUERY_OP_FORMULA_FUNCTION) {
                // Formula updated
                onChange({
                    ...field,
                    function: e.function,
                    ref: e.ref,
                    formatting: {
                        ...field.formatting,
                        ...omit(e.formatting, 'formatter'),
                        rank: field.formatting?.rank
                    }
                });
            } else if (e.ref == field.ref) {
                // This is a change of function
                onChange({ ...field, function: e.function, ref: e.ref });
            } else {
                // This is a change of field.
                const newFieldConfig = queryOpFields.find(f => f.id == e.ref);

                // Attempt to keep the currently used function if it is compatible.
                const compatibleFormatters = newFieldConfig?.dimensionFormatters || [];
                const selectedFormatter =
                    targetFunction && compatibleFormatters.includes(targetFunction)
                        ? targetFunction
                        : compatibleFormatters[0];

                // Attempt to keep the currently used display formatter if it is compatible.
                const targetDisplayFormatter = e.formatting?.formatter;
                const compatibleDisplayFormatters = newFieldConfig?.displayFormatters || [];
                const selectedDisplayFormatter =
                    targetDisplayFormatter && compatibleDisplayFormatters.includes(targetDisplayFormatter)
                        ? targetDisplayFormatter
                        : undefined;

                // Apply change
                onChange({
                    ...field,
                    function: selectedFormatter,
                    ref: e.ref,
                    formatting: { ...field.formatting, formatter: selectedDisplayFormatter }
                });
            }
        },
        [field, onChange, queryOpFields]
    );

    // Hook invoked when the dimension formula is updated
    const onFormulaChange = useCallback(
        (formulaState: FormulaComposerState) => {
            // Do nothing if formula is undefined
            if (formulaState.formula == undefined) return;

            // Update field config
            onDimensionChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: formulaState.formula });
        },
        [onDimensionChange]
    );

    // Hook invoked when the formula is cleared. Attempt to restore the last known non-formula choice.
    const onFormulaClear = useCallback(
        () => onDimensionChange(preFormulaField || { ref: displayQueryOpFields[0].id }),
        [displayQueryOpFields, onDimensionChange, preFormulaField]
    );

    // Use dropdown for field
    const {
        getDropdownProps: getFieldDropdownProps,
        getDropdownMenuProps: getFieldDropdownMenuProps,
        getDropdownItemProps: getFieldDropdownItemProps,
        options: displayQueryOpFieldsResult
    } = useNavigableDropdown<QueryOpField>({
        fallbackSelect: () => onDimensionChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: '' }),
        onSelect: id => {
            if (id == QUERY_OP_FORMULA_FUNCTION) {
                onDimensionChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: '' });
            } else {
                onDimensionChange({ ref: id || '', function: id ? currentFieldFormatter : QUERY_OP_FORMULA_FUNCTION });
            }
        },
        options: displayQueryOpFields,
        mapper: {
            value: 'id',
            label: 'label'
        }
    });

    return (
        <div className={classNames('d-flex', 'h-100', 'flex-grow-1', className)}>
            {/* Dropdown: Choose field */}
            {!isFormula && (
                <Dropdown className="flex-grow-1" {...getFieldDropdownProps}>
                    <Dropdown.Toggle
                        as="div"
                        role="button"
                        className="d-flex justify-content-between align-items-center h-100 p-2"
                        title={currentField?.label}
                    >
                        {currentField ? (
                            <div className="d-flex">
                                <FieldTypeIcon field={currentField} className="me-2" />
                                <span className="text-dark text-uppercase fw-bold">{currentField.label}</span>
                            </div>
                        ) : (
                            <em>
                                <RichMessage id="dashboarding.widget-field-selector.empty-label" />
                            </em>
                        )}
                    </Dropdown.Toggle>

                    {/* List of fields */}
                    <NavigableDropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                        {...getFieldDropdownMenuProps}
                    >
                        {displayQueryOpFieldsResult.map(f => {
                            return (
                                <NavigableDropdown.Item
                                    key={f.id}
                                    onClick={() => onDimensionChange({ ref: f.id, function: currentFieldFormatter })}
                                    value={f.id}
                                    className="d-flex"
                                    {...getFieldDropdownItemProps}
                                >
                                    <FieldTypeIcon field={f} className="me-2" />
                                    <span
                                        className={classNames('text-dark text-uppercase', {
                                            'fw-bold': f.id == currentField?.id
                                        })}
                                    >
                                        {f.label}
                                    </span>
                                </NavigableDropdown.Item>
                            );
                        })}
                        <Dropdown.Divider />
                        <NavigableDropdown.Item
                            onClick={() => onDimensionChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: '' })}
                            className="d-flex align-items-center text-primary"
                            value={QUERY_OP_FORMULA_FUNCTION}
                            {...getFieldDropdownItemProps}
                        >
                            <FontAwesomeIcon icon={kpFormula} className="me-2 ms-1" />
                            <RichMessage id="dashboarding.widget-field-selector.dimension-function.FORMULA" />
                        </NavigableDropdown.Item>
                    </NavigableDropdown.Menu>
                </Dropdown>
            )}

            {/* Dropdown: Choose dimension function (grouping function) */}
            {currentField && formatters.length > 0 && currentFormatter && !isFormula && (
                <Dropdown className="flex-grow-1 border-start border-grey-6">
                    <Dropdown.Toggle
                        as="div"
                        role="button"
                        className="d-flex justify-content-between align-items-center h-100 p-2 ps-3 text-dark fw-bold"
                        title={formatterLabel}
                    >
                        {formatterDisplayLabel}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        {/* Available formatters */}
                        {formatters.map(f => {
                            return (
                                <Dropdown.Item
                                    key={f}
                                    onClick={() => onDimensionChange({ ref: currentField.id, function: f })}
                                    className={classNames({ 'fw-bold': f == currentFormatter })}
                                >
                                    <RichMessage id={`dashboarding.widget-field-selector.dimension-function.${f}`} />
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            )}

            {/* Special handling for formulas */}
            {isFormula && (
                <WidgetFormulaComposer
                    label={label}
                    value={field.ref}
                    onChange={onFormulaChange}
                    onClear={onFormulaClear}
                    fact={fact}
                    usageScope={usageScope}
                />
            )}
        </div>
    );
};

export default WidgetDimensionSelector;
