import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';

interface LabelBadgeProps {
    children: ReactNode;
    className?: string;
}

const LabelBadge: FunctionComponent<LabelBadgeProps> = ({ children, className }: LabelBadgeProps) => {
    return (
        <Badge
            pill
            className={classNames('text-light border border-light text-uppercase mb-2 fs-sm', className)}
            bg="none"
        >
            {children}
        </Badge>
    );
};

interface Props {
    labels: string[];
    maxCount: number;
}

const LibraryCardLabels: FunctionComponent<Props> = ({ labels, maxCount }) => {
    const truncatedLabels = useMemo(() => labels.slice(0, maxCount), [labels, maxCount]);
    const omittedLabels = useMemo(() => labels.slice(maxCount, labels?.length), [labels, maxCount]);

    return (
        <div>
            {truncatedLabels.map(e => (
                <LabelBadge key={e} className="me-2">
                    {e}
                </LabelBadge>
            ))}
            {omittedLabels.length > 0 && (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] }}
                    overlay={
                        <Popover className="rounded">
                            <Popover.Body>
                                {omittedLabels?.map(e => (
                                    <LabelBadge key={e} className="mx-1">
                                        {e}
                                    </LabelBadge>
                                ))}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    {/*
                        OverlayTrigger requires a React.forwardRef child. Wrapping our custom component
                        in a div is easier than having to integrate forwardRef on our custom component
                        See: https://stackoverflow.com/questions/54654303/using-a-forwardref-component-with-children-in-typescript
                    */}
                    <div className="d-inline-block">
                        <LabelBadge>+{omittedLabels.length}</LabelBadge>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    );
};

export default LibraryCardLabels;
