import React, { FunctionComponent } from 'react';
import { NameLabelPair } from 'react-querybuilder';

interface Props {
    className: string | undefined;
    title: string | undefined;
    value: string;
    values: NameLabelPair[];
    onChange: (value: string) => void;
}

const RadioValueField: FunctionComponent<Props> = ({ className, title, value, values, onChange }: Props) => {
    return (
        <span className={className} title={title}>
            {values.map(v => (
                <label key={v.name}>
                    <input
                        type="radio"
                        value={v.name}
                        checked={value === v.name}
                        onChange={e => onChange(e.target.value)}
                    />
                    {v.label}
                </label>
            ))}
        </span>
    );
};

export default RadioValueField;
