import React, { FunctionComponent, useMemo } from 'react';

import IdentityItem from './IdentityItem';
import { ErrorFragment } from 'components/ErrorManagement';
import { RichMessage, HelpIcon } from 'components/RichMessage';
import { useApps } from 'api/hq/hooks/useApps';
import { APP_CATEGORIES_WITH_IDENTITIES } from 'constants/defaultValues';
import { useSearchParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { sortBy } from 'lodash';
import FullPageLoader from 'components/Design/FullPageLoader';

const IdentityList: FunctionComponent = () => {
    // Services
    const [searchParams] = useSearchParams();

    // Fetch identity apps
    const { apps, loading: appLoading, error: appError } = useApps({ alpha: !!searchParams.get('alpha') });

    // Filter and sort apps
    const appList = useMemo(
        () =>
            sortBy(
                apps.filter(e => APP_CATEGORIES_WITH_IDENTITIES.includes(e.category)),
                'name'
            ),
        [apps]
    );

    // Display loading screen if the initial query is loading
    if (appLoading || !apps) return <FullPageLoader vh={75} />;

    // Hande fetch error
    if (appError) {
        return <ErrorFragment error={{ code: 'fetch-apps', namespace: 'fetch-user-account' }} />;
    }

    return (
        <Table className="align-middle">
            <thead>
                <tr>
                    <th colSpan={4}>
                        <h6 className="text-uppercase text-grey-4">
                            <RichMessage id="settings.app-management.identities" />
                            <HelpIcon id="settings.app-management.identities.help" className="ms-2" />
                        </h6>
                    </th>
                </tr>
            </thead>
            <tbody>
                {appList.map(app => (
                    <IdentityItem key={app.id} app={app} />
                ))}
            </tbody>
        </Table>
    );
};

export default IdentityList;
