// This file contains common array operators, usually provided by Lodash.
// Lodash tends to not be friendly with some react components, especially with lists.
// It is recommended to stick to ES6 as much as possible.

interface GenericObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

// Usage:
// myArray.filter(uniq)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uniq = (value: any, index: number, self: any[]): boolean => {
    return self.indexOf(value) === index;
};

// Usage:
// myObjectsArray.filter(uniqBy('someObjectProperty'))
export const uniqBy = (prop: string): ((obj: GenericObject, index: number, self: GenericObject[]) => boolean) => {
    return (obj, index, self): boolean => {
        return self.map(e => e[prop]).indexOf(obj[prop]) === index;
    };
};

// Usage:
// myObjectsArray.sort(sortBy('someObjectProperty'))
export const sortBy = (prop: string, caseInsensitive = false): ((a: GenericObject, b: GenericObject) => number) => {
    return (a, b): number => {
        if (a[prop] === b[prop]) return 0;
        if (caseInsensitive) {
            return a[prop].toLowerCase().localeCompare(b[prop].toLowerCase());
        } else {
            return a[prop] > b[prop] ? 1 : -1;
        }
    };
};

// Usage:
// myStringArray.sort(caseInsensitiveSort)
export const caseInsensitiveSort = (a: string, b: string): number => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
};

// Usage:
// myObjectsArray.sort(selectBy('someObjectProperty', 'someValue'))
export const selectBy = (
    prop: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    propValue: any
): ((obj: GenericObject, index: number, self: GenericObject[]) => boolean) => {
    return (obj): boolean => {
        return obj[prop] === propValue;
    };
};
