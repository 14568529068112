import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { CreateWidgetResp, CREATE_WIDGET, WIDGET_CREATE_ATTRS } from 'api/hq/queries/Widget';
import { DashboardWithLayout, UpdateDashboardResp, UPDATE_DASHBOARD } from 'api/hq/queries/Dashboard';
import { Layout } from 'react-grid-layout';
import { ReduxState } from 'redux/reducers';
import { QueryError } from 'components/ErrorManagement';
import { WidgetDisplayConfig, WidgetType, WIDGET_DISPLAY_CONFIGS } from '../Models/Widget';
import { findFirstAvailableGridPlacement } from 'routes/reports/GenericDashboard/DashboardGrid';
import { pick } from 'lodash';
import { MutationResp } from 'api/hq/queries/Shared';

interface AddNewWidgetToDashboardResult {
    success: boolean;
    widget?: { id: string };
    resp?: MutationResp | undefined;
    dashboard?: {
        id: string;
        layout: Layout[];
    };
    error?: QueryError;
}

export type AddNewWidgetType = Omit<WidgetType, 'id'>;
interface AddNewWidgetToDashboardProps {
    widget: AddNewWidgetType;
    placement?: Omit<Layout, 'i'>;
}

interface Props {
    dashboard?: DashboardWithLayout;
}

type Result = (arg: AddNewWidgetToDashboardProps) => Promise<AddNewWidgetToDashboardResult>;

// A hook for adding widget to a dashboard
function useAddNewWidgetToDashboard({ dashboard }: Props): Result {
    const company = useSelector((e: ReduxState) => e.authUser.company);
    const [createWidget] = useMutation<CreateWidgetResp>(CREATE_WIDGET);
    const [updateDashboard] = useMutation<UpdateDashboardResp>(UPDATE_DASHBOARD);

    return useCallback(
        async ({ widget, placement }) => {
            // Check dashboard exist
            if (!dashboard) throw new Error('no dashboards provided');

            // Create widget
            try {
                const widgetPayload = pick({ ...widget, companyId: company?.id }, WIDGET_CREATE_ATTRS);
                const resp = (await createWidget({ variables: { input: widgetPayload } })).data?.createWidget;

                // If creating widget a success
                if (resp?.success && resp.widget) {
                    // Widget sizing
                    const { defaultSizing }: WidgetDisplayConfig = WIDGET_DISPLAY_CONFIGS[widget.configType];

                    // Find available space for the widget
                    const widgetPlacement = findFirstAvailableGridPlacement(dashboard.layout, {
                        ...defaultSizing,
                        ...placement
                    });

                    // Build new layout version
                    const layout = dashboard.layout;
                    const payload = {
                        id: dashboard.id,
                        layout: [...layout, { i: resp.widget.id, ...widgetPlacement }]
                    };

                    // Update dashboard
                    const dashboardResp = (await updateDashboard({ variables: { input: payload } })).data
                        ?.updateDashboard;

                    // If updating dashboard failed
                    if (!dashboardResp?.success) throw dashboardResp?.errors[0] || { code: 'default ' };

                    // If updating dashboard succeed
                    return {
                        success: true,
                        widget: resp.widget,
                        dashboard: payload
                    };
                } else {
                    // If creating widget failed
                    throw resp;
                }
            } catch (resp) {
                return {
                    success: false,
                    resp: resp as MutationResp
                };
            }
        },
        [company?.id, createWidget, dashboard, updateDashboard]
    );
}

export default useAddNewWidgetToDashboard;
