import { useEffect, useState } from 'react';
import { SubscriptionHookOptions, useSubscription } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { OperationVariables } from '@apollo/react-common';

// Subscriptions can be stuck in a forever loading state if the server responds
// too quickly (before the component is mounted)
// The fix for this is to wait for the component to be mounted via useEffect
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSafeSubscription<TData = any, TVariables = OperationVariables>(
    subscription: DocumentNode,
    options?: SubscriptionHookOptions<TData, TVariables>
): {
    variables: TVariables | undefined;
    loading: boolean;
    data?: TData | undefined;
    error?: import('apollo-client').ApolloError | undefined;
} {
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const sub = useSubscription(subscription, { ...options, skip: !isMounted || options?.skip });

    // Wait for the component to be mounted
    useEffect(() => setIsMounted(true), []);

    // Return the subscription
    return sub;
}
