import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from 'redux/reducers';

import { useBillingSubscription } from 'api/hq/hooks/useBillingSubscription';
import CreditCardItem from 'components/CreditCard/CreditCardItem';
import CreditCardForm from 'components/CreditCard/CreditCardForm';
import SubscriptionPresenter from './SubscriptionPresenter';
import Confetti from 'react-confetti';
import { FormattedMessage } from 'react-intl';
import AccountSettingsLayout from 'routes/settings/AccountSettingsLayout';
import FullPageLoader from 'components/Design/FullPageLoader';
import {
    BillingSubscriptionReferences,
    BILLING_CARD_SETUP_STATUS_SUCCEEDED,
    BILLING_SUBSCRIPTION_REF_FREE
} from 'api/hq/queries/BillingSubscription';

const BillingSettings: FunctionComponent = () => {
    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // State
    const [isCardEdit, setIsCardEdit] = useState<boolean>(false);
    const [showConfetti, setShowConfetti] = useState<boolean>(false);

    // Get company billing subscription
    const {
        loading: billingSubLoading,
        refetch: refetchBillingSub,
        normalized: billingSubscription
    } = useBillingSubscription({
        companyId: company?.id
    });

    // Extract credit card status
    const isCardValid = billingSubscription?.billingCard?.setupStatus === BILLING_CARD_SETUP_STATUS_SUCCEEDED;

    // Handler invoked when the credit card is successfully saved
    const onCardSave = useCallback(async (): Promise<void> => {
        await refetchBillingSub();
        setIsCardEdit(false);
    }, [refetchBillingSub, setIsCardEdit]);

    // Handler invoked when the subscription is changed
    const onSubscriptionChange = useCallback(
        async (planRef: BillingSubscriptionReferences): Promise<void> => {
            await refetchBillingSub();

            // Throw confettis if the user upgraded to a paid plan
            if (planRef != BILLING_SUBSCRIPTION_REF_FREE) setShowConfetti(true);
        },
        [refetchBillingSub]
    );

    // On load, switch to edition mode if card is invalid and isCardEdit
    // has no value.
    useEffect(() => {
        if (!billingSubLoading && isCardEdit === null) setIsCardEdit(!isCardValid);
    }, [billingSubLoading, isCardEdit, isCardValid]);

    // Return loader
    if (billingSubLoading || !billingSubscription) {
        return (
            <AccountSettingsLayout id="subscription">
                <FullPageLoader vh={75} />
            </AccountSettingsLayout>
        );
    }

    // Return
    return (
        <AccountSettingsLayout id="subscription">
            {/* Post-upgrade confetti */}
            {showConfetti && <Confetti recycle={false} numberOfPieces={500} />}

            {/* Selected subscription */}
            <div className="mb-4">
                <SubscriptionPresenter billingSubscription={billingSubscription} onSave={onSubscriptionChange} />
            </div>

            {/* Payment method */}
            <div className="fw-bold fs-4 mb-3">
                <FormattedMessage id="settings.billing.form.billing-details" />
            </div>
            {billingSubscription?.billingCard && !isCardEdit ? (
                <CreditCardItem billingSubscription={billingSubscription} onEdit={() => setIsCardEdit(true)} />
            ) : (
                <CreditCardForm
                    billingSubscription={billingSubscription}
                    onSave={onCardSave}
                    onCancelEdit={() => setIsCardEdit(false)}
                />
            )}
        </AccountSettingsLayout>
    );
};

export default BillingSettings;
