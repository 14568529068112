/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

const mainMessages = {
    /* Components */
    'generic.footer': 'Accelerate your development',
    'generic.with-copy-suffix': '{name} - Copy',
    'generic.sort': 'Sort',
    'generic.none': 'None',

    // Error page component
    'error-page.title': 'Something is fishy...',
    'error-page.back-home-link': 'Back home',
    'error-page.back-link': 'Back',
    'error-fragment.title': 'Something is not right...',

    /* System Errors / Generic 0001XX */
    'errors.generic.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 000100]',
    'errors.generic.cannot-load-account':
        "We're having issues loading your account just now. Please check your internet connection and retry later. [Error: 000101]",

    /* System Errors / System Action / Invite user 0200XX */
    'errors.invite-company-user.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020000]',
    'errors.invite-company-user.email-already-has-a-pending-invite':
        'Email already has a pending invite. [Error: 020001]',

    /* System Errors / System Action / Enable projects 0201XX */
    'errors.enable-projects.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020100]',

    /* System Errors / System Action / Disable projects 0202XX */
    'errors.disable-projects.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020200]',

    /* System Errors / System Action / Track project 0203XX */
    'errors.track-project.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020300]',
    'errors.track-project.account-not-found':
        'This project owner does not seem to exist or is not accessible due to permissions. [Error: 020301]',
    'errors.track-project.name-not-found':
        'This project does not seem to exist or is not accessible due to permissions. [Error: 020302]',

    /* System Errors / System Action / Link connector user 0204XX */
    'errors.link-connector-user.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020400]',
    'errors.link-connector-user.integration-not-found':
        'It seems that you do not have access to the Keypup team for which this app was setup. Please ask the admin of your team to invite you to Keypup first. [Error: 020401]',
    'errors.link-connector-user.expired': 'This link is expired. [Error: 020402]',

    /* System Errors / System Action / Accept company invite 0205XX */
    'errors.accept-company-invite.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020500]',
    'errors.accept-company-invite.invite-not-found': 'This invite is invalid. [Error: 020501]',
    'errors.accept-company-invite.expired': 'This invite is either invalid or expired. [Error: 020502]',

    /* System Errors / System Action / Create prioritization rule 0206XX */
    'errors.create-prioritization-rule.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020600]',

    /* System Errors / System Action / Update prioritization rule 0207XX */
    'errors.update-prioritization-rule.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020700]',

    /* System Errors / System Action / Delete prioritization rule 0208XX */
    'errors.delete-prioritization-rule.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020800]',

    /* System Errors / System Action / Data Explorer query 0209XX */
    'errors.data-explorer-query.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 020900]',

    /* System Errors / System Action / Data Explorer query 0210XX */
    'errors.data-explorer-save-report.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 021000]',

    /* System Errors / System Action / Delete Custom Report 0211XX */
    'errors.delete-custom-report.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 021100]',

    /* System Errors / System Action / Create Company 0212XX */
    'errors.create-company.default':
        "Looks like we're unable to setup your team just now. Please retry later or contact us via chat. [Error: 021200]",

    /* System Errors / System Action / Update Company 0213XX */
    'errors.update-team.default':
        "Looks like we're unable to update your team just now. Please retry later or contact us via chat. [Error: 021300]",

    /* System Errors / System Action / Update Company 0214XX */
    'errors.update-sprint-settings.default':
        "Looks like we're unable to update your settings just now. Please retry later or contact us via chat. [Error: 021400]",

    /* System Errors / System Action / Upsert Company Board 0215XX */
    'errors.upsert-company-board.default':
        "Looks like we're unable to update your company board. Please retry later or contact us via chat. [Error: 021500]",

    /* System Errors / System Action / Update Subscription 0216XX */
    'errors.update-subscription.default':
        "Looks like we're unable to update your subscription just now. Please retry later or contact us via chat. [Error: 021600]",
    'errors.update-subscription.billing_card-is-blank':
        'You need to enter a credit card in order to upgrade. Please enter a credit card first or contact us via chat. [Error: 021601]',
    'errors.update-subscription.billing_card-was-declined':
        'We were unable to charge the subscription on your credit card. Please update your credit card details and retry. You can also contact us via chat if you believe this is an error. [Error: 021602]',

    /* System Errors / System Action / Update Subscription 0217XX */
    'errors.update-team-membership.default':
        "Looks like we're unable to update this membership just now. Please retry later or contact us via chat. [Error: 021700]",
    'errors.update-team-membership.update-rejected':
        'Looks like you do not have the rights to update this membership or your subscription does not allow it. [Error: 021701]',

    /* System Errors / System Action / Upgrade Subscription 0218XX */
    'errors.upgrade-subscription.default':
        "Looks like we're unable to upgrade your subscription just now. Please retry later or contact us via chat. [Error: 021800]",
    'errors.upgrade-subscription.billing_card-is-blank':
        'You need to enter a credit card in order to upgrade. Please enter a credit card first or contact us via chat. [Error: 021801]',
    'errors.upgrade-subscription.billing_card-was-declined':
        'We were unable to charge the subscription on your credit card. Please update your credit card details and retry. You can also contact us via chat if you believe this is an error. [Error: 021802]',

    /* System Errors / System Action / Assign Inbox Item Priority 0219XX */
    'errors.assign-inbox-item-priority.default':
        "Looks like we're unable to change this item priority right now. Please retry later or contact us via chat. [Error: 021900]",

    /* System Errors / System Action / Create dashboard from template - 0220XX */
    'errors.add-dashboard-template.default':
        "We're having issues creating your dashboard. Please retry later or contact us via chat. [Error: 022000]",
    'errors.add-dashboard-template.invalid-template':
        'The requested dashboard template is invalid. Please verify the URL you used or contact us via chat. [Error: 022001]',
    'errors.add-dashboard-template.no-companies':
        'You do not belong to any team. In order to add dashboard you must first create or join a team. [Error: 022002]',

    /* System Errors / System Action / Pin or unpin a dashboard - 0221XX */
    'errors.unpin-dashboard.default':
        "We're having issues unpinning this dashboard. Please retry later or contact us via chat. [Error: 022100]",
    'errors.pin-dashboard.default':
        "We're having issues pinning this dashboard. Please retry later or contact us via chat. [Error: 022101]",

    /* System Errors / System Action / Dashboard sharing - 0222XX */
    'errors.share-dashboard.default':
        "We're having issues sharing this dashboard. Please retry later or contact us via chat. [Error: 022200]",
    'errors.share-unshare-dashboard.default':
        "We're having issues updating permissions on this dashboard. Please retry later or contact us via chat. [Error: 022200]",

    /* System Errors / System Action / Delete resources - 0223XX */
    'errors.delete-dashboard.default':
        "We're having issues deleting this dashboard. Please retry later or contact us via chat. [Error: 022300]",
    'errors.delete-widget.default':
        "We're having issues deleting this insight. Please retry later or contact us via chat. [Error: 022301]",

    /* System Errors / Remote Action / Create comment - 0301XX */
    'errors.create-comment.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 030100]',
    'errors.create-comment.provider-unauthorized':
        'Your are not allowed to comment on this item (forbidden by {provider}). [Error: 030101]',
    'errors.create-comment.user-not-found':
        'It seems your {provider} identity is incorrectly setup on our side. Please contact us via chat. [Error: 030102]',

    /* System Errors / Remote Action / Merge pull request - 0302XX */
    'errors.merge-pull-request.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 030200]',
    'errors.merge-pull-request.unprocessable':
        'Base branch was modified. Hop on {provider} to review and try the merge again. [Error: 030201]',
    'errors.merge-pull-request.pull-request-closed':
        'The pull request has been closed and cannot be merged. [Error: 030202]',
    'errors.merge-pull-request.provider-api-throttled':
        'It seems {provider} is currently throttling our calls, certainly due to a recent large import. Please merge this PR from the {provider} website directly. [Error: 030203]',

    /* System Errors / Remote Action / Link PR to Issue - 0303XX */
    'errors.link-pr-to-issue.default':
        "We couldn't link your pull request just now. Please retry later or contact us via chat. [Error: 030300]",
    'errors.link-pr-to-issue.search-suggestion':
        "Looks like we're having issues retrieving your suggestions. Please retry later or contact us via chat. [Error: 030301]",
    'errors.link-pr-to-issue.pull-request-closed': 'Your PR is already closed and cannot be updated. [Error: 030302]',

    /* System Errors / Remote Action / Link issue to PR - 0304XX */
    'errors.link-issue-to-pr.default':
        "We couldn't link your issue to the selected PR(s). Please retry later or contact us via chat. [Error: 030400]",
    'errors.link-issue-to-pr.pull-request-closed':
        'One of the PR(s) you are linking to is already closed and cannot be updated. [Error: 030401]',

    /* System Errors / Page load / Priority Inbox - 0400XX */
    'errors.fetch-priority-inbox.default':
        "We're having issues retrieving your priority inbox. Please retry later or contact us via chat. [Error: 040000]",

    /* System Errors / Page load / Review & Merge - 0401XX */
    'errors.fetch-review-merge.default':
        "We're having issues retrieving your review & merge board. Please retry later or contact us via chat. [Error: 040100]",

    /* System Errors / Page load / Project List - 0402XX */
    'errors.fetch-project-list.default':
        "We're having issues retrieving your projects. Please retry later or contact us via chat. [Error: 040200]",
    'errors.fetch-project-list.cannot-fetch-projects':
        "We're having issues retrieving your projects. Please retry later or contact us via chat. [Error: 040201]",
    'errors.fetch-project-list.cannot-fetch-integrations':
        "We're having issues retrieving your projects. Please retry later or contact us via chat. [Error: 040202]",

    /* System Errors / Page load / Company Account - 0403XX */
    'errors.fetch-company-account.default':
        "We're having issues retrieving your team account. Please retry later or contact us via chat. [Error: 040300]",
    'errors.fetch-company-account.fetch-company':
        "We're having issues retrieving your team account. Please retry later or contact us via chat. [Error: 040301]",
    'errors.fetch-company-account.fetch-apps':
        "We're having issues retrieving your team account. Please retry later or contact us via chat. [Error: 040302]",
    'errors.fetch-company-account.fetch-integration':
        "We're having issues retrieving this app connection status. Please retry later or contact us via chat. [Error: 040303]",

    /* System Errors / Page load / Team List - 0404XX */
    'errors.team-management.default':
        "We're having issues retrieving your team details. Please retry later or contact us via chat. [Error: 040400]",
    'errors.team-management.fetch-members':
        "We're having issues retrieving your team members. Please retry later or contact us via chat. [Error: 040401]",
    'errors.team-management.fetch-invites':
        "We're having issues retrieving your team invites. Please retry later or contact us via chat. [Error: 040402]",
    'errors.team-management.fetch-team':
        "We're having issues retrieving your team details. Please retry later or contact us via chat. [Error: 040403]",

    /* System Errors / Page load / User Account - 0405XX */
    'errors.fetch-user-account.default':
        "We're having issues retrieving your account. Please retry later or contact us via chat. [Error: 040500]",
    'errors.fetch-user-account.fetch-user':
        "We're having issues retrieving your account. Please retry later or contact us via chat. [Error: 040501]",
    'errors.fetch-user-account.fetch-apps':
        "We're having issues retrieving your account. Please retry later or contact us via chat. [Error: 040502]",
    'errors.fetch-user-account.fetch-identity':
        "We're having issues retrieving this identity. Please retry later or contact us via chat. [Error: 040503]",
    'errors.fetch-user-account.fetch-integration':
        "We're having issues retrieving this identity. Please retry later or contact us via chat. [Error: 040504]",

    /* System Errors / Page load / Prioritization Rules - 0406XX */
    'errors.fetch-prioritization-rules.default':
        "We're having issues retrieving your prioritization rules. Please retry later or contact us via chat. [Error: 040600]",
    'errors.fetch-prioritization-rules.fetch-company':
        "We're having issues retrieving your team account. Please retry later or contact us via chat. [Error: 040601]",

    /* System Errors / Page load / Prioritization Rules - 0407XX */
    'errors.fetch-custom-reports.default':
        "We're having issues retrieving your saved reports. Please retry later or contact us via chat. [Error: 040700]",

    /* System Errors / Page load / Onboarding / Company Creation - 0408XX */

    /* System Errors / Page load / Onboarding / App Selection - 0409XX */
    'errors.onboarding-app-selection.default':
        "We're having issues with this page. Please retry later or contact us via chat. [Error: 040900]",
    'errors.onboarding-app-selection.fetch-apps':
        "We're having issues retrieving apps available for you. Please retry later or contact us via chat. [Error: 040901]",
    'errors.onboarding-app-selection.fetch-integration':
        "We're having issues retrieving this app connection status. Please retry later or contact us via chat. [Error: 040902]",
    'errors.onboarding-app-selection.fetch-integrations':
        "We're having issues retrieving your team apps. Please retry later or contact us via chat. [Error: 040903]",

    /* System Errors / Page load / Ruleset Builder - 0410XX */
    'errors.ruleset-builder.fetch-members':
        "We're having issues retrieving your team members. Please retry later or contact us via chat. [Error: 041000]",

    /* System Errors / Page load / Dashboard - 0411XX */
    'errors.dashboard-view.default':
        "We're having issues loading your dashboard. Please retry later or contact us via chat. [Error: 041100]",
    'errors.dashboard-view.no-access':
        'The dashboard you are trying to access does not exist or you do not have access to it. [Error: 041101]',

    /* System Errors / Component load / Dashboard Widget - 0412XX */
    'errors.dashboard-widget.default':
        "We're having issues loading your widget. Please retry later or contact us via chat. [Error: 041200]",
    'errors.dashboard-widget.no-access': 'This insight does not exist or you can no longer access it. [Error: 041201]',

    /* System Errors / Public - 0500XX */
    'errors.public.default':
        'Looks like something wrong happened. Please retry later or contact us via chat. [Error: 050000]',
    'errors.public.page-not-found.title': 'Looks like you might be lost',
    'errors.public.page-not-found':
        "The page you're trying to reach does not exist. Double check your URL or contact us via chat to report this page as missing. [Error: 050001]",

    /* System Errors / Public / Authentication - 0501xx */
    'errors.public.identity-taken.title': 'Identity already linked!',
    'errors.public.identity-taken':
        'The {provider} account you are trying to link has already been associated with another Keypup user. Contact us via chat if you believe you are receiving this message in error. [Error: 050100]',

    /* System Errors / Public / Authentication - 0502x */
    'errors.public.integration-reconnect-mismatch.title': 'Identity does not match',
    'errors.public.integration-reconnect-mismatch':
        'You are trying to reconnect {provider} using a different identity from the one originally used to connect {provider}. If you really want to use a different identity you must first disconnect {provider} from the settings page.{break2}Contact us via chat if you believe you are receiving this message in error. [Error: 050200]',

    /* System Errors / Public / Authentication Failed - 0503xx */
    'errors.public.access_denied.title': 'Authentication failed',
    'errors.public.access_denied': 'Authentication through {provider} failed. Please retry logging in. [Error: 050301]',
    'errors.public.access_denied:invalid_endpoint.title': 'Invalid instance URI',
    'errors.public.access_denied:invalid_endpoint':
        'The hosted endpoint you have specified is either pointing to localhost or to the provider cloud platform. Please retry with a valid hosted endpoint. [Error: 050302]',
    'errors.public.access_denied:instance_unreachable.title': 'Instance not reachable',
    'errors.public.access_denied:instance_unreachable':
        'The hosted endpoint you have specified is not reachable by our servers. Is your {provider} instance behind an IP-filtering firewall or only accessible through a VPN? [Error: 050303]',
    'errors.public.access_denied:connection_not_found.title': 'Hosted connection not found',
    'errors.public.access_denied:connection_not_found':
        'It looks like the hosted instance you are trying to login though is not registered with us. Please contact us via chat so our team can look into this for you [Error: 050304]',
    'errors.public.access_denied:user_profile_fetch_failed.title': 'Unable to retrieve profile',
    'errors.public.access_denied:user_profile_fetch_failed':
        'We were able to acquire an access token from your {provider} instance but were unable to retrieve your profile details. Please contact us via chat so our team can look into this for you [Error: 050305]',

    /* User Login, Logout, Register */
    'user.login-title': 'Login',
    'user.register': 'Register',
    'user.forgot-password': 'Forgot Password',
    'user.email': 'E-mail',
    'user.password': 'Password',
    'user.forgot-password-question': 'Forget password?',
    'user.fullname': 'Full Name',
    'user.login-button': 'LOGIN',
    'user.register-button': 'REGISTER',
    'user.reset-password-button': 'RESET',

    /* Top Nav Menu */
    'top-nav.user-menu.help-center': 'Help center',
    'top-nav.user-menu.collections': 'My collections',
    'top-nav.user-menu.account': 'Account',
    'top-nav.user-menu.apps-and-projects': 'Apps & Projects',
    'top-nav.user-menu.billing': 'Billing & Subscription',
    'top-nav.user-menu.prioritization-rules': 'Prioritization Rules',
    'top-nav.user-menu.team-management': 'Team Management',
    'top-nav.user-menu.cancel': 'Cancel',
    'top-nav.user-menu.sign-out': 'Sign out',
    'top-nav.user-menu.switch-company': 'Switch team',

    /* Menu */
    'menu.new-dashboard': 'New dashboard',
    'menu.my-collection': 'My collection',
    'menu.feedback': 'Give us feedback!',
    'menu.pinned-dashboards': 'Pinned dashboards',
    'menu.no-pinned-dashboards': 'No pinned dashboards. ',
    'menu.see-all-dashboards': 'See all',
    'menu.collections': 'My collections',
    'menu.create-dashboard': 'Create dashboard',
    'menu.dashboard-menu.unpin': 'Unpin',

    // Legacy menu options
    'menu.app': 'Home',
    'menu.reports': 'Reports',
    'menu.dashboard': 'Dashboard',
    'menu.priority-inbox': 'Priority Inbox',
    'menu.dev-funnel-board': 'Activity Funnel',
    'menu.board': 'Sprint Board',
    'menu.data-explorer': 'Data Explorer',
    'menu.pr-analysis': 'PR Analysis',
    'menu.contributors': 'Contributors',
    'menu.sandbox': 'Sandbox',
    'menu.settings': 'Settings',
    'menu.company': 'Company',
    'menu.apps-and-projects': 'Apps & Projects',
    'menu.billing': 'Billing & Subscription',
    'menu.project-list': 'Projects',
    'menu.prioritization-rules': 'Prioritization Rules',
    'menu.team': 'Team management',
    'menu.search': 'Search',
    'menu.account': 'Account',
    'menu.team-work': 'Team Progress',
    'menu.get-things-done': 'Action Items',
    'menu.explore': 'Understand',

    /* Menu help */
    'menu.help.company':
        'This page allows you to manage team settings as well as integrations to third-party services.{break} See this <link-to-helpcenter-connect-apps>help article</link-to-helpcenter-connect-apps> to learn more about connecting apps.',
    'menu.help.data-explorer':
        'The data explorer allows you to query your project data across all connected apps. Reports can also be pinned to your priority inbox for easy access.',
    'menu.help.project-list':
        'The projects page allows you to select/unselect projects that should be monitored by Keypup. Project selection is team-wide and therefore applied to all team members.{break2}You may read this <link-to-helpcenter-connect-projects>help article</link-to-helpcenter-connect-projects> to learn more about importing data for your projects.',
    'menu.help.team':
        'The team page allows you to invite users to your team account on Keypup. Members can only see reports. Admin users can invite users and connect projects.{break2}Check out this <link-to-helpcenter-team-management>help center section</link-to-helpcenter-team-management> to learn more about team management.',

    /* General Badges */
    'badge.alpha': 'alpha',
    'badge.beta': 'beta',
    'badge.new': 'new',

    /* General Labels */
    'labels.app-categories.CODE_MANAGEMENT': 'Code collaboration',
    'labels.app-categories.COMMUNICATION': 'Communication tools',
    'labels.app-categories.PROJECT_MANAGEMENT': 'Project Management',

    /* Error Page */
    'layouts.error-title': 'Ooops... looks like an error occurred!',
    'layouts.error-code': 'Error code',
    'layouts.go-back-home': 'GO BACK HOME',

    /* Component: Billing Guard > TierChangeWarningModal */
    'components.billing-guard.tier-change-warning-modal.title': 'Price change confirmation',
    'components.billing-guard.tier-change-warning-modal.body.upgrade.with-plan-change':
        'This action will automatically switch you from your current plan (up to {currentMaxQuantity} projects) to the one above {targetMaxQuantity, select, undefined {(300+ projects with per-project scaling price)} other {(up to {targetMaxQuantity} projects)}} with immediate effect.{break2}You will incur a charge of <b>${targetPrice} per month</b> (from ${currentPrice} per month) with a pro-rated adjustment for the current billing period to reflect the date of the change.{break2}By clicking on <b>Continue</b>, you agree to the change of plan.',
    'components.billing-guard.tier-change-warning-modal.body.downgrade.with-plan-change':
        'This action will automatically switch you from your current plan {currentMaxQuantity, select, undefined {(300+ projects with per-project scaling price)} other { (up to {currentMaxQuantity} projects)}} to the one below (up to {targetMaxQuantity} projects) with immediate effect.{break2}You will incur a charge of <b>${targetPrice} per month</b> (from ${currentPrice} per month) with a pro-rated adjustment for the current billing period to reflect the date of the change.{break2}By clicking on <b>Continue</b>, you agree to the change of plan.',
    'components.billing-guard.tier-change-warning-modal.body.upgrade.with-same-plan':
        'This action will automatically increase the number of projects you are billed for from {currentQuantity} projects to {targetQuantity} projects with immediate effect.{break2}You will incur a charge of <b>${targetPrice} per month</b> (from ${currentPrice} per month) with a pro-rated adjustment for the current billing period to reflect the date of the change.{break2}By clicking on <b>Continue</b>, you agree to the change of plan.',
    'components.billing-guard.tier-change-warning-modal.body.downgrade.with-same-plan':
        'This action will automatically decrease the number of projects you are billed for from {currentQuantity} projects to {targetQuantity} projects with immediate effect.{break2}You will incur a charge of <b>${targetPrice} per month</b> (from ${currentPrice} per month) with a pro-rated adjustment for the current billing period to reflect the date of the change.{break2}By clicking on <b>Continue</b>, you agree to the change of plan.',
    'components.billing-guard.tier-change-warning-modal.btn-continue': 'Continue',
    'components.billing-guard.tier-change-warning-modal.btn-continue-in-progress': 'Working...',
    'components.billing-guard.tier-change-warning-modal.btn-cancel': 'Cancel',

    /* Component: Billing Guard > CreditCardRequiredModal */
    'components.billing-guard.credit-card-required-modal.title': 'Credit card required',
    'components.billing-guard.credit-card-required-modal.body':
        'In order to upgrade your plan you need to enter a valid credit card.',
    'components.billing-guard.credit-card-required-modal.btn-proceed': 'Save & Continue',
    'components.billing-guard.credit-card-required-modal.btn-proceeding': 'Working...',

    /* Component: Billing Guard > PlanRequiredModal */
    'components.billing-guard.plan-required-modal.title': 'Paid plan required',
    'components.billing-guard.plan-required-modal.body':
        'Your current plan does not allow you to import more projects. {break2}By upgrading your plan you will be able to <b>track more projects</b> in your dashboards and insights, with <b><text-primary>unlimited historical data</text-primary></b> and <b><text-primary>unlimited collaborators</text-primary></b>.',
    'components.billing-guard.plan-required-modal.btn-cancel-cc-edit': 'Cancel edit',
    'components.billing-guard.plan-required-modal.btn-save-cc-edit': 'Save & Upgrade',
    'components.billing-guard.plan-required-modal.btn-cancel': 'Cancel',
    'components.billing-guard.plan-required-modal.btn-proceed': 'Upgrade',
    'components.billing-guard.plan-required-modal.btn-proceeding': 'Working...',

    /* Component: AppConnectItem */
    'components.app-connect-item.action-forbidden': 'Only admins can perform this action',
    'components.app-connect-item.action-add-instance':
        'Connect additional organizations (OAuth flow will be triggered again)',
    'components.app-connect-item.action-add-instance.jira':
        'Connect an additional JIRA site (OAuth flow will be triggered again)',
    'components.app-connect-item.connected': '<text-success><b>Connected</b></text-success>',
    'components.app-connect-item.connected-via':
        '<text-success><b>Connected</b></text-success> <text-grey-4>(via {username})</text-grey-4>',
    'components.app-connect-item.disabled': 'Not connected',
    'components.app-connect-item.disconnected': 'Disconnected',
    'components.app-connect-item.revoked-by': 'Revoked by {username}',
    'components.app-connect-item.disconnect': 'Disconnect',
    'components.app-connect-item.connect': 'Connect',
    'components.app-connect-item.connecting': 'Connecting...',
    'components.app-connect-item.discovering': 'Setting up...',
    'components.app-connect-item.disconnect-modal.title': 'Are you sure?',
    'components.app-connect-item.disconnect-modal.body':
        'Disconnecting this app will remove any project associated with it and purge all related data from our servers within 24h. It is always possible to reconnect the app later on if you wish to.',
    'components.app-connect-item.disconnect-modal.disconnect': 'Disconnect',
    'components.app-connect-item.disconnect-modal.disconnecting': 'Disconnecting...',
    'components.app-connect-item.app-help.bitbucket': 'Import pull requests',
    'components.app-connect-item.app-help-detailed.bitbucket':
        'The Bitbucket integration fetches pull requests, labels, milestones, review requests and reviews to analyze development activity and infer actions and priorities. Updates from Bitbucket are received in real-time for projects you have admin rights on. Keypup reverts to long polling - every 20 mins - for projects you have limited permissions on.{break2}See our <link-to-helpcenter-integration-bitbucket>help center article</link-to-helpcenter-integration-bitbucket> for more information.',
    'components.app-connect-item.app-help.github': 'Import issues and pull requests',
    'components.app-connect-item.app-help-detailed.github':
        'The GitHub integration fetches pull requests, issues, labels, milestones, review requests and reviews to analyze development activity and infer actions and priorities. Updates from GitHub are received in real-time for projects you have admin rights on. Keypup reverts to long polling - every 20 mins - for projects you have limited permissions on.{break}Note that GitHub has strict API rate limitations and it may take Keypup a few hours to initially retrieve your data if you connect a lot of projects at once (e.g. 50+).{break2}See our <link-to-helpcenter-integration-github>help center article</link-to-helpcenter-integration-github> for more information.',
    'components.app-connect-item.app-help.gitlab': 'Import issues and merge requests',
    'components.app-connect-item.app-help.gitlab-hosted': 'Import issues and merge requests',
    'components.app-connect-item.app-help-detailed.gitlab':
        'The GitLab integration fetches merge requests, issues, labels, milestones and suggested reviewers to analyze development activity and infer actions and priorities. Most updates from GitLab are received in real-time for projects you have admin rights on. Keypup reverts to long polling - every 20 mins - for projects you have limited permissions on. Labels and milestones are always long-polled as GitLab does not support webhooks for these resources.{break2}See our <link-to-helpcenter-integration-gitlab>help center article</link-to-helpcenter-integration-gitlab> for more information.',
    'components.app-connect-item.app-help-detailed.gitlab-hosted':
        'The GitLab integration fetches merge requests, issues, labels, milestones and suggested reviewers to analyze development activity and infer actions and priorities. Most updates from GitLab are received in real-time for projects you have admin rights on. Keypup reverts to long polling - every 20 mins - for projects you have limited permissions on. Labels and milestones are always long-polled as GitLab does not support webhooks for these resources.{break2}See our <link-to-helpcenter-integration-gitlab>help center article</link-to-helpcenter-integration-gitlab> for more information.',
    'components.app-connect-item.app-help.msteams': 'Receive personal updates from Keypup',
    'components.app-connect-item.app-help-detailed.msteams':
        "The Microsoft Teams integration installs a chat bot - Keybot - for your team. Keybot sends daily personal digests to team members containing priorities and actions for the day/week. Digests are always sent via private channels (only visible to the recipient). Keybot does not support mentions or discussions yet...but don't worry we're actively teaching him to listen and speak! (coming soon){break2}See our <link-to-helpcenter-integration-msteams>help center article</link-to-helpcenter-integration-msteams> for more information.",
    'components.app-connect-item.app-help.slack': 'Receive personal updates from Keypup',
    'components.app-connect-item.app-help-detailed.slack':
        "The Slack integration installs a chat bot - Keybot - into your workspace. Keybot sends daily personal digests to team members containing priorities and actions for the day/week. Digests are always sent via private channels (only visible to the recipient). Keybot does not support mentions or discussions yet...but don't worry we're actively teaching him to listen and speak! (coming soon){break2}See our <link-to-helpcenter-integration-slack>help center article</link-to-helpcenter-integration-slack> for more information.",
    'components.app-connect-item.app-help.jira': 'Import issues',
    'components.app-connect-item.app-help-detailed.jira':
        'The JIRA integration fetches issues and labels to analyze development activity and infer actions and priorities. Updates from JIRA are received via long-polling (every 10 mins) as JIRA does not support webhooks at this stage.{break2}See our <link-to-helpcenter-integration-jira>help center article</link-to-helpcenter-integration-jira> for more information.',
    'components.app-connect-item.app-help.clickup': 'Import issues',
    'components.app-connect-item.app-help-detailed.clickup':
        'The ClickUp integration fetches issues and labels to analyze development activity and infer actions and priorities. Updates from Clickup are received in real-time for projects you have admin rights on. Keypup reverts to long polling - every 10 mins - for projects you have limited permissions on.{break2}See our <link-to-helpcenter-integration-clickup>help center article</link-to-helpcenter-integration-clickup> for more information.',
    'components.app-connect-item.app-help.trello': 'Import issues',
    'components.app-connect-item.app-help-detailed.trello':
        'The Trello integration fetches cards and labels to analyze development activity and infer actions and priorities. Updates from Trello are received in real-time for boards you are a member of. Keypup reverts to long polling - every 10 mins - for boards you have limited permissions on.{break2}See our <link-to-helpcenter-integration-trello>help center article</link-to-helpcenter-integration-trello> for more information.',

    /* Component: ConfirmationModal */
    'component.confirmation-modal.cancel-button': 'Cancel',

    /* Component: List > FilteringDropdown*/
    'component.list.filtering-dropdown.all': 'All',

    /* Component: RulesetBuilder > Buttons */
    'components.ruleset-builder.add-rule-button.label': 'Add a rule',
    'components.ruleset-builder.add-group-button.label': 'Add a rule group',

    /* Component: RulesetBuilder > Fields */
    /* These keys are rendered using markdown */
    'components.ruleset-builder.help.custom_field.string':
        '<b>({section} custom field)</b> is a text field. You can use any text-specific operator (e.g. <em>=</em>, <em>regex</em>) to filter on this field.',
    'components.ruleset-builder.help.custom_field.integer':
        '<b>({section} custom field)</b> is a number field. You can use any number-specific operator (e.g. <em>=</em>, <em>>=</em>) to filter on this field.',
    'components.ruleset-builder.help.custom_field.boolean':
        '<b>({section} custom field)</b> is a boolean field. You can select `true` or `false` to filter on this field.',
    'components.ruleset-builder.help.custom_field.datetime':
        '<b>({section} custom field)</b> is a date & time field. You can use any time-specific operator (e.g. <em>>=</em>, <em>before</em>) to filter on this field.',
    'components.ruleset-builder.help.custom_field.string_array':
        '<b>({section} custom field)</b> is an array of text values. You can use any array-like operator (e.g. <em>include</em>, <em>exclude</em>) to filter on this field.',
    'components.ruleset-builder.help.custom_field.object':
        '<b>({section} custom field)</b> is an object composed of multiple sub-fields. You can select a specific sub-field and one of the provided operators to filter your results.',
    'components.ruleset-builder.help.custom_field.object_array':
        '<b>({section} custom field)</b> is an array of objects. You can use array-like operators (e.g. <em>include</em>, <em>exclude</em>) if you pick a field to match on `Any` element of the array. You can use field-specific operators (e.g. <em>>=</em>, <em>before</em>, <em>regex</em>) if you select a field on a specific element (`at index: x`) inside the array.',

    /* Component: WidgetDocumentationTooltip > Buttons */
    'components.widget-documentation-tooltip.title.label': 'About this insight',
    'components.widget-documentation-tooltip.no-documentation':
        'No documentation available.{break2}Edit the configuration of the widget to add your own.',

    /* Component: Charts > MetricKpi */
    'components.charts.metric-kpi.progression-unit': '{sign}{count, plural, =0 {No change} one {#} other {#}}',

    /* Component: RulesetBuilder > Operators*/
    /* These keys are rendered using markdown */
    'components.ruleset-builder.help.operator.AFTER_DATE':
        'will match if the field value is strictly after the provided date (day granularity).',
    'components.ruleset-builder.help.operator.AFTER_N_TIMEPERIOD_WITH_OFFSET':
        'will match if the field value is after X periods ago or from now.\n\nE.g.\n- **0 hours ago**: matches values in the future\n- **2 months ago**: matches values greater than 2 months in the past\n- **2 weeks from now**: matches values greater than 2 weeks in the future',
    'components.ruleset-builder.help.operator.ASSIGNEES_INCLUDE_ME': 'will match if you are assigned to the item.',
    'components.ruleset-builder.help.operator.AUTHOR_IS_ME': 'will match if you are the author of the item.',
    'components.ruleset-builder.help.operator.BEFORE_DATE':
        'will match if the field value is strictly before the provided date (day granularity).',
    'components.ruleset-builder.help.operator.BEFORE_N_TIMEPERIOD_WITH_OFFSET':
        'will match if the field value is before X periods ago or from now.\n\nE.g.\n- **0 hours ago**: matches values in the past\n- **2 months ago**: matches values older than 2 months in the past\n- **2 weeks from now**: matches values older than 2 weeks in the future',
    'components.ruleset-builder.help.operator.CURRENT_TIMEPERIOD':
        'will match if the field value is part of the selected present time period (e.g. current month).',
    'components.ruleset-builder.help.operator.EQUAL': 'will match if the field has the exact value.',
    'components.ruleset-builder.help.operator.GREATER_THAN':
        'will match if the field is strictly greater than the value.',
    'components.ruleset-builder.help.operator.GREATER_THAN_EQUAL':
        'will match if the field is greater or equal to the value.',
    'components.ruleset-builder.help.operator.INCLUDE': 'will match if the field (list) includes the provided value.',
    'components.ruleset-builder.help.operator.INCLUDE_ANY_OF':
        'will match if the field (list) includes any of the provided comma-separated values.',
    'components.ruleset-builder.help.operator.IS_EMPTY': 'will match if the field has an actual value (not `NULL`)',
    'components.ruleset-builder.help.operator.IS_DATE':
        'will match if the field is equal to the provided date (day granularity)',
    'components.ruleset-builder.help.operator.IS_NOT_EMPTY': 'will match if the field has no value (is `NULL`)',
    'components.ruleset-builder.help.operator.IS_NOT_DATE':
        'will match if the field is different from the provided date (day granularity)',
    'components.ruleset-builder.help.operator.LAST_N_TIMEPERIOD':
        'will match if the field value is part of the selected past time period. This operator is **exclusive of the current period**.\n\nE.g.\n-**Last week**: matches values from last week.\n-**Last 3 months**: matches values from the last 3 preceding months, excluding the current month.',
    'components.ruleset-builder.help.operator.LESS_THAN': 'will match if the field is strictly lesser than the value.',
    'components.ruleset-builder.help.operator.LESS_THAN_EQUAL':
        'will match if the field is lesser or equal to the value.',
    'components.ruleset-builder.help.operator.MATCH':
        'will match if the field matches the provided POSIX regex. Matching is case INsensitive by default. You can make the regex case sensitive by adding `(?c)` at the beginning of your regex. E.g. `(?c)^security` will match any value starting with "security".',
    'components.ruleset-builder.help.operator.MERGED_BY_IS_ME': 'will match if you merged the pull request.',
    'components.ruleset-builder.help.operator.NEXT_N_TIMEPERIOD':
        'will match if the field value is part of the selected future time period. This operator is **exclusive of the current period**.\n\nE.g.\n-**Next week**: matches values from next week.\n-**Next 3 months**: matches values from the next 3 coming months, excluding the current month.',
    'components.ruleset-builder.help.operator.NOT_EQUAL': 'will match if the field is different from the value.',
    'components.ruleset-builder.help.operator.NOT_INCLUDE':
        'will match if the field (list) does NOT include the provided value.',
    'components.ruleset-builder.help.operator.NOT_INCLUDE_ANY_OF':
        'will match if the field (list) does NOT include any of the provided comma-separated values.',
    'components.ruleset-builder.help.operator.NOT_MATCH':
        'will match if the field does not match the provided POSIX regex. Matching is case INsensitive by default. You can make the regex case sensitive by adding `(?c)` at the beginning of your regex. E.g. `(?c)^WIP` will exclude any value starting with "WIP".',
    'components.ruleset-builder.help.operator.REVIEWERS_INCLUDE_ME':
        'will match if you are assigned as a reviewer on the item.',
    'components.ruleset-builder.help.operator.WITHIN_DATES':
        'will match if the field value is within or equal to the provided date boundaries',

    /* Component: RulesetBuilder > Operators*/
    'components.ruleset-builder.explainer.loading': '(loading...)',

    /* Components: RulesetBuilder > Values > DateRangePicker > Labels */
    'components.ruleset-builder.daterangepicker.AND': 'AND',

    /* Components: DropdownMultiselect */
    'components.dropdown-multiselect.search-bar.placeholder': 'Search',
    'components.dropdown-multiselect.filtered-options.no-results': 'No results',
    'components.dropdown-multiselect.other': '+ {count, plural, one {# other} other {# others}}',

    /* Components: Library card */
    'components.library-card.updated-on': 'Last modified on <date-ll>{date}</date-ll>',
    'components.library-card.related-dashboards': 'Dashboards: ',
    'components.library-card.no-related-dashboards': 'None',
    'components.library-card.open-this-dashboard': 'Open dashboard',
    'components.library-card.open-insight': 'Preview insight',
    'components.library-card.add-insight-to-dashboard': 'Add insight to dashboard',
    'components.library-card.adding-insight-to-dashboard': 'Adding insight to dashboard...',
    'components.library-card.insight-already-added-to-dashboard': 'Insight already added to dashboard',
    'components.library-card.create-insight-from-template': 'Create new insight from template',
    'components.library-card.create-dashboard-from-template': 'Create new dashboard from template',

    /* Settings: Billing & Subscription > Credit Card Form */
    'components.credit-card.form.name': 'Name',
    'components.credit-card.form.email': 'Email',
    'components.credit-card.form.card-details': 'Card Details',
    'components.credit-card.form.cancel': 'Cancel',
    'components.credit-card.form.save': 'Save',
    'components.credit-card.form.saving': 'Saving...',
    'components.credit-card.form.error.REQUIRES_ACTION':
        'The last card you entered appears to be either invalid, expired or 3-D secure validation did not complete properly. Please re-enter your card details to avoid service disruption.',
    'components.credit-card.form.error.INVALID':
        'These card details seem to be invalid. Please try another card or contact us via chat if you believe this is an error.',
    'components.credit-card.form.error.default': 'Saving...',
    'components.credit-card.item.edit': 'Edit',
    'components.credit-card.item.masked-number': '••••  ••••  ••••  {number}',
    'components.credit-card.item.brand-and-expiry': '{brand} - Expires {expiryMonth}/{expiryYear}',

    /* Dashboard KPIs */
    'dashboards.kpi.avg-changes-per-active-contributor.title': 'Changes / contributor',
    'dashboards.kpi.contributor-count.title': 'Active Contributors',
    'dashboards.kpi.open-pull-requests.title': 'Open PRs',
    'dashboards.kpi.stale-pull-requests.title': 'Stale PRs',

    'dashboards.widget.markdown.cancel': 'Cancel',
    'dashboards.widget.markdown.save': 'Save',

    /* Reports Shared: Action Label */
    'reports.shared.action-label.action': 'Action',
    'reports.shared.action-label.title.ASSIGN_REVIEWER': 'Finish and assign reviewer',
    'reports.shared.action-label.title.ASSIGN_MISSING_REVIEWERS':
        'Assign {required_approval_remaining_count, plural, one {1 more reviewer} other {# more reviewers}}',
    'reports.shared.action-label.title.CLOSE': 'Close issue (implemented)',
    'reports.shared.action-label.title.IMPLEMENT': 'Implement',
    'reports.shared.action-label.title.FIX_BUILD': 'Fix build',
    'reports.shared.action-label.title.FIX_CODE': 'Address review changes',
    'reports.shared.action-label.title.MERGE': 'Merge',
    'reports.shared.action-label.title.REBASE': 'Rebase',
    'reports.shared.action-label.title.REVIEW':
        'Review {required_approval_remaining_count, plural, zero {} other {(# still needed)}}',
    'reports.shared.action-label.title.WAIT_FOR_REVIEW':
        'Wait for {required_approval_remaining_count, plural, zero {review} one {# review} other {# reviews}}',
    'reports.shared.action-label.title.WAIT_FOR_IMPLEMENTATION': 'Wait for PR to be merged',
    'reports.shared.action-label.title-short.ASSIGN_REVIEWER': 'Assign reviewer',
    'reports.shared.action-label.title-short.ASSIGN_MISSING_REVIEWERS':
        'Assign {required_approval_remaining_count, plural, one {1 more reviewer} other {# more reviewers}}',
    'reports.shared.action-label.title-short.CLOSE': 'Close',
    'reports.shared.action-label.title-short.IMPLEMENT': 'Implement',
    'reports.shared.action-label.title-short.FIX_BUILD': 'Fix build',
    'reports.shared.action-label.title-short.FIX_CODE': 'Changes needed',
    'reports.shared.action-label.title-short.MERGE': 'Merge',
    'reports.shared.action-label.title-short.REBASE': 'Rebase',
    'reports.shared.action-label.title-short.REVIEW':
        'Review {required_approval_remaining_count, plural, zero {} other {(# still needed)}}',
    'reports.shared.action-label.title-short.WAIT_FOR_REVIEW':
        'Wait for {required_approval_remaining_count, plural, zero {review} one {# review} other {# reviews}}',
    'reports.shared.action-label.title-short.WAIT_FOR_IMPLEMENTATION': 'Wait for PR',

    /* Reports Shared: Item Title */
    'reports.shared.item-title.build-icon.tooltip.SUCCESS': 'Build is green',
    'reports.shared.item-title.build-icon.tooltip.FAILURE': 'Build failed',
    'reports.shared.item-title.build-icon.tooltip.IN_PROGRESS': 'Build is running...',
    'reports.shared.item-title.long.pull_request': 'PR {key}',
    'reports.shared.item-title.long.issue': 'Issue {key}',
    'reports.shared.item-title.short.pr_only': 'PR only',
    'reports.shared.item-title.short.issue_only': 'Issue only',
    'reports.shared.item-title.short.pull_request': 'PR{key}',
    'reports.shared.item-title.short.issue': 'Issue{key}',

    /* Reports Shared: Resolved Items Tooltip */
    'reports.shared.resolved-items-tooltip.pull_request.title':
        'Resolves {count, number} {count, plural, one {item} other {items}}',
    'reports.shared.resolved-items-tooltip.pull_request.title-short':
        '{count, number} {count, plural, one {item} other {items}}',
    'reports.shared.resolved-items-tooltip.issue.title':
        'Resolved by {count, number} {count, plural, one {item} other {items}}',
    'reports.shared.resolved-items-tooltip.issue.title-short':
        '{count, number} {count, plural, one {item} other {items}}',
    'reports.shared.resolved-items-tooltip.content':
        'This pull request resolves the following {count, plural, one {item} other {items}}:',
    'reports.shared.resolved-items-tooltip.table.key': 'Key',
    'reports.shared.resolved-items-tooltip.table.title': 'Title',
    'reports.shared.resolved-items-tooltip.table.due_on': 'Due on',
    'reports.shared.resolved-items-tooltip.table.labels': 'Labels',

    /* Dashboarding: Dashboard/Widget JSON export */
    'dashboarding.resource-as-json-template.copy-json': 'JSON',
    'dashboarding.resource-as-json-template.copy-link': 'Template Link',

    /* Dashboarding: Dashboard editor */
    'dashboarding.dashboard-editor.dashboard-name-placeholder': 'Dashboard name',
    'dashboarding.dashboard-editor.dashboard-description-placeholder': 'Optional description',
    'dashboarding.dashboard-editor.delete-dashboard': 'Delete dashboard',
    'dashboarding.dashboard-editor.duplicate-dashboard': 'Duplicate dashboard',
    'dashboarding.dashboard-editor.share-dashboard': 'Share',
    'dashboarding.dashboard-editor.edit-dashboard': 'Edit dashboard',
    'dashboarding.dashboard-editor.save-dashboard': 'Save',
    'dashboarding.dashboard-editor.cancel-dashboard': 'Cancel',
    'dashboarding.dashboard-editor.add-insight': 'Add insight',
    'dashboarding.dashboard-editor.new-cards': 'New cards',
    'dashboarding.dashboard-editor.new-chart': 'New chart',
    'dashboarding.dashboard-editor.new-kpi': 'New KPI',
    'dashboarding.dashboard-editor.new-report': 'New report',
    'dashboarding.dashboard-editor.new-markdown': 'New textbox',
    'dashboarding.dashboard-editor.add-v-separator': 'V divider',
    'dashboarding.dashboard-editor.add-h-separator': 'H divider',
    'dashboarding.dashboard-editor.edit-insight': 'Edit insight',
    'dashboarding.dashboard-editor.duplicate-insight': 'Duplicate',
    'dashboarding.dashboard-editor.edit-content': 'Edit content',
    'dashboarding.dashboard-editor.delete-insight': 'Remove',
    'dashboarding.dashboard-editor.edit-drilldown': 'Edit drilldown',

    /* Dashboarding: New Dashboard Modal */
    'dashboarding.new-dashboard-modal.title': 'New dashboard',
    'dashboarding.new-dashboard-modal.create': 'Create',
    'dashboarding.new-dashboard-modal.cancel': 'Cancel',
    'dashboarding.new-dashboard-modal.create-blank-dashboard': 'Create blank dashboard',
    'dashboarding.new-dashboard-modal.creating-blank-dashboard': 'Creating blank dashboard...',

    /* Dashboarding: Share Dashboard Modal */
    'dashboarding.share-dashboard-modal.title': 'Share dashboard',
    'dashboarding.share-dashboard-modal.input.placeholder': 'Share to team member',
    'dashboarding.share-dashboard-modal.input.no-results': 'No results',
    'dashboarding.share-dashboard-modal.add': 'Add',
    'dashboarding.share-dashboard-modal.roles.OWNER': 'Owner',
    'dashboarding.share-dashboard-modal.roles.EDITOR': 'Editor',
    'dashboarding.share-dashboard-modal.roles.VIEWER': 'Viewer',
    'dashboarding.share-dashboard-modal.roles.NONE': 'Remove',
    'dashboarding.share-dashboard-modal.shared-with': 'Shared with',
    'dashboarding.share-dashboard-modal.not-shared-yet': 'This dashboard has not been shared to anyone yet.',
    'dashboarding.share-dashboard-modal.cancel': 'Cancel',
    'dashboarding.share-dashboard-modal.save': 'Save',
    'dashboarding.share-dashboard-modal.saving': 'Saving...',
    'dashboarding.share-dashboard-modal.done': 'Done',

    /* Dashboarding: Duplicate Dashboard Modal */
    'dashboarding.duplicate-dashboard-modal.title': 'Duplicate dashboard',
    'dashboarding.duplicate-dashboard-modal.actions.duplicate': 'Duplicate',
    'dashboarding.duplicate-dashboard-modal.actions.duplicate-in-progress': 'Duplicating...',
    'dashboarding.duplicate-dashboard-modal.fields.name': 'Dashboard name',
    'dashboarding.duplicate-dashboard-modal.actions.cancel': 'Cancel',
    'dashboarding.duplicate-dashboard-modal.insight-duplication.label': 'Do you want to duplicate the insights too?',
    'dashboarding.duplicate-dashboard-modal.insight-duplication.yes': 'Yes, create new copies of each insight',
    'dashboarding.duplicate-dashboard-modal.insight-duplication.no':
        'No, keep the original insights {break} (Note: this option means these insights will now be on multiple dashboards. If you edit them, they will update on each dashboard)',

    'dashboarding.duplicate-dashboard-modal.dashboard-sharing.label': 'Do you want to share the dashboard?',
    'dashboarding.duplicate-dashboard-modal.dashboard-sharing.yes':
        'Yes, share the dashboard copy with the same team members',
    'dashboarding.duplicate-dashboard-modal.dashboard-sharing.no': 'No, keep this dashboard copy private for now',

    /* Dashboarding: Add insight modal */
    'dashboarding.add-insight-modal.title': 'Add insight',
    'dashboarding.add-insight-modal.template-title': 'Add insight from template',
    'dashboarding.add-insight-modal.add-to-dashboard-success': 'The insight was successfully added to your dashboard.',

    /* Dashboarding: Add insight modal > Widget library */
    'dashboarding.add-insight-modal.widget-library.': 'Select template',
    'dashboarding.add-insight-modal.widget-library.title.suffix': ' - Copy',
    'dashboarding.add-insight-modal.widget-library.collections': 'My collections',
    'dashboarding.add-insight-modal.widget-library.templates': 'Keypup templates',
    'dashboarding.add-insight-modal.widget-library.category.collections.all': 'All',
    'dashboarding.add-insight-modal.widget-library.category.collections.owned': 'My insights',
    'dashboarding.add-insight-modal.widget-library.category.collections.shared': 'Shared with me',
    'dashboarding.add-insight-modal.widget-library.category.templates.all': 'All',
    'dashboarding.add-insight-modal.widget-library.category.templates.blank': 'Blank templates',
    'dashboarding.add-insight-modal.widget-library.category.templates.delivery': 'Delivery',
    'dashboarding.add-insight-modal.widget-library.category.templates.development-quality': 'Development Quality',
    'dashboarding.add-insight-modal.widget-library.category.templates.dora-metrics': 'DORA Metrics',
    'dashboarding.add-insight-modal.widget-library.category.templates.planning-distribution': 'Planning & Distribution',
    'dashboarding.add-insight-modal.widget-library.category.blank-templates': 'Start from a blank template',
    'dashboarding.add-insight-modal.widget-library.no-insights': 'No insights.',

    /* Dashboarding: Add insight modal > Builder selection */
    'dashboarding.add-insight-modal.builder-selection.assistant.title': 'Configuration assistant',
    'dashboarding.add-insight-modal.builder-selection.assistant.description':
        'Recommended for novice users to quickly setup insights through a series of questions.',
    'dashboarding.add-insight-modal.builder-selection.assistant.button': 'Configure with configuration assistant',
    'dashboarding.add-insight-modal.builder-selection.advanced.title': 'Advanced builder',
    'dashboarding.add-insight-modal.builder-selection.advanced.description':
        'Recommended for advanced users who need to configure filters, metrics and dimensions to create custom insights.',
    'dashboarding.add-insight-modal.builder-selection.advanced.button': 'Configure with advanced builder',
    'dashboarding.add-insight-modal.builder-selection.add-to-dashboard':
        'Keep default configuration and add to dashboard',
    'dashboarding.add-insight-modal.builder-selection.adding-to-dashboard': 'Adding insight to dashboard...',

    /* Dashboarding: Widget Library Modal > Library Card */

    /* Dashboarding: Widget messages */
    'dashboarding.widget.error-fallback.title': 'Something went wrong. You should remove this insight.',
    'dashboarding.widget.no-data.title': 'No data',
    'dashboarding.widget.no-data.sample-data': 'Sample data',

    /* Dashboarding: List widget > Pagination */
    'dashboarding.list-widget.pagination.total-records': '{totalRecords} entries, showing',
    'dashboarding.list-widget.pagination.per-page': 'per page',

    /* Dashboarding: List widget > Details modal */
    'dashboarding.list-widget.details-modal.title': 'Details: ',

    /* Dashboarding: Drilldown widget */
    'dashboarding.drilldown-widget.title': 'Drilldown on {sourceWidgetName}',
    'dashboarding.drilldown-widget.subtitle': 'Picked datapoint: ',

    /* Dashboarding: Edit widget */
    'dashboarding.widget-editor.title': 'Edit insight',
    'dashboarding.widget-editor.defaut-config.name': 'New insight',
    'dashboarding.widget-editor.defaut-config.name-placeholder': 'Insight name',
    'dashboarding.widget-editor.defaut-config.description': 'Optional short description',
    'dashboarding.widget-editor.action.save': 'Save',
    'dashboarding.widget-editor.action.save-as-new': 'Save as new',
    'dashboarding.widget-editor.action.cancel': 'Cancel',
    'dashboarding.widget-editor.action.undo.*': 'Undo (Ctrl+Z)',
    'dashboarding.widget-editor.action.redo.*': 'Redo (Ctrl+⇧+Z)',
    'dashboarding.widget-editor.action.undo.mac': 'Undo (⌘Z)',
    'dashboarding.widget-editor.action.redo.mac': 'Redo (⇧⌘Z)',
    'dashboarding.widget-editor.dock.configure-visualization': 'Select visualization',
    'dashboarding.widget-editor.dock.configure-columns': 'Configure board',
    'dashboarding.widget-editor.dock.configure-filters': 'Configure filters',
    'dashboarding.widget-editor.dock.configure-source': 'Select dataset',
    'dashboarding.widget-editor.dock.configure-table': 'Configure table',
    'dashboarding.widget-editor.dock.configure-kpi-metric-table': 'Configure KPI',
    'dashboarding.widget-editor.dock.configure-kpi-trend-table': 'Configure chart',
    'dashboarding.widget-editor.dock.customize-chart': 'Customize chart',
    'dashboarding.widget-editor.dock.customize-list': 'Customize table',
    'dashboarding.widget-editor.dock.configure-drilldown': 'Configure drilldown',
    'dashboarding.widget-editor.dock.document-insight': 'Document insight',
    'dashboarding.widget-editor.sort-selector.sort.asc': '{field} (ascending)',
    'dashboarding.widget-editor.sort-selector.sort.desc': '{field} (descending)',

    /* Dashboarding: Edit widget > Configure Source */
    'dashboarding.widget-editor.configure-source.heading.title': 'Select a source dataset',
    'dashboarding.widget-editor.configure-source.heading.description':
        'Changing the dataset drives the type of data and fields available for reporting in the next steps.',
    'dashboarding.widget-editor.configure-table.field-list-title': 'Available fields',

    /* Dashboarding: Edit widget > Configure Table */
    'dashboarding.widget-editor.configure-table.heading.title': 'Configure table',
    'dashboarding.widget-editor.configure-table.heading.description':
        'Select and configure the fields and metrics to populate your insight.',
    'dashboarding.widget-editor.configure-table.heading-type.metric': 'Metric',
    'dashboarding.widget-editor.configure-table.heading-type.dimension': 'Dimension',
    'dashboarding.widget-editor.configure-table.default-heading-name': 'Name of axis',
    'dashboarding.widget-editor.configure-table.action.add-metric': 'Add Metric',
    'dashboarding.widget-editor.configure-table.action.add-dimension': 'Add Dimension',
    'dashboarding.widget-editor.configure-table.action.remove-column': 'Delete column',
    'dashboarding.widget-editor.configure-table.action.sort-asc': 'Sort ascending',
    'dashboarding.widget-editor.configure-table.action.sort-desc': 'Sort descending',
    'dashboarding.widget-editor.configure-table.action.move-left': 'Move left',
    'dashboarding.widget-editor.configure-table.action.move-right': 'Move right',
    'dashboarding.widget-editor.configure-table.no-records-placeholder': 'No records',

    /* Dashboarding: Edit widget > Configure Table: KPI Metric */
    'dashboarding.widget-editor.configure-kpi-metric-table.heading.title': 'Configure main metric',
    'dashboarding.widget-editor.configure-kpi-metric-table.heading.description':
        'Select a metric function and some filters to build your main metric. If your group data using a dimension, only the first value will be retained.',

    /* Dashboarding: Edit widget > Configure Table: KPI Trend */
    'dashboarding.widget-editor.configure-kpi-trend-table.heading.title': 'Configure trend',
    'dashboarding.widget-editor.configure-kpi-trend-table.heading.description':
        'Group and sort your data to build the trailing points of your trend. Only the first {highlightCount} points will be retained.',

    /* Dashboarding: Edit widget > Configure Filters */
    'dashboarding.widget-editor.configure-filters.heading.title': 'Configure filtering and sorting',
    'dashboarding.widget-editor.configure-filters.heading.description':
        'Refine the list of cards to display by selecting fields to filter and sort on.',

    /* Dashboarding: Edit widget > Configure Columns */
    'dashboarding.widget-editor.configure-columns.heading.title': 'Configure board columns',
    'dashboarding.widget-editor.configure-columns.heading.description':
        'Refine the list of cards to display in each column by selecting global filters, column filters and sorting.',
    'dashboarding.widget-editor.configure-columns.global-filters': 'Global filters',
    'dashboarding.widget-editor.configure-columns.add-column': 'Add column',
    'dashboarding.widget-editor.configure-columns.box.move-left': 'Move left',
    'dashboarding.widget-editor.configure-columns.box.move-right': 'Move right',
    'dashboarding.widget-editor.configure-columns.box.remove-column': 'Delete column',
    'dashboarding.widget-editor.configure-columns.box.title': 'Filter dataset',
    'dashboarding.widget-editor.configure-columns.box.configure-filters': 'Configure filters',
    'dashboarding.widget-editor.configure-columns.box.list-rest':
        'and {count} {count, plural, one {other} other {others}}',
    'dashboarding.widget-editor.configure-columns.box.filter-text': 'Filtered on',
    'dashboarding.widget-editor.configure-columns.form.name': 'Name',
    'dashboarding.widget-editor.configure-columns.form.filters': 'Filters',
    'dashboarding.widget-editor.configure-columns.form.filters-error': 'A value is required in one of your rules',
    'dashboarding.widget-editor.configure-columns.form.save': 'Save',
    'dashboarding.widget-editor.configure-columns.form.cancel': 'Cancel',

    /* Dashboarding: Edit widget > Customize XX */
    'dashboarding.widget-editor.customize.metric-label': 'Metric label',
    'dashboarding.widget-editor.customize.metric-color': 'Metric color',
    'dashboarding.widget-editor.customize.kpi-color': 'KPI color',
    'dashboarding.widget-editor.customize.color-suffix.gradient-based': ' (gradient-based)',
    'dashboarding.widget-editor.customize.color-suffix.threshold-based': ' (threshold-based)',

    /* Dashboarding: Edit widget > Customize chart */
    'dashboarding.widget-editor.customize-chart.metrics-customization':
        '{count, plural, one {Metric} other {Metrics}} customization',
    'dashboarding.widget-editor.customize-chart.metric-legend-placeholder': 'Choose a metric name',
    'dashboarding.widget-editor.customize-chart.show-metric-legend.title':
        'Show the {count, plural, one {metric} other {metrics}} legend',
    'dashboarding.widget-editor.customize-chart.show-metric-legend.description':
        'Display the name and color of each metric next to the graph',
    'dashboarding.widget-editor.customize-chart.show-data-label.title': 'Show values in cells',
    'dashboarding.widget-editor.customize-chart.show-data-label.description': 'Display the values in each cell',
    'dashboarding.widget-editor.customize-chart.axis-legend-options': 'Axis options',
    'dashboarding.widget-editor.customize-chart.axis-x.label': 'Axis X label',
    'dashboarding.widget-editor.customize-chart.axis-x.placeholder': 'Axis X label',
    'dashboarding.widget-editor.customize-chart.axis-y.label': 'Axis Y label',
    'dashboarding.widget-editor.customize-chart.axis-y.placeholder': 'Axis Y label',
    'dashboarding.widget-editor.customize-chart.configure-threshold': 'Or configure threshold...',
    'dashboarding.widget-editor.customize-chart.configure-gradient': 'Or configure gradient...',

    /* Dashboarding: Edit widget > Customize kpi */
    'dashboarding.widget-editor.customize-kpi.metric.title': 'KPI configuration',
    'dashboarding.widget-editor.customize-kpi.metric.threshold-coloring': 'Threshold-based coloring',
    'dashboarding.widget-editor.customize-kpi.metric.name-title': 'KPI suffix',
    'dashboarding.widget-editor.customize-kpi.metric.name-placeholder': 'Choose a short suffix. E.g. %',
    'dashboarding.widget-editor.customize-kpi.trend.title': 'Chart configuration',
    'dashboarding.widget-editor.customize-kpi.trend.progression-enabled': 'Show trend',
    'dashboarding.widget-editor.customize-kpi.trend.name-placeholder': 'Choose a name for your chart metric',

    /* Dashboarding: Edit widget > Configure drilldown */
    'dashboarding.widget-editor.configure-drilldown.heading.title': 'Configure drilldown',
    'dashboarding.widget-editor.configure-drilldown.heading.multi-metrics-title': ' for metric {index}',
    'dashboarding.widget-editor.configure-drilldown.heading.metric-tab-title': 'Metric {index}',
    'dashboarding.widget-editor.configure-drilldown.heading.description':
        'Select and configure the fields, metrics and filters to populate your drilldown table.',
    'dashboarding.widget-editor.configure-drilldown.no-metrics':
        'Drill-down is only available with metrics. To add drill-down behavior, add a metric to your report',
    'dashboarding.widget-editor.configure-drilldown.apply-recommended-config': 'Apply recommended config',
    'dashboarding.widget-editor.configure-drilldown.drilldown-dropdown.item-title': 'Picked',
    'dashboarding.widget-editor.configure-drilldown.drilldown-dropdown.no-picked-value': 'no picked value',

    /* Dashboarding: Threshold-based color config */
    'dashboarding.widget-editor.threshold-based-color-config.add-threshold': 'Add threshold',

    /* Dashboarding: Gradient-based color config */
    'dashboarding.widget-editor.gradient-based-color-config.lowest-value': 'Lowest value',
    'dashboarding.widget-editor.gradient-based-color-config.middle-value': 'Middle value',
    'dashboarding.widget-editor.gradient-based-color-config.highest-value': 'Highest value',
    'dashboarding.widget-editor.gradient-based-color-config.add-bracket': 'Add bracket',

    /* Dashboarding: Edit widget > Document insight */
    'dashboarding.widget-editor.document-insight.title': 'Documentation',
    'dashboarding.widget-editor.document-insight.display-documentation.label':
        'Display documentation tooltip on widget',
    'dashboarding.widget-editor.document-insight.display-documentation.description':
        'Add a <b>?</b> button next to the widget title to make the documentation available via a tooltip.',
    'dashboarding.widget-editor.document-insight.description.label': 'One-liner description',
    'dashboarding.widget-editor.document-insight.description.description':
        'Used as a short description in collections and in the documentation tooltip.',
    'dashboarding.widget-editor.document-insight.documentation.label':
        'Full documentation <fw-light>(optional)</fw-light>',
    'dashboarding.widget-editor.document-insight.documentation.description':
        'Used in the documentation tooltip. The documentation should include detailed information and reading tips about your insight.',

    /* Dashboarding: Number color range */
    'dashboarding.widget-editor.number-color-range.base-color': 'as base color',
    'dashboarding.widget-editor.number-color-range.threshold-placeholder': 'Threshold',

    /* Dashboarding: Edit widget > Customize List */
    'dashboarding.widget-editor.customize-list.column-customization':
        '{count, plural, one {Column} other {Columns}} customization',
    'dashboarding.widget-editor.customize-list.striped-rows.title': 'Show zebra stripes',
    'dashboarding.widget-editor.customize-list.striped-rows.description': 'Alternate row colors to improve readability',

    /* Dashboarding: Edit widget > widget type names */
    'dashboarding.widget-editor.widget-type.AREACHART': 'Area chart',
    'dashboarding.widget-editor.widget-type.BARCHART': 'Bar chart',
    'dashboarding.widget-editor.widget-type.COLUMNCHART': 'Column chart',
    'dashboarding.widget-editor.widget-type.HEATMAPCHART': 'Heatmap chart',
    'dashboarding.widget-editor.widget-type.LARGECARD': 'Cards feed',
    'dashboarding.widget-editor.widget-type.LINECHART': 'Line chart',
    'dashboarding.widget-editor.widget-type.LIST': 'Report',
    'dashboarding.widget-editor.widget-type.NUMBERKPI': 'KPI',
    'dashboarding.widget-editor.widget-type.MINICARD': 'Board',
    'dashboarding.widget-editor.widget-type.PIECHART': 'Pie chart',
    'dashboarding.widget-editor.widget-type.SPARKCOLUMNKPI': 'KPI with column chart',
    'dashboarding.widget-editor.widget-type.SPARKLINEKPI': 'KPI with sparkline',
    'dashboarding.widget-editor.widget-type.TRENDKPI': 'KPI with trend',

    /* Dashboarding: Edit widget > toasts */
    'dashboarding.widget-editor.toasts.metric-added.default': 'Metric column added.',
    'dashboarding.widget-editor.toasts.metric-hidden.default': 'Metric column hidden.',
    'dashboarding.widget-editor.toasts.dimension-added.default': 'Dimension column added.',
    'dashboarding.widget-editor.toasts.dimension-hidden.default': 'Dimension column hidden.',
    'dashboarding.widget-editor.toasts.metric-added.match-insight-requirement':
        'Metric column added to match insight requirements.',
    'dashboarding.widget-editor.toasts.metric-hidden.match-insight-requirement':
        'Metric column hidden to match insight requirements.',
    'dashboarding.widget-editor.toasts.dimension-added.match-insight-requirement':
        'Dimension column added to match insight requirements.',
    'dashboarding.widget-editor.toasts.dimension-hidden.match-insight-requirement':
        'Dimension column hidden to match insight requirements.',
    'dashboarding.widget-editor.toasts.metric-added.replicate-configuration':
        'Metric column added to perform the configuration replication.',
    'dashboarding.widget-editor.toasts.metric-hidden.replicate-configuration':
        'Metric column hidden to perform the configuration replication.',
    'dashboarding.widget-editor.toasts.dimension-added.replicate-configuration':
        'Dimension column added to perform the configuration replication.',
    'dashboarding.widget-editor.toasts.dimension-hidden.replicate-configuration':
        'Dimension column hidden to perform the configuration replication.',
    'dashboarding.widget-editor.toasts.mini-to-large-card.columns-hidden.default':
        'Board column(s) hidden to match insight requirements.',
    'dashboarding.widget-editor.toasts.mini-to-large-card.filters-merged-with-global.default':
        'First column filters and global filters were merged into the large card filters.',
    'dashboarding.widget-editor.toasts.mini-to-large-card.filters-moved.default':
        'First column filters were moved in the large card filters.',
    'dashboarding.widget-editor.toasts.large-to-mini-card.default':
        'All large card filters were moved to first column filters in board.',
    'dashboarding.widget-editor.toasts.replicate-kpi-configuration.default':
        'The chart configuration was overwritten by the KPI configuration.',
    'dashboarding.widget-editor.toasts.replicate-chart-configuration.default':
        'The KPI configuration was overwritten by the chart configuration.',
    'dashboarding.widget-editor.toasts.sort-by-merged-at.default': 'Some merging dates are missing.',

    /* Dashboarding: Edit widget > widget descriptions */
    'dashboarding.widget-editor.widget-description.AREACHART':
        'A chart showing the evolution of values with an emphasis on volume (the area), particularly suitable for time series.',
    'dashboarding.widget-editor.widget-description.BARCHART':
        'A chart displaying horizontal bars and suited for discreet value distribution.',
    'dashboarding.widget-editor.widget-description.COLUMNCHART':
        'A chart displaying vertical bars and suited for discreet value distribution or time series.',
    'dashboarding.widget-editor.widget-description.HEATMAPCHART':
        'A chart displaying a heatmap of two dimensions and one metric',
    'dashboarding.widget-editor.widget-description.LARGECARD':
        'A list of issues and/or pull requests displayed in a wide format with key information, related items and comments.',
    'dashboarding.widget-editor.widget-description.LIST':
        'A table of record values with or without metrics. Suitable when details need to be surfaced.',
    'dashboarding.widget-editor.widget-description.LINECHART':
        'A line chart showing the evolution of values, particularly suitable for time series.',
    'dashboarding.widget-editor.widget-description.MINICARD':
        'A configurable sprint board with issues and pull requests. Suitable for tracking the progress of your pipe.',
    'dashboarding.widget-editor.widget-description.NUMBERKPI':
        'A simple KPI showing a single number, particularly suitable to focus on key metric.',
    'dashboarding.widget-editor.widget-description.PIECHART':
        'A chart highlighting the proportion of discreet values relative to each other.',
    'dashboarding.widget-editor.widget-description.SPARKCOLUMNKPI':
        'A KPI made of a metric and a column chart trend, particularly suitable to quickly check the evolution of key metrics.',
    'dashboarding.widget-editor.widget-description.SPARKLINEKPI':
        'A KPI made of a metric and a trend line, particularly suitable to quickly check the evolution of key metrics.',
    'dashboarding.widget-editor.widget-description.TRENDKPI':
        'A KPI made of a metric and a progression indicator, particularly suitable to highlight a recent evolution.',

    /* Dashboarding: Edit widget > Replicate configuration */
    'dashboarding.widget-editor.replicate-configuration.replicate-chart': 'Apply chart config',
    'dashboarding.widget-editor.replicate-configuration.replicate-kpi': 'Apply KPI config',

    /* Dashboarding: Edit widget > Filter box */
    'dashboarding.widget-editor.filter-box.default-title': 'Configure filter',
    'dashboarding.widget-editor.filter-box.records-text': 'records displayed',
    'dashboarding.widget-editor.filter-box.empty-text': 'Use filters to narrow down entries',
    'dashboarding.widget-editor.filter-box.list-rest': 'and {count} {count, plural, one {other} other {others}}',
    'dashboarding.widget-editor.filter-box.filters-error': 'A value is required in one of your rules',
    'dashboarding.widget-editor.filter-box.filter-text': 'Filtered on',
    'dashboarding.widget-editor.filter-box.action.save': 'Apply filters',
    'dashboarding.widget-editor.filter-box.action.cancel': 'Cancel',

    /* Dashboarding: Assistant */
    'dashboarding.assistant.form.required': 'Required',

    /* Dashboarding: Dashboard assistant */
    'dashboarding.dashboard-assistant.title': 'Dashboard assistant',
    'dashboarding.dashboard-assistant.header.cancel': 'Cancel',
    'dashboarding.dashboard-assistant.configure.title': '<text-primary>Configure</text-primary> your dashboard',
    'dashboarding.dashboard-assistant.configure.create': 'Create dashboard',
    'dashboarding.dashboard-assistant.configure.creating': 'Creating dashboard...',
    'dashboarding.dashboard-assistant.helper.impacted-insights':
        'This answer applies to the following {insightsCount, plural, one {insight} other {insights}} on your dashboard:',

    /* Dashboarding: Widget assistant */
    'dashboarding.widget-assistant.title': 'Insight assistant',
    'dashboarding.widget-assistant.widget-preview.invalid-form': 'Answer all questions to preview this insight',

    /* Dashboarding: Widget assistant - Header */
    'dashboarding.widget-assistant.header.stepper.configure': 'Configure',
    'dashboarding.widget-assistant.header.stepper.document': 'Document',
    'dashboarding.widget-assistant.header.stepper.review': 'Review',
    'dashboarding.widget-assistant.header.cancel': 'Cancel',

    /* Dashboarding: Widget assistant - Configure */
    'dashboarding.widget-assistant.configure.title': '<text-primary>Configure</text-primary> your insight',
    'dashboarding.widget-assistant.configure.next': 'Next',
    'dashboarding.widget-assistant.configure.reset': 'Revert answers to default',

    /* Dashboarding: Widget assistant - Document */
    'dashboarding.widget-assistant.document.title': '<text-primary>Document</text-primary> your insight',
    'dashboarding.widget-assistant.document.next': 'Next',

    /* Dashboarding: Widget assistant - Review */
    'dashboarding.widget-assistant.review.title': '<text-primary>Review</text-primary> your insight',
    'dashboarding.widget-assistant.review.info.title': 'What to do next',
    'dashboarding.widget-assistant.review.info.content.start': 'Your insight is now configured. You can now:',
    'dashboarding.widget-assistant.review.info.content.choices.item1':
        '<b>Save it and add it to your dashboard</b> for immediate use',
    'dashboarding.widget-assistant.review.info.content.choices.item2':
        '<b>Save it to your collections</b> for future use',
    'dashboarding.widget-assistant.review.info.content.choices.item3':
        '<b>Fine tune it in the advanced insight builder</b> to refine your queries and filters',
    'dashboarding.widget-assistant.review.info.content.end':
        'Note that once you exit this setup assistant (for any of the actions below), you <b>can no longer access it for this insight</b>.{break}Create a new insight from template to get back to this setup assistant',
    'dashboarding.widget-assistant.review.actions.save-add-to-dashboard': 'Save and add to dashboard',
    'dashboarding.widget-assistant.review.actions.saving-to-dashboard': 'Saving to dashboard...',
    'dashboarding.widget-assistant.review.actions.save-to-collections': 'Save to collections and quit',
    'dashboarding.widget-assistant.review.actions.saving-to-collections': 'Saving to collections...',
    'dashboarding.widget-assistant.review.actions.fine-tune-in-advanced-builder': 'Fine tune in advanced builder',

    /* Dashboarding: Widget-specific configuration (e.g. Axis names) */
    'dashboarding.widget-configuration.axis.LINECHART.dimension.0': 'X axis',
    'dashboarding.widget-configuration.axis.LINECHART.metric.*': 'Y axis (series {index})',
    'dashboarding.widget-configuration.axis.COLUMNCHART.dimension.0': 'X axis',
    'dashboarding.widget-configuration.axis.COLUMNCHART.metric.*': 'Y axis (series {index})',
    'dashboarding.widget-configuration.axis.LIST.dimension.*': 'Dimension {index}',
    'dashboarding.widget-configuration.axis.LIST.metric.*': 'Metric {index}',
    'dashboarding.widget-configuration.axis.PIECHART.dimension.0': 'Slice name',
    'dashboarding.widget-configuration.axis.PIECHART.metric.0': 'Slice size',
    'dashboarding.widget-configuration.axis.MINICARD.column.*': 'Column {index}',
    'dashboarding.widget-configuration.axis.HEATMAPCHART.dimension.0': 'X axis',
    'dashboarding.widget-configuration.axis.HEATMAPCHART.dimension.1': 'Y axis',
    'dashboarding.widget-configuration.axis.HEATMAPCHART.metric.0': 'Cell value',

    /* Dashboarding: Widget Field formatter */
    'dashboarding.widget-field-formatter.null': 'null',
    'dashboarding.widget-field-formatter.empty': '[empty]',
    'dashboarding.widget-field-formatter.link': '[Link]',

    /* Dashboarding: Widget Fact Selector */
    'dashboarding.widget-fact-selector.fact.COMMITS': 'Commits from pull requests',
    'dashboarding.widget-fact-selector.fact.COMMENTS': 'Comments from Issues/PRs/Reviews',
    'dashboarding.widget-fact-selector.fact.INBOX_ITEMS': 'Issues & Pull Requests',
    'dashboarding.widget-fact-selector.fact.REVIEWS': 'Reviews from pull requests',
    'dashboarding.widget-fact-selector.fact-description.COMMITS':
        'This dataset exposes commits attached to Pull Requests. Commits can be used to create activity feeds, release notes or metrics. Standalone commits pushed directly to branches are not available at this stage.',
    'dashboarding.widget-fact-selector.fact-description.COMMENTS':
        'This dataset exposes comments from Issues, Pull Requests and Pull Request Reviews. Comments can be used to create update feeds or activity metrics.',
    'dashboarding.widget-fact-selector.fact-description.INBOX_ITEMS':
        'This dataset makes Issues and Pull Requests available together. This makes it easy to create activity reports based on project and development data. This is the most popular and most complete dataset to create insights on work performance, quality and audit.',
    'dashboarding.widget-fact-selector.fact-description.REVIEWS':
        'This dataset exposes peer reviews from Pull Requests. Reviews can be used to create update feeds or metrics on review activity.',
    'dashboarding.widget-fact-selector.available-fields.show-label': 'See all available fields in this dataset',
    'dashboarding.widget-fact-selector.available-fields.hide-label': 'Hide available fields',

    /* Dashboarding: Display Formatter Selector */
    'dashboarding.widget-display-formatter-selector.formatter.NATIVE': '(no formatting)',
    'dashboarding.widget-display-formatter-selector.formatter.TS_L': '04/20/2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_DD/MM/YYYY': '20/04/2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_LL': 'April 20, 2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_ll': 'Apr 20, 2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_LLL': 'April 20, 2030 3:15 PM',
    'dashboarding.widget-display-formatter-selector.formatter.TS_lll': 'Apr 20, 2030 3:15 PM',
    'dashboarding.widget-display-formatter-selector.formatter.TS_LLLL': 'Friday, April 20, 2030 3:15 PM',
    'dashboarding.widget-display-formatter-selector.formatter.TS_llll': 'Fri, Apr 20, 2030 3:15 PM',
    'dashboarding.widget-display-formatter-selector.formatter.TS_MM/YYYY': '04/2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_MMM_YYYY': 'Apr, 2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_WW': 'Week 16',
    'dashboarding.widget-display-formatter-selector.formatter.TS_WW_YYYY': 'Week 16, 2030',
    'dashboarding.widget-display-formatter-selector.formatter.TS_YYYY-WW': '2030-W16',
    'dashboarding.widget-display-formatter-selector.formatter.TS_YYYY-MM': '2030-04',
    'dashboarding.widget-display-formatter-selector.formatter.TS_YYYY': '2030',
    'dashboarding.widget-display-formatter-selector.formatter.LABEL_BADGES': 'Badges',
    'dashboarding.widget-display-formatter-selector.formatter.FULL_LINK': 'Full link',
    'dashboarding.widget-display-formatter-selector.formatter.SHORT_LINK': 'Short link',

    /* Dashboarding: Widget Field Selector */
    'dashboarding.widget-field-selector.empty-label': 'Add field',
    'dashboarding.widget-field-selector.dimension-function.FORMULA': 'Custom formula',
    'dashboarding.widget-field-selector.dimension-function.NATIVE': '(no grouping logic)',
    'dashboarding.widget-field-selector.dimension-function.YEAR': 'Year',
    'dashboarding.widget-field-selector.dimension-function.YEAR_MONTH': 'Year > Month',
    'dashboarding.widget-field-selector.dimension-function.YEAR_MONTH_DAY': 'Year > Month > Day',
    'dashboarding.widget-field-selector.dimension-function.YEAR_WEEK': 'Year > Week',
    'dashboarding.widget-field-selector.metric-function.AVG': 'AVG',
    'dashboarding.widget-field-selector.metric-function.COUNT': 'COUNT',
    'dashboarding.widget-field-selector.metric-function.COUNT_DISTINCT': 'COUNT DISTINCT',
    'dashboarding.widget-field-selector.metric-function.FORMULA': 'Custom formula',
    'dashboarding.widget-field-selector.metric-function.MAX': 'MAX',
    'dashboarding.widget-field-selector.metric-function.MEDIAN': 'MEDIAN',
    'dashboarding.widget-field-selector.metric-function.MIN': 'MIN',
    'dashboarding.widget-field-selector.metric-function.STDDEV': 'STD DEVIATION',
    'dashboarding.widget-field-selector.metric-function.SUM': 'SUM',
    'dashboarding.widget-field-selector.metric-function.VARIANCE': 'VARIANCE',

    /* Dashboarding > Formula Editor > General */
    'dashboarding.formula-editor.help-hint.empty': 'Press F1 for suggestions or start typing.',
    'dashboarding.formula-editor.help-hint.error': 'Press F1 for suggestions.',
    'dashboarding.formula-editor.error.syntax-invalid': 'Formula syntax is incorrect.',
    'dashboarding.formula-editor.error.function-undefined': 'Function {target} does not exist.',
    'dashboarding.formula-editor.error.field-undefined': 'Field {target} does not exist.',
    'dashboarding.formula-editor.error.formula-empty': 'Formula is empty.',

    /* Dashboarding > Formula Editor > Fields > Commits */
    /* The documentation entries support markdown format (used in Monaco editor) */
    'dashboarding.formula-editor.help.fields.COMMITS._system_id.header': 'ID (system)',
    'dashboarding.formula-editor.help.fields.COMMITS._system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.COMMITS.additions.header': 'Code lines added',
    'dashboarding.formula-editor.help.fields.COMMITS.additions.documentation':
        'The number of lines of code added in the commit',
    'dashboarding.formula-editor.help.fields.COMMITS.author_username.header': 'Author username',
    'dashboarding.formula-editor.help.fields.COMMITS.author_username.documentation':
        'The username of the person who created the commit. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.COMMITS.created_at.header': 'Creation timestamp',
    'dashboarding.formula-editor.help.fields.COMMITS.created_at.documentation':
        'The datetime at which the commit was created.',
    'dashboarding.formula-editor.help.fields.COMMITS.deletions.header': 'Code lines deleted',
    'dashboarding.formula-editor.help.fields.COMMITS.deletions.documentation':
        'The number of lines of code deleted in the commit',
    'dashboarding.formula-editor.help.fields.COMMITS.id.header': 'ID (source)',
    'dashboarding.formula-editor.help.fields.COMMITS.id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.COMMITS.message.header': 'Commit message',
    'dashboarding.formula-editor.help.fields.COMMITS.message.documentation':
        'The message describing the content of the commit.',
    'dashboarding.formula-editor.help.fields.COMMITS.project_name.header': 'Project name',
    'dashboarding.formula-editor.help.fields.COMMITS.project_name.documentation':
        'The name of the project associated with the commit',
    'dashboarding.formula-editor.help.fields.COMMITS.updated_at.header': 'Last updated timestamp',
    'dashboarding.formula-editor.help.fields.COMMITS.updated_at.documentation':
        'The datetime at which the commit was last updated.',
    'dashboarding.formula-editor.help.fields.COMMITS.url.header': 'Commit URL',
    'dashboarding.formula-editor.help.fields.COMMITS.url.documentation':
        'The URL of the commit in the source application',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_author_username.header': 'PR author',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_author_username.documentation':
        'The username of the person who created the issue or pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_created_at.header': 'PR creation',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_created_at.documentation':
        'The datetime at which the pull request or issue was created.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_closed_at.header': 'PR closure date',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_closed_at.documentation':
        'The datetime at which the item was merged or closed.',
    'dashboarding.formula-editor.help.fields.COMMITS.due_on.header': 'PR due date',
    // Pull Request association
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_due_on.documentation':
        'The due date of the item. This due date takes into account resolved issues. E.g. a pull request resolving multiple issues will have a `due_on` value equals to the earliest due date.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_system_id.header': 'PR ID (system)',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_id.header': 'PR ID (source)',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_label_names.header': 'PR labels',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_label_names.documentation':
        'The list of labels assigned to the item. Labels are app specific and case sensitive.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_merged_at.header': 'PR merge date',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_merged_at.documentation':
        'The datetime at which the pull request was merged. The field will be `null` for issues and pull requests which have been closed without merge.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_review_state.header': 'PR review state',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_review_state.documentation':
        'The state of the pull request review. Possible values are:\n- `APPROVED` the pull request is approved by all participating reviewers\n- `CHANGES_REQUESTED` one of the reviewers has requested changes\n- `NONE` there is no review in progress\n- `PENDING` some required reviews are still pending approval/rejection',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_state.header': 'PR state',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_state.documentation':
        'The open/close state of the item. Possible values are:\n- `OPEN` the issue or pull request is still active\n- `MERGED` the pull request was merged\n- `CLOSED` the issue was closed or the pull request was closed without merge',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_title.header': 'PR title.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_title.documentation':
        'The title/name/header of the item.',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_updated_at.header': 'PR update timestamp',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_updated_at.documentation':
        'The datetime at which the item was last updated (description, CI build, assigned milestone etc.).',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_url.header': 'PR URL',
    'dashboarding.formula-editor.help.fields.COMMITS.pull_request_url.documentation':
        'The URL of the item in the source application',

    /* Dashboarding > Formula Editor > Fields > Comments */
    /* The documentation entries support markdown format (used in Monaco editor) */
    'dashboarding.formula-editor.help.fields.COMMENTS._system_id.header': 'ID (system)',
    'dashboarding.formula-editor.help.fields.COMMENTS._system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.COMMENTS.author_username.header': 'Author username',
    'dashboarding.formula-editor.help.fields.COMMENTS.author_username.documentation':
        'The username of the person who created the comment. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.COMMENTS.body.header': 'Body/content',
    'dashboarding.formula-editor.help.fields.COMMENTS.body.documentation': 'The body of the comment in source format',
    'dashboarding.formula-editor.help.fields.COMMENTS.created_at.header': 'Creation timestamp',
    'dashboarding.formula-editor.help.fields.COMMENTS.created_at.documentation':
        'The datetime at which the comment was created.',
    'dashboarding.formula-editor.help.fields.COMMENTS.id.header': 'ID (source)',
    'dashboarding.formula-editor.help.fields.COMMENTS.id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.COMMENTS.project_name.header': 'Project name',
    'dashboarding.formula-editor.help.fields.COMMENTS.project_name.documentation':
        'The name of the project associated with the comment',
    'dashboarding.formula-editor.help.fields.COMMENTS.updated_at.header': 'Last updated timestamp',
    'dashboarding.formula-editor.help.fields.COMMENTS.updated_at.documentation':
        'The datetime at which the comment was last updated.',
    'dashboarding.formula-editor.help.fields.COMMENTS.url.header': 'Resource URL',
    'dashboarding.formula-editor.help.fields.COMMENTS.url.documentation':
        'The URL of the comment in the source application',
    // Issue/PR association
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_author_username.header': 'Issue/PR author',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_author_username.documentation':
        'The username of the person who created the issue or pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_created_at.header': 'Issue/PR creation',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_created_at.documentation':
        'The datetime at which the pull request or issue was created.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_closed_at.header': 'Issue/PR closure date',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_closed_at.documentation':
        'The datetime at which the item was merged or closed.',
    'dashboarding.formula-editor.help.fields.COMMENTS.due_on.header': 'Issue/PR due date',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_due_on.documentation':
        'The due date of the item. This due date takes into account resolved issues. E.g. a pull request resolving multiple issues will have a `due_on` value equals to the earliest due date.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_system_id.header': 'Issue/PR ID (system)',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_id.header': 'Issue/PR ID (source)',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_label_names.header': 'Issue/PR labels',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_label_names.documentation':
        'The list of labels assigned to the item. Labels are app specific and case sensitive.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_merged_at.header': 'Issue/PR merge date',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_merged_at.documentation':
        'The datetime at which the pull request was merged. The field will be `null` for issues and pull requests which have been closed without merge.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_review_state.header': 'Issue/PR review state',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_review_state.documentation':
        'The state of the pull request review. Possible values are:\n- `APPROVED` the pull request is approved by all participating reviewers\n- `CHANGES_REQUESTED` one of the reviewers has requested changes\n- `NONE` there is no review in progress\n- `PENDING` some required reviews are still pending approval/rejection',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_state.header': 'Issue/PR state',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_state.documentation':
        'The open/close state of the item. Possible values are:\n- `OPEN` the issue or pull request is still active\n- `MERGED` the pull request was merged\n- `CLOSED` the issue was closed or the pull request was closed without merge',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_title.header': 'Issue/PR title.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_title.documentation':
        'The title/name/header of the item.',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_type.header': 'Issue/PR type',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_type.documentation':
        'The type of the item. Possible values are:\n- `ISSUE` the item is an issue\n- `PULL_REQUEST` the item is pull request',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_updated_at.header': 'Issue/PR update timestamp',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_updated_at.documentation':
        'The datetime at which the item was last updated (description, CI build, assigned milestone etc.).',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_url.header': 'Issue/PR URL',
    'dashboarding.formula-editor.help.fields.COMMENTS.inbox_item_url.documentation':
        'The URL of the item in the source application',
    // Review association
    'dashboarding.formula-editor.help.fields.COMMENTS.review_system_id.header': 'Review ID (system)',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_author_username.header': 'Review author',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_author_username.documentation':
        'The username of the person who created the review. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_created_at.header': 'Review creation',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_created_at.documentation':
        'The datetime at which the review was created.',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_id.header': 'Review ID (source)',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_updated_at.header': 'Review update timestamp',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_updated_at.documentation':
        'The datetime at which the review was last updated.',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_url.header': 'Review URL',
    'dashboarding.formula-editor.help.fields.COMMENTS.review_url.documentation':
        'The URL of the review in the source application',

    /* Dashboarding > Formula Editor > Fields > Inbox Items */
    /* The documentation entries support markdown format (used in Monaco editor) */
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS._system_id.header': 'ID (system)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS._system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.additions.header': 'Code lines added',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.additions.documentation':
        'The number of lines of code added in the pull request',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.author_username.header': 'Author username',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.author_username.documentation':
        'The username of the person who created the issue or pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.assignee_usernames.header': 'Assignee usernames',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.assignee_usernames.documentation':
        'The list of people usernames declared as being assigned to the issue or pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.assigned_at.header': 'First assignment timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.assigned_at.documentation':
        'The datetime at which a user was first assigned to the item. This date cannot be earlier than when the item was imported into Keypup.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.body.header': 'Body/description',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.body.documentation':
        'The body of the pull request or issue in source format',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.build_status.header': 'PR build status',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.build_status.documentation':
        'The state `PENDING`, `RUNNING`, `FAILED`, `SUCCESS` of the Continuous Integration (CI) as exposed by the code management platforms (e.g. GitHub, GitLab)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.created_at.header': 'Creation timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.created_at.documentation':
        'The datetime at which the pull request or issue was created.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.closed_at.header': 'Closure timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.closed_at.documentation':
        'The datetime at which the item was merged or closed.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.deletions.header': 'Code lines deleted',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.deletions.documentation':
        'The number of lines of code deleted in the pull request',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.due_on.header': 'Due date timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.due_on.documentation':
        'The due date of the item. This due date takes into account resolved issues. E.g. a pull request resolving multiple issues will have a `due_on` value equals to the earliest due date.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.first_commit_at.header': 'First commit timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.first_commit_at.documentation':
        'The datetime at which the first pull request commit was made. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.first_review_submitted_at.header':
        'First review submitted timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.first_review_submitted_at.documentation':
        'The datetime at which the first pull request review was submitted. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.id.header': 'ID (source)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.label_names.header': 'List labels',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.label_names.documentation':
        'The list of labels assigned to the item. Labels are app specific and case sensitive.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_assigned_at.header': 'Last assignment timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_assigned_at.documentation':
        'The datetime at which a user was last assigned to the item. This date cannot be earlier than when the item was imported into Keypup.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_commit_at.header': 'Last commit timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_commit_at.documentation':
        'The datetime at which the last pull request commit was made. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_review_submitted_at.header':
        'Last review submitted timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.last_review_submitted_at.documentation':
        'The datetime at which the last pull request review was submitted. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.lines_changed.header': 'Code lines changed',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.lines_changed.documentation':
        'The number of lines of code added or deleted in the pull request (additions + deletions)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.mergeable.header': 'Mergeability state',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.mergeable.documentation':
        'The mergeability state of the pull request. Possible values are:\n- `MERGEABLE` the pull request can be safely merged\n- `CONFLICTING` the pull request conflicts with the base branch\n- `UNKNOWN` The mergeability state cannot be evaluated or is being evaluated',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.merged_at.header': 'Merge timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.merged_at.documentation':
        'The datetime at which the pull request was merged. The field will be `null` for issues and pull requests which have been closed without merge.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.merged_by_username.header': 'Merger username',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.merged_by_username.documentation':
        'The username of the person who merged the pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.project_name.header': 'Project name',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.project_name.documentation':
        'The name of the project associated with the issue or pull request',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.requested_reviewer_usernames.header': 'Reviewer usernames',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.requested_reviewer_usernames.documentation':
        'The list of people usernames assigned as reviewers on the pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.recommended_action.header': 'Recommended next step',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.recommended_action.documentation':
        'The action that Keypup recommends (e.g. review, merge etc.) based on the status of the item (approval status, build status etc.). Values:\n- `ASSIGN_REVIEWER`\n- `ASSIGN_MISSING_REVIEWERS`\n- `CLOSE`\n- `FIX_BUILD`\n- `FIX_CODE`\n- `IMPLEMENT`\n- `MERGE`\n- `NONE`\n- `REBASE`\n- `REVIEW`\n- `WAIT_FOR_IMPLEMENTATION`',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.recommended_actor_usernames.header':
        'Recommended actor usernames',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.recommended_actor_usernames.documentation':
        'The list of people usernames that are expected to perform the `recommended_action`. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.required_approval_count.header': 'Required approvals',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.required_approval_count.documentation':
        'The number of approvals this pull request requires before it can be considered "approved" (and merged)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.required_approval_remaining_count.header':
        'Remaining required approvals',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.required_approval_remaining_count.documentation':
        'The number of approvals still required before the pull request can be considered "approved" (and merged)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolution_state.header': 'Issue resolution state',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolution_state.documentation':
        'An indication of whether the issue has been implemented via resolving pull requests or not. This is only applicable to issues. Pull requests will always have this field set to `NONE`.\n\nResolution states can have the following values:\n- `NONE` the issue has no resolving pull requests or all the resolving pull requests have been closed (not merged).\n- `IN_PROGRESS` the issue has at least one open pull request resolving it.\n- `IMPLEMENTED` all the resolving pull requests have been merged.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolved_issue_sysids.header': 'Resolved Issue IDs (System)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolved_issue_sysids.documentation':
        'The list of issue system IDs that the pull request resolves (via auto-closing keywords).',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolving_pull_request_sysids.header':
        'Resolving PR IDs (System)',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.resolving_pull_request_sysids.documentation':
        'The list of pull request system IDs that resolve this issue (via auto-closing keywords).',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_approved_at.header': 'Review approved timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_approved_at.documentation':
        'The datetime at which the pull request review was approved. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_approved_at.header':
        'Review last approved timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_approved_at.documentation':
        'The datetime at which the pull request review was last approved. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_rejected_at.header':
        'Review last rejected timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_rejected_at.documentation':
        'The datetime at which the pull request review was last rejected. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_requested_at.header':
        'Review last requested timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_last_requested_at.documentation':
        'The datetime at which the pull request review was last requested. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_rejected_at.header': 'Review rejected timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_rejected_at.documentation':
        'The datetime at which the pull request review was rejected. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_requested_at.header': 'Review requested timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_requested_at.documentation':
        'The datetime at which the pull request review was requested. For issues the timestamp is evaluated from resolving pull requests.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_state.header': 'PR review state',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.review_state.documentation':
        'The state of the pull request review. Possible values are:\n- `APPROVED` the pull request is approved by all participating reviewers\n- `CHANGES_REQUESTED` one of the reviewers has requested changes\n- `NONE` there is no review in progress\n- `PENDING` some required reviews are still pending approval/rejection',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.state.header': 'Open/Close state',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.state.documentation':
        'The open/close state of the item. Possible values are:\n- `OPEN` the issue or pull request is still active\n- `MERGED` the pull request was merged\n- `CLOSED` the issue was closed or the pull request was closed without merge',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.suggested_merger_usernames.header':
        'Recommended actor usernames',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.suggested_merger_usernames.documentation':
        'The list of people usernames that are suggested to merge this pull request based on historical merge activity. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.title.header': 'Title/header.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.title.documentation': 'The title/name/header of the item.',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.type.header': 'Issue/PR type',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.type.documentation':
        'The type of the item. Possible values are:\n- `ISSUE` the item is an issue\n- `PULL_REQUEST` the item is pull request',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.updated_at.header': 'Last updated timestamp',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.updated_at.documentation':
        'The datetime at which the item was last updated (description, CI build, assigned milestone etc.).',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.url.header': 'Resource URL',
    'dashboarding.formula-editor.help.fields.INBOX_ITEMS.url.documentation':
        'The URL of the item in the source application',

    /* Dashboarding > Formula Editor > Fields > Reviews */
    /* The documentation entries support markdown format (used in Monaco editor) */
    'dashboarding.formula-editor.help.fields.REVIEWS._system_id.header': 'ID (system)',
    'dashboarding.formula-editor.help.fields.REVIEWS._system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.REVIEWS.author_username.header': 'Author username',
    'dashboarding.formula-editor.help.fields.REVIEWS.author_username.documentation':
        'The username of the person who created the review. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.REVIEWS.body.header': 'Review content',
    'dashboarding.formula-editor.help.fields.REVIEWS.body.documentation': 'The content of the review.',
    'dashboarding.formula-editor.help.fields.REVIEWS.created_at.header': 'Creation timestamp',
    'dashboarding.formula-editor.help.fields.REVIEWS.created_at.documentation':
        'The datetime at which the review was created.',
    'dashboarding.formula-editor.help.fields.REVIEWS.id.header': 'ID (source)',
    'dashboarding.formula-editor.help.fields.REVIEWS.id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.REVIEWS.project_name.header': 'Project name',
    'dashboarding.formula-editor.help.fields.REVIEWS.project_name.documentation':
        'The name of the project associated with the review',
    'dashboarding.formula-editor.help.fields.REVIEWS.updated_at.header': 'Last updated timestamp',
    'dashboarding.formula-editor.help.fields.REVIEWS.updated_at.documentation':
        'The datetime at which the review was last updated.',
    'dashboarding.formula-editor.help.fields.REVIEWS.url.header': 'Commit URL',
    'dashboarding.formula-editor.help.fields.REVIEWS.url.documentation':
        'The URL of the review in the source application',
    // Pull Request association
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_author_username.header': 'PR author',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_author_username.documentation':
        'The username of the person who created the issue or pull request. Note that usernames are app specific.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_created_at.header': 'PR creation',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_created_at.documentation':
        'The datetime at which the pull request or issue was created.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_closed_at.header': 'PR closure date',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_closed_at.documentation':
        'The datetime at which the item was merged or closed.',
    'dashboarding.formula-editor.help.fields.REVIEWS.due_on.header': 'PR due date',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_due_on.documentation':
        'The due date of the item. This due date takes into account resolved issues. E.g. a pull request resolving multiple issues will have a `due_on` value equals to the earliest due date.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_system_id.header': 'PR ID (system)',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_system_id.documentation':
        'The universal ID assigned to the record to uniquely identify it across all application records',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_id.header': 'PR ID (source)',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_id.documentation':
        'The ID assigned to the record by the source application',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_label_names.header': 'PR labels',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_label_names.documentation':
        'The list of labels assigned to the item. Labels are app specific and case sensitive.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_merged_at.header': 'PR merge date',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_merged_at.documentation':
        'The datetime at which the pull request was merged. The field will be `null` for issues and pull requests which have been closed without merge.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_review_state.header': 'PR review state',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_review_state.documentation':
        'The state of the pull request review. Possible values are:\n- `APPROVED` the pull request is approved by all participating reviewers\n- `CHANGES_REQUESTED` one of the reviewers has requested changes\n- `NONE` there is no review in progress\n- `PENDING` some required reviews are still pending approval/rejection',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_state.header': 'PR state',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_state.documentation':
        'The open/close state of the item. Possible values are:\n- `OPEN` the issue or pull request is still active\n- `MERGED` the pull request was merged\n- `CLOSED` the issue was closed or the pull request was closed without merge',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_title.header': 'PR title.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_title.documentation':
        'The title/name/header of the item.',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_updated_at.header': 'PR update timestamp',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_updated_at.documentation':
        'The datetime at which the item was last updated (description, CI build, assigned milestone etc.).',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_url.header': 'PR URL',
    'dashboarding.formula-editor.help.fields.REVIEWS.pull_request_url.documentation':
        'The URL of the item in the source application',

    /* Priority Inbox */
    'priority-inbox.priority': 'Priorities',
    'priority-inbox.actions.edit-custom-report': 'Edit',
    'priority-inbox.actions.edit-custom-report.disabled-help': 'Only admins can edit team reports',
    'priority-inbox.tab.filters-no-results': 'No items matching your filters',
    'priority-inbox.tab._generic_.empty': 'Your report is empty',
    'priority-inbox.tab._generic_.empty-explanation': 'Looks like this report is clear!',
    'priority-inbox.tab.prioritized': 'Prioritized',
    'priority-inbox.tab.prioritized.empty': 'Your inbox is empty',
    'priority-inbox.tab.prioritized.empty-explanation': "Looks like you're up to date on all items.",
    'priority-inbox.tab.pending-peer': 'Pending peer',
    'priority-inbox.tab.pending-peer.empty': 'No items pending action from others',
    'priority-inbox.tab.pending-peer.empty-explanation':
        'None of your items are currently requiring actions from members of your team',
    'priority-inbox.tab.unprioritized': 'Coming up',
    'priority-inbox.tab.unprioritized.empty': 'No items coming up',
    'priority-inbox.tab.unprioritized.empty-explanation': 'Horizon is clear captain! Nothing is coming up.',
    'priority-inbox.tab.closed': 'Recently closed',
    'priority-inbox.tab.closed.empty': 'No items recently closed',
    'priority-inbox.tab.closed.empty-explanation': 'No items closed within the last seven days.',
    'priority-inbox.categories': 'Categories',
    'priority-inbox.labels': 'Labels',
    'priority-inbox.projects': 'Projects',
    'priority-inbox.categories.pull_request': 'Pull Request',
    'priority-inbox.categories.issue': 'Issue',
    'priority-inbox.reviews.default-body.APPROVED': '{reviewer} approved this item',
    'priority-inbox.reviews.default-body.CHANGES_REQUESTED': '{reviewer} requested changes',
    'priority-inbox.created-by': 'Created by {author}',
    'priority-inbox.and-assigned-to': 'and currently assigned to {assignees}',
    'priority-inbox.author': 'Author',
    'priority-inbox.actors': 'Actors',
    'priority-inbox.resolution.pull_request': 'Resolves',
    'priority-inbox.resolution.issue': 'Resolved by',
    'priority-inbox.due-on': 'Due on',
    'priority-inbox.no-due-date': 'No due date',
    'priority-inbox.last-due_on': 'Last updated on <date-ll>{date}</date-ll>',
    'priority-inbox.last-updated_at': 'Last updated on <date-ll>{date}</date-ll>',
    'priority-inbox.last-created_at': 'Created on <date-ll>{date}</date-ll>',
    'priority-inbox.last-merged_at': 'Merged on <date-ll>{date}</date-ll>',
    'priority-inbox.priorities.critical': 'Critical',
    'priority-inbox.priorities.important': 'Important',
    'priority-inbox.priorities.medium': 'Medium',
    'priority-inbox.priorities.low': 'Low',
    'priority-inbox.priorities.to-be-closed': 'To be closed',
    'priority-inbox.priorities.in-progress': 'In progress',
    'priority-inbox.priorities.scheduled-for-me': 'Scheduled (me)',
    'priority-inbox.priorities.scheduled-for-other': 'Scheduled (other)',
    'priority-inbox.priorities.scheduled-unassigned': 'Scheduled',
    'priority-inbox.priorities.unprioritized': 'Backlog',
    'priority-inbox.priorities.closed': 'Closed',
    'priority-inbox.priorities.merged': 'Merged',
    'priority-inbox.due-date-tooltip.due-date': 'Due on <date-ll>{date}</date-ll> (<from-now>{date}</from-now>)',
    'priority-inbox.due-date-tooltip.no-due-date': 'No due date',
    'priority-inbox.item-priority-icon.auto': 'Auto/Reset',
    'priority-inbox.item-priority-icon.force': 'Force',
    'priority-inbox.item-priority-icon.priority-source.MANUAL': 'Manually assigned',
    'priority-inbox.item-priority-icon.priority-source.RULE': 'Matched by prioritization rule',
    'priority-inbox.item-priority-icon.priority-source.SYSTEM': 'Evaluated by Keypup',
    'priority-inbox.feed-item.unified': 'All activity',
    'priority-inbox.feed-item-types.CREATED': 'created',
    'priority-inbox.feed-item-types.COMMENTED': 'commented',
    'priority-inbox.feed-item-types.APPROVED': 'approved',
    'priority-inbox.feed-item-types.CHANGES_REQUESTED': 'requested changes',
    'priority-inbox.feed-item-types.DISMISSED': 'previously reviewed (review dismissed)',
    'priority-inbox.feed-item-types.CREATED.on': 'created',
    'priority-inbox.feed-item-types.COMMENTED.on': 'commented on',
    'priority-inbox.feed-item-types.APPROVED.on': 'approved',
    'priority-inbox.feed-item-types.CHANGES_REQUESTED.on': 'requested changes on',
    'priority-inbox.feed-item-types.DISMISSED.on': 'previously reviewed (review dismissed)',
    'priority-inbox.feed.load-more': 'See hidden comments ({count})',
    'priority-inbox.comment-input.connect-identity':
        'Click here to connect your {provider} identity and reply on this item.',
    'priority-inbox.comment-input.reply-placeholder': 'Type your comment here...',
    'priority-inbox.comment-input.submit': 'Press enter to submit',
    'priority-inbox.comment-input.demo-msg-sent':
        "Alright! You've just remotely replied on this item's discussion feed. It's not for real don't worry, this is only a demo.",

    // Priority Inbox: Notifications
    'priority-inbox.notification.connect-apps.message': 'Connect some apps to start using the service.',
    'priority-inbox.notification.connect-apps.link': 'Connect apps',
    'priority-inbox.notification.connect-projects.message': 'Import projects to see insights based on your data.',
    'priority-inbox.notification.connect-projects.link': 'Import projects',
    'priority-inbox.notification.projects-importing.message':
        '{projectsCount, plural, one {# project is} other {# projects are}} still importing. Data points may still be missing.',
    'priority-inbox.notification.projects-imported.message':
        '{projectsCount, plural, one {# project is} other {# projects are}} successfully imported.',

    // Priority Inbox: Linker Form
    'priority-inbox.inbox-item-linker.how-do-we-do.title': 'How do we link issues?',
    'priority-inbox.inbox-item-linker.how-do-we-do.content':
        'Issues get linked to pull requests using native GitHub/GitLab closing keywords. When you click save we modify the description of your pull request(s) and add a <pre>closes: https://issue-url</pre> keyword at the end of the body.{break2}We do not offer the ability to unlink issues at this stage as closing keywords may be embedded in places we prefer not to modify (e.g. comments and commits). You can always unlink issues by manually removing closing keywords on GitHub/GitLab.',
    'priority-inbox.inbox-item-linker.linked-items.pull_request': 'Linked issues',
    'priority-inbox.inbox-item-linker.linked-items.issue': 'Linked pull requests',
    'priority-inbox.inbox-item-linker.form.demo-text':
        'This section allows you to quickly link pull requests to issues and vice versa. Issue linking in Keypup leverages the native auto-linking functionalities of GitHub and GitLab.{break2}When you link issues to pull requests in your priority inbox, Keypup updates your pull request description with a closing link to the issue. This means that the linkage will be properly reflected when you view your pull request on GitHub or GitLab.',
    'priority-inbox.inbox-item-linker.form.close': 'Close',
    'priority-inbox.inbox-item-linker.form.cancel': 'Cancel',
    'priority-inbox.inbox-item-linker.form.saving': 'Saving',
    'priority-inbox.inbox-item-linker.form.save': 'Save',
    'priority-inbox.inbox-item-linker.form.input-placeholder.pull_request': 'E.g. "My issue"',
    'priority-inbox.inbox-item-linker.form.input-placeholder.issue': 'E.g. "My pull request"',
    'priority-inbox.inbox-item-linker.form.input-label.pull_request':
        'Use the search bar below to link issues to this pull request',
    'priority-inbox.inbox-item-linker.form.input-label.issue':
        'Use the search bar below to link this issue to pull requests',
    'priority-inbox.inbox-item-linker.form.input-explanation':
        'Enter search keywords then select the item to link (use arrow keys + enter for fast navigation)',
    'priority-inbox.inbox-item-linker.form.issue-might-disappear':
        '<b>Note:</b> linking this issue to a prioritized pull request will move it under the actual pull request',

    // Priority Inbox: Merger Form
    'priority-inbox.inbox-item-merger.title': 'Merge this pull request?',
    'priority-inbox.inbox-item-merger.cancel': 'Cancel',
    'priority-inbox.inbox-item-merger.merging': 'Merging',
    'priority-inbox.inbox-item-merger.merge': 'Merge',

    /* Sprint Board */
    'sprint-board.due': 'Due',
    'sprint-board.no-due-date': 'No due date',

    /* Collections */
    'collections.title': 'My collections',
    'collections.empty-resources': 'No items',
    'collections.empty-filtered-resources': 'No items match your filters',
    'collections.filters.type.placeholder': 'Filter by type',
    'collections.filters.type.dashboards': 'Dashboards',
    'collections.filters.type.widgets': 'Insights',
    'collections.filters.ownership.placeholder': 'Filter by ownership',
    'collections.filters.ownership.personal': 'Owned by me',
    'collections.filters.ownership.shared': 'Shared with me',
    'collections.view-mode.grid': 'Grid layout',
    'collections.view-mode.list': 'List layout',
    'collections.dashboard.title': 'Dashboard',

    /* Collections > List view */
    'collections.list-view.header.name': 'Name',
    'collections.list-view.header.owner': 'Owner',
    'collections.list-view.header.last-modified': 'Last modified',

    /* Collections > Actions */
    'collections.actions.delete': 'Delete',
    'collections.actions.duplicate': 'Duplicate',
    'collections.actions.pin': 'Pin',
    'collections.actions.unpin': 'Unpin',
    'collections.actions.add-insight-to-dashboard': 'Add insight to dashboard',
    'collections.actions.edit': 'Edit',
    'collections.actions.share': 'Share',

    /* Collections > Widget preview Modal */
    'collections.widget-preview-modal.title': 'Insight preview',

    /* Collections > Confirmation Modals */
    'collections.confirmation-modal.delete-dashboard.title': 'Delete this dashboard',
    'collections.confirmation-modal.delete-dashboard.content':
        'Are you sure you want to delete this dashboard?{break}This action cannot be reversed once done.',
    'collections.confirmation-modal.delete-dashboard.delete-dashboards-widgets':
        'Also delete insight(s) that <b>only</b> belong to this dashboard.',
    'collections.confirmation-modal.delete-dashboard.confirm-button': 'Delete dashboard',
    'collections.confirmation-modal.delete-dashboard.deletion-in-progress': 'Deleting dashboard...',
    'collections.confirmation-modal.delete-widget.title': 'Delete this insight',
    'collections.confirmation-modal.delete-widget.content':
        'Are you sure you want to delete this insight?{break}This action cannot be reversed once done.',
    'collections.confirmation-modal.delete-widget.confirm-button': 'Delete insight',
    'collections.confirmation-modal.delete-widget.deletion-in-progress': 'Deleting insight...',
    'collections.confirmation-modal.bulk-delete.title': 'Delete {count, plural, one {this item} other {these items}}',
    'collections.confirmation-modal.bulk-delete.content':
        'Are you sure you want to delete {count, plural, one {this item} other {these items}}?{break}This action cannot be reversed once done.',
    'collections.confirmation-modal.bulk-delete.delete-dashboards-widgets':
        'Also delete insight(s) that <b>only</b> belong to the selected {dashboardCount, plural, one {dashboard} other {dashboards}}.',
    'collections.confirmation-modal.bulk-delete.confirm-button': 'Delete {count, plural, one {item} other {items}}',
    'collections.confirmation-modal.bulk-delete.deletion-in-progress':
        'Deleting {count, plural, one {item} other {items}}...',

    /* Settings: App management */
    'settings.app-management.tab.apps': 'Connected Apps',
    'settings.app-management.tab.projects': 'Imported Projects',
    'settings.app-management.tab.identities': 'Linked identities',
    'settings.app-management.identities.disclaimer':
        'Linking an identity is only about identifying yourself in a third-party app - it <b>does not import any data</b>. To connect apps and import data for your team, switch to the <link-to-app-connect>Connected Apps</link-to-app-connect> tab',
    'settings.app-management.identities': 'Who you are in each app',
    'settings.app-management.identities.help':
        'Linking app identities allows us to know who you are in each app. This allows you to use the <pre>is me</pre> filter in your insights. These identities also allow you to login to Keypup via these apps.',
    'settings.app-management.identity-item.connected': 'Identified as <b>{username}</b>',
    'settings.app-management.identity-item.disconnected': 'Not linked',
    'settings.app-management.identity-item.disconnected-not-required':
        'Not required (app is not connected to the team)',
    'settings.app-management.identity-item.add-instance':
        'Allow access to additional organizations (OAuth flow will be triggered again)',
    'settings.app-management.identity-item.add-instance.jira':
        'Allow access to additional JIRA sites (OAuth flow will be triggered again)',
    'settings.app-management.identity-item.disconnect': 'Unlink',
    'settings.app-management.identity-item.connect': 'Link',
    'settings.app-management.identity-item.connecting': 'Linking',
    'settings.app-management.identity-item.disconnect-modal.title': 'Are you sure?',
    'settings.app-management.identity-item.disconnect-modal.body':
        'Unlinking {appName} will impact any insight using {appName} data and the <pre>is me</pre> filter as Keypup will no longer be able to identify you in this app.',
    'settings.app-management.identity-item.disconnect-modal.warning-unenroll':
        '<b>WARNING</b>: You are trying to delete the only identity attached to your account. Doing so will UNENROLL your whole account on Keypup. Please make sure this is what you want before continuing.',
    'settings.app-management.identity-item.disconnect-modal.warning-disconnection':
        '<b>ALSO</b>: You are currently logged in using {appName}. You will be logged out upon unlinking your {appName} identity.',
    'settings.app-management.identity-item.disconnect-modal.disconnecting': 'Unlinking',
    'settings.app-management.identity-item.disconnect-modal.disconnect': 'Unlink',
    'settings.app-management.identity-item.disconnect-modal.unenroll': 'Unenroll my account from Keypup',

    /* Settings: Project List */
    'settings.project-list.table.heading.project-name': 'Project',
    'settings.project-list.table.heading.owner': 'Owner',
    'settings.project-list.table.heading.status': 'Status',
    'settings.project-list.protip.jira':
        'Need to connect additional JIRA sites? <link-to-add-instance-jira>Click here</link-to-add-instance-jira>',
    'settings.project-list.help-text':
        'Tick the projects that Keypup should monitor{integrationsWithTrackNewCount, plural, =0 {} other { or {trackProjectBtn}}}',
    'settings.project-list.filter-by-account': 'Account',
    'settings.project-list.filter-by-account.all': 'All',
    'settings.project-list.filter-by-app': 'App',
    'settings.project-list.filter-by-app.all': 'All',
    'settings.project-list.filter-by-status': 'Status',
    'settings.project-list.filter-by-status.all': 'All',
    'settings.project-list.action-import': 'Import selected',
    'settings.project-list.action-importing': 'Importing...',
    'settings.project-list.action-disable': 'Disable selected',
    'settings.project-list.action-disabling': 'Disabling...',
    'settings.project-list.action-connect-apps': 'Connect some apps',
    'settings.project-list.discovering': "Stay with us! We're fetching your projects...",
    'settings.project-list.integrations-empty': 'Alright, you need to connect some apps now!',
    'settings.project-list.integrations-empty-explanation':
        'Keypup relies on importing project data from your apps to evaluate priorities and provide centralized views.{break}This page allows you to select which projects to import. But first <link-to-app-connect>you need to connect some apps</link-to-app-connect> for your team.',
    'settings.project-list.empty': 'Uh oh.. no projects here!',
    'settings.project-list.empty-explanation':
        'Looks like there are no projects to connect. Head to <link-to-app-connect>Company settings</link-to-app-connect> to connect some apps',
    'settings.project-list.filtered-empty': 'No projects match your filters',

    /* Settings: Project List Item */
    'settings.project-list-item.status.ACTIVE': 'ACTIVE',
    'settings.project-list-item.status.DISABLED': 'DISABLED',
    'settings.project-list-item.status.DISCONNECTED': 'ACCESS REVOKED',
    'settings.project-list-item.status.IMPORTING': 'Importing... ({progress}%)',
    'settings.project-list-item.status.PENDING': 'PENDING',
    'settings.project-list-item.warning-tooltip.API_THROTTLING':
        '{provider} is currently throttling our API calls. There may be delays updating data for this project.',
    'settings.project-list-item.warning-tooltip.API_UNAVAILABLE':
        '{provider} is currently returning errors on its API. Data updates will automatically resume when the API becomes available again.',
    'settings.project-list-item.warning-tooltip.WEBHOOK_UNAVAILABLE':
        'Webhooks are not available for this project due to permissions. Data will be refreshed every 20 minutes via long polling.',

    /* Settings: Project List Track Project */
    'settings.project-list.track-project.track-project': 'add a project manually',
    'settings.project-list.track-project.modal.title': 'Add a new project',
    'settings.project-list.track-project.modal.body-success':
        "Alright we're setting up {projectRef}. Give us a few seconds and it will appear in your list of followed projects. You will then be able to enable it for import.",
    'settings.project-list.track-project.modal.body1':
        "You can add projects you don't belong to by entering their details below.",
    'settings.project-list.track-project.modal.body2':
        "If you contribute to an open source project on GitHub or GitLab but don't belong to the team owning the repository, you can still track it in Keypup by manually adding it below.",
    'settings.project-list.track-project.modal.submit': 'Add project',
    'settings.project-list.track-project.modal.submitting': 'Adding project...',
    'settings.project-list.track-project.modal.close': 'Close',
    'settings.project-list.track-project.modal.form.project-reference-explanation':
        'Project reference in the form <owner-name>/<project-name>',

    /* Account Settings */
    'settings.account-settings.title': 'Account Settings',

    // Settings: User Account
    'settings.user-account.profile': 'Profile',
    'settings.user-account.basic-information': 'Basic information',
    'settings.user-account.form.name': 'Name',
    'settings.user-account.form.email': 'Email',
    'settings.user-account.form.profile-picture': 'Profile Picture',
    'settings.user-account.form.upload-picture': 'Upload your picture',
    'settings.user-account.form.edit-gravatar': 'Edit on Gravatar',
    'settings.user-account.danger-zone': 'Danger zone',
    'settings.user-account.danger-zone.title': 'Leave Keypup',
    'settings.user-account.danger-zone.description':
        'If you wish to leave Keypup, click the button below. Please be aware that all the data we collected will be removed from our servers in accordance with GDPR. This action cannot be rolled back by our staff.',

    // Settings: User Account > Unenroll section / modal

    'settings.user-account.action-unenroll': 'Unenroll',
    'settings.user-account.unenroll-modal.title': 'Permanent deletion',
    'settings.user-account.unenroll-modal.body':
        'If you proceed with this action we will disconnect all your applications and delete all your data from our servers in accordance with GDPR. <b>This action is irreversible.</b>{break2}By proceeding, you agree to deleting your data and account with no appeal possible.',
    'settings.user-account.unenroll-modal.btn-unenrolling': 'Unenrolling...',
    'settings.user-account.unenroll-modal.btn-confirm': 'Yes, unenroll my account',

    // Settings: User Account - Tabs nav
    'settings.user-account.tab.general': 'General Information',
    'settings.user-account.tab.subscription': 'Subscription',
    'settings.user-account.tab.invoices': 'Invoices',

    /* Settings: Billing & Subscription > General */
    'settings.billing.title': 'Account Settings',
    'settings.billing.tab.subscription': 'Subscription',
    'settings.billing.tab.invoices': 'Invoices',
    'settings.billing.title.past-invoices': 'Invoices',
    'settings.billing.title.billing-details': 'Billing details',
    'settings.billing.title.secured-by': 'Secured by',
    'settings.billing.form.billing-details': 'Billing details',

    /* Settings: Subscription > Subscription Presenter */
    'settings.billing.subscription-presenter.title.NO_PLAN': 'No plan',
    'settings.billing.subscription-presenter.title.FREE': 'Free',
    'settings.billing.subscription-presenter.title.START': 'Start',
    'settings.billing.subscription-presenter.title.BOOST': 'Boost',
    'settings.billing.subscription-presenter.title.GROW': 'Grow',
    'settings.billing.subscription-presenter.title.SCALE': 'Scale',
    'settings.billing.subscription-presenter.title.FLY': 'Fly',
    'settings.billing.subscription-presenter.title.ENTERPRISE': 'Enterprise',
    'settings.billing.subscription-presenter.free-trial.payments-start': 'Your payments will start',
    'settings.billing.subscription-presenter.free-trial.free-trial-ends': 'Your free trial ends',
    'settings.billing.subscription-presenter.btn-upgrade': 'Upgrade',
    'settings.billing.subscription-presenter.btn-upgrading': 'Upgrading...',
    'settings.billing.subscription-presenter.btn-downgrade': 'Cancel plan',
    'settings.billing.subscription-presenter.btn-downgrading': 'Cancelling...',
    'settings.billing.subscription-presenter.overdue':
        'Your subscription is past due. Your current plan will be cancelled if we are unable to charge a valid credit card within 7 days. Please update your credit card details below.',
    'settings.billing.subscription-presenter.pricing.FREE': '<b>${price}</b> /month',
    'settings.billing.subscription-presenter.pricing.PER_PROJECT_MONTHLY': '<b>${price}</b> /month',
    'settings.billing.subscription-presenter.pricing.PER_PROJECT_YEARLY': '<b>${price}</b> /year',
    'settings.billing.subscription-presenter.no-usage-allowed': 'Upgrade to connect projects',
    'settings.billing.subscription-presenter.free-usage-ratio': '{units} / {maxUnits} free projects',
    'settings.billing.subscription-presenter.usage-ratio.with-max': '{units} / {maxUnits} connected projects',
    'settings.billing.subscription-presenter.usage-ratio.unlimited': '{units} connected projects',
    'settings.billing.subscription-presenter.benefit-status.included': 'Included',
    'settings.billing.subscription-presenter.benefit-status.unlimited': 'Unlimited',
    'settings.billing.subscription-presenter.benefit-status.upgrade': 'Upgrade',

    // Key benefits
    'settings.billing.subscription-presenter.benefits.users': 'Users',
    'settings.billing.subscription-presenter.benefits.metrics_builder': 'Metrics Builder',
    'settings.billing.subscription-presenter.benefits.dashboard_templates': 'Dashboard templates',
    'settings.billing.subscription-presenter.benefits.contributors': 'Contributors',
    'settings.billing.subscription-presenter.benefits.dashboard_builder': 'Dashboard builder',
    'settings.billing.subscription-presenter.benefits.git_integration': 'Git integration',
    'settings.billing.subscription-presenter.benefits.historical_data': 'Historical data',
    'settings.billing.subscription-presenter.benefits.metric_widget': 'Insight templates',
    'settings.billing.subscription-presenter.benefits.project_integration': 'Project integration',

    'settings.billing.subscription-presenter.downgrade-modal.title': 'Are you sure?',
    'settings.billing.subscription-presenter.downgrade-modal.body.with-free-quota':
        'If you cancel your current plan we will automatically disable excess projects. {break2} The free plan only allows <b>{freeQuota}</b> connected projects. We recommend disabling excess projects prior to downgrading your plan.',
    'settings.billing.subscription-presenter.downgrade-modal.body.no-free-quota':
        'Cancelling your plan will <b>disconnect all currently connected projects</b>. As a result all your dashboards and insights <b><text-danger>will be empty</text-danger></b>. {break2}If you change your mind later you can always switch back to a paid plan and reconnect your projects.',
    'settings.billing.subscription-presenter.downgrade-modal.btn-downgrade': 'Cancel plan',
    'settings.billing.subscription-presenter.downgrade-modal.btn-downgrading': 'Cancelling...',

    /* Settings: Billing & Subscription > Past Invoices */
    'settings.billing.billing-invoice-list.empty': 'No past invoices',
    'settings.billing.billing-invoice-list.thead.date': 'Date',
    'settings.billing.billing-invoice-list.thead.amount': 'Amount',
    'settings.billing.billing-invoice-list.thead.invoice-number': 'Invoice Number',
    'settings.billing.billing-invoice-list.thead.status': 'Status',
    'settings.billing.billing-invoice-list.status.OPEN': 'Failed',
    'settings.billing.billing-invoice-list.status.PAID': 'Paid',
    'settings.billing.billing-invoice-list.status.UNCOLLECTIBLE': 'Cancelled',
    'settings.billing.billing-invoice-list.status.VOID': 'Cancelled',

    /* Team Management Settings */
    'settings.team-management.title': 'Team management',
    'settings.team-management.tab.team-members': 'Team & Members',
    'settings.team-management.tab.agile-settings': 'Agile settings',

    'settings.team-management.title.team-name': 'Team name',
    'settings.team-management.title.members-management': 'Team Members',

    /* Settings: Team */
    'settings.team-management.tab.members': 'Members',
    'settings.team-management.tab.sprint-settings': 'Agile Settings',
    'settings.team-management.tab.general': 'General',

    /* Settings: Team > Sprint Settings */
    'settings.team-management.team-sprint-settings.default-settings': 'Default settings',
    'settings.team-management.team-sprint-settings.help-info':
        'Use these setttings to tell Keypup how due dates should be inferred for issues. This helps us prioritize items based on your work style. Pull requests are automatically prioritized based on due dates from related issues.{break2}<b>Scrum:</b> Keypup will rely on native due dates for items. {break}E.g. Setting a milestone on a GitHub issue.{break}E.g. Creating a sprint in JIRA.{break2}<b>Kanban:</b> Keypup will start a timer when issues are assigned or created. Directly assigning due dates to issues will still work and the soonest of <pre>[assigned due date, inferred due date]</pre> will be used.{break}E.g. Issues must be resolved within 3 days after being assigned to someone.{break2}Default agile settings can be overriden at project level. So if you use scrum in a general manner but want to use Kanban for a specific project, simply override the agile settings for that one project.',
    'settings.team-management.team-sprint-settings.badge.INHERITED': 'Inherited',
    'settings.team-management.team-sprint-settings.badge.SCRUM': 'Scrum',
    'settings.team-management.team-sprint-settings.badge.KANBAN': 'Kanban | {startOn} + {duration}{durationType}',
    'settings.team-management.team-sprint-settings.non-admin': 'Only team admins are allowed to edit agile settings',
    'settings.team-management.team-sprint-settings.form.timer-duration-with.ASSIGNED_AT':
        'Once assigned, issues must be resolved within',
    'settings.team-management.team-sprint-settings.form.timer-duration-with.CREATED_AT':
        'Once created, issues must be resolved within',
    'settings.team-management.team-sprint-settings.form.timer-duration-type.DAYS': 'Days',
    'settings.team-management.team-sprint-settings.form.timer-duration-type.HOURS': 'Hours',
    'settings.team-management.team-sprint-settings.form.timer-duration-type.MINUTES': 'Minutes',
    'settings.team-management.team-sprint-settings.title': 'Due dates behaviour',
    'settings.team-management.team-sprint-settings.form.sprint-methodology': 'My team prioritizes issues by',
    'settings.team-management.team-sprint-settings.form.sprint-methodology.SCRUM': 'Assigning due dates (Scrum)',
    'settings.team-management.team-sprint-settings.form.sprint-methodology.KANBAN': 'Assigning people (Kanban)',
    'settings.team-management.team-sprint-settings.form.timer-from': 'Prioritization starts when an issue is',
    'settings.team-management.team-sprint-settings.form.timer-from.ASSIGNED_AT': 'Assigned to someone',
    'settings.team-management.team-sprint-settings.form.timer-from.CREATED_AT': 'Created',
    'settings.team-management.team-sprint-settings.action.resetting': 'Resetting...',
    'settings.team-management.team-sprint-settings.action.reset': 'Reset to global settings',
    'settings.team-management.team-sprint-settings.action.saving': 'Saving...',
    'settings.team-management.team-sprint-settings.action.save': 'Save',
    'settings.team-management.team-sprint-settings.action.cancel': 'Cancel',

    /* Settings: Team > General Settings */
    'settings.team-management.team-settings.title': 'General settings',
    'settings.team-management.team-settings.non-admin': 'Only team admins are allowed to edit team settings',
    'settings.team-management.team-settings.action.saving': 'Saving...',
    'settings.team-management.team-settings.action.save': 'Save',
    'settings.team-management.team-settings.action.cancel': 'Cancel',

    'settings.team-management.team-settings.form.name': 'Team name',

    /* Settings: Team > Members */
    'settings.team-management.actions.invite-memeber': 'Add team member',
    'settings.team-management.members-list.thead.members-name': 'Name',
    'settings.team-management.members-list.thead.email': 'Email',
    'settings.team-management.members-list.thead.role': 'Role',

    'settings.team-management.team-list.pending-invite': '(Pending invite)',
    'settings.team-management.team-list.action-invite': 'Add team member',
    'settings.team-management.team-list.invite-modal.title': 'Add team member',
    'settings.team-management.team-list.invite-modal.send': 'Send invite',
    'settings.team-management.team-list.invite-modal.sending': 'Sending...',
    'settings.team-management.team-list.invite-modal.form.email': 'Email',
    'settings.team-management.team-list.invite-modal.form.validation-error.email-invalid': 'Invalid email address',
    'settings.team-management.team-list.role.ADMIN': 'Admin',
    'settings.team-management.team-list.role.MEMBER': 'Member',
    'settings.team-management.team-list.status.DISABLED': 'DISABLED',
    'settings.team-management.team-list.invite-item.action-cancel': 'Cancel invite',
    'settings.team-management.team-list.user-item.action-switch-admin': 'Switch to admin',
    'settings.team-management.team-list.user-item.action-switch-member': 'Switch to member',
    'settings.team-management.team-list.user-item.action-switch-enabled': 'Enable',
    'settings.team-management.team-list.user-item.action-switch-disabled': 'Disable',
    'settings.team-management.team-list.user-item.action-remove': 'Remove',
    'settings.team-management.team-list.invites-empty': 'No pending invites',
    'settings.team-management.team-list.invites-empty-explanation':
        "We'll keep any pending team invites safe & sound right here!",

    /* Flows: Onboarding - Header */
    'flows.onboarding.header.stepper.app-selection': 'Connect',
    'flows.onboarding.header.stepper.project-import': 'Import',
    'flows.onboarding.header.stepper.configure-dashboard': 'Configure',
    'flows.onboarding.header.project-import-status.importing': 'Importing projects...',

    /* Flows: Onboarding - Company Creation */
    'flows.onboarding.company-creation.title': 'Welcome to <text-primary><b>Keypup</b></text-primary> !',
    'flows.onboarding.company-creation.subtitle': "Let's get to know each other a little...",
    'flows.onboarding.company-creation.form.teamname': 'Team name',
    'flows.onboarding.company-creation.form.teamname.placeholder': 'Google squad',
    'flows.onboarding.company-creation.form.next': "Let's go!",

    /* Flows: Onboarding - What's next */
    'flows.onboarding.whats-next.title': "Now, what's next?",
    'flows.onboarding.whats-next.connect-apps.title': '<text-light-turquoise>Connect</text-light-turquoise> your apps',
    'flows.onboarding.whats-next.connect-apps.description': "Let's sync your environment and your tools",
    'flows.onboarding.whats-next.import-projects.title':
        '<text-light-turquoise>Import</text-light-turquoise> your projects',
    'flows.onboarding.whats-next.import-projects.description': 'Make it real with your real projects and real data',
    'flows.onboarding.whats-next.configure-dashboard.title':
        '<text-light-turquoise>Configure</text-light-turquoise> your dashboard',
    'flows.onboarding.whats-next.configure-dashboard.description': "Let's dive in and make a shiny dashboard for you",
    'flows.onboarding.whats-next.next': "Let's get started",

    /* Flows: Onboarding - App Selection */
    'flows.onboarding.app-selection.title': '<text-primary>Connect</text-primary> your apps to Keypup',
    'flows.onboarding.app-selection.action.next': 'Continue',
    'flows.onboarding.app-selection.action.skip': 'Skip this step',
    'flows.onboarding.app-selection.info.title': "You're in control of your data",
    'flows.onboarding.app-selection.info.content':
        'When connecting an app, we do not copy, transfer or fetch your code. This step is only for identification and authentication purposes. You can choose which projects to import at the next step.',

    /* Flows: Onboarding - Project Import */
    'flows.onboarding.project-import.title': '<text-primary>Import</text-primary> your projects to Keypup',
    'flows.onboarding.project-import.action.import':
        '{projectsCount, plural, =0 {Continue} one {Import # project} other {Import # projects}}',
    'flows.onboarding.project-import.action.skip': 'Skip this step',
    'flows.onboarding.project-import.filter.placeholder': 'Owner filter',
    'flows.onboarding.project-import.list.discovering': "Stay with us! We're fetching your projects...",
    'flows.onboarding.project-import.list.empty-filter': 'No projects match your filters',
    'flows.onboarding.project-import.list.empty': "Hmm...we can't see any projects",
    'flows.onboarding.project-import.list.empty-explanation':
        "Looks like there are no repositories or projects available to connect. Either you do not have any projects in the apps you've connected or you do not have sufficient privileges to retrieve them.",
    'flows.onboarding.project-import.info.title': 'Security: Your code is safe. We do not fetch, copy or transfer.',
    'flows.onboarding.project-import.info.content':
        'Keypup does not access your code. Our engine relies solely on metadata such as pull requests, issues, milestones and labels to perform its tasks. You can disconnect your projects and apps at any time from your settings.',

    /* Flows: Onboarding - Configure Dashboard > Template selection */
    'flows.onboarding.configure-dashboard.template-selection.title':
        'Choose your <text-primary>dashboard template</text-primary>',
    'flows.onboarding.configure-dashboard.template-selection.select-template': 'Select template',

    /* Flows: Onboarding - Configure Dashboard > Template customization */
    'flows.onboarding.configure-dashboard.template-customization.title':
        '<text-primary>Configure</text-primary> your dashboard',
    'flows.onboarding.configure-dashboard.template-customization.action.finalize-setup': 'Finalize setup',

    /* Flows: Onboarding - Setup Account */
    'flows.onboarding.finalize-account.preparing': 'Preparing',
    'flows.onboarding.finalize-account.creating-account': 'Creating your account',
    'flows.onboarding.finalize-account.creating-team': 'Creating your team',
    'flows.onboarding.finalize-account.setting-up-dashboard': 'Setting up your dashboard',
    'flows.onboarding.finalize-account.workspace-ready': 'Your workspace is ready!',
    'flows.onboarding.finalize-account.description': 'Setting up your environment. This will take a few seconds…',

    /* Flows: Onboarding - Wall */
    'flows.onboarding.wall.title': 'What you can do when you <text-primary>connect projects</text-primary>',
    'flows.onboarding.wall.actions.connect-projects': 'Connect projects to use this dashboard',
    'flows.onboarding.wall.actions.book-demo': 'Book a demo with our team',

    /* Flow: Company Invite */
    'flows.company-invite.title': '{name} invites you to collaborate on Keypup!',
    'flows.company-invite.accept': 'Click to accept!',
    'flows.company-invite.footer': 'Accelerate your development',
    'flows.company-invite.error.title': 'Invalid invite',

    /* Flow: Link User */
    'flows.link-user.title': 'Link your {appName} account to {companyName} on Keypup',
    'flows.link-user.confirm': 'Click to confirm',
    'flows.link-user.confirmed': 'All good! Back to your dashboard now...',
    'flows.link-user.error.title': 'Invalid link',

    /* Flow: Company Disabled */
    'flows.company-disabled.title': 'Access to team <b>{companyName}</b> has been disabled',
    'flows.company-disabled.explanation':
        "One of the admins of team {companyName} has disabled your access or your team's plan does not allow you access anymore. Contact your administrator to be granted access again.",
    'flows.company-disabled.team-selector':
        'You can switch to another active team by clicking one of the options below:',

    /* Flow: Add Dashboard Template */
    'flows.add-dashboard-template.title': 'New dashboard from template',
    'flows.add-dashboard-template.message':
        'You are about to add the dashboard <b>{name}</b> {description, select, undefined {} other {("{description}") }}to your account.',
    'flows.add-dashboard-template.select-team': 'This dashboard will be added under team',
    'flows.add-dashboard-template.action.add': 'Add to my account',
    'flows.add-dashboard-template.action.adding': 'Adding dashboard...'
};

export default mainMessages;
