import React, { FunctionComponent, Fragment, useMemo } from 'react';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { ErrorFragment } from 'components/ErrorManagement';
import { APP_MANAGEMENT_PROJECTS_PATH } from 'constants/routeBuilders';
import { APP_CATEGORIES_WITH_PROJECTS } from 'constants/defaultValues';
import { AppResp } from 'api/hq/queries/App';
import { useApps } from 'api/hq/hooks/useApps';
import { useSearchParams } from 'react-router-dom';
import { sortBy, uniq } from 'lodash';
import Table from 'react-bootstrap/Table';
import { RichMessage } from 'components/RichMessage';
import FullPageLoader from 'components/Design/FullPageLoader';
import AppConnectRowItem from 'components/AppConnectItem/AppConnectRowItem';

// Return the URL where users should be redirected after connecting an app.
const postConnectRedirect = (app: AppResp): string | undefined => {
    if (!APP_CATEGORIES_WITH_PROJECTS.includes(app.category)) return undefined;

    return `${APP_MANAGEMENT_PROJECTS_PATH}?app=${app.provider}`;
};

const AppConnectManagement: FunctionComponent = () => {
    // Services
    const [searchParams] = useSearchParams();

    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Check if alpha apps should be displayed
    const includeAlpha = !!searchParams.get('alpha');

    // Fetch apps data
    const { apps, loading: appLoading, error: appErrors } = useApps({ alpha: includeAlpha });

    // Extract list of categories
    const appCategories = useMemo(() => uniq(apps.map(e => e.category)).sort(), [apps]);

    // Get sorted list of apps
    const sortedApps = useMemo(() => sortBy(apps, 'name'), [apps]);

    // Display loading screen if the initial query is loading
    if (appLoading || !company) return <FullPageLoader vh={75} />;

    // Display error message
    if (appErrors || !apps) {
        return <ErrorFragment error={{ code: 'fetch-apps', namespace: 'fetch-company-account' }} />;
    }

    return (
        <>
            {appCategories.map(category => {
                return (
                    <div key={category} className="mb-4">
                        <Table className="align-middle">
                            <thead>
                                <tr>
                                    <th colSpan={4}>
                                        <h6 className="text-uppercase text-grey-4">
                                            <RichMessage id={`labels.app-categories.${category}`} />
                                        </h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedApps
                                    .filter(e => e.category === category)
                                    .map(app => (
                                        <AppConnectRowItem
                                            key={app.id}
                                            company={company}
                                            app={app}
                                            errorNamespace="fetch-company-account"
                                            postConnectRedirectUri={postConnectRedirect(app)}
                                        />
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                );
            })}
        </>
    );
};

export default AppConnectManagement;
