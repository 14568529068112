import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { WidgetType } from 'components/Dashboarding/Models/Widget';
import { GetWidgetResp, GET_WIDGET, SUBSCRIBE_TO_WIDGET_DELETED, SUBSCRIBE_TO_WIDGET_UPDATED } from '../queries/Widget';
import omitDeep from 'omit-deep-lodash';
import { useMemo } from 'react';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';

interface QueryOpts {
    id?: string;
    skip?: boolean;
}

interface WidgetQueryResults extends QueryResult<GetWidgetResp, OperationVariables> {
    normalized?: WidgetType;
}

export function useWidget({ id, skip }: QueryOpts): WidgetQueryResults {
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve the widget by ID
    const rs = useQuery<GetWidgetResp>(GET_WIDGET, {
        skip: skip || !id,
        variables: { id }
    });

    // Subscribe to widget update
    useSafeSubscription(SUBSCRIBE_TO_WIDGET_UPDATED, {
        variables: { companyId: company?.id },
        skip: skip || !company?.id,
        onSubscriptionData: sub => {
            if (sub.subscriptionData.data?.widgetUpdated?.id == id) rs.refetch();
        }
    });

    // Subscribe to widget deletion
    useSafeSubscription(SUBSCRIBE_TO_WIDGET_DELETED, {
        variables: { companyId: company?.id },
        skip: skip || !company?.id,
        onSubscriptionData: sub => {
            if (sub.subscriptionData.data?.widgetUpdated?.id == id) rs.refetch();
        }
    });

    // Extract the widget
    const normalized = useMemo(() => {
        const obj = rs.data?.widget;
        return obj ? (omitDeep(obj, '__typename') as WidgetType) : undefined;
    }, [rs.data?.widget]);

    return { ...rs, loading: rs.loading, normalized: normalized };
}
