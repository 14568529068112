export const CURRENT_COMPANY_ID_KEY = 'currentCompanyId';
export const CURRENT_API_USER_TOKEN_KEY = 'currentApiUserToken';
export const CURRENT_LANGUAGE_KEY = 'currentLanguage';

export const ONBOARDING_ASSISTANT_DSHB_TMPL_KEY = 'onboarding-assistant-dshb-tpml';
export const ONBOARDING_ASSISTANT_DSHB_QNS_KEY = 'onboarding-assistant-dshb-qns';

export const ASSISTANT_DSHB_TMPL_KEY = 'assistant-dshb-tmpl';
export const ASSISTANT_DSHB_QNS_KEY = 'assistant-dshb-qns';

export const ASSISTANT_WIDGET_TMPL_KEY = 'assistant-widget-tmpl';
export const ASSISTANT_WIDGET_QNS_KEY = 'assistant-widget-qns';

export const COLLECTIONS_VIEW_MODE_KEY = 'collections-view-mode';
