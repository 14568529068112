import React, { FunctionComponent, useContext } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { RichMessage } from 'components/RichMessage';
import { QueryOpFact } from '../DataSource';
import { useFilteredRecords } from '../Hooks/useFilteredRecords';
import { DrilldownContext } from 'contexts/DrilldownContext';

interface Props {
    fact: QueryOpFact;
    filters?: RuleGroupType;
    drilldownEnabled?: boolean;
}

export const FiltersRatio: FunctionComponent<Props> = ({ fact, filters, drilldownEnabled }: Props) => {
    // Retrieve potentiel coordinates from drilldown context
    const { coordinates } = useContext(DrilldownContext);

    // Query filtered/total records
    const {
        filteredRecordsCount,
        totalRecordsCount,
        filteredRecordsLoading,
        totalRecordsLoading
    } = useFilteredRecords({ fact, filters, pickedCoordinates: drilldownEnabled ? coordinates : undefined });

    return (
        <>
            {filteredRecordsLoading ? '-' : filteredRecordsCount}/{totalRecordsLoading ? '-' : totalRecordsCount}&nbsp;
            <RichMessage id="dashboarding.widget-editor.filter-box.records-text" />
        </>
    );
};
