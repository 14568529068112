import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { RICH_TAGS } from './RichMessageConfig';

export interface RichMessageProps {
    id: string;
    values?: object;
}

// Wrapper component used to inject common HTML tags in translations
// This allows us to define a kind of "meta language" for translation keys
// with commonly used HTML tags (bold, italic, pre etc.)
export const RichMessage: FunctionComponent<RichMessageProps> = ({ id, values }: RichMessageProps) => {
    return <FormattedMessage id={id} values={{ ...RICH_TAGS, ...values }} />;
};
