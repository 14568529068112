import {
    QUERY_OP_FILTER_FUNCTIONS,
    QUERY_OP_DEFAULT_ENUM_VALUES,
    QueryOpEnum,
    QueryOpFilterDisplayType
} from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFieldLookup } from 'components/Dashboarding/Hooks/useQueryOpFieldLookup';
import { RulesetBuilderContext } from 'components/RulesetBuilder/RulesetBuilder';
import { DateValue, RuleValue, RuleValueType } from 'components/RulesetBuilder/useRulesetValidator';
import SelectCompanyUser from 'components/RulesetBuilder/RulesetValueField/SelectCompanyUser';
import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react';
import { ValueEditorProps } from 'react-querybuilder';
import DatePickerValueField from './DatePickerValueField';
import RadioValueField from './RadioValueField';
import DateRangePickerValueField from './DateRangePickerValueField';
import SelectValueField from './SelectValueField';
import TimePeriodValueField from './TimePeriodValueField';
import { ComplexValue, DateRangeValue, TimePeriodValue } from '..';
import InputValueField from './InputValueField';
import classNames from 'classnames';

export interface RulesetValueFieldProps {
    drilldownEnabled?: boolean;
}

export const RulesetValueField: FunctionComponent<ValueEditorProps> = ({
    operator,
    value,
    handleOnChange,
    title,
    className,
    field,
    context
}: ValueEditorProps) => {
    // State
    const [localValue, setLocalValue] = useState<RuleValue>(value);

    // Extract contextual information
    const { fact, drilldownEnabled } = context as RulesetBuilderContext;

    // Retrieve field configuration
    const fieldConfig = useQueryOpFieldLookup({ fact, fieldUri: field });

    // Extract value formatting config
    const operatorConfig = useMemo(() => QUERY_OP_FILTER_FUNCTIONS.find(e => e.name === operator), [operator]);
    const displayType: QueryOpFilterDisplayType =
        operatorConfig?.displayType || fieldConfig?.filterDisplayType || 'text';

    const values = useMemo(() => operatorConfig?.values || fieldConfig?.filterValues || QUERY_OP_DEFAULT_ENUM_VALUES, [
        operatorConfig?.values,
        fieldConfig?.filterValues
    ]);

    // Get value and value type
    const valueType = operatorConfig?.type || fieldConfig?.type || 'string';

    // Invoke parent hook
    const onValueChange = useCallback(
        (val: string | number | boolean | ComplexValue | undefined, pickedCoordinateIndex?: number): void => {
            handleOnChange({
                type: valueType as RuleValueType,
                value: val,
                pickedCoordinateIndex: pickedCoordinateIndex
            });
        },
        [handleOnChange, valueType]
    );

    // Resync local state when parent gets updated
    useEffect(() => setLocalValue(value), [value]);

    // Render value editor based on type
    switch (displayType) {
        case 'no-arg':
            // No input required for imperative operators
            return null;
        case 'select':
            return (
                <SelectValueField
                    className={className}
                    title={title}
                    onChange={onValueChange}
                    value={localValue?.value as string}
                    values={values as QueryOpEnum[]}
                    pickedCoordinateIndex={localValue?.pickedCoordinateIndex}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        case 'select-company-user':
            return (
                <SelectCompanyUser
                    className={className}
                    title={title}
                    onChange={onValueChange}
                    value={localValue?.value as string}
                />
            );
        case 'checkbox':
            return (
                <input
                    type="checkbox"
                    className={className}
                    title={title}
                    onChange={e => onValueChange(e.target.checked)}
                    checked={localValue?.value as boolean}
                />
            );
        case 'radio':
            return (
                <RadioValueField
                    className={className}
                    title={title}
                    value={localValue?.value as string}
                    values={values as QueryOpEnum[]}
                    onChange={onValueChange}
                />
            );
        case 'datepicker':
            return (
                <DatePickerValueField
                    className={classNames(className, { 'border-end-0': drilldownEnabled })}
                    value={localValue?.value as DateValue}
                    onChange={onValueChange}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        case 'daterangepicker':
            return (
                <DateRangePickerValueField
                    className={classNames(className, { 'border-end-0': drilldownEnabled })}
                    value={localValue?.value as DateRangeValue}
                    onChange={onValueChange}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        case 'timeperiod':
            return (
                <TimePeriodValueField
                    className={className}
                    value={localValue?.value as TimePeriodValue}
                    values={values as QueryOpEnum[][]}
                    onChange={onValueChange}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        case 'timeperiod-with-offset':
            return (
                <TimePeriodValueField
                    className={className}
                    value={localValue?.value as TimePeriodValue}
                    values={values as QueryOpEnum[][]}
                    onChange={onValueChange}
                    withOffset={true}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        case 'number':
        case 'text':
        case 'text-list':
            return (
                <InputValueField
                    className={className}
                    title={title}
                    inputType={displayType}
                    value={localValue?.value as string}
                    onChange={onValueChange}
                    pickedCoordinateIndex={localValue?.pickedCoordinateIndex}
                    drilldownEnabled={drilldownEnabled}
                />
            );
        default:
            return null;
    }
};
