import {
    PRIMARY_DARK_BLUE,
    SECONDARY_GREY_BLUE,
    SECONDARY_ANIS,
    PRIMARY_NORMAL_BLUE,
    SECONDARY_PINK,
    PRIMARY_NORMAL_PURPLE,
    SECONDARY_ORANGE,
    PRIMARY_TWILIGHT_BLUE
} from 'constants/colors';
import gql from 'graphql-tag';
import { round } from 'lodash';
import { MutationResp } from './Shared';

// ================================================
// Subscription pricing types
// ================================================
export const BILLING_SUBSCRIPTION_REF_FREE = 'FREE';
export const BILLING_SUBSCRIPTION_REF_PAID = ['PER_PROJECT_MONTHLY', 'PER_PROJECT_YEARLY'] as const;
export const BILLING_SUBSCRIPTION_REFS = [BILLING_SUBSCRIPTION_REF_FREE, ...BILLING_SUBSCRIPTION_REF_PAID] as const;
export type BillingSubscriptionReferences = typeof BILLING_SUBSCRIPTION_REFS[number];

// ================================================
// Subscription tiers
// ================================================
export const BILLING_SUBSCRIPTION_TIER_NO_PLAN = 'NO_PLAN';
export const BILLING_SUBSCRIPTION_TIERS = [
    BILLING_SUBSCRIPTION_TIER_NO_PLAN,
    BILLING_SUBSCRIPTION_REF_FREE,
    'START',
    'BOOST',
    'GROW',
    'SCALE',
    'FLY',
    'ENTERPRISE'
] as const;

export type BillingSubscriptionTierName = typeof BILLING_SUBSCRIPTION_TIERS[number];
export interface BillingSubscriptionTierConfig {
    monthlyPrice: number | ((units: number) => number);
    yearlyPrice: number | ((units: number) => number);
    maxQuantity?: number;
    color: string;
    upgradeBtnVariant?: 'primary' | 'secondary' | 'dark'; // should be a bootstrap-supported color
}
export interface BillingSubscriptionTier {
    name: BillingSubscriptionTierName;
    price: number;
    quantity: number;
    config: BillingSubscriptionTierConfig;
}

// The configuration of the tiers. The maxQuantity is the number of allowed paid plans.
// The legacy FREE plan (3 free projects) has a maxQuantity set to zero because the number of
// free projects is now controlled at a subscription level via the freeQuota attribute.
export const BILLING_SUBSCRIPTION_TIER_CONFIGS: {
    [key in BillingSubscriptionTierName]: BillingSubscriptionTierConfig;
} = {
    NO_PLAN: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        maxQuantity: 0,
        color: PRIMARY_DARK_BLUE,
        upgradeBtnVariant: 'secondary'
    },
    FREE: { monthlyPrice: 0, yearlyPrice: 0, maxQuantity: 0, color: SECONDARY_GREY_BLUE },
    START: { monthlyPrice: 9, yearlyPrice: 86, maxQuantity: 3, color: SECONDARY_ANIS },
    BOOST: { monthlyPrice: 99, yearlyPrice: 950, maxQuantity: 25, color: PRIMARY_NORMAL_BLUE },
    GROW: { monthlyPrice: 299, yearlyPrice: 2870, maxQuantity: 50, color: SECONDARY_PINK },
    SCALE: { monthlyPrice: 499, yearlyPrice: 4790, maxQuantity: 100, color: PRIMARY_NORMAL_PURPLE },
    FLY: { monthlyPrice: 999, yearlyPrice: 9590, maxQuantity: 300, color: SECONDARY_ORANGE },
    ENTERPRISE: {
        monthlyPrice: (units: number) => round(units * 3.33, 2),
        yearlyPrice: (units: number) => round(units * 31.96, 2),
        color: PRIMARY_TWILIGHT_BLUE
    }
};

// ================================================
// Billing Card statuses
// ================================================
export const BILLING_CARD_SETUP_STATUS_REQUIRES_ACTION = 'REQUIRES_ACTION';
export const BILLING_CARD_SETUP_STATUS_SUCCEEDED = 'SUCCEEDED';
type BillingCardSetupStatuses =
    | 'CANCELED'
    | 'PROCESSING'
    | typeof BILLING_CARD_SETUP_STATUS_REQUIRES_ACTION
    | 'REQUIRES_CONFIRMATION'
    | 'REQUIRES_PAYMENT_METHOD'
    | typeof BILLING_CARD_SETUP_STATUS_SUCCEEDED;

// ================================================
// Subscription statuses
// ================================================
export const BILLING_SUBSCRIPTION_STATUS_OVERDUE = 'PAST_DUE';
type BillingSubscriptionStatuses =
    | 'ACTIVE'
    | 'CANCELED'
    | 'INCOMPLETE'
    | 'INCOMPLETE_EXPIRED'
    | typeof BILLING_SUBSCRIPTION_STATUS_OVERDUE
    | 'TRIALING'
    | 'UNPAID';

// ================================================
// Billing statuses
// ================================================
export interface BillingCardResp {
    brand: string;
    email: string;
    expMonth: number;
    expYear: number;
    last4: string;
    name: string;
    paymentMethodId: string;
    setupStatus: BillingCardSetupStatuses;
}

export interface BillingSubscriptionResp {
    id: string;
    billingCard?: BillingCardResp;
    chargedQuantity: number;
    companyId: string;
    freeQuota: number;
    freeTrialEndAt: string;
    pricingQuantity: number;
    status: BillingSubscriptionStatuses;
    subscriptionRef: BillingSubscriptionReferences;
}

export interface BillingSubscriptionWithTier extends BillingSubscriptionResp {
    tier: BillingSubscriptionTier;
}

export interface BillingCardVerificationResp {
    paymentMethodId: string;
    setupStatus: BillingCardSetupStatuses;
    setupSecret: string;
}

export interface BillingSubscriptionUpdateResp {
    id: string;
    status: BillingSubscriptionStatuses;
    billingCard?: BillingCardVerificationResp;
}

export interface BillingSubscriptionPaymentResp {
    clientSecret: string;
    createdAt: string;
    status: string;
}

export interface GetBillingSubscriptionResp {
    billingSubscription: BillingSubscriptionResp;
}

export interface AttachBillingCardResp {
    attachBillingCard: MutationResp & {
        billingSubscription: BillingSubscriptionUpdateResp;
    };
}

export interface SwitchBillingSubscriptionPlanResp {
    switchBillingSubscriptionPlan: MutationResp & {
        billingSubscription: BillingSubscriptionUpdateResp;
    };
}

export const GET_BILLING_SUBSCRIPTION = gql`
    query($companyId: ID) {
        billingSubscription(companyId: $companyId) {
            id
            status
            freeQuota
            freeTrialEndAt
            pricingQuantity
            chargedQuantity
            billingCard {
                brand
                email
                expMonth
                expYear
                last4
                name
                paymentMethodId
                setupStatus
            }
            companyId
            subscriptionRef
        }
    }
`;

export const SUBSCRIBE_TO_BILLING_SUBSCRIPTION_UPDATED = gql`
    subscription($companyId: ID!) {
        billingSubscriptionUpdated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_BILLING_SUBSCRIPTION_CREATED = gql`
    subscription($companyId: ID!) {
        billingSubscriptionCreated(companyId: $companyId) {
            id
        }
    }
`;

export const ATTACH_BILLING_CARD = gql`
    mutation($companyId: ID!, $paymentMethodId: String!) {
        attachBillingCard(input: { companyId: $companyId, paymentMethodId: $paymentMethodId }) {
            success
            billingSubscription {
                id
                status
                billingCard {
                    paymentMethodId
                    setupStatus
                    setupSecret
                }
            }
            errors {
                path
                message
                code
            }
        }
    }
`;

export const SWITCH_BILLING_SUBSCRIPTION_PLAN = gql`
    mutation($companyId: ID!, $subscriptionRef: BillingSubscriptionRefEnum!) {
        switchBillingSubscriptionPlan(input: { companyId: $companyId, subscriptionRef: $subscriptionRef }) {
            success
            billingSubscription {
                id
                status
                billingCard {
                    paymentMethodId
                    setupStatus
                    setupSecret
                }
            }
            errors {
                path
                message
                code
            }
        }
    }
`;
