import { useFetchSeries } from 'components/Dashboarding/Hooks/useFetchSeries';
import {
    isChartWidget,
    isKpiWidget,
    SeriesNDimensionsNMetrics,
    WidgetChartType,
    WidgetKpiType
} from 'components/Dashboarding/Models/Widget';
import {
    KPI_TREND_SERIES_INDEX,
    CHART_SERIES_INDEX,
    DIMENSION_IDENTIFIER,
    METRIC_IDENTIFIER
} from 'constants/defaultValues';
import { DrilldownContext, PickableData } from 'contexts/DrilldownContext';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';

interface Props {
    widget: WidgetChartType | WidgetKpiType;
}

// This hook is responsible for initializing needed data for the drilldown feature,
// in the drilldown context (such as the first record coordinates or the pickable data).
export const useDrilldownContext = ({ widget }: Props): void => {
    // Drilldown context
    const {
        firstRecordCoordinates: ctxFirstRecordCoordinates,
        setFirstRecordCoordinates,
        setPickableData
    } = useContext(DrilldownContext);

    // Series index to target, depending on the widget type
    const seriesIndex = useMemo(() => {
        if (!widget) return;

        return isKpiWidget(widget) ? KPI_TREND_SERIES_INDEX : CHART_SERIES_INDEX;
    }, [widget]);

    // Series to target
    const series = useMemo(
        () =>
            seriesIndex != undefined
                ? ({ ...widget?.config?.series?.[seriesIndex], limit: 1 } as SeriesNDimensionsNMetrics)
                : undefined,
        [seriesIndex, widget]
    );

    // Perform query
    const { records, initialLoading, reloading } = useFetchSeries({
        fact: widget?.config?.fact || 'INBOX_ITEMS',
        series,
        skip: !widget || !series || (!isChartWidget(widget) && !isKpiWidget(widget))
    });

    // Extract the first record coordinates
    const firstRecordCoordinates = useMemo(() => {
        // Abort if no series, no records or if records are loading
        if (!series || !records || !records[0] || initialLoading || reloading) return;

        // Build series types array (e.g. ['d0', 'd1', 'm0'])
        const seriesTypes = [
            ...series.dimensions.map((e, index) => `${DIMENSION_IDENTIFIER}${index}`),
            ...series.metrics.map((e, index) => `${METRIC_IDENTIFIER}${index}`)
        ];

        // Return first record values
        return seriesTypes.map(e => records[0][e]);
    }, [initialLoading, records, reloading, series]);

    // Update first record coordinates in drilldown context
    useEffect(() => {
        if (!records || initialLoading || reloading) return;

        // If first record coordinates are not already defined or they have changed
        if (
            !firstRecordCoordinates ||
            (ctxFirstRecordCoordinates && isEqual(ctxFirstRecordCoordinates, firstRecordCoordinates))
        )
            return;

        // Update drilldown context
        setFirstRecordCoordinates(firstRecordCoordinates);
    }, [
        records,
        initialLoading,
        reloading,
        firstRecordCoordinates,
        ctxFirstRecordCoordinates,
        setFirstRecordCoordinates
    ]);

    // Update pickable data in drilldown context
    useEffect(() => {
        // Abort if no series
        if (!series) return;

        // Build pickable data
        const pickableDimensions =
            series.dimensions?.map((e, index) => ({
                id: `{{dimension${index + 1}}}`,
                type: 'dimension',
                index: index + 1,
                label: e.function == 'FORMULA' ? 'FORMULA' : e.ref.toUpperCase()
            })) ?? [];
        const pickableMetrics =
            series.metrics?.map((e, index) => ({
                id: `{{metric${index + 1}}}`,
                type: 'metric',
                index: index + 1,
                label: e.function.toUpperCase()
            })) ?? [];

        const pickableData: PickableData[] = [...pickableDimensions, ...pickableMetrics];

        // Update drilldown context
        setPickableData(pickableData);
    }, [series, setPickableData]);
};
