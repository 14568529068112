import React, { FunctionComponent, useState, Fragment } from 'react';
import { GithubMarkdown } from 'components/TextProcessor';
import { FeedItem } from 'api/viz/queries/InboxItem';
import Moment from 'react-moment';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { stripMarkdown } from 'util/StringOperators';
import ItemTitle from '../ItemTitle';
import ItemUserIcon from '../ItemUserIcon';

const DEFAULT_COMMENT_TYPE = 'COMMENTED';

const TYPE_COLOR_MAPPING: { [key: string]: string } = {
    CREATED: 'text-muted',
    COMMENTED: 'text-muted',
    APPROVED: 'text-success',
    CHANGES_REQUESTED: 'text-danger',
    DISMISSED: 'text-muted'
};

interface Props {
    item: FeedItem;
    includeName?: boolean;
}

const InboxFeedItem: FunctionComponent<Props> = ({ item, includeName }: Props) => {
    const [bodyExpanded, setBodyExpanded] = useState<boolean>(false);
    const itemType = item.state || DEFAULT_COMMENT_TYPE;

    return (
        // Click folded item to expand it
        <div
            className="d-flex px-3 py-3 mb-2 rounded bg-grey-6 bg-opacity-10"
            onClick={() => bodyExpanded || setBodyExpanded(true)}
            role={bodyExpanded ? undefined : 'button'}
        >
            {/* User Icon */}
            <div>
                <ItemUserIcon user={item.author} />
            </div>

            {/* Comment content and metadata */}
            <div className="ms-2 w-95">
                {/* Item Header */}
                <div className="mb-2 d-flex text-dark">
                    {/* Author & Item Type */}
                    <a className="hover-underline" href={item.url} target="_blank" rel="noopener noreferrer">
                        <span className="font-weight-bold">{item.author.username}</span>

                        {/* 
                            We fragments and raw items so as to only deal with spans and make
                            the hover-underline work on all elements inside the <a> tag 
                        */}
                        {includeName && item.parent ? (
                            <>
                                {' '}
                                <span className={classNames('fw-bold', TYPE_COLOR_MAPPING[itemType])}>
                                    <FormattedMessage id={`priority-inbox.feed-item-types.${itemType}.on`} />{' '}
                                </span>
                                <ItemTitle
                                    item={item.parent}
                                    tooltip={true}
                                    short={true}
                                    tooltipShowDelay={1000}
                                    className={classNames('fw-bold', TYPE_COLOR_MAPPING[itemType])}
                                />
                            </>
                        ) : (
                            <>
                                {' '}
                                <span className={classNames('fw-bold', TYPE_COLOR_MAPPING[itemType])}>
                                    <FormattedMessage id={`priority-inbox.feed-item-types.${itemType}`} />
                                </span>
                            </>
                        )}
                    </a>

                    {/* Date - Also used as clickable area to collapse the comment */}
                    <div
                        className="d-flex align-items-center text-light fs-sm flex-grow-1"
                        onClick={() => bodyExpanded && setBodyExpanded(!bodyExpanded)}
                        role={bodyExpanded ? 'button' : undefined}
                    >
                        <span className="mx-2 fs-6">{'•'}</span>
                        <span className="fw-bold">
                            <Moment format="ll">{item.submitted_at}</Moment>
                        </span>
                        <span className="ms-1">
                            {' ('}
                            <Moment fromNow>{item.submitted_at}</Moment>
                            {')'}
                        </span>
                    </div>
                </div>

                {/* Item Content */}
                {bodyExpanded ? (
                    // Full content (expanded)
                    <div>
                        <div className="markdown">
                            <GithubMarkdown markup={item.body} flavor={item.parent?.project?.provider} />
                        </div>
                    </div>
                ) : (
                    // Content preview
                    <div className="text-truncate opacity-50">{stripMarkdown(item.body)}</div>
                )}
            </div>
        </div>
    );
};

export default InboxFeedItem;
