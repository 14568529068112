import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyResp } from 'api/hq/queries/Company';
import { SprintSettings } from 'api/hq/queries/Shared';
import { RichMessage } from 'components/RichMessage';
import { isEqual } from 'lodash';
import React, { FunctionComponent, ReactNodeArray } from 'react';
import Badge from 'react-bootstrap/Badge';
import { APP_IMAGES } from 'constants/defaultValues';
import { faBuilding, faUser } from '@fortawesome/free-regular-svg-icons';
import AppIcon from 'components/Design/AppIcon';

interface DisplayOpts {
    ownerType?: string;
    ownerName?: string;
    app?: string;
    appName?: string;
}

interface Props {
    company: CompanyResp;
    name?: string | ReactNodeArray;
    sprintSettings: SprintSettings;
    showBadge?: boolean;
    displayOpts?: DisplayOpts;
}

const SprintSettingsItem: FunctionComponent<Props> = ({
    company,
    name,
    sprintSettings,
    showBadge = false,
    displayOpts = {}
}: Props) => {
    // Infer state
    const isInherited = isEqual(sprintSettings, company.sprintSettings);
    const displayMethodology = isInherited ? 'INHERITED' : sprintSettings.methodology;
    const statusColor =
        displayMethodology === 'INHERITED' ? 'light' : displayMethodology === 'SCRUM' ? 'primary' : 'secondary';
    const duration = sprintSettings.timerDuration || 0;
    const durationType = duration % (24 * 60) === 0 ? 'd' : duration % 60 === 0 ? 'h' : 'm';
    const durationDisplay =
        durationType === 'd' ? duration / (24 * 60) : durationType === 'h' ? duration / 60 : duration;

    // Display options
    const ownerIcon = displayOpts.ownerType
        ? displayOpts.ownerType === 'ORGANIZATION'
            ? faBuilding
            : faUser
        : undefined;
    const appImg = displayOpts.app ? APP_IMAGES[displayOpts.app] : undefined;

    return (
        <div className="d-flex row align-items-center w-100">
            {/* Project name */}
            <p className="col overflow-wrap fw-bold flex-grow-1 m-0 text-dark">{name}</p>

            {/* Owner */}
            {ownerIcon && displayOpts.ownerName && (
                <div className="col flex-grow-1 text-dark">
                    <FontAwesomeIcon icon={ownerIcon} className="me-2" />
                    <span>{displayOpts.ownerName}</span>
                </div>
            )}

            {/* App name */}
            {appImg && displayOpts.appName && (
                <div className="col text-dark d-flex align-items-center flex-grow-1">
                    <AppIcon src={appImg} alt={displayOpts.appName} className="me-3" />
                    <span>{displayOpts.appName}</span>
                </div>
            )}

            {/* Status */}
            <div className="col d-flex align-items-center flex-grow-1">
                {showBadge && (
                    <Badge className={`bg-${statusColor} bg-opacity-10`} text={statusColor} pill>
                        <RichMessage
                            id={`settings.team-management.team-sprint-settings.badge.${displayMethodology}`}
                            values={{
                                startOn: sprintSettings.timerStartOn?.toLowerCase(),
                                duration: durationDisplay,
                                durationType: durationType
                            }}
                        />
                    </Badge>
                )}
            </div>
        </div>
    );
};

export default SprintSettingsItem;
