import React, { FunctionComponent, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { appConnectionPath } from 'constants/routeBuilders';
import { INTEGRATION_ACTIVE_STATUS, INTEGRATION_DISCOVERING_STATUSES, APP_FEATURES } from 'constants/defaultValues';
import { CompanyState } from 'redux/auth/reducer';
import { RichTooltip, useRichIntl } from 'components/RichMessage';
import { AppResp } from 'api/hq/queries/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    status: string;
    app: AppResp;
    company: CompanyState;
    postConnectRedirectUri?: string;
    skipDiscoveryStatus?: boolean;
    className?: string;
}

const AppAddInstanceBtn: FunctionComponent<Props> = ({
    status,
    app,
    company,
    postConnectRedirectUri,
    skipDiscoveryStatus,
    className
}: Props) => {
    // Services
    const [isReconnecting, setIsReconnecting] = useState<boolean>(false);
    const intl = useRichIntl();

    // Extract data
    const isAdmin = company.role === 'ADMIN';
    const connectLink = appConnectionPath({
        provider: app.provider,
        companyId: company.id,
        returnTo: postConnectRedirectUri,
        reconnect: true
    });

    // Evaluate real status used for display
    // if skipDiscoveryStatus is set to true the integration will be considered as 'ACTIVE' even
    // if it is still discovering
    const realStatus =
        skipDiscoveryStatus && INTEGRATION_DISCOVERING_STATUSES.includes(status) ? INTEGRATION_ACTIVE_STATUS : status;

    // Evaluate tooltip message based on context
    const tooltipForbiddenId = 'components.app-connect-item.action-forbidden';
    const tooltipAddInstanceId = 'components.app-connect-item.action-add-instance';
    const tooltipAddInstanceAppSpecificId = `${tooltipAddInstanceId}.${app.provider}`;
    const tooltipMsgId = isAdmin
        ? intl.messages[tooltipAddInstanceAppSpecificId]
            ? tooltipAddInstanceAppSpecificId
            : tooltipAddInstanceId
        : tooltipForbiddenId;

    // Action performed when connecting an app
    const connectApp = useCallback(() => {
        setIsReconnecting(true);
        window.location.href = connectLink;
    }, [connectLink]);

    // Abort if the app does not support the add instance feature
    if (!APP_FEATURES[app.provider]?.addInstance) return null;

    // Abort if the app is not active
    if (realStatus !== INTEGRATION_ACTIVE_STATUS) return null;

    return (
        <RichTooltip id={tooltipMsgId} placement="bottom">
            <Button
                variant="dark"
                onClick={connectApp}
                disabled={!isAdmin || isReconnecting}
                track-id={`click-app-add-instance-${app.provider}`}
                className={className}
            >
                {isReconnecting ? (
                    <Spinner animation="border" size="sm" variant="primary" />
                ) : (
                    <FontAwesomeIcon icon={faPlus} />
                )}
            </Button>
        </RichTooltip>
    );
};

export default AppAddInstanceBtn;
