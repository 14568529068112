import { WidgetType } from 'components/Dashboarding/Models/Widget';
import gql from 'graphql-tag';
import { MutationResp } from './Shared';

//============================================
// Fetch collection
//============================================
export interface GetWidgetsConfigTypeResp {
    widgets: {
        nodes: WidgetType[];
    };
}

export const GET_WIDGETS_CONFIG_TYPE = gql`
    query getWidgetsConfigType($ids: [ID!]) {
        widgets(idIn: $ids) {
            nodes {
                id
                configType
            }
        }
    }
`;

export interface GetCompanyWidgetsResp {
    widgets: {
        nodes: (WidgetType & { dashboards: { id: string; name: string }[] })[];
    };
}

export const GET_COMPANY_WIDGETS = gql`
    query getCompanyWidgets(
        $companyId: ID!
        $currentUserRoles: [RoleNameEnum!]
        $excludedConfigTypes: [WidgetConfigTypeEnum!]
        $idIn: [ID!]
    ) {
        widgets(
            companyId: $companyId
            currentUserRoles: $currentUserRoles
            configTypeNin: $excludedConfigTypes
            idIn: $idIn
        ) {
            nodes {
                id
                config {
                    fact
                    filters
                    formatting
                    series {
                        dimensions {
                            formatting
                            function
                            ref
                            sort
                        }
                        filters
                        formatting
                        limit
                        metrics {
                            formatting
                            function
                            ref
                            sort
                        }
                        content
                    }
                }
                currentUserRole {
                    id
                    name
                }
                configType
                description
                name
                createdAt
                updatedAt
                user {
                    id
                    firstName
                }
                dashboards {
                    nodes {
                        id
                        name
                    }
                }
                documentation
                documentationEnabled
            }
        }
    }
`;

//============================================
// Fetch single
//============================================
export interface GetWidgetResp {
    widget?: WidgetType;
}

// List of commonly retrieved attributes
export const WIDGET_ATTRIBUTES = gql`
    fragment widgetAttributes on Widget {
        id
        config {
            fact
            filters
            formatting
            series {
                dimensions {
                    formatting
                    function
                    ref
                    sort
                }
                filters
                formatting
                limit
                metrics {
                    formatting
                    function
                    ref
                    sort
                }
                drilldownConfigs {
                    enabled
                    series {
                        metrics {
                            formatting
                            function
                            ref
                            sort
                        }
                        dimensions {
                            formatting
                            function
                            ref
                            sort
                        }
                        filters
                        formatting
                        limit
                    }
                    formatting
                }
                content
            }
        }
        configType
        description
        documentation
        documentationEnabled
        name
    }
`;

export const GET_WIDGET = gql`
    query getWidget($id: ID!) {
        widget(id: $id) {
            ...widgetAttributes
        }
    }
    ${WIDGET_ATTRIBUTES}
`;

//============================================
// Mutations
//============================================
export const WIDGET_CREATE_ATTRS = [
    'companyId',
    'config',
    'configType',
    'description',
    'name',
    'documentation',
    'documentationEnabled',
    'templateSource',
    'actionSource'
] as const;
export const WIDGET_UPDATE_ATTRS = [
    'config',
    'configType',
    'description',
    'id',
    'name',
    'documentation',
    'documentationEnabled'
];

export interface CreateWidgetResp {
    createWidget: MutationResp & {
        widget?: {
            id: string;
        };
    };
}

export const CREATE_WIDGET = gql`
    mutation($input: CreateWidgetInput!) {
        createWidget(input: $input) {
            success
            widget {
                id
            }
            errors {
                path
                message
            }
        }
    }
`;

export interface UpdateWidgetResp {
    updateWidget: MutationResp;
}

export const UPDATE_WIDGET = gql`
    mutation($input: UpdateWidgetInput!) {
        updateWidget(input: $input) {
            success
            errors {
                path
                message
            }
        }
    }
`;

export interface DeleteWidgetResp {
    deleteWidget: MutationResp;
}

export const DELETE_WIDGET = gql`
    mutation($id: ID!) {
        deleteWidget(input: { id: $id }) {
            success
            errors {
                path
                message
            }
        }
    }
`;

//============================================
// Subscriptions
//============================================
export const SUBSCRIBE_TO_WIDGET_UPDATED = gql`
    subscription($companyId: ID!) {
        widgetUpdated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_WIDGET_CREATED = gql`
    subscription($companyId: ID!) {
        widgetCreated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_WIDGET_DELETED = gql`
    subscription($companyId: ID!) {
        widgetDeleted(companyId: $companyId) {
            id
        }
    }
`;
