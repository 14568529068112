import { useEffect, useMemo } from 'react';

import { client as Elevio } from 'elevio';
import { ReduxState } from 'redux/reducers';
import { useSelector } from 'react-redux';
import AppLocale from 'lang/index';

export const ELEVIO_ACCOUNT_ID = process.env.REACT_APP_ELEVIO_ACCOUNT_ID;

// This is the one instance to use across the whole app
export const ElevioClient = Elevio;

// This is the id of the page of the feedback inside the
// elevio popup
export const ELEVIO_FEEDBACK_ID = '2';

export const useElevio = (): void => {
    // Redux state
    const apiUser = useSelector((e: ReduxState) => e.authUser.apiUser);
    const locale = useSelector((e: ReduxState) => e.settings.locale);

    // Retrieve currently used language
    const language = useMemo(() => AppLocale[locale].locale, [locale]);

    // Compute user attributes
    const elevioUser = useMemo(() => {
        if (!ELEVIO_ACCOUNT_ID || !apiUser) return null;

        return {
            first_name: apiUser.user.firstName,
            email: apiUser.user.email,
            user_hash: apiUser.elevioHash,
            registered_at: new Date(apiUser.user.createdAt).getTime()
        };
    }, [apiUser]);

    useEffect(() => {
        if (elevioUser) ElevioClient.setUser(elevioUser);
        ElevioClient.setLanguage(language);
        ElevioClient.load(ELEVIO_ACCOUNT_ID || '');
    }, [elevioUser, language]);
};
