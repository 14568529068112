import classNames from 'classnames';
import { PRIMARY_LIGHT_BLUE } from 'constants/colors';
import { sample } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';
import ColorMatrix, { MAIN_PICKER_COLORS } from './ColorMatrix';
import IconMatrix from './IconMatrix';
import IconRenderer, { SupportedPickerIconType, SUPPORTED_PICKER_ICONS } from './IconRenderer';

// Default icon background color
const DEFAULT_BG_COLOR = PRIMARY_LIGHT_BLUE;

// Default icon size
const DEFAULT_SIZE = 40;

export interface IconPickerConfig {
    ref?: SupportedPickerIconType;
    color?: string;
}

// Get random dashboard icon
export const pickRandomIcon = (): IconPickerConfig => {
    return {
        ref: sample(SUPPORTED_PICKER_ICONS) as SupportedPickerIconType,
        color: sample(MAIN_PICKER_COLORS)
    };
};

interface Props {
    className?: string;
    editable?: boolean;
    icon?: IconPickerConfig;
    onChange?: (e: IconPickerConfig) => void;
    size?: number;
}

// Return an icon based on an icon name
const IconPicker: FunctionComponent<Props> = ({ className, editable, icon, onChange, size = DEFAULT_SIZE }: Props) => {
    // Is the picker in edit mode?
    const isEditable = editable && onChange;

    // Coloring parameters
    const bgColor = icon?.color || DEFAULT_BG_COLOR;
    const textColor = useMemo(() => textColorFromBg(bgColor, shadeHexColor(bgColor, 1), shadeHexColor(bgColor, -1)), [
        bgColor
    ]);

    // Handle value change
    const onValueChange = useCallback(
        (opts: Partial<IconPickerConfig>) => {
            onChange && onChange({ ...icon, ...opts });
        },
        [icon, onChange]
    );

    // Picker content
    // The content gets memoized because it needs to be reused
    // based on the scenario (enabled/disabled)
    const pickerContent = useMemo(() => {
        return (
            <div
                className={classNames('rounded d-flex justify-content-center align-items-center', className)}
                style={{ backgroundColor: bgColor, color: textColor, height: size, width: size }}
                role={isEditable ? 'button' : undefined}
            >
                <IconRenderer icon={icon?.ref} />
            </div>
        );
    }, [bgColor, className, icon?.ref, isEditable, size, textColor]);

    // Render
    if (isEditable) {
        return (
            <OverlayTrigger
                trigger="click"
                placement="bottom-start"
                transition={false}
                rootClose
                overlay={
                    <Popover>
                        <Popover.Body>
                            <ColorMatrix onChange={e => onValueChange({ color: e })} />
                            <hr />
                            <IconMatrix value={icon?.ref} onChange={e => onValueChange({ ref: e })} />
                        </Popover.Body>
                    </Popover>
                }
            >
                {pickerContent}
            </OverlayTrigger>
        );
    } else {
        return pickerContent;
    }
};

export default IconPicker;
