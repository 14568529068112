import data from './ConfigQns.json';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { RuleValue } from 'components/RulesetBuilder';
import { IconPickerConfig } from 'components/Design/ColorPickers/IconPicker';
import { WidgetBase } from './Widget';
import { DropdownOption } from 'components/List/FilteringDropdown';
import { ResourceType } from 'api/hq/queries/ResourceRole';

export const CONFIG_QN_TYPES = [
    'DASHBOARD_NAMING',
    'DASHBOARD_DESCRIPTION',
    'WIDGET_NAMING',
    'SINGLESELECT',
    'MULTISELECT_INPUT',
    'INPUT',
    'PROJECTS_SELECT'
] as const;
export type ConfigQnType = typeof CONFIG_QN_TYPES[number];

export interface ConfigOption {
    id: number;
    label: string;
    value: string | RuleType | RuleGroupType | RuleValue;
}

export interface ConfigQn {
    id: string;
    type: ConfigQnType;
    priority: number;
    label: string;
    hint?: string;
    placeholder?: string;
    default: unknown;
    documentation?: string;
    impactedWidgets?: Partial<WidgetBase>[];
    result: unknown;
    isValid: boolean;
    scopes?: ResourceType[];
}

export interface ConfigQnDbNaming extends ConfigQn {
    type: 'DASHBOARD_NAMING';
    default: { name: string; icon?: IconPickerConfig };
    result: { name: string; icon?: IconPickerConfig };
}

export interface ConfigQnSingleSelect extends ConfigQn {
    type: 'SINGLESELECT';
    options: ConfigOption[];
    default: number;
    result: ConfigOption;
}

export interface ConfigQnMultiSelectInput extends ConfigQn {
    type: 'MULTISELECT_INPUT';
    options: ConfigOption[];
    default: { selectedOptions: number[]; input: string };
    result: { selectedOptions: ConfigOption[]; input: string };
}

export interface ConfigQnInput extends ConfigQn {
    type: 'INPUT';
    default: string;
    result: string;
}

const CONDITIONAL_IDS = ['all', 'some'] as const;
type ConditionalId = typeof CONDITIONAL_IDS[number];

export interface ConfigConditionalOption {
    id: ConditionalId;
    label: string;
}

export interface ConfigQnProjectsSelect extends ConfigQn {
    type: 'PROJECTS_SELECT';
    options: ConfigConditionalOption[];
    default: ConditionalId;
    result: { selectedOption: ConfigConditionalOption; selectedProjects: DropdownOption[] };
    subLabel: string;
    subPlaceholder: string;
}

// Configuration questions
// These questions are maintained at https://github.com/keypup-io/dashboard-templates/tree/master/questions
export const CONFIG_QNS = (data as unknown) as ConfigQn[];

//============================================
// Question type predicates
//============================================
// Is the question a dashboard naming question
export const isDashboardNamingQnType = (qn: ConfigQn): qn is ConfigQnDbNaming => {
    return qn.type == 'DASHBOARD_NAMING';
};

// Is the question a dashboard naming question
export const isDashboardDescriptionQnType = (qn: ConfigQn): qn is ConfigQnInput => {
    return qn.type == 'DASHBOARD_DESCRIPTION';
};

// Is the question a widget naming question
export const isWidgetNamingQnType = (qn: ConfigQn): qn is ConfigQnInput => {
    return qn.type == 'WIDGET_NAMING';
};

// Is the question a single select question
export const isSingleSelectQnType = (qn: ConfigQn): qn is ConfigQnSingleSelect => {
    return qn.type == 'SINGLESELECT';
};

// Is the question a multi select input question
export const isMultiSelectInputQnType = (qn: ConfigQn): qn is ConfigQnMultiSelectInput => {
    return qn.type == 'MULTISELECT_INPUT';
};

// Is the question an input question
export const isInputQnType = (qn: ConfigQn): qn is ConfigQnInput => {
    return qn.type == 'INPUT';
};

// Is the question a projects select question
export const isProjectsSelectQnType = (qn: ConfigQn): qn is ConfigQnProjectsSelect => {
    return qn.type == 'PROJECTS_SELECT';
};
