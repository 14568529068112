import { QueryResult } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { OperationVariables } from 'apollo-client';
import omitDeep from 'omit-deep-lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import {
    GetUserRoleForResourceResp,
    GET_USER_ROLES_FOR_DASHBOARD,
    GET_USER_ROLES_FOR_WIDGET,
    ResourceRole,
    ResourceRoleDeletedSubscription,
    ResourceRoleUpsertedSubscription,
    ResourceType,
    RESOURCE_ROLES_WITH_EDIT,
    SUBSCRIBE_TO_RESOURCE_ROLE_CREATED,
    SUBSCRIBE_TO_RESOURCE_ROLE_DELETED,
    SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED
} from '../queries/ResourceRole';

interface QueryOpts {
    skip?: boolean;
    resourceId: string;
    resourceType: ResourceType;
}

interface UserRolesForResourceQueryResults extends QueryResult<GetUserRoleForResourceResp, OperationVariables> {
    normalized?: ResourceRole;
    canEdit: boolean;
    canView: boolean;
    canDelete: boolean;
}

export function useUserRoleForResource(opts: QueryOpts): UserRolesForResourceQueryResults {
    // Get context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Determine the query based on the provided resourceType
    let query;
    switch (opts.resourceType) {
        case 'DASHBOARD':
            query = GET_USER_ROLES_FOR_DASHBOARD;
            break;
        case 'WIDGET':
            query = GET_USER_ROLES_FOR_WIDGET;
            break;
        default:
            throw new Error("'useUserRoleForResource' hook arguments: unsupported resourceType.");
    }

    // Retrieve the user resolved roles for the resource
    const rs = useQuery<GetUserRoleForResourceResp>(query, {
        skip: opts?.skip,
        variables: {
            resourceId: opts.resourceId
        }
    });

    // Subscribe to role updates
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_UPDATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role creation
    useSafeSubscription<ResourceRoleUpsertedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_CREATED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: sub => {
            // Only refetch if the role is related to the current user or company
            const role = sub.subscriptionData.data?.role;
            if (role?.actorType == 'COMPANY' && role?.actorId != company?.id) return;
            if (role?.actorType == 'USER' && role?.actorId != user?.id) return;

            rs.refetch();
        }
    });

    // Subscribe to role deletion
    useSafeSubscription<ResourceRoleDeletedSubscription>(SUBSCRIBE_TO_RESOURCE_ROLE_DELETED, {
        variables: { companyId: company?.id },
        skip: opts?.skip || !company?.id,
        onSubscriptionData: () => {
            // The subscription payload only contains the object ID. We cannot
            // check the Actor.
            rs.refetch();
        }
    });

    // Extract the list of roles
    const normalized = useMemo(() => {
        const roleObj = rs.data?.resource?.role;
        if (!roleObj) return undefined;

        return omitDeep(roleObj, '__typename') as ResourceRole;
    }, [rs.data?.resource?.role]);
    const canEdit = !!(normalized && RESOURCE_ROLES_WITH_EDIT.includes(normalized.name));
    const canView = !!normalized;
    const canDelete = !!(normalized && normalized.name == 'OWNER');

    return { ...rs, loading: rs.loading, normalized, canEdit, canView, canDelete };
}
