import { useMemo } from 'react';
import {
    GetInboxItemRelatedResp,
    GET_INBOX_ITEM_RELATED,
    InboxItemRelated,
    InboxItem,
    InboxItemWithRelated
} from '../queries/InboxItem';
import { useReport, UseReportResult } from './useReport';

interface UseInboxItemRelatedOpts {
    skip?: boolean | null;
    noSubscribe?: boolean;
    inboxItem: InboxItem;
}

interface UseInboxItemsRelatedQueryResults extends UseReportResult<GetInboxItemRelatedResp> {
    normalized: InboxItemRelated[];
    inboxItem: InboxItemWithRelated;
}

export function useInboxItemRelated({
    skip,
    noSubscribe,
    inboxItem
}: UseInboxItemRelatedOpts): UseInboxItemsRelatedQueryResults {
    // Related information
    const relatedType = inboxItem.type === 'issue' ? 'pull_request' : 'issue';
    const relatedSysIds = useMemo(
        () => (relatedType === 'issue' ? inboxItem.resolved_issue_sysids : inboxItem.resolving_pull_request_sysids),
        [inboxItem.resolved_issue_sysids, inboxItem.resolving_pull_request_sysids, relatedType]
    );

    // Fetch items
    const reportQuery = useReport<GetInboxItemRelatedResp>(GET_INBOX_ITEM_RELATED, {
        skip: skip || relatedSysIds.length === 0,
        noSubscribe: noSubscribe,
        variables: {
            relatedType: relatedType,
            relatedSysIds: relatedSysIds
        }
    });

    // Get related items
    const normalized = useMemo(() => reportQuery.data?.report.results.relatedItems || [], [
        reportQuery.data?.report.results.relatedItems
    ]);

    // Build inbox item with related items
    const inboxItemWithRel = useMemo(() => {
        return {
            ...inboxItem,
            resolved_issues: relatedType === 'issue' ? normalized : [],
            resolving_pull_requests: relatedType === 'pull_request' ? normalized : []
        };
    }, [inboxItem, normalized, relatedType]);

    return { ...reportQuery, normalized, inboxItem: inboxItemWithRel };
}
