import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import {
    BillingSubscriptionReferences,
    BillingSubscriptionTier,
    BillingSubscriptionTierName,
    BillingSubscriptionWithTier,
    BILLING_SUBSCRIPTION_REF_FREE,
    BILLING_SUBSCRIPTION_REF_PAID,
    BILLING_SUBSCRIPTION_TIERS,
    BILLING_SUBSCRIPTION_TIER_CONFIGS,
    BILLING_SUBSCRIPTION_TIER_NO_PLAN,
    GetBillingSubscriptionResp,
    GET_BILLING_SUBSCRIPTION,
    SUBSCRIBE_TO_BILLING_SUBSCRIPTION_CREATED,
    SUBSCRIBE_TO_BILLING_SUBSCRIPTION_UPDATED
} from '../queries/BillingSubscription';
import { useMemo } from 'react';

interface TierDiscoveryProps {
    subscriptionRef: BillingSubscriptionReferences;
    freeQuota: number;
    pricingQuantity: number;
}

/**
 * Return the name of the tier matching the provided context.
 * @param context The props used to evaluate the tier. Can be a BillingSubscriptionResp or BillingSubscriptionWithTier.
 * @param quantity An optional quantity to use in lieu of the quantity passed as part of the context.
 * @returns The name of the tier associated with the context
 */
export const getSubscriptionTierName = (
    context?: TierDiscoveryProps,
    quantity?: number
): BillingSubscriptionTierName => {
    if (!context) return BILLING_SUBSCRIPTION_TIER_NO_PLAN;

    // Infer the actual quantity being tested
    const actualQuantity = quantity ?? context.pricingQuantity;

    // Company is on free plan or no plan
    if (context.subscriptionRef == BILLING_SUBSCRIPTION_REF_FREE) {
        return context.freeQuota > 0 ? BILLING_SUBSCRIPTION_REF_FREE : BILLING_SUBSCRIPTION_TIER_NO_PLAN;
    }

    // Company is on paid tier
    let tier: keyof typeof BILLING_SUBSCRIPTION_TIER_CONFIGS;
    for (tier in BILLING_SUBSCRIPTION_TIER_CONFIGS) {
        // Get tier config and skip if the tier has no quantity bracket
        const config = BILLING_SUBSCRIPTION_TIER_CONFIGS[tier];
        if (config.maxQuantity == 0) continue;

        // Select the first tier immediately greater or equal to the number
        // of consumed units OR strictly greater than the free quota allowed
        // on the subscription.
        // The latter is to ensure that the maximum number of allowed projects
        // is always strictly greater than the number of projects that users
        // can have for free. See the logic of SubscriptionPresenter for more details
        // on how the count of used vs allowed projects is presented.
        if (config.maxQuantity && actualQuantity <= config.maxQuantity && context.freeQuota < config.maxQuantity) {
            return tier;
        }
    }

    // Company is on the last tier (enterprise), which has no maximum number of projects
    return BILLING_SUBSCRIPTION_TIERS[BILLING_SUBSCRIPTION_TIERS.length - 1];
};

/**
 * Return the tier object matching the provided context.
 * @param context The props used to evaluate the tier. Can be a BillingSubscriptionResp or BillingSubscriptionWithTier.
 * @param quantity An optional quantity to use in lieu of the quantity passed as part of the context.
 * @returns The the tier associated with the context
 */
export const getSubscriptionTier = (context?: TierDiscoveryProps, quantity?: number): BillingSubscriptionTier => {
    // Get tier name and configuration
    const actualQuantity = quantity ?? context?.pricingQuantity ?? 0;
    const tierName = getSubscriptionTierName(context, actualQuantity);
    const tierConfig = BILLING_SUBSCRIPTION_TIER_CONFIGS[tierName];

    // Evaluate price
    // Evaluate how to calculate the price
    const pricingMethod =
        context?.subscriptionRef == BILLING_SUBSCRIPTION_REF_PAID[1] ? tierConfig.yearlyPrice : tierConfig.monthlyPrice;
    const priceValue = typeof pricingMethod === 'function' ? pricingMethod(actualQuantity) : pricingMethod;

    // Return tier object
    return {
        name: tierName,
        price: priceValue,
        quantity: actualQuantity,
        config: tierConfig
    };
};

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
}

interface BillingSubscriptionQueryResults extends QueryResult<GetBillingSubscriptionResp, OperationVariables> {
    normalized?: BillingSubscriptionWithTier;
}

export function useBillingSubscription({ companyId, skip }: QueryOpts): BillingSubscriptionQueryResults {
    // Subscribe to billing subscription update
    const updSub = useSafeSubscription(SUBSCRIBE_TO_BILLING_SUBSCRIPTION_UPDATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs?.refetch();
        }
    });

    // Subscribe to billing subscription creation
    useSafeSubscription(SUBSCRIBE_TO_BILLING_SUBSCRIPTION_CREATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs?.refetch();
        }
    });

    // Get billing subscription after we have successfully subscribed to updates
    const rs = useQuery<GetBillingSubscriptionResp>(GET_BILLING_SUBSCRIPTION, {
        skip: skip || !companyId || updSub.loading,
        variables: { companyId: companyId }
    });

    // Extract billing subscription
    const billingSubscription = useMemo(() => rs.data?.billingSubscription, [rs.data?.billingSubscription]);

    // Retrieve the tier configuration
    const subscriptionTier = useMemo(() => getSubscriptionTier(billingSubscription), [billingSubscription]);

    // Extract billing subscription
    const normalized: BillingSubscriptionWithTier | undefined = billingSubscription && {
        ...billingSubscription,
        tier: subscriptionTier
    };

    return { ...rs, loading: rs.loading || updSub.loading, normalized: normalized };
}
