import { FunctionComponent, useEffect } from 'react';

interface Props {
    uri: string;
}

// Component used to redirect user to external URLs, similar to <Navigate />
const ExternalRedirect: FunctionComponent<Props> = ({ uri }: Props) => {
    useEffect(() => {
        window.location.href = uri;
    }, [uri]);

    return null;
};

export default ExternalRedirect;
