import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DashboardWithLayout, UpdateDashboardResp, UPDATE_DASHBOARD } from 'api/hq/queries/Dashboard';
import { Layout } from 'react-grid-layout';
import { QueryError } from 'components/ErrorManagement';
import { WidgetDisplayConfig, WidgetFactType, WIDGET_DISPLAY_CONFIGS } from '../Models/Widget';
import { findFirstAvailableGridPlacement } from 'routes/reports/GenericDashboard/DashboardGrid';

interface AddWidgetToDashboardResult {
    success: boolean;
    widget?: { id: string };
    dashboard?: {
        id: string;
        layout: Layout[];
    };
    error?: QueryError;
}

interface AddWidgetToDashboardProps {
    widget: WidgetFactType;
    placement?: Omit<Layout, 'i'>;
}

interface Props {
    dashboard?: DashboardWithLayout;
}

type Result = (arg: AddWidgetToDashboardProps) => Promise<AddWidgetToDashboardResult>;

// A hook for adding widget to a dashboard
function useAddWidgetToDashboard({ dashboard }: Props): Result {
    const [updateDashboard] = useMutation<UpdateDashboardResp>(UPDATE_DASHBOARD);

    return useCallback(
        async ({ widget, placement }) => {
            // Check dashboard exist
            if (!dashboard) throw new Error('no dashboards provided');

            try {
                // Widget sizing
                const { defaultSizing }: WidgetDisplayConfig = WIDGET_DISPLAY_CONFIGS[widget.configType];

                // Find available space for the widget
                const widgetPlacement = findFirstAvailableGridPlacement(dashboard.layout, {
                    ...defaultSizing,
                    ...placement
                });

                // Build new layout version
                const layout = dashboard.layout;
                const payload = {
                    id: dashboard.id,
                    layout: [...layout, { i: widget.id, ...widgetPlacement }]
                };

                // Update dashboard
                const dashboardResp = (await updateDashboard({ variables: { input: payload } })).data?.updateDashboard;

                // If updating dashboard failed
                if (!dashboardResp?.success) throw dashboardResp?.errors[0] || { code: 'default ' };

                // If updating dashboard succeed
                return {
                    success: true,
                    widget: widget,
                    dashboard: payload
                };
            } catch (e) {
                return {
                    success: false,
                    error: e as QueryError
                };
            }
        },
        [dashboard, updateDashboard]
    );
}

export default useAddWidgetToDashboard;
