import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { CompanyResp, GetCompanyResp, GET_COMPANY, SUBSCRIBE_TO_COMPANY_UPDATED } from '../queries/Company';
import { useSafeSubscription } from 'api/core/useSafeSubscription';

interface QueryOpts {
    companyId?: string;
    onCompleted?: (data?: GetCompanyResp) => void;
    skip?: boolean;
}

interface CompanyQueryResults extends QueryResult<GetCompanyResp, OperationVariables> {
    normalized?: CompanyResp;
}

export function useCompany({ companyId, skip, onCompleted }: QueryOpts): CompanyQueryResults {
    // Subscribe to company update
    const updSub = useSafeSubscription(SUBSCRIBE_TO_COMPANY_UPDATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs?.refetch();
        }
    });

    // Get company after we have successfully subscribed to updates
    const rs = useQuery<GetCompanyResp>(GET_COMPANY, {
        skip: skip || !companyId || updSub.loading,
        variables: { id: companyId },
        onCompleted
    });

    const normalized = rs.data?.company;

    return { ...rs, loading: rs.loading || updSub.loading, normalized: normalized };
}
