import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_INTEGRATION, DeleteIntegrationResp } from 'api/hq/queries/Integration';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    onSuccess?: () => void;
    integrationId: string;
}

const DisconnectAppModal: FunctionComponent<Props> = ({ isOpen, toggle, onSuccess, integrationId }: Props) => {
    const [mutationError, setMutationError] = useState<string | null | undefined>(undefined);
    const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

    // Declare integration deletion mutation
    const [deleteIntegration] = useMutation<DeleteIntegrationResp>(DELETE_INTEGRATION);

    // TODO: error handling
    // Disconnect application
    const disconnectApp = async (): Promise<void> => {
        setMutationInProgress(true);

        const resp = await deleteIntegration({ variables: { id: integrationId } });
        const deleteResp = resp.data && resp.data.deleteIntegration;

        if (deleteResp && deleteResp.success) {
            onSuccess && onSuccess();
            toggle();
        } else {
            if (deleteResp) setMutationError(deleteResp.errors[0].code);
        }
        setMutationInProgress(false);
    };

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="components.app-connect-item.disconnect-modal.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <FormattedMessage id="components.app-connect-item.disconnect-modal.body" />
                </p>
                {mutationError ? (
                    <div className="text-danger">
                        <FormattedMessage id={`components.app-connect-item.disconnect-modal.errors.${mutationError}`} />
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <AsyncButton
                    track-id="click-app-disconnect"
                    variant="danger"
                    onClick={disconnectApp}
                    disabled={mutationInProgress}
                    loading={mutationInProgress}
                    messageProps={{ id: 'components.app-connect-item.disconnect-modal.disconnect' }}
                    loadingMessageProps={{ id: 'components.app-connect-item.disconnect-modal.disconnecting' }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default DisconnectAppModal;
