import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { dashboardViewPath } from 'constants/routeBuilders';
import { useDashboardCreator } from 'components/Dashboarding/Hooks/useDashboardCreator';
import { DashboardTemplate, DASHBOARD_TEMPLATES } from 'components/Dashboarding/Models/DashboardTemplates';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RichMessage } from 'components/RichMessage';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { ErrorMessage, QueryError } from 'components/ErrorManagement';
import { ReactComponent as KeypupLogo } from 'assets/img/logo.svg';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { sortBy } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import { useOnboardingNavigate } from 'util/useOnboardingNavigate';
import { useLocalStorage } from 'react-use';
import { DASHBOARD_TMPL_KEY, DASHBOARD_TMPL_ID_KEY } from 'constants/routeBuilders';
import FullPageLoader from 'components/Design/FullPageLoader';
import { useTitleLabel } from 'util/useTitleLabel';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

// The namespace used for error messages
const ERROR_NS = 'add-dashboard-template';

const AddDashboardTemplate: FunctionComponent = () => {
    // Page title
    useTitleLabel('flows.add-dashboard-template.title');

    // Services
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { createDashboardFromTemplate } = useDashboardCreator();
    const navigateToOnboarding = useOnboardingNavigate();

    // Redux state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Storage
    const [, setOnboardingStarterTemplate] = useLocalStorage<DashboardTemplate>(DASHBOARD_TMPL_KEY);

    // Regular state
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined);
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);
    const [actionSuccess, setActionSuccess] = useState<boolean>(false);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);

    // Company selection
    const isLoading = !user;
    const companies = useMemo(
        () =>
            sortBy(
                (user?.memberships?.nodes || []).map(e => e.company),
                'name'
            ),
        [user?.memberships?.nodes]
    );
    const currentCompany = companies.find(e => e.id === selectedCompanyId) || undefined;
    const isMultiCompany = companies.length > 1;

    // Get the dashboard template
    const dashboardTemplate = useMemo(() => {
        const tmplId = searchParams.get(DASHBOARD_TMPL_ID_KEY);
        const tmplJson = searchParams.get(DASHBOARD_TMPL_KEY);

        if (tmplId) {
            return DASHBOARD_TEMPLATES.find(e => e.id == tmplId);
        } else if (tmplJson) {
            try {
                return JSON.parse(tmplJson) as DashboardTemplate;
            } catch {
                return undefined;
            }
        }
    }, [searchParams]);

    // Hook invoked when the user click to proceed with the dashbooard creation
    const setupDashboard = useCallback(async () => {
        if (!selectedCompanyId || !dashboardTemplate) return;

        // Reset state
        setActionInProgress(true);
        setMutationError(undefined);

        try {
            // Evaluate action source
            const actionSource = searchParams.has(DASHBOARD_TMPL_ID_KEY) ? 'website-template' : 'shared-link';

            // Create dashboard
            const resp = await createDashboardFromTemplate(dashboardTemplate, {
                companyId: selectedCompanyId,
                actionSource
            });
            const dashboard = resp?.createDashboard.dashboard;

            if (dashboard?.id) {
                // Visual confirmation
                setActionSuccess(true);

                // Redirect to newly created dashboard after 2 seconds
                const dashboardPath = dashboardViewPath({ dashboardId: dashboard.id });
                setTimeout(() => navigate(dashboardPath, { replace: true }), 2000);
            } else {
                const error = resp?.createDashboard.errors[0] || { code: 'default' };
                setMutationError(error);
                return;
            }
        } catch {
            setMutationError({ code: 'default' });
        } finally {
            setActionInProgress(false);
        }
    }, [createDashboardFromTemplate, dashboardTemplate, navigate, searchParams, selectedCompanyId]);

    // Set error if the dashboard template is invalid (ID invalid or could not be parsed)
    useEffect(() => {
        !dashboardTemplate && !mutationError && setMutationError({ code: 'invalid-template' });
    }, [dashboardTemplate, mutationError]);

    // Auto-select company ID from currently logged in company
    useEffect(() => {
        !selectedCompanyId && company && setSelectedCompanyId(company.id);
    }, [company, selectedCompanyId]);

    // If the user has no companies, redirect to onboarding and store the
    // requested template.
    useEffect(() => {
        // Abort if the resources are not loaded or if the user has companies
        if (isLoading) return;
        if (companies.length > 0) return;

        // Store requested template in local storage so it can
        // be picked up during the onboarding
        dashboardTemplate && setOnboardingStarterTemplate(dashboardTemplate);

        // Redirect to onboarding
        navigateToOnboarding();
    }, [companies.length, dashboardTemplate, isLoading, navigateToOnboarding, setOnboardingStarterTemplate]);

    // Loading
    if (isLoading) return <FullPageLoader />;

    // Render
    return (
        <>
            <Container>
                <div className="d-flex align-items-center vh-100 w-60 mx-auto my-auto text-dark">
                    <Card className="flex-fill">
                        <Card.Header className="bg-white pt-5 pb-4">
                            <Card.Title className="text-center">
                                <FontAwesomeIcon icon={faPlusSquare} className="text-info mb-3" size="2x" />
                                <h3>
                                    <RichMessage id="flows.add-dashboard-template.title" />
                                </h3>
                            </Card.Title>
                        </Card.Header>

                        <Card.Body className="d-flex flex-column text-center align-items-center justify-content-center py-5">
                            {/* Error message */}
                            {mutationError && (
                                <ErrorMessage
                                    error={{ ...mutationError, namespace: ERROR_NS }}
                                    className="text-danger"
                                />
                            )}

                            {/* General messaging */}
                            {dashboardTemplate?.name && (
                                <p className="w-75">
                                    <RichMessage
                                        id="flows.add-dashboard-template.message"
                                        values={{
                                            name: dashboardTemplate.name,
                                            description: dashboardTemplate?.description
                                        }}
                                    />
                                </p>
                            )}

                            {/* Team selection/confirmation */}
                            <div className="d-flex align-items-center">
                                <span className="me-1">
                                    <RichMessage id="flows.add-dashboard-template.select-team" />
                                </span>

                                {isMultiCompany ? (
                                    <Dropdown id="selectTeam">
                                        <Dropdown.Toggle
                                            variant="outline-light"
                                            className="text-dark ms-1"
                                            disabled={actionInProgress || actionSuccess}
                                            size="sm"
                                        >
                                            {currentCompany?.name}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {companies.map(e => (
                                                <Dropdown.Item key={e.id} onClick={() => setSelectedCompanyId(e.id)}>
                                                    {e.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    <span className="fw-bold">{currentCompany?.name}</span>
                                )}
                            </div>
                        </Card.Body>

                        <Card.Footer className="d-flex justify-content-center bg-white pb-4 border-top-0">
                            {actionSuccess ? (
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" size="3x" />
                            ) : (
                                <AsyncButton
                                    variant="dark"
                                    onClick={setupDashboard}
                                    disabled={actionInProgress}
                                    loading={actionInProgress}
                                    messageProps={{ id: 'flows.add-dashboard-template.action.add' }}
                                    loadingMessageProps={{ id: 'flows.add-dashboard-template.action.adding' }}
                                />
                            )}
                        </Card.Footer>
                    </Card>
                </div>
            </Container>

            <div className="fixed-bottom text-center text-muted">
                <div className="mb-5">
                    <KeypupLogo height={32} className="mb-2" />
                </div>
            </div>
        </>
    );
};

export default AddDashboardTemplate;
