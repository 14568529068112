import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DECORATOR_WIDGET_TYPES } from 'api/hq/hooks/useWidgets';
import {
    DashboardWithLayoutAndWidgetsAndResolvedRoles,
    GetDashboardWithWidgetsAndResolvedRolesResp,
    GET_DASHBOARD_WITH_WIDGETS_AND_RESOLVED_ROLES
} from 'api/hq/queries/Dashboard';
import { SetResourceRoleResp, SET_RESOURCE_ROLE, SET_ROLE_ATTRIBUTES } from 'api/hq/queries/ResourceRole';
import { toastQueryError } from 'components/Toast';
import { filter, find, pick, reject } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { useCallback, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useDashboardCreator } from './useDashboardCreator';

interface Props {
    onComplete: (dashboardId: string) => void;
}

interface DuplicateDashboardOpts {
    duplicateWidgets: boolean;
    duplicateRoles: boolean;
}

interface DuplicateDashboardProps {
    id: string;
    name: string;
}

type DuplicateDashboard = (dashboard: DuplicateDashboardProps, opts: DuplicateDashboardOpts) => Promise<void>;

export function useDashboardDuplicator({
    onComplete
}: Props): { loading: boolean; duplicateDashboard: DuplicateDashboard } {
    // State
    const [loading, setLoading] = useState(false);

    // Service
    const intl = useIntl();
    const client = useApolloClient();

    // Redux state
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);

    // Mutations
    const [setRole] = useMutation<SetResourceRoleResp>(SET_RESOURCE_ROLE);
    const { createDashboardFromLayout, createWidgetsFromLayout } = useDashboardCreator();

    // Clone the dashboard
    const duplicateDashboard = useCallback(
        async ({ id: dashboardId, ...overrideParams }, opts) => {
            try {
                setLoading(true);

                // Get the dashboard with the layout widgets and roles
                const { data } = await client.query<GetDashboardWithWidgetsAndResolvedRolesResp>({
                    query: GET_DASHBOARD_WITH_WIDGETS_AND_RESOLVED_ROLES,
                    variables: { id: dashboardId }
                });

                // omit all __typenames values from the dashboard structure
                const dashboard = omitDeep(
                    data.dashboard as object,
                    '__typename'
                ) as DashboardWithLayoutAndWidgetsAndResolvedRoles;

                //=======================
                // Duplicate Widgets
                //=======================

                // Override the widgets names toadd "- Copy" suffix
                const widgets = dashboard.widgets.nodes.map(e => ({
                    ...e,
                    name: intl.formatMessage({ id: 'generic.with-copy-suffix' }, { name: e.name })
                }));

                let newLayout: Layout[];
                if (opts?.duplicateWidgets) {
                    // Duplicate all widgets
                    newLayout = await createWidgetsFromLayout(dashboard.layout, widgets);
                } else {
                    // Duplicate only decorators
                    const decoratorWidgetPredicate = (e: Layout): boolean => {
                        const configType = find(widgets, { id: e.i })?.configType;
                        return DECORATOR_WIDGET_TYPES.includes(configType || '');
                    };

                    newLayout = [
                        ...reject(dashboard.layout, decoratorWidgetPredicate),
                        ...(await createWidgetsFromLayout(filter(dashboard.layout, decoratorWidgetPredicate), widgets))
                    ];
                }

                // Create dashboard with the duplicated widgets
                const dashboardResp = await createDashboardFromLayout(dashboard, newLayout, overrideParams);
                const newDashboard = dashboardResp?.createDashboard?.dashboard;

                //=======================
                // Duplicate Roles
                //=======================

                // Duplicate all roles associated to the dashboard
                if (opts.duplicateRoles) {
                    await Promise.all(
                        dashboard.resolvedRoles
                            .filter(r => !r.inheritedFromParent)
                            .map(async e => {
                                // Duplicate roles names except for other OWNERS. they will be switched to EDITORS
                                const resolvedName = {
                                    OWNER: e.actorId !== user?.id ? 'EDITOR' : 'OWNER',
                                    EDITOR: 'EDITOR',
                                    VIEWER: 'VIEWER'
                                }[e.name];
                                const rolePayload = {
                                    ...pick(e, SET_ROLE_ATTRIBUTES),
                                    name: e.actorId == user?.id ? 'OWNER' : resolvedName,
                                    resourceId: newDashboard?.id
                                };

                                await setRole({ variables: { input: rolePayload } });
                            })
                    );
                }

                // Callback
                if (onComplete && newDashboard?.id) onComplete(newDashboard?.id);
            } catch (e) {
                toastQueryError({ namespace: 'generic' });
            } finally {
                setLoading(false);
            }
        },
        [client, createDashboardFromLayout, createWidgetsFromLayout, intl, onComplete, setRole, user?.id]
    );

    return { loading, duplicateDashboard };
}
