import { ItemLabel } from 'api/viz/queries/InboxItem';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { QueryOpDisplayFormatter, QueryOpFieldType } from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { WidgetFieldType } from 'components/Dashboarding/Models/Widget';
import { InstantTooltip } from 'components/Dashboarding/InstantTooltip';
import LabelBadge from 'components/InboxCard/LabelBadge';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, useMemo } from 'react';
import Moment from 'react-moment';
import classNames from 'classnames';
import { useMeasure } from 'react-use';
import { ExternalLink } from 'components/ExternalLink';
import { useIntl } from 'react-intl';
import { stripMarkdown } from 'util/StringOperators';

interface Props {
    className?: string;
    fact: QueryOpFact;
    field: WidgetFieldType;
    value: string;
    disableTooltip?: boolean;
    onClick?: (isTruncated: boolean) => void;
}

// A default formatter handling all common field types
const FieldFormatter: FunctionComponent<Props> = ({
    className,
    value,
    field,
    fact,
    disableTooltip,
    onClick
}: Props) => {
    // Refs
    const [cellRef, { width: cellWidth }] = useMeasure<HTMLDivElement>();
    const [cellContentRef, { width: cellContentWidth }] = useMeasure<HTMLDivElement>();
    const isTruncated = useMemo(() => cellContentWidth >= cellWidth, [cellContentWidth, cellWidth]);

    // Services
    const intl = useIntl();

    // Get fact fields
    const { queryOpFields } = useQueryOpFields({ fact });

    // Extract parameters
    const formatting = field.formatting;
    const queryOpField = queryOpFields.find(e => e.id == field.ref);
    const isArray = queryOpField?.typeIsArray;
    const type = queryOpField?.type || 'string';

    // Is it an empty record
    const isEmptyRecord = useMemo(
        () =>
            [
                intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }),
                intl.formatMessage({ id: 'dashboarding.widget-editor.configure-table.no-records-placeholder' })
            ].includes(value),
        [intl, value]
    );

    // Convert value to list so we handle only one type
    const valueList = useMemo(
        () => (isArray && value && Array.isArray(value) ? ((value as unknown) as string[]) : [value]),
        [isArray, value]
    );

    // Select separator based on type
    const formatter: QueryOpDisplayFormatter | undefined = useMemo(
        () => (formatting && formatting.formatter ? formatting.formatter : queryOpField?.defaultDisplayFormatter),
        [formatting, queryOpField?.defaultDisplayFormatter]
    );
    const separator = formatter == 'LABEL_BADGES' ? ' ' : ', ';

    // Handle empty array scenario
    if (Array.isArray(value) && value.length == 0) {
        return (
            <span className="text-grey-4">
                <RichMessage id="dashboarding.widget-field-formatter.empty" />
            </span>
        );
    }

    // Format each list element via NativeValueFormatter
    return (
        <div
            ref={cellRef}
            className={classNames(className, 'text-truncate ms-2', {
                'cursor-help': !disableTooltip && isTruncated && !isEmptyRecord
            })}
            onClick={() => onClick && onClick(isTruncated)}
        >
            <InstantTooltip
                disabled={!isTruncated || disableTooltip || isEmptyRecord}
                value={stripMarkdown(valueList.join(separator))}
            >
                <div className="text-truncate" style={{ width: 'fit-content', maxWidth: '100%' }} ref={cellContentRef}>
                    {valueList.map((e, i) => [
                        i > 0 && separator,
                        <NativeValueFormatter
                            key={i}
                            formatter={formatter}
                            fieldFunction={field.function}
                            type={type}
                            value={e}
                        />
                    ])}
                </div>
            </InstantTooltip>
        </div>
    );
};

interface NativeValueFormatterProps {
    formatter: QueryOpDisplayFormatter | undefined;
    fieldFunction: string | undefined;
    type?: QueryOpFieldType;
    value: string;
}

// A default formatter handling all common field types (excluding arrays)
const NativeValueFormatter: FunctionComponent<NativeValueFormatterProps> = ({
    formatter,
    fieldFunction,
    type,
    value
}: NativeValueFormatterProps) => {
    // Services
    const intl = useIntl();

    // Do not format any placeholder messages such as `No data` or `No records`
    if (
        [
            intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }),
            intl.formatMessage({ id: 'dashboarding.widget-editor.configure-table.no-records-placeholder' })
        ].includes(value)
    ) {
        return <>{value.toString()}</>;
    } else if (value == null || value == undefined) {
        return (
            <span className="text-muted">
                <RichMessage id="dashboarding.widget-field-formatter.null" />
            </span>
        );
    } else if (formatter == 'TS_L') {
        return <Moment format="L">{value}</Moment>;
    } else if (formatter == 'TS_DD/MM/YYYY') {
        return <Moment format="DD/MM/YYYY">{value}</Moment>;
    } else if (formatter == 'TS_LL') {
        return <Moment format="LL">{value}</Moment>;
    } else if (formatter == 'TS_ll') {
        return <Moment format="ll">{value}</Moment>;
    } else if (formatter == 'TS_LLL') {
        return <Moment format="LLL">{value}</Moment>;
    } else if (formatter == 'TS_lll') {
        return <Moment format="lll">{value}</Moment>;
    } else if (formatter == 'TS_LLLL') {
        return <Moment format="LLLL">{value}</Moment>;
    } else if (formatter == 'TS_llll') {
        return <Moment format="llll">{value}</Moment>;
    } else if (formatter == 'TS_MM/YYYY') {
        return <Moment format="MM/YYYY">{value}</Moment>;
    } else if (formatter == 'TS_MMM_YYYY') {
        return <Moment format="MMM, YYYY">{value}</Moment>;
    } else if (formatter == 'TS_WW') {
        return <Moment format="[Week] WW">{value}</Moment>;
    } else if (formatter == 'TS_WW_YYYY') {
        return <Moment format="[Week] WW, YYYY">{value}</Moment>;
    } else if (formatter == 'TS_YYYY-WW') {
        return <Moment format="YYYY-[W]WW">{value}</Moment>;
    } else if (formatter == 'TS_YYYY-MM') {
        return <Moment format="YYYY-MM">{value}</Moment>;
    } else if (formatter == 'TS_YYYY') {
        return <Moment format="YYYY">{value}</Moment>;
    } else if (formatter == 'LABEL_BADGES') {
        return <LabelBadge label={(value as unknown) as ItemLabel} />;
    } else if (!fieldFunction && formatter == 'FULL_LINK') {
        return (
            <div className="wmde-markdown bg-transparent fs-6">
                <ExternalLink href={value}>{value}</ExternalLink>
            </div>
        );
    } else if (!fieldFunction && formatter == 'SHORT_LINK') {
        return (
            <div className="wmde-markdown bg-transparent fs-6">
                <ExternalLink href={value}>
                    <RichMessage id="dashboarding.widget-field-formatter.link" />
                </ExternalLink>
            </div>
        );
    } else if (type == 'label') {
        return <>{((value as unknown) as ItemLabel).name}</>;
    } else {
        return <>{stripMarkdown(value.toString())}</>;
    }
};

export default FieldFormatter;
