import React, { ReactNode, ReactNodeArray, FunctionComponent, useCallback } from 'react';
import NavigationalTabs, { NavigationalTab } from 'components/Design/NavigationalTabs';
import { useNavigate } from 'react-router-dom';
import TopNavBar from 'components/Design/TopNavBar';
import { RichMessage } from 'components/RichMessage';
import Container from 'react-bootstrap/Container';
import { TEAM_MANAGEMENT_PATH, TEAM_SPRINT_SETTINGS_PATH } from 'constants/routeBuilders';
import { useTitleLabel } from 'util/useTitleLabel';

const TABS = [
    {
        id: 'settings.team-management.tab.team-members',
        name: 'team-members',
        to: TEAM_MANAGEMENT_PATH
    },
    {
        id: 'settings.team-management.tab.agile-settings',
        name: 'agile-settings',
        to: TEAM_SPRINT_SETTINGS_PATH
    }
];

interface Props {
    id: string; // active tab id. e.g. 'team-member'
    actions?: ReactNode | ReactNodeArray;
    children: ReactNode | ReactNodeArray;
}

// Account Settings Layout
//
// Usage:
//
// <Layout id="agile-settings" actions={<Button>action</Button>}>
//    {your_content}
// </Layout>
//
const TeamManagementLayout: FunctionComponent<Props> = ({ children, id, actions }) => {
    // Page title
    useTitleLabel('settings.team-management.title');

    // Services
    const navigate = useNavigate();

    // navigate to another tab url
    const switchTab = useCallback(
        (tab: NavigationalTab): void => {
            navigate(tab.to);
        },
        [navigate]
    );

    return (
        <>
            {/* Top bar */}
            <TopNavBar>
                <h4 className="flex-grow-1">
                    <RichMessage id="settings.team-management.title" />
                </h4>
                {actions}
            </TopNavBar>
            <Container>
                {/* Navigation tabs */}
                <NavigationalTabs onSelect={switchTab} className="mb-4" tabs={TABS} activeTab={id} />
                {children}
            </Container>
        </>
    );
};

export default TeamManagementLayout;
