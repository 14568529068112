import {
    MetricColorMode,
    MetricFormattingOptions,
    WidgetChartType,
    WidgetColorRange,
    WidgetKpiType
} from 'components/Dashboarding/Models/Widget';
import { WIDGET_GRADIENT_COLORS, WIDGET_COLOR_PALETTE, WIDGET_THRESHOLD_COLORS } from 'constants/colors';
import { sample } from 'lodash';
import { useCallback } from 'react';

interface Results {
    onAddRange: (colorMode: MetricColorMode, seriesIndex: number, insertBetween?: boolean) => void;
    onRangeUpdate: (
        colorMode: MetricColorMode,
        seriesIndex: number,
        rangeIndex?: number,
        range?: WidgetColorRange,
        insertBetween?: boolean
    ) => void;
    complyColorRanges: (
        colorRanges: WidgetColorRange[] | undefined,
        toColorMode: MetricColorMode
    ) => WidgetColorRange[];
}

export function useColorRanges<TWidget extends WidgetChartType | WidgetKpiType>(
    widget: TWidget,
    onMetricFormattingChange: (index: number, addedOpts: Partial<MetricFormattingOptions>) => void
): Results {
    // Hook invoked when the range is updated
    const onRangeUpdate = useCallback(
        (
            colorMode: MetricColorMode,
            seriesIndex: number,
            rangeIndex?: number,
            range?: WidgetColorRange,
            insertBetween?: boolean
        ) => {
            // Get the current color range
            const series = widget.config.series[seriesIndex];
            const defaultColorRanges =
                colorMode == 'gradient'
                    ? WIDGET_GRADIENT_COLORS
                    : colorMode == 'threshold'
                    ? WIDGET_THRESHOLD_COLORS
                    : [];
            const updatedColorRanges = [...(series.metrics[0].formatting?.colorRanges || defaultColorRanges)];

            // Update the range
            if (rangeIndex == undefined && range) {
                if (insertBetween) {
                    const tmp = updatedColorRanges.pop() as WidgetColorRange;
                    updatedColorRanges.push(range);
                    updatedColorRanges.push({ ...tmp });
                } else {
                    updatedColorRanges.push(range);
                }
            } else if (rangeIndex != undefined && range) {
                updatedColorRanges[rangeIndex] = range;
            } else if (rangeIndex != undefined && range == undefined) {
                updatedColorRanges.splice(rangeIndex, 1);
            }

            // Update the series
            onMetricFormattingChange(seriesIndex, { colorRanges: updatedColorRanges });
        },
        [widget.config.series, onMetricFormattingChange]
    );

    // Hook invoked when a range is added
    const onAddRange = useCallback(
        (colorMode: MetricColorMode, seriesIndex: number, insertBetween?: boolean) => {
            // Get range information
            const series = widget.config.series[seriesIndex];
            const ranges = series?.metrics[0]?.formatting?.colorRanges || [];
            const rangeColors = ranges.map(e => e[0]);
            const lastRangeThreshold = ranges?.[ranges.length - 1]?.[1];
            const newRangeColor = sample(WIDGET_COLOR_PALETTE.filter(e => !rangeColors.includes(e))) as string;

            if (colorMode == 'threshold') {
                // Get new range configuration
                const newRangeThreshold =
                    ranges.length == 0
                        ? undefined
                        : lastRangeThreshold == undefined
                        ? 0
                        : lastRangeThreshold == 0
                        ? 10
                        : lastRangeThreshold * 2;

                onRangeUpdate(colorMode, seriesIndex, undefined, [newRangeColor, newRangeThreshold], insertBetween);
            } else {
                onRangeUpdate(colorMode, seriesIndex, undefined, [newRangeColor], insertBetween);
            }
        },
        [widget.config.series, onRangeUpdate]
    );

    // Comply color ranges to `gradient` or `threshold` color mode
    const complyColorRanges = (
        colorRanges: WidgetColorRange[] | undefined,
        toColorMode: MetricColorMode
    ): WidgetColorRange[] => {
        if (toColorMode == 'gradient') {
            return (colorRanges || WIDGET_GRADIENT_COLORS).map(e => [e[0]]).slice(0, 3) as WidgetColorRange[];
        } else if (toColorMode == 'threshold') {
            return (colorRanges || WIDGET_THRESHOLD_COLORS).map((e, index) => [e[0], index == 0 ? undefined : index]);
        } else {
            return [];
        }
    };

    return {
        onAddRange,
        onRangeUpdate,
        complyColorRanges
    };
}
