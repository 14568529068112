import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import LibraryCard from 'components/LibraryCard/LibraryCard';
import { LIBRARY_CARD_DESCRIPTION_MAX_LENGTH } from 'constants/defaultValues';
import { truncate } from 'lodash';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import LibraryCardHeader, { HeaderConfig } from '../LibraryCardHeader';

export const DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH = 300;

interface Props {
    dashboard: DashboardTemplate;
    headerConfig?: HeaderConfig;
    children: ReactNode | ReactNodeArray;
}

export const DashboardTemplateLibraryCard: FunctionComponent<Props> = ({
    dashboard,
    headerConfig,
    children
}: Props) => {
    // Content formatting
    const truncatedDescription = useMemo(
        () => truncate(dashboard.description, { length: LIBRARY_CARD_DESCRIPTION_MAX_LENGTH }),
        [dashboard.description]
    );

    return (
        <LibraryCard width={DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH}>
            {/* Header */}
            <LibraryCardHeader
                config={
                    headerConfig || {
                        variant: 'rectangle-img',
                        backgroundColor: dashboard.icon?.color,
                        imgDataUrl: dashboard.imgDataUrl
                    }
                }
            />

            {/* Body */}
            <>
                {/* Title */}
                <Card.Title className="d-flex align-items-center mw-100">
                    <div className="text-truncate" title={dashboard.name}>
                        {dashboard.name}
                    </div>
                </Card.Title>

                {/* Description */}
                <Card.Text className="text-dark" title={dashboard.description}>
                    {truncatedDescription}
                </Card.Text>
            </>

            {/* Main action button */}
            {children}
        </LibraryCard>
    );
};
