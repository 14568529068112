import { ChartSeriesType, WidgetChartType, WidgetKpiType } from 'components/Dashboarding/Models/Widget';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import PreviewTable from './PreviewTable';
import { RichMessage } from 'components/RichMessage';
import FilterBoxButton from '../FilterBoxButton';
import { debounce } from 'lodash';

interface Props {
    onChange: (widget: WidgetChartType) => void;
    seriesIndex?: number;
    widget: WidgetChartType;
}

// Allows the user to configure the dimensions and metrics to be used by
// the widget and visualize the data in a table.
const ConfigureTable: FunctionComponent<Props> = ({ onChange, seriesIndex = 0, widget }: Props) => {
    // State
    const [localWidget, setLocalWidget] = useState<WidgetChartType | WidgetKpiType>(widget);

    // Get series to configure
    const series = localWidget.config.series[seriesIndex];

    // Debounced onChange
    // This is used in combination with a local state to apply updates on screen faster
    const debouncedOnChange = useMemo(() => debounce(onChange), [onChange]);

    // Hook invoked when the main series get updated
    const onSeriesChange = useCallback(
        (series: ChartSeriesType) => {
            // Update the series at index
            const updatedSeries = [...localWidget.config.series];
            updatedSeries[seriesIndex] = series;

            // Update widget
            const updatedWidget = {
                ...localWidget,
                config: { ...localWidget.config, series: updatedSeries }
            } as WidgetChartType;

            // Apply changes
            setLocalWidget(updatedWidget);
            debouncedOnChange(updatedWidget);
        },
        [debouncedOnChange, localWidget, seriesIndex]
    );

    // Resync local state from parent
    useEffect(() => setLocalWidget(widget), [widget]);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h4 className="text-dark mb-1">
                        <span className="elevio-configure-table-title">
                            <RichMessage id="dashboarding.widget-editor.configure-table.heading.title" />
                        </span>
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage id="dashboarding.widget-editor.configure-table.heading.description" />
                    </div>
                </div>
                <FilterBoxButton
                    className="me-4"
                    fact={localWidget.config.fact}
                    onChange={onSeriesChange}
                    series={series}
                    variant="outline-dark"
                />
            </div>

            <PreviewTable
                fact={localWidget.config.fact}
                configType={localWidget.configType}
                series={series}
                onChange={onSeriesChange}
            />
        </div>
    );
};

export default ConfigureTable;
