import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { GithubMarkdown } from 'components/TextProcessor';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { HelpIcon, RichMessage } from 'components/RichMessage';
import { NEUTRAL_GREY_1 } from 'constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kpXMark } from 'util/customIcons';
import { useBreakpoint } from 'util/useBreakpoint';

interface Props {
    widget: WidgetFactType;
}

const WidgetDocumentationTooltip: FunctionComponent<Props> = ({ widget }) => {
    const breakpoint = useBreakpoint();
    const bigScreen = useMemo(() => ['md', 'lg', 'xlg', 'xxl'].includes(breakpoint), [breakpoint]);

    const [show, setShow] = useState(false);

    return (
        <HelpIcon placement={bigScreen ? 'right-start' : 'bottom'} onToggle={() => setShow(!show)} show={show}>
            <>
                <div className="tooltip-header d-flex justify-content-between">
                    <h5>
                        <RichMessage id="components.widget-documentation-tooltip.title.label" />
                    </h5>
                    <FontAwesomeIcon
                        icon={kpXMark}
                        onClick={() => setShow(false)}
                        color={NEUTRAL_GREY_1}
                        className="cursor-pointer ms-4"
                    />
                </div>
                <div className="tooltip-content overflow-y-auto">
                    {!widget.description && !widget.documentation && (
                        <RichMessage id="components.widget-documentation-tooltip.no-documentation" />
                    )}
                    {widget?.description && <p>{widget.description}</p>}

                    {widget?.documentation && (
                        <div>
                            <GithubMarkdown markup={widget.documentation} />
                        </div>
                    )}
                </div>
            </>
        </HelpIcon>
    );
};

export default WidgetDocumentationTooltip;
