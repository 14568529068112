import { cleanupTemplateMarkup } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import { WidgetFactTemplate } from 'components/Dashboarding/Models/WidgetTemplates';
import { RichMessage } from 'components/RichMessage';
import { dashboardWidgetNewPath } from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface BlankTemplateButtonProps {
    dashboardId: string;
    widget: WidgetFactTemplate;
}

const WidgetBlankTemplateButton: FunctionComponent<BlankTemplateButtonProps> = ({ dashboardId, widget }) => {
    // Services
    const navigate = useNavigate();
    const intl = useIntl();

    // Hook invoked to create a new widget from a blank template
    const handleCreateFromBlankTemplate = useCallback(() => {
        // Template cleanup
        const formattedWidget = cleanupTemplateMarkup(widget);

        // Redirect to advanced builder
        const path = dashboardWidgetNewPath({
            dashboardId,
            template: {
                ...formattedWidget,
                name:
                    widget.name +
                    intl.formatMessage({ id: `dashboarding.add-insight-modal.widget-library.title.suffix` })
            }
        });
        navigate(path);
    }, [dashboardId, intl, navigate, widget]);

    return (
        <Button
            variant="dark"
            onClick={handleCreateFromBlankTemplate}
            className="rounded-0 rounded-bottom justify-content-center w-100"
        >
            <RichMessage id="components.library-card.create-insight-from-template" />
        </Button>
    );
};

export default WidgetBlankTemplateButton;
