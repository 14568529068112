import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { SeriesCardColumn, WidgetMiniCard } from 'components/Dashboarding/Models/Widget';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import ColumnEditSummary from './ColumnEditSummary';
import ColumnEditModal from './ColumnEditModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { WIDGET_DEFAULT_SERIES_COLOR } from 'constants/colors';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';

interface Props {
    widget: WidgetMiniCard;
    column: SeriesCardColumn;
    colIndex: number;
    colCount: number;
    onChange: (column: SeriesCardColumn, colIndex: number) => void;
    onReorder: (colIndex: number, direction: 'left' | 'right') => void;
    onDelete: (colIndex: number) => void;
}

const ColumnEditBox: FunctionComponent<Props> = ({
    widget,
    column,
    colIndex,
    colCount,
    onChange,
    onReorder,
    onDelete
}: Props) => {
    // Services
    const intl = useRichIntl();

    // State
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Infer state
    const canRemove = colCount > 1;
    const canMoveLeft = colIndex > 0;
    const canMoveRight = colIndex < colCount - 1;
    const colLabel =
        column.formatting?.label ||
        intl.formatMessage({ id: 'dashboarding.widget-configuration.axis.MINICARD.column.*' }, { index: colIndex + 1 });

    // Evaluate top heading style
    const columnColor = useMemo(() => column.formatting?.color || WIDGET_DEFAULT_SERIES_COLOR, [
        column.formatting?.color
    ]);
    const columnLabelStyle = useMemo(() => {
        const bgColor = shadeHexColor(columnColor, 0.85);
        const txtColor = textColorFromBg(columnColor, columnColor, shadeHexColor(columnColor, -0.5));
        return { backgroundColor: bgColor, color: txtColor };
    }, [columnColor]);

    // Move up action
    const moveLeft = useCallback((): void => onReorder(colIndex, 'left'), [colIndex, onReorder]);

    // Move down action
    const moveRight = useCallback((): void => onReorder(colIndex, 'right'), [colIndex, onReorder]);

    // Remove column action
    const removeColumn = useCallback((): void => onDelete(colIndex), [colIndex, onDelete]);

    // Hook invoked when the column configuration gets changed via the modal
    const onColumnChange = useCallback(
        (e: SeriesCardColumn) => {
            onChange(e, colIndex);
            setIsOpen(false);
        },
        [colIndex, onChange]
    );

    return (
        <div className="d-flex flex-column border rounded me-4" style={{ minWidth: '12vw' }}>
            {/* Box header */}
            <div className="d-flex justify-content-between align-items-center border-bottom">
                {/* Column name */}
                <div className="d-flex py-3 px-3 flex-grow-1 fw-bold" style={columnLabelStyle}>
                    {colLabel}
                </div>

                {/* Triple-dot menu */}
                <Dropdown className="h-100 border-start border-grey-6">
                    {/* Toggle */}
                    <Dropdown.Toggle
                        as="div"
                        bsPrefix="none"
                        className="h-100 px-3 d-flex justify-content-center align-items-center"
                        role="button"
                    >
                        <FontAwesomeIcon icon={faEllipsisV} className="text-muted" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        <Dropdown.Item disabled={!canMoveLeft} onClick={moveLeft}>
                            <RichMessage id="dashboarding.widget-editor.configure-columns.box.move-left" />
                        </Dropdown.Item>
                        <Dropdown.Item disabled={!canMoveRight} onClick={moveRight}>
                            <RichMessage id="dashboarding.widget-editor.configure-columns.box.move-right" />
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item disabled={!canRemove} onClick={removeColumn}>
                            <RichMessage id="dashboarding.widget-editor.configure-columns.box.remove-column" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {/* Filter summary */}
            <div
                className="flex-grow-1 d-flex justify-content-center align-items-center text-muted m-4"
                onClick={() => setIsOpen(true)}
                role="button"
            >
                <ColumnEditSummary column={column} widget={widget} />
            </div>

            {/* Configuration modal */}
            <ColumnEditModal
                colIndex={colIndex}
                column={column}
                widget={widget}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                onSave={onColumnChange}
            />
        </div>
    );
};

export default ColumnEditBox;
