import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';
import { ReactComponent as SuccessSvg } from 'assets/img/toasts/success.svg';
import { ReactComponent as UpdateSuccessSvg } from 'assets/img/toasts/update-success.svg';
import { ReactComponent as WarningSvg } from 'assets/img/toasts/warning.svg';
import { ReactComponent as IncidentSvg } from 'assets/img/toasts/incident.svg';
import { ReactComponent as DisabledSvg } from 'assets/img/toasts/disabled.svg';
import { ReactComponent as CloseButtonSvg } from 'assets/img/toasts/close-button.svg';
import toast, { Toast as ReactHotToast } from 'react-hot-toast';
import classNames from 'classnames';
import { ErrorMessage, QueryError } from 'components/ErrorManagement';
import { ERROR_TOAST_DURATION } from 'constants/defaultValues';

export const TOAST_TYPES = ['success', 'update-success', 'warning', 'incident', 'disabled'] as const;
export type ToastType = typeof TOAST_TYPES[number];

interface Props {
    t: ReactHotToast;
    type: ToastType;
    children: ReactNode | ReactNodeArray;
}

// Abstract toast wrapper
export const toastError = ({
    type = 'incident',
    children
}: {
    type: ToastType;
    children: ReactNode | ReactNodeArray;
}): void => {
    toast.custom(
        t => (
            <Toast t={t} type={type}>
                {children}
            </Toast>
        ),
        { duration: ERROR_TOAST_DURATION }
    );
};

// Toast for apollo query errors
export const toastQueryError = (error: Partial<QueryError>): void => {
    const actualError = { code: 'default', ...error };

    toastError({
        type: 'incident',
        children: () => <ErrorMessage error={actualError} className="text-danger mt-2" />
    });
};

export const Toast: FunctionComponent<Props> = ({ t, type, children }: Props) => {
    let icon;
    switch (type) {
        case 'success':
            icon = <SuccessSvg />;
            break;
        case 'update-success':
            icon = <UpdateSuccessSvg />;
            break;
        case 'warning':
            icon = <WarningSvg />;
            break;
        case 'incident':
            icon = <IncidentSvg />;
            break;
        case 'disabled':
            icon = <DisabledSvg />;
            break;
    }

    return (
        <div className={classNames('kp-toast', !t.visible && 'slide-out')}>
            <div className="kp-toast__icon">{icon}</div>
            <div className="kp-toast__description">{children}</div>
            <div className="kp-toast__closeButton" onClick={() => toast.dismiss(t.id)}>
                <CloseButtonSvg />
            </div>
        </div>
    );
};
