import { ObjectFieldSchema, PrimitiveFieldSchema } from 'api/viz/queries/CustomField';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import {
    QueryOpField,
    QUERY_OP_CUSTOM_FIELD_PREFIX,
    QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING,
    QUERY_OP_FIELD_TRAVERSING_SEPARATOR,
    QUERY_OP_FIELD_WILDCARD_SELECTOR
} from 'components/Dashboarding/DataSource/QueryOperators';
import { useMemo } from 'react';
import { split2 } from 'util/StringOperators';
import { useQueryOpFields } from './useQueryOpFields';

export const customFieldSplitHeadTail = (val: string | undefined): string[] => {
    if (!val) return [];

    if (val.startsWith(`${QUERY_OP_CUSTOM_FIELD_PREFIX}.`)) {
        const parts = split2(val.replace(`${QUERY_OP_CUSTOM_FIELD_PREFIX}.`, ''), QUERY_OP_FIELD_TRAVERSING_SEPARATOR);
        return [`${QUERY_OP_CUSTOM_FIELD_PREFIX}.${parts[0]}`, parts[1]];
    } else {
        return split2(val, QUERY_OP_FIELD_TRAVERSING_SEPARATOR);
    }
};

// Retrieve the end schema of a field
const getCustomFieldSchema = (
    field: QueryOpField | undefined,
    subChainVal: string | undefined
): PrimitiveFieldSchema | ObjectFieldSchema | null => {
    if (!field) return null;

    // Extract sub-chain
    const subChain = subChainVal?.split('.') || [];

    // The chain points to a specific element with a type
    // Discover the end-of-chain type
    return (
        subChain.reduce((prev, curr) => {
            // Skip array positions (0, 1, 2 etc..) in the chain
            if (!isNaN(+curr) || curr === QUERY_OP_FIELD_WILDCARD_SELECTOR) return prev;

            const subSchema = (prev?.schema as ObjectFieldSchema) || {};
            return subSchema[curr];
        }, field.schema) || null
    );
};

// Removes the parentField URI from a fieldUri
export function childFieldValueChain(
    parentField: QueryOpField | undefined | null,
    fieldUri: string | undefined
): string {
    if (!parentField || !fieldUri) return '';

    return (
        fieldUri
            ?.replace(`${parentField.name}${QUERY_OP_FIELD_TRAVERSING_SEPARATOR}`, '')
            .replace(`${parentField.name}`, '') || ''
    );
}

// Build a RulesetField from custom field schema
export function rulesetFieldFromCustomField(
    fieldName: string,
    fieldSchema: PrimitiveFieldSchema | ObjectFieldSchema,
    opts?: { array?: boolean }
): QueryOpField {
    const nativeSchema = fieldSchema as PrimitiveFieldSchema;
    const typeMapping = QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING[nativeSchema.type];
    const operators = opts?.array ? QUERY_OP_CUSTOM_FIELD_TYPE_MAPPING['ARRAY'].filters : typeMapping?.filters;
    const displayType = nativeSchema.values ? 'select' : typeMapping?.filterDisplayType;

    return {
        id: fieldName,
        name: fieldName,
        label: fieldName,
        type: typeMapping?.type,
        usageScopes: ['filter'],
        filterDisplayType: displayType,
        filterValues: nativeSchema.values,
        schema: {
            type: nativeSchema.type,
            array: nativeSchema.array,
            schema: nativeSchema.schema
        },
        filters: operators
    };
}

interface LookupOpts {
    fieldUri?: string;
    fact: QueryOpFact;
    matchParent?: boolean;
    fieldList?: QueryOpField[];
}

// Retrieve a field or nested field configuration based on a field URI (dot notation)
export function useQueryOpFieldLookup({ fact, fieldUri, matchParent, fieldList }: LookupOpts): QueryOpField | null {
    // Evaluate field pool to use for the lookup
    const { queryOpFields } = useQueryOpFields({ fact });
    const allFields = fieldList || queryOpFields;

    // Memoize result
    const rs = useMemo(() => {
        // Abort if no fieldUri
        if (!fieldUri) return null;

        // If a native field is found, return it immediately
        const nativeField = allFields.find(e => e.name === fieldUri);
        if (nativeField) return nativeField;

        // No native field found. We're dealing with a nested field
        // Break down field into a chain of fields (split on '.' separator)
        const uriHeadTail = customFieldSplitHeadTail(fieldUri);

        // Find the parent native field by looking up fields using header information
        // (e.g. custom_fields.foo for a fieldUri set to custom_fields.foo.bar.baz)
        const parentField = allFields.find(e => e.name === uriHeadTail[0]);
        if (!parentField) return null;

        // Break here if only parent is required
        if (matchParent) return parentField;

        // Find the schema
        const nestedFieldSchema = getCustomFieldSchema(parentField, uriHeadTail[1]);
        if (!nestedFieldSchema) return null;

        // Build a ruleset field using the nested field schema
        const fieldName = uriHeadTail[1].split(QUERY_OP_FIELD_TRAVERSING_SEPARATOR).slice(-1)[0];
        const isArray = fieldUri.includes(QUERY_OP_FIELD_WILDCARD_SELECTOR);
        return rulesetFieldFromCustomField(fieldName, nestedFieldSchema, { array: isArray });
    }, [fieldUri, matchParent, allFields]);

    return rs;
}
