import React, { FunctionComponent } from 'react';
import { WidgetColorRange } from 'components/Dashboarding/Models/Widget';
import PaletteColorPicker from 'components/Design/ColorPickers/PaletteColorPicker';
import { RichMessage } from 'components/RichMessage';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface Props {
    className?: string;
    onChange: (r?: WidgetColorRange) => void;
    type: 'lowest' | 'middle' | 'highest';
    range: WidgetColorRange;
    isDeletable: boolean;
}

const ColorBracket: FunctionComponent<Props> = ({ className, onChange, type, range, isDeletable }: Props) => {
    // Render
    return (
        <div className={classNames('d-flex align-items-center', className)}>
            {/* Color */}
            <PaletteColorPicker color={range[0]} onChange={e => onChange([e, range[1]])} className="me-2" />

            {/* Applies to */}
            <div className="py-3 px-2 text-dark fw-bold">
                <RichMessage id={`dashboarding.widget-editor.gradient-based-color-config.${type}-value`} />
            </div>

            {/* Delete Button */}
            {isDeletable && (
                <div role="button" className="text-light ms-4" onClick={() => onChange(undefined)}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};

export default ColorBracket;
