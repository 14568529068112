import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityResp } from 'api/hq/queries/Identity';
import classnames from 'classnames';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent } from 'react';

interface Props {
    identity?: IdentityResp;
    notRequired?: boolean;
}

const IdentityConnectionStatus: FunctionComponent<Props> = ({ identity, notRequired }: Props) => {
    return identity ? (
        <>
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            <span className="ms-2 text-dark">
                <RichMessage
                    id="settings.app-management.identity-item.connected"
                    values={{ username: identity.username }}
                />
            </span>
        </>
    ) : (
        <>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
            <span className={classnames('ms-2', { 'text-grey-4': notRequired })}>
                {notRequired ? (
                    <RichMessage id="settings.app-management.identity-item.disconnected-not-required" />
                ) : (
                    <RichMessage id="settings.app-management.identity-item.disconnected" />
                )}
            </span>
        </>
    );
};

export default IdentityConnectionStatus;
