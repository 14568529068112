import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import Card from 'react-bootstrap/Card';
import { InboxItem } from 'api/viz/queries/InboxItem';
import ItemTitle from 'components/InboxCard/ItemTitle';
import InboxCardModal from 'components/InboxCard/InboxCardModal';
import ItemUserIconList from 'components/InboxCard/ItemUserIconList';
import BoardCardLoader from './BoardCardLoader';
import { useInboxItemRelated } from 'api/viz/hooks/useInboxItemRelated';
import { CompanyUser } from 'api/hq/queries/CompanyUser';
import { shadeHexColor } from 'util/ColorOperators';
import moment from 'moment';
import classNames from 'classnames';
import { openInANewTab } from 'constants/routeBuilders';

interface Props {
    item: InboxItem;
    refreshing?: boolean;
    filterUser?: CompanyUser;
}

const BoardCard: FunctionComponent<Props> = ({ item, refreshing, filterUser }: Props) => {
    // State
    const [selectedItem, setSelectedItem] = useState<InboxItem | undefined>(undefined);

    // Fetch related items
    const { initialLoading: inboxItemLoading, inboxItem } = useInboxItemRelated({ inboxItem: item });

    // Method used when a card is selected. This eventually displays the modal with full information.
    // If ctrl/command key used, the card external url will be opened
    const selectItem = useCallback(
        e => {
            if (e.ctrlKey || e.metaKey) {
                openInANewTab(item.url);
            } else {
                setSelectedItem(inboxItem), [inboxItem];
            }
        },
        [inboxItem, item.url]
    );

    // Check if the item is overdue
    const isOverdue = useMemo(() => item.due_on && moment(item.due_on) < moment(), [item.due_on]);

    // Return null if the current item the selected user
    if (filterUser && inboxItem.recommended_actors.filter(a => filterUser?.refs.includes(a.ref)).length === 0) {
        return null;
    }

    // Return if dependencies are loading
    if (inboxItemLoading) {
        return <BoardCardLoader />;
    }

    // Display item
    return (
        <Fragment>
            {/* Full view modal */}
            <InboxCardModal
                item={inboxItem}
                refreshing={refreshing}
                isOpen={!!selectedItem}
                toggle={() => setSelectedItem(undefined)}
            />

            {/* Card */}
            <Card className="board-card mb-3">
                <Card.Body className="d-flex flex-column justify-content-between m-0 px-3 py-2">
                    {/* Header */}
                    <div className="mb-3">
                        {/* Title */}
                        <div onClick={selectItem} role="button">
                            <div className="fw-semibold w-100 text-truncate" title={inboxItem.project.name}>
                                {inboxItem.project.name}
                            </div>
                            <ItemTitle item={inboxItem} prefixType />
                        </div>

                        {/* Labels */}
                        <div className="d-flex flex-wrap my-2">
                            {inboxItem.labels.map((label, index) => (
                                <div
                                    key={index}
                                    className="label fs-sm text-center rounded-pill px-2 py-1 me-1 mb-1 fw-bold"
                                    style={{ backgroundColor: shadeHexColor(`#${label.color}`, 0.8) }}
                                >
                                    <span style={{ color: shadeHexColor(`#${label.color}`, -0.8) }}>{label.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Additional information */}
                    <div className="d-flex align-items-center justify-content-between">
                        {/* Due date */}
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center me-1">
                                <FontAwesomeIcon icon={faCalendar} size="xs" />
                            </div>
                            {inboxItem.due_on ? (
                                <span className="fs-sm fw-bold">
                                    <Moment format="DD MMM YYYY">{inboxItem.due_on}</Moment>
                                    <span className={classNames('ms-1', { 'text-danger': isOverdue })}>
                                        (<Moment fromNow>{inboxItem.due_on}</Moment>)
                                    </span>
                                </span>
                            ) : (
                                <span className="text-muted fs-sm">
                                    <FormattedMessage id="sprint-board.no-due-date" />
                                </span>
                            )}
                        </div>

                        {/* Actors */}
                        <ItemUserIconList users={inboxItem.recommended_actors} />
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default BoardCard;
