import {
    WIDGET_COLOR_PALETTE,
    FONT_FAMILY_BASE,
    NEUTRAL_GREY_5,
    PRIMARY_DARK_BLUE,
    PRIMARY_NORMAL_BLUE,
    NEUTRAL_GREY_7,
    NEUTRAL_GREY_10,
    WIDGET_GRADIENT_COLORS,
    WIDGET_THRESHOLD_COLORS
} from 'constants/colors';
import { merge, uniq } from 'lodash';
import { useMemo } from 'react';
import { usePrevious } from 'react-use';
import {
    ChartSeriesType,
    MetricFormattingOptions,
    WidgetChartConfigType,
    WidgetColorRange,
    WidgetKpiConfigType,
    WidgetLineStyleType,
    WidgetMetricField
} from '../Models/Widget';
import Highcharts, { ColorAxisOptions, PointClickEventObject } from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);
import moment, { utc } from 'moment';
import { useRichIntl } from 'components/RichMessage';
import { IntlShape } from 'react-intl';
import { Coordinates } from 'contexts/DrilldownContext';
import { METRIC_IDENTIFIER } from 'constants/defaultValues';

// Config type accepted in the hook
type SupportedConfigType = WidgetChartConfigType | WidgetKpiConfigType;
type SupportedHcSeriesType = 'areaspline' | 'bar' | 'column' | 'heatmap' | 'pie' | 'spline';

// List of Highcharts chart compatible with time series
const TIME_SERIES_CHARTS: SupportedHcSeriesType[] = ['areaspline', 'column', 'spline'];

export interface PickEvent {
    srcIndex: number;
    coordinates: Coordinates;
}

interface Props {
    chartType: SupportedConfigType;
    forceXAxisCategories?: boolean;
    options: Partial<Highcharts.Options>;
    records: Record<string, string>[];
    series?: ChartSeriesType;
    forcedMessageId?: string;
    showDataLabel?: boolean;
    onPick?: (e: PickEvent) => void;
    skip?: boolean;
}

// Dash style mapping
const WIDGET_LINE_STYLE_HC_MAPPING: { [key in WidgetLineStyleType]: Highcharts.DashStyleValue } = {
    solid: 'Solid',
    dashed: 'Dash'
};

// Chart types
const WIDGET_TYPE_HC_MAPPING: {
    [key in SupportedConfigType]: SupportedHcSeriesType;
} = {
    AREACHART: 'areaspline',
    BARCHART: 'bar',
    COLUMNCHART: 'column',
    HEATMAPCHART: 'heatmap',
    LINECHART: 'spline',
    NUMBERKPI: 'spline',
    PIECHART: 'pie',
    SPARKLINEKPI: 'areaspline',
    SPARKCOLUMNKPI: 'column',
    TRENDKPI: 'spline'
};

function getPointCategoryName(point: Highcharts.Point, dimension: string): string {
    const series = point.series;
    const isY = dimension === 'y';
    const axis = series[isY ? 'yAxis' : 'xAxis'];
    const value = point[isY ? 'y' : 'x'];
    if (value == undefined) return '';
    return axis.categories[value];
}

const WIDGET_OPTION_HC_MAPPING: { [key in SupportedConfigType]: Partial<Highcharts.Options> } = {
    AREACHART: {
        plotOptions: {
            areaspline: {
                marker: { enabled: false }
            }
        }
    },
    BARCHART: {},
    COLUMNCHART: {},
    HEATMAPCHART: {
        plotOptions: {
            heatmap: {
                rowsize: 0.95,
                colsize: 0.92
            }
        },
        tooltip: {
            formatter: function() {
                return `${getPointCategoryName(this.point, 'x')}<br>${getPointCategoryName(this.point, 'y')}<br><b>${
                    this.point.value
                }</b> ${this.series.name}`;
            }
        }
    },
    LINECHART: {
        plotOptions: {
            spline: {
                marker: { enabled: false }
            }
        }
    },
    NUMBERKPI: {},
    PIECHART: {
        chart: {
            borderWidth: 0
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                slicedOffset: 0, // Disable the native animation when clicking a slice
                states: {
                    hover: {
                        halo: null
                    }
                }
            }
        }
    },
    SPARKLINEKPI: {
        plotOptions: {
            areaspline: {
                marker: { enabled: false },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, PRIMARY_NORMAL_BLUE],
                        [
                            1,
                            Highcharts.color(PRIMARY_NORMAL_BLUE)
                                .setOpacity(0)
                                .get('rgba')
                                .toString()
                        ]
                    ]
                }
            }
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        }
    },
    SPARKCOLUMNKPI: {
        xAxis: {
            visible: true,
            labels: {
                enabled: false
            }
        },
        yAxis: {
            visible: false
        }
    },
    TRENDKPI: {}
};

// The default behaviour and styling for highcharts
const DEFAULT_HC_OPTIONS: Highcharts.Options = {
    colors: WIDGET_COLOR_PALETTE,
    colorAxis: undefined,
    chart: {
        style: {
            fontFamily: FONT_FAMILY_BASE
        },
        backgroundColor: 'transparent'
    },
    credits: {
        enabled: false
    },
    title: {
        text: undefined
    },
    legend: {
        enabled: false,
        layout: 'vertical',
        align: 'right',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: NEUTRAL_GREY_5,
        floating: false,
        verticalAlign: 'middle',
        title: {
            style: { fontWeight: 'semiBold' }
        }
    },
    plotOptions: {
        series: {
            // Disable initial animation
            animation: false
        }
    },
    tooltip: {
        enabled: true,
        backgroundColor: PRIMARY_DARK_BLUE,
        borderWidth: 0,
        shadow: false,
        style: {
            color: '#fff',
            fontSize: '10px'
        },
        outside: true
    },
    yAxis: {
        title: { text: undefined }
    },
    responsive: {
        rules: [
            {
                // If not present highcharts adds this _id at runtime, which
                // in turns generates useless re-renders.
                _id: '1',
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    plotOptions: {
                        column: {
                            // Do not set pointWidth to a fixed value, the columns would be overlapping if the chart's width is too small
                            // Instead, set pointPadding and groupPadding so that pointWidth is calculated from them and set a maxPointWidth
                            // https://api.highcharts.com/highcharts/plotOptions.column.pointWidth
                            pointPadding: 0,
                            groupPadding: 0,
                            maxPointWidth: 20,
                            borderWidth: 0
                        }
                    }
                }
            } as Highcharts.ResponsiveRulesOptions
        ]
    }
};

// Placeholder data used when the chart is empty
const PLACEHOLDER_TEXT_COLOR = NEUTRAL_GREY_7;
const PLACEHOLDER_SERIES_COLOR = NEUTRAL_GREY_10;
const PLACEHOLDER_RECORDS: Record<string, string>[] = [
    { d0: '1', m0: '15' },
    { d0: '2', m0: '20' },
    { d0: '3', m0: '40' },
    { d0: '4', m0: '50' }
];
const HEATMAP_PLACEHOLDER_RECORDS: Record<string, string>[] = [
    { d0: '1', d1: '1', m0: '15' },
    { d0: '2', d1: '2', m0: '20' },
    { d0: '3', d1: '3', m0: '40' },
    { d0: '4', d1: '4', m0: '50' }
];

const HEATMAP_X_CATEGORIES = HEATMAP_PLACEHOLDER_RECORDS.map(e => e.d0);
const HEATMAP_Y_CATEGORIES = HEATMAP_PLACEHOLDER_RECORDS.map(e => e.d1);

// Generate a placeholder series. Used when there are no data to display
const generatePlaceholderOptions = (
    intl: IntlShape,
    highchartsType: SupportedHcSeriesType,
    placeholderMessageId: string
): Highcharts.Options => {
    const isHeatmap = highchartsType == 'heatmap';
    const isAreaSparkLine = highchartsType == 'areaspline';

    return {
        legend: {
            enabled: false
        },
        series: [
            {
                type: highchartsType,
                name: intl.formatMessage({ id: 'dashboarding.widget.no-data.sample-data' }),
                data: isHeatmap
                    ? HEATMAP_PLACEHOLDER_RECORDS.map(e => [
                          HEATMAP_X_CATEGORIES.indexOf(e['d0']),
                          HEATMAP_Y_CATEGORIES.indexOf(e['d1']),
                          e['m0']
                      ])
                    : PLACEHOLDER_RECORDS.map(e => [e['d0'], parseInt(e['m0'])]),
                // Bar/column/line charts
                color: PLACEHOLDER_SERIES_COLOR,
                // Pie charts
                colors: [
                    Highcharts.color(PLACEHOLDER_SERIES_COLOR)
                        .setOpacity(0.6)
                        .get('rgba')
                        .toString()
                ],
                // Charts with gradient area
                fillColor: isAreaSparkLine ? chartLinearGradient(PLACEHOLDER_SERIES_COLOR) : undefined,
                enableMouseTracking: false
            }
        ],
        title: {
            text: intl.formatMessage({ id: placeholderMessageId }),
            verticalAlign: 'middle',
            style: {
                color: PLACEHOLDER_TEXT_COLOR,
                fontSize: '30px',
                fontWeight: 'bold'
            }
        },
        tooltip: { enabled: false },
        xAxis: {
            categories: isHeatmap ? HEATMAP_X_CATEGORIES : PLACEHOLDER_RECORDS.map(e => e['d0']),
            type: 'category',
            labels: { enabled: false },
            title: {
                style: { color: PLACEHOLDER_TEXT_COLOR }
            },
            gridLineWidth: isHeatmap ? 0 : undefined,
            lineWidth: isHeatmap ? 0 : undefined
        },
        yAxis: {
            categories: isHeatmap ? HEATMAP_Y_CATEGORIES : undefined,
            labels: { enabled: false },
            title: {
                style: { color: PLACEHOLDER_TEXT_COLOR }
            },
            gridLineWidth: isHeatmap ? 0 : undefined,
            lineWidth: isHeatmap ? 0 : undefined
        }
    };
};

// Get fill gradient
const chartLinearGradient = (color: string): Highcharts.GradientColorObject => {
    return {
        linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
        },
        stops: [
            [0, color],
            [
                0.8,
                Highcharts.color(color)
                    .setOpacity(0.1)
                    .get('rgba')
                    .toString()
            ],
            [
                1,
                Highcharts.color(color)
                    .setOpacity(0)
                    .get('rgba')
                    .toString()
            ]
        ]
    };
};

// Format dimension value based on type
const formatDimension = (value: string | string[] | boolean | boolean[]): string => {
    if (Array.isArray(value)) {
        return value.length == 0 ? '[empty]' : value.join(', ');
    } else if (value == null || value == undefined) {
        return '[null]';
    } else {
        return value.toString();
    }
};

// Build the color axis property for heatmap charts, from the formatting options.
// - for `gradient` color mode, it translates to `stops` in the highcharts configuration.
// - for `threshold` color mode, it translates to `dataClasses`  in the highcharts configuration.
const buildColorAxis = (
    formatting?: MetricFormattingOptions
): (ColorAxisOptions | Array<ColorAxisOptions>) | undefined => {
    if (!formatting || !formatting.colorMode || formatting.colorMode == 'gradient') {
        return {
            stops: (formatting?.colorRanges || WIDGET_GRADIENT_COLORS).map((cr, index) => [
                index / 2,
                cr?.[0] || '#ffffff'
            ])
        };
    } else if (formatting.colorMode == 'threshold') {
        const sortedRanges: WidgetColorRange[] = [...(formatting?.colorRanges || WIDGET_THRESHOLD_COLORS)].sort(
            (e1, e2) => (e1[1] || 0) - (e2[1] || 0)
        );
        return {
            dataClasses: sortedRanges.map((cr, index) => ({
                from: cr[1] || 0,
                to: sortedRanges?.[index + 1]?.[1],
                color: cr[0]
            }))
        };
    }
};

export function useHighchartsOptions({
    chartType,
    forceXAxisCategories,
    options,
    records,
    series,
    forcedMessageId,
    showDataLabel,
    onPick,
    skip
}: Props): [Highcharts.Options, boolean] {
    // Services
    const intl = useRichIntl();

    // Retrieve the actual highcharts chart type
    const highchartsType = WIDGET_TYPE_HC_MAPPING[chartType];

    // Specific widget options
    const widgetOptions = WIDGET_OPTION_HC_MAPPING[chartType];

    // Track isTimeSeries changes. When switching from time series to categories
    // Highcharts doesn't properly switch from 'datetime' mode to 'categories' mode via chart.update
    // so when isTimeSeries changes we must enable the 'immutable' prop on the chart to redraw it from
    // scratch.
    const isChartTimeSeriesCompatible = TIME_SERIES_CHARTS.includes(highchartsType);
    const isDataTimeSeries = useMemo(() => records[0] && moment(records.filter(e => !!e['d0'])[0]?.['d0']).isValid(), [
        records
    ]);
    const isTimeSeries = !forceXAxisCategories && isDataTimeSeries && isChartTimeSeriesCompatible;
    const prevIsTimeSeries = usePrevious(isTimeSeries);
    const timeSeriesChanged = isTimeSeries != prevIsTimeSeries;

    /**
     * Build series chart options
     */
    const seriesOptions: Highcharts.Options = useMemo(() => {
        if (!series?.metrics || skip) return {};

        // Return placeholder series if a message is forced to be displayed
        if (forcedMessageId) return generatePlaceholderOptions(intl, highchartsType, forcedMessageId);

        // Return placeholder series if there are no data to display
        if (records.length == 0)
            return generatePlaceholderOptions(intl, highchartsType, 'dashboarding.widget.no-data.title');

        // Format series
        const displayRecords = isTimeSeries
            ? [...records].filter(e => e['d0']).sort((a, b) => a['d0'].toString().localeCompare(b['d0'].toString()))
            : records;

        // Heatmap chart type
        if (chartType == 'HEATMAPCHART') {
            // Build categories
            const xCategories = uniq(records.map(e => formatDimension(e['d0'])));
            const yCategories = uniq(records.map(e => formatDimension(e['d1'])));

            // Build data
            const data = records.map(r => [
                xCategories.findIndex(d0 => d0 === formatDimension(r['d0'])),
                yCategories.findIndex(d0 => d0 === formatDimension(r['d1'])),
                r['m0']
            ]);

            // Is the drilldown enabled for the heatmap
            const isDrilldownEnabled = series.drilldownConfigs?.[0]?.enabled;

            return {
                colorAxis: { ...buildColorAxis(series.metrics[0]?.formatting) },
                series: [
                    {
                        type: highchartsType,
                        name: series.metrics[0].formatting?.label || series.metrics[0].function,
                        data,
                        dataLabels: {
                            enabled: showDataLabel,
                            style: {
                                color: 'contrast',
                                textOutline: 'none'
                            }
                        }
                    }
                ],
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                click: (e: PointClickEventObject) => {
                                    if (onPick)
                                        onPick({
                                            srcIndex: e.point.series.index,
                                            coordinates: [
                                                getPointCategoryName(e.point, 'x'),
                                                getPointCategoryName(e.point, 'y'),
                                                e.point.value ?? undefined
                                            ]
                                        });
                                }
                            }
                        },
                        cursor: isDrilldownEnabled ? 'pointer' : undefined
                    }
                },
                xAxis: {
                    categories: xCategories,
                    type: 'category',
                    gridLineWidth: 0,
                    lineWidth: 0
                },
                yAxis: {
                    categories: yCategories,
                    type: 'category',
                    gridLineWidth: 0,
                    lineWidth: 0
                }
            };
        } else {
            // Other chart types
            const ySeries = series.metrics.map(
                (metric: WidgetMetricField, index: number): Highcharts.SeriesOptionsType => {
                    const color = metric.formatting?.color || PRIMARY_NORMAL_BLUE;
                    const accessor = `${METRIC_IDENTIFIER}${index}`;
                    const data = isTimeSeries
                        ? displayRecords.filter(e => !!e['d0']).map(e => [utc(e['d0']).valueOf(), e[accessor]])
                        : displayRecords.map(e => [formatDimension(e['d0']), e[accessor]]);
                    const isDrilldownEnabled = series.drilldownConfigs?.[index]?.enabled;

                    return {
                        type: highchartsType,
                        name: metric.formatting?.label || metric.function,
                        data: data,
                        color: color,
                        dashStyle: metric.formatting?.lineStyle
                            ? WIDGET_LINE_STYLE_HC_MAPPING[metric.formatting?.lineStyle]
                            : undefined,
                        fillColor: highchartsType == 'areaspline' ? chartLinearGradient(color) : undefined,
                        cursor: isDrilldownEnabled ? 'pointer' : undefined
                    };
                }
            );
            return {
                series: ySeries,
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                click: (e: PointClickEventObject) => {
                                    if (onPick) {
                                        const seriesIndex = e.point.series.index;
                                        onPick({
                                            srcIndex: seriesIndex,
                                            coordinates: [
                                                displayRecords[e.point.index]['d0'],
                                                displayRecords[e.point.index][`${METRIC_IDENTIFIER}${seriesIndex}`]
                                            ]
                                        });
                                    }
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    categories: isTimeSeries ? undefined : records.map(e => formatDimension(e['d0'])),
                    type: isTimeSeries ? 'datetime' : 'category'
                }
            };
        }
    }, [
        series?.metrics,
        series?.drilldownConfigs,
        skip,
        forcedMessageId,
        intl,
        highchartsType,
        records,
        isTimeSeries,
        chartType,
        showDataLabel,
        onPick
    ]);

    // Memoize customized config
    const resultOptions: Highcharts.Options = useMemo(() => {
        return merge({}, DEFAULT_HC_OPTIONS, widgetOptions, options, seriesOptions);
    }, [options, widgetOptions, seriesOptions]);

    const prevColorAxis: ColorAxisOptions | undefined = usePrevious(seriesOptions.colorAxis as ColorAxisOptions);

    // When switching from a colorAxis with a `dataClasses` property, to a colorAxis with
    // `stops` property, the chart needs to be redrawn
    const colorAxisFromDataClassesToStops = !!(
        (seriesOptions.colorAxis as ColorAxisOptions)?.stops && prevColorAxis?.dataClasses
    );

    const fullChartRedraw = timeSeriesChanged || colorAxisFromDataClassesToStops;

    // Return result
    return [resultOptions, fullChartRedraw];
}
