import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useExplorerReport } from 'api/viz/hooks/useExplorerReport';
import BoardCard from 'components/BoardCard';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import {
    SeriesCardColumn,
    WidgetDimensionField,
    WidgetFactType,
    WidgetMiniCard
} from 'components/Dashboarding/Models/Widget';
import { useRichIntl } from 'components/RichMessage';
import { RuleGroupType } from 'react-querybuilder';
import { WIDGET_DEFAULT_SERIES_COLOR } from 'constants/colors';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';
import { Dropdown, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import WidgetSortSelector from '../../DashboardWidgetEdit/ConfigurationPanel/WidgetSortSelector';
import { immutableSet } from 'util/ObjectOperators';

interface Props {
    column: SeriesCardColumn;
    colCount: number;
    colIndex: number;
    onReloading: (reloading: boolean) => void;
    onChange?: (e: WidgetFactType) => void;
    widget: WidgetMiniCard;
    forcedMessageId?: string;
}

const MiniCardColumn: FunctionComponent<Props> = ({
    column,
    colIndex,
    onReloading,
    widget,
    onChange,
    forcedMessageId
}: Props) => {
    // Services
    const intl = useRichIntl();
    const columnRef = useRef<HTMLDivElement>(null);

    // State
    const [dimensions, setDimensions] = useState<WidgetDimensionField[] | undefined>(undefined);

    // Get redux context
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // The sorting dimension
    const sortingDimension: WidgetDimensionField | undefined = useMemo(() => dimensions?.find(e => e.sort), [
        dimensions
    ]);

    // Change the dimensions sort in this column
    const handleSortChange = useCallback(
        dimensions => {
            setDimensions(dimensions);
            if (onChange) onChange(immutableSet(widget, `config.series[${colIndex}].dimensions`, dimensions));
        },
        [colIndex, onChange, widget]
    );

    // Combine column filters with global filters
    const colFilters: RuleGroupType | undefined = useMemo(() => {
        if (!widget.config.filters) return column.filters;
        if (!column.filters) return widget.config.filters;

        return {
            combinator: 'AND',
            rules: [widget.config.filters, column.filters]
        };
    }, [column.filters, widget.config.filters]);

    // Fetch report
    const { initialLoading, loading, refreshing, normalized: queriedItems } = useExplorerReport({
        fact: widget.config.fact,
        dimensions,
        filters: colFilters,
        userId: user?.id,
        companyId: company?.id
    });

    // Get column label
    const colLabel =
        column.formatting?.label ||
        intl.formatMessage({ id: 'dashboarding.widget-configuration.axis.MINICARD.column.*' }, { index: colIndex + 1 });

    // Evaluate heading style
    const columnColor = useMemo(() => column.formatting?.color || WIDGET_DEFAULT_SERIES_COLOR, [
        column.formatting?.color
    ]);
    const columnLabelStyle = useMemo(() => {
        const bgColor = shadeHexColor(columnColor, 0.85);
        const txtColor = textColorFromBg(columnColor, columnColor, shadeHexColor(columnColor, -0.5));
        return { backgroundColor: bgColor, color: txtColor };
    }, [columnColor]);

    // Update parent state when widget reloads
    useEffect(() => onReloading(loading || refreshing), [loading, onReloading, refreshing]);

    // Set the default column dimensions
    useEffect(() => column?.dimensions && setDimensions(column?.dimensions), [column?.dimensions]);

    // Scroll to the top when the sorting is changed
    useEffect(() => columnRef?.current?.scrollTo(0, 0), [sortingDimension]);

    // Display inbox
    return (
        <div className="mini-card-column d-flex flex-column p-1 h-100">
            {/* Column title */}
            <div
                className="col d-flex justify-content-between align-items-center fw-bold rounded px-4 py-2 mb-2"
                style={columnLabelStyle}
            >
                <div className="text-truncate">{colLabel}</div>

                {/* Sorting fields */}
                <WidgetSortSelector fact={widget.config.fact} onChange={handleSortChange} dimensions={dimensions}>
                    <Dropdown.Toggle
                        as="div"
                        bsPrefix="none"
                        className="d-flex justify-content-between align-items-center h-100"
                        variant="link"
                    >
                        <FontAwesomeIcon icon={faSort} className="cursor-pointer" />
                    </Dropdown.Toggle>
                </WidgetSortSelector>
            </div>

            {/* List of column cards */}
            <div ref={columnRef} className="d-flex flex-column overflow-y-auto h-100">
                {/* List of items */}
                {initialLoading ? (
                    <Spinner animation="border" variant="primary" className="mx-auto mt-4" />
                ) : forcedMessageId ? (
                    <span className="d-flex align-items-center justify-content-center text-center text-grey-7 fw-bold h-100">
                        <FormattedMessage id={forcedMessageId} />
                    </span>
                ) : queriedItems.length ? (
                    queriedItems.map(e => <BoardCard key={e._system_id} item={e} refreshing={refreshing} />)
                ) : (
                    <span className="d-flex align-items-center justify-content-center text-center text-grey-7 fw-bold h-100">
                        <FormattedMessage id="dashboarding.widget.no-data.title" />
                    </span>
                )}
            </div>
        </div>
    );
};

export default MiniCardColumn;
