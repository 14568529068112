import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { GetCompanyMembershipsResp, GET_COMPANY_MEMBERSHIPS, Membership } from '../queries/CompanyMembership';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { SUBSCRIBE_TO_COMPANY_UPDATED } from '../queries/Company';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
}

interface CompanyMembershipsQueryResults extends QueryResult<GetCompanyMembershipsResp, OperationVariables> {
    normalized: Membership[];
}

// TODO: implement pagination
export function useCompanyMemberships({ companyId, skip }: QueryOpts): CompanyMembershipsQueryResults {
    // Retrieve company members
    const rs = useQuery<GetCompanyMembershipsResp>(GET_COMPANY_MEMBERSHIPS, {
        skip: skip || !companyId,
        variables: { companyId: companyId }
    });

    // Refetch data when company is updated
    useSafeSubscription(SUBSCRIBE_TO_COMPANY_UPDATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs.refetch();
        }
    });

    // Extract core data
    const normalized = rs.data?.company.memberships.nodes || [];

    return { ...rs, normalized: normalized };
}
