import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import {
    BillingInvoiceResp,
    GetBillingInvoicesResp,
    GET_BILLING_INVOICES,
    SUBSCRIBE_TO_BILLING_INVOICE_CREATED,
    SUBSCRIBE_TO_BILLING_INVOICE_UPDATED
} from '../queries/BillingInvoice';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
}

interface BillingInvoicesQueryResults extends QueryResult<GetBillingInvoicesResp, OperationVariables> {
    normalized: BillingInvoiceResp[];
}

export function useBillingInvoices({ companyId, skip }: QueryOpts): BillingInvoicesQueryResults {
    // Subscribe to billing invoice updates
    const updSub = useSafeSubscription(SUBSCRIBE_TO_BILLING_INVOICE_UPDATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs?.refetch();
        }
    });

    // Subscribe to billing invoice creation
    useSafeSubscription(SUBSCRIBE_TO_BILLING_INVOICE_CREATED, {
        variables: { companyId: companyId },
        skip: skip || !companyId,
        onSubscriptionData: () => {
            rs?.refetch();
        }
    });

    // Get billing subscription after we have successfully subscribed to updates
    const rs = useQuery<GetBillingInvoicesResp>(GET_BILLING_INVOICES, {
        skip: skip || !companyId || updSub.loading,
        variables: { companyId: companyId }
    });

    const normalized = rs.data?.billingSubscription?.billingInvoices.nodes || [];

    return { ...rs, loading: rs.loading || updSub.loading, normalized: normalized };
}
