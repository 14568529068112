import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import { ApolloProvider } from '@apollo/react-hooks';
import { configureStore } from 'redux/store';
import { hqApolloClient } from 'clients/hqApolloClient';

// CSS
import 'assets/sass/main.scss';

// Polyfill for browsers not properly supporting the internationalization API
// See: https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import IntercomWrapper from 'components/IntercomWrapper';

// Show Bootstrap responsive indicator at the top right of the screen
// Only enabled in development
const RESPONSIVE_INDICATOR = process.env.REACT_APP_RESPONSIVE_INDICATOR === 'true';

// Main app
const MainApp: FunctionComponent = () => {
    return (
        <IntercomWrapper>
            <ApolloProvider client={hqApolloClient}>
                <Provider store={configureStore()}>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </Provider>

                {/* Bootstrap Responsive indicator */}
                {RESPONSIVE_INDICATOR && <div className="bootstrap-responsive-indicator"></div>}
            </ApolloProvider>
        </IntercomWrapper>
    );
};

export default MainApp;
