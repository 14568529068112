import React, { FunctionComponent, useMemo } from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
    style?: object;
    label: string;
    size: number;
    margin?: number;
}

const DiamondBullet: FunctionComponent<Props> = ({ className, style, label, size }: Props) => {
    const margin = useMemo(() => size / 8, [size]);

    return (
        <div
            className={classNames('diamond', className)}
            style={Object.assign({}, style, { width: size, height: size, minWidth: size, minHeight: size })}
        >
            <div className="diamond-shell" />
            <div
                className="diamond-text position-absolute w-100 m-auto text-dark fw-bold"
                style={{ lineHeight: `${size}px`, fontSize: `${size / 4}px` }}
            >
                {label}
            </div>
            <div
                className="diamond-inner"
                style={{
                    top: margin,
                    left: margin,
                    height: `calc(100% - ${margin * 2}px)`,
                    width: `calc(100% - ${margin * 2}px)`
                }}
            />
        </div>
    );
};

export default DiamondBullet;
