import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetHSeparator as WidgetHSeparatorType } from 'components/Dashboarding/Models/Widget';
import { Spinner } from 'react-bootstrap';

interface Props {
    isEditable?: boolean;
    isEditing?: boolean;
    loading?: boolean;
    onRemoveWidget?: () => void;
    widget?: WidgetHSeparatorType;
}

const WidgetHSeparator: FunctionComponent<Props> = ({ loading, onRemoveWidget, widget, isEditing }: Props) => {
    // Loading render
    if (loading) return <Spinner animation="border" variant="primary" />;

    // Abort if no widget
    if (!widget) return null;

    return (
        <div
            className={classNames('d-flex justify-content-center align-items-center', 'h-100 w-100 flex-grow-1', {
                'cursor-move': isEditing,
                'bg-white': isEditing,
                'ps-4': isEditing
            })}
        >
            {/* Horizontal line */}
            <div className="w-100 border border-top-0 border-grey-7" />

            {/* Edit mode control buttons */}
            {isEditing && (
                <Button variant="link">
                    <FontAwesomeIcon onClick={onRemoveWidget} icon={faTrash} className="text-danger" />
                </Button>
            )}
        </div>
    );
};

export default WidgetHSeparator;
