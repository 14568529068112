import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { QueryOpFilterScope } from 'components/Dashboarding/DataSource/QueryOperators';
import { useMemo } from 'react';
import { Field } from 'react-querybuilder';

interface UseRulesetFieldsProps {
    fact: QueryOpFact;
    scope?: QueryOpFilterScope;
}

interface UseRulesetFieldsResult {
    rulesetFields: Field[];
    loading: boolean;
}

// Retrieve and map all fields to be used with the RulesetBuilder
export function useRulesetFields({ scope, fact }: UseRulesetFieldsProps): UseRulesetFieldsResult {
    // Get all applicable fields
    const { queryOpFields, loading } = useQueryOpFields({ fact, filterScope: scope, usageScope: 'filter' });

    // Convert them into QueryBuilder Field model
    const rulesetFields: Field[] = useMemo(
        () =>
            queryOpFields.map(e => {
                return {
                    id: e.id,
                    name: e.name,
                    label: e.label
                };
            }),
        [queryOpFields]
    );

    // Memoize result
    const rs = useMemo(() => {
        return { rulesetFields, loading };
    }, [rulesetFields, loading]);

    // Return result
    return rs;
}
