import { useCompanyUsers } from 'api/hq/hooks/useCompanyUsers';
import { ItemUser } from 'api/viz/queries/User';
import React, { CSSProperties, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Gravatar from 'react-gravatar';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { ReduxState } from 'redux/reducers';

interface Props {
    user: ItemUser;
    size?: number;
}

const ItemUserIcon: FunctionComponent<Props> = ({ user, size = 25 }: Props) => {
    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Fetch company users
    const { normalized: companyUsers, loading: usersLoading } = useCompanyUsers({ companyId: company?.id });
    const [isImgLoading, setIsImgLoading] = useState<boolean>(true);

    // Tooltip configuration
    // See bug https://github.com/reactstrap/reactstrap/issues/773#issuecomment-488689148
    const targetRef = useRef<HTMLDivElement | null>(null);
    const [tooltipReady, setTooltipReady] = useState<boolean>(false);
    useEffect(() => {
        if (targetRef.current) {
            setTooltipReady(true);
        }
    }, [usersLoading]);

    // Image hook invoked when an image has finished loading
    const onImgLoaded = useCallback((): void => {
        setIsImgLoading(false);
    }, [setIsImgLoading]);

    // Loading status
    const isLoading = usersLoading || isImgLoading;

    // Retrieve user email
    const provider = user.meta.provider;
    const companyUser = companyUsers.find(e => e.refs.includes(user.ref));
    const userName = companyUser ? `${companyUser?.firstName} (${user.username})` : user.username;
    const userEmail = companyUser?.email || user.username;

    return (
        <div style={{ width: size, height: size }}>
            {/* Image loader */}
            <ImageLoaderPlaceholder size={size} style={isLoading ? undefined : { display: 'none' }} />

            {/* Actual image */}
            <div ref={targetRef}>
                {!companyUser && provider === 'github' ? (
                    <GithubProfileImage
                        username={user.username}
                        size={size}
                        className="rounded-circle border-white"
                        onLoad={onImgLoaded}
                        style={isLoading ? { display: 'none' } : undefined}
                    />
                ) : (
                    <Gravatar
                        email={userEmail}
                        size={size}
                        default="wavatar"
                        className="rounded-circle border-white"
                        onLoad={onImgLoaded}
                        style={isLoading ? { display: 'none' } : undefined}
                    />
                )}
            </div>
            {tooltipReady && targetRef.current && (
                <UncontrolledTooltip placement="top" target={targetRef} className="tooltip-dark">
                    {userName}
                </UncontrolledTooltip>
            )}
        </div>
    );
};

interface GithubProfileImageProps {
    username: string;
    size?: number;
    className?: string;
    onLoad?: () => void;
    style?: CSSProperties;
}

const GithubProfileImage: FunctionComponent<GithubProfileImageProps> = ({
    username,
    className,
    size = 25,
    onLoad,
    style
}: GithubProfileImageProps) => {
    return (
        <img
            alt={username}
            src={`https://github.com/${username}.png?size=${size}`}
            width={size}
            height={size}
            className={className}
            onLoad={onLoad}
            style={style}
        />
    );
};

interface ImageLoaderPlaceholderProps {
    size: number;
    style?: CSSProperties;
}

const ImageLoaderPlaceholder: FunctionComponent<ImageLoaderPlaceholderProps> = ({
    size,
    style
}: ImageLoaderPlaceholderProps) => {
    const radius = size / 2;

    return (
        <ContentLoader
            speed={2}
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            backgroundColor="#f5f5f5"
            foregroundColor="#ededed"
            style={style}
        >
            <circle cx={radius} cy={radius} r={radius} />
        </ContentLoader>
    );
};

export default ItemUserIcon;
