import React, { FunctionComponent, useState, ChangeEvent, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { validateEmail } from 'util/StringOperators';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_INVITE } from 'api/hq/queries/CompanyInvite';
import { QueryError, ErrorMessage } from 'components/ErrorManagement';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

const EMAIL_INVALID_ERROR_KEY = 'email-invalid';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    onSuccess?: () => void;
    companyId?: string;
}

const TeamInviteModal: FunctionComponent<Props> = ({ isOpen, toggle, companyId, onSuccess }: Props) => {
    const [inviteeEmail, setInviteeEmail] = useState<string | undefined>(undefined);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);
    const [validationError, setValidationError] = useState<string | null | undefined>(undefined);
    const [createInProgress, setCreateInProgress] = useState<boolean>(false);

    // Declare create invite mutation
    const [createInviteQuery] = useMutation(CREATE_INVITE);

    // Invite a user
    const createUserInvite = useCallback(async (): Promise<void> => {
        setCreateInProgress(true);

        try {
            const resp = await createInviteQuery({ variables: { email: inviteeEmail, companyId: companyId } });
            const inviteResp = resp?.data?.createCompanyInvite;

            if (inviteResp && inviteResp.success) {
                toggle();
                onSuccess && onSuccess();
            } else {
                setMutationError(inviteResp?.errors[0]);
            }
        } catch {
            setMutationError({ code: 'default' });
        } finally {
            setCreateInProgress(false);
        }
    }, [companyId, createInviteQuery, inviteeEmail, onSuccess, toggle]);

    // Return validation status
    // undefined: no validation
    // null: no errors
    // <string>: error to display
    const emailValidationStatus = useCallback((email: string): string | null | undefined => {
        if (!email || email === '') return undefined;

        if (validateEmail(email)) {
            return null;
        } else {
            return EMAIL_INVALID_ERROR_KEY;
        }
    }, []);

    // Handle email input change
    const handleEmailChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            const email = e.target.value;
            setInviteeEmail(email);
            setValidationError(emailValidationStatus(email));
            setMutationError(undefined);
        },
        [emailValidationStatus]
    );

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            <Modal.Header closeButton>
                <h4>
                    <FormattedMessage id="settings.team-management.team-list.invite-modal.title" />{' '}
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={e => {
                        createUserInvite();
                        e.preventDefault();
                    }}
                >
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="settings.team-management.team-list.invite-modal.form.email" />
                        </Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            onChange={handleEmailChange}
                            isValid={!!inviteeEmail && !validationError}
                            isInvalid={!!(inviteeEmail && (validationError || mutationError))}
                            autoComplete="off"
                        />
                        {validationError && (
                            <Form.Control.Feedback>
                                <FormattedMessage
                                    id={`settings.team-management.team-list.invite-modal.form.validation-error.${validationError}`}
                                />
                            </Form.Control.Feedback>
                        )}
                        {mutationError && (
                            <Form.Control.Feedback>
                                <ErrorMessage error={{ ...mutationError, namespace: 'invite-company-user' }} />
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <AsyncButton
                    track-id="click-invite-send"
                    variant="dark"
                    onClick={createUserInvite}
                    disabled={!!validationError || createInProgress}
                    loading={createInProgress}
                    messageProps={{ id: 'settings.team-management.team-list.invite-modal.send' }}
                    loadingMessageProps={{ id: 'settings.team-management.team-list.invite-modal.sending' }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default TeamInviteModal;
