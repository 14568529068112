import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lang/index';
import { ReduxState } from 'redux/reducers';
import AuthGuard from './AuthGuard';
import { useUtmTracker } from 'util/useUtmTracker';
import PublicError from './public/PublicError';
import Public404 from './public/Public404';
import CompanyDisabled from './flows/CompanyDisabled';
import CompanyInvite from './flows/CompanyInvite';
import LinkUser from './flows/LinkUser';
import Onboarding1 from './flows/Onboarding1';
import ExternalRedirect from './public/ExternalRedirect';
import LayoutWithNav from './LayoutWithNav';
import {
    APP_MANAGEMENT_APPS_PATH,
    APP_MANAGEMENT_PROJECTS_PATH,
    DASHBOARD_ROOT_PATH,
    INVOICES_PATH,
    SUBSCRIPTION_PATH,
    TEAM_MANAGEMENT_PATH,
    TEAM_SPRINT_SETTINGS_PATH,
    USER_ACCOUNT_PATH,
    WIDGET_ASSISTANT_PATH
} from 'constants/routeBuilders';
import AppManagement from './settings/AppManagement';
import BillingSettings from './settings/BillingSettings';
import TeamMemberList from './settings/TeamManagement/TeamMemberList';
import UserAccount from './settings/UserAccount';
import DashboardView from './reports/GenericDashboard/DashboardView';
import DashboardWidgetEdit from './reports/GenericDashboard/DashboardWidgetEdit';
import NoDashboardView from './reports/GenericDashboard/NoDashboardView';
import LayoutFullScreen from './LayoutFullScreen';
import DashboardAsJsonTemplate from './reports/GenericDashboard/DashboardAsJsonTemplate';
import BillingInvoiceList from './settings/BillingSettings/BillingInvoiceList';
import TeamSprintSettings from './settings/TeamManagement/TeamSprintSettings';
import { useGtmTracker } from 'util/useGtmTracker';
import { useIntercomTracker } from 'util/useIntercomTracker';
import { Toaster } from 'react-hot-toast';
import { useToastNotifications } from 'components/Notifications/useToastNotifications';
import { DEFAULT_TOAST_DURATION } from 'constants/defaultValues';
import AddDashboardTemplate from './flows/AddDashboardTemplate';
import { useElevio } from 'util/useElevio';
import { Collections } from './collections';
import DashboardAssistant from './reports/GenericDashboard/DashboardAssistant/DashboardAssistant';
import DashboardWidgetAssistant from './reports/GenericDashboard/DashboardWidgetAssistant';
import WidgetAsJsonTemplate from './reports/GenericDashboard/WidgetAsJsonTemplate';

const App: FunctionComponent = () => {
    // Capture UTM parameters before actual signup/login redirect
    useUtmTracker();

    // Push user information to GTM data layer
    useGtmTracker();

    // Boot intercom
    useIntercomTracker();

    // Setup notification toasts
    useToastNotifications();

    // Setup elevio
    useElevio();

    // Get actual locale
    const locale = useSelector((e: ReduxState) => e.settings.locale);
    const currentAppLocale = AppLocale[locale];

    return (
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Toaster
                position="top-right"
                containerStyle={{ top: 95, right: 10 }}
                toastOptions={{ duration: DEFAULT_TOAST_DURATION }}
            />
            <Routes>
                {/* Root path */}
                <Route index element={<Navigate to="app/reports" />} />

                {/* App routes - Requires authenticated user and company */}
                <Route path="/app" element={<AuthGuard companyRequired={true} />}>
                    <Route index element={<Navigate to="reports" />} />

                    {/* Layout with sidebar/top-nav */}
                    <Route element={<LayoutWithNav />}>
                        {/* Reports & Dashboards */}
                        <Route path="reports">
                            {/* Reports */}
                            <Route index element={<Navigate to={DASHBOARD_ROOT_PATH} />} />

                            {/* Edit Dashboards */}
                            <Route path="dashboards">
                                <Route index element={<NoDashboardView />} />
                                <Route path=":dashboardId" element={<DashboardView />} />
                                <Route path=":dashboardId/json-export" element={<DashboardAsJsonTemplate />} />
                            </Route>
                        </Route>

                        {/* Collections */}
                        <Route path="collections" element={<Collections />} />

                        {/* User & Company Settings */}
                        <Route path="settings">
                            <Route index element={<Navigate to="app-management" />} />

                            {/* App management */}
                            <Route path="app-management">
                                <Route path=":tab" element={<AppManagement />} />
                                <Route index element={<Navigate to="projects?display=auto" />} />
                            </Route>

                            {/* Billing */}
                            <Route path="subscription" element={<BillingSettings />} />

                            {/* Invoices */}
                            <Route path="invoices" element={<BillingInvoiceList />} />

                            {/* Team Management */}
                            <Route path="sprint" element={<TeamSprintSettings />} />
                            <Route path="team" element={<TeamMemberList />} />

                            {/* User account */}
                            <Route path="user-account" element={<UserAccount />} />

                            {/* Legacy routes - redirect to new menus */}
                            <Route path="board" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="billing" element={<Navigate to={SUBSCRIPTION_PATH} />} />
                            <Route path="billing/invoices" element={<Navigate to={INVOICES_PATH} />} />
                            <Route path="billing/subscription" element={<Navigate to={SUBSCRIPTION_PATH} />} />
                            <Route path="company" element={<Navigate to={APP_MANAGEMENT_APPS_PATH} />} />
                            <Route path="data-explorer" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="dev-funnel-board" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="priority-inbox" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="project-list" element={<Navigate to={APP_MANAGEMENT_PROJECTS_PATH} />} />
                            <Route path="prioritization-rules" element={<Navigate to={USER_ACCOUNT_PATH} />} />
                            <Route path="retrospectives" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="retrospective-feedbacks/:id" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="retrospective-results/:id" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="review-merge-board" element={<Navigate to={DASHBOARD_ROOT_PATH} />} />
                            <Route path="team" element={<Navigate to={TEAM_MANAGEMENT_PATH} />} />
                            <Route path="team/general" element={<Navigate to={TEAM_MANAGEMENT_PATH} />} />
                            <Route path="team/members" element={<Navigate to={TEAM_MANAGEMENT_PATH} />} />
                            <Route path="team/sprint-settings" element={<Navigate to={TEAM_SPRINT_SETTINGS_PATH} />} />
                        </Route>
                    </Route>

                    {/* Full screen layout */}
                    <Route element={<LayoutFullScreen />}>
                        {/* Widget edition */}
                        <Route path="reports">
                            <Route path="widgets/edit" element={<DashboardWidgetEdit />} />
                            <Route path="widgets/json-export" element={<WidgetAsJsonTemplate />} />
                            <Route path="dashboards/:dashboardId/widgets/edit" element={<DashboardWidgetEdit />} />
                            <Route
                                path="dashboards/:dashboardId/widgets/json-export"
                                element={<WidgetAsJsonTemplate />}
                            />
                            <Route
                                path={`dashboards/:dashboardId${WIDGET_ASSISTANT_PATH}/*`}
                                element={<DashboardWidgetAssistant />}
                            />
                            <Route path="dashboards/assistant" element={<DashboardAssistant />} />
                        </Route>
                    </Route>
                </Route>

                {/* Flow routes - Requires authenticated user */}
                <Route path="/flows" element={<AuthGuard />}>
                    <Route path="company-disabled/:id" element={<CompanyDisabled />} />
                    <Route path="company-invite/:id/:token" element={<CompanyInvite />} />
                    <Route path="link-user/:id/:token" element={<LinkUser />} />

                    {/* Onboarding variants. Routes are encapsulated in their own "main" component 
                    as they are pretty specific to onboarding  */}
                    <Route path="onboarding/*" element={<Onboarding1 />} />

                    {/* Create dashboard from template ID or full template */}
                    <Route path="add-dashboard-template" element={<AddDashboardTemplate />} />

                    {/* Redirect upgrade plan route to subscription page for now */}
                    <Route path="upgrade-plan" element={<Navigate to={SUBSCRIPTION_PATH} />} />
                </Route>

                {/* Public routes (e.g. Error) */}
                <Route path="/public">
                    <Route path="error" element={<PublicError />} />
                    <Route path="404" element={<Public404 />} />
                </Route>

                {/* Signup/Login */}
                {/* The /auth/auth0 endpoint is managed by HQ */}
                {/* UTMs parameters get tracked before the redirect (useUtmTracking) */}
                {/* Redirect to "origin=/" to let the app manage the entrypoint based on user context */}
                <Route path="/signup" element={<ExternalRedirect uri="/auth/auth0?origin=/&prompt=signup" />} />
                <Route path="/login" element={<ExternalRedirect uri="/auth/auth0?origin=/&prompt=login" />} />

                {/* Not found */}
                <Route path="*" element={<Public404 />} />
            </Routes>
        </IntlProvider>
    );
};

export default App;
