import { useMutation } from '@apollo/react-hooks';
import { toastQueryError } from 'components/Toast';
import { useCallback, useState } from 'react';
import { SetResourcePinResp, SET_RESOURCE_PIN } from '../queries/ResourcePin';

interface Props {
    resourceType: string | undefined;
    resourceId: string | undefined;
    onSuccess?: () => void;
}

interface Result {
    loading: boolean;
    setPin: (pinned: boolean) => Promise<void>;
}

export function useResourcePin({ resourceType, resourceId, onSuccess }: Props): Result {
    // State
    const [loading, setLoading] = useState(false);

    // Mutations
    const [setResourcePin] = useMutation<SetResourcePinResp>(SET_RESOURCE_PIN);

    return {
        loading,
        setPin: useCallback(
            async (pinned: boolean) => {
                // Abort if resourceId or resourceType are missing
                if (!resourceId || !resourceType) return;

                const operationName = pinned ? 'pin' : 'unpin';

                try {
                    // Set loading state
                    setLoading(true);

                    // Perform mutation
                    const resp = (
                        await setResourcePin({
                            variables: { resourceId, resourceType, pinned }
                        })
                    ).data?.setPin;

                    // Handle response
                    if (resp?.success) {
                        if (onSuccess) onSuccess();
                    } else {
                        toastQueryError({ ...resp?.errors[0], namespace: `${operationName}-dashboard` });
                    }
                } catch (error) {
                    toastQueryError({ namespace: `${operationName}-dashboard` });
                } finally {
                    // Reset loading state
                    setLoading(false);
                }
            },
            [setResourcePin, resourceId, resourceType, onSuccess]
        )
    };
}
