import { AppResp } from 'api/hq/queries/App';
import { IdentityResp } from 'api/hq/queries/Identity';
import { IntegrationResp } from 'api/hq/queries/Integration';
import { RichMessage } from 'components/RichMessage';
import { identityConnectionPath, LOGOUT_REDIRECTION_PATH } from 'constants/routeBuilders';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { ReduxState } from 'redux/reducers';
import DisconnectIdentityModal from './DisconnectIdentityModal';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

interface Props {
    integration?: IntegrationResp;
    identity?: IdentityResp;
    app: AppResp;
    onDisconnect: () => void;
    className?: string;
}

const IdentityActionButton: FunctionComponent<Props> = ({
    integration,
    identity,
    app,
    onDisconnect,
    className
}: Props) => {
    // State
    const [isConnecting, setIsConnecting] = useState<boolean>(false);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState<boolean>(false);

    // Global state
    const apiUser = useSelector((e: ReduxState) => e.authUser.apiUser);

    // Format connection link
    const connectLink = identityConnectionPath({ provider: app.provider, connectionScope: integration?.apiEndpoint });

    // Action performed when connecting an app
    const connectIdentity = (): void => {
        setIsConnecting(true);
        window.location.href = connectLink;
    };

    // Sign out user if the disconnected identity is the currently logged in
    // identity. Call parent callback otherwise.
    const disconnectIdentity = (): void => {
        if (identity && identity.id === apiUser?.identity.id) {
            window.location.href = LOGOUT_REDIRECTION_PATH;
        } else {
            onDisconnect();
        }
    };

    if (identity) {
        // Disconnect button
        return (
            <>
                <Button
                    variant="outline-danger"
                    onClick={() => setDisconnectModalOpen(true)}
                    track-id={`click-identity-disconnect-${app.provider}`}
                    className={className}
                >
                    <RichMessage id="settings.app-management.identity-item.disconnect" />
                </Button>
                <DisconnectIdentityModal
                    isOpen={disconnectModalOpen}
                    toggle={() => setDisconnectModalOpen(!disconnectModalOpen)}
                    app={app}
                    identity={identity}
                    onSuccess={disconnectIdentity}
                />
            </>
        );
    } else {
        // Connect button
        return (
            <AsyncButton
                variant="outline-dark"
                className={className}
                onClick={connectIdentity}
                disabled={isConnecting}
                track-id={`click-identity-connect-${app.provider}`}
                loading={isConnecting}
                messageProps={{ id: 'settings.app-management.identity-item.connect' }}
                loadingMessageProps={{ id: 'settings.app-management.identity-item.connecting' }}
            />
        );
    }
};

export default IdentityActionButton;
