import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import {
    QueryOpAggregator,
    QueryOpField,
    QUERY_OP_FORMULA_FUNCTION
} from 'components/Dashboarding/DataSource/QueryOperators';
import { sortBy, truncate, uniq } from 'lodash';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { WidgetMetricField } from 'components/Dashboarding/Models/Widget';
import WidgetFormulaComposer, { FormulaComposerState } from './WidgetFormulaComposer';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import FieldTypeIcon from 'components/Dashboarding/FieldTypeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kpFormula } from 'util/customIcons';
import { useNavigableDropdown } from 'components/NavigableDropdown/useNavigableDropdown';
import NavigableDropdown from 'components/NavigableDropdown';

// Max number of characters allowed (function + field label)
const MAX_CONTENT_LENGTH = 32;

interface PartialWidgetMetricField {
    function: QueryOpAggregator;
    ref?: string;
}

interface Props {
    label: ReactNode;
    className?: string;
    fact: QueryOpFact;
    onChange: (e: WidgetMetricField) => void;
    field: WidgetMetricField;
}

const WidgetMetricSelector: FunctionComponent<Props> = ({ label, className, fact, field, onChange }: Props) => {
    // Services
    const intl = useRichIntl();

    // State
    const [preFormulaField, setPreFormulaField] = useState<WidgetMetricField | undefined>(undefined);

    // Get all relevant fields and applicable aggregators
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: 'metric' });

    // Extract field params
    const metricFn = field.function;
    const isFormula = metricFn == QUERY_OP_FORMULA_FUNCTION;

    // Extract list of useable aggregators
    const aggregatorList = useMemo(
        () => uniq(queryOpFields.flatMap(e => e.aggregators)).sort() as QueryOpAggregator[],
        [queryOpFields]
    );

    // Sort fields by name for display purpose and only select the fields which are compatible
    // with the currently selected aggregator
    const displayQueryOpFields = useMemo(() => {
        if (!metricFn) return [];
        const applicableFields = queryOpFields.filter(e => e.aggregators?.includes(metricFn));
        return sortBy(applicableFields, ['name']);
    }, [metricFn, queryOpFields]);

    // Get selected field
    const currentField = useMemo(() => {
        return displayQueryOpFields.find(e => e.id === field?.ref);
    }, [displayQueryOpFields, field?.ref]);

    // Labels
    const metricFnLabel = intl.formatMessage({ id: `dashboarding.widget-field-selector.metric-function.${metricFn}` });
    const currentFieldLabel = truncate(currentField?.label || '', {
        length: MAX_CONTENT_LENGTH - metricFnLabel.length,
        omission: '..'
    });

    // Keep in memory the last selected field configuration which is not a formula. This
    // is used to restore the last select configuration when users choose to clear/cancel the formula.
    useEffect(() => {
        field.function != QUERY_OP_FORMULA_FUNCTION && setPreFormulaField(field);
    }, [field]);

    // Hook invoked when the metric configuration changes
    const onMetricChange = useCallback(
        (e: PartialWidgetMetricField): void => {
            const targetFieldRef = e.ref || field.ref;

            if (e.function == metricFn) {
                // This is a change of field
                onChange({ ...field, function: e.function, ref: targetFieldRef });
            } else if (e.function == QUERY_OP_FORMULA_FUNCTION) {
                // This is a change of function to a formula
                onChange({ ...field, function: e.function, ref: e.ref || '' });
            } else {
                // This is a change of field-based function. Attempt to keep the currently
                // selected field if it is compatible
                const compatibleFields = queryOpFields.filter(f => f.aggregators?.includes(e.function));
                const selectedField = compatibleFields.find(f => f.id == targetFieldRef) || compatibleFields[0];
                onChange({ ...field, function: e.function, ref: selectedField.id });
            }
        },
        [field, metricFn, onChange, queryOpFields]
    );

    // Hook invoked when the metric formula is updated
    const onMetricFormula = useCallback(
        (formulaState: FormulaComposerState) => {
            // Do nothing if formula is undefined
            if (formulaState.formula == undefined) return;

            // Update metric configuration
            onMetricChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: formulaState.formula });
        },
        [onMetricChange]
    );

    // Hook invoked when the formula is cleared. Attempt to restore the last known non-formula choice.
    const onFormulaClear = useCallback(() => onMetricChange(preFormulaField || { function: aggregatorList[0] }), [
        aggregatorList,
        preFormulaField,
        onMetricChange
    ]);

    // Use dropdown for aggregator
    const {
        getDropdownProps: getAggregatorDropdownProps,
        getDropdownMenuProps: getAggregatorDropdownMenuProps,
        getDropdownItemProps: getAggregatorDropdownItemProps,
        options: aggregatorListResult
    } = useNavigableDropdown({
        translationNS: 'dashboarding.widget-field-selector.metric-function',
        fallbackSelect: () => onMetricChange({ function: QUERY_OP_FORMULA_FUNCTION, ref: '' }),
        onSelect: id => onMetricChange({ function: id as QueryOpAggregator }),
        options: aggregatorList.map(f => ({ id: f, label: f })),
        mapper: {
            value: 'id',
            label: 'label'
        }
    });

    // Use dropdown for fields
    const {
        getDropdownProps: getFieldDropdownProps,
        getDropdownMenuProps: getFieldDropdownMenuProps,
        getDropdownItemProps: getFieldDropdownItemProps,
        options: displayQueryOpFieldsResult
    } = useNavigableDropdown<QueryOpField>({
        onSelect: id => onMetricChange({ function: metricFn, ref: id as QueryOpAggregator }),
        options: displayQueryOpFields,
        mapper: {
            value: 'id',
            label: 'label'
        }
    });

    return (
        <div className={classNames('d-flex', 'h-100', className)}>
            {/* Choose function */}
            {!isFormula && (
                <Dropdown {...getAggregatorDropdownProps} className="flex-grow-1">
                    <Dropdown.Toggle
                        as="div"
                        role="button"
                        className="d-flex justify-content-between align-items-center h-100 p-2 ps-3 text-dark text-uppercase fw-bold"
                        title={metricFnLabel}
                    >
                        {metricFnLabel}
                    </Dropdown.Toggle>

                    <NavigableDropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        {...getAggregatorDropdownMenuProps}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        {aggregatorListResult.map(e => {
                            return (
                                <NavigableDropdown.Item
                                    key={e.id}
                                    onClick={() => onMetricChange({ function: e.id })}
                                    value={e.id}
                                    className={classNames({ 'fw-bold': e.id == metricFn })}
                                    {...getAggregatorDropdownItemProps}
                                >
                                    <RichMessage id={`dashboarding.widget-field-selector.metric-function.${e.id}`} />
                                </NavigableDropdown.Item>
                            );
                        })}
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => onMetricChange({ function: QUERY_OP_FORMULA_FUNCTION })}
                            className="d-flex align-items-center text-primary"
                        >
                            <FontAwesomeIcon icon={kpFormula} className="me-2 ms-1" />
                            <RichMessage id="dashboarding.widget-field-selector.metric-function.FORMULA" />
                        </Dropdown.Item>
                    </NavigableDropdown.Menu>
                </Dropdown>
            )}

            {/* Choose field */}
            {metricFn && !isFormula && (
                <Dropdown {...getFieldDropdownProps} className="flex-grow-1 border-start border-grey-6">
                    <Dropdown.Toggle
                        as="div"
                        role="button"
                        className="d-flex justify-content-between align-items-center h-100 p-2"
                        title={currentField?.label}
                    >
                        {currentField ? (
                            <div className="d-flex">
                                <FieldTypeIcon field={currentField} className="me-2" />
                                <span className="text-dark text-uppercase fw-bold text-truncate">
                                    {currentFieldLabel}
                                </span>
                            </div>
                        ) : (
                            <em>
                                <RichMessage id="dashboarding.widget-field-selector.empty-label" />
                            </em>
                        )}
                    </Dropdown.Toggle>

                    <NavigableDropdown.Menu
                        className="max-vh-30 overflow-y-auto"
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                        {...getFieldDropdownMenuProps}
                    >
                        {displayQueryOpFieldsResult.map(f => {
                            return (
                                <NavigableDropdown.Item
                                    key={f.id}
                                    onClick={() => onMetricChange({ function: metricFn, ref: f.id })}
                                    value={f.id}
                                    className="d-flex"
                                    {...getFieldDropdownItemProps}
                                >
                                    <FieldTypeIcon field={f} className="me-2" />
                                    <span
                                        className={classNames('text-dark text-uppercase', {
                                            'fw-bold': f.id == currentField?.id
                                        })}
                                    >
                                        {f.label}
                                    </span>
                                </NavigableDropdown.Item>
                            );
                        })}
                    </NavigableDropdown.Menu>
                </Dropdown>
            )}

            {/* Formula metric */}
            {isFormula && (
                <WidgetFormulaComposer
                    label={label}
                    value={field.ref}
                    onChange={onMetricFormula}
                    onClear={onFormulaClear}
                    fact={fact}
                    usageScope="metric"
                />
            )}
        </div>
    );
};

export default WidgetMetricSelector;
