import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Nav } from 'react-bootstrap';

import { RichMessage } from 'components/RichMessage';

export interface NavigationalTab {
    name: string;
    to: string;
    id: string;
}
interface Props {
    tabs: NavigationalTab[];
    className: string;
    activeTab: string;
    onSelect?: (tabName: NavigationalTab) => void;
}

// Nav wrapper with tab looks
const NavigationalTabs: FunctionComponent<Props> = ({ tabs, activeTab, className, onSelect, ...props }) => {
    return (
        <Nav variant="tabs" className={classnames('separator-tabs ms-0 mb-3', className)} {...props}>
            {tabs.map(tab => {
                return (
                    <Nav.Item key={tab.id}>
                        <Nav.Link
                            className={classnames({ active: activeTab === tab.name, 'nav-link': true })}
                            onClick={() => onSelect && onSelect(tab)}
                        >
                            <RichMessage id={tab.id} />
                        </Nav.Link>
                    </Nav.Item>
                );
            })}
        </Nav>
    );
};

export default NavigationalTabs;
