import React, { FunctionComponent } from 'react';
import { Variant } from 'react-bootstrap/types';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    variant?: Variant;
    vh?: number;
}

const FullPageLoader: FunctionComponent<Props> = ({ variant = 'dark', vh = 100 }) => {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ height: `${vh}vh` }}>
            <Spinner animation="border" variant={variant} />
        </div>
    );
};

export default FullPageLoader;
