import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { DASHBOARD_TMPL_ID_KEY, ONBOARDING1_PATH } from 'constants/routeBuilders';
import { cleanupTemplateMarkup, useTemplateAssistant } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import TemplateCustomizationDiagram from './TemplateCustomizationDiagram';
import { omit } from 'lodash';
import FullPageLoader from 'components/Design/FullPageLoader';
import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import Header from './Header';
import { Questions } from 'components/Questions';
import { ONBOARDING_ASSISTANT_DSHB_QNS_KEY, ONBOARDING_ASSISTANT_DSHB_TMPL_KEY } from 'constants/localStorageKeys';
import { useLocalStorage } from 'react-use';

const BACK_STEP_PATH = '/flows/onboarding/configure-dashboard/template-selection';
const NEXT_STEP_PATH = `${ONBOARDING1_PATH}/finalize-account`;

const Step3bTemplateCustomization: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();

    // Extract params
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(DASHBOARD_TMPL_ID_KEY);
    const [, setOnboardingTemplate] = useLocalStorage<DashboardTemplate>(ONBOARDING_ASSISTANT_DSHB_TMPL_KEY);

    // Template customizer hook
    const { loading, resultTemplate, questions, updateQuestion, resetQuestions, clearQuestions } = useTemplateAssistant(
        ONBOARDING_ASSISTANT_DSHB_QNS_KEY,
        templateId,
        'DASHBOARD',
        1
    );

    // Invalid form
    const isFormInvalid = useMemo(() => !questions || Array.from(questions.values()).some(e => !e.isValid), [
        questions
    ]);

    // Hook invoked on next click
    const handleNextClick = useCallback(() => {
        // Clear questions from local storage
        clearQuestions();

        // Redirect to next step
        navigate(NEXT_STEP_PATH);
    }, [clearQuestions, navigate]);

    // Cleanup and save template to local storage
    useEffect(() => {
        // Abort if no result template
        if (!resultTemplate) return;

        // Template cleanup
        const formattedTemplate = omit(cleanupTemplateMarkup(resultTemplate as DashboardTemplate), 'imgDataUrl');

        // Put the template in local storage
        setOnboardingTemplate(formattedTemplate);
    }, [resultTemplate, setOnboardingTemplate]);

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            {loading ? (
                <>
                    {/* Display loading screen if questions are still loading */}
                    <FullPageLoader />
                </>
            ) : !questions || Array.from(questions.keys()).length == 0 ? (
                <>
                    {/* There are no questions to answer, navigate to finalize step */}
                    <Navigate to={NEXT_STEP_PATH} />
                </>
            ) : (
                <>
                    <div className="flex-grow-1">
                        {/* Title */}
                        <div className="d-flex align-items-baseline mt-4 mb-5">
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                to={BACK_STEP_PATH}
                                variant="link"
                                className="p-0"
                            >
                                <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                            </Button>
                            <h1 className="fw-bolder">
                                <RichMessage id="flows.onboarding.configure-dashboard.template-customization.title" />
                            </h1>
                        </div>

                        {/* Questions */}
                        <div className="mb-5">
                            <Questions
                                questions={questions}
                                updateQuestion={updateQuestion}
                                resetQuestions={resetQuestions}
                            />
                        </div>
                    </div>

                    {/* Next button */}
                    <div className="flex-grow-0">
                        <Button
                            variant="primary"
                            className="justify-content-center w-100 mb-4"
                            onClick={handleNextClick}
                            disabled={isFormInvalid}
                        >
                            <RichMessage id="flows.onboarding.configure-dashboard.template-customization.action.finalize-setup" />
                        </Button>
                    </div>
                </>
            )}

            {/* Right section */}
            <TemplateCustomizationDiagram
                className="h-auto m-auto"
                style={{ width: '45%', maxHeight: '90vh', maxWidth: '35vh' }}
                template={resultTemplate as DashboardTemplate}
            />
        </LayoutSplitScreen>
    );
};

export default Step3bTemplateCustomization;
