import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    DimensionFormattingOptions,
    MetricFormattingOptions,
    SeriesNDimensionsNMetrics,
    sortedSeriesColumns,
    WidgetDimensionField,
    WidgetFormattingOptions,
    WidgetList,
    WidgetMetricField,
    WidgetTypedFieldWrapper
} from 'components/Dashboarding/Models/Widget';
import FieldFormatter from './FieldFormatter';
import { DEFAULT_QUERY_LIMIT, useFetchSeries } from 'components/Dashboarding/Hooks/useFetchSeries';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { Row, useBlockLayout, useResizeColumns, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';
import { debounce, isEqual, max, min } from 'lodash';
import scrollbarWidth from 'util/scrollbarWidth';
import { WIDGET_DEFAULT_SERIES_COLOR } from 'constants/colors';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';
import { useMeasure } from 'react-use';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faSort,
    faSortDown,
    faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_DEBOUNCE_TIME, DIMENSION_IDENTIFIER, METRIC_IDENTIFIER } from 'constants/defaultValues';
import { SortConfig } from 'components/SortableHeader';
import CellDetailsModal from './CellDetailsModal';
import { PickEvent } from 'components/Dashboarding/Hooks/useHighchartsOptions';
import { DrilldownContext } from 'contexts/DrilldownContext';
import DynamicWidthTransparentInput from 'components/Design/DynamicWidthTransparentInput';

// Table configuration
const TABLE_TH_HEIGHT = 37; //px
const TABLE_ROW_HEIGHT = 35; //px
const TABLE_COL_MIN_WIDTH = 200; //px
const TABLE_COL_RESIZE_MIN_WIDTH = 50; //px
const BORDER_WIDTH = 1; //px
const TABLE_CONTENT_PADDING_Y = 10; //px

const ALLOWED_LIMITS = [5, 10, 25, 50, 100, 500];

// i18n configuration
const DEFAULT_FIELD_NS = 'dashboarding.widget-configuration.axis.LIST';

// Initialize sort from the widget config
const initSort = (widget: WidgetList): SortConfig | undefined => {
    const sortedBy = widget.config.series.flatMap(e => [...e.dimensions, ...e.metrics]).find(e => e.sort);

    if (!sortedBy) return sortedBy;

    return {
        asc: sortedBy.sort == 'ASC' ? true : false,
        columnId: sortedBy.ref
    };
};

interface Props {
    widget: WidgetList;
    drilldownMode?: boolean;
    onChange: (e: WidgetList) => void;
    onReloading: (reloading: boolean) => void;
    forcedMessageId?: string;
    onPick?: (event: PickEvent) => void;
}

const ListWidget: FunctionComponent<Props> = ({
    widget,
    drilldownMode,
    onChange,
    onReloading,
    forcedMessageId,
    onPick
}: Props) => {
    // Get configuration of columns
    const fact = widget.config.fact;
    const [series] = widget.config.series;

    // Build series types array (e.g. ['d0', 'd1', 'm0'])
    const seriesTypes = useMemo(
        () => [
            ...series.dimensions.map((e, index) => `${DIMENSION_IDENTIFIER}${index}`),
            ...series.metrics.map((e, index) => `${METRIC_IDENTIFIER}${index}`)
        ],
        [series.dimensions, series.metrics]
    );

    // State
    const [details, setDetails] = useState<{ field: string; columnName: string; value: string } | undefined>();
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<SortConfig | undefined>(initSort(widget));
    const [offset, setOffset] = useState<number>(0);
    const [editedFormatting, setEditedFormatting] = useState<WidgetFormattingOptions | undefined>(
        widget.config.formatting
    );
    const [editedSeries, setEditedSeries] = useState<SeriesNDimensionsNMetrics>(series);

    // Debounced version of `onChange`
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Get translation service
    const intl = useRichIntl();

    // Reference to the div wrapper to detect the available width the table can use
    const [tableWrapperRef, { height: tableWrapperHeight, width: tableWrapperWidth }] = useMeasure<HTMLDivElement>();

    const rankedFields = useMemo(() => sortedSeriesColumns(widget.configType, editedSeries), [
        editedSeries,
        widget.configType
    ]);

    // Calculate the width of a column based on the table max width and the number of fields to display
    const tableColWidth = useMemo(
        () =>
            max([
                TABLE_COL_MIN_WIDTH,
                (tableWrapperWidth - (rankedFields.length + 1) * BORDER_WIDTH) / rankedFields.length
            ]) as number,
        [tableWrapperWidth, rankedFields]
    );

    // Retrieve `coordinates` from drilldown context
    const { coordinates } = useContext(DrilldownContext);

    // Perform query
    const { records, totalRecords, initialLoading, reloading } = useFetchSeries({
        fact,
        series: editedSeries,
        offset,
        pickedCoordinates: drilldownMode ? coordinates : undefined
    });

    // Pagination
    const limit = useMemo(() => editedSeries.limit ?? DEFAULT_QUERY_LIMIT, [editedSeries.limit]);
    const pagination = useMemo(() => {
        const currentPageIndex = Math.floor(offset / limit);
        const lastPageIndex = Math.floor((totalRecords ?? 0) / limit);

        return {
            currentPageIndex,
            currentPage: currentPageIndex + 1,
            lastPageIndex,
            lastPage: lastPageIndex + 1
        };
    }, [limit, offset, totalRecords]);

    // Handle sort changes
    const onSortChange = useCallback(
        (sort: SortConfig | undefined) => {
            // Update state
            setSort(sort);

            // Reset offset
            setOffset(0);

            // Prepare series
            const updatedDimensions = editedSeries.dimensions.map(d => ({
                ...d,
                sort: sort?.columnId == d.ref ? (sort.asc ? 'ASC' : 'DESC') : undefined
            })) as WidgetDimensionField[];

            const updatedMetrics = editedSeries.metrics.map(m => ({
                ...m,
                sort: sort?.columnId == m.ref ? (sort.asc ? 'ASC' : 'DESC') : undefined
            })) as WidgetMetricField[];

            const updatedSeries = {
                ...editedSeries,
                dimensions: updatedDimensions,
                metrics: updatedMetrics
            } as SeriesNDimensionsNMetrics;

            // Update state
            setEditedSeries(updatedSeries);

            // Propagate to parent
            debouncedOnChange({
                ...widget,
                config: {
                    ...widget.config,
                    series: [updatedSeries],
                    formatting: editedFormatting
                }
            });
        },
        [debouncedOnChange, editedFormatting, editedSeries, widget]
    );

    // Hook invoked when a header is clicked
    const handleHeaderClick = useCallback(
        (columnId: string) => {
            if (!sort || sort.columnId != columnId) {
                onSortChange({ columnId, asc: true });
                return;
            }

            if (!sort.asc) {
                onSortChange(undefined);
                return;
            }

            onSortChange({ ...sort, asc: !sort.asc });
        },
        [onSortChange, sort]
    );

    // Hook invoked when the formatting of a field (dimension/metric) is updated
    const handleHeaderNameChange = useCallback(
        (
            fieldWrapper: WidgetTypedFieldWrapper,
            addedOpts: Partial<MetricFormattingOptions | DimensionFormattingOptions>
        ) => {
            // Update the target field
            const baseField = fieldWrapper.field;
            const updatedField = { ...baseField, formatting: { ...baseField.formatting, ...addedOpts } };

            // Generate the updated list
            const updatedFieldList =
                fieldWrapper.type == 'metric' ? [...editedSeries.metrics] : [...editedSeries.dimensions];
            updatedFieldList[fieldWrapper.localIndex] = updatedField;

            // Apply the changes
            const updatedSeries =
                fieldWrapper.type == 'metric'
                    ? ({ ...editedSeries, metrics: updatedFieldList } as SeriesNDimensionsNMetrics)
                    : ({ ...editedSeries, dimensions: updatedFieldList } as SeriesNDimensionsNMetrics);

            // Update state
            setEditedSeries(updatedSeries);

            // Propagate to parent
            const updatedWidget = {
                ...widget,
                config: { ...widget.config, series: [updatedSeries] }
            } as WidgetList;
            onChange(updatedWidget);
        },
        [widget, editedSeries, onChange]
    );

    // Hook invoked when a cell is clicked
    const handleCellClick = useCallback(
        (
            isTruncated: boolean,
            fieldWrapper: WidgetTypedFieldWrapper,
            value: string,
            record: Record<string, string>
        ) => {
            const isEmptyRecord = [
                intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }),
                intl.formatMessage({ id: 'dashboarding.widget-editor.configure-table.no-records-placeholder' })
            ].includes(value);

            // If the cell is displaying a dimension and the content is truncated
            if (fieldWrapper.type == 'dimension' && isTruncated && !isEmptyRecord) {
                // Prepare cell details modal data
                setDetails({
                    field: fieldWrapper.field.ref,
                    columnName:
                        fieldWrapper.field.formatting?.label ||
                        intl.formatMessage(
                            { id: `${DEFAULT_FIELD_NS}.${fieldWrapper.type}.*` },
                            { index: fieldWrapper.localIndex + 1 }
                        ),
                    value: value.toString()
                });

                // Open the modal
                setIsDetailsModalOpen(true);
            } else if (
                onPick &&
                fieldWrapper.type == 'metric' &&
                series.drilldownConfigs?.[fieldWrapper.localIndex]?.enabled
            ) {
                // Else, if the cell is displaying a metric, and the drilldown is enabled
                // for this metric, emit a pick event
                onPick({ srcIndex: fieldWrapper.localIndex, coordinates: seriesTypes.map(e => record[e]) });
            }
        },
        [intl, onPick, seriesTypes, series]
    );

    // Generate table column definitions
    const tableColumns = useMemo(() => {
        return rankedFields.map(fieldWrapper => {
            return {
                accessor: fieldWrapper.accessor,
                Header: (
                    <div className="text-truncate" style={{ color: 'inherit' }}>
                        <DynamicWidthTransparentInput
                            value={
                                fieldWrapper.field.formatting?.label ||
                                intl.formatMessage(
                                    { id: `${DEFAULT_FIELD_NS}.${fieldWrapper.type}.*` },
                                    { index: fieldWrapper.localIndex + 1 }
                                )
                            }
                            onCommit={label => handleHeaderNameChange(fieldWrapper, { label })}
                            className="transparent-framed-input framed-input px-2"
                            variant="none"
                            style={{ color: 'inherit' }}
                        />
                        <span className="cursor-pointer" onClick={() => handleHeaderClick(fieldWrapper.field.ref)}>
                            {!sort || sort.columnId != fieldWrapper.field.ref ? (
                                <FontAwesomeIcon icon={faSort} className="ms-2" />
                            ) : sort.asc ? (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            ) : (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            )}
                        </span>
                    </div>
                ),
                Cell: ({ value, row }: { value: string; row: Row }) => {
                    return (
                        <FieldFormatter
                            className={classNames({ 'text-wrap': forcedMessageId })}
                            fact={widget.config.fact}
                            field={fieldWrapper.field}
                            value={value}
                            onClick={(isTruncated: boolean) =>
                                handleCellClick(isTruncated, fieldWrapper, value, row.original)
                            }
                        />
                    );
                },
                width:
                    (editedFormatting?.columnWidths && editedFormatting?.columnWidths[fieldWrapper.accessor]) ??
                    tableColWidth
            };
        });
    }, [
        rankedFields,
        intl,
        sort,
        editedFormatting?.columnWidths,
        tableColWidth,
        handleHeaderNameChange,
        handleHeaderClick,
        forcedMessageId,
        widget.config.fact,
        handleCellClick
    ]);

    // If a message is forced to be displayed, generate a virtual row with this message
    // for each column
    const forcedMessageRecord = useMemo(() => {
        const record = {} as Record<string, string>;
        rankedFields.forEach(
            column =>
                (record[column.accessor] = forcedMessageId
                    ? intl.formatMessage({
                          id: forcedMessageId
                      })
                    : '')
        );
        return [record];
    }, [intl, rankedFields, forcedMessageId]);

    // If there is no records, generate a virtual row with empty values
    // for each column
    const emptyRecord = useMemo(() => {
        const record = {} as Record<string, string>;
        rankedFields.forEach(
            column => (record[column.accessor] = intl.formatMessage({ id: 'dashboarding.widget.no-data.title' }))
        );
        return [record];
    }, [intl, rankedFields]);

    // Just capture the state of the widget data
    const isDataEmpty = !records.length;

    const defaultColumn = useMemo(
        () => ({
            minWidth: TABLE_COL_RESIZE_MIN_WIDTH
        }),
        []
    );

    // Reducer to handle the columns resizing
    const stateReducer = useCallback(
        (newState, action) => {
            if (action.type == 'columnDoneResizing') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newColumnWidths = (newState as any).columnResizing.columnWidths;

                // Make sure we don't save a column width smaller than `TABLE_COL_RESIZE_MIN_WIDTH`
                Object.keys(newColumnWidths).forEach(
                    e => (newColumnWidths[e] = Math.max(TABLE_COL_RESIZE_MIN_WIDTH, Math.round(newColumnWidths[e])))
                );

                // Merge existing column widths with the emitted ones
                const updatedColumnWidths = Object.assign({}, editedFormatting?.columnWidths, newColumnWidths);

                // Abort if the merged column widths are no different from the existing ones
                if (isEqual(updatedColumnWidths, editedFormatting?.columnWidths)) return newState;

                // Prepare formatting
                const updatedFormatting = {
                    ...editedFormatting,
                    columnWidths: updatedColumnWidths
                };

                // Propagate to parent
                debouncedOnChange({
                    ...widget,
                    config: {
                        ...widget.config,
                        series: [editedSeries],
                        formatting: updatedFormatting
                    }
                });
            }
            return newState;
        },
        [debouncedOnChange, editedFormatting, editedSeries, widget]
    );

    // Get table helpers
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth } = useTable(
        {
            columns: tableColumns,
            data: forcedMessageId ? forcedMessageRecord : isDataEmpty ? emptyRecord : records,
            defaultColumn,
            stateReducer
        },
        useBlockLayout,
        useResizeColumns
    );

    // Get the width of the scrollbar for the current OS/browser so as to adjust the
    // width of the scrollable container and avoid having the squeezing the content of the
    // table to the left and dealigning the table.
    const scrollBarSize = useMemo(() => scrollbarWidth(), []);

    // Get the width of the scrollbar for the current OS/browser so as to adjust the
    // width of the scrollable container and avoid having the squeezing the content of the
    // table to the left and dealigning the table.
    // Calculate the height of the table
    const tableContentHeight = useMemo(() => (isDataEmpty ? tableWrapperHeight : rows.length * TABLE_ROW_HEIGHT), [
        isDataEmpty,
        rows.length,
        tableWrapperHeight
    ]);
    const tableMaxHeight = useMemo(() => {
        const fullHeight = tableWrapperHeight - scrollBarSize - TABLE_TH_HEIGHT - 2 * TABLE_CONTENT_PADDING_Y;
        const totalRowsHeight = rows.length * TABLE_ROW_HEIGHT;

        // If the table is empty, get the max height available to fill the widget white space
        // with No data indicator.
        // otherwise, get the exact height from the total of the rows heights
        const criteriaFn = isDataEmpty ? max : min;

        return criteriaFn([fullHeight, totalRowsHeight]) as number;
    }, [isDataEmpty, rows.length, scrollBarSize, tableWrapperHeight]);
    const isVScrollbarEnabled = tableContentHeight > tableMaxHeight;

    // Pure component used to limit the number of re-renders
    const renderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            return (
                <div
                    {...row.getRowProps({ style: { ...style, width: `calc(100% - ${2 * BORDER_WIDTH}px)` } })}
                    className={classNames('tr', {
                        'h-100': isDataEmpty,
                        'bg-grey-10': widget.config.formatting?.enableStripedRows && index % 2
                    })}
                >
                    {row.cells.map((cell, cellIndex) => {
                        const { key, style, className, ...cellProps } = cell.getCellProps({
                            className: classNames('td', {
                                'd-flex align-items-center justify-content-center': isDataEmpty
                            })
                        });

                        // Reduce cell width to accomodate scrollbar size
                        const isLast = cellIndex == row.cells.length - 1;
                        const origCellWidth = (cell.column.width || 0) as number;
                        const cellWidth = isVScrollbarEnabled && isLast ? origCellWidth - scrollBarSize : origCellWidth;
                        const isMetricCell = cell.column.id[0] == METRIC_IDENTIFIER;
                        const metricIndex = +cell.column.id[1];
                        const isDrilldownEnabled =
                            isMetricCell && metricIndex != undefined && series.drilldownConfigs?.[metricIndex]?.enabled;

                        return (
                            <div
                                key={key}
                                {...cellProps}
                                className={classNames(
                                    { 'drilldown-enabled-cell cursor-pointer': isDrilldownEnabled },
                                    className
                                )}
                                style={{ ...style, width: `${cellWidth}px` }}
                            >
                                <div className={classNames({ 'text-grey-7 fw-bold': isDataEmpty })}>
                                    {cell.render('Cell')}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        },
        [
            rows,
            prepareRow,
            isDataEmpty,
            widget.config.formatting?.enableStripedRows,
            isVScrollbarEnabled,
            scrollBarSize,
            series.drilldownConfigs
        ]
    );

    // Hook invoked on limit change
    const onLimitChange = useCallback(
        limit => {
            setEditedSeries({ ...editedSeries, limit: +limit });
            setOffset(0);

            debouncedOnChange &&
                debouncedOnChange({
                    ...widget,
                    config: {
                        ...widget.config,
                        series: [{ ...editedSeries, limit: +limit }]
                    }
                });
        },
        [debouncedOnChange, editedSeries, widget]
    );

    // Update parent state when widget reloads
    useEffect(() => onReloading(reloading), [onReloading, reloading]);

    // Re-sync on widget update
    useEffect(() => {
        // Re-sync formatting
        setEditedFormatting(widget.config.formatting);

        // Re-sync series
        const [series] = widget.config.series;
        setEditedSeries(series);

        // Re-sync sort
        setSort(initSort(widget));
    }, [widget]);

    return (
        <div className="d-flex flex-column h-100">
            {/* Table */}
            <div className="flex-grow-1 text-center overflow-x-auto" ref={tableWrapperRef}>
                {rankedFields.length === 0 ? (
                    <div>Select fields</div>
                ) : initialLoading ? (
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <div {...getTableProps({ style: { width: tableWrapperWidth } })} className="list-widget-table">
                        <div>
                            {headerGroups.map(headerGroup => {
                                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <div key={key} {...restHeaderGroupProps} className="tr">
                                        {headerGroup.headers.map((column, index) => {
                                            const bgColor =
                                                rankedFields[index].field.formatting?.color ||
                                                WIDGET_DEFAULT_SERIES_COLOR;
                                            const txtColor = textColorFromBg(
                                                bgColor,
                                                shadeHexColor(bgColor, 1),
                                                shadeHexColor(bgColor, -1)
                                            );
                                            const { key, ...restHeaderProps } = column.getHeaderProps({
                                                className: 'th',
                                                style: {
                                                    backgroundColor: bgColor,
                                                    color: txtColor
                                                }
                                            });

                                            // Types are incorrect for `react-table` v7.7.9. `getResizerProps()` method is missing from `HeaderGroup`.
                                            // => Cast as `any` until we migrate from `react-table` v7 to `@tanstack/react-table` v8+
                                            // and have correct types

                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            const columnAsAny = column as any;

                                            return (
                                                <div key={key} {...restHeaderProps}>
                                                    <div style={{ color: 'inherit' }}>{column.render('Header')}</div>
                                                    <div {...columnAsAny.getResizerProps()} className="resizer" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            {...getTableBodyProps({ style: { width: totalColumnsWidth } })}
                            className="overflow-y-auto tbody"
                        >
                            <FixedSizeList
                                height={tableMaxHeight}
                                itemCount={rows.length}
                                itemSize={TABLE_ROW_HEIGHT}
                                width={totalColumnsWidth}
                            >
                                {renderRow}
                            </FixedSizeList>
                        </div>
                    </div>
                )}
            </div>

            {/* Pagination */}
            <div className="flex-grow-0 mt-2 d-flex align-items-center text-grey-14">
                <div className="flex-grow-1 d-flex align-items-center">
                    {/* Total records */}
                    <div>
                        <RichMessage id="dashboarding.list-widget.pagination.total-records" values={{ totalRecords }} />
                    </div>

                    {/* Records per page */}
                    <Dropdown drop="up" onSelect={(eventKey: string | null) => onLimitChange(eventKey)}>
                        <Dropdown.Toggle className="mx-2 py-2 px-3" variant="outline-light" role="button">
                            {limit}
                        </Dropdown.Toggle>

                        {/* List of allowed limits */}
                        <Dropdown.Menu
                            className="max-vh-30 overflow-y-auto"
                            style={{ minWidth: 'auto' }}
                            // Fixed strategy is required to avoid issues with container overflow
                            popperConfig={{ strategy: 'fixed' }}
                            // Fixed strategy is bugged. Need renderOnMount to work properly
                            // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                            renderOnMount
                        >
                            {ALLOWED_LIMITS.map(limit => {
                                return (
                                    <Dropdown.Item className="text-grey-14" key={limit} eventKey={limit}>
                                        {limit}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div>
                        <RichMessage id="dashboarding.list-widget.pagination.per-page" />
                    </div>
                </div>

                {/* Page change */}
                <div className="flex-grow-0 d-flex align-items-center">
                    {/* First page */}
                    <Button
                        className="p-0 me-1 text-grey-14"
                        variant="link"
                        disabled={offset == 0}
                        onClick={() => setOffset(0)}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} size="lg" />
                    </Button>

                    {/* Previous page */}
                    <Button
                        className="p-0 me-2 text-grey-14"
                        variant="link"
                        disabled={offset == 0}
                        onClick={() => setOffset((pagination.currentPageIndex - 1) * limit)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    </Button>

                    {/* Current page / Last page */}
                    <span>
                        {pagination?.currentPage || '?'}/{pagination?.lastPage || '?'}
                    </span>

                    {/* Next page */}
                    <Button
                        className="p-0 ms-2 text-grey-14"
                        variant="link"
                        disabled={
                            pagination.currentPageIndex == pagination.lastPageIndex ||
                            !pagination?.lastPageIndex ||
                            offset == pagination.lastPageIndex * limit
                        }
                        onClick={() => setOffset((pagination.currentPageIndex + 1) * limit)}
                    >
                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                    </Button>

                    {/* Last page */}
                    <Button
                        className="p-0 ms-1 text-grey-14"
                        variant="link"
                        disabled={
                            pagination.currentPageIndex == pagination.lastPageIndex ||
                            !pagination?.lastPageIndex ||
                            offset == pagination.lastPageIndex * limit
                        }
                        onClick={() => pagination?.lastPageIndex && setOffset(pagination.lastPageIndex * limit)}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                    </Button>
                </div>
            </div>

            {/* Cell details modal */}
            <CellDetailsModal isOpen={isDetailsModalOpen} onHide={() => setIsDetailsModalOpen(false)} value={details} />
        </div>
    );
};

export default ListWidget;
