import { DEFAULT_AUTOCOMMIT_TIME } from 'constants/defaultValues';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

type InputValueType = string | undefined;
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface Props extends FormControlProps {
    value: InputValueType;
    onCommit?: (value: InputValueType) => void;
}

// A variation of Form.Control that automatically gets locked for resync on edit.
// The component supports an onCommit hook which triggers when the component is unfocused or at regular intervals.
const FormControlWithCommit: FunctionComponent<Props> = ({ onFocus, onBlur, onChange, onCommit, value, ...props }) => {
    // State
    const [localValue, setLocalValue] = useState<InputValueType>(value);
    const [isResyncLocked, setIsResyncLocked] = useState<boolean>(false);

    // Get debounced version of onCommit
    const debouncedOnCommit = useMemo(() => onCommit && debounce(onCommit, DEFAULT_AUTOCOMMIT_TIME), [onCommit]);

    // Hook invoked when the input content changes
    const handleOnChange = useCallback(
        (event: React.FocusEvent<FormControlElement>): void => {
            // Propagate event
            setLocalValue(event.target.value);
            onChange && onChange(event);

            // Auto-commit regularly
            debouncedOnCommit && debouncedOnCommit(event.target.value);
        },
        [debouncedOnCommit, onChange]
    );

    // Hook invoked when the input gets focus
    const handleOnFocus = useCallback(
        (event: React.FocusEvent<FormControlElement>): void => {
            // Prevent resyncs from parent
            setIsResyncLocked(true);

            // Propagate event
            onFocus && onFocus(event);
        },
        [onFocus]
    );

    // Hook invoked when the input loses focus
    const handleOnBlur = useCallback(
        (event: React.FocusEvent<FormControlElement>): void => {
            // Progagate event
            onBlur && onBlur(event);

            // Cancel the next debounce iteration and commit immediately
            // The debounce cancellation ensures we do not end up with
            // two successive identical updates
            debouncedOnCommit?.cancel();
            onCommit && value != localValue && onCommit(localValue);

            // Allow resyncs from parent
            setIsResyncLocked(false);
        },
        [debouncedOnCommit, localValue, onBlur, onCommit, value]
    );

    // Resync local value from attribute when the input is unlocked
    useEffect(() => {
        !isResyncLocked && setLocalValue(value);
    }, [isResyncLocked, value]);

    // Render
    return (
        <Form.Control
            value={localValue}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            {...props}
        />
    );
};

export default FormControlWithCommit;
