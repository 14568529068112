import { createBreakpoint } from 'react-use';

// List of breakpoints
export type ScreenBreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

// Configuration
const BREAKPOINT_CONFIG: { [key in ScreenBreakpointType]: number } = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};

// The equivalent of bootstrap breakpoints in JS
export const useBreakpoint = createBreakpoint(BREAKPOINT_CONFIG) as () => ScreenBreakpointType;
