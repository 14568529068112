import gql from 'graphql-tag';

import { AppResp } from './App';
import { CompanyResp } from './Company';
import { MutationResp } from './Shared';

const INTEGRATION_ATTRIBUTES = gql`
    fragment integrationAttributes on Integration {
        id
        status
        authorizerUsername
        apiEndpoint
        app {
            id
            category
            name
            provider
        }
        company {
            id
            name
        }
    }
`;

export interface IntegrationResp {
    id: string;
    status: string;
    authorizerUsername: string;
    apiEndpoint: string;
    app: AppResp;
    company: CompanyResp;
}

export interface GetIntegrationResp {
    integration: IntegrationResp;
}

// Retrieve integration by id
export const GET_INTEGRATION = gql`
    query($id: ID!) {
        integration(id: $id) {
            ...integrationAttributes
        }
    }
    ${INTEGRATION_ATTRIBUTES}
`;

export interface GetIntegrationsResp {
    integrations: {
        nodes: IntegrationResp[];
    };
}

// Retrieve integration query
export const GET_INTEGRATIONS_BY_APP = gql`
    query($companyId: ID, $appId: ID) {
        integrations(companyId: $companyId, appId: $appId) {
            nodes {
                ...integrationAttributes
            }
        }
    }
    ${INTEGRATION_ATTRIBUTES}
`;

export interface GetCompanyIntegrationsResp {
    integrations: {
        pageInfo: {
            endCursor: string | null;
        };
        nodes: IntegrationResp[];
    };
}

export const GET_COMPANY_INTEGRATIONS = gql`
    query getCompanyIntegrations($companyId: ID!, $cursor: String, $categories: [AppCategoryEnum!]) {
        integrations(after: $cursor, sortBy: "name.asc", companyId: $companyId, appCategoryIn: $categories) {
            pageInfo {
                endCursor
            }
            nodes {
                ...integrationAttributes
            }
        }
    }
    ${INTEGRATION_ATTRIBUTES}
`;

export const SUBSCRIBE_TO_INTEGRATION_UPDATED = gql`
    subscription subscribeToIntegrationUpdated($companyId: ID!) {
        integrationUpdated(companyId: $companyId) {
            ...integrationAttributes
        }
    }
    ${INTEGRATION_ATTRIBUTES}
`;

export const SUBSCRIBE_TO_INTEGRATION_DELETED = gql`
    subscription subscribeToIntegrationDeleted($companyId: ID!) {
        integrationDeleted(companyId: $companyId) {
            id
        }
    }
`;

export interface DeleteIntegrationResp {
    deleteIntegration: MutationResp;
}

// Delete an integration (= disconnect an app)
export const DELETE_INTEGRATION = gql`
    mutation($id: ID!) {
        deleteIntegration(input: { id: $id }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export interface LinkIntegrationUserResp {
    linkIntegrationUser: MutationResp;
}

export const LINK_INTEGRATION_USER = gql`
    mutation($integrationId: ID!, $token: String!) {
        linkIntegrationUser(input: { integrationId: $integrationId, token: $token }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
