import classNames from 'classnames';
import { ConfigQn } from 'components/Dashboarding/Models/ConfigQns';
import { HelpIcon, RichMessage, useRichIntl } from 'components/RichMessage';
import { GithubMarkdown } from 'components/TextProcessor';
import React, { FunctionComponent, useMemo } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    config: ConfigQn;
}

export const Label: FunctionComponent<Props> = ({ config }: Props) => {
    // Services
    const intl = useRichIntl();

    // Build the helper content
    const helperContent = useMemo(() => {
        let content = '';

        // If the question has documentation
        if (config.documentation) {
            content += config.documentation;
        }

        if (config.documentation && config.impactedWidgets?.[0]) {
            content += '\n';
        }

        // If widgets are impacted by the question
        if (config.impactedWidgets?.[0]) {
            content += intl.formatMessage(
                {
                    id: 'dashboarding.dashboard-assistant.helper.impacted-insights'
                },
                { insightsCount: config.impactedWidgets?.length }
            );
            content += '\n';
            config.impactedWidgets.forEach(e => (content += `- **${e.name}**: ${e.description}\n`));
        }
        return content;
    }, [intl, config.documentation, config.impactedWidgets]);

    return (
        <Form.Label className="mb-3">
            <div className="d-flex">
                {/* Label */}
                <div>{config.label}</div>

                {/* Helper */}
                {helperContent && (
                    <HelpIcon className="align-self-center">
                        <GithubMarkdown markup={helperContent} />
                    </HelpIcon>
                )}
            </div>
            <p className="text-grey-14 fw-500 mb-0">
                {/* Hint */}
                {config.hint}

                {/* Required */}
                {!config.isValid && (
                    <span className={classNames('text-danger', { 'ms-2': config.hint })}>
                        <RichMessage id="dashboarding.assistant.form.required" />
                    </span>
                )}
            </p>
        </Form.Label>
    );
};
