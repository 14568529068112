import { MutationResp } from 'api/hq/queries/Shared';
import { ItemUser } from 'api/viz/queries/User';
import { gql } from 'apollo-boost';

export interface ItemProject {
    id: string;
    name: string;
    provider: string;
}

export interface ItemLabel {
    name: string;
    description: string;
    color: string;
}

export interface ItemMilestone {
    due_on?: string;
    title?: string;
    description?: string;
}

export interface ItemPullRequest {
    _system_id: string;
    id: string;
    created_at: string;
    updated_at: string;
    author: ItemUser;
    body: string;
    state: string;
    title: string;
    url: string;
}

export interface InboxItemRelated {
    _system_id: string;
    id: string;
    created_at: string;
    updated_at: string;
    author: ItemUser;
    body: string;
    due_on: string;
    key: string;
    labels: ItemLabel[];
    milestone?: ItemMilestone;
    project: ItemProject;
    state: string;
    title: string;
    type: string;
    url: string;
}

export interface InboxItem {
    _system_id: string;
    id: string;
    created_at: string;
    updated_at: string;
    author: ItemUser;
    body: string;
    build_status: string;
    due_on: string;
    key: string;
    labels: ItemLabel[];
    merged_at?: string;
    milestone?: ItemMilestone;
    project: ItemProject;
    recommended_action?: string;
    recommended_actors: ItemUser[];
    required_approval_remaining_count: number;
    resolved_issue_sysids: string[];
    resolving_pull_request_sysids: string[];
    resolution_state: string;
    title: string;
    type: string;
    url: string;
}
export type InboxItemFields = keyof InboxItem;

export interface FeedItem {
    _system_id: string;
    author: ItemUser;
    body: string;
    parent: InboxItem | InboxItemRelated;
    state?: string;
    submitted_at: string;
    type: string;
    updated_at: string;
    url: string;
}

export interface InboxItemWithRelated extends InboxItem {
    resolved_issues: InboxItemRelated[];
    resolving_pull_requests: InboxItemRelated[];
}

export interface PendingPrItem {
    _system_id: string;
    id: string;
    created_at: string;
    updated_at: string;
    additions: number;
    author: ItemUser;
    body: string;
    build_status: string;
    lines_changed: number;
    deletions: number;
    due_on: string;
    key: string;
    labels: ItemLabel[];
    mergeable: string;
    milestone: ItemMilestone;
    project: ItemProject;
    recommended_action: string;
    resolved_issue_sysids: string[];
    review_complexity: string;
    review_state: string;
    suggested_reviewers: ItemUser[];
    title: string;
    type: string;
    url: string;
}

export interface PendingPrItemWithRelated extends PendingPrItem {
    resolved_issues: InboxItemRelated[];
}

export interface GetInboxItemsResp {
    queriedItems: InboxItem[];
}

// Retrieve full representation of inbox items
export const GET_INBOX_ITEMS = gql`
    query($companyId: String!, $dimensions: [DimensionInput!], $filters: [FilterInput!], $cacheDuration: Int) {
        report(
            cacheDuration: $cacheDuration
            queries: [
                {
                    companyScopes: [$companyId]
                    target: "queriedItems"
                    fact: INBOX_ITEMS
                    factors: [{ string: $userId }]
                    dimensions: $dimensions
                    filters: $filters
                }
            ]
        ) {
            id
            results
            errors {
                message
            }
        }
    }
`;

export interface GetInboxItemRelatedResp {
    relatedItems: InboxItemRelated[];
}

// The last 100 comments are fetched (100 is the default limit)
export const GET_INBOX_ITEM_RELATED = gql`
    query($relatedSysIds: [String!], $relatedType: String!) {
        report(
            queries: [
                {
                    target: "relatedItems"
                    fact: INBOX_ITEMS
                    dimensions: [
                        { field: "_system_id" }
                        { field: "id" }
                        { field: "created_at" }
                        { field: "updated_at" }
                        { field: "author" }
                        { field: "body" }
                        { field: "due_on" }
                        { field: "key" }
                        { field: "labels" }
                        { field: "milestone" }
                        { field: "project" }
                        { field: "state" }
                        { field: "title" }
                        { field: "type" }
                        { field: "url" }
                    ]
                    filters: [
                        { function: IN, args: [{ field: "_system_id" }, { stringL: $relatedSysIds }] }
                        { function: EQUAL, args: [{ field: "type" }, { string: $relatedType }] }
                    ]
                }
            ]
        ) {
            id
            results
            errors {
                message
            }
        }
    }
`;

export interface UpdateInboxItemPriorityResp {
    updateInboxItem: MutationResp;
}

export const UPDATE_INBOX_ITEM_PRIORITY = gql`
    mutation($sysId: ID!, $priorityGroupAssigned: InboxItemPriorityGroupEnum) {
        updateInboxItem(input: { sysid: $sysId, priorityGroupAssigned: $priorityGroupAssigned }) {
            success
            errors {
                code
                path
                message
            }
        }
    }
`;
