import gql from 'graphql-tag';
import { MutationResp } from './Shared';

export interface InviteResp {
    id: string;
    email: string;
    createdAt: string;
    expireAt: string;
}

export interface GetCompanyInvitesResp {
    company: {
        id: string;
        requestedInvites: {
            nodes: InviteResp[];
        };
    };
}

// Pagination is skipped as it is expected that companies will not
// have more than 100 (= default page size) requested invites at once for now.
export const GET_COMPANY_INVITES = gql`
    query($companyId: ID!) {
        company(id: $companyId) {
            id
            requestedInvites(sortBy: "email.asc") {
                nodes {
                    id
                    email
                    createdAt
                    expireAt
                }
            }
        }
    }
`;

export interface CreateInviteResp {
    createCompanyInvite: MutationResp;
}

export const CREATE_INVITE = gql`
    mutation($companyId: ID!, $email: String!) {
        createCompanyInvite(input: { email: $email, companyId: $companyId }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export const CANCEL_INVITE = gql`
    mutation($id: ID!) {
        cancelCompanyInvite(input: { id: $id }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export interface GetCompanyInviteByRefResp {
    companyInvite: {
        id: string;
        company: {
            id: string;
            name: string;
        };
        requestor: {
            firstName: string;
        };
    };
}

export const GET_COMPANY_INVITE_BY_REF = gql`
    query($id: ID!, $token: String!) {
        companyInvite(id: $id, token: $token) {
            id
            company {
                id
                name
            }
            requestor {
                firstName
            }
        }
    }
`;

export interface AcceptCompanyInviteResp {
    acceptCompanyInvite: MutationResp;
}

export const ACCEPT_COMPANY_INVITE = gql`
    mutation($id: ID!, $token: String!) {
        acceptCompanyInvite(input: { id: $id, token: $token }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
