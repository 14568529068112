import { useMemo } from 'react';
import { GetItemCommentsResp, GET_ITEM_COMMENTS, ItemComment } from '../queries/Comment';
import { useReport, UseReportResult } from './useReport';

interface UseItemCommentsOps {
    skip?: boolean | null;
    noSubscribe?: boolean;
    parentSysIds: string[];
}

interface UseItemCommentsQueryResults extends UseReportResult<GetItemCommentsResp> {
    normalized: ItemComment[];
}

export function useItemComments({ skip, noSubscribe, parentSysIds }: UseItemCommentsOps): UseItemCommentsQueryResults {
    // Fetch items
    const reportQuery = useReport<GetItemCommentsResp>(GET_ITEM_COMMENTS, {
        skip: skip,
        noSubscribe: noSubscribe,
        variables: {
            parentSysIds
        }
    });

    const normalized = useMemo(() => reportQuery.data?.report.results.comments || [], [
        reportQuery.data?.report.results.comments
    ]);

    return { ...reportQuery, normalized };
}
