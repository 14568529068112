import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullPageLoader from 'components/Design/FullPageLoader';
import { Questions } from 'components/Questions';
import { dashboardViewPath, DASHBOARD_TMPL_ID_KEY } from 'constants/routeBuilders';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTemplateAssistant } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import { ASSISTANT_DSHB_QNS_KEY } from 'constants/localStorageKeys';
import { useTitleLabel } from 'util/useTitleLabel';
import TemplateCustomizationDiagram from 'routes/flows/Onboarding1/TemplateCustomizationDiagram';
import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import { omit } from 'lodash';
import { useDashboardCreator } from 'components/Dashboarding/Hooks/useDashboardCreator';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import { toastQueryError } from 'components/Toast';

interface HeaderProps {
    handleCancelClick: () => void;
}
const Header: FunctionComponent<HeaderProps> = ({ handleCancelClick }) => {
    return (
        <div className="splitscreen-flow-header d-flex align-items-center p-4">
            {/* Logo */}
            <div className="flex-grow-1 flex-basis-0">
                <KeypupLogoFull />
            </div>

            {/* Cancel button */}
            <div className="flex-grow-1 flex-basis-0">
                <Button variant="link" className="justify-content-end p-0 w-100" onClick={handleCancelClick}>
                    <RichMessage id="dashboarding.dashboard-assistant.header.cancel" />
                </Button>
            </div>
        </div>
    );
};

const DashboardAssistant: FunctionComponent = () => {
    // Page title
    useTitleLabel('dashboarding.dashboard-assistant.title');

    // State
    const [createInProgress, setCreateInProgress] = useState<boolean>(false);

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Services
    const navigate = useNavigate();
    const { createDashboardFromTemplate } = useDashboardCreator();

    // Extract params
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(DASHBOARD_TMPL_ID_KEY);

    // Template customizer hook
    const { loading, resultTemplate, questions, updateQuestion, resetQuestions, clearQuestions } = useTemplateAssistant(
        ASSISTANT_DSHB_QNS_KEY,
        templateId,
        'DASHBOARD',
        3
    );

    // Invalid form
    const isFormInvalid = useMemo(() => !questions || Array.from(questions.values()).some(e => !e.isValid), [
        questions
    ]);

    // Hook invoked on back click
    const handleBackClick = useCallback(() => {
        // Clear questions from local storage
        clearQuestions();

        // Navigate to previous history stack
        navigate(-1);
    }, [clearQuestions, navigate]);

    // Hook invoked user clicks the `Next` button
    const handleCreateClick = useCallback(async () => {
        // Set loading state
        setCreateInProgress(true);

        // Format the template
        const formattedTemplate = omit(resultTemplate, 'imgDataUrl') as DashboardTemplate;

        try {
            // Create the dashboard
            const resp = (
                await createDashboardFromTemplate(formattedTemplate, {
                    companyId: company?.id,
                    pinned: true,
                    actionSource: 'platform-template'
                })
            )?.createDashboard;
            const dashboardId = resp?.dashboard?.id;

            if (resp && (!resp.success || !dashboardId)) {
                toastQueryError({ ...resp?.errors[0], namespace: 'save-dashboard' });
                return;
            }

            if (dashboardId) {
                // Clear questions from local storage
                clearQuestions();

                // Redirect to the newly created dashboard
                navigate(dashboardViewPath({ dashboardId }));
            }
        } catch {
            toastQueryError({ namespace: 'save-dashboard' });
            return;
        } finally {
            setCreateInProgress(false);
        }
    }, [clearQuestions, company?.id, createDashboardFromTemplate, navigate, resultTemplate]);

    return (
        <LayoutSplitScreen header={<Header handleCancelClick={handleBackClick} />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            {loading ? (
                <>
                    {/* Display loading screen if questions are still loading */}
                    <FullPageLoader />
                </>
            ) : (
                <>
                    <div className="flex-grow-1">
                        {/* Title */}
                        <div className="d-flex align-items-baseline mt-4 mb-5">
                            <Button onClick={handleBackClick} variant="link" className="p-0">
                                <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                            </Button>
                            <h1 className="fw-bolder">
                                <RichMessage id="dashboarding.dashboard-assistant.configure.title" />
                            </h1>
                        </div>

                        {/* Questions */}
                        <div className="mb-5">
                            <Questions
                                questions={questions}
                                updateQuestion={updateQuestion}
                                resetQuestions={resetQuestions}
                            />
                        </div>
                    </div>

                    {/* Next button */}
                    <div className="flex-grow-0">
                        <AsyncButton
                            className="justify-content-center w-100 mb-4"
                            variant="primary"
                            onClick={handleCreateClick}
                            disabled={isFormInvalid || createInProgress}
                            loading={createInProgress}
                            messageProps={{ id: 'dashboarding.dashboard-assistant.configure.create' }}
                            loadingMessageProps={{ id: 'dashboarding.dashboard-assistant.configure.creating' }}
                        />
                    </div>
                </>
            )}

            {/* Right section */}
            <TemplateCustomizationDiagram
                className="h-auto m-auto"
                style={{ width: '45%', maxHeight: '90vh', maxWidth: '35vh' }}
                template={resultTemplate as DashboardTemplate}
            />
        </LayoutSplitScreen>
    );
};

export default DashboardAssistant;
