import { Notification } from 'api/hq/queries/Notification';
import { useCompanyIntegrations } from 'api/hq/hooks/useCompanyIntegrations';
import { useUserIdentities } from 'api/hq/hooks/useUserIdentities';
import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import { useEffect, useMemo, useState } from 'react';
import { PROJECT_DISABLED_STATUS, PROJECT_IMPORTING_STATUS, PROJECT_PENDING_STATUS } from 'constants/defaultValues';
import { usePrevious } from 'react-use';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';

// Return the list of notifications to be displayed to the user
// Notifications are account related (e.g. no apps connected, no projects connected etc.)
export function useNotifications(): Notification | undefined {
    // Global state
    const authUser = useSelector((e: ReduxState) => e.authUser);
    const companyId = authUser.company?.id;
    const userId = authUser?.apiUser?.user?.id;

    const integrationsResp = useCompanyIntegrations({ companyId });
    const projectsResp = useCompanyProjects({ companyId });
    const identitiesResp = useUserIdentities({ userId });
    const [projectsImportCount, setProjectsImportCount] = useState<number>();

    // Extract variables
    const integrations = useMemo(() => integrationsResp.data?.integrations.nodes || [], [
        integrationsResp.data?.integrations.nodes
    ]);
    const projects = projectsResp.data?.company.projects.nodes || [];
    const activeProjects = projects.filter(e => e.status !== PROJECT_DISABLED_STATUS);
    const importingProjects = projects.filter(
        e => e.status == PROJECT_IMPORTING_STATUS || e.status == PROJECT_PENDING_STATUS
    );
    const prevImportingProjects = usePrevious(importingProjects);

    const isLoading =
        !userId || !companyId || integrationsResp.loading || projectsResp.loading || identitiesResp.loading;

    useEffect(() => {
        (projectsImportCount == undefined || prevImportingProjects?.length == 0) &&
            importingProjects.length > 0 &&
            setProjectsImportCount(importingProjects.length);
    }, [projectsImportCount, prevImportingProjects, importingProjects.length]);

    return useMemo(() => {
        // Abort initial evaluation if component is still loading
        if (isLoading) return;

        if (integrations.length === 0) {
            return { type: 'connect-apps' };
        } else if (activeProjects.length === 0) {
            return { type: 'connect-projects' };
        } else if (importingProjects.length > 0 && projectsImportCount) {
            return { type: 'projects-importing', context: { projectsImportCount: projectsImportCount } };
        } else if (
            prevImportingProjects &&
            prevImportingProjects.length > 0 &&
            importingProjects.length === 0 &&
            projectsImportCount
        ) {
            const importedProjectsCount = projectsImportCount;
            return { type: 'projects-imported', context: { projectsImportCount: importedProjectsCount } };
        }
    }, [
        isLoading,
        integrations.length,
        activeProjects.length,
        importingProjects.length,
        prevImportingProjects,
        projectsImportCount
    ]);
}
