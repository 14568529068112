import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    DimensionFormattingOptions,
    MetricFormattingOptions,
    sortedSeriesColumns,
    WidgetFormattingOptions,
    WidgetList,
    WidgetTypedFieldWrapper
} from 'components/Dashboarding/Models/Widget';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import PaletteColorPicker from 'components/Design/ColorPickers/PaletteColorPicker';
import WidgetDisplayFormatterSelector from './WidgetDisplayFormatterSelector';
import FormControlWithCommit from 'components/Input/FormControlWithCommit';
import { Form } from 'react-bootstrap';
import { merge } from 'lodash';

// i18n configuration
const DEFAULT_FIELD_NS = 'dashboarding.widget-configuration.axis.LIST';

interface Props {
    widget: WidgetList;
    onChange: (widget: WidgetList) => void;
}

const CustomizeList: FunctionComponent<Props> = ({ widget, onChange }: Props) => {
    // Services
    const intl = useRichIntl();

    // Local widget. Used for use in combination with debounced functions
    const [localWidget, setLocalWidget] = useState<WidgetList>(widget);

    // Get main series
    const mainSeries = localWidget.config.series[0];
    const rankedFields = useMemo(() => sortedSeriesColumns(localWidget.configType, mainSeries), [
        mainSeries,
        localWidget.configType
    ]);

    // Hook invoked when the formatting of a field (dimension/metric) is updated
    const onColumnFormattingChange = useCallback(
        (
            fieldWrapper: WidgetTypedFieldWrapper,
            addedOpts: Partial<MetricFormattingOptions | DimensionFormattingOptions>
        ) => {
            // Update the target field
            const baseField = fieldWrapper.field;
            const updatedField = { ...baseField, formatting: { ...baseField.formatting, ...addedOpts } };

            // Generate the updated list
            const updatedFieldList =
                fieldWrapper.type == 'metric' ? [...mainSeries.metrics] : [...mainSeries.dimensions];
            updatedFieldList[fieldWrapper.localIndex] = updatedField;

            // Apply the changes
            const updatedSeries =
                fieldWrapper.type == 'metric'
                    ? { ...mainSeries, metrics: updatedFieldList }
                    : { ...mainSeries, dimensions: updatedFieldList };
            const updatedWidget = {
                ...localWidget,
                config: { ...localWidget.config, series: [updatedSeries] }
            } as WidgetList;
            setLocalWidget(updatedWidget);
            onChange(updatedWidget);
        },
        [localWidget, mainSeries, onChange]
    );

    // Hook invoked when the general formatting is updated
    const onWidgetFormattingChange = useCallback(
        (addedOpts: Partial<WidgetFormattingOptions>) => {
            // Merge new formatting
            const updatedFormatting = merge({}, localWidget.config.formatting, addedOpts);

            // Updated widget
            const updatedWidget = { ...localWidget, config: { ...localWidget.config, formatting: updatedFormatting } };

            // Update state
            setLocalWidget(updatedWidget);

            // Propagate to parent
            onChange(updatedWidget);
        },
        [localWidget, onChange]
    );

    // Resync local state when parent gets updated
    useEffect(() => setLocalWidget(widget), [widget]);

    // Render
    return (
        <div>
            {/* Field customization */}
            <div>
                {/* Title */}
                <div>
                    <h4 className="text-dark mb-4">
                        <RichMessage
                            id="dashboarding.widget-editor.customize-list.column-customization"
                            values={{ count: rankedFields.length }}
                        />
                    </h4>
                </div>

                {/* Metrics section inner */}
                <Row className="mb-4">
                    <Col xs="12" xxl="8">
                        {rankedFields.map(fieldWrapper => {
                            return (
                                <div key={fieldWrapper.absoluteIndex} className="d-flex mb-2">
                                    {/* Header color */}
                                    <PaletteColorPicker
                                        color={fieldWrapper.field.formatting?.color}
                                        onChange={e => onColumnFormattingChange(fieldWrapper, { color: e })}
                                        className="me-2"
                                    />

                                    {/* Header label */}
                                    <FormControlWithCommit
                                        value={fieldWrapper.field.formatting?.label || ''}
                                        placeholder={intl.formatMessage(
                                            { id: `${DEFAULT_FIELD_NS}.${fieldWrapper.type}.*` },
                                            { index: fieldWrapper.localIndex + 1 }
                                        )}
                                        onCommit={val => onColumnFormattingChange(fieldWrapper, { label: val })}
                                    />

                                    {/* Formatter */}
                                    <WidgetDisplayFormatterSelector
                                        className="ms-2"
                                        fact={localWidget.config.fact}
                                        field={fieldWrapper.field}
                                        onChange={e =>
                                            onColumnFormattingChange(fieldWrapper, {
                                                formatter: e.formatting?.formatter
                                            })
                                        }
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>

                {/* Striped rows coloring */}
                <Row className="mb-4">
                    <Col xs="6" className="ps-4">
                        {/* Legend switch */}
                        <div className="d-flex justify-content-between mb-2">
                            <div className="me-2">
                                <Form.Label htmlFor="stripedRowsSwitchInput" className="d-block mb-0">
                                    <RichMessage id="dashboarding.widget-editor.customize-list.striped-rows.title" />
                                </Form.Label>
                                <Form.Text id="stripedRowsSwitchHelp" muted>
                                    <RichMessage id="dashboarding.widget-editor.customize-list.striped-rows.description" />
                                </Form.Text>
                            </div>
                            <Form.Check
                                type="switch"
                                id="stripedRowsSwitchInput"
                                aria-describedby="stripedRowsSwitchHelp"
                                role="button"
                                checked={localWidget.config.formatting?.enableStripedRows}
                                onChange={e => onWidgetFormattingChange({ enableStripedRows: e.target.checked })}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CustomizeList;
