import { ReportFunction, ReportFunctionArg } from 'api/viz/hooks/useGenericReport';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { QueryOpField } from 'components/Dashboarding/DataSource/QueryOperators';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { useCallback } from 'react';
import FormulaParser from './FormulaParser';

// This parser parses the syntax and return a formatted object
// The content of each object does not get transformed
export const parseFormulaAsObject = (formula?: string): ReportFunction | undefined => {
    return FormulaParser.parse(formula);
};

interface ParseFormulaProps {
    fieldMappings: QueryOpField[];
    parsedFormula?: ReportFunction | ReportFunctionArg;
}

// Replace field IDs in the source parsedFormula by their actual field content
// E.g. author_usernames becomes author.username
export const parseFormulaAsQuery = ({
    fieldMappings,
    parsedFormula
}: ParseFormulaProps): ReportFunction | ReportFunctionArg | undefined => {
    // Abort if undefined
    if (parsedFormula == undefined) return undefined;

    // Function case
    if ('function' in parsedFormula) {
        return {
            ...parsedFormula,
            args: parsedFormula.args.map(e => parseFormulaAsQuery({ fieldMappings, parsedFormula: e })) as (
                | ReportFunction
                | ReportFunctionArg
            )[]
        };
    }

    // Field case
    if ('field' in parsedFormula) {
        const fieldId = parsedFormula.field;
        const fieldContent = fieldMappings.find(e => e.id == fieldId)?.name;
        return { field: fieldContent || fieldId };
    }

    // All other cases, just passthrough
    return parsedFormula;
};

interface UseFormulaFormatterProps {
    fact: QueryOpFact;
}

// This hook returns a parser which parses a formula syntax and also replaces the field IDs by their
// content.
// E.g. author_usernames becomes author.username
export const useParseFormulaAsQuery = ({
    fact
}: UseFormulaFormatterProps): ((formula?: string) => ReportFunction | undefined) => {
    // Get fields associated with the provided fact
    const { queryOpFields } = useQueryOpFields({ fact });

    // Build formatter for the provided fact
    const formatter = useCallback(
        (formula?: string) => {
            const parsedFormula = parseFormulaAsObject(formula);
            return parseFormulaAsQuery({ fieldMappings: queryOpFields, parsedFormula: parsedFormula }) as
                | ReportFunction
                | undefined;
        },
        [queryOpFields]
    );

    return formatter;
};
