// Apollo Configuration
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Pusher from 'pusher-js';
import PusherLink from './PusherLink';
import AuthErrorLink from './AuthErrorLink';
import { RetryLink } from 'apollo-link-retry';
import { OperationDefinitionNode, OperationTypeNode } from 'graphql';

// Configuration
const PUSHER_APP_KEY = process.env.REACT_APP_HQ_PUSHER_APP_KEY || 'none';
const PUSHER_CLUSTER = process.env.REACT_APP_HQ_PUSHER_CLUSTER || 'us2';

// Load Pusher and create a client
const pusherClient = new Pusher(PUSHER_APP_KEY, { cluster: PUSHER_CLUSTER, forceTLS: true });

// Make the HTTP link which actually sends the queries
const httpLink = new HttpLink();

// Automatically retry on network/server error
const retryLink = new RetryLink({
    attempts: {
        max: 10,
        retryIf: (error, operation) => {
            // Do not retry mutations or unauthorized queries
            return (
                error.statusCode !== 401 &&
                error.statusCode !== 403 &&
                !operation.query.definitions
                    .map(e => (e as OperationDefinitionNode).operation)
                    .includes(OperationTypeNode.MUTATION)
            );
        }
    }
});

// Make the Pusher link which will pick up on subscriptions
const pusherLink = new PusherLink({ pusher: pusherClient });

// Combine the links to work together
const link = ApolloLink.from([retryLink, AuthErrorLink, pusherLink, httpLink]);

// Initialize the client
export const hqApolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        }
    }
});
