import React, { FunctionComponent, useCallback } from 'react';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichMessage } from 'components/RichMessage';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DashboardTemplate, DASHBOARD_ONBOARDING_TEMPLATES } from 'components/Dashboarding/Models/DashboardTemplates';
import {
    ONBOARDING1_PATH,
    ONBOARDING1_STEP2,
    onboardingTemplateSelectionNextPath,
    ONBOARDING_SKIPPED_STEP_KEY
} from 'constants/routeBuilders';
import TemplateCustomizationDiagram from './TemplateCustomizationDiagram';
import Header from './Header';
import {
    DashboardTemplateLibraryCard,
    DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH
} from 'components/LibraryCard/DashboardTemplateLibraryCard';
import { useMeasure } from 'react-use';
import { LIBRARY_CARD_MARGIN } from 'components/LibraryCard';
import { computeCardsContainerMaxWidth } from 'components/LibraryCard/LibraryCardUtils';

const Step3aTemplateSelection: FunctionComponent = () => {
    // Refs
    const [parentContainerRef, { width: parentContainerWidth }] = useMeasure<HTMLDivElement>();

    // Services
    const navigate = useNavigate();

    // Extract params
    const [searchParams] = useSearchParams();
    const skippedStep = searchParams.get(ONBOARDING_SKIPPED_STEP_KEY);

    const handleTemplateClick = useCallback(
        (templateId: string) => navigate(onboardingTemplateSelectionNextPath(skippedStep, templateId)),
        [navigate, skippedStep]
    );

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            <>
                {/* Title */}
                <div className="d-flex align-items-baseline mt-4 mb-5">
                    <Button
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        as={Link as any}
                        to={`${ONBOARDING1_PATH}/${skippedStep || ONBOARDING1_STEP2}`}
                        variant="link"
                        className="p-0"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                    </Button>
                    <h1 className="fw-bolder">
                        <RichMessage id="flows.onboarding.configure-dashboard.template-selection.title" />
                    </h1>
                </div>

                <div ref={parentContainerRef}>
                    <Row
                        className="mx-auto"
                        xs="auto"
                        style={{
                            maxWidth: computeCardsContainerMaxWidth(
                                parentContainerWidth,
                                DASHBOARD_TEMPLATE_LIBRARY_CARD_WIDTH + 2 * LIBRARY_CARD_MARGIN
                            )
                        }}
                    >
                        {DASHBOARD_ONBOARDING_TEMPLATES.map((d: DashboardTemplate) => (
                            <Col key={d.id} className="p-0">
                                {/* Dashboard card */}
                                <DashboardTemplateLibraryCard dashboard={d}>
                                    <Button
                                        variant="dark"
                                        onClick={() => handleTemplateClick(d.id)}
                                        className="rounded-0 rounded-bottom justify-content-center w-100"
                                    >
                                        <RichMessage id="flows.onboarding.configure-dashboard.template-selection.select-template" />
                                    </Button>
                                </DashboardTemplateLibraryCard>
                            </Col>
                        ))}
                    </Row>
                </div>
            </>

            {/* Right section */}
            <TemplateCustomizationDiagram
                className="h-auto m-auto"
                style={{ width: '45%', maxHeight: '90vh', maxWidth: '35vh' }}
            />
        </LayoutSplitScreen>
    );
};

export default Step3aTemplateSelection;
