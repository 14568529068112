import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import { filterFieldList } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import React, { CSSProperties, ReactElement, useCallback, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { RuleGroupType } from 'react-querybuilder';
import FilterBoxModal from './FilterBoxModal';

interface Props<TSeries> {
    className?: string;
    fact: QueryOpFact;
    onChange: (widget: TSeries) => void;
    style?: CSSProperties;
    series?: TSeries;
    titleId?: string;
    variant?: string;
    drilldownEnabled?: boolean;
}

// The filter box provides a light summary of the currently active
// fields and allows users to customize filters via a modal.
function FilterBoxButton<TSeries extends { filters?: RuleGroupType }>({
    className,
    fact,
    onChange,
    series,
    style,
    titleId,
    variant,
    drilldownEnabled
}: Props<TSeries>): ReactElement<Props<TSeries>> {
    // State
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Get all fields defined on the fact
    const { queryOpFields } = useQueryOpFields({ fact: fact });

    // Get the number of fields filtered on
    const filterCount = useMemo(() => filterFieldList(queryOpFields, series?.filters).length, [
        queryOpFields,
        series?.filters
    ]);

    // Hook invoked when the modal filters are saved
    const onFilterSave = useCallback(
        (e: TSeries) => {
            onChange(e);
            setIsOpen(false);
        },
        [onChange]
    );

    // Render
    return (
        <>
            <Button
                variant={variant}
                className={className}
                disabled={!series || drilldownEnabled == false}
                style={style}
                onClick={() => setIsOpen(true)}
            >
                <FontAwesomeIcon icon={faFilter} className="me-2" />
                <RichMessage id={titleId || 'dashboarding.widget-editor.filter-box.default-title'} />
                {filterCount > 0 && ` (${filterCount})`}
            </Button>
            {series && (
                <FilterBoxModal
                    fact={fact}
                    isOpen={isOpen}
                    onSave={onFilterSave}
                    titleId={titleId}
                    toggle={() => setIsOpen(!isOpen)}
                    series={series}
                    drilldownEnabled={drilldownEnabled}
                />
            )}
        </>
    );
}

export default FilterBoxButton;
