import gql from 'graphql-tag';

import { AccountResp } from './Account';
import { AppResp } from './App';
import { MutationResp, SprintSettings } from './Shared';

export const PROJECT_STATUS = ['ACTIVE', 'DISCONNECTED', 'IMPORTING', 'PENDING', 'DISABLED'] as const;
export type ProjectStatus = typeof PROJECT_STATUS[number];

export interface ProjectResp {
    id: string;
    name: string;
    progress: number;
    status: ProjectStatus;
    account: AccountResp;
    app: AppResp;
    integration: {
        id: string;
        status: string;
    };
    warnings: string[];
    sprintSettings: SprintSettings;
}

export interface EnableProjectsResp {
    enableProjects: MutationResp;
}

export interface DisableProjectsResp {
    disableProjects: MutationResp;
}

export interface TrackProjectResp {
    trackProject: MutationResp;
}

export interface UpdateProjectResp {
    updateProject: MutationResp;
}

export const PROJECT_ATTRIBUTES = gql`
    fragment projectAttributes on Project {
        id
        name
        progress
        status
        warnings
        account {
            id
            accountType
            name
        }
        app {
            category
            name
            provider
        }
        integration {
            id
            status
        }
        sprintSettings {
            methodology
            timerStartOn
            timerDuration
        }
    }
`;

export interface GetCompanyProjectsResp {
    company: {
        id: string;
        __typename: string;
        projects: {
            __typename: string;
            pageInfo: {
                endCursor: string | null;
            };
            nodes: ProjectResp[];
        };
    };
}

export const GET_COMPANY_PROJECTS = gql`
    query getCompanyProjects($companyId: ID!, $cursor: String, $statuses: [ProjectStatusEnum!]) {
        company(id: $companyId) {
            id
            projects(after: $cursor, sortBy: "name.asc", statusIn: $statuses) {
                pageInfo {
                    endCursor
                }
                nodes {
                    ...projectAttributes
                }
            }
        }
    }
    ${PROJECT_ATTRIBUTES}
`;

export const SUBSCRIBE_TO_PROJECT_CREATED = gql`
    subscription subscribeToProjectCreated($companyId: ID!) {
        projectCreated(companyId: $companyId) {
            ...projectAttributes
        }
    }
    ${PROJECT_ATTRIBUTES}
`;

export const SUBSCRIBE_TO_PROJECT_UPDATED = gql`
    subscription subscribeToProjectUpdated($companyId: ID!) {
        projectUpdated(companyId: $companyId) {
            ...projectAttributes
        }
    }
    ${PROJECT_ATTRIBUTES}
`;

export const TRACK_PROJECT = gql`
    mutation enableProjects($integrationId: ID!, $accountName: String!, $projectName: String!) {
        trackProject(input: { integrationId: $integrationId, accountName: $accountName, projectName: $projectName }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export const ENABLE_PROJECTS = gql`
    mutation enableProjects($ids: [ID!]!) {
        enableProjects(input: { ids: $ids }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export const DISABLE_PROJECTS = gql`
    mutation disableProjects($ids: [ID!]!) {
        disableProjects(input: { ids: $ids }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export const UPDATE_PROJECT = gql`
    mutation updateProject($id: ID!, $sprintSettings: SprintSettingsInput) {
        updateProject(input: { id: $id, sprintSettings: $sprintSettings }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
