import React, { FunctionComponent, ReactNode, ReactNodeArray } from 'react';
import { WidgetColorRange } from 'components/Dashboarding/Models/Widget';
import ColorBracket from 'routes/reports/GenericDashboard/DashboardWidgetEdit/ConfigurationPanel/ColorBracket';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';

const COLORS_MAX_LENGTH = 3;

interface Props {
    colorRanges: WidgetColorRange[];
    onRangeUpdate: (rangeIndex?: number, range?: WidgetColorRange) => void;
    onAddRange: () => void;
    children?: ReactNode | ReactNodeArray;
}

const GradientColorConfig: FunctionComponent<Props> = ({ colorRanges, onRangeUpdate, onAddRange, children }) => {
    return (
        <>
            {/* Gradient-based coloring */}
            <div>
                {colorRanges?.map((range, index) => {
                    const type =
                        index == 0
                            ? 'lowest'
                            : index == 1 && colorRanges?.length == COLORS_MAX_LENGTH
                            ? 'middle'
                            : 'highest';
                    const isDeletable = index == 1 && colorRanges?.length == COLORS_MAX_LENGTH;
                    return (
                        <ColorBracket
                            key={index}
                            className="mt-2"
                            range={range}
                            type={type}
                            onChange={e => onRangeUpdate(index, e)}
                            isDeletable={isDeletable}
                        />
                    );
                })}
                <div className="d-flex">
                    {colorRanges?.length < COLORS_MAX_LENGTH && (
                        <Button variant="outline-light" onClick={onAddRange} className="mt-2">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            <RichMessage id="dashboarding.widget-editor.gradient-based-color-config.add-bracket" />
                        </Button>
                    )}
                    {children}
                </div>
            </div>
        </>
    );
};

export default GradientColorConfig;
