import React, { FunctionComponent, useState, useCallback } from 'react';
import {
    BillingSubscriptionWithTier,
    BILLING_SUBSCRIPTION_STATUS_OVERDUE,
    BILLING_SUBSCRIPTION_REF_FREE,
    BILLING_SUBSCRIPTION_REF_PAID,
    SwitchBillingSubscriptionPlanResp,
    SWITCH_BILLING_SUBSCRIPTION_PLAN,
    BillingSubscriptionReferences
} from 'api/hq/queries/BillingSubscription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faExclamationCircle, faGift, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';
import { useSafeState } from 'util/useSafeState';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { ErrorMessage, QueryError } from 'components/ErrorManagement';
import moment from 'moment';
import { BillingGuard } from 'components/BillingGuard';

import Moment from 'react-moment';
import Alert from 'components/Design/Alert';
import KeyBenefits from './KeyBenefits';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { SECONDARY_RED } from 'constants/colors';

// The default color used by the upgrade button when not specified
// in the tier config
const DEFAULT_UPGRADE_BTN_VARIANT = 'dark';

interface Props {
    billingSubscription: BillingSubscriptionWithTier;
    onSave: ((planRef: BillingSubscriptionReferences) => Promise<void>) | (() => void);
}

// This component handles the presentation of the current company subscription
const SubscriptionPresenter: FunctionComponent<Props> = ({ billingSubscription, onSave }: Props) => {
    // Extract current subscription
    const subscriptionRef = billingSubscription.subscriptionRef;
    const subscriptionTier = billingSubscription.tier;

    // Get context
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // State
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useSafeState<boolean>(false);
    const [mutationError, setMutationError] = useState<QueryError | undefined>(undefined);

    // Declare update for billing subscription
    const [switchBillingSubscriptionPlan] = useMutation<SwitchBillingSubscriptionPlanResp>(
        SWITCH_BILLING_SUBSCRIPTION_PLAN
    );

    // Check if the subscription is overdue
    const isSubOverdue = billingSubscription?.status === BILLING_SUBSCRIPTION_STATUS_OVERDUE;

    // Extract inferred state
    const isPaidPlan = subscriptionRef != BILLING_SUBSCRIPTION_REF_FREE;
    const isInTrialPeriod = moment(billingSubscription?.freeTrialEndAt) > moment();
    const isFreeTrial = billingSubscription && isPaidPlan && isInTrialPeriod;
    const maxQuantity = subscriptionTier.config.maxQuantity;
    const upgradeBtnVariant = subscriptionTier.config.upgradeBtnVariant || DEFAULT_UPGRADE_BTN_VARIANT;

    // Upgrade conditions/display
    const canManageSub = company?.role === 'ADMIN';

    // Toggle upgrade modal
    const toggleDowngradeModal = (): void => {
        setIsModalOpen(!isModalOpen);
    };

    // Change subscription
    const changeSubscription = useCallback(
        async (planRef: BillingSubscriptionReferences) => {
            setSaveInProgress(true);

            try {
                // Update billing subscription with payment method
                const resp = await switchBillingSubscriptionPlan({
                    variables: { companyId: company?.id, subscriptionRef: planRef }
                });
                const respContent = resp.data?.switchBillingSubscriptionPlan;

                // Abort if subscription could not be updated
                if (!respContent?.success) {
                    const error = respContent?.errors[0] || { code: 'default' };
                    setMutationError(error);
                    setSaveInProgress(false);
                    return;
                }

                // Success
                await onSave(planRef);
                setMutationError(undefined);
            } catch {
                setMutationError({ code: 'default' });
            } finally {
                // Stop loading state
                setSaveInProgress(false);
                setIsModalOpen(false);
            }
        },
        [company?.id, onSave, setSaveInProgress, switchBillingSubscriptionPlan]
    );

    // Render list of subscriptions
    return (
        <>
            {/* Free trial alert */}
            <Alert variant="info" show={isFreeTrial} dismissible>
                <span className="me-1">
                    <RichMessage
                        id={`settings.billing.subscription-presenter.free-trial.${
                            billingSubscription?.billingCard ? 'payments-start' : 'free-trial-ends'
                        }`}
                    />
                </span>
                <Moment fromNow>{billingSubscription?.freeTrialEndAt}</Moment>
            </Alert>

            {/* Subscription overdue alert */}
            <Alert variant="danger" show={isSubOverdue} dismissible>
                <div className="text-danger">
                    <RichMessage id="settings.billing.subscription-presenter.overdue" />
                </div>
            </Alert>

            {/* Update error alert */}
            <Alert variant="danger" show={Boolean(mutationError)} dismissible>
                <div className="mb-4">
                    <ErrorMessage
                        error={{ ...mutationError, namespace: 'update-subscription' }}
                        className="text-danger"
                    />
                </div>
            </Alert>

            {/* Subscription presenters */}
            <div className="py-3">
                <div className="position-relative d-flex py-4">
                    {/* Decoration border */}
                    <div
                        className="position-absolute h-100 w-95 border-2 rounded top-0 end-0"
                        style={{ color: subscriptionTier.config.color }}
                    />

                    {/* Current plan */}
                    <div
                        className={'d-flex flex-column text-white rounded p-5'}
                        style={{ backgroundColor: subscriptionTier.config.color, zIndex: 1 }}
                    >
                        {/* Title */}
                        <h1 className="mb-3">
                            <RichMessage
                                id={`settings.billing.subscription-presenter.title.${subscriptionTier.name}`}
                            />
                        </h1>

                        {/* Price */}
                        <h2 className="text-nowrap mb-2">
                            <RichMessage
                                id={`settings.billing.subscription-presenter.pricing.${subscriptionRef}`}
                                values={{ price: subscriptionTier.price }}
                            />
                        </h2>

                        {/* Project usage display */}
                        <div className="mb-4">
                            {/* No usage allowed */}
                            {billingSubscription.freeQuota == 0 && !isPaidPlan && (
                                <div className="text-nowrap fw-bold mb-1">
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2" />
                                    <RichMessage id="settings.billing.subscription-presenter.no-usage-allowed" />
                                </div>
                            )}

                            {/* Free plan usage */}
                            {!isPaidPlan && billingSubscription.freeQuota > 0 && (
                                <div className="text-nowrap fw-bold mb-1">
                                    <FontAwesomeIcon icon={faGift} className="me-2" />
                                    <RichMessage
                                        id="settings.billing.subscription-presenter.free-usage-ratio"
                                        values={{
                                            units: billingSubscription.pricingQuantity,
                                            maxUnits: billingSubscription.freeQuota
                                        }}
                                    />
                                </div>
                            )}

                            {/* Paid plan usage */}
                            {isPaidPlan && (
                                <div className="text-nowrap fw-bold mb-1">
                                    <FontAwesomeIcon icon={faLayerGroup} className="me-2" />
                                    <RichMessage
                                        id={`settings.billing.subscription-presenter.usage-ratio.${
                                            maxQuantity == undefined ? 'unlimited' : 'with-max'
                                        }`}
                                        values={{
                                            units: billingSubscription.pricingQuantity,
                                            maxUnits: maxQuantity
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Plan Action */}
                        {canManageSub && (
                            <div>
                                {subscriptionRef == BILLING_SUBSCRIPTION_REF_FREE ? (
                                    <BillingGuard actionType="upgrade">
                                        <AsyncButton
                                            track-id="click-subscription-upgrade"
                                            variant={upgradeBtnVariant}
                                            onClick={() => changeSubscription(BILLING_SUBSCRIPTION_REF_PAID[0])}
                                            disabled={saveInProgress}
                                            className="text-white w-100 fw-bold d-flex justify-content-center"
                                            loading={saveInProgress}
                                            messageProps={{ id: 'settings.billing.subscription-presenter.btn-upgrade' }}
                                            loadingMessageProps={{
                                                id: 'settings.billing.subscription-presenter.btn-upgrading'
                                            }}
                                        />
                                    </BillingGuard>
                                ) : (
                                    <AsyncButton
                                        track-id="click-subscription-downgrade-open-modal"
                                        variant="dark"
                                        onClick={toggleDowngradeModal}
                                        className="text-white w-100 fw-bold d-flex justify-content-center"
                                        loading={saveInProgress}
                                        messageProps={{ id: 'settings.billing.subscription-presenter.btn-downgrade' }}
                                        loadingMessageProps={{
                                            id: 'settings.billing.subscription-presenter.btn-downgrading'
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>

                    {/* Summary of benefits */}
                    <div className="key-benefits d-flex py-4 px-5 w-100" style={{ zIndex: 1 }}>
                        <KeyBenefits billingSubscription={billingSubscription} />
                    </div>
                </div>

                {/* Downgrade confirmation modal */}
                <ConfirmationModal
                    isOpen={isModalOpen}
                    icon={faExclamationCircle}
                    iconColor={SECONDARY_RED}
                    title={<RichMessage id="settings.billing.subscription-presenter.downgrade-modal.title" />}
                    content={
                        <RichMessage
                            id={`settings.billing.subscription-presenter.downgrade-modal.body.${
                                billingSubscription.freeQuota > 0 ? 'with-free-quota' : 'no-free-quota'
                            }`}
                            values={{ freeQuota: billingSubscription.freeQuota }}
                        />
                    }
                    customConfirmButton={
                        <AsyncButton
                            track-id="click-subscription-downgrade"
                            variant="danger"
                            className="w-100 justify-content-center"
                            onClick={() => changeSubscription(BILLING_SUBSCRIPTION_REF_FREE)}
                            disabled={saveInProgress}
                            loading={saveInProgress}
                            messageProps={{
                                id: 'settings.billing.subscription-presenter.downgrade-modal.btn-downgrade'
                            }}
                            loadingMessageProps={{
                                id: 'settings.billing.subscription-presenter.downgrade-modal.btn-downgrading'
                            }}
                        />
                    }
                    onCancel={toggleDowngradeModal}
                />
            </div>
        </>
    );
};

export default SubscriptionPresenter;
