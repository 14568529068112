import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Toast as ReactHotToast } from 'react-hot-toast';
import { APP_MANAGEMENT_PROJECTS_PATH } from 'constants/routeBuilders';
import { RichMessage } from 'components/RichMessage';
import { Toast } from 'components/Toast';

const ConnectProjectsToast: FunctionComponent<{ t: ReactHotToast }> = ({ t }) => {
    return (
        <Toast t={t} type="warning">
            <div>
                <RichMessage id="priority-inbox.notification.connect-projects.message" />
            </div>
            <Link to={APP_MANAGEMENT_PROJECTS_PATH}>
                <RichMessage id="priority-inbox.notification.connect-projects.link" />
            </Link>
        </Toast>
    );
};

export default ConnectProjectsToast;
