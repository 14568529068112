import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { uniqBy } from '../../../util/ArrayOperators';
import {
    GetCompanyProjectsResp,
    GET_COMPANY_PROJECTS,
    SUBSCRIBE_TO_PROJECT_UPDATED,
    SUBSCRIBE_TO_PROJECT_CREATED,
    ProjectResp
} from '../queries/Project';
import { useSafeSubscription } from 'api/core/useSafeSubscription';
import { useEffect } from 'react';

interface QueryOpts {
    companyId?: string;
    skip?: boolean;
    statuses?: string[];
}

interface CompanyProjectsQueryResults extends QueryResult<GetCompanyProjectsResp, OperationVariables> {
    normalized: ProjectResp[];
}

export function useCompanyProjects({ companyId, skip, statuses }: QueryOpts): CompanyProjectsQueryResults {
    // Subscribe to create/update events first
    const updSub = useSafeSubscription(SUBSCRIBE_TO_PROJECT_UPDATED, {
        skip: !companyId || skip,
        variables: { companyId: companyId }
    });
    const createSub = useSafeSubscription(SUBSCRIBE_TO_PROJECT_CREATED, {
        skip: !companyId || skip,
        variables: { companyId: companyId },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const newProject = subscriptionData.data?.projectCreated;
            if (!newProject) return;

            // Abort if newProject doesn't match filtering params
            if (statuses && !statuses.includes(newProject.status)) return;

            let previousResult: GetCompanyProjectsResp | null = null;
            try {
                previousResult = client.readQuery<GetCompanyProjectsResp>({
                    query: GET_COMPANY_PROJECTS,
                    variables: { companyId: companyId }
                });
            } catch {}

            const existingProjects = previousResult?.company.projects.nodes || [];
            const pageInfo = previousResult?.company.projects.pageInfo || { endCursor: null };

            // Note: always fetch and specify id on the top parent resource
            // otherwise apollo network caching will fail
            client.writeQuery({
                query: GET_COMPANY_PROJECTS,
                variables: { companyId: companyId, statuses: statuses },
                data: {
                    company: {
                        id: companyId,
                        __typename: 'Company',
                        projects: {
                            __typename: 'ProjectConnection',
                            pageInfo: pageInfo,
                            nodes: [newProject, ...existingProjects].filter(uniqBy('id'))
                        }
                    }
                }
            });
        }
    });

    // Once 'created' event has been subscribed, fetch all projects
    // Ensuring that the 'created' event is subscribed first ensures that
    // we do not miss projects between the time the fetch query is run
    // and the time the subscription is established (concurrency issue)
    const rs = useQuery<GetCompanyProjectsResp>(GET_COMPANY_PROJECTS, {
        skip: !companyId || skip || createSub.loading || updSub.loading,
        variables: { companyId: companyId, statuses: statuses }
    });

    // Load all project data pages
    const endCursor = rs.loading || !rs.data ? null : rs.data.company.projects.pageInfo.endCursor;
    useEffect(() => {
        if (companyId && endCursor) {
            rs.fetchMore({
                variables: {
                    companyId: companyId,
                    statuses: statuses,
                    cursor: endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newNodes = fetchMoreResult ? fetchMoreResult.company.projects.nodes : [];
                    const pageInfo = fetchMoreResult ? fetchMoreResult.company.projects.pageInfo : { endCursor: null };

                    // Note: always fetch and specify id on the top parent resource
                    // otherwise apollo network caching will fail
                    return {
                        company: {
                            id: companyId,
                            __typename: 'Company',
                            projects: {
                                __typename: 'ProjectConnection',
                                pageInfo,
                                nodes: [...previousResult.company.projects.nodes, ...newNodes].filter(uniqBy('id'))
                            }
                        }
                    };
                }
            }).catch(e => {
                // Do not crash on Invariant error, which happens when switching page when a fetchMore
                // is still in progress.
                // See: https://github.com/apollographql/apollo-client/issues/5291
                console.log(e);
            });
        }
    }, [companyId, endCursor, rs, statuses]);

    // Extract normalized data
    const normalized = rs.data?.company.projects.nodes || [];

    // Note: if the endCursor is not null then it means we're still fetching pages of data
    return { ...rs, normalized: normalized, loading: createSub.loading || updSub.loading || rs.loading || !!endCursor };
}
