import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { WidgetChartType, WidgetKpiType, WidgetMetricField } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Nav } from 'react-bootstrap';
import { toastWidgetEditorSuccess } from '..';
import PreviewTable from './ConfigureTable/PreviewTable';
import FilterBoxButton from './FilterBoxButton';
import { useDrilldown } from './useDrilldown';

interface Props {
    widget: WidgetChartType | WidgetKpiType;
    onChange: (widget: WidgetChartType | WidgetKpiType) => void;
    selectedSubMenu: number | undefined;
    onSubMenuSelect: (index: number) => void;
}

const ConfigureDrilldown: FunctionComponent<Props> = ({
    widget,
    onChange,
    selectedSubMenu,
    onSubMenuSelect
}: Props) => {
    // State
    const [localWidget, setLocalWidget] = useState<WidgetChartType | WidgetKpiType>(widget);
    const [metricIndex, setMetricIndex] = useState<number>(selectedSubMenu ?? 0);

    // Get debounced version of onChange
    const debouncedOnChange = useMemo(() => debounce(onChange), [onChange]);

    // Drilldown hook
    const {
        series,
        isDrilldownEnabledForMetric,
        enableDrilldownForMetric,
        disableDrilldownForMetric,
        onDrilldownSeriesChangeForMetric,
        applyRecommendedDrilldownConfiguration
    } = useDrilldown({
        widget: localWidget,
        onComplete: debouncedOnChange
    });

    // Is the drilldown enabled for the current metric
    const isDrilldownEnabled = useMemo(() => isDrilldownEnabledForMetric(metricIndex), [
        isDrilldownEnabledForMetric,
        metricIndex
    ]);

    // Memoized metrics
    const metrics: WidgetMetricField[] = useMemo(() => series?.metrics || [], [series]);

    // Hook invoked when the KPI configuration gets replicated
    const handleApplyRecommendedDrilldownConfiguration = useCallback(() => {
        if (!series || !series.drilldownConfigs?.[metricIndex]?.series) return;

        // Build the new series with the replicated filters
        const updatedSeries = applyRecommendedDrilldownConfiguration(series);

        // Apply changes
        onDrilldownSeriesChangeForMetric(updatedSeries, metricIndex);

        // Toast the filters have been replicated
        toastWidgetEditorSuccess('apply-recommended-drilldown-configuration');
    }, [applyRecommendedDrilldownConfiguration, metricIndex, onDrilldownSeriesChangeForMetric, series]);

    // Re-sync local state
    useEffect(() => setLocalWidget(widget), [widget]);

    // Re-sync sub menu
    useEffect(() => {
        if (selectedSubMenu) {
            setMetricIndex(selectedSubMenu);
        }
    }, [selectedSubMenu]);

    if (!series?.metrics.length) {
        return <RichMessage id="dashboarding.widget-editor.configure-drilldown.no-metrics" />;
    }

    return (
        <div>
            {/* Display tabs if more than one metric */}
            {metrics.length > 1 && (
                <Nav variant="tabs" className="separator-tabs ms-0 mb-3">
                    {metrics.map((e, index) => {
                        return (
                            <Nav.Item key={index}>
                                <Nav.Link
                                    className={classNames({ active: metricIndex === index, 'nav-link': true })}
                                    style={{ width: 'fit-content' }}
                                    onClick={() => {
                                        setMetricIndex(index);
                                        onSubMenuSelect(index);
                                    }}
                                >
                                    <RichMessage
                                        id="dashboarding.widget-editor.configure-drilldown.heading.metric-tab-title"
                                        values={{ index: index + 1 }}
                                    />
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
            )}

            <div className="d-flex justify-content-between align-items-center mb-3">
                {/* Title + description */}
                <div className="flex-grow-1">
                    <h4 className="text-dark mb-1">
                        <span className="elevio-configure-drilldown-title">
                            <RichMessage id="dashboarding.widget-editor.configure-drilldown.heading.title" />
                            {metrics.length > 1 && (
                                <RichMessage
                                    id="dashboarding.widget-editor.configure-drilldown.heading.multi-metrics-title"
                                    values={{ index: metricIndex + 1 }}
                                />
                            )}
                        </span>
                    </h4>
                    <div className="text-grey-4">
                        <RichMessage id="dashboarding.widget-editor.configure-drilldown.heading.description" />
                    </div>
                </div>

                {/* Drilldown switch */}
                <Form.Check
                    className="flex-grow-0 me-4"
                    type="switch"
                    role="button"
                    checked={isDrilldownEnabled}
                    onChange={e =>
                        e.target.checked
                            ? enableDrilldownForMetric(metricIndex)
                            : disableDrilldownForMetric(metricIndex)
                    }
                />

                {/* Apply recommended configuration button */}
                <Button
                    variant="outline-dark"
                    className="me-4"
                    onClick={handleApplyRecommendedDrilldownConfiguration}
                    disabled={!isDrilldownEnabled}
                >
                    <FontAwesomeIcon icon={faMagic} className="me-2" />
                    <RichMessage id="dashboarding.widget-editor.configure-drilldown.apply-recommended-config" />
                </Button>

                {/* Drilldown filters */}
                <FilterBoxButton
                    className="me-4 flex-grow-0"
                    fact={localWidget.config.fact}
                    onChange={e => onDrilldownSeriesChangeForMetric(e, metricIndex)}
                    series={series.drilldownConfigs?.[metricIndex]?.series}
                    variant="outline-dark"
                    drilldownEnabled={isDrilldownEnabled}
                />
            </div>

            {/* Preview table */}
            {isDrilldownEnabled && series.drilldownConfigs?.[metricIndex]?.series && (
                <PreviewTable
                    fact={localWidget.config.fact}
                    configType="LIST"
                    series={series.drilldownConfigs?.[metricIndex]?.series}
                    onChange={e => onDrilldownSeriesChangeForMetric(e, metricIndex)}
                    drilldownEnabled={isDrilldownEnabled}
                />
            )}
        </div>
    );
};

export default ConfigureDrilldown;
