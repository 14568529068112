import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
    switchableSeriesConfigTypes,
    switchWidgetConfigType,
    WidgetConfigType,
    WidgetFactType
} from 'components/Dashboarding/Models/Widget';
import WidgetVisualizationCard from 'components/Dashboarding/WidgetVisualizationCard';
import { useRichIntl } from 'components/RichMessage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { sortBy } from 'lodash';
import { toastWidgetEditorSuccess } from '..';

// Base translation namespace for widget type names
const WIDGET_TYPE_NS = 'dashboarding.widget-editor.widget-type';

interface Props {
    widget: WidgetFactType;
    onChange: (widget: WidgetFactType) => void;
}

const ConfigureVisualization: FunctionComponent<Props> = ({ widget, onChange }: Props) => {
    // Services
    const intl = useRichIntl();

    // Select the list of visualizations
    const availableVisualizations = useMemo(() => {
        return sortBy(switchableSeriesConfigTypes(widget.configType), e =>
            intl.formatMessage({ id: `${WIDGET_TYPE_NS}.${e}` })
        );
    }, [intl, widget.configType]);

    // Hook invoked when the config type gets changed
    // Switch and migrate widget then invoke parent callback
    const switchWidgetType = useCallback(
        (newType: WidgetConfigType) => {
            const formattedWidget = switchWidgetConfigType(widget, newType, {
                toastWidgetEditorSuccess
            });
            onChange(formattedWidget);
        },
        [onChange, widget]
    );

    return (
        <Row>
            {availableVisualizations.map(chartType => (
                <Col key={chartType} xs="6" xl="4" xxl="3" className="mb-4">
                    <WidgetVisualizationCard
                        selected={chartType == widget.configType}
                        onClick={() => switchWidgetType(chartType)}
                        widgetType={chartType}
                        className="h-100"
                    />
                </Col>
            ))}
        </Row>
    );
};

export default ConfigureVisualization;
