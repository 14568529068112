import React, { FunctionComponent, useCallback } from 'react';
import { WIDGET_DEFAULT_SERIES_COLOR } from 'constants/colors';
import classNames from 'classnames';
import { WidgetLineStyleType, WIDGET_LINE_STYLE_TYPES } from './Models/Widget';

const DEFAULT_SERIES_LINE_STYLE = 'solid';

interface Props {
    className?: string;
    color?: string;
    lineStyle?: WidgetLineStyleType;
    onChange: (lineStyle: WidgetLineStyleType) => void;
}

const SeriesLineStylePicker: FunctionComponent<Props> = ({ className, color, lineStyle, onChange }: Props) => {
    // Get values
    const pickedColor = color || WIDGET_DEFAULT_SERIES_COLOR;
    const pickedLineStyle = lineStyle || DEFAULT_SERIES_LINE_STYLE;

    // Cycle through styles on click
    const onColorClick = useCallback(() => {
        const newIndex = (WIDGET_LINE_STYLE_TYPES.indexOf(pickedLineStyle) + 1) % WIDGET_LINE_STYLE_TYPES.length;
        const newStyle = WIDGET_LINE_STYLE_TYPES[newIndex];
        onChange(newStyle);
    }, [onChange, pickedLineStyle]);

    // Render
    return (
        <div
            className={classNames('line-picker-control border rounded', className)}
            onClick={onColorClick}
            role="button"
        >
            <div style={{ borderColor: pickedColor, borderStyle: pickedLineStyle }}></div>
        </div>
    );
};

export default SeriesLineStylePicker;
