import { useCustomFields } from 'api/viz/hooks/useCustomFields';
import { intersection } from 'lodash';
import { useMemo } from 'react';
import { QueryOpFact, QUERY_OP_FIELDS } from '../DataSource';
import { QueryOpField, QueryOpFilterScope, QueryOpUsageScope } from '../DataSource/QueryOperators';

interface UserQueryOpFieldsProps {
    fact: QueryOpFact;
    usageScope?: QueryOpUsageScope | QueryOpUsageScope[];
    filterScope?: QueryOpFilterScope;
}

interface UserQueryOpFieldsResult {
    queryOpFields: QueryOpField[];
    loading: boolean;
}

export function useQueryOpFields({ filterScope, usageScope, fact }: UserQueryOpFieldsProps): UserQueryOpFieldsResult {
    // Fetch custom fields (if any for the current fact)
    const { customFields, loading } = useCustomFields({ fact });

    // Memoize result
    const rs = useMemo(() => {
        // Get fact-specific fields
        const factFields = QUERY_OP_FIELDS[fact];

        // Get usage scopes
        const usageScopes = usageScope ? [usageScope].flat() : undefined;

        // Combine native and custom fields for the provived fact
        // Select fields matching the fact, filter and usage scope.
        const queryOpFields = factFields
            .concat(customFields)
            .filter(e => !filterScope || !e.filterScopes || e.filterScopes.includes(filterScope))
            .filter(e => !usageScopes || !e.usageScopes || intersection(usageScopes, e.usageScopes).length > 0);

        return { loading, queryOpFields };
    }, [fact, filterScope, usageScope, customFields, loading]);

    // Return result
    return rs;
}
