import { ObjectFieldSchema, PrimitiveFieldSchema } from 'api/viz/queries/CustomField';
import {
    childFieldValueChain,
    rulesetFieldFromCustomField,
    useQueryOpFieldLookup
} from 'components/Dashboarding/Hooks/useQueryOpFieldLookup';
import { useQueryOpFields } from 'components/Dashboarding/Hooks/useQueryOpFields';
import {
    QueryOpField,
    QUERY_OP_FIELD_TRAVERSING_SEPARATOR,
    QUERY_OP_FIELD_WILDCARD_SELECTOR
} from 'components/Dashboarding/DataSource/QueryOperators';
import { map, range, sortBy, uniq } from 'lodash';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Field, FieldSelectorProps } from 'react-querybuilder';
import { RulesetBuilderContext } from './RulesetBuilder';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import FieldTypeIcon from 'components/Dashboarding/FieldTypeIcon';
import { RichMessage } from 'components/RichMessage';
import { useNavigableDropdown } from 'components/NavigableDropdown/useNavigableDropdown';
import NavigableDropdown from 'components/NavigableDropdown';

// Number of "at index" options proposed for array filters
const ARRAY_INDEX_OPTIONS_COUNT = 15;

interface RulesetFieldSelectorProps extends FieldSelectorProps {
    noOptionSort?: boolean;
    queryOpFieldOptions?: QueryOpField[];
}

const RulesetFieldSelector: FunctionComponent<RulesetFieldSelectorProps> = ({
    className,
    handleOnChange,
    options,
    title,
    value,
    level,
    noOptionSort,
    path,
    context,
    queryOpFieldOptions
}: RulesetFieldSelectorProps) => {
    // Extract contextual information
    const { fact } = context as RulesetBuilderContext;
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: 'filter' });

    // Selectable options
    const selectableOptions = useMemo(() => {
        const optionNames = (options as Field[]).map(e => e.name);
        return queryOpFieldOptions ? queryOpFieldOptions : queryOpFields.filter(e => optionNames.includes(e.name));
    }, [options, queryOpFieldOptions, queryOpFields]);

    // Selectable options with no section group
    const getSelectableOptionsWithNoSection = useCallback(
        (options: QueryOpField[]): QueryOpField[] => {
            const filteredOptions = options.filter(e => !e.filterSection);
            return noOptionSort ? filteredOptions : sortBy(filteredOptions, ['label']);
        },
        [noOptionSort]
    );

    // Extract sections
    const optGroups = useMemo(() => uniq(selectableOptions.map(e => e.filterSection).filter(e => !!e)).sort(), [
        selectableOptions
    ]);

    // Function to extract options of a given section
    const getSectionOptions = useCallback(
        (section: string | undefined, options: QueryOpField[]): QueryOpField[] => {
            const sectionOptions = options.filter(e => e.filterSection === section);
            return noOptionSort ? sectionOptions : sortBy(sectionOptions, ['label']);
        },
        [noOptionSort]
    );

    // Lookup currently selected field
    const selectedField: QueryOpField | null = useQueryOpFieldLookup({
        fact,
        fieldUri: value,
        fieldList: selectableOptions,
        matchParent: true
    });
    const selectedValue = selectedField?.name || value;
    const isObject = selectedField?.schema?.type === 'OBJECT';

    // Evaluate sub field value. This value is passed to downstream dropdowns to select
    // more specific fields when the selectedField is an object or array of objects.
    const subFieldValue = useMemo(() => {
        const extractedSubFieldValue = childFieldValueChain(selectedField, value);
        return extractedSubFieldValue === '' ? undefined : extractedSubFieldValue;
    }, [selectedField, value]);

    // Check presence of handleOnChange and invoke the callback
    const handleSelectChange = useCallback(
        (e: string | null): void => {
            handleOnChange && handleOnChange(e);
        },
        [handleOnChange]
    );

    // Handle field selection
    const handleSelection = useCallback(
        (name: string | null): void => {
            handleSelectChange(name);
        },
        [handleSelectChange]
    );

    // Handle sub field selection. The chain of fields gets concatenated via pipe.
    const handleSubfieldOnChange = useCallback(
        (subVal: string): void => {
            handleSelectChange([selectedValue, subVal].join(QUERY_OP_FIELD_TRAVERSING_SEPARATOR));
        },
        [selectedValue, handleSelectChange]
    );

    // Force selection of first option if value is not set
    useEffect(() => {
        if (!value && selectableOptions[0]) {
            handleSelectChange(selectableOptions[0].name);
        }
    }, [value, handleSelectChange, selectableOptions]);

    // use dropdown
    const {
        getDropdownProps,
        getDropdownMenuProps,
        getDropdownItemProps,
        options: selectableOptionsResult
    } = useNavigableDropdown<QueryOpField>({
        onSelect: handleSelection,
        options: selectableOptions,
        mapper: {
            value: 'name',
            label: 'label'
        }
    });

    return (
        <>
            <Dropdown {...getDropdownProps} onSelect={handleSelection}>
                <Dropdown.Toggle
                    as="div"
                    role="button"
                    className={classNames(className, 'd-flex justify-content-between align-items-center h-100')}
                    title={title}
                >
                    {selectedField ? (
                        <div className="d-flex">
                            <FieldTypeIcon field={selectedField} className="me-2" />
                            <span className="text-dark text-uppercase fw-bold">{selectedField.label}</span>
                        </div>
                    ) : (
                        <em>
                            <RichMessage id="dashboarding.widget-field-selector.empty-label" />
                        </em>
                    )}
                </Dropdown.Toggle>

                {/* List of fields */}
                <NavigableDropdown.Menu
                    className="max-vh-30 overflow-y-auto"
                    // Fixed strategy is required to avoid issues with container overflow
                    popperConfig={{ strategy: 'fixed' }}
                    // Fixed strategy is bugged. Need renderOnMount to work properly
                    // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                    renderOnMount
                    {...getDropdownMenuProps}
                >
                    {getSelectableOptionsWithNoSection(selectableOptionsResult).map(f => {
                        return (
                            <NavigableDropdown.Item
                                {...getDropdownItemProps}
                                key={f.id}
                                value={f.name}
                                eventKey={f.name}
                                className="d-flex"
                            >
                                <FieldTypeIcon field={f} className="me-2" />
                                <span
                                    className={classNames('text-dark text-uppercase', {
                                        'fw-bold': f.id == selectedField?.id
                                    })}
                                >
                                    {f.label}
                                </span>
                            </NavigableDropdown.Item>
                        );
                    })}
                    {optGroups.map(section => {
                        return (
                            <Fragment key={section}>
                                <Dropdown.Divider />
                                <Dropdown.Header>{section}</Dropdown.Header>
                                {getSectionOptions(section, selectableOptionsResult).map(option => {
                                    return (
                                        <NavigableDropdown.Item
                                            className={classNames('text-dark text-uppercase', {
                                                'fw-bold': option.id == selectedField?.id
                                            })}
                                            key={`key-${option.name}`}
                                            value={option.name}
                                            eventKey={option.name}
                                            {...getDropdownItemProps}
                                        >
                                            {option.label}
                                        </NavigableDropdown.Item>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                    <Dropdown.Divider />
                </NavigableDropdown.Menu>
            </Dropdown>

            {/* 
                If the type of the field is an object (or array of objects)
                then display additional dropdowns forcing the user
                to be more specific about the sub-field to filter on
            */}
            {isObject && selectedField.schema && (
                <RulesetSubFieldSelector
                    parentSchema={selectedField.schema}
                    handleOnChange={handleSubfieldOnChange}
                    className={className}
                    level={level}
                    value={subFieldValue}
                    path={path}
                    context={context}
                />
            )}
        </>
    );
};

interface RulesetSubFieldSelectorOpts {
    parentSchema: PrimitiveFieldSchema | ObjectFieldSchema;
    handleOnChange: (e: string) => void;
    className?: string;
    level: number;
    value?: string;
    path: number[];
    context: RulesetBuilderContext;
}

// The purpose of this component is to pre-format options for RulesetFieldSelector
// in the context of a nested field
const RulesetSubFieldSelector: FunctionComponent<RulesetSubFieldSelectorOpts> = ({
    parentSchema,
    handleOnChange,
    className,
    level,
    value,
    path,
    context
}: RulesetSubFieldSelectorOpts) => {
    // Skip sorting for array sub-field - we directly order the options (see below)
    const noOptionSort = !parentSchema.array;

    // Build the options array based on the parent schema
    const queryOpFieldOptions: QueryOpField[] = useMemo(() => {
        if (parentSchema.array) {
            // Force user to select:
            // - all values in the array => only array filtering operators will be
            //  available such as includes / not includes
            // - a value at a specific index => type-specific operator (date, string) become available
            const wildcardOption: QueryOpField = {
                id: QUERY_OP_FIELD_WILDCARD_SELECTOR,
                name: QUERY_OP_FIELD_WILDCARD_SELECTOR,
                label: 'Any',
                type: 'string',
                usageScopes: ['filter'],
                schema: { ...(parentSchema as PrimitiveFieldSchema), array: false }
            };
            const indexOptions: QueryOpField[] = range(ARRAY_INDEX_OPTIONS_COUNT).map(e => {
                return {
                    id: e.toString(),
                    name: e.toString(),
                    label: `at index: ${e}`,
                    type: 'string',
                    usageScopes: ['filter'],
                    schema: { ...(parentSchema as PrimitiveFieldSchema), array: false }
                };
            });

            // Return wilcard (*) and "at index" options
            return [wildcardOption].concat(indexOptions);
        } else {
            // Generate options for the drop down based on the fields available
            // in the schema
            return map(parentSchema.schema as ObjectFieldSchema, (schema, name) => {
                return rulesetFieldFromCustomField(name, schema);
            });
        }
    }, [parentSchema]);

    return (
        <RulesetFieldSelector
            options={[]}
            queryOpFieldOptions={queryOpFieldOptions}
            noOptionSort={noOptionSort}
            handleOnChange={handleOnChange}
            className={className}
            level={level}
            value={value}
            path={path}
            context={context}
        />
    );
};

export default RulesetFieldSelector;
