import LibraryCard from 'components/LibraryCard/LibraryCard';
import LibraryCardHeader, { HeaderConfig } from 'components/LibraryCard/LibraryCardHeader';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent } from 'react';
import { Card, Button } from 'react-bootstrap';

interface BuilderLibraryCardProps {
    headerConfig: HeaderConfig;
    titleId: string;
    descriptionId: string;
    mainActionMessageId: string;
    onSelect: () => void;
}

const BuilderLibraryCard: FunctionComponent<BuilderLibraryCardProps> = ({
    headerConfig,
    titleId,
    descriptionId,
    mainActionMessageId,
    onSelect
}: BuilderLibraryCardProps) => {
    return (
        <LibraryCard>
            {/* Header */}
            <LibraryCardHeader config={headerConfig} />

            {/* Body */}
            <>
                {/* Title */}
                <Card.Title className="d-flex align-items-center mw-100">
                    <RichMessage id={titleId} />
                </Card.Title>

                {/* Description */}
                <Card.Text className="text-dark">
                    <RichMessage id={descriptionId} />
                </Card.Text>
            </>

            {/* Main action button */}
            <Button variant="dark" onClick={onSelect} className="rounded-0 rounded-bottom justify-content-center w-100">
                <RichMessage id={mainActionMessageId} />
            </Button>
        </LibraryCard>
    );
};

export default BuilderLibraryCard;
