import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResourceRoles } from 'api/hq/hooks/useResourceRoles';
import DuplicateDashboardModal from 'components/Dashboarding/Duplicating/DuplicateDashboardModal';
import { ShareDashboardModal } from 'components/Dashboarding/Sharing/ShareDashboardModal';
import DashboardActions from 'components/LibraryCard/DashboardLibraryCard/DashboardActions';
import { useRichIntl } from 'components/RichMessage';
import { PRIMARY_LIGHT_BLUE } from 'constants/colors';
import { dashboardViewPath } from 'constants/routeBuilders';
import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';
import { kpDashboard, kpPin, kpShared } from 'util/customIcons';
import { DashboardItem } from '../Collections';

// Default icon background color
const DEFAULT_BG_COLOR = PRIMARY_LIGHT_BLUE;

// Default icon size
const DEFAULT_SIZE = 40;

interface Props {
    dashboard: DashboardItem;
    selected?: boolean;
    onSelectChange: (dashboard: DashboardItem, selected: boolean) => void;
    onPinChange: () => void;
}

const DashboardRowItem: FunctionComponent<Props> = ({ dashboard, selected, onSelectChange, onPinChange }) => {
    // State
    const [isShareDashboardModalOpen, setIsShareDashboardModalOpen] = useState<boolean>(false);
    const [isDuplicateDashboardModalOpen, setIsDuplicateDashboardModalOpen] = useState<boolean>(false);

    // Refs
    const [iconRef, { width: iconWidth }] = useMeasure<HTMLDivElement>();
    const [pinnedIconRef, { width: pinnedIconWidth }] = useMeasure<HTMLDivElement>();
    const [sharedIconRef, { width: sharedIconWidth }] = useMeasure<HTMLDivElement>();

    // Services
    const intl = useRichIntl();

    // Load the roles
    const { isShared, loading: loadingRoles } = useResourceRoles({
        resourceId: dashboard.id,
        resourceType: 'DASHBOARD'
    });

    const bgColor = useMemo(() => dashboard.icon?.color || DEFAULT_BG_COLOR, [dashboard.icon?.color]);
    const textColor = useMemo(() => textColorFromBg(bgColor, shadeHexColor(bgColor, 1), shadeHexColor(bgColor, -1)), [
        bgColor
    ]);

    // Hook invoked when a project is selected
    const handleSelectedChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            onSelectChange(dashboard, e.target.checked);
        },
        [onSelectChange, dashboard]
    );

    return (
        <>
            <tr>
                {/* Select/deselect dashboard */}
                <td className="text-center checkbox">
                    <Form.Check className="fs-xs" type="checkbox" checked={selected} onChange={handleSelectedChange} />
                </td>

                {/* Icon + name + pinned icon + shared icon */}
                <td>
                    <div className="d-flex align-items-center">
                        {/* Icon */}
                        <div
                            ref={iconRef}
                            className="flex-grow-0 rounded d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: bgColor,
                                color: textColor,
                                minHeight: DEFAULT_SIZE,
                                minWidth: DEFAULT_SIZE
                            }}
                            title={intl.formatMessage({ id: 'collections.dashboard.title' })}
                        >
                            <FontAwesomeIcon icon={kpDashboard} />
                        </div>

                        <div
                            className="flex-grow-1 d-flex align-items-center pe-4"
                            style={{ maxWidth: `calc(100% - ${iconWidth}px)` }}
                        >
                            {/* Name */}
                            <Button
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                as={Link as any}
                                variant="link"
                                to={dashboardViewPath({ dashboardId: dashboard.id })}
                                className="text-dark fw-semibold p-0 m-2"
                                style={{ maxWidth: `calc(100% - ${pinnedIconWidth + sharedIconWidth}px)` }}
                            >
                                <span className="text-truncate">{dashboard.name}</span>
                            </Button>

                            {/* Pinned */}
                            <div className="me-2" ref={pinnedIconRef}>
                                {dashboard.pinned && <FontAwesomeIcon icon={kpPin} size="sm" className="me-2" />}
                            </div>

                            {/* Shared */}
                            <div ref={sharedIconRef} className="me-2">
                                {loadingRoles ? (
                                    <Spinner animation="border" size="sm" />
                                ) : isShared ? (
                                    <FontAwesomeIcon icon={kpShared} size="sm" />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </td>

                {/* Owner */}
                <td className="text-center d-lg-table-cell d-none">
                    <span>{dashboard.user?.firstName}</span>
                </td>

                {/* Last modified */}
                <td className="text-center d-lg-table-cell d-none">
                    <Moment format="ll">{dashboard.updatedAt}</Moment>
                </td>

                {/* More button */}
                <td>
                    <DashboardActions variant="dropdown" dashboard={dashboard} onPinChange={onPinChange} />
                </td>
            </tr>

            {/* Share Dashboard Modal */}
            {isShareDashboardModalOpen && (
                <ShareDashboardModal
                    isOpen={isShareDashboardModalOpen}
                    onHide={() => setIsShareDashboardModalOpen(false)}
                    dashboardId={dashboard.id}
                />
            )}

            {/* Duplicate Dashboard Modal */}
            {isDuplicateDashboardModalOpen && (
                <DuplicateDashboardModal
                    isOpen={isDuplicateDashboardModalOpen}
                    onHide={() => setIsDuplicateDashboardModalOpen(false)}
                    dashboard={dashboard}
                />
            )}
        </>
    );
};

export default DashboardRowItem;
