import gql from 'graphql-tag';
import { ItemUser } from './User';

export interface ItemReview {
    _system_id: string;
    author: ItemUser;
    body: string;
    pull_request_system_id: string;
    state: string;
    submitted_at: string;
    updated_at: string;
    url: string;
}

export interface GetItemReviewsResp {
    reviews: ItemReview[];
}

// The last 100 reviews are fetched (100 is the default limit)
export const GET_ITEM_REVIEWS = gql`
    query($parentSysIds: [String!]) {
        report(
            queries: [
                {
                    target: "reviews"
                    fact: REVIEWS
                    dimensions: [
                        { field: "_system_id" }
                        { field: "author" }
                        { field: "body" }
                        { field: "pull_request_system_id" }
                        { field: "state" }
                        { field: "submitted_at", sort: DESC }
                        { field: "updated_at" }
                        { field: "url" }
                    ]
                    filters: [{ function: IN, args: [{ field: "pull_request_system_id" }, { stringL: $parentSysIds }] }]
                }
            ]
        ) {
            id
            results
            errors {
                message
            }
        }
    }
`;
