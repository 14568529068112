/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Editor, { Monaco, useMonaco } from '@monaco-editor/react';
import { editor as MonacoEditor, IRange, languages as MonacoLanguages, Position } from 'monaco-editor';
import {
    LG_FORMULA_DIMENSION_FN_CONFIGS,
    LG_FORMULA_METRIC_FN_CONFIGS,
    QueryOpUsageScope
} from '../DataSource/QueryOperators';
import { QueryOpFact } from '../DataSource';
import { useQueryOpFields } from '../Hooks/useQueryOpFields';
import { useRichIntl } from 'components/RichMessage';
import { ReportFunction } from 'api/viz/hooks/useGenericReport';
import { FormulaValidationState } from './useFormulaValidator';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { useKey } from 'react-use';

// Language definition
const LG_NS = 'formula';

// Theme definition
const TH_NAME = 'formulaTheme';
const TH_CONFIG: MonacoEditor.IStandaloneThemeData = {
    base: 'vs',
    inherit: false,
    rules: [
        { token: '', foreground: '000000', background: 'fffffe' },
        { token: 'invalid', foreground: 'cd3131' },
        { token: 'keyword', foreground: '0000FF' },
        { token: 'number', foreground: '098658' },
        { token: 'string', foreground: 'a31515' },
        { token: 'table-field', foreground: 'C700C7' }
    ],
    colors: {
        'editor.foreground': '#000000'
    }
};

// Translation namespaces
const FN_DOC_NS = 'dashboarding.formula-editor.help.functions';
const FIELD_DOC_NS = 'dashboarding.formula-editor.help.fields';

// Styling
const DEFAULT_EDITOR_HEIGHT = 24;

// The editor options are designed to make the editor fit on one line
// and mimic an input field.
const EDITOR_OPTIONS: MonacoEditor.IStandaloneEditorConstructionOptions = {
    contextmenu: false,
    folding: false,
    find: { addExtraSpaceOnTop: false, autoFindInSelection: 'never', seedSearchStringFromSelection: 'never' },
    fontSize: 14,
    glyphMargin: false,
    hideCursorInOverviewRuler: true,
    lineDecorationsWidth: 0,
    lineHeight: DEFAULT_EDITOR_HEIGHT,
    lineNumbers: 'off',
    lineNumbersMinChars: 0,
    minimap: { enabled: false },
    renderLineHighlight: 'none',
    overviewRulerLanes: 0,
    overviewRulerBorder: false,
    scrollBeyondLastColumn: 0,
    scrollbar: { horizontal: 'hidden', vertical: 'hidden' },
    suggest: {
        snippetsPreventQuickSuggestions: false
    },
    wordWrap: 'off'
};

/**
 * Fix positioning of the suggestion details/documentation container
 *
 * Several issues are raised about this problem, the main one being:
 * https://github.com/microsoft/monaco-editor/issues/2503
 *
 * With fixedOverflowWidgets = false it happens that the suggestion details
 * is offset to the bottom right, appearing detached from the suggestion list.
 *
 * This issue comes from a difference of perception between the CSS position
 * (left/right attributes) and the Viewport position (getBoundingClientRect).
 * The observer below will calculate the differential observed between the two
 * and correct the position based on the calculated difference.
 *
 * @param editor The Monaco editor instance
 */
const fixSuggestionDetailsPlacement = (editor: MonacoEditor.IStandaloneCodeEditor): void => {
    // Get suggest controller
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const suggestController = editor.getContribution('editor.contrib.suggestController') as any;

    // Get the suggestion list/details references
    const suggestListEl = suggestController?.widget?.value?.element?.domNode;
    const suggestDetails = suggestController?.widget?.value?._details;
    const suggestDetailsEl = suggestDetails?._resizable?.domNode;

    // This callback is applied whenever the state (e.g. visibility) of the
    // suggestion details container is updated
    const callback: MutationCallback = () => {
        if (!suggestDetails._topLeft) return;

        // Calculate differential between the perceived position and the set position
        // and abort if there are no differences
        const suggestDetailsElRect = suggestDetailsEl.getBoundingClientRect();
        const diffX = suggestDetailsElRect.left - suggestDetails._topLeft.left;
        const diffY = suggestDetailsElRect.top - suggestDetails._topLeft.top;
        if (diffX == 0 && diffY == 0) return;

        // Place the suggestions details at the top right end of the
        // suggestion list
        const suggestListElRect = suggestListEl.getBoundingClientRect();
        suggestDetails._applyTopLeft({
            left: suggestListElRect.right - diffX,
            top: suggestListElRect.top - diffY
        });
    };

    // Observe the suggestion details element for changes in appearance
    // and apply the corrective callbacks on element changes.
    const observer = new MutationObserver(callback);
    observer.observe(suggestDetailsEl, { attributes: true });

    // Stop observing when the editor gets destroyed
    editor.onDidDispose(() => {
        observer.disconnect();
    });
};

export interface FormulaState extends FormulaValidationState {
    formula?: string;
    parsedFormula?: ReportFunction;
}

interface Props {
    fact: QueryOpFact;
    onBlur?: () => void;
    onChange?: (e?: string) => void;
    onCommit?: (e?: string) => void;
    onFocus?: () => void;
    usageScope: QueryOpUsageScope;
    value?: string;
    height?: number;
    lineDecorationsWidth?: number;
    wordWrap?: 'off' | 'on' | 'wordWrapColumn' | 'bounded';
    fixedOverflowWidgets: boolean;
    onEnterKeyPressed?: () => void;
}

// Wrapper around Monaco Editor (VS code engine) to handle formula
// using a single line editor
export const FormulaEditor: FunctionComponent<Props> = ({
    fact,
    onBlur,
    onChange,
    onCommit,
    onFocus,
    usageScope,
    value,
    height,
    lineDecorationsWidth,
    wordWrap,
    fixedOverflowWidgets,
    onEnterKeyPressed
}: Props) => {
    // Local state
    const [localValue, setLocalValue] = useState<string | undefined>(value);
    const [isResyncLocked, setIsResyncLocked] = useState<boolean>(false);

    // Get services
    const intl = useRichIntl();
    const monaco = useMonaco();

    // Get all relevant fields
    // We allow all metric and dimension fields to be used in formulas
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: ['metric', 'dimension'] });

    // Get language definitions
    const lgName = [LG_NS, fact, usageScope].join('.');
    const lgFields = useMemo(() => queryOpFields.map(e => e.id), [queryOpFields]);
    const lgFnConfigs = useMemo(() => {
        // Return all allowed functions for current language mode
        if (usageScope == 'metric') {
            return LG_FORMULA_METRIC_FN_CONFIGS;
        } else {
            return LG_FORMULA_DIMENSION_FN_CONFIGS;
        }
    }, [usageScope]);

    // Get debounced version of onChange so as to not trigger successive updates
    // while the user is typing.
    const debouncedOnCommit = useMemo(() => onCommit && debounce(onCommit, DEFAULT_DEBOUNCE_TIME), [onCommit]);

    // Handle editor configuration
    const handleEditorInitialization = useCallback(
        (editor: MonacoEditor.IStandaloneCodeEditor, monaco: Monaco): void => {
            // Get suggest controller
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const suggestController = editor.getContribution('editor.contrib.suggestController') as any;

            // Open the suggestion documentation by default
            suggestController.widget?.value?._setDetailsVisible(true);

            // Fix placement of the suggestion documentation box
            if (!fixedOverflowWidgets) fixSuggestionDetailsPlacement(editor);

            // disable `Find` widget
            // see: https://github.com/microsoft/monaco-editor/issues/287#issuecomment-328371787
            editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyF, () => {});
            editor.addCommand(monaco.KeyCode.F3, () => {});

            // Disable "show command" palette and replace it with suggestions instead
            editor.addCommand(monaco.KeyCode.F1, () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (suggestController?.model?.state == 1) {
                    // Close suggestions
                    editor.trigger('editor', 'hideSuggestWidget', undefined);
                } else {
                    // Open suggestions
                    editor.trigger('editor', 'editor.action.triggerSuggest', undefined);
                }
            });

            // Disable `Enter` in the context of a newline
            editor.onKeyDown(e => {
                if (e.keyCode == monaco.KeyCode.Enter) {
                    // We only prevent enter when the suggest model is not active
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if ((editor.getContribution('editor.contrib.suggestController') as any)?.model?.state == 0) {
                        e.preventDefault();
                    }
                }
            });

            // Lock the resyncs on focus
            editor.onDidFocusEditorWidget(() => {
                // Prevent resyncs from parent
                setIsResyncLocked(true);

                // Propagate event
                onFocus && onFocus();
            });

            // Unlock the resyncs on blur
            editor.onDidBlurEditorWidget(() => {
                // Progagate event
                onBlur && onBlur();

                // Cancel the next debounce iteration and commit immediately
                // The debounce cancellation ensures we do not end up with
                // two successive identical updates
                debouncedOnCommit?.cancel();
                onCommit && value != localValue && onCommit(localValue);

                // Allow resyncs from parent
                setIsResyncLocked(false);
            });
        },
        [debouncedOnCommit, fixedOverflowWidgets, localValue, onBlur, onCommit, onFocus, value]
    );

    // Handle value change
    const handleValueChange = useCallback(
        (e?: string): void => {
            // Update local value state
            setLocalValue(e);

            // Propagate value to parent
            onChange && onChange(e);

            // Auto-commit regularly
            debouncedOnCommit && debouncedOnCommit(e);
        },
        [debouncedOnCommit, onChange]
    );

    // Resync local state when parent gets updated
    useEffect(() => {
        !isResyncLocked && setLocalValue(value);
    }, [localValue, isResyncLocked, value]);

    // Track when the Enter key is pressed
    useKey('Enter', onEnterKeyPressed);

    // Handle Monaco configuration
    useEffect(() => {
        // Monaco is not loaded yet
        if (!monaco) return;

        // Abort if the language is already registered
        if (monaco.languages.getEncodedLanguageId(lgName) > 0) return;

        // Register a new language
        // See: https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-custom-languages
        monaco.languages.register({ id: lgName });

        // Register a tokens provider for the language
        // See: https://microsoft.github.io/monaco-editor/monarch.html
        monaco.languages.setMonarchTokensProvider(lgName, {
            ignoreCase: true,
            defaultToken: 'invalid',
            keywords: (lgFnConfigs.map(e => e.id) as string[]).concat(['and', 'or']),
            tableColumns: lgFields,
            operators: ['+', '-', '*', '/', '&&', '||', '~', '!~', '>', '<', '>=', '<=', '==', '!='],
            digits: /\d+(_+\d+)*/,
            symbols: /[=><!~?:&|+\-*/^%]+/,
            escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
            tokenizer: {
                root: [
                    // function and table fields
                    [
                        /[a-z_$][\w$]*/,
                        {
                            cases: {
                                '@tableColumns': 'table-field',
                                '@keywords': 'keyword',
                                '@default': 'identifier'
                            }
                        }
                    ],

                    // whitespace
                    { include: '@whitespace' },

                    // Delimiters and operators
                    [/[{}()[\]]/, '@brackets'],
                    [
                        /@symbols/,
                        {
                            cases: {
                                '@operators': 'delimiter',
                                '@default': ''
                            }
                        }
                    ],

                    // Numbers
                    [/(@digits)\.(@digits)/, 'number.float'],
                    [/(@digits)/, 'number'],

                    // delimiter: after number because of .\d floats
                    [/[,.]/, 'delimiter'],

                    // strings
                    [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
                    [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-teminated string
                    [/"/, 'string', '@string_double'],
                    [/'/, 'string', '@string_single']
                ],
                whitespace: [[/[ \t\r\n]+/, '']],
                string_double: [
                    [/[^\\"]+/, 'string'],
                    [/@escapes/, 'string.escape'],
                    [/\\./, 'string.escape.invalid'],
                    [/"/, 'string', '@pop']
                ],

                string_single: [
                    [/[^\\']+/, 'string'],
                    [/@escapes/, 'string.escape'],
                    [/\\./, 'string.escape.invalid'],
                    [/'/, 'string', '@pop']
                ]
            }
        });

        // Register a completion item provider for the new language
        monaco.languages.registerCompletionItemProvider(lgName, {
            provideCompletionItems: (model: MonacoEditor.ITextModel, position: Position) => {
                const word = model.getWordUntilPosition(position);
                const range: IRange = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };

                // Function suggestions
                const fnSuggestions: MonacoLanguages.CompletionItem[] = lgFnConfigs.map(e => {
                    const insertArgs = e.argHints.map((e, i) => `\${${i + 1}:${e}}`).join(', ');
                    const baseDocLink = `${FN_DOC_NS}.${e.id}`;

                    return {
                        label: e.id,
                        kind: monaco.languages.CompletionItemKind.Method,
                        insertText: `${e.id}(${insertArgs})`,
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        range: range,
                        detail: intl.safeFormatMessage({ id: `${baseDocLink}.header` }) as string,
                        documentation: {
                            value: intl.safeFormatMessage({ id: `${baseDocLink}.documentation` }) as string
                        }
                    };
                });

                // Column suggestions
                const fieldSuggestions: MonacoLanguages.CompletionItem[] = lgFields.map(e => {
                    const baseDocLink = `${FIELD_DOC_NS}.${fact}.${e}`;

                    return {
                        label: e,
                        kind: monaco.languages.CompletionItemKind.Field,
                        insertText: e,
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        range: range,
                        detail: intl.safeFormatMessage({ id: `${baseDocLink}.header` }) as string,
                        documentation: {
                            value: intl.safeFormatMessage({ id: `${baseDocLink}.documentation` }) as string
                        }
                    };
                });

                return { suggestions: fnSuggestions.concat(fieldSuggestions) };
            }
        });

        // Load theme
        monaco.editor.defineTheme(TH_NAME, TH_CONFIG);
    }, [fact, intl, lgFields, lgFnConfigs, lgName, monaco]);

    return (
        <Editor
            height={`${height ?? DEFAULT_EDITOR_HEIGHT}px`}
            value={localValue}
            onChange={handleValueChange}
            language={lgName}
            theme={TH_NAME}
            options={{
                ...EDITOR_OPTIONS,
                wordWrap: wordWrap,
                lineDecorationsWidth: lineDecorationsWidth,
                fixedOverflowWidgets: fixedOverflowWidgets
            }}
            onMount={handleEditorInitialization}
        />
    );
};
