import { useCompanyProjects } from 'api/hq/hooks/useCompanyProjects';
import classNames from 'classnames';
import { ConfigConditionalOption, ConfigQnProjectsSelect } from 'components/Dashboarding/Models/ConfigQns';
import { DropdownMultiselect } from 'components/DropdownMultiselect';
import { DropdownOption } from 'components/List/FilteringDropdown';
import {
    PROJECT_ACTIVE_STATUS,
    PROJECT_DISCONNECTED_STATUS,
    PROJECT_IMPORTING_STATUS,
    PROJECT_PENDING_STATUS
} from 'constants/defaultValues';
import { sortBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { Label } from '../Label';

interface OptionProps {
    label: string;
    selected: boolean;
    onClick: () => void;
}

const Option: FunctionComponent<OptionProps> = ({ label, selected, onClick }) => {
    return (
        <div
            className={classNames(
                'd-flex flex-column justify-content-center cursor-pointer h-100 p-4 mx-auto text-center border fw-500',
                {
                    'border-primary bg-primary-light text-primary': selected,
                    'bg-transparent text-dark': !selected
                }
            )}
            style={{ maxWidth: '325px' }}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

interface Props {
    config: ConfigQnProjectsSelect;
    onChange: (e: ConfigQnProjectsSelect) => void;
}

const ProjectsSelect: FunctionComponent<Props> = ({ config, onChange }) => {
    // State
    const [selectedOption, setSelectedOption] = useState<ConfigConditionalOption>();
    const [selectedProjects, setSelectedProjects] = useState<DropdownOption[]>([]);

    // Global state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Get projects
    const { data: projectsData, loading: loading } = useCompanyProjects({
        skip: selectedOption?.id != 'some',
        companyId: company?.id,
        statuses: [PROJECT_ACTIVE_STATUS, PROJECT_DISCONNECTED_STATUS, PROJECT_IMPORTING_STATUS, PROJECT_PENDING_STATUS]
    });

    // Sorted projects list
    const projectList = useMemo(
        () =>
            sortBy(projectsData?.company.projects.nodes || [], 'name').map(e => ({
                id: e.id,
                name: e.name
            })),
        [projectsData?.company.projects.nodes]
    );

    // Hook invoked when an option is clicked
    const handleOptionClick = useCallback(
        (option: ConfigConditionalOption) => {
            setSelectedOption(option);

            switch (option.id) {
                case 'all':
                    onChange({ ...config, result: { selectedOption: option, selectedProjects: [] } });
                    break;
                case 'some':
                    onChange({ ...config, result: { selectedOption: option, selectedProjects } });
                    break;
            }
        },
        [onChange, config, selectedProjects]
    );

    // Hook invoked when projects selection changes
    const handleOnChange = useCallback(
        (selectedItems: DropdownOption[]) => {
            if (!selectedOption) return;

            setSelectedProjects(selectedItems);
            onChange({ ...config, result: { selectedOption, selectedProjects: selectedItems } });
        },
        [config, onChange, selectedOption]
    );

    // Resync selected option from parent update
    useEffect(() => setSelectedOption(config.result?.selectedOption), [config.result?.selectedOption]);

    // Resync selected projects from parent update
    useEffect(() => setSelectedProjects(config.result?.selectedProjects ?? []), [config.result?.selectedProjects]);

    return (
        <Form.Group>
            <Label config={config} />
            <Row xs={1} sm={2}>
                {config.options?.map(e => (
                    <Col key={e.id} className="mb-4 px-2">
                        <Option
                            label={e.label}
                            selected={selectedOption?.id == e?.id}
                            onClick={() => handleOptionClick(e)}
                        />
                    </Col>
                ))}
            </Row>
            {selectedOption?.id == 'some' && (
                <>
                    <Form.Label>{config.subLabel}</Form.Label>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" size="sm" />
                        </div>
                    ) : (
                        <DropdownMultiselect
                            placeholder={config.subPlaceholder}
                            options={projectList}
                            selected={selectedProjects}
                            onChange={handleOnChange}
                            searchBar={true}
                        />
                    )}
                </>
            )}
        </Form.Group>
    );
};

export default ProjectsSelect;
