//============================================
// Theme
//============================================

import { WidgetColorRange } from 'components/Dashboarding/Models/Widget';

// Primary colors
export const PRIMARY_DARK_BLUE = '#030545';
export const PRIMARY_TWILIGHT_BLUE = '#40416B';
export const PRIMARY_LIGHT_TURQUOISE = '#87e9f9';
export const PRIMARY_LIGHT_BLUE = '#53b5f9';
export const PRIMARY_NORMAL_BLUE = '#3981f7';
export const PRIMARY_LIGHT_PURPLE = '#a39ff8';
export const PRIMARY_NORMAL_PURPLE = '#684fe0';

// Secondary colors
export const SECONDARY_LIGHT_LIGHT_PURPLE = '#d8d7fe';
export const SECONDARY_PINK = '#e13373';
export const SECONDARY_ORANGE = '#ed7637';
export const SECONDARY_GREEN = '#c3d152';
export const SECONDARY_GREY_BLUE = '#35778b';
export const SECONDARY_DARK_RED = '#af2749';
export const SECONDARY_RED = '#e1334c';
export const SECONDARY_YELLOW = '#f1cf1d';
export const SECONDARY_ANIS = '#62d4a3';

// Greys
export const NEUTRAL_BLACK = '#0b0b0b';
export const NEUTRAL_GREY_1 = '#686868';
export const NEUTRAL_GREY_2 = '#6a6a6a';
export const NEUTRAL_GREY_3 = '#999999';
export const NEUTRAL_GREY_4 = '#ababab';
export const NEUTRAL_GREY_5 = '#c3c3c3';
export const NEUTRAL_GREY_6 = '#c1c1c1';
export const NEUTRAL_GREY_7 = '#D1D6DE';
export const NEUTRAL_GREY_10 = '#eee';
export const NEUTRAL_GREY_11 = '#f7f8f9';

// Typography
export const FONT_FAMILY_BASE = 'Inter, sans-serif';

//============================================
// Widget palette
//============================================
export const WIDGET_COLOR_PALETTE = [
    PRIMARY_NORMAL_BLUE,
    PRIMARY_NORMAL_PURPLE,
    SECONDARY_LIGHT_LIGHT_PURPLE,
    SECONDARY_PINK,
    SECONDARY_ORANGE,
    SECONDARY_GREEN,
    SECONDARY_GREY_BLUE,
    SECONDARY_DARK_RED,
    SECONDARY_RED,
    SECONDARY_YELLOW,
    SECONDARY_ANIS,
    PRIMARY_DARK_BLUE,
    PRIMARY_TWILIGHT_BLUE,
    PRIMARY_LIGHT_BLUE,
    PRIMARY_LIGHT_PURPLE
];
export const WIDGET_DEFAULT_SERIES_COLOR = WIDGET_COLOR_PALETTE[0];

//============================================
// Widget color ranges
//============================================
export const WIDGET_GRADIENT_COLORS: [WidgetColorRange, WidgetColorRange] = [[PRIMARY_LIGHT_BLUE], [PRIMARY_DARK_BLUE]];
export const WIDGET_THRESHOLD_COLORS: [WidgetColorRange, WidgetColorRange] = [
    [PRIMARY_LIGHT_BLUE],
    [PRIMARY_DARK_BLUE, 1]
];
