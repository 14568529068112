import React, { FunctionComponent, useMemo, useState } from 'react';
import MDEditor, { commands, ICommand, MDEditorProps } from '@uiw/react-md-editor';
import { Button } from 'react-bootstrap';
import { RichMessage } from 'components/RichMessage';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { useMeasure } from 'react-use';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import { DEFAULT_AUTOCOMMIT_TIME } from 'constants/defaultValues';
import { Root, RootContent } from 'hast';

// Height of the markdown toolbar
const TOOLBAR_HEIGHT = 40; //px

// Allow data-elevio-article attribute on span elements so
// as to be able to link Elev.io articles directly from the Assistant
const REHYPE_SANITIZE_SCHEMA = {
    ...defaultSchema,
    attributes: {
        ...defaultSchema.attributes,
        span: [...(defaultSchema.attributes?.span || []), 'dataElevioArticle']
    }
};

// Link rewrite logic. Open all links in a new tab.
const rehypeOpenLinkInNewTab = (node: Root | RootContent): void => {
    if (node.type != 'element' || node.tagName != 'a') return;
    node.properties = { ...node.properties, target: '_blank', rel: 'noopener noreferrer' };
};

interface Props extends MDEditorProps {
    onCancel?: (value: string | undefined) => void;
    onCommit?: (value: string | undefined) => void;
    onSave?: (value: string | undefined) => void;
    previewClass?: string;
}

// The list of text edition commands
// Displayed at the bottom left
const DEFAULT_COMMANDS = [
    commands.bold,
    commands.italic,
    commands.strikethrough,
    commands.hr,
    commands.title,
    commands.divider,
    commands.link,
    commands.quote,
    commands.code,
    commands.codeBlock,
    commands.image,
    commands.divider,
    { ...commands.unorderedListCommand, shortcuts: 'ctrlcmd+shift+8' },
    { ...commands.orderedListCommand, shortcuts: 'ctrlcmd+shift+7' },
    { ...commands.checkedListCommand, shortcuts: 'ctrlcmd+shift+.' }
];

// The list of control commands
// Displayed at the bottom right
const DEFAULT_EXTRA_COMMANDS = [commands.codeEdit, commands.codeLive, commands.codePreview];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, react/prop-types
const MarkdownEditor: FunctionComponent<Props> = ({
    onSave,
    onCancel,
    onFocus,
    onBlur,
    onChange,
    onCommit,
    previewClass,
    value,
    ...props
}) => {
    // State
    const [localValue, setLocalValue] = useState<string | undefined>(value);
    const [isResyncLocked, setIsResyncLocked] = useState<boolean>(false);

    // Get debounced version of onCommit
    const debouncedOnCommit = useMemo(() => onCommit && debounce(onCommit, DEFAULT_AUTOCOMMIT_TIME), [onCommit]);

    // Hook invoked when the input content changes
    const handleOnChange = useCallback(
        (val?: string): void => {
            setLocalValue(val);
            onChange && onChange(val);

            // Auto-commit regularly
            debouncedOnCommit && debouncedOnCommit(val);
        },
        [debouncedOnCommit, onChange]
    );

    // Hook invoked when the input gets focus
    const handleOnFocus = useCallback(
        (event: React.FocusEvent<HTMLDivElement>): void => {
            // Prevent resyncs from parent
            setIsResyncLocked(true);

            // Propagate event
            onFocus && onFocus(event);
        },
        [onFocus]
    );

    // Hook invoked when the input loses focus
    const handleOnBlur = useCallback(
        (event: React.FocusEvent<HTMLDivElement>): void => {
            // Progagate event
            onBlur && onBlur(event);

            // Cancel the next debounce iteration and commit immediately
            // The debounce cancellation ensures we do not end up with
            // two successive identical updates
            debouncedOnCommit?.cancel();
            onCommit && value != localValue && onCommit(localValue);

            // Allow resyncs from parent
            setIsResyncLocked(false);
        },
        [debouncedOnCommit, localValue, onBlur, onCommit, value]
    );

    // Define save/cancel commands
    const saveCommands = useMemo(
        () => [
            commands.divider,
            commands.group([], {
                icon: <span />,
                children: ({ textApi }) => {
                    return (
                        <div className="d-flex">
                            {onCancel && (
                                <Button variant="link" size="sm" onClick={() => onCancel(textApi?.textArea.value)}>
                                    <RichMessage id="dashboards.widget.markdown.cancel" />
                                </Button>
                            )}

                            {onSave && (
                                <Button
                                    variant="outline-dark"
                                    size="sm"
                                    onClick={() => onSave(textApi?.textArea.value)}
                                >
                                    <RichMessage id="dashboards.widget.markdown.save" />
                                </Button>
                            )}
                        </div>
                    );
                }
            })
        ],
        [onCancel, onSave]
    );

    // Cancel/save commands
    const extraCommands: ICommand[] = useMemo(
        () => [...DEFAULT_EXTRA_COMMANDS, ...(onSave || onCancel ? saveCommands : [])],
        [onCancel, onSave, saveCommands]
    );

    const [mdWrapperRef, { height: mdWrapperHeight }] = useMeasure<HTMLDivElement>();
    const isEditMode = useMemo<boolean>(() => props.preview === 'edit', [props.preview]);

    // Calculate editor height based on current mode
    const mdEditorHeight = useMemo(() => mdWrapperHeight + (isEditMode ? 0 : TOOLBAR_HEIGHT), [
        isEditMode,
        mdWrapperHeight
    ]);

    // Resync local value from attribute when the input is unlocked
    useEffect(() => {
        !isResyncLocked && setLocalValue(value);
    }, [isResyncLocked, value]);

    return (
        <div data-color-mode="light" className="h-100 pe-2" ref={mdWrapperRef}>
            <MDEditor
                height={mdEditorHeight}
                visibleDragbar={false}
                commands={DEFAULT_COMMANDS}
                toolbarHeight={TOOLBAR_HEIGHT}
                hideToolbar={!isEditMode}
                prefixCls="w-md-editor"
                extraCommands={extraCommands}
                previewOptions={{
                    rehypePlugins: [[rehypeSanitize, REHYPE_SANITIZE_SCHEMA]],
                    rehypeRewrite: rehypeOpenLinkInNewTab,
                    className: previewClass
                }}
                value={localValue}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                {...props}
            />
        </div>
    );
};

export default MarkdownEditor;
