import { useMutation } from '@apollo/react-hooks';
import { useCompany } from 'api/hq/hooks/useCompany';
import { UpdateCompanyResp, UPDATE_COMPANY } from 'api/hq/queries/Company';
import { ErrorFragment } from 'components/ErrorManagement';
import { RichMessage } from 'components/RichMessage';
import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useState } from 'react';
import { toastQueryError } from 'components/Toast';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CompanyState } from 'redux/auth/reducer';
import { AsyncButton } from 'components/AsyncButton';

interface Props {
    company: CompanyState | null;
}

const TeamSettings: FunctionComponent<Props> = ({ company }: Props) => {
    // State
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>('');

    // Fetch/subscribe to company
    const { normalized: companyData, refetch, loading, error } = useCompany({
        companyId: company?.id,
        onCompleted: data => setCompanyName(data?.company?.name || '')
    });

    // Declare update mutation
    const [updateCompany] = useMutation<UpdateCompanyResp>(UPDATE_COMPANY);

    // Infer state
    const canEdit = company?.role === 'ADMIN';
    const isSaveEnabled = companyName && companyName.length > 0 && companyData && companyName !== companyData.name;

    // Form hook
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => setCompanyName(event.target.value);

    // Is cancel shown?
    const isEdited = companyName !== companyData?.name;

    // Cancel function
    const handleCancel = (): void => setCompanyName(companyData?.name || '');

    // Save function
    const handleTeamSave = useCallback(
        async (e: FormEvent) => {
            e.preventDefault(); // avoid page reload
            setUpdateInProgress(true);

            try {
                // Update remote rule
                const resp = await updateCompany({
                    variables: {
                        id: companyData?.id,
                        name: companyName
                    }
                });
                const updateResp = resp.data?.updateCompany;

                if (updateResp?.success) {
                    await refetch();
                    setUpdateInProgress(false);
                } else {
                    toastQueryError({ ...updateResp?.errors[0], namespace: 'update-team' });
                }
            } catch {
                setUpdateInProgress(false);
            }
        },
        [companyData?.id, companyName, refetch, updateCompany]
    );

    // Loading screen
    if (loading || !company) {
        return (
            <div className="text-center">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    // Handle fetch errors
    if (error) {
        return <ErrorFragment error={{ code: 'fetch-team', namespace: 'team-management' }} />;
    }

    return (
        <>
            {/* Non-admin warning */}
            {!canEdit && (
                <div className="text-muted mb-4">
                    <RichMessage id="settings.team-management.team-settings.non-admin" />
                </div>
            )}

            <Form className="mb-4" onSubmit={handleTeamSave}>
                <Form.Group controlId="formTeamName">
                    <Form.Control value={companyName} onChange={handleNameChange} disabled={!canEdit} />
                </Form.Group>
            </Form>

            {canEdit && (
                <div className="d-flex justify-content-end">
                    {/* Cancel */}
                    {isEdited && (
                        <Button variant="link" onClick={handleCancel} disabled={updateInProgress} className="me-2">
                            <RichMessage id="settings.team-management.team-settings.action.cancel" />
                        </Button>
                    )}

                    {/* Save */}
                    {isEdited && (
                        <AsyncButton
                            variant="dark"
                            onClick={handleTeamSave}
                            disabled={updateInProgress || !isSaveEnabled}
                            loading={updateInProgress}
                            messageProps={{ id: 'settings.team-management.team-settings.action.save' }}
                            loadingMessageProps={{ id: 'settings.team-management.team-settings.action.saving' }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default TeamSettings;
