import { ItemComment } from 'api/viz/queries/Comment';
import { InboxItem, InboxItemRelated, FeedItem } from 'api/viz/queries/InboxItem';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ItemReview } from '../queries/Review';

export const useFeedGenerator = (): ((
    item: InboxItem | InboxItemRelated,
    comments: ItemComment[],
    reviews: ItemReview[]
) => FeedItem[]) => {
    const intl = useIntl();

    // Use the original body or generate a message for empty reviews
    const reviewBody = useCallback(
        (review: ItemReview): string => {
            if (review.body) return review.body;

            // Generate default message
            const msgId = `priority-inbox.reviews.default-body.${review.state}`;
            if (!intl.messages[msgId]) return '';

            return intl.formatMessage(
                { id: `priority-inbox.reviews.default-body.${review.state}` },
                { reviewer: review.author.username }
            );
        },
        [intl]
    );

    // Generate a list of feed items from an Inbox Item
    const generateFeed = useCallback(
        (item: InboxItem | InboxItemRelated, comments: ItemComment[], reviews: ItemReview[]): FeedItem[] => {
            // Convert item to feed item
            const mainFeedItem: FeedItem = {
                _system_id: item._system_id,
                author: item.author,
                body: item.body,
                parent: item,
                state: 'CREATED',
                submitted_at: item.created_at,
                updated_at: item.updated_at,
                type: 'description',
                url: item.url
            };

            // Create feed by combining the main item, comments and reviews.
            return [mainFeedItem]
                .concat(
                    comments.map(e => ({
                        ...e,
                        submitted_at: e.created_at,
                        parent: item,
                        type: 'comment'
                    })) as FeedItem[]
                )
                .concat(reviews.map(e => ({ ...e, body: reviewBody(e), parent: item, type: 'review' })) as FeedItem[])
                .filter(e => e.body);
        },
        [reviewBody]
    );

    return generateFeed;
};
