import React, { FunctionComponent } from 'react';

import { faHandPaper } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { ReduxState } from 'redux/reducers';
import { RichMessage } from 'components/RichMessage';
import { MEMBERSHIP_ENABLED_STATUS } from 'constants/defaultValues';
import { LOGIN_USER_SUCCESS } from 'constants/actionTypes';
import { Navigate, useParams } from 'react-router-dom';
import { DEFAULT_START_PATH } from 'constants/routeBuilders';

// Page displayed when a user attempts to access a disabled company
const CompanyDisabled: FunctionComponent = () => {
    // Params
    const { id: companyId } = useParams() as { id: string };

    // Redux event dispatcher
    const dispatch = useDispatch();

    // Get state
    const company = useSelector((e: ReduxState) => e.authUser.company);
    const apiUser = useSelector((e: ReduxState) => e.authUser.apiUser);
    const enabledMemberships =
        apiUser?.user.memberships.nodes.filter(e => e.status === MEMBERSHIP_ENABLED_STATUS) || [];
    const enabledCompanyIds = enabledMemberships.map(e => e.company.id);

    // Redirect to root path if user has switched to a different company
    if (companyId !== company?.id || enabledCompanyIds.includes(companyId)) {
        return <Navigate to={DEFAULT_START_PATH} />;
    }

    // Switch user to selected company
    const switchCompany = (companyId: string): void => {
        dispatch({ type: LOGIN_USER_SUCCESS, payload: { ...apiUser, currentCompanyId: companyId } });
    };

    return (
        <div className="container">
            <div className="d-flex align-items-center vh-100 w-75 mx-auto my-auto">
                <Card className="flex-fill pt-2 pb-5">
                    <Card.Header className="text-center">
                        <h1 className="display-3 text-danger">
                            <FontAwesomeIcon icon={faHandPaper} />
                        </h1>
                        <h2>
                            <RichMessage id="flows.company-disabled.title" values={{ companyName: company?.name }} />
                        </h2>
                    </Card.Header>
                    <Card.Body>
                        <p className="fs-5">
                            <RichMessage
                                id="flows.company-disabled.explanation"
                                values={{ companyName: company?.name }}
                            />
                        </p>

                        {enabledMemberships.length > 0 && (
                            <div>
                                <p className="fs-5">
                                    <RichMessage id="flows.company-disabled.team-selector" />
                                </p>
                                <ul className="fs-5">
                                    {enabledMemberships.map(e => (
                                        <li key={e.company.id}>
                                            <Button
                                                variant="link"
                                                className="p-0 fs-5"
                                                onClick={() => switchCompany(e.company.id)}
                                            >
                                                {e.company.name}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default CompanyDisabled;
