import React, { FunctionComponent } from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';

const AddRuleAction: FunctionComponent<ActionWithRulesProps> = ({ className, title, handleOnClick }) => {
    return (
        <button role="button" type="button" className={className} title={title} onClick={e => handleOnClick(e)}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            <RichMessage id="components.ruleset-builder.add-rule-button.label" />
        </button>
    );
};

export default AddRuleAction;
