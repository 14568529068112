import { Membership } from 'api/hq/queries/CompanyMembership';
import { ResourceRoleName } from 'api/hq/queries/ResourceRole';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';
import { ALLOWED_ROLES, RemovedRoleType, REMOVED_ROLE } from './ShareDashboardModal';

interface Props {
    membership: Membership;
    roleName?: ResourceRoleName | RemovedRoleType;
    onRoleUpdated: (role: ResourceRoleName | undefined) => void;
    disabled: boolean;
}

const UpdateResourceRole: FunctionComponent<Props> = ({ membership, roleName, onRoleUpdated, disabled }: Props) => {
    return (
        <>
            {roleName && (
                <tr className="align-middle">
                    {/* User item content */}
                    <td
                        className={classNames('fw-bold text-truncate py-4', {
                            'text-decoration-line-through': roleName == REMOVED_ROLE
                        })}
                    >
                        <Gravatar
                            email={membership.user?.email}
                            size={40}
                            default="wavatar"
                            className="rounded-circle me-3"
                        />
                        {membership.user.firstName}
                    </td>
                    <td
                        className={classNames('text-truncate', {
                            'text-decoration-line-through': roleName == REMOVED_ROLE
                        })}
                    >
                        {membership.user.email}
                    </td>

                    {/* Item actions */}
                    <td>
                        <Dropdown onSelect={(eventKey: string | null) => onRoleUpdated(eventKey as ResourceRoleName)}>
                            <Dropdown.Toggle
                                variant="outline-dark"
                                role="button"
                                className={classNames(
                                    'd-flex justify-content-between align-items-center h-100 ms-auto',
                                    { 'text-danger': roleName == REMOVED_ROLE }
                                )}
                                disabled={roleName == 'OWNER' || disabled}
                                style={{ width: '110px' }}
                            >
                                <FormattedMessage id={`dashboarding.share-dashboard-modal.roles.${roleName}`} />
                                &nbsp;
                            </Dropdown.Toggle>

                            {/* List of allowed roles */}
                            <Dropdown.Menu
                                className="max-vh-30 overflow-y-auto"
                                // Fixed strategy is required to avoid issues with container overflow
                                popperConfig={{ strategy: 'fixed' }}
                                // Fixed strategy is bugged. Need renderOnMount to work properly
                                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                                renderOnMount
                            >
                                {ALLOWED_ROLES.map(option => {
                                    return (
                                        <Dropdown.Item key={option} eventKey={option}>
                                            <FormattedMessage
                                                id={`dashboarding.share-dashboard-modal.roles.${option}`}
                                            />
                                        </Dropdown.Item>
                                    );
                                })}
                                <Dropdown.Item eventKey={REMOVED_ROLE}>
                                    <span className="text-danger">
                                        <FormattedMessage
                                            id={`dashboarding.share-dashboard-modal.roles.${REMOVED_ROLE}`}
                                        />
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            )}
        </>
    );
};

export default UpdateResourceRole;
