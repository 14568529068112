import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faToggleOff, faFont, faSquareRootAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { QueryOpField, QueryOpFieldType } from './DataSource/QueryOperators';

// Field type to icon mapping
const TYPE_ICON_MAPPING: { [key in QueryOpFieldType]: IconDefinition } = {
    bool: faToggleOff,
    string: faFont,
    float: faSquareRootAlt,
    int: faSquareRootAlt,
    ts: faCalendarAlt,
    label: faFont
};

interface Props {
    className?: string;
    field?: QueryOpField;
    size?: number;
}

const FieldTypeIcon: FunctionComponent<Props> = ({ className, field, size = 20 }: Props) => {
    const typeIcon = field && (field.icon ? field.icon : TYPE_ICON_MAPPING[field?.type]);

    // Abort if no icon
    if (!typeIcon) return null;

    // Render icon
    return (
        <div
            className={classNames('d-flex justify-content-center align-items-center bg-grey-11 text-grey-4', className)}
            style={{ width: size, height: size }}
        >
            <FontAwesomeIcon icon={typeIcon} />
        </div>
    );
};

export default FieldTypeIcon;
