import React, { FunctionComponent } from 'react';
import {
    ConfigQn,
    ConfigQnDbNaming,
    ConfigQnInput,
    ConfigQnMultiSelectInput,
    ConfigQnProjectsSelect,
    ConfigQnSingleSelect
} from 'components/Dashboarding/Models/ConfigQns';
import { DashboardNaming, Input, MultiSelectInput, SingleSelect } from 'components/Questions/Forms';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichMessage } from 'components/RichMessage';
import { Button } from 'react-bootstrap';
import ProjectsSelect from './Forms/ProjectsSelect';

interface Props {
    questions: Map<string, ConfigQn> | undefined;
    updateQuestion: (e: ConfigQn) => void;
    resetQuestions: () => void;
}

export const Questions: FunctionComponent<Props> = ({ questions, updateQuestion, resetQuestions }: Props) => {
    if (!questions) return null;

    return (
        <>
            {/* Question list */}
            {Array.from(questions.keys()).map(key => (
                <div key={key} className="mb-5">
                    {questions.get(key)?.type == 'DASHBOARD_NAMING' ? (
                        <DashboardNaming config={questions.get(key) as ConfigQnDbNaming} onChange={updateQuestion} />
                    ) : questions.get(key)?.type == 'WIDGET_NAMING' ||
                      questions.get(key)?.type == 'DASHBOARD_DESCRIPTION' ||
                      questions.get(key)?.type == 'INPUT' ? (
                        <Input config={questions.get(key) as ConfigQnInput} onChange={updateQuestion} />
                    ) : questions.get(key)?.type == 'MULTISELECT_INPUT' ? (
                        <MultiSelectInput
                            config={questions.get(key) as ConfigQnMultiSelectInput}
                            onChange={updateQuestion}
                        />
                    ) : questions.get(key)?.type == 'SINGLESELECT' ? (
                        <SingleSelect config={questions.get(key) as ConfigQnSingleSelect} onChange={updateQuestion} />
                    ) : questions.get(key)?.type == 'PROJECTS_SELECT' ? (
                        <ProjectsSelect
                            config={questions.get(key) as ConfigQnProjectsSelect}
                            onChange={updateQuestion}
                        />
                    ) : null}
                </div>
            ))}

            {/* Reset button */}
            <div className="d-flex justify-content-end" style={{ marginTop: '-1.5rem' }}>
                <Button variant="link" className="text-grey-1 p-0" onClick={resetQuestions}>
                    <FontAwesomeIcon icon={faUndo} className="me-2" />
                    <RichMessage id="dashboarding.widget-assistant.configure.reset" />
                </Button>
            </div>
        </>
    );
};
