import React, { Fragment, FunctionComponent } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from 'components/Menu/Sidebar';

import TopNav from 'components/Menu/TopNav';
import classNames from 'classnames';
import { SIDEBAR_EXPANDED_BREAKPOINT } from 'constants/defaultValues';
import { useLocalStorage } from 'react-use';
import { DASHBOARD_ROOT_PATH } from 'constants/routeBuilders';

// Layout with sidebar (desktop) or top nav (mobile)
const LayoutWithNav: FunctionComponent = () => {
    // Services
    const location = useLocation();

    // State
    const [isMenuExpanded, setIsMenuExpanded] = useLocalStorage(
        'sidebarExpanded',
        window.innerWidth >= SIDEBAR_EXPANDED_BREAKPOINT
    );

    // Evaluate background color
    const isGreyBg = location.pathname.startsWith(DASHBOARD_ROOT_PATH);

    return (
        <Fragment>
            {/* Mobile Nav */}
            <TopNav className="d-sm-none" />

            {/* Desktop Nav */}
            <Sidebar
                className="d-none d-sm-block"
                expanded={isMenuExpanded}
                toggleExpand={() => setIsMenuExpanded(!isMenuExpanded)}
            />

            {/* Content */}
            <main className={classNames({ 'menu-expanded': isMenuExpanded, 'bg-grey-11': isGreyBg })}>
                {/* Flow down routing components */}
                <Outlet />
            </main>
        </Fragment>
    );
};

export default LayoutWithNav;
