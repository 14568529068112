import React, { FunctionComponent } from 'react';
import { ReactComponent as AreachartSvg } from 'assets/img/widgets/areachart.svg';
import { ReactComponent as BarchartSvg } from 'assets/img/widgets/barchart.svg';
import { ReactComponent as ColumnchartSvg } from 'assets/img/widgets/columnchart.svg';
import { ReactComponent as HeatmapchartSvg } from 'assets/img/widgets/heatmapchart.svg';
import { ReactComponent as LargeCardSvg } from 'assets/img/widgets/largecard.svg';
import { ReactComponent as LinechartSvg } from 'assets/img/widgets/linechart.svg';
import { ReactComponent as ListSvg } from 'assets/img/widgets/list.svg';
import { ReactComponent as MiniCardSvg } from 'assets/img/widgets/minicard.svg';
import { ReactComponent as NumberkpiSvg } from 'assets/img/widgets/numberkpi.svg';
import { ReactComponent as PiechartSvg } from 'assets/img/widgets/piechart.svg';
import { ReactComponent as SparkcolumnkpiSvg } from 'assets/img/widgets/sparkcolumnkpi.svg';
import { ReactComponent as SparklinekpiSvg } from 'assets/img/widgets/sparklinekpi.svg';
import { ReactComponent as TrendkpiSvg } from 'assets/img/widgets/trendkpi.svg';
import { WidgetConfigType } from './Models/Widget';
import Card from 'react-bootstrap/Card';
import { RichMessage } from 'components/RichMessage';
import classNames from 'classnames';

interface Props {
    className?: string;
    onClick?: () => void;
    selected?: boolean;
    widgetType: WidgetConfigType;
}

const WidgetVisualizationCard: FunctionComponent<Props> = ({ className, onClick, selected, widgetType }: Props) => {
    return (
        <Card
            onClick={onClick}
            role={onClick ? 'button' : undefined}
            className={classNames('p-4', { 'border-primary': selected }, className)}
        >
            {/* Card image */}
            <Card.Img variant="top" as="div" className="text-center mb-4">
                {widgetType == 'AREACHART' ? (
                    <AreachartSvg width="100%" />
                ) : widgetType == 'BARCHART' ? (
                    <BarchartSvg width="100%" />
                ) : widgetType == 'COLUMNCHART' ? (
                    <ColumnchartSvg width="100%" />
                ) : widgetType == 'HEATMAPCHART' ? (
                    <HeatmapchartSvg width="100%" />
                ) : widgetType == 'LARGECARD' ? (
                    <LargeCardSvg width="100%" />
                ) : widgetType == 'LINECHART' ? (
                    <LinechartSvg width="100%" />
                ) : widgetType == 'LIST' ? (
                    <ListSvg width="100%" />
                ) : widgetType == 'MINICARD' ? (
                    <MiniCardSvg width="100%" />
                ) : widgetType == 'NUMBERKPI' ? (
                    <NumberkpiSvg width="100%" />
                ) : widgetType == 'PIECHART' ? (
                    <PiechartSvg width="100%" />
                ) : widgetType == 'SPARKCOLUMNKPI' ? (
                    <SparkcolumnkpiSvg width="100%" />
                ) : widgetType == 'SPARKLINEKPI' ? (
                    <SparklinekpiSvg width="100%" />
                ) : widgetType == 'TRENDKPI' ? (
                    <TrendkpiSvg width="100%" />
                ) : null}
            </Card.Img>

            {/* Title */}
            <Card.Title>
                <RichMessage id={`dashboarding.widget-editor.widget-type.${widgetType}`} />
            </Card.Title>

            {/* Description */}
            <Card.Text className="text-grey-4">
                <RichMessage id={`dashboarding.widget-editor.widget-description.${widgetType}`} />
            </Card.Text>
        </Card>
    );
};

export default WidgetVisualizationCard;
