import {
    faFile,
    faAddressBook,
    faBalanceScale,
    faBriefcase,
    faBug,
    faBusinessTime,
    faCalendarAlt,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faCode,
    faCodeBranch,
    faColumns,
    faCompass,
    faCut,
    faEnvelope,
    faEnvelopeOpen,
    faFolder,
    faGlobe,
    faHourglass,
    faIndustry,
    faLandmark,
    faProjectDiagram,
    faSitemap,
    faStickyNote,
    faStopwatch,
    faStream,
    faTable,
    faTag,
    faTags,
    faTasks,
    faWallet,
    faUsers,
    faUser,
    faClipboard,
    faPhoneAlt,
    faBullseye,
    faArchive,
    faTerminal,
    faDatabase,
    faDollarSign,
    faGlasses,
    faFilter,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

// List of available icons
export const SUPPORTED_PICKER_ICONS = [
    'address-book',
    'business-time',
    'briefcase',
    'balance-scale',
    'bug',
    'calendar-alt',
    'chart-area',
    'chart-bar',
    'chart-line',
    'chart-pie',
    'code',
    'code-branch',
    'columns',
    'compass',
    'cut',
    'envelope',
    'envelope-open',
    'file',
    'folder',
    'globe',
    'hourglass',
    'industry',
    'landmark',
    'project-diagram',
    'sitemap',
    'sticky-note',
    'stopwatch',
    'stream',
    'table',
    'tag',
    'tags',
    'tasks',
    'wallet',
    'users',
    'user',
    'clipboard',
    'phone-alt',
    'bullseye',
    'archive',
    'terminal',
    'database',
    'dollar-sign',
    'glasses',
    'filter'
] as const;

export type SupportedPickerIconType = typeof SUPPORTED_PICKER_ICONS[number];
export const DEFAULT_PICKER_ICON: SupportedPickerIconType = 'chart-pie';

// Name to definition mapping
const ICON_MAPPING: { [key in SupportedPickerIconType]: IconDefinition } = {
    'address-book': faAddressBook,
    'business-time': faBusinessTime,
    briefcase: faBriefcase,
    'balance-scale': faBalanceScale,
    bug: faBug,
    'calendar-alt': faCalendarAlt,
    'chart-area': faChartArea,
    'chart-bar': faChartBar,
    'chart-line': faChartLine,
    'chart-pie': faChartPie,
    code: faCode,
    'code-branch': faCodeBranch,
    columns: faColumns,
    compass: faCompass,
    cut: faCut,
    envelope: faEnvelope,
    'envelope-open': faEnvelopeOpen,
    file: faFile,
    folder: faFolder,
    glasses: faGlasses,
    globe: faGlobe,
    hourglass: faHourglass,
    industry: faIndustry,
    landmark: faLandmark,
    'project-diagram': faProjectDiagram,
    sitemap: faSitemap,
    'sticky-note': faStickyNote,
    stopwatch: faStopwatch,
    stream: faStream,
    table: faTable,
    tag: faTag,
    tags: faTags,
    tasks: faTasks,
    wallet: faWallet,
    users: faUsers,
    user: faUser,
    clipboard: faClipboard,
    'phone-alt': faPhoneAlt,
    bullseye: faBullseye,
    archive: faArchive,
    terminal: faTerminal,
    database: faDatabase,
    'dollar-sign': faDollarSign,
    filter: faFilter
};

interface Props {
    icon?: SupportedPickerIconType;
    size?: number;
    color?: string;
    className?: string;
}

const IconRenderer: FunctionComponent<Props> = ({ icon, size, color, className }: Props) => {
    const iconDef = icon ? ICON_MAPPING[icon] : ICON_MAPPING[DEFAULT_PICKER_ICON];

    return <FontAwesomeIcon className={className} icon={iconDef} style={{ height: size, width: size }} color={color} />;
};

export default IconRenderer;
