import { COMMENTS_QUERY_OP_FIELDS } from './Comments';
import { COMMITS_QUERY_OP_FIELDS } from './Commits';
import { INBOX_ITEMS_QUERY_OP_FIELDS } from './InboxItems';
import { QueryOpField } from './QueryOperators';
import { REVIEWS_QUERY_OP_FIELDS } from './Reviews';

// Fact definition
export const QUERY_OP_FACTS = ['COMMITS', 'COMMENTS', 'INBOX_ITEMS', 'REVIEWS'] as const;
export type QueryOpFact = typeof QUERY_OP_FACTS[number];

// The default fact to use with widgets
export const DEFAULT_QUERY_OP_FACT: QueryOpFact = 'INBOX_ITEMS';

// Fields per fact
export const QUERY_OP_FIELDS: { [key in QueryOpFact]: QueryOpField[] } = {
    COMMITS: COMMITS_QUERY_OP_FIELDS,
    COMMENTS: COMMENTS_QUERY_OP_FIELDS,
    INBOX_ITEMS: INBOX_ITEMS_QUERY_OP_FIELDS,
    REVIEWS: REVIEWS_QUERY_OP_FIELDS
};
