import {
    WidgetFactTemplate,
    WIDGET_BLANK_TEMPLATES,
    WIDGET_TEMPLATES
} from 'components/Dashboarding/Models/WidgetTemplates';
import { RichMessage } from 'components/RichMessage';
import { sortBy, uniq, uniqBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import SearchBar from 'components/List/SearchBar';
import classNames from 'classnames';
import { useWidgets } from 'api/hq/hooks/useWidgets';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { kpNewChart } from 'util/customIcons';
import { FormattedMessage } from 'react-intl';
import { useDashboard } from 'api/hq/hooks/useDashboard';
import { Button, Spinner } from 'react-bootstrap';
import { WidgetTemplateLibraryCard } from 'components/LibraryCard/WidgetTemplateLibraryCard';
import { WidgetLibraryCard } from 'components/LibraryCard/WidgetLibraryCard';
import WidgetBlankTemplateButton from './WidgetBlankTemplateButton';
import WidgetInstanceButton from './WidgetInstanceButton';
import { LIBRARY_CARD_MARGIN } from 'components/LibraryCard';
import { useMeasure } from 'react-use';
import { computeCardsContainerMaxWidth } from 'components/LibraryCard/LibraryCardUtils';

// Combine the regular and blank templates
const TEMPLATES: WidgetFactTemplate[] = sortBy(uniqBy(WIDGET_TEMPLATES, 'id'), 'id');
const BLANK_TEMPLATES: WidgetFactTemplate[] = Object.values(WIDGET_BLANK_TEMPLATES);

// Gather all categories mentioned in Templates
const CATEGORIES: string[] = uniq(TEMPLATES.flatMap(t => t.categories));

// Cards dimensions
const WIDGET_CARD_WIDTH = 315;
const BLANK_TEMPLATE_CARD_WIDTH = 435;

interface Props {
    className?: string;
    dashboardId: string;
    onWidgetAdded: () => Promise<void>;
    onTemplateSelected: (widget: WidgetFactTemplate) => void;
}

interface Category {
    icon: IconDefinition;
    subCategories: { [key: string]: WidgetFactTemplate[] | WidgetFactType[] };
}

const SUB_CATEGORY_ALL = 'all';

// Group widget templates by category
const groupWidgetTemplatesByCategory = (widgets: WidgetFactTemplate[]): { [key: string]: WidgetFactTemplate[] } => {
    return widgets.reduce(
        (group: { [key: string]: WidgetFactTemplate[] }, widget: WidgetFactTemplate) => {
            const categories = widget.categories;

            group[SUB_CATEGORY_ALL] = group[SUB_CATEGORY_ALL] ?? [];
            group[SUB_CATEGORY_ALL].push(widget);

            categories.forEach(c => {
                group[c] = group[c] ?? [];
                group[c].push(widget);
            });

            return group;
        },
        // Format the CATEGORIES array of string to object. e.g. { blank: [] }
        CATEGORIES.reduce((acc, value) => ({ ...acc, [value]: [] }), { all: [] })
    );
};

// Filter widgets by search term
const filterWidgets = (
    widgets: (WidgetFactType & { dashboards: { id: string; name: string }[] })[],
    searchTerm: string | undefined
): WidgetFactType[] => {
    const lowerSearchTerm = searchTerm?.toLowerCase();

    return sortBy(
        widgets.filter(
            w =>
                !lowerSearchTerm ||
                w.description?.toLowerCase().includes(lowerSearchTerm) ||
                w.name.toLowerCase().includes(lowerSearchTerm)
        ),
        'name'
    );
};

// Filter widget templates by search term
const filterWidgetTemplates = (widgets: WidgetFactTemplate[], searchTerm: string | undefined): WidgetFactTemplate[] => {
    if (!searchTerm) return widgets;
    const lowerSearchTerm = searchTerm?.toLowerCase();
    return widgets.filter(
        w =>
            !lowerSearchTerm ||
            w.labels
                .map(l => l.toLowerCase())
                .join(' ')
                .includes(lowerSearchTerm) ||
            w.description?.toLowerCase().includes(lowerSearchTerm) ||
            w.name.toLowerCase().includes(lowerSearchTerm)
    );
};

export const WidgetLibrary: FunctionComponent<Props> = ({
    className,
    dashboardId,
    onWidgetAdded,
    onTemplateSelected
}: Props) => {
    // State
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [categories, setCategories] = useState<{ [key: string]: Category }>({
        collections: {
            icon: kpNewChart,
            subCategories: {}
        },
        templates: {
            icon: faLayerGroup,
            subCategories: {}
        }
    });

    // Refs
    const [parentContainerRef, { width: parentContainerWidth }] = useMeasure<HTMLDivElement>();

    // Dashboard
    const { normalized: dashboard } = useDashboard({ id: dashboardId });

    // Load the user widgets
    const { normalized: userWidgets, loading: loadingUserWidgets, refetch: refetchUserWidgets } = useWidgets({
        roles: ['OWNER'],
        excludeDecorators: true
    });

    // Load the shared widgets
    const { normalized: sharedWidgets, loading: loadingSharedWidgets, refetch: refetchSharedWidgets } = useWidgets({
        roles: ['EDITOR', 'VIEWER'],
        excludeDecorators: true
    });

    const [selectedCategoryAndSub, setSelectedCategoryAndSub] = useState<string>('collections.all');
    const selectedCategory = selectedCategoryAndSub.split('.')[0];
    const selectedSubCategory = selectedCategoryAndSub.split('.')[1];
    const selectedSubCategoryItems =
        selectedSubCategory == 'blank'
            ? BLANK_TEMPLATES
            : categories[selectedCategory]?.subCategories[selectedSubCategory];
    const isTemplate = selectedCategory == 'templates';
    const isBlankTemplate = selectedCategoryAndSub == 'templates.blank';

    // Hook invoked when a refetch is needed
    const refetch = useCallback(async () => {
        await refetchUserWidgets();
        await refetchSharedWidgets();
        await onWidgetAdded();
    }, [onWidgetAdded, refetchSharedWidgets, refetchUserWidgets]);

    // Build categories
    useEffect(
        () =>
            setCategories({
                collections: {
                    icon: kpNewChart,
                    subCategories: {
                        all: filterWidgets([...userWidgets, ...sharedWidgets], searchTerm),
                        owned: filterWidgets(userWidgets, searchTerm),
                        shared: filterWidgets(sharedWidgets, searchTerm)
                    }
                },
                templates: {
                    icon: faLayerGroup,
                    subCategories: groupWidgetTemplatesByCategory(filterWidgetTemplates(TEMPLATES, searchTerm))
                }
            }),
        [searchTerm, sharedWidgets, userWidgets]
    );

    // Render
    return (
        <Row className={classNames('g-0 h-100', className)}>
            {/* Search & categories */}
            <Col xs="5" lg="4" className="pt-4 pe-1 d-flex flex-column h-100 overflow-y-auto">
                {/* Search bar */}
                <SearchBar value={searchTerm} onChange={setSearchTerm} className="mb-5 ms-4" />

                {/* Select categories */}
                {Object.entries(categories).map(([categoryId, category]) => (
                    <div className="mb-5" key={categoryId}>
                        {/* Category Title */}
                        <h5 className="mb-2 ms-4">
                            <FontAwesomeIcon icon={category.icon} className="me-2" />
                            <RichMessage id={`dashboarding.add-insight-modal.widget-library.${categoryId}`} />
                        </h5>

                        {/* Category content */}
                        <ListGroup className="sticky-left">
                            {Object.entries(category.subCategories)
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([subCategoryId, subCategory]) => {
                                    const categoryAndSubCategory = `${categoryId}.${subCategoryId}`;
                                    const isActive = categoryAndSubCategory == selectedCategoryAndSub;
                                    return (
                                        // Category menu
                                        <ListGroup.Item
                                            key={categoryAndSubCategory}
                                            action
                                            onClick={() => setSelectedCategoryAndSub(categoryAndSubCategory)}
                                            active={isActive}
                                            className="ps-4"
                                        >
                                            <RichMessage
                                                id={`dashboarding.add-insight-modal.widget-library.category.${categoryAndSubCategory}`}
                                            />
                                            <span
                                                className={classNames('ms-1', {
                                                    'text-light': !isActive,
                                                    'text-white opacity-50': isActive
                                                })}
                                            >
                                                ({subCategory.length})
                                            </span>
                                        </ListGroup.Item>
                                    );
                                })}
                        </ListGroup>
                    </div>
                ))}

                {/* Blank Templates */}
                <ListGroup.Item
                    action
                    onClick={() => setSelectedCategoryAndSub('templates.blank')}
                    active={selectedCategoryAndSub == 'templates.blank'}
                    className={classNames('mb-5 ps-4 rounded-end', {
                        'text-grey-4': selectedCategoryAndSub !== 'templates.blank'
                    })}
                >
                    <RichMessage id={`dashboarding.add-insight-modal.widget-library.category.blank-templates`} />
                </ListGroup.Item>
            </Col>

            {/* List of templates */}
            <Col className="d-flex flex-column px-4 px-lg-5 pt-4 h-100 overflow-y-auto">
                {/* Category title */}
                <h5 className="mb-4">
                    <RichMessage
                        id={`dashboarding.add-insight-modal.widget-library.category.${selectedCategoryAndSub}`}
                    />
                    <span className="text-light ms-1">
                        {selectedSubCategory == 'blank' ? null : `(${selectedSubCategoryItems?.length})`}
                    </span>
                </h5>

                <div ref={parentContainerRef}>
                    {/* Category templates */}
                    <Row
                        className="mx-auto"
                        xs="auto"
                        style={{
                            maxWidth: computeCardsContainerMaxWidth(
                                parentContainerWidth,
                                WIDGET_CARD_WIDTH + 2 * LIBRARY_CARD_MARGIN
                            )
                        }}
                    >
                        {selectedCategory == 'collections' && (loadingUserWidgets || loadingSharedWidgets) ? (
                            <Spinner animation="border" className="m-auto" />
                        ) : selectedSubCategoryItems && selectedSubCategoryItems.length > 0 ? (
                            selectedSubCategoryItems.map(e => (
                                <Col key={e.id} className="p-0">
                                    {isTemplate ? (
                                        <WidgetTemplateLibraryCard
                                            widget={e as WidgetFactTemplate}
                                            width={WIDGET_CARD_WIDTH}
                                            height={!isBlankTemplate ? BLANK_TEMPLATE_CARD_WIDTH : undefined}
                                        >
                                            {isBlankTemplate ? (
                                                <WidgetBlankTemplateButton
                                                    dashboardId={dashboardId}
                                                    widget={e as WidgetFactTemplate}
                                                />
                                            ) : (
                                                <Button
                                                    variant="dark"
                                                    onClick={() => onTemplateSelected(e as WidgetFactTemplate)}
                                                    className="rounded-0 rounded-bottom justify-content-center w-100"
                                                >
                                                    <RichMessage id="components.library-card.create-insight-from-template" />
                                                </Button>
                                            )}
                                        </WidgetTemplateLibraryCard>
                                    ) : (
                                        <WidgetLibraryCard
                                            widget={
                                                e as WidgetFactType & { dashboards: { id: string; name: string }[] }
                                            }
                                            dashboardId={dashboardId}
                                            width={WIDGET_CARD_WIDTH}
                                        >
                                            {dashboard && (
                                                <WidgetInstanceButton
                                                    dashboard={dashboard}
                                                    widget={
                                                        e as WidgetFactType & {
                                                            dashboards: { id: string; name: string }[];
                                                        }
                                                    }
                                                    refetch={refetch}
                                                />
                                            )}
                                        </WidgetLibraryCard>
                                    )}
                                </Col>
                            ))
                        ) : (
                            <Col className="text-center">
                                <FormattedMessage id="dashboarding.add-insight-modal.widget-library.no-insights" />
                            </Col>
                        )}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};
