import gql from 'graphql-tag';

export interface BillingInvoiceResp {
    id: string;
    amountCents: number;
    currency: string;
    publishedAt: string;
    reference: string;
    status: string;
    url: string;
}

export interface GetBillingInvoicesResp {
    billingSubscription?: {
        billingInvoices: {
            nodes: BillingInvoiceResp[];
        };
    };
}

export const SUBSCRIBE_TO_BILLING_INVOICE_UPDATED = gql`
    subscription($companyId: ID!) {
        billingInvoiceUpdated(companyId: $companyId) {
            id
        }
    }
`;

export const SUBSCRIBE_TO_BILLING_INVOICE_CREATED = gql`
    subscription($companyId: ID!) {
        billingInvoiceCreated(companyId: $companyId) {
            id
        }
    }
`;

// Retrieves the last 100 valid invoices which a strictly positive amount
//
// Showing zero-dollar invoices may scare customers as they wouldn't
// expect invoices when taking a free trial or when there is credit remaining
// on their account.
//
// Also we explicitly exclude uncollectible or void invoices as those are
// by definition non-existing from a customer perspective.
//
export const GET_BILLING_INVOICES = gql`
    query($companyId: ID) {
        billingSubscription(companyId: $companyId) {
            id
            billingInvoices(sortBy: "publishedAt.desc", amountCentsGt: 0, statusIn: [OPEN, PAID]) {
                nodes {
                    id
                    amountCents
                    currency
                    publishedAt
                    reference
                    status
                    url
                }
            }
        }
    }
`;
