import React, { Fragment, FunctionComponent, useState, useEffect } from 'react';
import { FeedItem, InboxItem, InboxItemRelated } from 'api/viz/queries/InboxItem';
import InboxFeedItem from './InboxFeedItem';
import CommentInput from './CommentInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ItemComment } from 'api/viz/queries/Comment';
import { FormattedMessage } from 'react-intl';

// Start collapsing messages when the number reaches that threshold
const COLLAPSE_THRESHOLD = 7;

interface Props {
    includeName?: boolean;
    parent: InboxItem | InboxItemRelated;
    items: FeedItem[];
    demoMode?: boolean;
}

const InboxFeedList: FunctionComponent<Props> = ({ parent, items, includeName, demoMode }: Props) => {
    // Extract comments
    const sortedItems = items.sort((a, b) => {
        return Date.parse(a.submitted_at) > Date.parse(b.submitted_at) ? 1 : -1;
    });
    const cacheKey = JSON.stringify(sortedItems.map(e => `${e._system_id}-${e.updated_at}`).sort());
    const collapsible = items.length >= COLLAPSE_THRESHOLD;

    // State
    const [messages, setMessages] = useState<FeedItem[]>([]);
    const [isCollapsed, setIsCollapsed] = useState<boolean | null>(null);

    // Update messages state from props ONLY when the actual list of items has changed
    useEffect(() => {
        setMessages(sortedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheKey]);

    // Return the feed item key to use in list elements
    const itemKey = (item: FeedItem): string => {
        return item.type + '-' + item._system_id;
    };

    // Create comment callback
    // Note: the message is added to the local messages state. Eventually the messages state will
    // be reset from props (see useEffect) when the report is updated and sent via GraphQL subscription.
    const onCreateComment = (parent: InboxItem | InboxItemRelated, comment: ItemComment): void => {
        setMessages(messages.concat((comment as unknown) as FeedItem));
    };

    // Retrieve items used in collapsed version
    const isRealCollapsed = isCollapsed === null ? collapsible : isCollapsed;
    const collapsedFeed =
        messages.length > 0 ? [messages[0], null, messages[messages.length - 2], messages[messages.length - 1]] : [];
    const collapsedCount = messages.length - collapsedFeed.filter(e => e).length;

    return (
        <Fragment>
            {isRealCollapsed ? (
                // Collapsed version
                collapsedFeed.map((feedItem: FeedItem | null) => {
                    return feedItem ? (
                        <InboxFeedItem key={itemKey(feedItem)} item={feedItem} includeName={includeName} />
                    ) : (
                        <ExpandSeparator key="separator" count={collapsedCount} onClick={() => setIsCollapsed(false)} />
                    );
                })
            ) : (
                // Expanded version
                <Fragment>
                    {collapsible && <CollapseSeparator onClick={() => setIsCollapsed(true)} />}
                    {messages.map((feedItem: FeedItem) => {
                        return <InboxFeedItem key={itemKey(feedItem)} item={feedItem} includeName={includeName} />;
                    })}
                </Fragment>
            )}
            <CommentInput parent={parent} onCreate={onCreateComment} demoMode={demoMode} />
        </Fragment>
    );
};

interface ExpandSeparatorProps {
    count: number;
    onClick?: () => void;
}

const ExpandSeparator: FunctionComponent<ExpandSeparatorProps> = ({ count, onClick }: ExpandSeparatorProps) => {
    return (
        <div
            className="collapsible-separator position-relative d-flex align-items-center justify-content-center my-4"
            onClick={onClick}
        >
            {/* separator line */}
            <div className="expand-separator position-absolute w-100" />

            {/* separator label */}
            <div className="counter bg-white text-grey-7 px-5 py-2 zindex-1">
                <FormattedMessage id="priority-inbox.feed.load-more" values={{ count }} />
            </div>
        </div>
    );
};

interface CollapseSeparatorProps {
    onClick?: () => void;
}

const CollapseSeparator: FunctionComponent<CollapseSeparatorProps> = ({ onClick }: CollapseSeparatorProps) => {
    return (
        <div className="collapsible-separator collapse-separator" onClick={onClick}>
            <div className="action">
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
                <FontAwesomeIcon icon={faChevronUp} size="sm" />
            </div>
        </div>
    );
};

export default InboxFeedList;
