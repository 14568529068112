import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ConfigOption, ConfigQnMultiSelectInput } from 'components/Dashboarding/Models/ConfigQns';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/defaultValues';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Label } from '../Label';

interface OptionProps {
    label: string;
    selected: boolean;
    onToggleSelection: () => void;
}

const Option: FunctionComponent<OptionProps> = ({ label, selected, onToggleSelection }) => {
    return (
        <div
            className={classNames(
                'd-flex flex-column justify-content-center rounded-pill cursor-pointer p-4 mx-auto text-center border fw-500 bg-transparent text-dark',
                {
                    'border-primary bg-primary-light text-primary': selected
                }
            )}
            style={{ aspectRatio: '4.5 / 1', maxWidth: '325px' }}
            onClick={onToggleSelection}
        >
            <span>
                {selected && <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" size="lg" />}
                {label}
            </span>
        </div>
    );
};

interface Props {
    config: ConfigQnMultiSelectInput;
    onChange: (e: ConfigQnMultiSelectInput) => void;
}

export const MultiSelectInput: FunctionComponent<Props> = ({ config, onChange }) => {
    // State
    const [selectedOptions, setSelectedOptions] = useState<ConfigOption[]>([]);
    const [value, setValue] = useState<string>('');

    // Debounced version of onChange
    const debouncedOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_TIME), [onChange]);

    // Hook invoked when an option is clicked
    const handleOptionClick = useCallback(
        (option: ConfigOption) => {
            const index = selectedOptions.findIndex(e => e.id == option.id);
            const updatedSelected = [...selectedOptions];
            if (index == -1) {
                updatedSelected.push(option);
            } else {
                updatedSelected.splice(index, 1);
            }
            setSelectedOptions(updatedSelected);
            onChange({
                ...config,
                result: {
                    input: value,
                    selectedOptions: updatedSelected
                }
            });
        },
        [config, onChange, selectedOptions, value]
    );

    const handleOnInputChange = useCallback(
        (event: React.FocusEvent<HTMLInputElement>): void => {
            setValue(event.target.value);
            debouncedOnChange({ ...config, result: { selectedOptions, input: event.target.value } });
        },
        [debouncedOnChange, config, selectedOptions]
    );

    // Resync selected options from parent update
    useEffect(() => setSelectedOptions(config.result.selectedOptions), [config.result.selectedOptions]);

    // Resync input value from parent update
    useEffect(() => setValue(config.result.input), [config.result.input]);

    return (
        <Form.Group>
            <Label config={config} />
            <Row xs={1} md={2}>
                {config.options?.map(e => (
                    <Col key={e.id} className="mb-4 px-2">
                        <Option
                            label={e.label as string}
                            selected={selectedOptions.findIndex(o => o.id == e.id) != -1}
                            onToggleSelection={() => handleOptionClick(e)}
                        />
                    </Col>
                ))}
            </Row>
            <Form.Control type="input" placeholder={config.placeholder} value={value} onChange={handleOnInputChange} />
        </Form.Group>
    );
};
