import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { DragHandleProps } from 'react-querybuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

const DragHandle: ForwardRefExoticComponent<DragHandleProps & RefAttributes<HTMLSpanElement>> = forwardRef(
    ({ className, title }: DragHandleProps & RefAttributes<HTMLSpanElement>, ref) => (
        <span ref={ref} className={className} title={title}>
            <FontAwesomeIcon icon={faGripVertical} className="text-grey-7" />
        </span>
    )
);

export default DragHandle;
