import { useQueryOpFieldLookup } from 'components/Dashboarding/Hooks/useQueryOpFieldLookup';
import { QueryOpFilter, QueryOpFilterFunction } from 'components/Dashboarding/DataSource/QueryOperators';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { OperatorSelectorProps } from 'react-querybuilder';
import { RulesetBuilderContext } from './RulesetBuilder';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { useNavigableDropdown } from 'components/NavigableDropdown/useNavigableDropdown';
import NavigableDropdown from 'components/NavigableDropdown';

interface GroupOption {
    section: string | undefined;
    options: QueryOpFilterFunction[];
}

const RulesetOperator: FunctionComponent<OperatorSelectorProps> = ({
    className,
    handleOnChange,
    options,
    title,
    value,
    field,
    context
}: OperatorSelectorProps) => {
    // Extract contextual information
    const { fact } = context as RulesetBuilderContext;

    // Retrieve field configuration
    const fieldConfig = useQueryOpFieldLookup({ fact, fieldUri: field });

    // Retrieve allowed operators
    const operatorList = fieldConfig?.filters;
    const selectableOptions = options as QueryOpFilterFunction[];
    const allowedOptions = useMemo(
        () =>
            operatorList
                ? selectableOptions
                      .filter(e => operatorList.includes(e.name))
                      .sort((a, b) => (operatorList.indexOf(a.name) < operatorList.indexOf(b.name) ? -1 : 1))
                : selectableOptions,
        [operatorList, selectableOptions]
    );

    const groupedAllowedOptions = useCallback(
        (options: QueryOpFilterFunction[]) =>
            options
                .filter(o => o.section)
                .reduce((previousValue: GroupOption[], current: QueryOpFilterFunction) => {
                    const existingGroup = previousValue.find((g: GroupOption) => g.section === current.section);
                    if (existingGroup) {
                        existingGroup.options.push(current);
                    } else {
                        previousValue.push({ section: current.section, options: [current] });
                    }
                    return previousValue;
                }, []),
        []
    );

    const selectedLabel = allowedOptions.find(o => o.name == value)?.label;

    // Invoke parent hook
    const onValueChange = useCallback(
        (value: string | null): void => {
            handleOnChange && handleOnChange(value);
        },
        [handleOnChange]
    );

    const handleSelection = useCallback(
        (name: string | null) => {
            onValueChange(name);
        },
        [onValueChange]
    );

    // Ensure provided value is allowed
    useEffect(() => {
        if ((!value || !allowedOptions.map(e => e.name).includes(value as QueryOpFilter)) && allowedOptions[0]) {
            onValueChange(allowedOptions[0].name);
        }
    }, [value, allowedOptions, handleSelection, onValueChange]);

    // use dropdown
    const {
        getDropdownProps,
        getDropdownMenuProps,
        getDropdownItemProps,
        options: allowedOptionsResult
    } = useNavigableDropdown<QueryOpFilterFunction>({
        isSearchable: false,
        onSelect: handleSelection,
        options: allowedOptions,
        mapper: {
            value: 'name',
            label: 'label'
        }
    });

    // Display all available operators for that field, sorted by label
    return (
        <Dropdown {...getDropdownProps} onSelect={handleSelection}>
            <Dropdown.Toggle
                as="div"
                role="button"
                className={classNames(className, 'd-flex justify-content-between align-items-center h-100')}
                title={title}
            >
                {selectedLabel}
            </Dropdown.Toggle>
            {/* List of operators */}
            <NavigableDropdown.Menu
                className="max-vh-30 overflow-y-auto"
                // Fixed strategy is required to avoid issues with container overflow
                popperConfig={{ strategy: 'fixed' }}
                // Fixed strategy is bugged. Need renderOnMount to work properly
                // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                renderOnMount
                {...getDropdownMenuProps}
            >
                {groupedAllowedOptions(allowedOptionsResult).map(group => {
                    return (
                        <Fragment key={group.section}>
                            {group.options.map(option => {
                                return (
                                    <NavigableDropdown.Item
                                        {...getDropdownItemProps}
                                        key={option.name}
                                        value={option.name}
                                        eventKey={option.name}
                                    >
                                        {option.label}
                                    </NavigableDropdown.Item>
                                );
                            })}
                            <Dropdown.Divider />
                        </Fragment>
                    );
                })}
                {allowedOptionsResult.map(option => {
                    return (
                        !option.section && (
                            <NavigableDropdown.Item
                                {...getDropdownItemProps}
                                key={option.name}
                                value={option.name}
                                eventKey={option.name}
                            >
                                {option.label}
                            </NavigableDropdown.Item>
                        )
                    );
                })}
            </NavigableDropdown.Menu>
        </Dropdown>
    );
};

export default RulesetOperator;
