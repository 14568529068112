import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WIDGET_ASSISTANT_STEP1, WIDGET_ASSISTANT_STEP2, WIDGET_ASSISTANT_STEP3 } from 'constants/routeBuilders';
import { Step1Configure } from './Step1Configure';
import { Step2Document } from './Step2Document';
import { Step3Review } from './Step3Review';

const DashboardWidgetAssistant: FunctionComponent = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to={WIDGET_ASSISTANT_STEP1} />} />
            <Route path={WIDGET_ASSISTANT_STEP1} element={<Step1Configure />} />
            <Route path={WIDGET_ASSISTANT_STEP2} element={<Step2Document />} />
            <Route path={WIDGET_ASSISTANT_STEP3} element={<Step3Review />} />
        </Routes>
    );
};

export default DashboardWidgetAssistant;
