import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    ChartSeriesType,
    KpiSeriesType,
    WidgetConfigType,
    WidgetFieldSortDirection,
    WidgetTypedFieldWrapper,
    WIDGET_DISPLAY_CONFIGS
} from 'components/Dashboarding/Models/Widget';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import React, { ReactElement, useCallback, useMemo } from 'react';
import WidgetDimensionSelector from '../WidgetDimensionSelector';
import WidgetMetricSelector from '../WidgetMetricSelector';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import Dropdown from 'react-bootstrap/Dropdown';
import { WIDGET_DEFAULT_SERIES_COLOR, NEUTRAL_GREY_6 } from 'constants/colors';
import { shadeHexColor, textColorFromBg } from 'util/ColorOperators';
import ColorMatrix from 'components/Design/ColorPickers/ColorMatrix';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props<TTypedField extends WidgetTypedFieldWrapper> {
    fact: QueryOpFact;
    configType: WidgetConfigType;
    series: ChartSeriesType | KpiSeriesType;
    typedField: TTypedField;
    onChange: (e?: TTypedField) => void;
    onReorder: (direction: 'left' | 'right') => void;
    onSort: (direction: WidgetFieldSortDirection) => void;
}

// Component used in ConfigureTable to format the header cells
function PreviewTableTh<TTypedField extends WidgetTypedFieldWrapper>({
    fact,
    configType,
    series,
    typedField,
    onChange,
    onReorder,
    onSort
}: Props<TTypedField>): ReactElement<TTypedField> {
    // Services
    const intl = useRichIntl();

    // Infer context
    const seriesConfig = WIDGET_DISPLAY_CONFIGS[configType];

    // Is field coloring enabled?
    const hasColoring = useMemo(() => {
        return (
            (typedField.type == 'metric' && seriesConfig.colorMetric) ||
            (typedField.type == 'dimension' && seriesConfig.colorDimension)
        );
    }, [seriesConfig.colorDimension, seriesConfig.colorMetric, typedField.type]);

    // Evaluate top heading style
    const baseFieldColor = useMemo(
        () => (hasColoring ? typedField.field.formatting?.color || WIDGET_DEFAULT_SERIES_COLOR : NEUTRAL_GREY_6),
        [hasColoring, typedField.field.formatting?.color]
    );
    const columnLabelStyle = useMemo(() => {
        const bgColor = shadeHexColor(baseFieldColor, 0.85);
        const txtColor = textColorFromBg(baseFieldColor, baseFieldColor, shadeHexColor(baseFieldColor, -0.5));
        return {
            backgroundColor: bgColor,
            borderColor: bgColor,
            borderStyle: 'solid',
            borderWidth: 1,
            color: txtColor
        };
    }, [baseFieldColor]);

    // Evaluate if the column can be removed
    const canRemove = useMemo(() => {
        const localRemove =
            typedField.type == 'metric'
                ? series.metrics.length > seriesConfig.rangeMetrics[0]
                : series.dimensions.length > seriesConfig.rangeDimensions[0];
        const globalRemove = series.metrics.length + series.dimensions.length > 1;
        return localRemove && globalRemove;
    }, [
        series.dimensions.length,
        series.metrics.length,
        seriesConfig.rangeDimensions,
        seriesConfig.rangeMetrics,
        typedField.type
    ]);

    // Evaluate if the column can be moved
    const [canMoveLeft, canMoveRight] = useMemo(() => {
        // Get local and absolute column count
        const localColCount = typedField.type == 'metric' ? series.metrics.length : series.dimensions.length;
        const absoluteColCount = series.metrics.length + series.dimensions.length;

        return [
            seriesConfig.absoluteRanking ? typedField.absoluteIndex > 0 : typedField.localIndex > 0,
            seriesConfig.absoluteRanking
                ? typedField.absoluteIndex < absoluteColCount - 1
                : typedField.localIndex < localColCount - 1
        ];
    }, [
        series.dimensions.length,
        series.metrics.length,
        seriesConfig.absoluteRanking,
        typedField.absoluteIndex,
        typedField.localIndex,
        typedField.type
    ]);

    // Evaluate axis name
    const label = useMemo(() => {
        const labelIdBase = `dashboarding.widget-configuration.axis.${configType}.${typedField.type}`;
        const displayIndex = typedField.localIndex + 1;
        const labelIdSpecific = `${labelIdBase}.${typedField.localIndex}`;
        const labelIdGlob = `${labelIdBase}.*`;

        if (intl.messages[labelIdSpecific]) {
            return intl.formatMessage({ id: labelIdSpecific }, { index: displayIndex });
        } else if (intl.messages[labelIdGlob]) {
            return intl.formatMessage({ id: labelIdGlob }, { index: displayIndex });
        } else {
            return undefined;
        }
    }, [configType, typedField.type, typedField.localIndex, intl]);

    // Full label
    const fullLabel = useMemo(
        () => (
            <>
                <RichMessage id={`dashboarding.widget-editor.configure-table.heading-type.${typedField.type}`} />
                {label && <span>: {label}</span>}
            </>
        ),
        [typedField.type, label]
    );

    // Cycle through colors on click
    const onColorPinClick = useCallback(
        (color: string) => {
            onChange({
                ...typedField,
                field: { ...typedField.field, formatting: { ...typedField.field.formatting, color } }
            });
        },
        [onChange, typedField]
    );

    // Render cell content
    return (
        <>
            {/* Header: label & color */}
            <div className="th-upper">
                <div className="d-flex w-100 fw-bold text-uppercase">
                    {/* Field color pin */}
                    {hasColoring && (
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom-start"
                            transition={false}
                            rootClose
                            overlay={
                                <Popover>
                                    <Popover.Body>
                                        <ColorMatrix onChange={onColorPinClick} />
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div
                                className="d-flex justify-content-center align-items-center border rounded-start px-3"
                                role="button"
                            >
                                <div className="color-pin">
                                    <div
                                        className="color-pin-inner"
                                        style={{ backgroundColor: columnLabelStyle.color }}
                                    ></div>
                                </div>
                            </div>
                        </OverlayTrigger>
                    )}

                    {/* Field label with coloring */}
                    <div
                        className={classNames('rounded-end flex-grow-1 p-2', { 'rounded-start': !hasColoring })}
                        style={columnLabelStyle}
                    >
                        <span className={`elevio-${typedField.type}`}>{fullLabel}</span>
                    </div>
                </div>
            </div>

            {/* Header: edition */}
            <div className="th-lower">
                {/* Header content */}
                <div className="flex-grow-1 h-100">
                    {typedField.type == 'dimension' ? (
                        <WidgetDimensionSelector
                            label={fullLabel}
                            fact={fact}
                            field={typedField.field}
                            usageScope="dimension"
                            onChange={e => onChange({ ...typedField, field: e })}
                        />
                    ) : (
                        <WidgetMetricSelector
                            label={fullLabel}
                            fact={fact}
                            field={typedField.field}
                            onChange={e => onChange({ ...typedField, field: e })}
                        />
                    )}
                </div>

                {/* Triple-dot menu */}
                <Dropdown className="h-100 border-start border-grey-6">
                    {/* Toggle */}
                    <Dropdown.Toggle
                        as="div"
                        bsPrefix="none"
                        className="h-100 px-3 bg-grey-10 d-flex justify-content-center align-items-center"
                        role="button"
                    >
                        <FontAwesomeIcon icon={faEllipsisV} className="text-muted" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        // Fixed strategy is required to avoid issues with container overflow
                        popperConfig={{ strategy: 'fixed' }}
                        // Fixed strategy is bugged. Need renderOnMount to work properly
                        // See https://github.com/react-bootstrap/react-bootstrap/issues/6203
                        renderOnMount
                    >
                        <Dropdown.Item
                            onClick={() => onSort('ASC')}
                            className={classNames({ 'fw-bold': typedField.field.sort == 'ASC' })}
                        >
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.sort-asc" />
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => onSort('DESC')}
                            className={classNames({ 'fw-bold': typedField.field.sort == 'DESC' })}
                        >
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.sort-desc" />
                        </Dropdown.Item>
                        <Dropdown.Item disabled={!canMoveLeft} onClick={() => onReorder('left')}>
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.move-left" />
                        </Dropdown.Item>
                        <Dropdown.Item disabled={!canMoveRight} onClick={() => onReorder('right')}>
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.move-right" />
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item disabled={!canRemove} onClick={() => onChange(undefined)}>
                            <RichMessage id="dashboarding.widget-editor.configure-table.action.remove-column" />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}

export default PreviewTableTh;
