import React, { FunctionComponent, ReactNode, ReactNodeArray, useState, useCallback, useEffect } from 'react';
import { default as BSAlert, AlertProps } from 'react-bootstrap/Alert';

interface Props extends AlertProps {
    children: ReactNode | ReactNodeArray;
}

// TODO: introduce a Hook to fire alerts from children components

// Bootstrap Alert wrapper
const Alert: FunctionComponent<Props> = ({ children, onClose, show: isOpen, ...props }: Props) => {
    const [show, setShow] = useState<AlertProps['show']>(false);

    // Set show state
    useEffect(() => setShow(isOpen), [isOpen]);

    // Handle dismissble feature of the Alert
    //
    // This callback assure you don't need to handle
    // the show state externally when dismissing the Alert
    const handleClose = useCallback(
        (arg1, arg2) => {
            setShow(false);
            if (onClose) onClose(arg1, arg2);
        },
        [onClose]
    );
    return (
        <BSAlert {...props} show={show} onClose={handleClose}>
            {children}
        </BSAlert>
    );
};

export default Alert;
