import React, { FunctionComponent } from 'react';
import { WIDGET_DEFAULT_SERIES_COLOR } from 'constants/colors';
import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ColorMatrix from './ColorMatrix';

interface Props {
    className?: string;
    color?: string;
    onChange: (color: string) => void;
}

const PaletteColorPicker: FunctionComponent<Props> = ({ className, color, onChange }: Props) => {
    // Get actual color
    const pickedColor = color || WIDGET_DEFAULT_SERIES_COLOR;

    // Render
    return (
        <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            transition={false}
            rootClose
            overlay={
                <Popover>
                    <Popover.Body>
                        <ColorMatrix onChange={onChange} />
                    </Popover.Body>
                </Popover>
            }
        >
            <div className={classNames('color-picker-control border rounded', className)} role="button">
                <div className="single-color rounded-circle" style={{ backgroundColor: pickedColor }} />
            </div>
        </OverlayTrigger>
    );
};

export default PaletteColorPicker;
