import { ReportFunction, ReportFunctionArg } from 'api/viz/hooks/useGenericReport';
import { useCallback, useMemo } from 'react';
import { QueryOpFact } from '../DataSource';
import {
    LG_FORMULA_DIMENSION_FN_CONFIGS,
    LG_FORMULA_METRIC_FN_CONFIGS,
    QueryOpField,
    QueryOpUsageScope
} from '../DataSource/QueryOperators';
import { useQueryOpFields } from '../Hooks/useQueryOpFields';

// List of supported (native) operators. Those are not functions but instead
// widely supported signs (e.g. +, -, /, *). These operators are hardcoded
// into the parser.
const NATIVE_OPERATORS = [
    'ADD',
    'AND',
    'DIVIDE',
    'EQUAL',
    'GREATER_THAN',
    'GREATER_THAN_EQUAL',
    'LESS_THAN',
    'LESS_THAN_EQUAL',
    'MATCH',
    'MULTIPLY',
    'NOT',
    'NOT_EQUAL',
    'NOT_MATCH',
    'OR',
    'SUBTRACT'
];

// List all supported function/operator names for metric formulas
const ALLOWED_METRIC_FN_NAMES = [
    ...NATIVE_OPERATORS,
    ...LG_FORMULA_METRIC_FN_CONFIGS.filter(e => !e.hideInFormula).map(e => e.id)
];

// List all supported function/operator names for dimensions formulas
const ALLOWED_DIMENSION_FN_NAMES = [
    ...NATIVE_OPERATORS,
    ...LG_FORMULA_DIMENSION_FN_CONFIGS.filter(e => !e.hideInFormula).map(e => e.id)
];

interface ValidationProps {
    allowedFields: QueryOpField[];
    parsedFormula?: ReportFunction | ReportFunctionArg;
}

export type FormulaValidationErrorType = 'syntax-invalid' | 'function-undefined' | 'field-undefined' | 'formula-empty';

export interface FormulaValidationError {
    id: FormulaValidationErrorType;
    target?: string;
}

export interface FormulaValidationState {
    isValid: boolean;
    error?: FormulaValidationError;
}

// TODO: make sure aggregators are nested. e.g. COUNT(COUNT())
// TODO: validate argument list/types
export const validateMetricFormula = ({ allowedFields, parsedFormula }: ValidationProps): FormulaValidationState => {
    // Abort if empty
    if (parsedFormula == undefined) {
        return { isValid: false, error: { id: 'formula-empty' } };
    }

    // Validate as function
    if ('function' in parsedFormula) {
        const fn = parsedFormula.function;

        // Validate the function used
        if (!ALLOWED_METRIC_FN_NAMES.includes(fn)) {
            return {
                isValid: false,
                error: { id: 'function-undefined', target: fn }
            };
        }

        // Validate arguments and return the first error (if any)
        const argError = parsedFormula.args
            .map(e => validateMetricFormula({ allowedFields, parsedFormula: e }))
            .find(e => !e.isValid);
        if (argError) return argError;
    }

    // Validate as field
    if ('field' in parsedFormula) {
        const allowedFieldNames = allowedFields.map(e => e.id);
        const fieldName = parsedFormula.field;

        // Validate field used
        if (!allowedFieldNames.includes(fieldName)) {
            return {
                isValid: false,
                error: { id: 'field-undefined', target: fieldName }
            };
        }
    }

    // Any other case if considered valid
    return { isValid: true };
};

// TODO: validate argument list/types
export const validateDimensionFormula = ({ allowedFields, parsedFormula }: ValidationProps): FormulaValidationState => {
    // Abort if empty
    if (parsedFormula == undefined) {
        return { isValid: false, error: { id: 'formula-empty' } };
    }

    // Validate as function
    if ('function' in parsedFormula) {
        const fn = parsedFormula.function;

        // Validate the function used
        if (!ALLOWED_DIMENSION_FN_NAMES.includes(fn)) {
            return {
                isValid: false,
                error: { id: 'function-undefined', target: fn }
            };
        }

        // Validate arguments and return the first error (if any)
        const argError = parsedFormula.args
            .map(e => validateMetricFormula({ allowedFields, parsedFormula: e }))
            .find(e => !e.isValid);
        if (argError) return argError;
    }

    // Validate as field
    if ('field' in parsedFormula) {
        const allowedFieldNames = allowedFields.map(e => e.id);
        const fieldName = parsedFormula.field;

        // Validate field used
        if (!allowedFieldNames.includes(fieldName)) {
            return {
                isValid: false,
                error: { id: 'field-undefined', target: fieldName }
            };
        }
    }

    // Any other case if considered valid
    return { isValid: true };
};

interface Props {
    fact: QueryOpFact;
    usageScope: QueryOpUsageScope;
}

// Return the relevant validator
export const useFormulaValidator = ({
    fact,
    usageScope
}: Props): ((parsedFormula?: ReportFunction) => FormulaValidationState) => {
    // Get all relevant fields
    // We allow all metric and dimension fields to be used in formulas
    const { queryOpFields } = useQueryOpFields({ fact, usageScope: ['metric', 'dimension'] });

    // Evaluate the validate to use
    const validateFn = useMemo(() => (usageScope == 'metric' ? validateMetricFormula : validateDimensionFormula), [
        usageScope
    ]);

    // Build the validator
    const validator = useCallback(
        (e?: ReportFunction) => validateFn({ allowedFields: queryOpFields, parsedFormula: e }),
        [queryOpFields, validateFn]
    );

    // Return the validator
    return validator;
};
