import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetVSeparator as WidgetVSeparatorType } from 'components/Dashboarding/Models/Widget';
import { Spinner } from 'react-bootstrap';

interface Props {
    isEditable?: boolean;
    isEditing?: boolean;
    loading?: boolean;
    onRemoveWidget?: () => void;
    widget?: WidgetVSeparatorType;
}

const WidgetVSeparator: FunctionComponent<Props> = ({ loading, onRemoveWidget, widget, isEditing }: Props) => {
    // Loading
    if (loading) return <Spinner animation="border" variant="primary" />;

    // Abort if no widget
    if (!widget) return null;

    return (
        <div
            className={classNames(
                'd-flex flex-column flex-column-reverse justify-content-center align-items-center',
                'h-100 w-100 flex-grow-1',
                { 'cursor-move': isEditing, 'bg-white': isEditing, 'pb-4': isEditing }
            )}
        >
            {/* Horizontal line */}
            <div className="h-100 border border-end-0 border-grey-7" />

            {/* Edit mode control buttons */}
            {isEditing && (
                <Button variant="link">
                    <FontAwesomeIcon onClick={onRemoveWidget} icon={faTrash} className="text-danger" />
                </Button>
            )}
        </div>
    );
};

export default WidgetVSeparator;
