import { useMemo } from 'react';

const WIN_PLATFORMS = ['Win32', 'Win64', 'Windows'];
const MAC_PLATFORMS = ['Macintosh', 'MacIntel', 'MacPPC', 'macOS'];

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
// The userAgentData is not defined in the default Typescript Navigator interface.
interface NavigatorWithAgent extends Navigator {
    userAgentData?: {
        platform?: string;
    };
}

interface UsePlatformResult {
    platform: 'windows' | 'macos' | undefined;
    isMac: boolean;
    isWindows: boolean;
}

// A fairly straightforward hook used to detect the
// user platform
export const usePlatform = (): UsePlatformResult => {
    // Get platform
    const platform = useMemo(() => {
        const navigatorWithAgent: NavigatorWithAgent = navigator;
        const srcPlatform = navigatorWithAgent.userAgentData?.platform || navigatorWithAgent.platform;

        if (WIN_PLATFORMS.includes(srcPlatform)) {
            return 'windows';
        } else if (MAC_PLATFORMS.includes(srcPlatform)) {
            return 'macos';
        } else {
            return undefined;
        }
    }, []);

    return { platform, isMac: platform == 'macos', isWindows: platform == 'windows' };
};
