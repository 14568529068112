import React, { FunctionComponent, useMemo, useState } from 'react';
import { InboxItem, InboxItemRelated, InboxItemWithRelated } from 'api/viz/queries/InboxItem';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';

import Moment from 'react-moment';
import ItemTitle, { BuildIcon } from './ItemTitle';
import InboxFeedTabContent from './InboxFeed/InboxFeedTabContent';
import { useInboxItemRelated } from 'api/viz/hooks/useInboxItemRelated';
import InboxCardLoader from './InboxCardLoader';
import ItemUserIconList from './ItemUserIconList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import ActionLabel from './ActionLabel';
import { get, sortBy, uniqBy } from 'lodash';
import LabelBadge from './LabelBadge';
import { RichMessage } from 'components/RichMessage';
import { WidgetDimensionField } from 'components/Dashboarding/Models/Widget';
import { useIntl } from 'react-intl';

interface Props {
    item: InboxItem;
    className?: string;
    sortingDimension?: WidgetDimensionField;
}

const InboxCard: FunctionComponent<Props> = ({ item, className, sortingDimension }: Props) => {
    // Service
    const intl = useIntl();

    // States
    const [tabId, setTabId] = useState<string>('-1');

    // Fetch related items
    const { initialLoading: inboxItemLoading, inboxItem } = useInboxItemRelated({ inboxItem: item });

    // Extract information
    const subItems = useMemo(
        () => (inboxItem.type === 'pull_request' ? inboxItem.resolved_issues : inboxItem.resolving_pull_requests),
        [inboxItem.resolved_issues, inboxItem.resolving_pull_requests, inboxItem.type]
    );
    const hasSubItems = subItems.length > 0;
    const allItems = [inboxItem, sortBy(subItems, 'created_at')].flat(1);

    // Build unified labels
    const unifiedLabels = useMemo(
        () =>
            sortBy(
                uniqBy(
                    allItems.flatMap(e => e.labels),
                    'name'
                ),
                'name'
            ),
        [allItems]
    );

    const sortingDate = useMemo(() => get(inboxItem, sortingDimension?.ref || ''), [inboxItem, sortingDimension?.ref]);

    // Return loader card if related items are still loading
    if (inboxItemLoading) return <InboxCardLoader className={className} />;

    // Render
    return (
        <Card className={classNames(className, 'inbox-card px-4 py-3')}>
            {/* Item Header */}
            <Card.Body className="hover-target-display-wrapper p-0">
                {/* Card header with title, project name and labels */}
                <div className="d-flex flex-wrap">
                    {/* Header */}
                    <div className="flex-grow-1 mb-2">
                        {/* Project name */}
                        <div className="fw-semibold w-100 text-truncate-left">{inboxItem.project.name}</div>

                        {/* Main Section */}
                        <div className="mb-2 mb-md-0">
                            {/* Item Title */}
                            <div>
                                <a href={inboxItem.url} target="_blank" rel="noopener noreferrer" className="row">
                                    <div className="col-10 text-truncate pe-0">
                                        <div className="d-flex text-break">
                                            <ItemTitle item={inboxItem} tooltip prefixType />
                                            <div className="ms-1 ps-0 pe-15">
                                                <BuildIcon item={inboxItem} tooltip={true} className="ms-1" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Recommended action */}
                    <div>
                        <ActionLabel item={inboxItem} />
                    </div>
                </div>

                {/* Labels */}
                <div className="d-flex flex-wrap align-items-center my-3 fs-sm">
                    {unifiedLabels.map(label => (
                        <LabelBadge key={label.name} label={label} />
                    ))}
                </div>

                {/* Due date and actors */}
                <div className="d-flex justify-content-between">
                    {/* Dates */}
                    <div className="d-flex align-items-center my-4">
                        <div className="d-flex">
                            {/* Due date */}
                            <div className="d-flex align-items-center me-4">
                                <div className="d-flex align-items-center me-1">
                                    <FontAwesomeIcon icon={faCalendar} size="sm" />
                                </div>
                                <div className="d-flex fs-sm">
                                    {inboxItem.due_on ? (
                                        <>
                                            <Moment format="DD MMM YYYY">{inboxItem.due_on}</Moment>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={
                                                    // PRs and Issues dates
                                                    <Tooltip id="tooltip-top" className="rounded">
                                                        <DueDatesTooltip items={allItems} />
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="text-warning ms-1">
                                                    {'('}
                                                    <Moment fromNow>{inboxItem.due_on}</Moment>
                                                    {')'}
                                                </span>
                                            </OverlayTrigger>
                                        </>
                                    ) : (
                                        intl.formatMessage({ id: 'generic.none' })
                                    )}
                                </div>
                            </div>

                            {/* Last date */}
                            <span className="text-light fs-sm">
                                <RichMessage
                                    id={
                                        sortingDate
                                            ? `priority-inbox.last-${sortingDate ? sortingDimension?.ref : 'none'}`
                                            : 'generic.none'
                                    }
                                    values={{ date: sortingDate }}
                                />
                            </span>
                        </div>
                    </div>

                    {/* Actors */}
                    <ItemUserIconList users={inboxItem.recommended_actors} />
                </div>

                {/* Tabs */}
                {hasSubItems && (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        {/* Navigation tabs */}
                        <div>
                            <Nav
                                variant="tabs"
                                className="separator-tabs border-0 fs-sm text-dark fw-semibold"
                                onSelect={e => setTabId(e == null ? '-1' : e)}
                                defaultActiveKey="-1"
                            >
                                {/* Unified tab */}
                                <Nav.Item>
                                    <Nav.Link eventKey="-1">
                                        <RichMessage id="priority-inbox.feed-item.unified" />
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Individual item tabs */}
                                {allItems.map((e, index) => {
                                    return (
                                        <Nav.Item key={e._system_id}>
                                            <Nav.Link eventKey={index}>
                                                <ItemTitle
                                                    item={e}
                                                    short={true}
                                                    selfTitleKey={e._system_id == item._system_id}
                                                    tooltip={e !== item}
                                                />
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>
                        </div>
                    </div>
                )}
            </Card.Body>

            {/* Activity feed */}
            <InboxFeedTabContent item={inboxItem} tabId={tabId} />
        </Card>
    );
};

interface DueDatesTooltipProps {
    items: Array<InboxItemWithRelated | InboxItemRelated>;
}
const DueDatesTooltip: FunctionComponent<DueDatesTooltipProps> = ({ items }: DueDatesTooltipProps) => {
    return (
        <Table borderless className="border-0 p-0 m-0">
            <tbody>
                {items.map((e, i) => (
                    <tr key={i} className="p-0 m-0">
                        {/* Short code */}
                        <td className="fw-bold">
                            <ItemTitle item={e} short={true} />
                        </td>

                        {/* Due dates */}
                        <td className="fw-semibold fs-sm text-nowrap align-middle">
                            {e.due_on ? (
                                <RichMessage
                                    id="priority-inbox.due-date-tooltip.due-date"
                                    values={{ date: e.due_on }}
                                />
                            ) : (
                                <RichMessage id="priority-inbox.due-date-tooltip.no-due-date" />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
export default InboxCard;
