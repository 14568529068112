import { faClipboardCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { RichMessage } from 'components/RichMessage';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Variant } from 'react-bootstrap/types';

interface Props {
    className?: string;
    content: string;
    labelId?: string;
    variant?: Variant;
}

const CopyToClipboard: FunctionComponent<Props> = ({ className, content, labelId, variant = 'dark' }: Props) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    // Select the whole json object on click
    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(content);
        setIsCopied(true);
    }, [content]);

    // Reset the isCopied state after some time
    useEffect(() => {
        isCopied && setTimeout(() => setIsCopied(false), 1000);
    }, [isCopied]);

    return (
        <Button
            variant={variant}
            className={classNames(className, { 'text-info': isCopied })}
            onClick={copyToClipboard}
        >
            <FontAwesomeIcon icon={isCopied ? faClipboardCheck : faClipboard} />
            {labelId && (
                <span className="ms-2">
                    <RichMessage id={labelId} />
                </span>
            )}
        </Button>
    );
};

export default CopyToClipboard;
