import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResourceRoles } from 'api/hq/hooks/useResourceRoles';
import { Bullet } from 'components/Bullet';
import { WidgetFactType, WIDGET_DISPLAY_CONFIGS } from 'components/Dashboarding/Models/Widget';
import WidgetDocumentationTooltip from 'components/Dashboarding/WidgetDocumentationTooltip';
import LibraryCard from 'components/LibraryCard/LibraryCard';
import { RichMessage, useRichIntl } from 'components/RichMessage';
import { LIBRARY_CARD_DESCRIPTION_MAX_LENGTH } from 'constants/defaultValues';
import { truncate } from 'lodash';
import React, { FunctionComponent, ReactNode, ReactNodeArray, useMemo } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { kpShared } from 'util/customIcons';
import LibraryCardHeader from '../LibraryCardHeader';
import RelatedDashboardsList from './RelatedDashboardsList';
import WidgetActions from './WidgetActions';

interface Props {
    widget: WidgetFactType & { dashboards: { id: string; name: string }[] };
    dashboardId?: string;
    width?: number;
    height?: number;
    children: ReactNode | ReactNodeArray;
}

export const WidgetLibraryCard: FunctionComponent<Props> = ({
    dashboardId,
    widget,
    width,
    height,
    children
}: Props) => {
    // Services
    const intl = useRichIntl();

    // Load the roles
    const { isShared, loading: loadingRoles } = useResourceRoles({
        resourceId: widget.id,
        resourceType: 'WIDGET'
    });

    // Content formatting
    const truncatedDescription = useMemo(
        () => truncate(widget.description, { length: LIBRARY_CARD_DESCRIPTION_MAX_LENGTH }),
        [widget.description]
    );

    return (
        <LibraryCard width={width} height={height}>
            {/* Header */}
            <LibraryCardHeader
                config={{ variant: 'square-icon', faIcon: WIDGET_DISPLAY_CONFIGS[widget.configType].icon }}
                title={intl.formatMessage({ id: `dashboarding.widget-editor.widget-type.${widget.configType}` })}
            />

            {/* Body */}
            <>
                {/* Title */}
                <Card.Title className="d-flex align-items-center mw-100">
                    <div>
                        {loadingRoles ? (
                            <Spinner animation="border" size="sm" className="me-2" />
                        ) : isShared ? (
                            <FontAwesomeIcon icon={kpShared} size="sm" className="me-2" />
                        ) : null}
                    </div>
                    <div className="text-truncate" title={widget.name}>
                        {widget.name}
                    </div>
                    <WidgetDocumentationTooltip widget={widget} />
                </Card.Title>

                {/* Subtitle */}
                <p className="small text-grey-4">
                    {/* Updated at */}
                    {widget.updatedAt && (
                        <>
                            <RichMessage id="components.library-card.updated-on" values={{ date: widget.updatedAt }} />
                        </>
                    )}

                    {/* Bullet separator */}
                    <Bullet />

                    {/* Additional info */}
                    <RelatedDashboardsList
                        widget={
                            widget as WidgetFactType & {
                                dashboards: { id: string; name: string }[];
                            }
                        }
                    />
                </p>

                {/* Description */}
                <Card.Text className="text-dark" title={widget.description}>
                    {truncatedDescription}
                </Card.Text>
            </>

            {/* Main action button */}
            {children}

            {/* Actions */}
            <WidgetActions variant="buttons" widget={widget as WidgetFactType} dashboardId={dashboardId} />
        </LibraryCard>
    );
};
