import { QueryResult } from '@apollo/react-common';
import { OperationVariables } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { DashboardWithLayout, GetDashboardResp, GET_DASHBOARD } from '../queries/Dashboard';
import { useCallback, useMemo } from 'react';
import omitDeep from 'omit-deep-lodash';

interface QueryOpts {
    id?: string;
    skip?: boolean;
}

interface DashboardQueryResults extends QueryResult<GetDashboardResp, OperationVariables> {
    normalized?: DashboardWithLayout;
    refetchNormalized: () => Promise<DashboardWithLayout | undefined>;
}

/**
 * Method to normalize the results
 *
 * @param data the results to normalize
 * @returns the normalized results
 */
const normalize = (data: GetDashboardResp | undefined): DashboardWithLayout | undefined => {
    // Abort if no dashboard result
    if (!data?.dashboard) return;

    // Normalize
    const dashboard = omitDeep(data.dashboard, '__typename') as DashboardWithLayout;
    dashboard.pinned = data.dashboard.pins?.nodes?.[0]?.pinned === true;
    return dashboard;
};

export function useDashboard({ id, skip }: QueryOpts): DashboardQueryResults {
    // Retrieve the dashboard by ID
    const rs = useQuery<GetDashboardResp>(GET_DASHBOARD, {
        skip: skip || !id,
        variables: { id }
    });

    // Refetch normalized results
    const refetchNormalized = useCallback(async () => {
        const response = await rs.refetch();
        return normalize(response.data);
    }, [rs]);

    // Extract the dashboard
    const normalized = useMemo(() => normalize(rs?.data), [rs.data]);

    return { ...rs, loading: rs.loading, normalized, refetchNormalized };
}
