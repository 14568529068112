import { LS_INITIAL_UTM_KEY } from 'constants/defaultValues';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

// List of UTM parameters
const TRACKED_UTMS = [
    // Official
    'utm_source',
    'utm_content',
    'utm_medium',
    'utm_campaign',
    'utm_term',

    // Custom: The ID of the user that referred this user for signup
    // This parameter is picked up by useIntercom and useGtmTracker then
    // propagated to Hubspot (via GTM)
    'utm_source_user',

    // Custom: The voucher used to signup
    // This parameter is picked up by useIntercom and useGtmTracker then
    // propagated to Hubspot (via GTM)
    'utm_voucher'
];

// Add UTM information to session storage (only persisted while the tab is open)
//
// This is used to capture the original UTM parameters that were sent to the /signup
// path, before it redirects to the actual signup/login page (hosted on Auth0). These
// UTM parameters are then restored in each marketing tracker (Intercom, Hubspot etc.)
export function useUtmTracker(): void {
    // Services
    const [searchParams] = useSearchParams();
    const [initialUtm, setInitialUtm] = useLocalStorage<Record<string, string> | undefined>(LS_INITIAL_UTM_KEY);

    // Track UTM values in local storage
    useEffect(() => {
        // Abort if the initialUtm has already been set
        if (initialUtm) return;

        // Capture individual values
        const utmFromParams: Record<string, string> = {};
        TRACKED_UTMS.forEach(tag => {
            const utmVal = searchParams.get(tag);
            if (utmVal) utmFromParams[tag] = utmVal;
        });

        // Update UTM in local storage
        setInitialUtm(utmFromParams);
    }, [initialUtm, searchParams, setInitialUtm]);
}
