import React, { FunctionComponent, Fragment, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AppConnectManagement from './AppConnectManagement';
import ProjectList from './ProjectList';
import IdentityList from './IdentityList';
import { RichMessage } from 'components/RichMessage';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import TopNavBar from 'components/Design/TopNavBar';
import { useTitleLabel } from 'util/useTitleLabel';

interface TabOpts {
    name: string;
    search?: string;
}

// Allowed tabs in order of appearance. First is default.
const ALLOWED_TABS: TabOpts[] = [
    { name: 'projects', search: 'display=auto' },
    { name: 'apps' },
    { name: 'identities' }
];

const AppManagement: FunctionComponent = () => {
    // Page title
    useTitleLabel('menu.apps-and-projects');

    // Services
    const navigate = useNavigate();
    const params = useParams() as { tab: string };

    // Extract params
    const tabId = params.tab;
    const isValidTabId = ALLOWED_TABS.map(e => e.name).includes(tabId);

    // Switch page to a specific tab
    const switchTab = useCallback(
        (tab: TabOpts): void => {
            navigate({ pathname: `../${tab.name}`, search: tab.search });
        },
        [navigate]
    );

    // If the tab is empty or incorrect, redirect to the default tab
    useEffect(() => {
        if (!isValidTabId) switchTab(ALLOWED_TABS[0]);
    }, [isValidTabId, switchTab]);

    // Abort if tab redirection is required.
    if (!isValidTabId) return null;

    // Render content
    return (
        <Fragment>
            {/* Header bar */}
            <TopNavBar>
                <h4 className="text-dark">
                    <RichMessage id="menu.apps-and-projects" />
                </h4>
            </TopNavBar>

            <Container>
                {/* Navigation tabs */}
                <Nav tabs className="separator-tabs ms-0 mb-4">
                    {ALLOWED_TABS.map(tab => {
                        return (
                            <NavItem key={tab.name}>
                                <NavLink
                                    className={classnames({ active: tabId === tab.name, 'nav-link': true })}
                                    onClick={() => switchTab(tab)}
                                    href="#"
                                >
                                    <RichMessage id={`settings.app-management.tab.${tab.name}`} />
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>

                {/* Tab Panes */}
                <TabContent activeTab={tabId}>
                    {/* Projects */}
                    <TabPane tabId="projects">
                        <ProjectList />
                    </TabPane>

                    {/* Apps */}
                    <TabPane tabId="apps">
                        <AppConnectManagement />
                    </TabPane>

                    {/* Identities */}
                    <TabPane tabId="identities">
                        <IdentityList />
                    </TabPane>
                </TabContent>
            </Container>
        </Fragment>
    );
};

export default AppManagement;
