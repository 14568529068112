import React, { Fragment, FunctionComponent, useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BUILD_ICON_CLASS: { [key: string]: { css: string; icon?: IconDefinition } } = {
    SUCCESS: { icon: faCheckCircle, css: 'text-success' },
    FAILURE: { icon: faTimesCircle, css: 'text-danger' },
    IN_PROGRESS: { css: 'loader small text-muted' }
};

interface TitleItem {
    key: string;
    title: string;
    type: string;
    build_status?: string;
}

interface Props {
    className?: string;
    item: TitleItem;
    short?: boolean;
    selfTitleKey?: boolean;
    tooltip?: boolean;
    tooltipShowDelay?: number;
    prefixType?: boolean;
    naturalKeyClass?: string;
}

const ItemTitle: FunctionComponent<Props> = ({
    className,
    item,
    short,
    selfTitleKey,
    tooltip,
    tooltipShowDelay,
    prefixType,
    naturalKeyClass = 'text-muted'
}: Props) => {
    // Tooltip configuration
    // See bug https://github.com/reactstrap/reactstrap/issues/773#issuecomment-488689148
    const titleLinkRef = useRef<HTMLElement | null>(null);
    const [tooltipReady, setTooltipReady] = useState<boolean>(false);
    useEffect(() => {
        if (titleLinkRef.current) {
            setTooltipReady(true);
        }
    }, []);

    // Infer title key from item type
    const titleKey = item.type === 'pull_request' ? 'pr_only' : 'issue_only';

    // Format the item key (prefix with # when plain number)
    const naturalKey = isNaN(parseInt(item.key)) ? item.key : `#${item.key}`;

    return (
        <>
            {/* Title tooltip content */}
            {tooltip && tooltipReady && titleLinkRef.current && (
                <UncontrolledTooltip
                    placement="top-start"
                    autohide={false}
                    hideArrow={true}
                    target={titleLinkRef.current}
                    className="tooltip-lg"
                    delay={{ show: tooltipShowDelay || 750, hide: 50 }}
                >
                    <span className={naturalKeyClass}>{naturalKey}</span> {item.title}
                </UncontrolledTooltip>
            )}

            {/* Full title without key prefix */}
            {!short && !prefixType && (
                <Fragment>
                    <span
                        ref={titleLinkRef}
                        className={classnames({ pointer: tooltip }, className, 'text-break-spaces')}
                    >
                        {item.title}
                    </span>
                </Fragment>
            )}

            {/* Full title with key prefix */}
            {!short && prefixType && (
                <Fragment>
                    <span
                        ref={titleLinkRef}
                        className={classnames({ pointer: tooltip }, className, 'text-break-spaces')}
                    >
                        <span className={naturalKeyClass}>
                            <FormattedMessage
                                id={`reports.shared.item-title.long.${item.type}`}
                                values={{ key: naturalKey }}
                            />
                        </span>{' '}
                        {item.title}
                    </span>
                </Fragment>
            )}

            {/* Short title with title key provided */}
            {short && selfTitleKey && (
                <span ref={titleLinkRef} className={classnames({ pointer: tooltip }, className)}>
                    <FormattedMessage id={`reports.shared.item-title.short.${titleKey}`} values={{ key: naturalKey }} />
                </span>
            )}

            {/* Short title when item key is a number */}
            {short && !selfTitleKey && naturalKey.startsWith('#') && (
                <span ref={titleLinkRef} className={classnames({ pointer: tooltip }, className)}>
                    <FormattedMessage
                        id={`reports.shared.item-title.short.${item.type}`}
                        values={{ key: naturalKey }}
                    />
                </span>
            )}

            {/* Short title where item key is an explicit key */}
            {short && !selfTitleKey && !naturalKey.startsWith('#') && (
                <span ref={titleLinkRef} className={className}>
                    {naturalKey}
                </span>
            )}
        </>
    );
};

interface BuildIconItem {
    build_status?: string;
}

interface BuildIconProps {
    item: BuildIconItem;
    tooltip?: boolean;
    className?: string;
}

export const BuildIcon: FunctionComponent<BuildIconProps> = ({ item, tooltip, className }: BuildIconProps) => {
    const buildIconLinkRef = useRef<HTMLElement | null>(null);
    const [tooltipReady, setTooltipReady] = useState<boolean>(false);
    useEffect(() => {
        if (buildIconLinkRef.current) {
            setTooltipReady(true);
        }
    }, []);

    // Abort if item has no build details
    if (!item.build_status || item.build_status === 'NONE') return null;

    // Get build icon class
    const buildIconClass = BUILD_ICON_CLASS[item.build_status];
    if (!buildIconClass) return null;

    // Render component
    return (
        <Fragment>
            {/* Build icon tooltip */}
            {tooltip && tooltipReady && buildIconLinkRef.current && (
                <UncontrolledTooltip
                    placement="right"
                    autohide={false}
                    hideArrow={true}
                    target={buildIconLinkRef.current}
                    className="tooltip-lg"
                    delay={{ show: 350, hide: 50 }}
                >
                    <FormattedMessage id={`reports.shared.item-title.build-icon.tooltip.${item.build_status}`} />
                </UncontrolledTooltip>
            )}

            {/* Icon */}
            <span ref={buildIconLinkRef} className={className}>
                {buildIconClass.icon ? (
                    <FontAwesomeIcon icon={buildIconClass.icon} className={buildIconClass.css} />
                ) : (
                    <i className={buildIconClass.css} />
                )}
            </span>
        </Fragment>
    );
};

export default ItemTitle;
