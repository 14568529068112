import { faList } from '@fortawesome/free-solid-svg-icons';
import {
    QueryOpField,
    QueryOpFilter,
    QUERY_OP_ENUM_FILTERS,
    QUERY_OP_LIST_FILTERS,
    QUERY_OP_NUMBER_FILTERS,
    QUERY_OP_STRING_FILTERS,
    QUERY_OP_TS_FILTERS,
    QUERY_OP_TS_DIMENSION_FORMATTERS,
    QUERY_OP_TS_DISPLAY_FORMATTERS,
    QUERY_OP_NUMBER_AGGREGATORS,
    QUERY_OP_ID_AGGREGATORS,
    QUERY_OP_DEFAULT_AGGREGATORS,
    QUERY_OP_URL_DISPLAY_FORMATTERS
} from './QueryOperators';

// Field definitions
//
// Notes:
// - First operator is the one displayed by default to users
// - If you update the fields, ensure you also update the `components.ruleset-builder.help.field.*`
//  translation keys in en_US.
// - Filterable fields displayed on the board must be common to inbox_items and history_inbox_items
//  so that metrics can be historically compared (in charts for example)
export const INBOX_ITEMS_QUERY_OP_FIELDS: QueryOpField[] = [
    {
        id: 'author_username',
        name: 'author.username',
        label: 'Author',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['AUTHOR_IS_ME', 'AUTHOR_IS_USER'].concat(QUERY_OP_STRING_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'assignee_usernames',
        name: 'assignees.*.username',
        label: 'Assignees',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['ASSIGNEES_INCLUDE_ME', 'ASSIGNEES_INCLUDE_USER'].concat(QUERY_OP_LIST_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'assigned_at',
        name: 'assigned_at',
        label: 'Assigned at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'base_ref_branch',
        name: 'base_ref.name',
        label: 'Base ref | Branch',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'base_ref_repository',
        name: 'base_ref.repo_fullname',
        label: 'Base ref | Repository',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'body',
        name: 'body',
        label: 'Body',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'build_status',
        name: 'build_status',
        label: 'Build status',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'FAILURE', label: 'Failure' },
            { name: 'IN_PROGRESS', label: 'In progress' },
            { name: 'NONE', label: 'None' },
            { name: 'SUCCESS', label: 'Success' }
        ]
    },
    {
        id: 'created_at',
        name: 'created_at',
        label: 'Created at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter', 'sort'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'closed_at',
        name: 'closed_at',
        label: 'Closed at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'due_on',
        name: 'due_on',
        label: 'Due on',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter', 'sort'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: '_system_id',
        name: '_system_id',
        label: 'ID (system)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'head_ref_branch',
        name: 'head_ref.name',
        label: 'Head ref | Branch',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'head_ref_repository',
        name: 'head_ref.repo_fullname',
        label: 'Head ref | Repository',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'id',
        name: 'id',
        label: 'ID (source)',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_ID_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'label_names',
        name: 'labels.*.name',
        label: 'Labels',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_LIST_FILTERS
    },
    {
        id: 'last_assigned_at',
        name: 'last_assigned_at',
        label: 'Last assigned at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'additions',
        name: 'additions',
        label: 'Lines added',
        type: 'int',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_NUMBER_AGGREGATORS,
        filters: QUERY_OP_NUMBER_FILTERS,
        filterDisplayType: 'number'
    },
    {
        id: 'deletions',
        name: 'deletions',
        label: 'Lines deleted',
        type: 'int',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_NUMBER_AGGREGATORS,
        filters: QUERY_OP_NUMBER_FILTERS,
        filterDisplayType: 'number'
    },
    {
        id: 'first_commit_at',
        name: 'first_commit_at',
        label: 'First commit at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'first_review_submitted_at',
        name: 'first_review_submitted_at',
        label: 'First review submitted at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'last_commit_at',
        name: 'last_commit_at',
        label: 'Last commit at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'last_review_submitted_at',
        name: 'last_review_submitted_at',
        label: 'Last review submitted at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'lines_changed',
        name: 'lines_changed',
        label: 'Lines changed',
        type: 'int',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_NUMBER_AGGREGATORS,
        filters: QUERY_OP_NUMBER_FILTERS,
        filterDisplayType: 'number'
    },
    {
        id: 'merged_at',
        name: 'merged_at',
        label: 'Merged at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter', 'sort'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'merged_by_username',
        name: 'merged_by.username',
        label: 'Merged by',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['MERGED_BY_IS_ME', 'MERGED_BY_IS_USER'].concat(QUERY_OP_STRING_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'mergeable',
        name: 'mergeable',
        label: 'Mergeability',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'MERGEABLE', label: 'Mergeable' },
            { name: 'CONFLICTING', label: 'Conflicting' },
            { name: 'UNKNOWN', label: 'Unknown' }
        ]
    },
    {
        id: 'project_name',
        name: 'project.name',
        label: 'Project',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'requested_reviewer_usernames',
        name: 'requested_reviewers.*.username',
        label: 'Reviewers',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['REVIEWERS_INCLUDE_ME', 'REVIEWERS_INCLUDE_USER'].concat(QUERY_OP_LIST_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'recommended_action',
        name: 'recommended_action',
        label: 'Recommended action',
        filters: QUERY_OP_ENUM_FILTERS,
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'ASSIGN_REVIEWER', label: 'Assign reviewer (suggested)' },
            { name: 'ASSIGN_MISSING_REVIEWERS', label: 'Assign missing reviewers (required)' },
            { name: 'CLOSE', label: 'Close' },
            { name: 'FIX_BUILD', label: 'Fix build' },
            { name: 'FIX_CODE', label: 'Address requested review changes' },
            { name: 'IMPLEMENT', label: 'Implement' },
            { name: 'MERGE', label: 'Merge' },
            { name: 'NONE', label: 'None' },
            { name: 'REBASE', label: 'Rebase' },
            { name: 'REVIEW', label: 'Review' },
            { name: 'WAIT_FOR_IMPLEMENTATION', label: 'Wait for implementation' }
        ]
    },
    {
        id: 'recommended_actor_usernames',
        name: 'recommended_actors.*.username',
        label: 'Recommended actors',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['REVIEWERS_INCLUDE_ME', 'REVIEWERS_INCLUDE_USER'].concat(QUERY_OP_LIST_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'required_approval_count',
        name: 'required_approval_count',
        label: 'Req. approvals',
        type: 'int',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_NUMBER_AGGREGATORS,
        filters: QUERY_OP_NUMBER_FILTERS,
        filterDisplayType: 'number'
    },
    {
        id: 'required_approval_remaining_count',
        name: 'required_approval_remaining_count',
        label: 'Req. approvals remaining',
        type: 'int',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_NUMBER_AGGREGATORS,
        filters: QUERY_OP_NUMBER_FILTERS,
        filterDisplayType: 'number'
    },
    {
        id: 'resolution_state',
        name: 'resolution_state',
        label: 'Resolution state',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'NONE', label: 'None' },
            { name: 'IN_PROGRESS', label: 'In progress' },
            { name: 'IMPLEMENTED', label: 'Implemented' }
        ]
    },
    {
        id: 'resolved_issue_sysids',
        name: 'resolved_issue_sysids',
        label: 'Resolved Issue IDs (System)',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_LIST_FILTERS
    },
    {
        id: 'resolving_pull_request_sysids',
        name: 'resolving_pull_request_sysids',
        label: 'Resolving PR IDs (System)',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_LIST_FILTERS
    },
    {
        id: 'review_state',
        name: 'review_state',
        label: 'Review state',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'APPROVED', label: 'Approved' },
            { name: 'CHANGES_REQUESTED', label: 'Changes requested' },
            { name: 'NONE', label: 'None' },
            { name: 'PENDING', label: 'Pending' }
        ]
    },
    {
        id: 'review_approved_at',
        name: 'review_approved_at',
        label: 'Review approved at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'review_last_approved_at',
        name: 'review_last_approved_at',
        label: 'Review last approved at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'review_last_rejected_at',
        name: 'review_last_rejected_at',
        label: 'Review last rejected at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'review_requested_at',
        name: 'review_requested_at',
        label: 'Review requested at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'review_last_requested_at',
        name: 'review_last_requested_at',
        label: 'Review last requested at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'review_rejected_at',
        name: 'review_rejected_at',
        label: 'Review rejected at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'state',
        name: 'state',
        label: 'State',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'CLOSED', label: 'Closed (not merged)' },
            { name: 'MERGED', label: 'Merged' },
            { name: 'OPEN', label: 'Open' }
        ]
    },
    {
        id: 'suggested_merger_usernames',
        name: 'suggested_mergers.*.username',
        label: 'Suggested mergers',
        type: 'string',
        icon: faList,
        typeIsArray: true,
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: ['REVIEWERS_INCLUDE_ME', 'REVIEWERS_INCLUDE_USER'].concat(QUERY_OP_LIST_FILTERS) as QueryOpFilter[]
    },
    {
        id: 'title',
        name: 'title',
        label: 'Title',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_STRING_FILTERS
    },
    {
        id: 'type',
        name: 'type',
        label: 'Type',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        filters: QUERY_OP_ENUM_FILTERS,
        filterDisplayType: 'select',
        filterValues: [
            { name: 'pull_request', label: 'Pull request' },
            { name: 'issue', label: 'Issue' }
        ]
    },
    {
        id: 'updated_at',
        name: 'updated_at',
        label: 'Updated at',
        type: 'ts',
        usageScopes: ['metric', 'dimension', 'filter', 'sort'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        displayFormatters: QUERY_OP_TS_DISPLAY_FORMATTERS,
        dimensionFormatters: QUERY_OP_TS_DIMENSION_FORMATTERS,
        filterDisplayType: 'datepicker',
        filters: QUERY_OP_TS_FILTERS
    },
    {
        id: 'url',
        name: 'url',
        label: 'URL',
        type: 'string',
        usageScopes: ['metric', 'dimension', 'filter'],
        aggregators: QUERY_OP_DEFAULT_AGGREGATORS,
        defaultDisplayFormatter: 'FULL_LINK',
        displayFormatters: QUERY_OP_URL_DISPLAY_FORMATTERS,
        filters: QUERY_OP_STRING_FILTERS
    }
];
