import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { DASHBOARD_TMPL_KEY, DEFAULT_START_PATH } from 'constants/routeBuilders';
import { useDashboardCreator } from 'components/Dashboarding/Hooks/useDashboardCreator';
import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import { useNavigate } from 'react-router-dom';
import { RichMessage } from 'components/RichMessage';
import { useLocalStorage } from 'react-use';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { kpCog, kpDashboard, kpShared } from 'util/customIcons';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ReactComponent as KeypupLogoFull } from 'assets/img/logo-full.svg';
import { compact } from 'lodash';
import { ONBOARDING_ASSISTANT_DSHB_TMPL_KEY } from 'constants/localStorageKeys';

const PROGRESS_CIRCLE_RADIUS = 50;

const delayedPromise = (delay: number): Promise<void> => {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
};

const FinalizeAccount: FunctionComponent = () => {
    // Services
    const navigate = useNavigate();
    const { createDashboardFromTemplate } = useDashboardCreator();

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Retrieve the onboarding dashboard template from the local storage
    const [onboardingTemplate, , clearOnboardingTemplate] = useLocalStorage<DashboardTemplate>(
        ONBOARDING_ASSISTANT_DSHB_TMPL_KEY
    );
    // If the user signed up through the /flows/add-dashboard-template (see AddDashboardTemplate)
    // then the requested template will be stored in local storage
    const [starterTemplate, , clearStarterTemplate] = useLocalStorage<DashboardTemplate>(DASHBOARD_TMPL_KEY);

    // The stages to roll out
    const stages = useMemo(
        () => [
            {
                titleId: 'flows.onboarding.finalize-account.preparing',
                progress: 0, // %
                icon: faEllipsisH,
                duration: 0 // ms
            },
            {
                titleId: 'flows.onboarding.finalize-account.creating-account',
                color: '#3981F7',
                progress: 25, // %
                icon: kpCog,
                duration: 1500 // ms
            },
            {
                titleId: 'flows.onboarding.finalize-account.creating-team',
                color: '#53B5F9',
                progress: 50, // %
                icon: kpShared,
                duration: 1500 // ms
            },
            {
                titleId: 'flows.onboarding.finalize-account.setting-up-dashboard',
                color: '#87E9F9',
                progress: 75, // %
                icon: kpDashboard,
                duration: 1500, // ms
                task: async () => {
                    const dashboardsToCreate = [];

                    if (onboardingTemplate) {
                        // Parse the dashboard and add it to the dashboards to create
                        dashboardsToCreate.push(onboardingTemplate);

                        // If the starter template is different from the one passed in URL
                        if (starterTemplate && starterTemplate.id != onboardingTemplate.id) {
                            // Add it to the dashboards to create
                            dashboardsToCreate.push(starterTemplate);
                        }
                    } else if (starterTemplate) {
                        // Add the starter template to the dashboards to create
                        dashboardsToCreate.push(starterTemplate);
                    }

                    // Create all dashboards in parallel
                    return Promise.all(
                        dashboardsToCreate.map(e =>
                            createDashboardFromTemplate(e, { pinned: true, actionSource: 'platform-template' })
                        )
                    ).finally(() => {
                        clearOnboardingTemplate();
                        clearStarterTemplate();
                    });
                }
            },
            {
                titleId: 'flows.onboarding.finalize-account.workspace-ready',
                color: '#62D4A3',
                progress: 100, // %
                icon: faCheck,
                duration: 1500 // ms
            }
        ],
        [
            clearOnboardingTemplate,
            clearStarterTemplate,
            createDashboardFromTemplate,
            onboardingTemplate,
            starterTemplate
        ]
    );

    // State
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const currentStep = useMemo(() => stages[currentStepIndex], [stages, currentStepIndex]);

    useEffect(() => {
        // Abort if company is not in context yet
        if (!company) return;

        Promise.all(compact([delayedPromise(currentStep.duration), currentStep.task && currentStep.task()])).then(
            () => {
                const nextStepIndex = currentStepIndex + 1;
                if (stages.length > nextStepIndex) {
                    setCurrentStepIndex(nextStepIndex);
                } else {
                    navigate(DEFAULT_START_PATH);
                }
            }
        );
    }, [company, currentStep, currentStepIndex, navigate, stages.length]);

    // Render
    return (
        <div className="bg-dark-cross-pattern-2 d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
            <div className="flex-grow-1 flex-basis-0">
                <div className="mt-5">
                    <KeypupLogoFull className="mt-5" width={171} height={48} fill="white" />
                </div>
            </div>
            <div className="align-self-center" style={{ width: '175px', height: '175px' }}>
                <CircularProgressbarWithChildren
                    value={currentStep.progress}
                    strokeWidth={2}
                    styles={buildStyles({
                        pathColor: currentStep.color,
                        trailColor: '#35396a'
                    })}
                >
                    <div
                        className="text-center rounded-circle"
                        style={{
                            background: currentStep.color,
                            width: `${2 * PROGRESS_CIRCLE_RADIUS}px`,
                            height: `${2 * PROGRESS_CIRCLE_RADIUS}px`,
                            top: `calc(50% - ${PROGRESS_CIRCLE_RADIUS}px)`,
                            left: `calc(50% - ${PROGRESS_CIRCLE_RADIUS}px)`,
                            lineHeight: `${2 * PROGRESS_CIRCLE_RADIUS}px`
                        }}
                    >
                        <FontAwesomeIcon icon={currentStep.icon} color="white" size="2x" className="align-middle" />
                    </div>
                </CircularProgressbarWithChildren>
            </div>
            <div className="flex-grow-1 flex-basis-0 text-white text-center">
                <h3 className="mt-5 mb-2">
                    <RichMessage id={currentStep.titleId} />
                </h3>
                <p>
                    <RichMessage id="flows.onboarding.finalize-account.description" />
                </p>
            </div>
        </div>
    );
};

export default FinalizeAccount;
