import React, { FunctionComponent, useCallback } from 'react';
import { WIDGET_COLOR_PALETTE } from 'constants/colors';
import classNames from 'classnames';
import { shuffle } from 'lodash';

interface Props {
    className?: string;
    colors?: string[];
    onChange: (colors: string[]) => void;
}

const PalettePicker: FunctionComponent<Props> = ({ className, colors, onChange }: Props) => {
    // Get actual colors
    const pickedColors = colors || WIDGET_COLOR_PALETTE;

    // Shuffle color palette on click
    const onColorClick = useCallback(() => onChange(shuffle(WIDGET_COLOR_PALETTE)), [onChange]);

    // Render
    return (
        <div
            className={classNames('color-picker-control border rounded', className)}
            onClick={onColorClick}
            role="button"
        >
            <div className="d-flex">
                <div className="multi-color" style={{ backgroundColor: pickedColors[3], borderTopLeftRadius: 50 }} />
                <div className="multi-color" style={{ backgroundColor: pickedColors[0], borderTopRightRadius: 50 }} />
            </div>

            <div className="d-flex">
                <div className="multi-color" style={{ backgroundColor: pickedColors[2], borderBottomLeftRadius: 50 }} />
                <div
                    className="multi-color"
                    style={{ backgroundColor: pickedColors[1], borderBottomRightRadius: 50 }}
                />
            </div>
        </div>
    );
};

export default PalettePicker;
