import React, { FunctionComponent } from 'react';
import { BillingSubscriptionWithTier } from 'api/hq/queries/BillingSubscription';
import { Button, Form, Col, FormGroup, Row } from 'react-bootstrap';
import { RichMessage } from 'components/RichMessage';
import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
    IconDefinition
} from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRIMARY_DARK_BLUE } from 'constants/colors';
import moment from 'moment';
import { capitalize } from 'lodash';
import classNames from 'classnames';

const DEFAULT_BRAND_ICON = faCreditCard;
const BRAND_ICONS: { [key: string]: IconDefinition } = {
    visa: faCcVisa,
    mastercard: faCcMastercard,
    amex: faCcAmex,
    discover: faCcDiscover,
    diners: faCcDinersClub,
    jcb: faCcJcb
};

interface Props {
    billingSubscription: BillingSubscriptionWithTier;
    onEdit: () => void;
    displayMode?: 'cozy' | 'compact';
}

const CreditCardItem: FunctionComponent<Props> = ({ billingSubscription, onEdit, displayMode }: Props) => {
    // Infer context
    const isCompact = displayMode == 'compact';
    const billingCard = billingSubscription.billingCard;
    const ccColor = billingSubscription.tier.config.color;
    const ccNumber = billingCard?.last4;
    const brandIcon = BRAND_ICONS[billingCard?.brand || ''] || DEFAULT_BRAND_ICON;
    const brand = capitalize(billingCard?.brand);
    const expiryYear = moment(billingCard?.expYear).format('YY');
    const expiryMonth = moment(billingCard?.expMonth).format('MM');

    // Render
    return (
        <>
            {/* Owner details */}
            <Row className="mb-4">
                <Col md={isCompact ? 12 : 6}>
                    {/* Name on card */}
                    <FormGroup>
                        <Form.Label>
                            <RichMessage id="components.credit-card.form.name" />
                        </Form.Label>
                        <Form.Control name="name" value={billingCard?.name} disabled />
                    </FormGroup>
                </Col>

                {/* Billing email */}
                <Col md={isCompact ? 12 : 6} className={classNames('mt-4', { 'mt-md-0': !isCompact })}>
                    <FormGroup>
                        <Form.Label>
                            <RichMessage id="components.credit-card.form.email" />
                        </Form.Label>
                        <Form.Control name="email" type="email" value={billingCard?.email} disabled />
                    </FormGroup>
                </Col>
            </Row>

            <div className="d-flex align-items-center border border-grey-7 rounded p-4">
                {/* Card data */}
                <div
                    className={classNames('d-flex rounded border border-primary me-4 p-3', {
                        'w-40': !isCompact,
                        'w-100': isCompact
                    })}
                >
                    {/* Card type logo */}
                    <div className="d-flex align-items-start">
                        <FontAwesomeIcon icon={brandIcon} size="3x" color={ccColor} className="me-2 d-inline" />
                    </div>

                    <div className="d-flex flex-column">
                        {/* Card masked numbers */}
                        <span className="fw-semibold">
                            <RichMessage id="components.credit-card.item.masked-number" values={{ number: ccNumber }} />
                        </span>
                        {/* Expiry date */}
                        <span className="fs-sm text-muted">
                            <RichMessage
                                id="components.credit-card.item.brand-and-expiry"
                                values={{ brand, expiryYear, expiryMonth }}
                            />
                        </span>
                    </div>
                </div>

                {/* Next billing (TODO) */}
                {/* <div className="d-flex flex-column flex-grow-1">
                    <span>
                        <RichMessage id="components.credit-card.item.billing-day" />
                    </span>
                    <span>
                        <RichMessage id="components.credit-card.item.next-billing" />
                    </span>
                </div> */}

                {/* Actions */}
                <div className="d-flex justify-content-end">
                    {/* Edit */}
                    <Button onClick={onEdit} variant="outline-grey-7" className="rounded-sm p-2 me-2">
                        <FontAwesomeIcon icon={faPen} color={PRIMARY_DARK_BLUE} />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CreditCardItem;
