import { DashboardTemplate } from 'components/Dashboarding/Models/DashboardTemplates';
import React, { FunctionComponent } from 'react';
import { ReactComponent as ConfigureDashboardDiagram } from 'assets/img/onboarding/configure-dashboard.svg';
import { ReactComponent as ConfigureDashboardDiagramPlaceholder } from 'assets/img/onboarding/configure-dashboard-placeholder.svg';
import IconPicker from 'components/Design/ColorPickers/IconPicker';
import classNames from 'classnames';

interface Props {
    className?: string;
    style?: object;
    template?: DashboardTemplate;
}

const TemplateCustomizationDiagram: FunctionComponent<Props> = ({ className, style, template }: Props) => {
    return (
        <div className={classNames('d-flex flex-column align-items-center py-5', className)} style={style}>
            <ConfigureDashboardDiagram />
            {!template ? (
                <ConfigureDashboardDiagramPlaceholder />
            ) : (
                <div className="p-2" style={{ border: '2px dashed grey', width: '170%' }}>
                    <div className="bg-grey-11">
                        <div>
                            <div className="d-flex bg-white px-3 py-2 align-items-center text-start">
                                <IconPicker className="flex-grow-0 me-3" icon={template.icon} size={32} />
                                <span className="flex-grow-1 fw-bolder fs-normal text-dark text-truncate">
                                    {template.name}
                                </span>
                            </div>
                        </div>
                        <img className="w-100" src={template.imgDataUrl} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TemplateCustomizationDiagram;
