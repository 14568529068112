import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetFactType } from 'components/Dashboarding/Models/Widget';
import { RichMessage } from 'components/RichMessage';
import { ASSISTANT_WIDGET_QNS_KEY, ASSISTANT_WIDGET_TMPL_KEY } from 'constants/localStorageKeys';
import {
    widgetAssistantStepPath,
    WIDGET_TMPL_ID_KEY,
    dashboardViewPath,
    dashboardWidgetNewPath,
    WIDGET_ASSISTANT_STEP2
} from 'constants/routeBuilders';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import LayoutSplitScreen from 'routes/LayoutSplitScreen';
import { useTitleLabel } from 'util/useTitleLabel';
import Header from './Header';
import useAddWidgetToDashboard from 'components/Dashboarding/Hooks/useAddWidgetToDashboard';
import { toastQueryError } from 'components/Toast';
import { useDashboard } from 'api/hq/hooks/useDashboard';
import { useMutation } from '@apollo/react-hooks';
import { CreateWidgetResp, CREATE_WIDGET, WIDGET_CREATE_ATTRS } from 'api/hq/queries/Widget';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { useTemplateAssistant } from 'components/Dashboarding/Hooks/useTemplateAssistant';
import WidgetPreview from './WidgetPreview';
import { AsyncButton } from 'components/AsyncButton/AsyncButton';

export const Step3Review: FunctionComponent = () => {
    // Page title
    useTitleLabel('dashboarding.widget-assistant.title');

    // State
    const [widget, , clearWidget] = useLocalStorage<WidgetFactType>(ASSISTANT_WIDGET_TMPL_KEY);
    const [saveToDashboardInProgress, setSaveToDashboardInProgress] = useState<boolean>(false);
    const [saveToCollectionsInProgress, setSaveToCollectionsInProgress] = useState<boolean>(false);

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Extract params
    const params = useParams() as { dashboardId: string };
    const [searchParams] = useSearchParams();
    const templateId = searchParams.get(WIDGET_TMPL_ID_KEY);
    const dashboardId = params.dashboardId;

    // Services
    const navigate = useNavigate();
    const { normalized: dashboard } = useDashboard({ id: dashboardId, skip: !dashboardId });
    const addWidgetToDashboard = useAddWidgetToDashboard({ dashboard });

    // Template customizer hook
    const { clearQuestions } = useTemplateAssistant(ASSISTANT_WIDGET_QNS_KEY, templateId, 'WIDGET', 2);

    // Mutations
    const [createWidget] = useMutation<CreateWidgetResp>(CREATE_WIDGET);

    // Hook invoked to create the widget
    const saveWidget = useCallback(
        async ({ widget, companyId }) => {
            // Create new widget
            const payload = {
                ...pick({ ...widget, companyId }, WIDGET_CREATE_ATTRS),
                templateSource: widget?.id,
                actionSource: 'platform-template'
            };
            return (await createWidget({ variables: { input: payload } })).data?.createWidget;
        },
        [createWidget]
    );

    // On success callback
    const onSuccess = useCallback(() => {
        // Clear widget from local storage
        clearWidget();

        // Clear questions from local storage
        clearQuestions();

        // Redirect to dashboard
        navigate(dashboardViewPath({ dashboardId }));
    }, [clearQuestions, clearWidget, dashboardId, navigate]);

    // Hook invoked to save the widget and add it to the dashboard
    const handleSaveToDashboard = useCallback(async () => {
        // Abort if no widget
        if (!widget || !company) return;

        try {
            // Start loading state
            setSaveToDashboardInProgress(true);

            // Create new widget
            const saveResp = await saveWidget({ widget, companyId: company.id });

            // Abort if the widget could not be saved
            if (saveResp?.success && saveResp?.widget?.id) {
                // Add id to new widget
                const updatedWidget = saveResp?.widget?.id ? { ...widget, id: saveResp.widget.id } : widget;

                // Add it to the dashboard
                if (dashboard) {
                    const addResp = await addWidgetToDashboard({ widget: updatedWidget });

                    // Abort if the widget could not be added to the dashboard
                    if (addResp?.success) {
                        onSuccess();
                    } else {
                        toastQueryError({ ...addResp?.error, namespace: 'add-widget-to-dashboard' });
                    }
                }
            } else {
                toastQueryError({ ...saveResp?.errors[0], namespace: 'save-widget' });
            }
        } catch {
            toastQueryError({ namespace: 'save-widget' });
        } finally {
            // Stop loading state
            setSaveToDashboardInProgress(false);
        }
    }, [addWidgetToDashboard, company, dashboard, onSuccess, saveWidget, widget]);

    // Hook invoked to save the widget to collections
    const handleSaveToCollections = useCallback(async () => {
        // Abort if no widget
        if (!widget || !company) return;

        try {
            // Start loading state
            setSaveToCollectionsInProgress(true);

            // Create new widget from template
            const resp = await saveWidget({ widget, companyId: company.id });

            // Abort if the widget could not be saved
            if (resp?.success) {
                onSuccess();
            } else {
                toastQueryError({ ...resp?.errors[0], namespace: 'save-widget' });
            }
        } catch {
            toastQueryError({ namespace: 'save-widget' });
        } finally {
            // Stop loading state
            setSaveToCollectionsInProgress(false);
        }
    }, [company, onSuccess, saveWidget, widget]);

    return (
        <LayoutSplitScreen header={<Header />} leftSectionStyle={{ maxWidth: 675 }}>
            {/* Left section */}
            <>
                <div className="flex-grow-1">
                    {/* Title */}
                    <div className="d-flex align-items-baseline mt-4 mb-5">
                        <Button
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            as={Link as any}
                            to={widgetAssistantStepPath(WIDGET_ASSISTANT_STEP2, templateId, dashboardId)}
                            variant="link"
                            className="p-0"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="me-3 text-grey-4" size="2x" />
                        </Button>
                        <h1 className="fw-bolder">
                            <RichMessage id="dashboarding.widget-assistant.review.title" />
                        </h1>
                    </div>

                    {/* Info */}
                    <Alert
                        className="d-none d-sm-block flex-grow-0 w-100 px-5 mb-5 border-0 text-primary rounded-4"
                        style={{ backgroundColor: '#eff5fe' }}
                    >
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="position-absolute my-4 mx-3"
                            size="lg"
                            style={{ top: 0, left: 0 }}
                        />
                        <h5 className="my-2 fw-500">
                            <RichMessage id="dashboarding.widget-assistant.review.info.title" />
                        </h5>
                        <div className="text-dark" style={{ opacity: 0.8, textAlign: 'justify' }}>
                            <RichMessage id="dashboarding.widget-assistant.review.info.content.start" />
                            <ul>
                                <li>
                                    <RichMessage id="dashboarding.widget-assistant.review.info.content.choices.item1" />
                                </li>
                                <li>
                                    <RichMessage id="dashboarding.widget-assistant.review.info.content.choices.item2" />
                                </li>
                                <li>
                                    <RichMessage id="dashboarding.widget-assistant.review.info.content.choices.item3" />
                                </li>
                            </ul>
                            <RichMessage id="dashboarding.widget-assistant.review.info.content.end" />
                        </div>
                    </Alert>

                    {/* Actions */}
                    <div className="mt-5">
                        {/* Save and add to dashboard */}
                        {dashboardId && (
                            <AsyncButton
                                className="justify-content-center w-100 mb-3"
                                variant="primary"
                                disabled={saveToDashboardInProgress || saveToCollectionsInProgress}
                                onClick={handleSaveToDashboard}
                                loading={saveToDashboardInProgress}
                                messageProps={{
                                    id: 'dashboarding.widget-assistant.review.actions.save-add-to-dashboard'
                                }}
                                loadingMessageProps={{
                                    id: 'dashboarding.widget-assistant.review.actions.saving-to-dashboard'
                                }}
                            />
                        )}

                        {/* Save and add to collections */}
                        <AsyncButton
                            className="justify-content-center w-100 mb-3"
                            variant="dark"
                            disabled={saveToDashboardInProgress || saveToCollectionsInProgress}
                            onClick={handleSaveToCollections}
                            loading={saveToCollectionsInProgress}
                            messageProps={{ id: 'dashboarding.widget-assistant.review.actions.save-to-collections' }}
                            loadingMessageProps={{
                                id: 'dashboarding.widget-assistant.review.actions.saving-to-collections'
                            }}
                        />

                        {/* Fine tune in advanced builder */}
                        <Button
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            as={Link as any}
                            disabled={saveToDashboardInProgress || saveToCollectionsInProgress}
                            to={dashboardWidgetNewPath({
                                dashboardId,
                                template: {
                                    ...widget,
                                    templateSource: widget?.id,
                                    actionSource: 'platform-template'
                                } as WidgetFactType
                            })}
                            variant="twilight"
                            className="justify-content-center w-100 mb-3"
                        >
                            <RichMessage id="dashboarding.widget-assistant.review.actions.fine-tune-in-advanced-builder" />
                        </Button>
                    </div>
                </div>
            </>

            {/* Right section */}
            {widget && <WidgetPreview widget={widget} />}
        </LayoutSplitScreen>
    );
};
