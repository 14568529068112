import {
    UserTransitionActionTypes,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    UserStateActionTypes
} from 'constants/actionTypes';
import { hqApolloClient } from 'clients/hqApolloClient';
import { vizApolloClient } from 'clients/vizApolloClient';
import { CurrentApiUserResp } from 'api/hq/queries/CurrentApiUser';
import { MEMBERSHIP_DISABLED_STATUS } from 'constants/defaultValues';
import { CURRENT_API_USER_TOKEN_KEY, CURRENT_COMPANY_ID_KEY } from 'constants/localStorageKeys';

export interface CompanyState {
    id: string;
    name: string;
    role: string;
    status: string;
}

export interface UserState {
    loading: boolean;
    apiUser: CurrentApiUserResp | null;
    company: CompanyState | null;
}

export const userInitialState: UserState = {
    apiUser: null,
    company: null,
    loading: false
};

export default (state = userInitialState, action: UserTransitionActionTypes | UserStateActionTypes): UserState => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS: {
            const apiUser = action.payload;
            const companyIdList = apiUser.user.memberships.nodes.map(e => e.company.id);
            let currentCompanyId: string | null =
                apiUser.currentCompanyId ||
                (state.company && state.company.id) ||
                localStorage.getItem(CURRENT_COMPANY_ID_KEY);

            // Select current company
            if (!currentCompanyId || !companyIdList.includes(currentCompanyId)) {
                currentCompanyId = companyIdList[0];
            }

            // Store currentCompanyId for next visit
            localStorage.setItem(CURRENT_COMPANY_ID_KEY, currentCompanyId);

            // Used by vizApolloClient to configure authentication
            localStorage.setItem(CURRENT_API_USER_TOKEN_KEY, apiUser.token);

            // Get current company role
            const currentMembership = apiUser.user.memberships.nodes.find(e => e.company.id === currentCompanyId);

            return {
                ...state,
                loading: false,
                apiUser: apiUser,
                company: {
                    id: currentCompanyId,
                    name: currentMembership?.company.name || '',
                    role: currentMembership?.role || '',
                    status: currentMembership?.status || MEMBERSHIP_DISABLED_STATUS
                }
            };
        }
        case LOGOUT_USER:
            localStorage.clear();
            hqApolloClient.clearStore();
            vizApolloClient.clearStore();
            return { ...state, apiUser: null, company: null };
        default:
            return { ...state };
    }
};
