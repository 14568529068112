import React, { FunctionComponent, Fragment, useState, useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ToggleMetadata } from '@restart/ui/Dropdown';
import {
    LOGOUT_REDIRECTION_PATH,
    USER_ACCOUNT_PATH,
    APP_MANAGEMENT_PATH,
    TEAM_MANAGEMENT_PATH,
    SUBSCRIPTION_PATH,
    DASHBOARD_ROOT_PATH
} from 'constants/routeBuilders';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { sortBy } from 'util/ArrayOperators';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Gravatar from 'react-gravatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faThLarge,
    faExchangeAlt,
    faSignOutAlt,
    faUsers,
    faChevronRight,
    faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import { RichMessage } from 'components/RichMessage';
import { LOGIN_USER_SUCCESS, LOGOUT_USER } from 'constants/actionTypes';

const SettingsMenu: FunctionComponent = () => {
    // Service
    const navigate = useNavigate();

    // State
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isSwitchCompanyOn, setIsSwitchCompanyOn] = useState<boolean>(false);

    // Global state
    const authUser = useSelector((e: ReduxState) => e.authUser);
    const dispatch = useDispatch();

    // Extract data
    const apiUser = authUser.apiUser;
    const user = apiUser?.user;
    const selectedCompany = authUser?.company;
    const companies = (user?.memberships?.nodes || []).map(e => e.company).sort(sortBy('name'));
    const currentCompany = companies.find(e => e.id === selectedCompany?.id) || { id: null, name: null };
    const isMultiCompany = companies.length > 1;

    // Return true if the provided company is the current one
    const isCurrentCompany = useCallback(
        (e: { id: string }): boolean => {
            return e.id === currentCompany.id;
        },
        [currentCompany.id]
    );

    // Hook invoked when a dropdown item is selected
    const onSelect = useCallback((eventKey: string | null): void => {
        // Ignore if the eventKey is "noclose". That means the list of dropdown items
        // is has been updated and a new option must select (e.g. switch company)
        if (eventKey == 'noclose') return;
        setIsSwitchCompanyOn(false);
        setIsOpen(false);
    }, []);

    // Hook invoked when the dropdown is toggled
    const onToggle = useCallback((nextShow: boolean, meta: ToggleMetadata): void => {
        // Ignore select events and let the onSelect handler
        // choose if the dropdown must be closed
        if (meta.source == 'select') return;

        // This is an outside "click" event. Proceed with change of visibility.
        setIsSwitchCompanyOn(false);
        setIsOpen(nextShow);
    }, []);

    // Switch user to selected company
    const switchCompany = useCallback(
        (companyId: string): void => {
            dispatch({ type: LOGIN_USER_SUCCESS, payload: { ...apiUser, currentCompanyId: companyId } });
            navigate(DASHBOARD_ROOT_PATH);
        },
        [apiUser, dispatch, navigate]
    );

    // Logout action
    const handleLogout = useCallback((): void => {
        dispatch({ type: LOGOUT_USER });
        window.location.href = LOGOUT_REDIRECTION_PATH;
    }, [dispatch]);

    // Abort if no user
    if (!user || !currentCompany) return null;

    return (
        <div className="user-menu">
            {/* Profile Menu */}
            <Dropdown drop="end" show={isOpen} onToggle={onToggle} onSelect={onSelect}>
                {/* Menu Button */}
                <Dropdown.Toggle bsPrefix="none" childBsPrefix="none">
                    <div className="menu-icon">
                        <Gravatar email={user.email} size={26} default="wavatar" className="rounded-circle" />
                    </div>
                    <div className="menu-label">
                        <RichMessage id="menu.settings" />
                    </div>
                    <div className="menu-chevron">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </Dropdown.Toggle>

                {/* Menu content */}
                <Dropdown.Menu variant="dark">
                    {isSwitchCompanyOn ? (
                        // Select company to switch to
                        <>
                            {companies.map(e => {
                                return (
                                    <Dropdown.Item
                                        key={e.id}
                                        onClick={() => switchCompany(e.id)}
                                        className={isCurrentCompany(e) ? 'font-weight-bold' : undefined}
                                    >
                                        {isCurrentCompany(e) && (
                                            <FontAwesomeIcon icon={faChevronRight} className="me-1" />
                                        )}
                                        {e.name}
                                    </Dropdown.Item>
                                );
                            })}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setIsSwitchCompanyOn(false)} eventKey="noclose">
                                <FormattedMessage id="top-nav.user-menu.cancel" />
                            </Dropdown.Item>
                        </>
                    ) : (
                        // Default menu
                        <>
                            {/* User account */}
                            <Dropdown.Item as={Link} to={USER_ACCOUNT_PATH}>
                                <FontAwesomeIcon icon={faUser} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.account" />
                            </Dropdown.Item>

                            {/* Apps & Projects */}
                            <Dropdown.Item as={Link} to={APP_MANAGEMENT_PATH}>
                                <FontAwesomeIcon icon={faThLarge} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.apps-and-projects" />
                            </Dropdown.Item>

                            {/* Billing & Subscription */}
                            <Dropdown.Item as={Link} to={SUBSCRIPTION_PATH}>
                                <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.billing" />
                            </Dropdown.Item>

                            {/* Help center */}
                            {/* <Dropdown.Item href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faBook} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.help-center" />
                            </Dropdown.Item> */}

                            {/* Team Management */}
                            <Dropdown.Item as={Link} to={TEAM_MANAGEMENT_PATH}>
                                <FontAwesomeIcon icon={faUsers} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.team-management" />
                            </Dropdown.Item>

                            {/* Switch company - only applicable if user has more than 1 company */}
                            {isMultiCompany ? (
                                <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => setIsSwitchCompanyOn(true)} eventKey="noclose">
                                        <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
                                        <FormattedMessage id="top-nav.user-menu.switch-company" />
                                        <span className="ms-1">({currentCompany.name})</span>
                                    </Dropdown.Item>
                                </>
                            ) : null}
                            <Dropdown.Divider />

                            {/* Sign out */}
                            <Dropdown.Item onClick={handleLogout} track-id="click-logout">
                                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                                <FormattedMessage id="top-nav.user-menu.sign-out" />
                            </Dropdown.Item>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default SettingsMenu;
