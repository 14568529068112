import gql from 'graphql-tag';

export interface Membership {
    id: string;
    role: string;
    status: string;
    createdAt: string;
    user: {
        id: string;
        firstName: string;
        email: string;
    };
}

export interface GetCompanyMembershipsResp {
    company: {
        id: string;
        memberships: {
            nodes: Membership[];
        };
    };
}

export const GET_COMPANY_MEMBERSHIPS = gql`
    query getCompanyMemberships($companyId: ID!, $cursor: String) {
        company(id: $companyId) {
            id
            memberships(after: $cursor, sortBy: "firstName.asc") {
                nodes {
                    id
                    role
                    status
                    createdAt
                    user {
                        id
                        firstName
                        email
                    }
                }
            }
        }
    }
`;

export const UPDATE_MEMBERSHIP = gql`
    mutation($id: ID!, $role: MembershipRoleEnum, $status: MembershipStatusEnum) {
        updateMembership(input: { id: $id, role: $role, status: $status }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;

export const REMOVE_MEMBERSHIP = gql`
    mutation($id: ID!) {
        deleteMembership(input: { id: $id }) {
            success
            errors {
                code
                message
                path
            }
        }
    }
`;
