import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
    CreateDashboardResp,
    CREATE_DASHBOARD,
    DashboardWithHeaders,
    DashboardWithLayout
} from 'api/hq/queries/Dashboard';
import {
    CreateWidgetResp,
    CREATE_WIDGET,
    GetCompanyWidgetsResp,
    GET_COMPANY_WIDGETS,
    WIDGET_CREATE_ATTRS
} from 'api/hq/queries/Widget';
import { find, omit, pick } from 'lodash';
import { useCallback } from 'react';
import { Layout } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/reducers';
import { cleanupTemplateMarkup } from './useTemplateAssistant';
import { DashboardTemplate, DashboardTemplateWidget } from '../Models/DashboardTemplates';
import { WidgetType } from '../Models/Widget';

interface CreateFromTemplateOpts {
    companyId?: string;
    pinned?: boolean;
    actionSource?: string;
}

interface Result {
    createWidgetsFromLayout: (layout: Layout[], widgets?: WidgetType[]) => Promise<Layout[]>;
    createDashboardFromTemplate: (
        template: DashboardTemplate,
        opts?: CreateFromTemplateOpts
    ) => Promise<CreateDashboardResp | undefined>;
    createDashboardFromLayout: (
        dashboard: DashboardWithLayout | DashboardTemplate,
        newLayout: Layout[],
        opts?: Partial<DashboardWithLayout>
    ) => Promise<CreateDashboardResp | undefined>;
}

const DASHBOARD_CREATE_EXCLUDE_ATTRS = ['id', 'widgets', 'roles', 'resolvedRoles', 'imgDataUrl'];

export function useDashboardCreator(): Result {
    // Service
    const client = useApolloClient();

    // Redux state
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Mutations
    const [createDashboard] = useMutation<CreateDashboardResp>(CREATE_DASHBOARD);
    const [createWidget] = useMutation<CreateWidgetResp>(CREATE_WIDGET);

    //
    // Create widgets from a layout. Used to duplicate the content of a dashboard.
    //
    const createWidgetsFromLayout = useCallback(
        async (layout: Layout[], widgets?: WidgetType[]): Promise<Layout[]> => {
            // If there are no widgets data provided, fetch the widgets by id
            if (!widgets) {
                const widgetIds = layout.flatMap(e => e.i);

                widgets = (
                    await client.query<GetCompanyWidgetsResp>({
                        query: GET_COMPANY_WIDGETS,
                        variables: { companyId: company?.id, idIn: widgetIds }
                    })
                ).data.widgets.nodes;
            }

            // Create the new layout
            return await Promise.all(
                layout.map(async e => {
                    // Format widget
                    const parentCompanyId = company?.id;
                    const widget = find(widgets, { id: e.i });
                    const widgetPayload = {
                        ...pick({ ...widget, companyId: parentCompanyId }, WIDGET_CREATE_ATTRS),
                        templateSource: widget?.id,
                        actionSource: 'dashboard-duplicate'
                    };

                    // Create widget and return layout item
                    const resp = (await createWidget({ variables: { input: widgetPayload } })).data?.createWidget
                        .widget;
                    const createdLayout: Layout = {
                        ...omit(e, 'widget'),
                        i: resp?.id || e.i
                    };

                    return createdLayout;
                })
            );
        },
        [client, company?.id, createWidget]
    );

    //
    // Create widgets from a layout template
    //
    const createWidgetsFromTemplateLayout = useCallback(
        async (layout: DashboardTemplateWidget[], opts?: CreateFromTemplateOpts): Promise<Layout[]> => {
            // Infer action source from parent dashboard
            const actionSource = opts?.actionSource ? `dashboard-${opts?.actionSource}` : undefined;

            // Create all widgets in parallel
            return await Promise.all(
                layout.map(async e => {
                    // Format widget
                    const parentCompanyId = opts?.companyId || company?.id;
                    const widgetPayload = {
                        ...pick({ ...e.widget, companyId: parentCompanyId }, WIDGET_CREATE_ATTRS),
                        templateSource: e.widget.id,
                        actionSource
                    };

                    // Create widget and return layout item
                    const resp = await createWidget({ variables: { input: widgetPayload } });
                    const createdLayout: Layout = {
                        ...omit(e, 'widget'),
                        i: resp?.data?.createWidget?.widget?.id || e.i
                    };

                    return createdLayout;
                })
            );
        },
        [company?.id, createWidget]
    );

    //
    // Create dashboard with new layout
    //
    const createDashboardFromLayout = useCallback(
        async (
            dashboard: DashboardWithHeaders,
            newLayout: Layout[],
            opts?: Partial<DashboardWithLayout>
        ): Promise<CreateDashboardResp | undefined> =>
            (
                await createDashboard({
                    variables: {
                        input: {
                            ...omit(dashboard, DASHBOARD_CREATE_EXCLUDE_ATTRS),
                            companyId: company?.id,
                            templateSource: dashboard.id,
                            ...opts,
                            layout: newLayout
                        }
                    }
                })
            ).data,
        [company?.id, createDashboard]
    );

    //
    // Create dashboard from a given template
    //
    const createDashboardFromTemplate = useCallback(
        async (
            template: DashboardTemplate,
            opts?: CreateFromTemplateOpts
        ): Promise<CreateDashboardResp | undefined> => {
            // Template cleanup
            const formattedTemplate = cleanupTemplateMarkup(template);

            // Create all widgets and return the new layout with ids
            const layout = await createWidgetsFromTemplateLayout(formattedTemplate.layout, opts);

            // Create dashboard with widgets attached
            return await createDashboardFromLayout(
                formattedTemplate,
                layout,
                // to override dashboard attributes
                opts
            );
        },
        [createDashboardFromLayout, createWidgetsFromTemplateLayout]
    );

    return { createDashboardFromTemplate, createDashboardFromLayout, createWidgetsFromLayout };
}
