import { RichMessage } from 'components/RichMessage';
import { RulesetBuilder, RulesetExplainer, useRulesetValidator } from 'components/RulesetBuilder';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { QueryOpFact } from 'components/Dashboarding/DataSource';
import { isEqual } from 'lodash';
import { FiltersRatio } from 'components/Dashboarding/FiltersRatio';

interface Props<TSeries> {
    fact: QueryOpFact;
    isOpen: boolean;
    onSave: (widget: TSeries) => void;
    titleId?: string;
    toggle: () => void;
    series: TSeries;
    drilldownEnabled?: boolean;
}

// The filter box provides a light summary of the currently active
// fields and allows users to customize filters via a modal.
function FilterBoxModal<TSeries extends { filters?: RuleGroupType }>({
    fact,
    isOpen,
    onSave,
    toggle,
    titleId,
    series,
    drilldownEnabled
}: Props<TSeries>): ReactElement<Props<TSeries>> {
    // State
    const [filters, setFilters] = useState<RuleGroupType | undefined>(series.filters);

    // Widget validation
    const isFiltersValid = useRulesetValidator({
        fact,
        ruleset: filters
    });
    const isChanged = useMemo(() => !isEqual(filters, series.filters), [filters, series.filters]);

    // Reset filters when modal gets closed
    const cancelEdit = useCallback(() => {
        toggle();
        setFilters(series.filters);
    }, [toggle, series.filters]);

    // Save filters on widget
    const saveEdit = useCallback(() => onSave({ ...series, filters: filters }), [filters, onSave, series]);

    // Change temporary filters if modal is open
    // This prevents unwanted changes from RulesetBuilder on closing the modal (e.g. when resetting
    // the reset on cancel)
    const onFilterChange = useCallback((e: RuleGroupType) => isOpen && setFilters(e), [isOpen]);

    // Resync filters if updated by the parent component
    useEffect(() => setFilters(series.filters), [series.filters]);

    // Render
    return (
        <Modal show={isOpen} onHide={cancelEdit} size="xl" centered animation={false}>
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    <RichMessage id={titleId || 'dashboarding.widget-editor.filter-box.default-title'} />
                    <h6 className="fw-semibold mt-1">
                        <FiltersRatio fact={fact} filters={filters} drilldownEnabled={drilldownEnabled} />
                    </h6>
                </Modal.Title>
            </Modal.Header>

            {/* Configure global filters */}
            <Modal.Body className="vh-50 overflow-auto">
                {/* Ruleset explainer */}
                <RulesetExplainer fact={fact} ruleset={filters} />
                {/* Validation status */}
                {!isFiltersValid && (
                    <small className="mt-0 mb-2 text-danger">
                        <RichMessage id="dashboarding.widget-editor.filter-box.filters-error" />
                    </small>
                )}
                {/* Interactive builder */}
                <RulesetBuilder
                    fact={fact}
                    scope="query"
                    ruleset={filters}
                    onChange={onFilterChange}
                    drilldownEnabled={drilldownEnabled}
                />
            </Modal.Body>

            {/* Apply or discard changes */}
            <Modal.Footer className="justify-content-center">
                <div className="d-flex justify-content-center align-items-center w-75">
                    <Button variant="outline-dark" className="w-50 justify-content-center me-2" onClick={cancelEdit}>
                        <RichMessage id="dashboarding.widget-editor.filter-box.action.cancel" />
                    </Button>
                    <Button
                        variant="dark"
                        className="w-50 justify-content-center"
                        onClick={saveEdit}
                        disabled={!isFiltersValid || !isChanged}
                    >
                        <RichMessage id="dashboarding.widget-editor.filter-box.action.save" />
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default FilterBoxModal;
