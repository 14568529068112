import { get, map, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useKey } from 'react-use';

interface NormalizedOption {
    label: string;
    value: string;
}

type Mapper = {
    [key in keyof NormalizedOption]: string;
};

interface NavigableDropdownProps {
    onToggle: (nextShow: boolean) => void;
    ref: React.MutableRefObject<null>;
    show: boolean;
}

interface NavigableDropdownMenuProps {
    handleSearch: (str: string) => void;
    isSearchable: boolean;
    toggle: boolean;
}

interface NavigableDropdownItemProps {
    focusedValue: string | null;
    onHover: (value: string) => void;
    onFocusing: (value: string) => void;
}

type NormalizeDropdownOptions<T> = (options: T[], mapper?: Mapper) => NormalizedOption[];
type DenormalizeDropdownOptions<T> = (options: T[], normalizedOptions: NormalizedOption[], mapper?: Mapper) => T[];
type MappingOption<T> = (option: T, mapper?: Mapper) => NormalizedOption;

interface Props<T> {
    fallbackSelect?: (string: string) => void;
    options: T[];
    isSearchable?: boolean;
    mapper?: Mapper;
    onSelect?: (name: string | null) => void;
    translationNS?: string;
}

interface Result<T> {
    getDropdownProps: NavigableDropdownProps;
    getDropdownMenuProps: NavigableDropdownMenuProps;
    getDropdownItemProps: NavigableDropdownItemProps;
    isSearchable: boolean;
    handleSearch: (str: string) => void;
    ref?: React.MutableRefObject<null>;
    onToggle: (nextShow: boolean) => void;
    options: T[];
    setFocusedValue: React.Dispatch<React.SetStateAction<string | null>>;
    show: boolean;
    toggle: boolean;
}

// This hook handles the shared logic for all the navigable dropdowns.
export function useNavigableDropdown<T>({
    fallbackSelect,
    isSearchable = true,
    mapper,
    options,
    onSelect,
    translationNS
}: Props<T>): Result<T> {
    // Services
    const ref = useRef(null);
    const intl = useIntl();

    // States
    const [focusedValue, setFocusedValue] = useState<string | null>(null);
    const [toggle, setToggle] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    /**
     *  Transform single option data structure to { value, label } pair of keys
     *  @param {(Object | string)} option - The dropdown option
     *  @param {Object} [mapper] - The mapper object that contains the pathes accessors to extract the value and label from the option. e.g. 'foo[0].bar'
     *  @return {Object} the option transformed into (value, label) pair
     */
    const mappingOption: MappingOption<T> = useCallback(
        (option, mapper) =>
            ((mapper
                ? { value: get(option, mapper?.value), label: get(option, mapper?.label) }
                : option) as unknown) as NormalizedOption,
        []
    );

    //
    /**
     * The function uses {mapper} to shape the {options} into the intended data structure { label, value }.
     * @param {(Object[] | string[])} options - The dropdown options data
     * @param {Object} mapper - The mapper is the object that contains the accessor paths to get the label and value from the dropdown options data
     * @returns {Object} the normalized option with only label and value keys
     **/
    const normalizeDropdownOptions: NormalizeDropdownOptions<T> = useCallback(
        (options, mapper) => {
            return map(options, option => {
                const { value, label } = mappingOption(option, mapper);

                return {
                    value,
                    label: translationNS ? intl.formatMessage({ id: `${translationNS}.${value}` }) : label
                };
            });
        },
        [intl, mappingOption, translationNS]
    );

    /**
     *  The function uses {mapper} to shape the {options} into the intended data structure { label, value }.
     *  @param {(Object[] | string[])} options - The dropdown options data
     *  @param {Object} mapper - The mapper is the object that contains the accessor paths to get the label and value from the dropdown options data
     *  @returns {Object} the normalized option with only label and value keys
     */
    const denormalizeDropdownOptions: DenormalizeDropdownOptions<T> = useCallback(
        (options, normalizedOptions, mapper) => {
            return options.filter(option => {
                const { value } = mappingOption(option, mapper);

                return map(normalizedOptions, 'value').includes(value);
            });
        },
        [mappingOption]
    );

    /**
     * The options of the dropdown transformed into { label, value } pair structure
     * This data structure will help unifiing the filtering of the dropdown options
     * based on the { label, value } pair
     */
    const normalizedOptions = useMemo(() => normalizeDropdownOptions(options, mapper), [
        mapper,
        normalizeDropdownOptions,
        options
    ]);

    // The options of the dropdown after
    //  => the search query is case-insensitive
    const filteredOptions: NormalizedOption[] = useMemo(
        () =>
            searchQuery
                ? normalizedOptions.filter(({ label }) => label.toLowerCase().includes(searchQuery.toLowerCase()))
                : normalizedOptions,
        [normalizedOptions, searchQuery]
    );

    /**
     * The original dropdown options after filtering them using { label, value } pair.
     * This memo to return back the dropdown options with the same shape (structure)
     * before transforming it to { label, value } pair structure.
     */
    const optionsResult: T[] = useMemo(() => denormalizeDropdownOptions(options, filteredOptions, mapper), [
        denormalizeDropdownOptions,
        options,
        filteredOptions,
        mapper
    ]);

    // Capture the first item value in the dropdown
    const firstItemValue = useMemo(() => sortBy(filteredOptions, ['label'])[0]?.value, [filteredOptions]);

    // Always focus on the first item if when filteredOptions updated
    useEffect(() => setFocusedValue(firstItemValue), [firstItemValue]);

    /**
     * Select an option on pressing Enter key on the keyboard
     * 1. Select if the item is focused by hovering
     * 2. OR select the first item in the list
     */
    const keyboardEnterPredicate = useCallback(() => {
        if (focusedValue && onSelect) {
            onSelect(focusedValue);
        } else {
            if (onSelect && firstItemValue) {
                onSelect(firstItemValue);
                return;
            }

            if (fallbackSelect) {
                fallbackSelect(searchQuery);
            }
        }

        // finally
        setToggle(false);
    }, [focusedValue, onSelect, fallbackSelect, firstItemValue, searchQuery]);
    useKey('Enter', keyboardEnterPredicate, { target: ref.current }, [keyboardEnterPredicate, ref.current]);

    // Persist toggle state
    const onToggle = useCallback(
        (toggleState: boolean): void => {
            // if toggle opened, set the focus to the first item
            if (toggleState) setFocusedValue(firstItemValue);

            // persist the toggle status
            setToggle(toggleState);
        },
        [firstItemValue]
    );

    // Return
    return {
        getDropdownProps: {
            onToggle,
            ref,
            show: toggle
        },
        getDropdownMenuProps: {
            handleSearch: setSearchQuery,
            toggle,
            isSearchable
        },
        getDropdownItemProps: {
            focusedValue,
            onHover: value => setFocusedValue(value),
            onFocusing: value => setFocusedValue(value)
        },
        handleSearch: setSearchQuery,
        isSearchable,
        options: optionsResult,
        onToggle,
        ref,
        setFocusedValue,
        show: toggle,
        toggle
    };
}
