import React, { FunctionComponent, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { RichMessage } from 'components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BillingSubscriptionTier } from 'api/hq/queries/BillingSubscription';
import { Col, Row } from 'react-bootstrap';
import { useSafeState } from 'util/useSafeState';
import { AsyncButton } from 'components/AsyncButton';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    currentTier: BillingSubscriptionTier;
    targetTier: BillingSubscriptionTier;
    onContinue?: () => void | Promise<void>;
}

// A warning modal explaining to the user that their recurring charges will change if they proceed
// with the action targetted by the BillingGuard.
//
// Note that if a company is already on the Enterprise plan (= price per unit instead of flat fee), then
// this modal will appear to notify users of a change of charges even though there is no change of plan.
//
// We therefore have to manages four scenarios:
// - Upgrade/downgrade of charges with a change of plan (e.g. start -> boost)
// - Upgrade/downgrade of charges without a change of plan (enterprise -> enterprise)
//
const TierChangeWarningModal: FunctionComponent<Props> = ({
    isOpen,
    toggle,
    currentTier,
    targetTier,
    onContinue
}: Props) => {
    // State
    const [isProcessing, setIsProcessing] = useSafeState<boolean>(false);

    // Display config
    const chargeChangeType = targetTier.price > currentTier.price ? 'upgrade' : 'downgrade';
    const planChangeType = currentTier.name == targetTier.name ? 'with-same-plan' : 'with-plan-change';

    // On proceed we close the modal and invoke the onContinue hook,
    // which is the onClick property of the underlying button being guarded
    const handleProceed = useCallback(async () => {
        setIsProcessing(true);
        onContinue && (await onContinue());
        toggle();
        setIsProcessing(false);
    }, [onContinue, setIsProcessing, toggle]);

    // Render
    return (
        <Modal show={isOpen} onHide={toggle}>
            {/* Modal Title */}
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faInfoCircle} className="me-3 text-primary" size="2x" />
                    <RichMessage id="components.billing-guard.tier-change-warning-modal.title" />
                </Modal.Title>
            </Modal.Header>

            {/* Modal Body */}
            <Modal.Body className="px-5 pb-3">
                {/* Explain the change of plan and the new charges */}
                <RichMessage
                    id={`components.billing-guard.tier-change-warning-modal.body.${chargeChangeType}.${planChangeType}`}
                    values={{
                        currentQuantity: currentTier.quantity,
                        currentMaxQuantity: currentTier.config.maxQuantity,
                        currentPrice: currentTier.price,
                        targetQuantity: targetTier.quantity,
                        targetMaxQuantity: targetTier.config.maxQuantity,
                        targetPrice: targetTier.price
                    }}
                />
            </Modal.Body>

            {/* Actions */}
            <Modal.Footer>
                <Row className="w-100">
                    <Col md="6">
                        {/* Cancel button */}
                        <Button
                            variant="outline-dark"
                            className="w-100 justify-content-center"
                            onClick={toggle}
                            disabled={isProcessing}
                            track-id={`tier-change-modal/cancel-${chargeChangeType}`}
                        >
                            <RichMessage id="components.billing-guard.tier-change-warning-modal.btn-cancel" />
                        </Button>
                    </Col>
                    <Col md="6">
                        <AsyncButton
                            variant="primary"
                            className="w-100 justify-content-center"
                            onClick={handleProceed}
                            track-id={`tier-change-modal/confirm-${chargeChangeType}`}
                            loading={isProcessing}
                            disabled={isProcessing}
                            messageProps={{ id: 'components.billing-guard.tier-change-warning-modal.btn-continue' }}
                            loadingMessageProps={{
                                id: 'components.billing-guard.tier-change-warning-modal.btn-continue-in-progress'
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default TierChangeWarningModal;
