import { createStore, Store } from 'redux';
import reducers, { ReduxState } from './reducers';
import { userInitialState } from './auth/reducer';
import { settingsInitialState } from './settings/reducer';

interface WindowStub extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (x: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION__: any;
}

declare let window: WindowStub;

const reduxInitialState: ReduxState = {
    authUser: userInitialState,
    settings: settingsInitialState
};

export function configureStore(state: ReduxState = reduxInitialState): Store {
    const devToolMiddleware = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    const store = createStore(reducers, state, devToolMiddleware);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(reducers));
    }

    return store;
}
