import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { ReduxState } from 'redux/reducers';
import { LS_INITIAL_UTM_KEY, LS_ONBOARDING_VARIANT_KEY } from 'constants/defaultValues';
import { useLocalStorage } from 'react-use';
import { camelCase, mapKeys } from 'lodash';
import { ONBOARDING_VARIANTS } from 'constants/routeBuilders';

// This hooks is in charge of sending events and user data to Google Tag Manager (GTM)
// Data Layer
// These events/data can then be used in GTM tags.
export function useGtmTracker(): void {
    // Get current user
    const user = useSelector((e: ReduxState) => e.authUser.apiUser?.user);
    const company = useSelector((e: ReduxState) => e.authUser.company);

    // Local storage
    const [initialUtm] = useLocalStorage<Record<string, string>>(LS_INITIAL_UTM_KEY);
    const [onboardingVariant] = useLocalStorage<string>(LS_ONBOARDING_VARIANT_KEY, ONBOARDING_VARIANTS[0].name);

    // Send user payload to Google Tag Manager
    useEffect(() => {
        // Abort if no user
        if (!user) return;

        // Camelcase UTM attributes
        const utmAttrs = mapKeys(initialUtm || {}, (v, k) => camelCase(k));

        // Send user payload. We attach the full payload as well as the userId
        // as some tags require a flat field.
        TagManager.dataLayer({
            dataLayer: {
                event: 'update-current-user',
                userId: user.id,
                companyId: company?.id,
                user: {
                    id: user.id,
                    createdAt: user.createdAt,
                    email: user.email,
                    firstName: user.firstName,
                    onboardingVariant: onboardingVariant,
                    ...utmAttrs
                }
            }
        });
    }, [initialUtm, onboardingVariant, user, company?.id]);
}
