import _, { PartialObject, clone, setWith } from 'lodash';
import { flattenDeep, isArray, isObject, map } from 'lodash';

interface GenericObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

// Usage:
// omitByDeep(myObject, 'name') => returns an object where the property names that equal 'name' have been recursively omitted
// omitByDeep(myObject, /.+FromAnswer$/) => returns an object where the property names that end with `FromAnswer` have been recursively omitted
export const omitByDeep = (obj: GenericObject, regexp: string | RegExp): PartialObject<GenericObject> => {
    return isObject(obj)
        ? isArray(obj)
            ? map(obj, v => omitByDeep(v, regexp))
            : _(obj)
                  .omitBy((v, k) => k.match(regexp))
                  .mapValues(v => omitByDeep(v, regexp))
                  .value()
        : obj;
};

// Usage:
// getDeepValuesByPropertyName(myObject, 'name') => returns an array containing all the values of the property names that equal 'name'
// getDeepValuesByPropertyName(myObject, /.+FromAnswer$/) => returns an array containing all the values of the property names that end with `FromAnswer`
export const getDeepValuesByPropertyName = (obj: GenericObject, regexp: string | RegExp): unknown[] => {
    const values = [];

    // Loop through all object properties
    for (const prop in obj) {
        // If the prop's value is an object
        if (typeof obj[prop] == 'object') {
            // Recursively execute the method on the object's sub properties
            // and push the results to the values array
            values.push(getDeepValuesByPropertyName(obj[prop], regexp));
        } else if (prop.match(regexp)) {
            // If the prop's name matches the regexp, add its value to the values array
            values.push(obj[prop]);
        }
    }

    // Deep flatten all the values
    return flattenDeep(values);
};

// Usage:
// deepMatchValue({ a: { b1: 'foobar' } }, 'foo') => returns true
// deepMatchValue({ a: { b1: 'foobar' } }, /^foo/) => returns true
// deepMatchValue({ a: { b1: 'foobar' } }, /foo$/) => return false
export const deepMatchValue = (obj: GenericObject, regexp: string | RegExp): boolean => {
    return (
        obj &&
        Object.values(obj).some(v => {
            if (typeof v == 'object') {
                return deepMatchValue(v, regexp);
            } else if (typeof v == 'string') {
                return v.match(regexp);
            }
            return false;
        })
    );
};

// Usage:
// getNestedPropertyValue({ a: { b1: 'foo', b2: 'bar' } }, 'a.b2') => returns 'bar'
// getNestedPropertyValue({ a: { b1: { c1: 'foo' }, b2: 'bar' } }, 'a.b2') => returns 'bar'
// getNestedPropertyValue({ a: { b1: { c1: 'foo' }, b2: 'bar' } }, 'a.b1.c1') => returns 'foo'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNestedPropertyValue = (obj: any, propName: string): any => {
    const arr = propName.split('.');
    while (arr.length) {
        const prop: string | undefined = arr.shift();
        if (prop == undefined) return obj;
        obj = obj[prop];
    }
    return obj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const immutableSet: any = (object: any, path: string, value: any) => setWith(clone(object), path, value, clone);
