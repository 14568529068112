import { WidgetChartType, WidgetKpiType } from 'components/Dashboarding/Models/Widget';
import { FIRST_MODAL_Z_INDEX } from 'constants/defaultValues';
import React, { FunctionComponent } from 'react';
import { Modal } from 'react-bootstrap';
import WidgetCard from './WidgetCard';

const DRILLDOWN_MODAL_HEIGHT = 400;

interface Props {
    widget: WidgetChartType | WidgetKpiType;
    dashboardId?: string;
    canEdit?: boolean;
    isOpen: boolean;
    onHide: () => void;
}
const DrilldownModal: FunctionComponent<Props> = ({ widget, dashboardId, canEdit, isOpen, onHide }) => {
    return (
        <Modal show={isOpen} onHide={onHide} size="xl">
            <Modal.Body className="p-0" style={{ height: DRILLDOWN_MODAL_HEIGHT }}>
                <WidgetCard
                    widget={widget}
                    dashboardId={dashboardId}
                    canEdit={canEdit}
                    enableSave
                    drilldownMode
                    tooltipZIndex={FIRST_MODAL_Z_INDEX}
                />
            </Modal.Body>
        </Modal>
    );
};

export default DrilldownModal;
