import React, { FunctionComponent } from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const RemoveAction: FunctionComponent<ActionWithRulesProps> = ({ className, title, handleOnClick }) => {
    return (
        <div className={className} title={title}>
            <FontAwesomeIcon role="button" icon={faTrash} className="text-grey-7" onClick={e => handleOnClick(e)} />
        </div>
    );
};

export default RemoveAction;
